import React from "react";
import { useLocation } from "react-router-dom";
import "./Footer.scss";

function FooterAdmission() {
  const location = useLocation();

  return (
    location.pathname !== "/login" && (
      <div className="Footer mt-4">
        <div className="copyright-area">
          <div className="container">
            <div className="copyright">
              <div className="row">
                {/* <div className="col-lg-6 col-md-4">
                  <div className="social-content">
                    <ul>
                      <li>
                        <span>Follow Us On</span>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/swaminarayanuniversity"
                          target="_blank"
                        >
                          <i className="ri-facebook-fill" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/swaminarayan_university/"
                          target="_blank"
                        >
                          <i className="ri-instagram-line" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/@swaminarayanuniversity7767"
                          target="_blank"
                        >
                          <i className="ri-youtube-fill" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}

                <div className="col-lg-6 col-md-4">
                  <div className="social-content d-flex align-items-center">
                    <ul className="d-flex align-items-center list-unstyled mb-0">
                      <li className="me-3">
                        <span style={{ color: "white", fontSize: "16px" }}>
                          Follow Us On
                        </span>
                      </li>
                      <li className="me-3 ml-1">
                        <a
                          href="https://www.facebook.com/swaminarayanuniversity"
                          target="_blank"
                        >
                          <i
                            className="ri-facebook-fill"
                            style={{ color: "white" }}
                          ></i>
                        </a>
                      </li>
                      <li className="me-3 ml-1">
                        <a
                          href="https://www.instagram.com/swaminarayan_university/"
                          target="_blank"
                        >
                          <i
                            className="ri-instagram-line"
                            style={{ color: "white" }}
                          ></i>
                        </a>
                      </li>
                      <li className="me-3 ml-1">
                        <a
                          href="https://www.youtube.com/@swaminarayanuniversity7767"
                          target="_blank"
                        >
                          <i
                            className="ri-youtube-fill"
                            style={{ color: "white" }}
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-6 col-md-8">
                  <div className="copy">
                    <p className="d-flex align-items-center justify-content-end">
                      {/* © 2022 Swaminarayan University. */}
                      Crafted with
                      <i className="mdi mdi-heart text-danger ml-1" /> by
                      <a
                        href="https://hibootstrap.com/"
                        target="_blank"
                        className="ml-2 text-danger"
                      >
                        Nexenstial
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default FooterAdmission;
