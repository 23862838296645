import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import {
  CLINICAL_NOTES,
  STUDENT_ID
} from "./Academic.Api";
import {
  COURSE_REGISTRATION_STUDENT,
  STUDENT_SESSION,
  ASSIGN_HOSPITAL
} from "../../utils/apiConstants";
import ClinicalNotesModal from "./ClinicalNotesModal";

function ClinicalStudies({ setLoading, collegeId }) {

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const student_id = sessionStorage.getItem("uid");

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  const [type, setType] = useState("OPD");
  const [data, setData] = useState([]);
  const college_id = sessionStorage.getItem("college");

  const [notes, setNotes] = useState({});
  const [edit, setEdit] = useState("add");
  const [patient, setPatient] = useState([]);
  const [clinicalNotes, setClinicalNotes] = useState([]);
  const [date, setDate] = useState("2024-05-11");
  // const [date, setDate] = useState(getCurrentDate());
  const [filterDept, setFilterDept] = useState("");
  const [patientDepartments, setPatientDepartments] = useState([]);
  const [assignedDepts, setAssignedDepts] = useState("");

  // console.log("college_id",college_id);

  const department = sessionStorage.getItem("department");
  // console.log("department",department);

  const classes = sessionStorage.getItem("class_id");
  // console.log("class_id",classes);

  const userid = sessionStorage.getItem("uid");
  console.log("user_id", userid);

  const semester_id = sessionStorage.getItem("semseter_id");
  console.log("semid - ", semester_id);

  const section_id = sessionStorage.getItem("section_id");

  const [id, setId] = useState("");



  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  useEffect(() => {
    setDepartmentOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == college_id
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), college_id]);


  const getOpdData = async () => {

    let result = [];

    try {
      setLoading(1);
      result = await axios.post('https://nexenstial.in/hims/api/opd_details.php', {
        date: date
      });
    }catch(err) {
        setLoading(0);
        toast.error("Something went wrong")
        console.log(err);
    }
    setLoading(0);
    // console.log('opd data - ', result?.data);
    result.data = Object.keys(result?.data)?.map(key => result?.data[key]) || [];
    if(result?.data?.length > 0) {
    const temp = [...result?.data];
    temp.pop();
    console.log('opd data - ', temp);

    const uniqueDepartments = new Set(temp?.map(patient => patient.department));
    const uniqueDepartmentsArray = [...uniqueDepartments];
    setPatientDepartments(uniqueDepartmentsArray);
    setData(temp);
    }
    else {
    toast.error("No data present");
    }
  }

  const getIpdData = async () => {

    let result = [];

    try {
      setLoading(1);
      result = await axios.post('https://nexenstial.in/hims/api/ipd_details.php', {
        date: date
      });
    }catch(err) {
        setLoading(0);
        toast.error("Something went wrong")
        console.log(err);
    }
    setLoading(0);
    result.data = Object.keys(result?.data)?.map(key => result?.data[key]) || [];
    if(result?.data?.length > 0) {
    const temp = [...result?.data];
    temp.pop();
    console.log('ipd data - ', temp);

    const uniqueDepartments = new Set(temp?.map(patient => patient.department));
    const uniqueDepartmentsArray = [...uniqueDepartments];
    setPatientDepartments(uniqueDepartmentsArray);
    setData(temp);
    }
    else {
      toast.error("No data present");
    }
  }

  const getClinicalNotes = async (req, res) => {
    const config = {
      method: "get",
      url: `${CLINICAL_NOTES}?student_session_id=${id}&date=${date}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    
    setLoading(1);
    await axios(config)
      .then((res) => {
        console.log(res);
        setLoading(0);
        setClinicalNotes(res.data.data);
        console.log('clinical student notes - ', res.data.data);
        // You may want to update the state or perform any other actions here
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        // toast.error('Something went wrong');
      });
  }

  const getStudentId = async () => {
    const config = {
      method: "get",
      url: `${STUDENT_ID}?student_id=${student_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        setId(res.data.data?.id);
        console.log('student session id - ', res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        // toast.error('Something went wrong');
      });
  }

  const handleSearch = async () => {

    if(!id || !date) {
      return toast.error("Mandatory fields are required");
    }
    const config = {
      method: "get",
      url: `${ASSIGN_HOSPITAL}?student_session_id=${id}&date=${date}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        if(res.data.data[0]?.department) {
          if(type == "OPD") {
            getOpdData();
          }
          else {
            getIpdData();
          }
          setAssignedDepts(res.data.data[0]?.department);
        }
        else {
          setAssignedDepts("");
        }
        console.log('assigned dept - ', res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        // toast.error('Something went wrong');
      });
  }

  useEffect(() => {
    if(id) {
      getClinicalNotes();
    }
  },[id]);

  useEffect(() => {
    getStudentId();
  },[]);

  useEffect(() => {
    setData([]);
  },[type, date]);

  
  // useEffect(() => {
  //   if(type == "OPD") {
  //     getOpdData();
  //   }
  //   else if(type == "IPD") {
  //     getIpdData();
  //   }
  // },[type]);
  

  return (
    <div>
      <ClinicalNotesModal 
        getClinicalNotes={getClinicalNotes}
        date={date} 
        student_session_id={id} 
        notes={notes} 
        patient={patient} 
        setNotes={setNotes} 
        edit={edit} 
        setEdit={setEdit} 
        setLoading={setLoading} 
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Clinical Studies</h4>
                </div>
              </div>
            </div>
            <div className="row">
              
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="">Patient Type <span style={{ color: "red" }}>*</span></label>
                  <select
                    className="form-control"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    <option value="OPD">OPD</option>
                    <option value="IPD">IPD</option>
                  </select>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Department</label>
                  <select
                    className="form-control"
                    value={filterDept}
                    onChange={(e) => {
                      setFilterDept(e.target.value)
                    }}
                  >
                     <option value="">All</option>
                    {patientDepartments?.map((i,k) => {
                      return (
                        <option value={i}>{i}</option>
                      )
                    })}
                  </select>
                </div>
              </div> */}
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="">Date <span style={{ color: "red" }}>*</span></label>
                  <input
                    type="date"
                    name="date"
                    onChange={(e) => {
                      setDate(e.target.value)
                    }}
                    className="form-control"
                    value={date}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <button
                  className="btn btn-nex mt-3"
                  type="submit"
                  name="submit"
                  onClick={handleSearch}
                >
                  <i className="fa fa-search" aria-hidden="true" />{" "}
                  Search
                </button>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <p className=""><b>{type} Patients List</b></p>
                          <p className="mr-3"><b>Date : {formatDate(date)}</b></p>
                        </div>
                    <hr />
                    <table
                      id="datatable"
                      className="table table-bordered dt-responsive nowrap table-hover "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Sl. No.</th>
                          <th>Patient Name</th>
                          <th>Gender</th>
                          <th>Age</th>
                          <th>Department</th>
                          <th>Clinical Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.filter((s) => s.department == assignedDepts)?.map((i,k) => {
                        return (
                          <tr>
                            <td>{k+1}</td>
                            <td>{i?.name}</td>
                            <td>{i?.gender}</td>
                            <td>{i?.age}</td>
                            <td>{i?.department}</td>
                            <td>
                              {clinicalNotes?.find((s) => s.patient_name == i?.name)
                              ?
                            <button 
                              className="btn btn-sm btn-primary"
                              data-toggle="modal"
                              data-target="#ClinicalNotesModal"
                              onClick={() => {
                                setEdit("edit");
                                setNotes(clinicalNotes?.find((s) => s.patient_name == i?.name));
                                setPatient(i);
                              }}
                            >
                              Edit Note
                            </button>
                            :
                            <button 
                              className="btn btn-sm btn-success"
                              data-toggle="modal"
                              data-target="#ClinicalNotesModal"
                              onClick={() => {
                                setEdit("add");
                                setNotes({});
                                setPatient(i);
                              }}
                            >Add Note</button>}
                            </td>
                          </tr>
                        )}
                        )}
                      </tbody>
                    </table>
                    {/* {data?.length == 0 ? <Nodata /> : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClinicalStudies;
