import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Toggler from "../../../Components/Toggler/Toggler";
import { LOCAL_EMPLOYEE, LOCAL_JOBROLES } from "../../../utils/LocalStorageConstants";
import { ALL_USER, EMPLOYEE_ALL, EMPLOYEE_FROM_USERS, EMPLOYEE_RESEND_CREDENTIALS, EVENT_WORKFLOW, HR_WORKFLOW, MAIL_EVENT_APPROVAL } from "../../../utils/apiConstants";

function ModalWorkFlow({
  type,
  id,
  data,
  reloadData,
  setLoading,
  approvers,
  collegeId,
}) {
  

  const [user, setUser] = useState({
    title: "",
    approver_1: "",
    approver_2: "",
    approver_3: "",
    approver_4: "",
    notification: true,
  });
 
const [emp, setEmpData] = useState([]);
const getusertableemployee = async () => {
  
    const config1 = {
      method: "get",
      url: EMPLOYEE_FROM_USERS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        setEmpData(res.data.data);
        // setLoading(0);
      })
      .catch((err) => {
        // setLoading(0);
        console.log(err);
      });
}

useEffect(()=>{
  getusertableemployee()
},[])

console.log("emp", emp)
const [selectedApprover, setSelectedApprover] = useState({ user_id: null, name: null, email: null, password: null });
console.log("d",selectedApprover)
useEffect(() => {
  const getEmployeeNameById = () => {
    const employee = emp.find(e => e.id == user?.approver_1);

    if (employee) {
      return { user_id: employee.id, 
        name: employee.name,
        email:employee.email,
        password:employee.password
       };
    } else {
      return { user_id: null, name: null, email:null, password:null };
    }
  };

  if (user.approver_1) {
    const result = getEmployeeNameById();
    setSelectedApprover(result);
  }
}, [user.approver_1]);
console.log("APPROV1", user?.approver_1)
  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  //fuction to clear the input fields after completion of any operation
  const clearData = () => {
    setUser({
      title: "",
      approver_1: "",
      approver_2: "",
      approver_3: "",
      approver_4: "",
      notification: true,
    });
  };
 


//fuction to call after post or put
const handleSubmit = async () => {
  //config for axios
  const config = {
    method: type === "edit" ? "put" : "post",
    url: `${EVENT_WORKFLOW}${type === "edit" ? "/" + data.id : ""}?type=LEAVE`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
    },
    data: {
      ...user,
      approval_status_1: user.approver_1 ? "PENDING" : "",
      approval_status_2: user.approver_2 ? "PENDING" : "",
      approval_status_3: user.approver_3 ? "PENDING" : "",
      approval_status_4: user.approver_4 ? "PENDING" : "",

      type: "LEAVE",
      college_id: collegeId,
    },
  };
  console.log(config);

  setLoading(1);
  await axios(config)
    .then((res) => {
      setLoading(0);
      toast.success(res.data.message);
      console.log(res);
      reloadData();
    })
    .catch((err) => {
      setLoading(0);
      console.log(err);
      toast.error("Something went wrong");
    });
  
   
      // setLoading(1);
    
};
const handleassign =async()=>{
  let cnt = 0;
  let d = [];
     
  const obj = {
    user_id: selectedApprover.user_id ,
   name:selectedApprover.name ,
   email:selectedApprover.email,
   password:selectedApprover.password
  }
  d.push(obj);

console.log("data to backend - ", d);
const config2 = {
  method: "post",
  url: `${MAIL_EVENT_APPROVAL}`,
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
  },
  data: { users: d },
};

await axios(config2)
.then(async (res) => {
  setLoading(0);
  if (res.data.data > 0)
    toast.success(`Sent credentials to ${selectedApprover?.name}`);
})
.catch((err) => {
  setLoading(0);
  toast.error("Internal Server Error");
  console.log(err);
});
// setLoading(0);
// setPromotes([]);
// "#ModalApprovalWorkFlow".modal("hide");
}

useEffect(() => {
  if (type === "edit") {
    if (data) {
      setUser({
        title: data.title,
        type: data.type,
        approver_1: data.approver_1,
        approver_2: data.approver_2,
        approver_3: data.approver_3,
        approver_4: data?.approver_4,
        approval_status_1: data.approval_status_1,
        approval_status_2: data.approval_status_2,
        approval_status_3: data.approval_status_3,
        approval_status_4: data.approval_status_4,
        notification: data.notification,
      });
    }
  }

  if (type === "add") {
    clearData();
  }
}, [data, type]);

return (
  <div className="ModalApprovalWorkFlow">
    <div className="ModalEarningPolicy">
      <div
        className="modal fade"
        id="ModalEventWorkFlow"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered mw-100 w-75"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {/* {props.type === 'edit' ? 'Edit' : 'Add'}  */}
                Approval Workflow
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {/* <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="">CODE <span style={{color:'red'}}>*</span></label>
                                        <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder='Enter Code'
                                        value={code}
                                        onChange={(e)=>{setCode(e.target.value)}}
                                         />
                                    </div>
                                </div> */}
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="">
                      Title <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Title"
                      name="title"
                      value={user.title}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="">
                      1st Approver <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      type="text"
                      className="form-control"
                      name="approver_1"
                      value={user.approver_1}
                      onChange={handleChange}
                    >
                      <option value="" selected>
                        Select Employee
                      </option>
                      {approvers.map((i, key) => (
                        <option key={key} value={i.employee_id}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="">
                      2nd Approver <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      type="text"
                      placeholder="Enter Title"
                      className="form-control"
                      name="approver_2"
                      value={user.approver_2}
                      onChange={handleChange}
                    >
                      <option value="" selected>
                        Select Employee
                      </option>
                      {approvers.map((i, key) => (
                        <option key={key} value={i.employee_id}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="">
                      3rd Approver <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      type="text"
                      className="form-control"
                      name="approver_3"
                      value={user.approver_3}
                      onChange={handleChange}
                    >
                      <option value="" selected>
                        Select Employee
                      </option>
                      {approvers.map((i, key) => (
                        <option key={key} value={i.employee_id}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="">
                      4th Approver <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      type="text"
                      className="form-control"
                      name="approver_4"
                      value={user.approver_4}
                      onChange={handleChange}
                    >
                      <option value="" selected>
                        Select Employee
                      </option>
                      {approvers.map((i, key) => (
                        <option key={key} value={i.employee_id}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-12 my-3">
                  <Toggler
                    label={"Notify on Final approval / rejection"}
                    defaultchecked={user.notification}
                    checked={user.notification}
                    checkboxValue={(e) => {
                      setUser((prevValue) => ({
                        ...prevValue,
                        notification: e.target.checked,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between px-2">
                <button
                  className="btn btn-danger btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleSubmit();
                    handleassign();
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
}

export default ModalWorkFlow
