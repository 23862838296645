import axios from "axios";
import React from "react";
import { useState } from "react";
import Nodata from "../../../Components/NoData/Nodata";
import { REPORT_FEE_DETAILS } from "../../../utils/Reports.apiConst";
import * as XLSX from "xlsx/xlsx.mjs";
import { useNavigate } from "react-router-dom";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { toast } from "react-toastify";
import { college_title } from "../../../Data/main";
import Select from "react-select";

const ProgramWiseCollectionReport = ({ setLoading, collegeId }) => {
  // const [user, setUser] = useState({
  //   date: "",
  //   to_transaction_date: "",
  // });

  const [user, setUser] = useState({
    date: new Date().toISOString().split('T')[0], // Set to current date
    to_transaction_date: new Date().toISOString().split('T')[0], // Set to current date
  });

  let count = 0;

  const tableRef = useRef();

  let amount = 0;

  const [data, setData] = useState([]);

  const [dates, setDates] = useState([]);

  const [program, setProgram] = useState("");
  const [selectedprogram, setSelectedProgram] = useState("");

  const programData = JSON.parse(localStorage.getItem(LOCAL_PROGRAM));
  const facultyData = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const departmentData = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [studentSet, setStudentSet] = useState([]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = async () => {
    if (!program) return toast.error("Please Select Program");
    setLoading(1);
    const config = {
      method: "get",
      url: `${REPORT_FEE_DETAILS}?transaction_date=${user?.date}&to_transaction_date=${user?.to_transaction_date}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        const m = res.data.data.filter(
          (s) => s?.program_id == program && s?.collegeId == collegeId
        );

        const result = [];

        setData(m);
        const stdSet = new Set();
        for (const i of m) {
          stdSet.add(i?.department_id);
        }

        const stdArr = Array.from(stdSet);
        setStudentSet(stdArr);

        stdArr?.map((item, key) => {
          m?.filter((s) => s.department_id == item)?.map((item1, key1) => {
            let y = result.find((s) => s.department_id == item1?.department_id);
            if (y) {
              item1?.newAmount?.map((item2, key) => {
                let z = y.nestedArray.find((s) => s.date == item2?.date);
                if (z) {
                  y.nestedArray.find((s) => s.date == item2?.date).total +=
                    item2?.amount;
                } else {
                  result
                    .find((s) => s.department_id == item1?.department_id)
                    .nestedArray.push({
                      date: item2?.date,
                      total: item2?.amount,
                    });
                }
              });
            } else {
              result.push({
                department_id: item1.department_id,
                nestedArray: [
                  {
                    date: item1?.newAmount[0]?.date,
                    total: item1?.newAmount[0]?.amount,
                  },
                ],
              });
            }
          });
        });
        console.log(result);
        setDates(result);
        setSelectedProgram(program);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const collegeList1 = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const downloadExcel = () => {
    let json_data = [];
    for (const iterator of data) {
      const obj = {
        "Student Registeration Number": iterator?.usn,
        "Student Name": iterator?.name,
        Amount: iterator?.amount,
        "Transaction Id": iterator?.transaction_id,
        "Transaction Type": iterator?.type,
      };
      json_data.push(obj);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `SUKALOL-Fee Details.xlsx`);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const options = programData?.map((i) => ({ value: i.id, label: i.name })) || [];

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">PROGRAM AND DATE WISE FEE REPORTS</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">Select Criteria</div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.date}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="to_transaction_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.to_transaction_date}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Program</label>
                      {/* <select
                        type="date"
                        name="to_transaction_date"
                        onChange={(e) => setProgram(e.target.value)}
                        className="form-control"
                        value={program}
                      >
                        <option value="">Select Program</option>
                        {programData?.map((i, key) => (
                          <option key={key} value={i?.id}>
                            {i?.name}
                          </option>
                        ))}
                      </select> */}

                      <Select
                        name="program"
                        className="basic-single"
                        classNamePrefix="select"
                        value={options.find((option) => option.value == program) || ""}
                        onChange={(selectedOption) => setProgram(selectedOption.value)}
                        options={options}
                        placeholder="Select Program"
                      />

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary rounded-pill"
                        onClick={getData}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="card-title">Fee Reports</div>
                    <button
                      className="btn btn-primary rounded-pill"
                      onClick={onDownload}
                    >
                      Export
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered" ref={tableRef}>
                        <tr>
                          <th colSpan={8}>{college_title}</th>
                        </tr>
                        <tr>
                          <th colSpan={8}>Date : {user?.date}</th>
                        </tr>
                        <tr>
                          <td colSpan={8} className="bg-dark text-light">
                            {
                              programData.find((s) => s.id == selectedprogram)
                                ?.name
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>Sl.No</th>
                          <th>Name</th>
                          <th>Enrollment No.</th>
                          <th>Transaction Id</th>
                          <th>Payment Type</th>
                          <th>Date</th>
                          <th>Note</th>
                          <th>Amount</th>
                        </tr>

                        {studentSet.map((i, key) => (
                          <>
                            <tr className="bg-dark text-light">
                              <td colSpan={7}>
                                {departmentData.find((s) => s.id == i)?.name}
                              </td>
                              <td>
                                {data
                                  ?.filter((s) => s.department_id == i)
                                  ?.reduce(
                                    (acc, current) =>
                                      acc +
                                      current?.newAmount?.reduce(
                                        (acc2, current2) =>
                                          acc2 + current2?.amount,
                                        0
                                      ),
                                    0
                                  )}
                              </td>
                            </tr>

                            {dates.map((item, key) => {
                              if (item?.department_id === i) {
                                return (
                                  <React.Fragment key={key}>
                                    {item?.nestedArray.map((item1, key1) => (
                                      <React.Fragment key={key1}>
                                        <tr className="bg-dark text-light">
                                          <td colSpan={7}>
                                            Date: {item1?.date}
                                          </td>
                                          <td>{item1?.total}</td>
                                        </tr>
                                        {data
                                          ?.filter((s) => s.department_id === i)
                                          ?.map((k, key2) => (
                                            <React.Fragment key={key2}>
                                              {k?.newAmount?.map((j, key3) => {
                                                if (item1?.date === j?.date) {
                                                  count = count + 1;
                                                  return (
                                                    <tr key={key3}>
                                                      <td>{count}</td>
                                                      <td>{k?.studentName}</td>
                                                      <td>{k?.user_id}</td>
                                                      <td>{j?.payment_id}</td>
                                                      <td>{j?.mode}</td>
                                                      <td>{j?.date}</td>
                                                      <td>{j?.note}</td>
                                                      <td>{j?.amount}</td>
                                                    </tr>
                                                  );
                                                }
                                                return null; // Add a fallback return for the map function
                                              })}
                                            </React.Fragment>
                                          ))}
                                      </React.Fragment>
                                    ))}
                                  </React.Fragment>
                                );
                              }
                              return null; // Add a fallback return for the map function
                            })}
                          </>
                        ))}

                        {data && data?.length != 0 ? (
                          <tr>
                            <td colSpan={7}>
                              <div className="d-flex justify-content-end">
                                Grand Total &nbsp;&nbsp;: &nbsp;&nbsp;
                                <strong>
                                  {data?.reduce(
                                    (acc, current) =>
                                      acc +
                                      current?.newAmount?.reduce(
                                        (acc2, current2) =>
                                          acc2 + current2?.amount,
                                        0
                                      ),
                                    0
                                  )}
                                </strong>
                              </div>
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramWiseCollectionReport;
