import { ROUTES } from "../../../Router/routerConfig";


const drop = "dropdown";
const stat = "static";
const sheet = "sheet";
export const navbarDataStudent = [
  // {
  //     title:'Dashboard',
  //     type:stat,
  //     route:ROUTES.Home,
  //     icon:<i className="ri-folder-chart-line mr-2" />
  // },
  // {
  //     title:'Fees',
  //     type:drop,
  //     icon:<i className="ri-store-2-line mr-2" />,
  //     drop:[
  //         {
  //         title:'Payments',
  //         type:stat,
  //         route:ROUTES.Home
  //         },
  //         {
  //         title:'Download Fees Reciept',
  //         type:stat,
  //         route:ROUTES.Home
  //         },
  //     ]
  // },
  {
    title: "My Profile",
    type: stat,
    route: ROUTES.StudentPortal.Profile,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Subjects",
    type: stat,
    route: ROUTES.StudentPortal.Subjects,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Course Registration",
    type: stat,
    route: ROUTES.StudentPortal.CourseRegistrationByStudent,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Attendance",
    type: stat,
    route: ROUTES.StudentPortal.Attendance,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Documents",
    type: stat,
    route: ROUTES.StudentPortal.Documents,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  
];

export const navbarDataMedicalStudent = [
  {
    title: "My Profile",
    type: stat,
    route: ROUTES.StudentPortal.Profile,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Subjects",
    type: stat,
    route: ROUTES.StudentPortal.Subjects,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Course Registration",
    type: stat,
    route: ROUTES.StudentPortal.CourseRegistrationByStudent,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Attendance",
    type: stat,
    route: ROUTES.StudentPortal.Attendance,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Documents",
    type: stat,
    route: ROUTES.StudentPortal.Documents,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Clinical Studies",
    type: stat,
    route: ROUTES.StudentPortal.ClinicalStudies,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  
];

export const navbarDataProvisionalStudent = [

  {
    title: "My Profile",
    type: stat,
    route: ROUTES.StudentPortal.Profile,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  
];

export const navbarDataGatePassStudent = [

  {
    title: "My Profile",
    type: stat,
    route: ROUTES.StudentPortal.Profile,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Subjects",
    type: stat,
    route: ROUTES.StudentPortal.Subjects,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Course Registration",
    type: stat,
    route: ROUTES.StudentPortal.CourseRegistrationByStudent,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Attendance",
    type: stat,
    route: ROUTES.StudentPortal.Attendance,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Documents",
    type: stat,
    route: ROUTES.StudentPortal.Documents,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Student Gate Pass",
    type: stat,
    route: ROUTES.StudentPortal.StudentGatePass,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  // {
  //   title: "View Gate Pass",
  //   type: stat,
  //   route: ROUTES.StudentPortal.ViewGatePass,
  //   icon: <i className="ri-folder-chart-line mr-2" />,
  // },
  
];