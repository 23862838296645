import React, { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../utils/LocalStorageConstants";
import {
  GATE_PASS_REQUEST_QUERY,
  STUDENT_GATE_PASS_GET1,
  STUDENT_SESSION_GET,
} from "../utils/InfoUploadingApiConstants";
import { EMPLOYEE_ALL, STUDENT_DETAILS } from "../utils/apiConstants";
import HostelModalStudentGatePass from "../modals/HostelModalStudentGatePass";
import Nodata from "../Components/NoData/Nodata";
import { ACADEMICS_ADD_CLASS } from "../utils/Academics.apiConst";

function GatePassWarden({ setLoading, collegeId }) {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  let date = new Date().toISOString().split("T")[0];

  const [data2, setData2] = useState("");
  const [flag, setFlag] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // When the user clicks the edit button
  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const [employee, setEmployee] = useState("");
  const [student, setStudent] = useState("");
  const [session, setSession] = useState("");

  const [classOpt, setClassOpt] = useState([]);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);

  console.log("collegeId", collegeId);

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${GATE_PASS_REQUEST_QUERY}`,
      // params: {
      //   from_date: fromDate,
      //   to_date: toDate,
      // },
    };

    await axios(config)
      .then((res) => {
        setData(res.data.data);
        // const studentGatePassData = res.data.data.filter(
        //   (s) => s.approval_status_2 != "APPROVED"
        // );
        // setData(studentGatePassData);
        // console.log("gate pass data - ", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });

    const config1 = {
      method: "get",
      url: EMPLOYEE_ALL,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        setEmployee(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: STUDENT_DETAILS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setStudent(res.data.data);
        console.log("student data -", res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config3 = {
      method: "get",
      url: STUDENT_SESSION_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config3)
      .then((res) => {
        setSession(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config4 = {
      method: "get",
      url: ACADEMICS_ADD_CLASS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config4)
      .then((res) => {
        setClassOpt(res.data.data);
        console.log("class data -", res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    setLoading(0);
  };

  useEffect(() => {
    getData();
  }, []);

  // console.log("collegeOpt", collegeOpt);

  const role = sessionStorage.getItem("role");
  // console.log("student data -", student);

  console.log("gate pass data - ", data);

  return (
    <div>
      <HostelModalStudentGatePass
        flag={flag}
        setFlag={setFlag}
        setLoading={setLoading}
        data={data2}
        getData={getData}
      />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> Student Gate Pass Requests </h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table table-responsive dt-responsive table-bordered nowrap table-hove">
                      <table
                        id="datatable"
                        className="table  nowrap table-hover  "
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          boarder: 0,
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sl.No.</th>
                            <th>Student Name</th>
                            <th>Enrollment No</th>
                            <th>Faculty</th>
                            <th>Department</th>
                            <th>Class</th>
                            <th>Request date</th>
                            <th>Purpose Type</th>
                            <th>Out Going Purpose</th>
                            <th>Out going From date</th>
                            <th>Out going To date</th>
                            <th>No Of Days</th>
                            <th>Proctor status</th>
                            <th>Proctor</th>
                            <th>Date</th>
                            <th>Warden Status</th>
                            <th>Warden</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.map((d, k) => (
                              <tr>
                                <td>{k + 1}</td>
                                {/* <td>
                                  {student &&
                                    student
                                      ?.filter(
                                        (s) => s.user_id == d?.student_id
                                      )
                                      ?.map((s) => s.name)}
                                </td> */}
                                <td>{d?.Student_Name}</td>
                                {/* <td>{d?.student_id}</td> */}
                                <td>{d?.Enrollment_No}</td>
                                {/* <td>
                                  {collegeOpt &&
                                    collegeOpt
                                      ?.filter((s) => s.id == d?.college_id)
                                      ?.map((s) => s.name)}
                                </td> */}
                                <td>{d?.College_Name}</td>
                                {/* <td>
                                  {student &&
                                    student
                                      ?.filter(
                                        (s) => s.user_id == d?.student_id
                                      )
                                      ?.map((s) => {
                                        const departmentId = s.department_id;
                                        const departments = department.find(
                                          (dept) => dept.id == departmentId
                                        );
                                        return departments
                                          ? departments.name
                                          : "Unknown Department";
                                      })}
                                </td> */}
                                <td>{d?.department_name}</td>
                                {/* <td>
                                  {student &&
                                    student
                                      ?.filter(
                                        (s) => s.user_id == d?.student_id
                                      )
                                      ?.map((s) => {
                                        const departmentId = s.department_id;
                                        const classname = classOpt.find(
                                          (dept) =>
                                            dept.department_id == departmentId
                                        );
                                        return classname
                                          ? classname.name
                                          : "Unknown Department";
                                      })}
                                </td> */}
                                <td>{d?.class_name}</td>
                                <td>{d?.request_date.split("T")[0]}</td>
                                <td>{d?.purpose_type}</td>
                                <td>{d?.out_going_purpose}</td>
                                <td>{d?.out_from_date}</td>
                                <td>{d?.out_to_date}</td>
                                <td>{d?.no_of_days}</td>

                                <td>
                                  <p
                                    className={` mt-3 badge ${
                                      d?.approval_status_1 == "APPROVED"
                                        ? "badge-soft-success"
                                        : d?.approval_status_1 == "PENDING"
                                        ? "badge-soft-warning"
                                        : d?.approval_status_1 == "DECLINED"
                                        ? "badge-soft-danger"
                                        : d?.approval_status_1 == "CANCELLED"
                                        ? "badge-soft-danger"
                                        : ""
                                    }`}
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "10px",
                                      textAlign: "center",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {d?.approval_status_1}
                                  </p>
                                </td>

                                {/* <td>
                                  {employee &&
                                    employee
                                      ?.filter(
                                        (s) => s.user_id == d?.approved_by_1
                                      )
                                      ?.map(
                                        (s) => `${s.first_name} ${s.last_name}`
                                      )}
                                </td> */}
                                <td>
                                  {d?.first_name} {d?.last_name}
                                </td>

                                <td>{d?.approved_date_1}</td>

                                <td>
                                  <p
                                    className={` mt-3 badge ${
                                      d?.approval_status_2 == "APPROVED"
                                        ? "badge-soft-success"
                                        : d?.approval_status_2 == "PENDING"
                                        ? "badge-soft-warning"
                                        : d?.approval_status_2 == "DECLINED"
                                        ? "badge-soft-danger"
                                        : d?.approval_status_2 == "CANCELLED"
                                        ? "badge-soft-danger"
                                        : ""
                                    }`}
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "10px",
                                      textAlign: "center",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {d?.approval_status_2}
                                  </p>
                                </td>

                                <td>
                                  {employee &&
                                    employee
                                      ?.filter((s) => s.id == d?.approved_by_2)
                                      ?.map(
                                        (s) => `${s.first_name} ${s.last_name}`
                                      )}
                                </td>

                                <td>{d?.approved_date_2}</td>

                                <td>
                                  <a
                                    data-toggle="modal"
                                    data-target="#HostelStudentGatePass"
                                    onClick={() => {
                                      setData2({
                                        ...d,
                                      });
                                    }}
                                  >
                                    <input
                                      className="btn btn-primary btn-nex btn-rounded mr-2"
                                      name="submit"
                                      onClick={() => handleEditClick(d)}
                                    >
                                      View
                                    </input>
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {data?.length == 0 ? <Nodata /> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GatePassWarden;
