import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Toggler from "../../../Components/Toggler/Toggler";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { ALL_DATA, EMPLOYEE, EMPLOYEE_DELETE } from "../../../utils/apiConstants";
import { HR_ASSIGN_WORKFLOW } from "../../../utils/apiConstants";
import { getDateMeta } from "@fullcalendar/react";
import '../Leave/TableScroll.css';

function ModalWorkFlowAssign({
  empData,
  type,
  id,
  data,
  reloadData,
  setLoading,
  approvers,
  collegeId,
  workflow,
  displayData,
  setDisplayData,
  getEmpData
}) {

    const [collegeOpt, setCollegeOpt] = useState(
            localStorage.getItem(LOCAL_COLLEGE)
        ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
        : null
    );

   const [promotes, setPromotes] = useState([]);
   const [selectAllChecked, setSelectAllChecked] = useState(false);
   const [searchName, setSearchName] = useState("");

   const toggleSelectAll = () => {
    setSelectAllChecked((prev) => !prev);
    const updatedPromotes = empData.filter(d => !d.leave_approval_workflow_id).map((d) => ({
      id: d.id,
      data: d,
    }));
    setPromotes(selectAllChecked ? [] : updatedPromotes);
  };

   const togglePromotion = (id, data) => {

    const isPromoted = promotes?.some((student) => student.id === id);

    if (isPromoted) {
      setPromotes((prevPromotes) => prevPromotes?.filter((student) => student.id !== id));
    } else {
      setPromotes((prevPromotes) => [...prevPromotes, {id, data}]);
    }
  };

  const assign = async (id) => {
    if(!id) {
        return toast.error("Unable to get Employee id");
    }
    let ids = [];
    ids.push(id);

    console.log('data to backend - ', {
        ids,
        work_flow_id: workflow.id
      })
    setLoading(1);
        const config = {
          method: "post",
          url: `${HR_ASSIGN_WORKFLOW}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          },
          data: {
            ids,
            work_flow_id: workflow.id
          }
        };
    
        axios(config)
          .then((res) => {
            setLoading(0);
            return toast.success("Assigned Successfully");
          })
          .catch((err) => {
            setLoading(0);
            toast.error(err.response.data.message);
            console.log(err);
          });
        setLoading(0);
        setPromotes([]);
        getEmpData();
  }


    const assignAll = async () => {
        if(promotes.length == 0) {
            return toast.error("Please Select Employees to assign");
        }
        let ids = [];
        for(let i in promotes) {
            ids.push(promotes[i].id);
        }
    
        console.log('data to backend - ', {
            ids,
            work_flow_id: workflow.id
          })
        setLoading(1);
            const config = {
              method: "post",
              url: `${HR_ASSIGN_WORKFLOW}`,
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              },
              data: {
                ids,
                work_flow_id: workflow.id
              }
            };
        
            axios(config)
              .then((res) => {
                setLoading(0);
                toast.success(`Assigned to ${promotes.length} employees`);
                ('#ModalWorkFlowAssign').modal('hide');
              })
              .catch((err) => {
                setLoading(0);
                toast.error(err.response.data.message);
                console.log(err);
              });
            setLoading(0);
            setPromotes([]);
            getEmpData();
    }

    useEffect(() => {
        console.log('promotes - ', promotes);
    },[promotes]);

    useEffect(() => {
      const filteredData = empData.filter((item) =>
        item.first_name.toLowerCase().includes(searchName.toLowerCase()) || item.last_name.toLowerCase().includes(searchName.toLowerCase())
      );
      setDisplayData(filteredData);
    },[searchName]);

    return (
        <div className="ModalWorkFlowAssign">
        <div className="ModalEarningPolicy">  
            <div
            className="modal fade"
            id="ModalWorkFlowAssign"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            >
            <div
                className="modal-dialog modal-dialog-centered mw-100 w-75"
                role="document"
            >
                <div className="modal-content ">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                    Assign Employees to Workflow : {workflow?.title}
                    </h5>
                    <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                <div>
                    <label>Assign Selected ({promotes.length}) : </label>
                    <button className="btn btn-success ml-2" data-dismiss="modal" aria-label="Close" onClick={assignAll}>Assign</button>
                </div>
                <div className="col-md-12 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search By Employee Name"
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                </div>
                <div className="table-responsive mt-3 fixTableHead">
                        <table className="table table-bordered ">
                            <thead className="bg-light">
                            <tr>
                                <th>
                                <input
                                    type="checkbox"
                                    id="select-all"
                                    checked={selectAllChecked}
                                    onChange={toggleSelectAll}
                                />
                                </th>
                                <th>Sl No.</th>
                                <th>Name</th>
                                <th>College</th>
                                <th>Work Flow Assigned</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                                {displayData ? displayData?.map((i,k) => {
                                    return (
                                        <tr>
                                            <td>
                                            {i?.leave_approval_workflow_id ? <input
                                                type="checkbox"
                                                name="checkbox[]"
                                                checked = {false}
                                                disabled={true}
                                            /> : <input
                                            type="checkbox"
                                            name="checkbox[]"
                                            value={i.id}
                                            checked={promotes?.some((staff) => staff.id === i.id)}
                                            onChange={() => togglePromotion(i.id, i)}
                                            />}
                                            </td>
                                            <td>{k+1}</td>
                                            <td>{`${i.first_name} ${i.last_name}`}</td>
                                            <td>{collegeOpt.find((s) => s.id == i.college_id)?.name}</td>
                                            <td>{data.find((s) => s.id == i.leave_approval_workflow_id)?.title ? data.find((s) => s.id == i.leave_approval_workflow_id)?.title : <span className="badge badge-soft-danger">Not Assigned</span>}</td>
                                            <td>{i?.leave_approval_workflow_id ? <span className="badge badge-soft-success ml-3">Assigned</span> : <button className="btn btn-success ml-2" data-dismiss="modal" aria-label="Close" onClick={() => assign(i.id)} disabled={promotes.length > 0}>Assign</button>}</td>
                                        </tr>
                                    )
                                }) : <>Loading Employees...</>}
                            </tbody>
                        </table>
                        </div>
                    <div className="row d-flex justify-content-between px-2">
                    <button
                        className="btn btn-danger btn-rounded btn-outline ml-3"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-success btn-outline mr-3"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                        assignAll();
                        }}
                    >
                        Assign
                    </button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
}

export default ModalWorkFlowAssign;
