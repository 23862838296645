import React from "react";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { HDFC_PAYMENT_GATEWAY } from "../AdmissionApiConstants";
import "./AppFeePayment.scss";

function Payment({ setLoading, basic_data, auth, form_data }) {
  const ref = useRef();

  const { state } = useLocation();

  console.log(form_data);
  const [paymentdata, setPaymentdata] = useState();

  const [amount, setAmount] = useState(1);

  const [name, setName] = useState(basic_data?.name);
  const [email, setEmail] = useState(basic_data?.email);
  const [usn, setUsn] = useState(basic_data?.sid);
  const [address, setAddress] = useState(basic_data?.address);
  const [city, setCity] = useState(basic_data?.city);
  const [stateName, setState] = useState("Gujarat");
  const [country, setCountry] = useState("India");
  const [pincode, setPincode] = useState("");

  let amt = 150;

  const handleSubmit = async () => {
    // setLoading(1);
    if (
      !amount ||
      !name ||
      !email ||
      !address ||
      !city ||
      !stateName ||
      !country ||
      !pincode
    ) {
      console.log(
        amount,
        name,
        email,
        address,
        city,
        stateName,
        country,
        pincode
      );
      toast.error("Please fill all the fields");
      return;
    }
    let x = [];
    x.push({
      college_id: sessionStorage.getItem("college"),
      program_id: sessionStorage.getItem("program"),
      department_id: sessionStorage.getItem("department"),
    });
    let obj = {
      type: "ADMISSION_APPLICATION_FEE_PAYMENT",
      payment_for: "ADMISSION APPLICATION FEE",
      data: x,
    };
    obj = JSON.stringify(obj);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      url: HDFC_PAYMENT_GATEWAY,
      data: {
        amount: 1,
        merchant_param1: name || basic_data?.name,
        merchant_param2: usn || basic_data?.user_id,
        merchant_param3: email || basic_data?.email,
        merchant_param4: "ADMISSION_APPLICATION_FEE_PAYMENT",
        merchant_param5: obj,
        billing_address: address,
        billing_city: city,
        billing_state: stateName,
        billing_country: country,
        billing_zip: pincode,
        merchant_faculty: sessionStorage.getItem("college"),
        transaction_date: new Date().toISOString().split("T")[0],
        department_id: sessionStorage.getItem("department"),
        program_id: sessionStorage.getItem("program"),
      },
      method: "post",
    };
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log(res.data);
        localStorage.setItem("Payment_details", JSON.stringify(res.data));
        setPaymentdata(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });
  };

  useEffect(() => {
    setPincode(state?.data?.pin);
    setAddress(state?.data?.address);
  }, [state]);

  useEffect(() => {
    if (paymentdata && paymentdata.encRequest && paymentdata.accessCode) {
      ref.current.click();
    }
  }, [paymentdata]);

  return (
    <div>
      <div
        className="register d-flex justify-content-center align-items-center p-5 mt-2"
        style={{ maxWidth: "1300px" }}
      >
        <div className="w-sm-50 w-100">
          {form_data?.payment_status != "Success" ? (
            <>
              <div style={{ paddingTop: "2rem" }}>
                <h3>Application Fee</h3>
                <div className="d-flex" style={{ marginBottom: "2rem" }}>
                  <div className="col-sm-3">
                    <label>Application Fee</label>
                    <input
                      value={amt}
                      readOnly={true}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "1rem" }}>
                <div className="col-md-12">
                  <h5>Pay Online</h5>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          value={name || basic_data?.name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          readOnly="true"
                          className="form-control"
                          placeholder="Enter Full Name here"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Enrollment Number</label>
                        <input
                          value={usn || basic_data?.user_id}
                          onChange={(e) => setUsn(e.target.value)}
                          type="text"
                          readOnly="true"
                          className="form-control"
                          placeholder="Starts with SUK..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        readOnly={true}
                        value={email || basic_data?.email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Enter Email here"
                      />
                      <p>
                        <strong className="text-danger"> Note: </strong> You
                        will recieve a email with the reciept of payment
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        value={address || basic_data.address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Enter Address here"
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label>City </label>
                        <input
                          value={city || basic_data.city}
                          onChange={(e) => setCity(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Enter City here"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>state</label>
                        <input
                          value={stateName}
                          onChange={(e) => setState(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Enter state here"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Country</label>
                        <input
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Enter Country here"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Pincode</label>
                        <input
                          value={pincode || basic_data?.pin}
                          onChange={(e) => setPincode(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Enter Pincode here"
                        />
                      </div>
                    </div>
                    <div
                      className=" float-end d-flex"
                      style={{ marginTop: "2rem" }}
                    >
                      <button
                        className="btn btn-danger"
                        onClick={() => handleSubmit()}
                      >
                        Pay Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            "Payment Has Been Done"
          )}
          <div>
            {paymentdata && paymentdata.encRequest && paymentdata.accessCode && (
              <form
                method="post"
                action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
              >
                <input
                  type="hidden"
                  id="encRequest"
                  name="encRequest"
                  value={paymentdata.encRequest}
                />
                <input
                  type="hidden"
                  name="access_code"
                  id="access_code"
                  value={paymentdata.accessCode}
                />
                <input
                  ref={ref}
                  type="submit"
                  className="btn btn-danger"
                  value="Pay Now"
                  style={{ display: "none" }}
                />
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
