import axios from "axios";
import React from "react";
import { useState } from "react";
import Nodata from "../../../Components/NoData/Nodata";
import { useReactToPrint } from "react-to-print";
import { REPORT_COLLEGE_WISE_FEE_DETAILS } from "../../../utils/Reports.apiConst";
import * as XLSX from "xlsx/xlsx.mjs";
import { useNavigate } from "react-router-dom";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { feeJsonData } from "../../../Data/jsonData/Fee/FeeJsonData";
import { useDownloadExcel } from "react-export-table-to-excel";
import { toast } from "react-toastify";
import { useRef } from "react";
import { college_title } from "../../../Data/main";

const ReportsBha4 = ({ setLoading }) => {

  const [user, setUser] = useState({
    date: new Date().toISOString().split("T")[0], // Set to current date
    to_transaction_date: new Date().toISOString().split("T")[0], // Set to current date
  });

  // const [user, setUser] = useState({
  //   date: "",
  //   to_transaction_date: "",
  // });

  const tableRef = useRef();

  const collegeList = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const [data, setData] = useState([]);

  const tableRef1 = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = async () => {
    if (!user.date || !user.to_transaction_date)
      return toast.error("From and to date is required");
    setLoading(1);
    const config = {
      method: "get",
      url: `${REPORT_COLLEGE_WISE_FEE_DETAILS}?transaction_date=${user?.date}&transaction_date_to=${user?.to_transaction_date}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const downloadExcel = () => {
    let json_data = [];

    Object.keys(data).map((i, key) => {
      const obj = {
        "College Name": collegeList?.find((s) => s?.id == i)?.name,
        Amount: data[i]?.reduce(
          (acc, current) => acc + Number(current.amount),
          0
        ),
      };
      json_data.push(obj);
    });
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    worksheet.wrire(0, 0, "THis is chk");
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `SUKALOL-Fee Details.xlsx`);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const getGrandTotal = () => {
    // console.log('ehre');
    var sum = 0;
    for (const key in data) {
      data[key].forEach((element) => {
        sum += Number(
          element?.newAmount?.reduce(
            (acc, current) => acc + Number(current.amount),
            0
          )
        );
        // console.log(element?.newAmount);
      });
    }
    return sum;
  };

  const handleSeparateAmount = (obj) => {
    const arr = [];
    for (const i of feeJsonData) {
      let sum = 0;
      for (const j of obj) {
        if (i.id == "Cash") {
          j?.newAmount?.forEach((el) => {
            sum += el.mode == "CASH" || el.mode == "Cash" ? el?.amount : 0;
          });
        } else {
          j?.newAmount?.forEach((el) => {
            sum += el.mode == i?.id ? el?.amount : 0;
          });
        }
      }
      const newObj = {
        id: i.id,
        sum: sum,
      };
      arr.push(newObj);
    }
    return arr;
  };

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">FEE REPORTS COLLEGE WISE</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">Select Criteria</div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.date}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">To Date</label>
                      <input
                        type="date"
                        name="to_transaction_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.to_transaction_date}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary rounded-pill"
                        onClick={getData}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="card-title">Fee Reports</div>
                  </div>
                  {/* <div
                    className="d-flex justify-content-center align-items-center px-2"
                    style={{ background: "white" }}
                  >
                    <img
                      src={"/assets/images/logoWide.png"}
                      alt=""
                      className=""
                      style={{ display: "n" }}
                      width={180}
                    />
                  </div> */}
                  <div className="col-md-6">
                    <button
                      className="btn float-right btn-primary rounded-pill"
                      onClick={onDownload}
                    >
                      EXCEL
                    </button>
                    <button
                      className="btn float-right mr-2 btn-primary rounded-pill"
                      onClick={handlePrint}
                    >
                      Pdf
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered" ref={tableRef}>
                        <tr>
                          <td colSpan={10}>{college_title}</td>
                        </tr>
                        <tr>
                          <td colSpan={10}>Date : {user?.date}</td>
                        </tr>
                        <tr>
                          <th>Sl.No</th>
                          <th>College Name</th>
                          <th colSpan={6}>Mode</th>
                          <th>Amount</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th></th>
                          {feeJsonData.map((i, key) => (
                            <th>{i.name}</th>
                          ))}
                          <th></th>
                        </tr>
                        {data && data?.length != 0 ? (
                          <>
                            {Object.keys(data).map((i, key) => (
                              <tr>
                                <td>{key + 1}</td>
                                <td>
                                  {collegeList?.find((s) => s?.id == i)?.name}
                                </td>

                                {/* {
                                    feeJsonData?.map((j) => {
                                      if (j.id == 'Cash') {
                                        let sum = 0;
                                        data[i].forEach(element => {
                                          if (element.type == "CASH") {
                                            sum += Number(element.newAmount?.reduce((acc,current)=>acc+Number(current.amount),0))
                                          }
                                        });
                                        return <td> {sum}</td>
                                      }
                                      else {
                                        let sum = 0;
                                        data[i].forEach(element => {
                                          if (element.type == j.id) {
                                            sum += Number(element.newAmount?.reduce((acc,current)=>acc+Number(current.amount),0))
                                            // console.log('yo');
                                          }
                                        });
                                        return <td> {sum}</td>
                                      }
                                    })
                                  } */}

                                {handleSeparateAmount(data[i])?.map(
                                  (i, key) => (
                                    <td>{i?.sum}</td>
                                  )
                                )}

                                <td>
                                  {data[i]?.reduce(
                                    (acc, current) =>
                                      acc +
                                      current?.newAmount?.reduce(
                                        (acc2, current2) =>
                                          acc2 + Number(current2.amount),
                                        0
                                      ),
                                    0
                                  )}
                                </td>
                              </tr>
                            ))}
                            {
                              <tr>
                                <th colSpan={2}>
                                  <strong>TOTAL</strong>
                                </th>
                                {feeJsonData.map((j, key) => {
                                  var sum = 0;
                                  return (
                                    <th>
                                      {Object.keys(data)?.forEach((i) => {
                                        data[i]?.forEach((el) => {
                                          el?.newAmount?.forEach((el2) => {
                                            if (j?.id == "Cash") {
                                              if (
                                                el2?.mode == "CASH" ||
                                                el2?.mode == "Cash"
                                              ) {
                                                sum =
                                                  Number(sum) +
                                                  Number(el2?.amount);
                                              }
                                              console.log(el2);
                                            } else if (j?.id == el2?.mode) {
                                              sum =
                                                Number(sum) +
                                                Number(el2?.amount);
                                            }
                                          });
                                        });
                                        // console.log(sum);
                                      })}
                                      {sum}
                                    </th>
                                  );
                                })}
                                <th colSpan={9}>
                                  <div>
                                    Grand Total : &nbsp;&nbsp;&nbsp;
                                    {getGrandTotal()
                                      ? "₹" +
                                        String(getGrandTotal())?.replace(
                                          /(\d)(?=(\d\d)+\d$)/g,
                                          "$1,"
                                        )
                                      : getGrandTotal()}
                                  </div>
                                </th>
                              </tr>
                            }
                          </>
                        ) : (
                          <tr>
                            <td colSpan={10}>
                              <Nodata />
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsBha4;
