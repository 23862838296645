import React, { useState, useEffect } from "react";
import { ANNOUNCEMENTS, LEAVE_LEAVE_TYPE } from "../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import "../../utils/input.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ASSET_EMPLOYEE_DOCUMENT } from "../../utils/AssetsReferenceTypes";
import { Select, Input, DatePicker, Form, Button, FormInstance} from "antd";
import { Checkbox } from 'antd';
import { getFileUrl } from "../../Helpers/Helpers";
import DocumentsModal from "../../modals/Students/DocumentsModal";
import moment from 'moment';

function ModalUpcomingEvent(props) {
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [attachment, setAttachment] = useState("");

  const [staff, setStaff] = useState(false);
  const [students, setStudents] = useState(false);
  const [parents, setParents] = useState(false);
  const [allColleges, setAllColleges] = useState(false);

  const [dayCount, setDayCount] = useState("");
  const [active, setActive] = useState(1);
  const [paid, setPaid] = useState(0);
  const [negative, setNegative] = useState(0);
  const [reason, setReason] = useState(1);
  const [paidLeaveAmount, setPaidLeaveAmount] = useState(0);

  const [modalTitle, setModalTitle] = useState("");
  const [modalLink, setModalLink] = useState("");

  //Function to clear the data
  const clearData = () => {
    setTitle("");
    setDesc("");
    setDayCount("");
    setActive(1);
    setPaid(0);
    setNegative(0);
    setReason(1);
    setPaidLeaveAmount(0);
    setAttachment(1);
    setFromDate("");
    setToDate("");
    setStaff(0);
    setParents(0);
    setStudents(0);
    setAllColleges(0);
  };

  const addAttachment = async (e, name) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Attachment`,
        e.target.value.split(".")[1],
        props.setLoading,
        e.target.files[0]
      );

      setAttachment(d);
    } catch (error) {
      console.log(error);
    }
  };

  //Function which triggers when user save changes
  //function hit the end points with put or post requests
  const SubmitModal = async () => {
    
  };

  const handleSubmit = async () => {

    if(!title) return toast.error("Please Enter Title");
    if(!fromDate) return toast.error("Please Enter From Date");
    if(!toDate) return toast.error("Please Enter To Date");

    const config = {
      method: "post",
      url: `${ANNOUNCEMENTS}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        title: title,
        description: desc,
        from_date: fromDate,
        to_date: toDate,
        attachment: attachment,
        createdBy: sessionStorage.getItem("employee_id"),
        college_id: props.collegeId,
        students: students ? 1 : 0,
        staff: staff ? 1 : 0,
        parents: parents ? 1 : 0,
        allColleges: allColleges ? 1 : 0,
        type: "event"
      },
    };
    props.setLoading(1);
    console.log("config - ", config);
    await axios(config)
      .then((res) => {
        props.setLoading(0);
        console.log(res);
        clearData();
        props.reloadData();
        toast.success("Event Created");
      })
      .catch((err) => {
        props.setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  }

  const handleEdit = async () => {

    if(!title) return toast.error("Please Enter Title");
    if(!fromDate) return toast.error("Please Enter From Date");
    if(!toDate) return toast.error("Please Enter To Date");

    if(!props.data.id) {
      return toast.error("Unable to get Event")
    }
    const config = {
      method: "put",
      url: `${ANNOUNCEMENTS}/${props.data.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        title: title,
        description: desc,
        from_date: fromDate,
        to_date: toDate,
        attachment: attachment,
        createdBy: sessionStorage.getItem("employee_id"),
        college_id: props.collegeId,
        students: students ? 1 : 0,
        staff: staff ? 1 : 0,
        parents: parents ? 1 : 0,
        allColleges: allColleges ? 1 : 0,
        type: "event"
      },
    };
    props.setLoading(1);
    await axios(config)
      .then((res) => {
        props.setLoading(0);
        console.log(res);
        clearData();
        props.reloadData();
        toast.success("Event Updated");
      })
      .catch((err) => {
        props.setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  }

  useEffect(() => {
    if (props.type === "edit") {
      if (props.data) {
        setTitle(props.data.title);
        setDesc(props.data.description);
        // setFromDate(moment(props.data.from_date));
        // setToDate(moment(props.data.to_date));
        setAttachment(props.data.attachment);
        setStudents(props.data.students);
        setStaff(props.data.staff);
        setAllColleges(props.data.allColleges);
        setParents(props.data.parents);
      }
    }

    if (props.type === "add") {
      clearData();
    }
  }, [props.data, props.type]);

  const modules = {
    placeholder: 'Write Detailed Description of Upcoming Event Here...',
  };

  return (
    <div className="ModalUpcomingEvent">
      <DocumentsModal title={modalTitle} img={modalLink} setLink={setModalLink} />
      <div
        className="modal fade"
        id="ModalUpcomingEvent"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Upcoming Event
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="inputBox">
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        required="required"
                      />
                      <span>Title *</span>
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="row">
                    <div className="col-md-12">
                        <p><b>From Date : </b></p>
                      <DatePicker
                        onChange={(date, dateString) => {
                          setFromDate(date);
                        }}
                        value={fromDate}
                        style={{ width: "90%" }}
                      />
                    </div>
                    <div className="col-md-12 mt-3">
                      <p><b>To Date : </b></p>
                      <DatePicker
                        onChange={(date, dateString) => {
                          setToDate(date);
                        }}
                        value={toDate}
                        style={{ width: "90%" }}
                      />
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <p><b>Announcement for :</b></p>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <Checkbox 
                          checked={staff} 
                          onChange={(e) => {
                            console.log(e.target.checked);
                            setStaff(e.target.checked);
                          }}
                        >
                          Staff
                        </Checkbox>
                      </div>
                      <div className="mt-4">
                        <Checkbox 
                          checked={students} 
                          onChange={(e) => {
                            console.log(e.target.checked);
                            setStudents(e.target.checked);
                          }}
                        >
                          Students
                        </Checkbox>
                      </div>
                      
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <Checkbox 
                          checked={allColleges} 
                          onChange={(e) => {
                            console.log(e.target.checked);
                            setAllColleges(e.target.checked);
                          }}
                        >
                          All Colleges
                        </Checkbox>
                      </div>
                      <div className="mt-4">
                        <Checkbox 
                          checked={parents} 
                          onChange={(e) => {
                            console.log(e.target.checked);
                            setParents(e.target.checked);
                          }}
                        >
                          Parents
                        </Checkbox>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <p><b>Description : </b></p>
                  <div>
                  <ReactQuill 
                    theme="snow" 
                    value={desc} 
                    onChange={(e) => {
                      setDesc(e);
                    }} 
                    placeholder="Write Detailed Description of Announcement Here..."
                  />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                <label>Attachment</label>
                <br />
                <div className="row">
                  <input
                    type="file"
                    name=""
                    className="form-control ml-2"
                    onChange={(e) => {
                      addAttachment(e, "attachment");
                    }}
                    style={{ width: "70%" }}
                  />
                  {attachment?.length > 0 ? (
                    <button
                      onClick={() => {
                        setModalLink(attachment);
                        setModalTitle("Announcement Attachment");
                      }}
                      data-toggle="modal"
                      data-target="#DocumentsModal"
                      className="btn btn-nex btn-sm my-1 ml-3"
                      title="View"
                    >
                      View
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              </div>
              <br />
              <div className="row d-flex justify-content-between px-2">
                <button
                  className="btn btn-danger btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearData();
                  }}
                >
                  Close
                </button>
                {props.type == "edit"
                ? 
                <button
                  className="btn btn-success btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleEdit}
                >
                  Update Changes
                </button>
                : 
                <button
                  className="btn btn-success btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleSubmit}
                >
                  Send
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalUpcomingEvent;
