import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { BsArrowDownCircleFill } from "react-icons/bs";
import img1 from "../../../assets/images/1.png";
import img2 from "../../../assets/images/3.png";
import img3 from "../../../assets/images/4.png";
import img4 from "../../../assets/images/5.png";
import img5 from "../../../assets/images/6.png";
import "./dashboard.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router/routerConfig";
import { HEADER } from "../AdmissionStorageConstats";
import {
  GET_PRE_ADMISSION,
  WITHDRAW_APPLICATION,
} from "../AdmissionApiConstants";
import Loader from "../../../Components/Loader/Loader";
import ProgressBar from "../ProgressBar/Progressbar";

function Dashboard() {
  const navigate = useNavigate();
  const locate = useLocation();
  const [basicInfo, setBasicInfo] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(0);
  const [show, setShow] = useState([]);
  // let show = [];
  const [flag, setFlag] = useState(false);
  const [withdrawalReason, setWithdrawalReason] = useState("");

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  function showWithdrawlReasonModal(app_no) {
    Swal.fire({
      title: "Withdrawal Confirmation",
      showCancelButton: true,
      confirmButtonColor: "#E34234",
      cancelButtonColor: "primary",
      confirmButtonText: "Withdraw",
      html: `<div>
              <label>Reason for Withdrawal<span style={{color: "red"}}>*</span></label>
              <br />
              <textarea type="text" id="reason" row="4" col="3"></textarea>
            </div>`,
      preConfirm: async () => {
        const selectElement = document.getElementById("reason");
        const reason = selectElement ? selectElement.value : "";

        if (!reason) {
          Swal.showValidationMessage(
            "Please enter reason for withdrawal of the application"
          );
        }
        return reason;
      },
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        updateData(app_no, result.value);
      }
    });
  }

  const selectRoute = (specialization, program, department, app_no) => {
    sessionStorage.setItem("college", specialization);
    sessionStorage.setItem("program", program);
    console.log("program -", program);
    sessionStorage.setItem("department", department);
    sessionStorage.setItem("application_no", app_no);

    switch (String(specialization) + "|" + program) {
      case "1111010|04":
        navigate(ROUTES.AdmissionPortal.Admission.bed);
        sessionStorage.setItem(HEADER, "BED Application Form");
        break;

      case "1111008|04":
        navigate(ROUTES.AdmissionPortal.Admission.UGAdmissionPharmacy);
        break;

      case "1111008|05":
        navigate(ROUTES.AdmissionPortal.Admission.PGAdmissionPharmacy);
        break;

      case "1111003|04":
        switch (department) {
          case 35:
            navigate(ROUTES.AdmissionPortal.Admission.bscNursing);
            sessionStorage.setItem(HEADER, "BSC Nursing Application Form");
            break;
          case 36:
            navigate(ROUTES.AdmissionPortal.Admission.AdmissionPostBasicBscNur);
            sessionStorage.setItem(HEADER, "Post Basic Bsc Application Form");
            break;
          default:
            navigate(ROUTES.AdmissionPortal.Admission.AdmissionPostBasicBscNur);
            sessionStorage.setItem(HEADER, "Post Basic Bsc Application Form");
            break;
        }
        break;

      case "1111011|04":
        navigate(ROUTES.AdmissionPortal.Admission.bsc);
        sessionStorage.setItem(HEADER, "BSC Application Form");
        break;

      case "1111005|04":
      case "1111005|05":
        navigate(ROUTES.AdmissionPortal.Admission.commerce);
        sessionStorage.setItem(HEADER, "Commerce Application Form");
        break;

      case "1111003|02":
        navigate(ROUTES.AdmissionPortal.Admission.gnm);
        sessionStorage.setItem(HEADER, "GNM Application Form");
        break;

      case "1111002|04":
      case "1111002|05":
        console.log("here");
        navigate(ROUTES.AdmissionPortal.Admission.law);
        sessionStorage.setItem(HEADER, "LAW Application Form");
        break;

      case "1111003|05":
        navigate(ROUTES.AdmissionPortal.Admission.AdmissionMscNursing);
        sessionStorage.setItem(HEADER, "MSC Nursing Application Form");
        break;

      case "1111011|05":
        navigate(ROUTES.AdmissionPortal.Admission.msc);
        sessionStorage.setItem(HEADER, "MSC Application Form");
        break;

      // case "1111007|04":
      //   navigate(ROUTES.AdmissionPortal.Admission.homeopathy);
      //   sessionStorage.setItem(HEADER, "B.H.M.S Application Form");
      //   break;
      case "1111007|04":
        navigate(ROUTES.AdmissionPortal.Admission.ayurveda);
        sessionStorage.setItem(HEADER, "B.A.M.S Application Form");
        break;
      case "1111007|05":
        navigate(ROUTES.AdmissionPortal.Admission.ayurvedaPG);
        sessionStorage.setItem(HEADER, "PG Ayurveda Application Form");
        break;
    }
  };

  const calculateCompletedPer = (total, completed) => {
    let x = (completed / total) * 100;
    return x;
  };

  function isStringified(str) {
    try {
      return JSON.parse(str);
    } catch (err) {
      return str;
    }
  }

  console.log("locate.state -", locate?.state);

  const getData = async () => {
    setLoading(1);
    var config = {
      method: "get",
      url: `${GET_PRE_ADMISSION}?phone=${
        locate.state?.phone
          ? locate?.state?.phone
          : sessionStorage.getItem("phone")
      }`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log(res.data.data);
        var total = Object.keys(res.data.data?.basic_info[0]).length - 2;
        var completed = 0;
        Object.values(res.data.data?.basic_info[0])?.map((item, key) => {
          let x = isStringified(item);
          if (x?.length > 0) {
            if (x[0]?.link?.length > 0) {
              completed++;
            }
          } else if (item != null && item) {
            completed++;
          }
        });
        res.data.data.basic_info[0].completed_per = calculateCompletedPer(
          total,
          completed - 2
        ).toFixed(2);
        setBasicInfo(res.data.data.basic_info);
        res.data.data.applications?.forEach((item, key) => {
          total = Object.keys(item).length - 2;
          completed = 0;
          Object.values(item)?.map((item1, key) => {
            if (item1 != null && item1) {
              completed++;
            }
          });
          item.completed_per = calculateCompletedPer(
            total,
            completed - 2
          ).toFixed(2);
        });
        setData(res.data.data.applications);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  const updateData = async (i, reason) => {
    let usn = sessionStorage.getItem("temp_usn");
    var config = {
      method: "put",
      url: `${WITHDRAW_APPLICATION}/${usn}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        data: {
          status: "WITHDRAWN",
          reason_for_withdrawl: reason,
        },
        application_no: i,
      },
    };
    console.log(config);
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log(res);
        toast.success("Application withdrawl successfull");
        getData();
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="container mt-4"
      // style={{ backgroundColor: "white" }}
    >
      <Loader loading={loading} />
      <ToastContainer autoClose={2000} />
      <div>
        <h2>Welcome {basicInfo[0]?.name}</h2>
        <br />
        <h6 className="text-dark">
          You have {data?.filter((s) => s.status == "PENDING")?.length || 0}{" "}
          pending{" "}
          {(data?.filter((s) => s.status == "PENDING")?.length || 0) <= 1
            ? "application"
            : "applications"}
        </h6>
      </div>
      <div className="main-content mt-2">
        <div className="page-content" style={{ backgroundColor: "white" }}>
          <div className="container-fluid">
            {data &&
              data?.map((item, key) => {
                return (
                  <div className="card shadow-sm mt-2">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table nowrap no-border">
                          <thead>
                            <tr style={{ borderBottomWidth: "0px" }}>
                              <th
                                width="12%"
                                style={{ borderBottomWidth: "0px" }}
                              >
                                Application No.
                              </th>
                              <th
                                width="50%"
                                style={{ borderBottomWidth: "0px" }}
                              >
                                Program
                              </th>
                              <th
                                width="10%"
                                style={{ borderBottomWidth: "0px" }}
                              >
                                Applied On
                              </th>
                              <th
                                width="8%"
                                style={{ borderBottomWidth: "0px" }}
                              >
                                Status
                              </th>
                              <th
                                style={{
                                  borderBottomWidth: "0px",
                                }}
                                className="text-center"
                                width="12%"
                              >
                                Action
                              </th>
                              <th
                                style={{
                                  borderBottomWidth: "0px",
                                }}
                              >
                                Details
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ borderBottomWidth: "0px" }}>
                                {item?.app_no}
                              </td>
                              <td style={{ borderBottomWidth: "0px" }}>
                                {item?.program_name} - {item?.college_name}-{" "}
                                {item?.dept_name}
                              </td>
                              <td style={{ borderBottomWidth: "0px" }}>
                                {formatDate(item?.applied_on.split("T")[0])}
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: "0px",
                                }}
                              >
                                <span
                                  className="px-2 py-1 text-white bordered"
                                  style={{
                                    backgroundColor:
                                      item?.status == "PENDING"
                                        ? "#FFC000"
                                        : item?.status == "WITHDRAWN"
                                        ? "#E34234"
                                        : "green",
                                    borderRadius: "0.3rem",
                                    display: "inline-block",
                                    fontSize: "0.8em",
                                  }}
                                >
                                  {item?.status}
                                </span>
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  borderBottomWidth: "0px",
                                }}
                              >
                                {item?.status != "WITHDRAWN" ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-outline-success mb-2"
                                      onClick={() => {
                                        selectRoute(
                                          item?.college_id,
                                          item?.program_id,
                                          item?.department_id,
                                          item?.app_no
                                        );
                                      }}
                                    >
                                      Resume
                                    </button>
                                    <br />
                                    <button
                                      className="btn btn-sm btn-outline-danger"
                                      // style={{ marginLeft: "1rem" }}
                                      onClick={() => {
                                        showWithdrawlReasonModal(item?.app_no);
                                      }}
                                    >
                                      Withdraw
                                    </button>
                                  </>
                                ) : (
                                  <p className="text-primary">No Action</p>
                                )}
                              </td>
                              <td
                                style={{
                                  borderBottomWidth: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setFlag((flag) => !flag);
                                  let x = [];
                                  x.push({
                                    id: key + 1,
                                    flag: true,
                                  });
                                  if (show.length == 0) {
                                    setShow(x);
                                  } else {
                                    if (show?.find((s) => s.id == key + 1)) {
                                      show?.forEach((item) => {
                                        if (item.id == key + 1) {
                                          item.flag = !item.flag;
                                        }
                                      });
                                    } else {
                                      let y = [...show, ...x];
                                      setShow(y);
                                    }
                                  }
                                }}
                              >
                                {show &&
                                show?.filter(
                                  (s) => s.id == key + 1 && s.flag == true
                                )?.length == 0 ? (
                                  <>
                                    <BsArrowRightCircleFill
                                      style={{ height: "25px", width: "25px" }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <BsArrowDownCircleFill
                                      style={{ height: "25px", width: "25px" }}
                                    />
                                  </>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {show.length > 0 &&
                      show?.filter((s) => s.id == key + 1 && s.flag == true)
                        ?.length > 0 ? (
                        <ProgressBar
                          value={0}
                          basic_percent={basicInfo[0].completed_per}
                          fee_status={
                            item?.payment_status == "Success"
                              ? "PAID"
                              : "PENDING"
                          }
                          form_percent={item?.completed_per}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              })}
            <div className="d-flex col-lg-12 mt-4">
              <div className="col-lg-8 ml-4">
                <h4>How to Apply</h4>
                <div className="card shadow-sm">
                  <div className="card-body" style={{ padding: "2rem" }}>
                    <div className="d-flex">
                      <div className="col-lg-2">
                        <img src={img1} />
                      </div>
                      <div className="col-lg-10">
                        <p>Choose a Program</p>

                        <p>
                          Click on the New Application and choose your preferred
                          program.
                        </p>
                      </div>
                    </div>
                    <br />

                    <hr />
                    <div className="d-flex mt-4">
                      <div className="col-lg-2">
                        <img src={img2} />
                      </div>
                      <div className="col-lg-12">
                        <p>Primary Information</p>
                        <p>
                          Fill out your name, address, your father’s number,
                          etc.
                        </p>
                      </div>
                    </div>
                    <br />

                    <hr />

                    <div className="d-flex mt-4">
                      <div className="col-lg-2">
                        <img src={img3} />
                      </div>
                      <div className="col-lg-12">
                        <p>Educational Information</p>

                        <p>
                          Fill in your SSC, HSC, Undergraduate or Postgraduate
                          details.
                        </p>
                      </div>
                    </div>
                    <br />
                    <hr />

                    <div className="d-flex mt-4">
                      <div className="col-lg-2">
                        <img src={img4} />
                      </div>
                      <div className="col-lg-12">
                        <p>Upload Documents</p>

                        <p>
                          Upload documents like Adhar card, SSC result, HSC
                          result and others mentioned.
                        </p>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <div className="d-flex mt-4">
                      <div className="col-lg-2">
                        <img src={img5} />
                      </div>
                      <div className="col-lg-12">
                        <p>Schedule Campus Visit</p>

                        <p>
                          You can select the date and time of your preference
                          and schedule the campus visit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4"
                style={{ marginLeft: "2rem", width: "24.4rem" }}
              >
                <h4>Reach Your Counsellor</h4>
                <div className="card shadow-sm">
                  <div className="card-body" style={{ padding: "2rem" }}>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
