import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import * as XLSX from "xlsx/xlsx.mjs";
import AdvanceFeeReciept from "../../Accounts/FeeCollection/AdvancePayFeeCollection";
import { useReactToPrint } from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import {
  STUDENT_DETAILS1,
  STUDENT_SESSION,
  STUDENT_ADVANCE_PAY,
} from "../../../utils/apiConstants";
import {
  LOCAL_DEPARTMENT,
  LOCAL_COLLEGE,
} from "../../../utils/LocalStorageConstants";

function StudentDetails1({ setLoading, collegeId }) {
  const locate = useLocation();

  const printRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => printRef.current,
  });

  const [adv, setAdv] = useState([]);
  const [data, setData] = useState([]);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const tableRef = useRef();

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  useEffect(() => {
    setDepartment(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == collegeId
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);

  const [classOpt, setClassOpt] = useState([]);

  const [sectionOpt, setSectionOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [faculty, setFaculty] = useState("");

  const [currentclass, setCurrentClass] = useState("");

  const [currentSemester, setCurrentSemester] = useState("");

  const [currentSection, setCurrentSection] = useState("");

  const [status, setStatus] = useState("ACTIVE");

  const [session, setSession] = useState("");

  const [subdata, setSubdata] = useState([]);

  const [a, setA] = useState([]);

  const getData = async () => {
    if (!faculty || !currentclass || !session)
      return toast.error("Mandatory fields are required");
    setLoading(1);
    const config = {
      method: "get",
      url:
        STUDENT_DETAILS1 +
        `?college_id=${collegeId}&department_id=${faculty}&class_id=${currentclass}&section_id=${currentSection}&semester_id=${currentSemester}&session_id=${session}&status_student=${status}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("Data Main", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some Error Occured");
      });
    setLoading(0);
  };

  const getAllData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data2, data3, data4] = await Promise.all([
      await axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),

      await axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),

      await axios({
        ...config,
        url: `${ACADEMICS_ADD_SECTION}?college_id=${collegeId}`,
      })
        .then((res) => {
          setSectionOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),

      await axios({
        ...config,
        url: STUDENT_ADVANCE_PAY,
      })
        .then((res) => {
          console.log(res.data.data);
          setAdv(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),
    ]);
  };

  useEffect(() => {
    if (locate?.state) {
      setCurrentClass(locate.state?.class);
      setCurrentSection(locate.state?.section);
      setCurrentSemester(locate.state?.sem);
      setSession(locate.state?.session);
      setFaculty(locate.state?.depart);
    }
  }, [locate?.state]);

  useEffect(() => {
    getAllData();
  }, []);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Student Details",
    sheet: "Student_Details",
  });

  const handleDepartmentChange = (selectedOption) => {
    setFaculty(selectedOption?.value);
  };

  const handleSessionChange = (selectedOption) => {
    setSession(selectedOption?.value);
  };

  const handleClassChange = (selectedOption) => {
    setCurrentClass(selectedOption?.value);
    
    // Clear the selected semester when class changes
    setCurrentSemester(null); // or setCurrentSemester('')
  };

  const handleSemesterChange = (selectedOption) => {
    setCurrentSemester(selectedOption?.value);

    setCurrentSection(null);
  };

  const handleSectionChange = (selectedOption) => {
    setCurrentSection(selectedOption?.value);
  };

  return (
    <div className="StudentDetails">
      <div style={{ display: "none" }}>
        <div ref={printRef}>
          <AdvanceFeeReciept
            mainData={subdata?.data}
            studentInfo={subdata?.studentInfo}
            data={a}
            collegeId={subdata?.studentInfo?.college_id}
            collegeOpt={collegeOpt}
            classData={classOpt}
            class_id={subdata?.data?.class_id}
            departmentData={department}
          />
        </div>
      </div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Students Details</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Students Information</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Students Details
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Department <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <select
                            name="faculty"
                            id="section"
                            className="form-control"
                            value={faculty}
                            onChange={(e) => {
                              setFaculty(e.target.value);
                            }}
                          >
                            <option value="" selected>
                              ALL
                            </option>
                            {department?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select> */}

                          <Select
                            options={[
                            ...department
                              ?.filter((s) => s.college_id == collegeId)
                              ?.map((i) => ({ value: i.id, label: i.name })),
                            ]}
                            value={
                              faculty
                                ? {
                                  value: faculty,
                                  label: department?.find((i) => i.id == faculty)?.name,
                                }
                                : null
                            }
                            onChange={handleDepartmentChange}
                            placeholder="Select Department"
                          />

                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">Session</label>
                          {/* <select
                            className="form-control"
                            value={session}
                            onChange={(e) => {
                              setSession(e.target.value);
                            }}
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option value={i?.id}>{i?.name}</option>
                            ))}
                          </select> */}

                        <Select
                          options={sessionOpt?.map((i) => ({ value: i.id, label: i.name }))}
                          value={
                            session
                              ? {
                                value: session,
                                label: sessionOpt?.find((i) => i.id == session)?.name,
                              }
                              : null
                          }
                          onChange={handleSessionChange}
                          placeholder="Select Session"
                        />
                        
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          {/* <select
                            className="form-control"
                            value={currentclass}
                            onChange={(e) => {
                              setCurrentClass(e.target.value);
                            }}
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter((s) => s?.department_id == faculty)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select> */}

                          <Select
                            options={[
                              ...classOpt
                              ?.filter((s) => s?.department_id == faculty)
                              ?.map((i) => ({ value: i.id, label: i.name })),
                            ]}
                            value={
                              currentclass
                                ? {
                                  value: currentclass,
                                  label: classOpt?.find((i) => i.id == currentclass)?.name,
                                }
                                : null
                            }
                            onChange={handleClassChange}
                            placeholder="Select Class"
                          />

                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          {/* <select
                            className="form-control"
                            value={currentSemester}
                            onChange={(e) => {
                              setCurrentSemester(e.target.value);
                            }}
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == currentclass)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select> */}

                          <Select
                            options={[
                              ...semesterOpt
                                ?.filter((s) => s.class_id == currentclass)
                                ?.map((i) => ({ value: i.id, label: i.name })),
                            ]}
                            value={
                              currentSemester
                                ? {
                                  value: currentSemester,
                                  label: semesterOpt?.find((i) => i.id == currentSemester)?.name,
                                }
                                : null
                            }
                            onChange={handleSemesterChange}
                            placeholder="Select Semester"
                          />

                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">Section</label>
                          {/* <select
                            className="form-control"
                            value={currentSection}
                            onChange={(e) => {
                              setCurrentSection(e.target.value);
                            }}
                          >
                            <option value="">Select Section</option>
                            {sectionOpt
                              ?.filter((s) => s.semester_id == currentSemester)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select> */}

                          <Select
                            options={[
                              ...sectionOpt
                                ?.filter((s) => s.semester_id == currentSemester)
                                ?.map((i) => ({ value: i.id, label: i.name })),
                            ]}
                            value={
                              currentSection
                                ? {
                                  value: currentSection,
                                  label: sectionOpt?.find((i) => i.id == currentSection)?.name,
                                }
                                : null
                            }
                            onChange={handleSectionChange}
                            placeholder="Select Section"
                          />

                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">Status</label>
                          <select
                            className="form-control"
                            value={status}
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >
                            <option value="">Select Status</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="">Select Student Fields</label>
                        </div>
                      </div> */}
                    </div>
                    <div className="row float-right">
                      <button
                        className="btn btn-primary btn-rounded"
                        type="submit"
                        name="submit"
                        onClick={getData}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">Students Details</h4>
                      </div>
                      <div className="col-md-8">
                        <span className="float-right">
                          <button
                            className="btn btn-primary mx-2"
                            onClick={onDownload}
                          >
                            Excel
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <hr />
                      <table
                        id="datatable"
                        className="table table-bordered nowrap table-hover"
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th>Sl No</th>
                            <th>Enrollment No.</th>
                            <th>Name</th>
                            <th>Department</th>
                            <th>Class</th>
                            <th>Semester</th>
                            <th>Section</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Date of Birth</th>
                            <th>Category</th>
                            <th style={{ width: "10rem" }}>Gender</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data.length !== 0 ? (
                            data?.map((i, key) => (
                              <tr>
                                <td>{key + 1}</td>
                                <td>{i?.user_id}</td>
                                <td>{i?.name}</td>
                                <td>
                                  {
                                    department?.filter(
                                      (s) => s.id == faculty
                                    )[0]?.name
                                  }
                                </td>
                                <td>
                                  {
                                    classOpt?.filter(
                                      (s) => s.id == currentclass
                                    )[0]?.name
                                  }
                                </td>
                                <td>
                                  {
                                    semesterOpt?.filter(
                                      (s) => s.id == currentSemester
                                    )[0]?.name
                                  }
                                </td>
                                <td>
                                  {
                                    sectionOpt?.filter(
                                      (s) => s.id == currentSection
                                    )[0]?.name
                                  }
                                </td>
                                <td>{i?.email}</td>
                                <td>{i?.phone}</td>
                                <td>{i?.dob?.split("T")[0]}</td>
                                <td>{i?.category}</td>
                                <td>{i?.gender}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan={13}>
                                <div align="center" className="text-danger">
                                  No data available in table <br /> <br />
                                  <img
                                    src="/assets/images/addnewitem.svg"
                                    width={150}
                                  />
                                  <br />
                                  <br />{" "}
                                  <span className="text-success bolds">
                                    <i className="fa fa-arrow-left" /> Add new
                                    record or search with different criteria.
                                  </span>
                                  <div />
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* end col */}
            </div>{" "}
            {/* end row */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDetails1;
