// Base Url

// export const BASE_URL = "http://localhost:4000";
export const BASE_URL = "https://nexcorpserver.nexenstial.org";

// export const BASE_URL = "https://umsbackend-production-214f.up.railway.app";

// export const BASE_URL =
//   process.env.REACT_APP_MODE == "development"
//     ? "http://localhost:4000"
//     : process.env.REACT_APP_SERVER_URL ;

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
///////////////////AWS FILE UPLOADER CONSTANTS//////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

export const AWS_URL_GENERATOR = `${BASE_URL}/api/asset/url`; //post Request

export const EMPLOYEE_BIO_IDS = `${BASE_URL}/api/hr/biometricIds`; // get

////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
///////////////////AWS FILE UPLOADER CONSTANTS//////////////////
///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

//Login
export const LOGIN = `${BASE_URL}/api/user/`;

export const RESET_PASSWORD = `${BASE_URL}/api/user/forgot`;

export const CONFIRM_PASSWORD = `${BASE_URL}/api/user/password`;

export const STUDENT_CONFIRM_PASSWORD = `${BASE_URL}/api/student/reset-password`;

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
////////////////////////////////HR////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

//////////////////
//////////////////
/////Dashboard////
//////////////////
//////////////////
export const DASHBOARD_HR = `${BASE_URL}/api/dashboard/hr`; //get put and post

export const DASHBOARD_REGISTRAR = `${BASE_URL}/api/dashboard/registrar`;

export const SUPERADMIN_DASHBOARD = `${BASE_URL}/api/dashboard/superadmin`;

export const MEDICAL_SUPERADMIN_DASHBOARD = `${BASE_URL}/api/dashboard/medical-superadmin`;
/////////////////
/////////////////
//employee//////
////////////////
////////////////

//Staff CRUD
export const EMPLOYEE = `${BASE_URL}/api/hr/employee`; //get put and post

export const QR_CODE = `${BASE_URL}/api/employee/generate-secret-key`; //get

export const TOOGLE_2FA = `${BASE_URL}/api/employee/toogle-2fa`; //get

export const VERIFY_TOTP = `${BASE_URL}/api/employee/verify-totp`; //post

export const EMPLOYEE_SQL = `${BASE_URL}/api/hr/employee-sql`; //get

export const EMPLOYEE_RESEND_CREDENTIALS = `${BASE_URL}/api/hr/resend-credentials`; //post

export const MAIL_EVENT_APPROVAL = `${BASE_URL}/api/hr/sendmail-eventapproval`; //post

export const EMPLOYEE_OTHER_DETAILS = `${BASE_URL}/api/employee/get-education-experience-document-publications`; //get

//employee profile progress
export const EMPLOYEE_PROGRESS = `${BASE_URL}/api/hr/employee-progress`; //get

export const EMPLOYEE1 = `${BASE_URL}/api/employee`; //get put and post

//BULK ADD EMPLOYEE
export const EMPLOYEE_ADD_BULK = `${BASE_URL}/api/hr/employee/bulk-add`; //add

export const EMPLOYEE_ADD_BULK_ENTITLEMENT = `${BASE_URL}/api/hr/employee/bulk-add-entitlement`; //add

//Staff Placement
export const EMPLOYEE_PLACEMENT = `${BASE_URL}/api/employee/placement`; //CRU

//Staff Employment Terms
export const EMPLOYEE_EMPLOYEMENT_TERMS = `${BASE_URL}/api/employee/employment-terms`; //CRU

//Staff Experience
export const EMPLOYEE_EXPERIENCE = `${BASE_URL}/api/employee/experience`; //CRU

//Staff Experience
export const EMPLOYEE_EDUCATION = `${BASE_URL}/api/employee/education`; //CRU

//Staff Experience
export const EMPLOYEE_LEGAL_DOCUMENTS = `${BASE_URL}/api/employee/legal-document`; //CRU

//Staff Training
export const EMPLOYEE_TRAINING = `${BASE_URL}/api/employee/training`; //CRU

//Staff Training
export const EMPLOYEE_PUBLICATION = `${BASE_URL}/api/employee/publication`; //CRU

//Delete Employee
export const EMPLOYEE_DELETE = `${BASE_URL}/api/employee/delete-employee`; //CRU

//Approver applications
export const LEAVE_APPROVER_APPLICATION = `${BASE_URL}/api/employee/leave-approvals`;

//Entitlements Employee
export const LEAVE_REVIEW_EMPLOYEE_SQL = `${BASE_URL}/api/employee/review-application-sql`;

/////////////////
/////////////////
//employer//////
////////////////
////////////////

export const HOSPITAL_ASSIGN = `${BASE_URL}/api/student/hospital-assign-students`;

//all Data

export const ALL_DATA = `${BASE_URL}/api/hr/data/getall`; //get

//COLLEGE Type
export const COLLEGE_TYPE = `${BASE_URL}/api/hr/collegetype`; //get , put and post

//Program
export const PROGRAM = `${BASE_URL}/api/hr/program`; //get , put and post

//Specialization
export const SPECIALIZATION = `${BASE_URL}/api/hr/specialization`; //get , put and post

//COLLEGEs
export const COLLEGES = `${BASE_URL}/api/hr/college`; //get , put and post

//COLLEGE Specialization
export const COLLEGE_SPECIALIZATION = `${BASE_URL}/api/hr/collegespecialization`; //get , put and post

//job Roles
export const JOB_POSITIONS = `${BASE_URL}/api/hr/jobroles`; //get, put and post

//Department
export const DEPARTMENTS = `${BASE_URL}/api/hr/department`; //get , put and post

//Trainer
export const TRAINER = `${BASE_URL}/api/hr/trainer`; //get , put and post

//Documetn Category
export const DOCUMENT_CATEGORY = `${BASE_URL}/api/hr/document-category`;

//custom approver
export const CUSTOM_APPROVER = `${BASE_URL}/api/hr/approver`;

export const EVENT_CUSTOM_APPROVER = `${BASE_URL}/api/hr/eventapprove`;

/////////////////
/////////////////
//Attendance/////
/////////////////
/////////////////

//get and add Attendance
export const EMPLOYEE_ATTENDANCE = `${BASE_URL}/api/employee/attendance`; //CRU

export const EMP_ATTENDANCE = `${BASE_URL}/api/hr/emp-attendance`; // get

//////////////////////////
//////////////////////////
//Documeny Management/////
//////////////////////////
//////////////////////////

//Document Workfolw
export const EMPLOYEE_DOCUMENT_APPROVAL_WORKFLOW = `${BASE_URL}/api/hr/document-workflow`; //CRU

//Document Workfolw
export const EMPLOYEE_DOCUMENT_MANAGEMENT = `${BASE_URL}/api/employee/document`; //CRU

//////////////////////////
//////////////////////////
// //Leave Management/////
//////////////////////////
//////////////////////////

//Leave Get All
export const LEAVE_GET_ALL = `${BASE_URL}/api/hr/leavemanagement`;

//Earning Poolicy
export const LEAVE_EARNING_POLICY = `${BASE_URL}/api/hr/earning-policy`;

//Earning Poolicy
export const LEAVE_LEAVE_TYPE = `${BASE_URL}/api/hr/leave-type`;

//Get Employee entitlements
export const LEAVE_ENTITLEMENT_EMPLOYEE = `${BASE_URL}/api/hr/employees-with-entitlements`;

//Holiday Type
export const LEAVE_HOLIDAY_TYPE = `${BASE_URL}/api/hr/holiday-type`;

//Holiday List
export const LEAVE_HOLIDAY_LIST = `${BASE_URL}/api/hr/holiday`;

export const HOLIDAY_CALENDER = `${BASE_URL}/api/hr/calender`;

//Entitlements
export const LEAVE_ENTITLEMENT = `${BASE_URL}/api/employee/entitlement`;

//Entitlements report
export const LEAVE_ENTITLEMENT_REPORT = `${BASE_URL}/api/employee/entitlement-report`;

//Entitlements report
export const LEAVE_LEAVE_REPORT = `${BASE_URL}/api/employee/leave-detail-report`;

//Entitlements Employee
export const LEAVE_REVIEW_EMPLOYEE = `${BASE_URL}/api/employee/review-application`;

//Applications
export const LEAVE_APPLICATION = `${BASE_URL}/api/employee/leave-application`;

//////////////////////////
//////////////////////////
// //Leave Management/////
//////////////////////////
//////////////////////////

//Team Discussions
export const TEAM_DISCUSSION = `${BASE_URL}/api/hr/team-discussion`;

export const TEAM_CHATS = `${BASE_URL}/api/hr/discussion-reply`;

//Team Announcements
export const TEAM_ANNOUNCEMENTS = `${BASE_URL}/api/hr/team-announcement`;

//Document Sharing
export const TEAM_DOC_SHARING = `${BASE_URL}/api/hr/team-document`;

//////////////////////////
//////////////////////////
///Pay Roll Management////
//////////////////////////
//////////////////////////

//Payscale
export const PAYROLL_PAYSCALE = `${BASE_URL}/api/hr/payroll/payscale`;

//Payscale
export const PAYROLL_SALARY = `${BASE_URL}/api/hr/payroll/salary`;

//Pay ROll Get ALl
export const PAYROLL_GETALL = `${BASE_URL}/api/hr/payroll/getAll`;

//Pay ROll Salary Adjustment
export const PAYROLL_SALARY_ADJUST = `${BASE_URL}/api/employee/salary-adjustment`;

//Pay ROll Pay Slip
export const PAYROLL_PAY_SLIP = `${BASE_URL}/api/employee/payslip`;

//Pay ROll Bonus
export const PAYROLL_BONUS = `${BASE_URL}/api/hr/payroll/bonus`;

//Employees Payroll Bonus
export const PAYROLL_EMP_BONUS = `${BASE_URL}/api/hr/payroll/employee-with-bonus`;

//Pay ROll Earning
export const PAYROLL_EARNING = `${BASE_URL}/api/hr/payroll/earning`;

//Employees Payroll Earning
export const PAYROLL_EMP_EARNING = `${BASE_URL}/api/hr/payroll/employee-with-earning`;

//Pay ROll Deduction
export const PAYROLL_DEDUCTION = `${BASE_URL}/api/hr/payroll/deduction`;

//Employees Payroll Deduction
export const PAYROLL_EMP_DEDUCTION = `${BASE_URL}/api/hr/payroll/employee-with-deduction`;

//Pay ROll Statutory Contribution
export const PAYROLL_STATUTORY_CONTRIBUTION = `${BASE_URL}/api/hr/payroll/statutorycontribution`;

//Pay ROll EMPLOYEE PAYSLIP
export const PAYROLL_EMPLOYEE_PAYSLIP = `${BASE_URL}/api/employee/getpayslip`;

export const PAYROLL_EMPLOYEES = `${BASE_URL}/api/hr/payroll/employees-payslip`;

//Employee Earnings
export const EMPLOYEE_EARNING = `${BASE_URL}/api/hr/payroll/employee-earning`;

//Employee Earnings
export const EMPLOYEE_BONUS = `${BASE_URL}/api/hr/payroll/employee-bonus`;

//Employee Earnings
export const EMPLOYEE_DEDUCTION = `${BASE_URL}/api/hr/payroll/employee-deduction`;

//Employee Earnings
export const PAYROLL_MONTHWISE_PAYSLIPS = `${BASE_URL}/api/hr/payroll/get-monthwise-payslips`;

//Employee Pay Payslip
export const EMPLOYEE_PAY_PAYSLIP = `${BASE_URL}/api/hr/payroll/pay-payslip`;

//Employee Pay Payslip
export const EMPLOYEE_GENERATED_PAYSLIP = `${BASE_URL}/api/hr/payroll/generated-payslip`;

//Employee Pay Payslip
export const EMPLOYEE_REDUCE_ATTENDANCE = `${BASE_URL}/api/hr/payroll/deduct-attendance`;

export const EMP_LATEIN_EARLOUT_UPDATE = `${BASE_URL}/api/hr/biometric-latein-update`; // put

export const ADD_BIOMETRIC_ATTENDANCE = `${BASE_URL}/api/staff-app/addBiometricAttendance`;

export const EMPLOYEE_ADD_BIOMETRIC_BULK = `${BASE_URL}/api/employee/bulk-biometricData-add`;

//Employee Payroll Leave Data
export const PAYROLL_EMP_LEAVE = `${BASE_URL}/api/hr/payroll/employee-leave-data`;

//Workflow
export const HR_WORKFLOW = `${BASE_URL}/api/hr/approval-workflow`;
export const EVENT_WORKFLOW = `${BASE_URL}/api/hr/event-workflow`;
export const EVENT_WORKFLOW_STATUS = `${BASE_URL}/api/hr/event-workflows`;

export const EVENTWORKFLOW = `${BASE_URL}/api/hr/event-work-flow`;

//assign workflow
export const HR_ASSIGN_WORKFLOW = `${BASE_URL}/api/hr/assign-employees-workflow`;

export const ASSIGN_HOSPITAL = `${BASE_URL}/api/employee/assign-hospital`;

//////////////////////////
//////////////////////////
// //Interview Process////
//////////////////////////
//////////////////////////

export const JOB_OPENINGS = `${BASE_URL}/api/hr/job-openings`; //get put post

export const JOB_APPLICATIONS = `${BASE_URL}/api/hr/job-application`; //post

//All users mail
export const EMPLOYEE_EMAIL = `${BASE_URL}/api/employee/user-emails`; //R

export const EMPLOYEE_FROM_USERS = `${BASE_URL}/api/hr/users`; //R
///////////////////////////////////
///////////////////////////////////
///////////////////////////////////
///////////////////////////////////
///////////////////////////////////
/////////////Employee/////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////

//Basic Data
export const EMPLOYEE_DETAILS_BASIC = `${BASE_URL}/api/employee/details`;

export const EMPLOYEE_ALL = `${BASE_URL}/api/employee/getAll`;

export const ALL_USER = `${BASE_URL}/api/employee/userstable`;

////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
///////////Student//////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////

//Admission Enquirry
export const STUDENT_ADMISSION_ENQUIRRY = `${BASE_URL}/api/frontOffice/admission-enquiry`;

export const ALL_ADMISSION_FORMS = `${BASE_URL}/api/admission/all-admission-forms`;

export const STUDENT_ADMISSION_REPORT = `${BASE_URL}/api/student/admissions/getStudentAdmissions`;

//STUDENTS
export const STUDENT_ADMISSION_DETAILS = `${BASE_URL}/api/admission`;

export const STUDENT_ADMISSION = `${BASE_URL}/api/student`;

export const STUDENT_GENDER = `${BASE_URL}/api/student/updategender`;

export const STUDENT_SESSION_UPDATE = `${BASE_URL}/api/student/session/x/newUpdate`;

export const STUDENT_ADMISSION_BULK = `${BASE_URL}/api/student/bulkadd`;

export const STUDENT_QUESTION_UPLOAD_BULK = `${BASE_URL}/api/academics/bulkquestionsUpload`;

export const STUDENT_SESSION = `${BASE_URL}/api/student/session/all`;

export const STUDENT_ADVANCE_PAY = `${BASE_URL}/api/student/getAdvancePay`;

export const STUDENTS_LIST = `${BASE_URL}/api/student`;

export const STUDENT_DETAILS = `${BASE_URL}/api/student`;

export const STUDENT_DETAILS1 = `${BASE_URL}/api/student/getStudent/BySequelize`;

export const STUDENT_DETAILS2 = `${BASE_URL}/api/student/getStudent/BySequelize2`;

export const STUDENT_COURSE_REGISTRATION = `${BASE_URL}/api/student/registerCourse`;

///////////////////////////////////////////
///////////////////////////////////////////
/////////////PHD Admission////////////////
///////////////////////////////////////////
///////////////////////////////////////////

export const PHD_ADMISSIONS = `${BASE_URL}/api/admission/phdadmissions`;

//Get Student Id's
export const GET_STUDENT_BY_ID = `${BASE_URL}/api/admission/StudentIds`;

export const GET_STUDENT_BY_ID1 = `${BASE_URL}/api/admission/StudentIds1`;

export const UPDATE_STUDENT_BY_ID = `${BASE_URL}/api/admission/updateStudentIds`;

export const GET_STUDENT_ABC_INFO = `${BASE_URL}/api/student/abc/info`;

export const ADVANCE_PAYMENT_DATE_WISE = `${BASE_URL}/api/student/fee/getadvancePayDatewise`;

///////////////////////////////////////////
////////////////PROCTORING////////////////
/////////////////////////////////////////

export const GET_STUDENTS = `${BASE_URL}/api/proctoring/get-students`;
export const GET_STUDENTS2 = `${BASE_URL}/api/proctoring/get-students2`;
export const ASSIGN_PROCTOR = `${BASE_URL}/api/proctoring/assign`;
export const PROCTEE_LIST = `${BASE_URL}/api/proctoring/get-proctees`;
export const CREATE_MEETING = `${BASE_URL}/api/proctoring/create-proctoring-meeting`;
export const GET_PROCTEE_BY_PROCTORING_ID = `${BASE_URL}/api/proctoring/get-proctee`;
export const CREATE_PROCTORING_MEETING = `${BASE_URL}/api/proctoring/create-proctoring-meeting`;
export const GET_PROCTORING_MEETINGS = `${BASE_URL}/api/proctoring/getproctoringMeetings`;

export const GET_PROCTORING_DETAILS = `${BASE_URL}/api/proctoring/getProctoringDetails`;

//StudentPortal

export const REDIRECT = `${BASE_URL}/api/student/portal/redirect-login`;

export const Login_Student = `${BASE_URL}/api/student/login`;

console.log("Login_Student -", Login_Student);

export const RESET_PASSWORD_STUDENT = `${BASE_URL}/api/student/reset-password`;

export const STUDENT_ADMISSIONS = `${BASE_URL}/api/student`;

export const FORGOT_PASSWORD = `${BASE_URL}/api/student/forgot-password`;

export const COURSE_REGISTRATION_STUDENT = `${BASE_URL}/api/student/registerCourse`;

///////////////////////////////////////////
//////////////// ANNOUNCEMENTS ///////////
/////////////////////////////////////////

export const ANNOUNCEMENTS = `${BASE_URL}/api/hr/announcements`;
// export const VIEWANNOUNCEMENT=  `${BASE_URL}/api/hr/viewSelectedAnnouncement`;
//HDFC Payment Gateway
export const HDFC_PAYMENT_GATEWAY = `${BASE_URL}/api/payment/hdfc`;

export const PAYMENT_DETAILS = `${BASE_URL}/api/payment/createPaymentDetails`;

export const GET_PAYMENT_DETAILS = `${BASE_URL}/api/payment/getPaymentDetails`;

// Add Event

export const ADD_EVENT = `${BASE_URL}/api/hr/addevent`;

// add event glimps

export const ADD_EVENT_GLIMPS = `${BASE_URL}/api/hr/eventglimps`;

//////////student details//////////

export const STUDENT_SESSION_BY_NAME = `${BASE_URL}/api/student/session/search-by-name`;

export const STUDENT_SESSION_BY_PHONE = `${BASE_URL}/api/student/session/search-by-phone`;

export const EMPLOYEE_ALL2 = `${BASE_URL}/api/employee/`;

export const GET_EMP_DATA = `${BASE_URL}/api/employee/empdetails`;
export const UPDATE_PASSWORD = `${BASE_URL}/api/employee/updateusersdetails`;
