import React,{useState, useRef, useEffect} from 'react'
import ModalLeaveType from '../../../../modals/HR/Leave/ModalLeaveType'
import ModalEntitlementAssign from '../../../../modals/HR/Leave/ModalEntitlementAssign'
import { LEAVE_LEAVE_TYPE, LEAVE_ENTITLEMENT_EMPLOYEE, LEAVE_GET_ALL } from '../../../../utils/apiConstants';
import axios from 'axios';
import {toast} from 'react-toastify'
import Nodata from '../../../../Components/NoData/Nodata';
import ModalEntitlementTemplate from "../../../../modals/HR/Staff/ModalEntitlementTemplate";
import ModalEntitlementUpload from "../../../../modals/Students/ModalEntitlementUpload";
import ModalEntitlementBulkResult from "../../../../modals/HR/Staff/ModalEntitlementBulkResult";
import { LOCAL_COLLEGE } from '../../../../utils/LocalStorageConstants';
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";

function LeaveLeaveType({setLoading, collegeId}) {

    const [data, setData] = useState([]);
    const [type, setType] = useState()
    const [edit, setEdit] = useState();
    const [empData, setEmpData] = useState([]);
    const [allData, setAllData] = useState();
    const [displayData, setDisplayData] = useState([]);

    const [showBulkResult, setShowBulkResult] = useState(false);
    const [bulkResult, setBulkResult] = useState({});
    const [showBulkTemplate, setShowBulkTemplate] = useState(false);


    const getAlldata = async () => {
        const config = {
          method: "get",
          url: `${LEAVE_GET_ALL}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          },
        };
    
        axios(config)
          .then((res) => {
            setAllData(res.data);
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message);
          });
      };

    const getEmpData = () => {
        setLoading(1);
        const year = new Date().getFullYear();
        const config = {
          method: "get",
          url: `${LEAVE_ENTITLEMENT_EMPLOYEE}?year=${year}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          },
        };
    
        axios(config)
          .then((res) => {
            setLoading(0);
            setEmpData(res.data.data);
            setDisplayData(res.data.data);
            console.log('employees with entitlements - ', res.data.data);
          })
          .catch((err) => {
            setLoading(0);
            toast.error(err.response.data.message);
            console.log(err);
          });
      };

    const getData = async () => {
        setLoading(1)
        const config = {
            method: 'get',
            url: LEAVE_LEAVE_TYPE,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },
        };
        
        setLoading(1);
        await axios(config)
        .then((res) => {
                setLoading(0)
                console.log('leave types - ', res.data.data);
                setData(res.data.data)
            })
            .catch(err => {
                setLoading(0)
                console.log(err)
            })

    }

    useEffect(() => {
        getData();
        getEmpData();
        getAlldata();
    }, [])

    const getCollegeData = () => {
        return localStorage.getItem(LOCAL_COLLEGE)
          ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
          : null;
      };
    
      const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

    const tableRef = useRef();

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Leave Type-" + new Date().getFullYear(),
        sheet: "Leave Type",
    });

    const PrintRecipt = useReactToPrint({
        content: () => tableRef.current,
      });
  
    //   const handlePrint = () => {
    //     PrintRecipt();
    //   };

    const handlePrint = () => {
        // Make the table visible
        tableRef.current.style.display = 'table';
      
        // Delay the PDF export
        setTimeout(() => {
          PrintRecipt();
          // Hide the table again if needed
          tableRef.current.style.display = 'none';
        }, 1); // Adjust the delay as needed
      };

      const getCurrentDate = () => {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        return formattedDate;
      };

      console.log("data -", data);
      console.log("edit -", edit);

  return (
    <div className='LeaveLeaveType'>

        {/** Bulk Upload Documents **/}
        <ModalEntitlementTemplate
        showBulkTemplate={showBulkTemplate}
        setShowBulkTemplate={setShowBulkTemplate}
        />
        <ModalEntitlementUpload
        setBulkResult={setBulkResult}
        setLoading={setLoading}
        setShowBulkResult={setShowBulkResult}
        />

        <ModalEntitlementBulkResult
            bulkResult={bulkResult}
            showBulkResult={showBulkResult}
            setShowBulkResult={setShowBulkResult}
        />

        {/** Multiple entitlement assignment **/}
        <ModalLeaveType reloadData={getData} type={type} data={edit} setLoading={setLoading}/>
        <ModalEntitlementAssign allData={allData} getEmpData={getEmpData} reloadData={getData} empData = {empData} edit={edit} setLoading={setLoading} displayData={displayData} setDisplayData={setDisplayData} leaveTypes={data}/>
        <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Leave Type</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="/">Leave</a>
                                            </li>
                                            <li className="breadcrumb-item active">Leave Type</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end page title */}


                        <div className="container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-md-8">
                                            <button
                                            className="btn btn-success btn-sm btn-rounded float-right ml-3"
                                            type="submit"
                                            name="submit"
                                            onClick={() => setShowBulkTemplate(true)}
                                            >
                                            <i className="fa fa-download" aria-hidden="true" />{" "}
                                            Download Template
                                            </button>
                                            <button
                                            className="btn btn-primary btn-sm btn-rounded float-right"
                                            data-toggle="modal"
                                            data-target="#ModalEntitlementUpload"
                                            type="button"
                                            name="submit"
                                            >
                                            <i className="fa fa-upload" aria-hidden="true" />{" "}
                                            Upload CSV
                                            </button>
                                        {/* </div>
                                        <div className="col-md-2"> */}
                                        <button
                                            className="btn btn-rounded btn-success btn-outline ml-3"
                                            data-toggle="modal"
                                            data-target="#ModalLeaveType"
                                            onClick={() => { setType("add"); setEdit() }}
                                        >
                                            Add +
                                        </button>
                                        <button onClick={onDownload} className="btn btn-primary btn-rounded ml-auto">
                                            Export Excel
                                        </button>
                                        <button
                                          className="btn btn-primary btn-rounded ml-2"
                                          onClick={handlePrint}
                                        >
                                            Export PDF
                                        </button>
                                    </div>
                                    </div>

                                    <div>
                                        {data && data.length != 0 ? data.map((i, key) => (
                                            <div
                                            className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow"
                                            onClick={() => { setType("edit"); setEdit(i); }}
                                            >
                                                <div className="col-10 d-flex justify-content-between" key={key}>
                                                    <div className="role-title mb-1">
                                                        {i.description}
                                                        <span className='badge badge-soft-success ml-2' style={{fontSize:"0.8em"}}>
                                                        Code - {i.code}
                                                        </span>
                                                    </div>
                                                    <div className="role-code">
                                                        
                                                    </div>
                                                </div>
                                                <div
                                                data-toggle="modal"
                                                data-target="#ModalEntitlementAssign"
                                                onClick={()=>{setType('edit');setEdit(i)}}
                                                className="ml-3"
                                                >
                                                <i
                                                    className="fa fa-tags "
                                                    aria-hidden="true"
                                                    style={{
                                                        color: "#364277",
                                                        cursor: "pointer",
                                                    }}
                                                    onmouseover="this.style.color='orange'"
                                                    onmouseout="this.style.color='#364277'"
                                                    /> Assign    
                                                </div> 
                                                <div 
                                                data-toggle="modal"
                                                data-target="#ModalLeaveType"
                                                onClick={()=>{setType('edit');setEdit(i)}}
                                                className="ml-3"
                                                >   
                                                    <span><i className="fa fa-edit " aria-hidden="true"/> Edit</span>
                                                </div>  
                                            </div>
                                        ))
                                        :
                                        <Nodata/>
                                        }
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                            <table
                                      id="table_id"
                                      ref={tableRef}
                                      className="display table table-bordered  nowrap table-hover "
                                      style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%", display: 'none' }}
                                    >
                                        <div className="col-12 text-center">

                                          
                                        <thead>
                                          <tr>
                                            <td colSpan={10}>
                                              <div>
                                                <h4 className="text-center">
                                                  Leave Type List -{" "}
                                                    {data?.length > 0 && data[0]?.college_id &&
                                                      collegeOpt?.find((s) => s.id === data[0]?.college_id)?.name}
                                                </h4>
                                                <span>
                                                  <p className="text-right float-right">
                                                    Printed On - {getCurrentDate()}
                                                  </p>
                                                </span>
                                              </div>
                                            </td>
                                          </tr>
                                            <tr>
                                                <th>Sl.No.</th>
                                                <th>Leave Type</th>
                                                <th>Description</th>
                                                <th>Day Count</th>
                                                <th>Paid Leave</th>
                                                <th>Negative Balance</th>
                                                <th>Reason Required</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                          data && data?.length == 0
                                          ?
                                          <tr>
                                            <td colSpan={10}>
                                              <Nodata />
                                            </td>
                                          </tr>
                                          :
                                          data
                                          ?.map((i, key) => {
                                            return <tr key={key}>
                                              <td>{key + 1}</td>
                                              <td>{i?.code}</td>
                                              <td>{i?.description}</td>
                                              <td>{i?.day_count}</td>
                                              <td>{i?.paid_leave}</td>
                                              <td>{i?.negative_balance}</td>
                                              <td>{i?.reason_required}</td>
                                              </tr>
                                          })
                                        }
                                        </tbody>
                                        </div>
                                    </table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default LeaveLeaveType