import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import Nodata from "../../../Components/NoData/Nodata";
import { REPORT_FEE_DETAILS } from "../../../utils/Reports.apiConst";
import * as XLSX from "xlsx/xlsx.mjs";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { college_title } from "../../../Data/main";

const CollegeWiseCollectionReport = ({ setLoading }) => {
  const doc = new jsPDF();

  // const [user, setUser] = useState({
  //   date: "",
  //   to_transaction_date: "",
  // });

  const [user, setUser] = useState({
    date: new Date().toISOString().split('T')[0], // Set to current date
    to_transaction_date: new Date().toISOString().split('T')[0], // Set to current date
  });

  const [clg, setClg] = useState("");
  const [clgid, setClgid] = useState();

  const facultyData = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const departmentData = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [data3, setData3] = useState([]);

  let clgname;
  const getCollege = () => {
    let clgid1 = localStorage.getItem("clg");
    let colleges = JSON.parse(localStorage.getItem("COLLEGE"));
    clgname = colleges && colleges.find((s) => s.id == clgid1)?.code;
    console.log(clgname);
    setClg(clgname);
  };

  useEffect(() => {
    getCollege();
  }, []);

  const tableRef = useRef();

  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const [all, setAll] = useState("all");

  const [studentSet, setStudentSet] = useState([]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${REPORT_FEE_DETAILS}?transaction_date=${user?.date}&to_transaction_date=${user?.to_transaction_date}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(async (res) => {
        console.log("main Data", res.data.data);
        setData(res.data.data);
        let id;
        for (var i = 0; i < res.data.data.length; i++) {
          for (var j = 0; j < res.data.data[i].newAmount.length; j++) {
            if (res.data.data[i].newAmount[j].payment_id.slice(4, 7) == clg) {
              id = res.data.data[i].collegeId;
              break;
            }
          }
        }
        setClgid(id);
        console.log(clgid);
        const stdSet = new Set();
        for (const i of res.data.data) {
          stdSet.add(i?.department_id);
        }
        console.log("stdSet", stdSet);
        const stdArr = Array.from(stdSet);
        setStudentSet(stdArr);
        change();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const getInitialData = async () => {
    setLoading(1);
    // Fetch initial data using the current date
    const config = {
      method: "get",
      url: `${REPORT_FEE_DETAILS}?transaction_date=${user?.date}&to_transaction_date=${user?.to_transaction_date}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      setData(res.data.data);
      let id;
      // ... (rest of your logic)

      for (var i = 0; i < res.data.data.length; i++) {
        for (var j = 0; j < res.data.data[i].newAmount.length; j++) {
          if (res.data.data[i].newAmount[j].payment_id.slice(4, 7) == clg) {
            id = res.data.data[i].collegeId;
            break;
          }
        }
      }
      setClgid(id);
      console.log(clgid);
      const stdSet = new Set();
      for (const i of res.data.data) {
        stdSet.add(i?.department_id);
      }
      console.log("stdSet", stdSet);
      const stdArr = Array.from(stdSet);
      setStudentSet(stdArr);
      change();

      setLoading(0);
    } catch (err) {
      console.error(err);
      setLoading(0);
    }
  };

  useEffect(() => {
    getInitialData(); // Fetch initial data when the component mounts
  }, []);

  const collegeList1 = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const downloadExcel = () => {
    let json_data = [];
    for (const iterator of data) {
      const obj = {
        "Student Registeration Number": iterator?.usn,
        "Student Name": iterator?.name,
        // "College Name":collegeOpt?.find(s=>s.id==collegeId)?.name+'-SUKALOL',
        // "Academic Year":sessionOpt?.find(s=>s?.id==user?.session_id)?.name,
        // "Department" :departmentOpt?.find(s=>s.id==user?.department_id)?.name ,
        // "Class":classOpt?.find(s=>s?.id==user?.class_id)?.name ,
        Amount: iterator?.amount,
        "Transaction Id": iterator?.transaction_id,
        "Transaction Type": iterator?.type,
      };
      json_data.push(obj);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `SUKALOL-Fee Details.xlsx`);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const change = async (e) => {
    if (e) {
      await setAll(e.target.value);
    }

    let data2 = [];
    for (var i = 0; i < departmentData.length; i++) {
      if (departmentData[i].college_id == clgid) {
        data2.push(departmentData[i]);
      }
    }
    console.log(departmentData, clgid);
    setData3(data2);
    let x = [];
    for (var i = 0; i < data3.length; i++) {
      for (var j = 0; j < data.length; j++) {
        if (data[j].department_id == data3[i].id) {
          var sum = 0;
          for (var k = 0; k < data[j].newAmount.length; k++) {
            sum += data[j].newAmount[k].amount;
          }
          x.push({
            id: data3[i].id,
            name: data3[i].name,
            amount: sum,
          });
        }
      }
    }

    const resultArray = [];
    const amounts = {};

    await x.forEach((item) => {
      if (amounts[item.name]) {
        amounts[item.name].amount += item.amount;
      } else {
        amounts[item.name] = { id: item.id, amount: item.amount };
      }
    });

    for (const name in amounts) {
      resultArray.push({
        id: amounts[name].id,
        name: name,
        amount: amounts[name].amount,
      });
    }
    console.log(resultArray);
    setData1(resultArray);
  };

  const exportToPDF = async () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("CollegeWiseCollectionReport.pdf");
    });
  };

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">COLLEGE AND DATE WISE FEE REPORTS</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">Select Criteria</div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.date}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="to_transaction_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.to_transaction_date}
                      />
                    </div>
                  </div>

                  {/*
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Select Type</label>
                      <select
                        onChange={(e) => {
                          change(e);
                          setAll(e.target.value);
                        }}
                        defaultValue=""
                        className="form-control"
                      >
                        <option value="">Select</option>
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                */}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary rounded-pill"
                        onClick={getData}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" id="pdf">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="card-title">COLLEGE AND DATE WISE FEE REPORTS</div>
                    <button
                      className="btn btn-primary rounded-pill"
                      onClick={onDownload}
                    >
                      Export
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered" ref={tableRef}>
                        <tr>
                          <th colSpan={8}>
                            {college_title}
                          </th>
                        </tr>
                        <tr>
                          <th>Sl.No</th>
                          <th>Student Name</th>
                          <th>Enrollment No.</th>
                          <th>Transaction Id</th>
                          <th>Payment Type</th>
                          <th>Date</th>
                          <th>Note</th>
                          <th className="text-right">Amount</th>
                        </tr>

                        {studentSet.map((i, key) => (
                          <>
                            {all == "all" ? (
                              <tr className="bg-dark text-light">
                                <th colSpan={7}>
                                  {departmentData.find((s) => s.id == i)?.name}
                                </th>
                                <th className="text-right">
                                  {data
                                    ?.filter((s) => s.department_id == i)
                                    ?.reduce(
                                      (acc, current) =>
                                        acc +
                                        current?.newAmount?.reduce(
                                          (acc2, current2) =>
                                            acc2 + current2?.amount,
                                          0
                                        ),
                                      0
                                    )}
                                </th>
                              </tr>
                            ) : (
                              data1?.map((item, key) => {
                                return (
                                  <>
                                    {item.id == i ? (
                                      <tr className="bg-dark text-light">
                                        <td colSpan={7}>{item?.name}</td>
                                        <td className="text-right">{item?.amount}</td>
                                      </tr>
                                    ) : null}
                                  </>
                                );
                              })
                            )}
                            {data
                              ?.filter((s) => s.department_id == i)
                              ?.map((k, key) => (
                                <>
                                  {k?.newAmount?.map((j, key2) => {
                                    return (
                                      <>
                                        {all == "all" ? (
                                          <tr>
                                            <td>{key + 1}</td>
                                            <td>{k?.studentName}</td>
                                            <td>{k?.user_id}</td>
                                            <td>{j?.payment_id}</td>
                                            <td>{j?.mode}</td>
                                            <td>{j?.date}</td>
                                            <td>{j?.note}</td>
                                            <td className="text-right">{j?.amount}</td>
                                          </tr>
                                        ) : j.payment_id.slice(4, 7) == clg ? (
                                          <tr>
                                            <td>{key + 1}</td>
                                            <td>{k?.studentName}</td>
                                            <td>{k?.usn}</td>
                                            <td>{j?.payment_id}</td>
                                            <td>{j?.mode}</td>
                                            <td>{j?.date}</td>
                                            <td>{j?.note}</td>
                                            <td className="text-right">{j?.amount}</td>
                                          </tr>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                </>
                              ))}
                          </>
                        ))}

                        {data && data?.length != 0 ? (
                          <tr>
                            <td colSpan={8}>
                              <div
                                className="d-flex justify-content-end"
                                style={{ paddingRight: "auto" }}
                              >
                                Grand Total &nbsp;&nbsp;: &nbsp;&nbsp;
                                <strong>
                                  {all == "all"
                                    ? data?.reduce(
                                        (acc, current) =>
                                          acc +
                                          current?.newAmount?.reduce(
                                            (acc2, current2) =>
                                              acc2 + current2?.amount,
                                            0
                                          ),
                                        0
                                      )
                                    : data1?.reduce(
                                        (acc, current) => acc + current?.amount,
                                        0
                                      )}
                                </strong>
                              </div>
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeWiseCollectionReport;
