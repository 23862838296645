import React, { useEffect, useState } from "react";
import axios from "axios";
import { Http } from "../../Services/Services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  ACADEMIC_STUDENT_SESSION_SEMESTER,
  STUDENT,
  SUBJECTS,
  SYLLABUS_STATUS,
} from "./student.Api";
import { SEMESTER_ID } from "../../Data/navbar/StudentPortal/storageConstant";
import { ROUTES } from "../../Router/routerConfig";
import { Devlog } from "./Logger";
import "./Subjects.scss";

const Subjects = ({ setLoading }) => {
  const [data, setData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [session, setSession] = useState("");
  const [faculty, setFaculty] = useState("");
  const [currentclass, setCurrentClass] = useState("");
  const [studentData, setStudentData] = useState([]);

  const navigate = useNavigate();
  const semester_id = sessionStorage.getItem("semseter_id");
  const college_id = sessionStorage.getItem("college");
  const class_id = sessionStorage.getItem("class_id");

  const getStudentData = async () => {
    const userId = sessionStorage.getItem("uid");

    await Http.get(`${STUDENT}/${userId}`)
      .then((res) => {
        let temp = res?.data;
        let activeSession = temp?.session?.filter((s) => s?.status == "ACTIVE");
        setSession(activeSession[0]?.session_id);
        setFaculty(res?.data?.college_id);
        setCurrentClass(activeSession[0]?.class_id);
        let obj = {
          id: temp?.semester[temp?.semester.length - 1]?.student_session_id,
          class_id: temp?.session[temp?.session.length - 1]?.class_id,
          session_id: temp?.session[temp?.session.length - 1]?.session_id,
          status: temp?.data?.status,
          class_name: temp?.session[temp?.session.length - 1]?.class_name,
          semester_name:
            temp?.semester[temp?.semester.length - 1]?.semester_name,
        };

        setStudentData(obj);
        console.log("obj - ", obj);
      })
      .catch((err) => {
        console.error("Error fetching student data:", err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getStudentData();
  }, []);

  const getCourseData = async () => {
    console.log("Fetching course data for student_session_id:", studentData.id);

    setLoading(1);
    await Http.get(
      `${ACADEMIC_STUDENT_SESSION_SEMESTER}?student_session_id=${studentData.id}`
    )
      .then((res) => {
        setLoading(0);
        const sessionsemdata = JSON.parse(res.data.data[0]?.courses) || [];
        setCourseData(sessionsemdata);
        console.log("sessionsemdata -", courseData);
      })
      .catch((err) => {
        setLoading(0);
        Devlog("sessionsemdata", err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    if (studentData?.id) {
      getCourseData();
    }
  }, [studentData?.id]);

  const courses = courseData[0]?.courses;

  const getData = async () => {
    setLoading(1);
    await Http.get(`${SUBJECTS}?semester_id=${semester_id}`)
      .then((res) => {
        setLoading(0);
        const subdata = res.data.data.filter(
          (s) => s.college_id == college_id && s.class_id == class_id
        );
        // setData(subdata);
        setData(res.data.data);
        console.log("subdata -", data);
      })
      .catch((err) => {
        setLoading(0);
        Devlog("Subjects", err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getData();
  }, [semester_id]);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h4 className="card-header text-center text-primary">
                    MY SUBJECTS
                  </h4>
                </div>
              </div>
            </div>
          </div>

          {courseData?.length !== 0 ? (
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card shadow-sm">
                  <h6 className="ml-4 mt-3 ">
                    CLASS:{" "}
                    <span className="text-dark">{studentData?.class_name}</span>
                    <span className="ml-5">
                      SEMESTER:{" "}
                      <span className="text-dark">
                        {studentData?.semester_name}
                      </span>
                    </span>
                  </h6>
                  <div className="card-body mt-2">
                    <div className="table-responsive">
                      <table className="table table-hover table-bordered subjects">
                        <thead>
                          <tr className="table">
                            <th>Sl No.</th>
                            <th>Subject</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {courseData?.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {
                                  data?.find((s) => s.id == item.course_id)
                                    ?.name
                                }
                              </td>
                              <td>
                                <button
                                  onClick={() =>
                                    navigate(
                                      `${ROUTES.StudentPortal.Lessons}/${
                                        item.course_id
                                      }/${sessionStorage.getItem(SEMESTER_ID)}`
                                    )
                                  }
                                  className="btn btn-sm btn-primary"
                                >
                                  View Lessons
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="alert alert-warning mt-4" role="alert">
              Please register your subjects first on the Course Registration
              page.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subjects;
