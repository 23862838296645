import { ROUTES } from "../../../Router/routerConfig";
const drop = 'dropdown'
const stat = 'static'
const sheet = 'sheet'
export const navbarDataNTSTAFF = [
    {
        title: 'Entitlement',
        type: stat,
        route: ROUTES.NonTeachingStaff.Entitlement,
        icon:<i className="ri-folder-chart-line mr-2" />
    },
    {
        title: 'Application',
        type: stat,
        route: ROUTES.NonTeachingStaff.Application,
        icon:<i className="ri-stack-line mr-2" />
    },
    {
        title: 'Schedule',
        type: stat,
        route: ROUTES.NonTeachingStaff.Schedule,
        icon:<i className="ri-pencil-ruler-2-line mr-2" />
    },
]