// import React from 'react'

import React, { useState } from "react";
import NavbarAdmission from "../Navbar/Navbar";
import FooterAdmission from "../Footer/Footer";
import Footer from "../../../Components/Footer/Footer";
import Navbar from "../../../Components/Navbar/Navbar";

function AdmissionForm() {
  const [info, setInfo] = useState({});
  return (
    <div>
      <NavbarAdmission></NavbarAdmission>
      {/* <Navbar></Navbar> */}
      <div className="register-area mt-5 ">
        <div className="container-fluid">
          <div className="register" style={{ maxWidth: "90%" }}>
            <h5>Baasic Informations</h5>

            <form>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Name of Student</label>
                    <input
                      required
                      type="text"
                      name="fullname"
                      className="form-control"
                      value={info?.name}
                      onChange={(e) =>
                        setInfo({ ...info, name: e.target.value })
                      }
                      placeholder="Student Full Name*"
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Mobile Number</label>

                    <input
                      required
                      type="number"
                      value={info?.phone}
                      onChange={(e) =>
                        setInfo({ ...info, phone: e.target.value })
                      }
                      name="mob_no"
                      className="form-control"
                      placeholder="Mobile Number"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Parents Mobile Number</label>

                    <input
                      required
                      type="text"
                      value={info?.parent_mobile}
                      onChange={(e) =>
                        setInfo({ ...info, parent_mobile: e.target.value })
                      }
                      name="parent_mobile"
                      className="form-control"
                      placeholder="Parent Mobile Number *"
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Date of Birth</label>

                    <div className="input-group date" id="datepicker">
                      <input
                        type="text"
                        placeholder="Date of Birth*"
                        name="dob"
                        value={info?.dob}
                        onChange={(e) =>
                          setInfo({ ...info, dob: e.target.value })
                        }
                        data-date-format="yy-mm-dd"
                        className="form-control"
                        id="date"
                      />
                      <span className="input-group-append d-print-none">
                        <span className="input-group-text bg-light ">
                          <i className="ri-calendar-2-line" />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Nationality</label>
                    <input
                      required
                      type="text"
                      value={info?.nationality}
                      onChange={(e) =>
                        setInfo({ ...info, nationality: e.target.value })
                      }
                      name="nationality"
                      className="form-control"
                      placeholder="Nationality"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label>Email of Student</label>

                    <input
                      required
                      type="email"
                      value={info?.email}
                      onChange={(e) =>
                        setInfo({ ...info, email: e.target.value })
                      }
                      name="email"
                      className="form-control"
                      placeholder="Email ID*"
                    />
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-6 program-level"
                  style={{ marginBottom: 0 }}
                >
                  <div className="form-group">
                    <p className="mb-1">
                      <b> Marital Status</b>
                    </p>
                    {/* <div class="form-check"> */}
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        value={"single"}
                        onChange={(e) =>
                          setInfo({ ...info, marital: e.target.value })
                        }
                        name="marital"
                        required
                        type="radio"
                        id="inlineCheckbox1"
                        defaultValue="single"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                      >
                        Single
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        value={"married"}
                        onChange={(e) =>
                          setInfo({ ...info, marital: e.target.value })
                        }
                        name="marital"
                        required
                        type="radio"
                        id="inlineCheckbox2"
                        defaultValue="married"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox2"
                      >
                        Married
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label>Gender</label>
                    <select
                      className="form-control"
                      value={info?.gender}
                      onChange={(e) =>
                        setInfo({ ...info, gender: e.target.value })
                      }
                      name="gender"
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Category</label>
                    <input
                      required
                      type="text"
                      value={info?.category}
                      onChange={(e) =>
                        setInfo({ ...info, category: e.target.value })
                      }
                      name="category"
                      className="form-control"
                      placeholder="Category*"
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Caste</label>
                    <input
                      required
                      type="text"
                      value={info?.caste}
                      onChange={(e) =>
                        setInfo({ ...info, caste: e.target.value })
                      }
                      name="caste"
                      className="form-control"
                      placeholder="Caste *"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Sub Caste</label>
                    <input
                      required
                      type="text"
                      value={info?.subcaste}
                      onChange={(e) =>
                        setInfo({ ...info, subcaste: e.target.value })
                      }
                      name="subcaste"
                      className="form-control"
                      placeholder="Sub-Caste*"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Blood Group</label>
                    <select
                      value={info?.blood_grp}
                      onChange={(e) =>
                        setInfo({ ...info, blood_grp: e.target.value })
                      }
                      name="blood_grp"
                      id=""
                      className="form-control"
                    >
                      <option value="">Select Blood Group</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        value={info?.address}
                        onChange={(e) =>
                          setInfo({ ...info, address: e.target.value })
                        }
                        name="address"
                        placeholder="Students Parmanent Address*"
                        id="floatingTextarea2"
                        rows={2}
                        cols={1}
                        defaultValue={""}
                      />
                      <label htmlFor="floatingTextarea2">Address</label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      required
                      type="text"
                      value={info?.state}
                      onChange={(e) =>
                        setInfo({ ...info, state: e.target.value })
                      }
                      name="state"
                      className="form-control"
                      placeholder="State"
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>District</label>
                    <input
                      required
                      type="text"
                      value={info?.dist}
                      onChange={(e) =>
                        setInfo({ ...info, dist: e.target.value })
                      }
                      name="dist"
                      className="form-control"
                      placeholder="District"
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Taluk</label>
                    <input
                      required
                      type="text"
                      value={info?.taluk}
                      onChange={(e) =>
                        setInfo({ ...info, taluk: e.target.value })
                      }
                      name="taluk"
                      className="form-control"
                      placeholder="Taluk"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Pin Code</label>
                    <input
                      required
                      type="text"
                      value={info?.pin}
                      onChange={(e) =>
                        setInfo({ ...info, pin: e.target.value })
                      }
                      name="pin"
                      className="form-control"
                      placeholder="Pin Code"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Aadhar Number</label>
                    <input
                      required
                      type="text"
                      value={info?.aadhar}
                      onChange={(e) =>
                        setInfo({ ...info, aadhar: e.target.value })
                      }
                      name="aadhar"
                      className="form-control"
                      placeholder="Aadhar Number *."
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>PAN Card Number</label>
                    <input
                      required
                      type="text"
                      value={info?.pan}
                      onChange={(e) =>
                        setInfo({ ...info, pan: e.target.value })
                      }
                      name="pan"
                      className="form-control"
                      placeholder="PAN Card Number *"
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Bank Name</label>
                    <input
                      required
                      type="text"
                      value={info?.bank_name}
                      onChange={(e) =>
                        setInfo({ ...info, bank_name: e.target.value })
                      }
                      name="bank_name"
                      className="form-control"
                      placeholder="Name of the Bank *"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>Bank Account Number</label>
                    <input
                      required
                      type="text"
                      value={info?.acc_no}
                      onChange={(e) =>
                        setInfo({ ...info, acc_no: e.target.value })
                      }
                      name="acc_no"
                      className="form-control"
                      placeholder="Bank Account Number *"
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="form-group">
                    <label>10th Passing Year</label>
                    <input
                      required
                      type="month"
                      value={info?.sslc_pass_month}
                      onChange={(e) =>
                        setInfo({ ...info, sslc_pass_month: e.target.value })
                      }
                      name="sslc_pass_month"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-lg-2 col-md-6">
                  <div className="form-group">
                    <label>10th Percentage</label>
                    <input
                      required
                      type="text"
                      value={info?.sslc_perce}
                      onChange={(e) =>
                        setInfo({ ...info, sslc_perce: e.target.value })
                      }
                      name="sslc_perce"
                      className="form-control"
                      placeholder="10th Percentage *"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label>10th Board of Exam</label>
                    <input
                      required
                      type="text"
                      value={info?.sslc_board}
                      onChange={(e) =>
                        setInfo({ ...info, sslc_board: e.target.value })
                      }
                      name="sslc_board"
                      className="form-control"
                      placeholder="Board of Examination *"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label>10th Seat Number</label>
                    <input
                      required
                      type="text"
                      value={info?.sslc_seat_no}
                      onChange={(e) =>
                        setInfo({ ...info, sslc_seat_no: e.target.value })
                      }
                      name="sslc_seat_no"
                      className="form-control"
                      placeholder="Seat Number *"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 col-md-6">
                  <div className="form-group">
                    <label>12th Passing Year</label>
                    <input
                      required
                      type="month"
                      value={info?.pu_pass_month}
                      onChange={(e) =>
                        setInfo({ ...info, pu_pass_month: e.target.value })
                      }
                      name="pu_pass_month"
                      className="form-control"
                      placeholder="Student Mobile Number *"
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="form-group">
                    <label>12th Percentage</label>
                    <input
                      required
                      type="text"
                      value={info?.pu_perce}
                      onChange={(e) =>
                        setInfo({ ...info, pu_perce: e.target.value })
                      }
                      name="pu_perce"
                      className="form-control"
                      placeholder="12th Percentage *"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>12th Subject Combination</label>
                    <input
                      required
                      type="text"
                      value={info?.subjects}
                      onChange={(e) =>
                        setInfo({ ...info, subjects: e.target.value })
                      }
                      name="subjects"
                      className="form-control"
                      placeholder="Sub Combinations  *"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label>12th Board of Exam</label>
                    <input
                      required
                      type="text"
                      value={info?.pu_board}
                      onChange={(e) =>
                        setInfo({ ...info, pu_board: e.target.value })
                      }
                      name="pu_board"
                      className="form-control"
                      placeholder="Board of Examination *"
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="form-group">
                    <label>12th Seat Number</label>
                    <input
                      required
                      type="text"
                      value={info?.pu_seat_no}
                      onChange={(e) =>
                        setInfo({ ...info, pu_seat_no: e.target.value })
                      }
                      name="pu_seat_no"
                      className="form-control"
                      placeholder="Seat Number *"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <h5>Upload Student Image</h5>
                    <input
                      required
                      type="file"
                      value={info?.std_img}
                      onChange={(e) =>
                        setInfo({ ...info, std_img: e.target.value })
                      }
                      name="std_img"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <h5>Upload PUC Markssheet</h5>
                    <input
                      required
                      type="file"
                      value={info?.puc_sheet}
                      onChange={(e) =>
                        setInfo({ ...info, puc_sheet: e.target.value })
                      }
                      name="puc_sheet"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <h5>Upload SSLC Markssheet</h5>
                    <input
                      required
                      type="file"
                      value={info?.sslc_sheet}
                      onChange={(e) =>
                        setInfo({ ...info, sslc_sheet: e.target.value })
                      }
                      name="sslc_sheet"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FooterAdmission></FooterAdmission>
      {/* <Footer></Footer> */}
    </div>
  );
}

export default AdmissionForm;
