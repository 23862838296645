import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  STUDENT_GATE_PASS_GET,
  STUDENT_GATE_PASS_GET1,
  STUDENT_GATE_PASS_UPLOAD,
} from "../../utils/InfoUploadingApiConstants";
import {
  STUDENT_ADMISSION,
  STUDENT_ADVANCE_PAY,
} from "../../utils/apiConstants";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../utils/LocalStorageConstants";
import { ROUTES } from "../../Router/routerConfig";
import {
  ASSET_HOMEOMEDIA,
  ASSET_MEDIA,
} from "../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../Helpers/Helpers";

function GatePassStudent({ setLoading }) {
  const userId = sessionStorage.getItem("uid");
  const collegeId = sessionStorage.getItem("college");

  const navigate = useNavigate();
  const fileref = useRef(null);

  const [adv, setAdv] = useState([]);
  const [data, setData] = useState([]);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);

  const [gatePasssData, setGatePassData] = useState([]);

  const [flag, setFlag] = useState(false);
  const [faculty, setFaculty] = useState("");
  const [currentclass, setCurrentClass] = useState("");
  const [session, setSession] = useState("");
  const [emp, setEmp] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [attachment, setAttachment] = useState([]);

  const [user, setUser] = useState({
    purpose_type: "",
    out_from_date: "",
    out_to_date: "",
    out_going_purpose: "",
    request_remark: "",
    attachment: "",
    approved_by_1: "",
    approved_date_1: "",
    // approval_status_1: "",
    approval_status_1: "PENDING",
    approved_by_2: "",
    approved_date_2: "",
    approval_status_2: "PENDING",
    status: "ACTIVE",
  });

  // const handleChange1 = (e) => {
  //   const { name, value } = e.target;
  //   setUser((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const clearData = () => {
    setUser((prev) => ({
      ...prev,
      request_date: "",
      req_created_by: "",
      purpose_type: "",
      out_from_date: "",
      out_to_date: "",
      out_going_purpose: "",
      request_remark: "",
      attachment: "",
      approved_by_1: "",
      approved_date_1: "",
      approval_status_1: "",
      approved_by_2: "",
      approved_date_2: "",
      approval_status_2: "",
      status: "ACTIVE",
    }));
  };

  const Change = async (e) => {
    let files = [];
    // return;
    for (let i of e.target.files) {
      console.log(i);
      try {
        const d = await getFileUrl(
          ASSET_HOMEOMEDIA,
          "Homoeopathy_Assets/Events",
          i?.name.split(".")[1],
          setLoading,
          i
        );
        files.push(d);
      } catch (error) {
        console.log(error);
      }
    }
    setAttachment(files);
  };

  const getStudentData = async () => {
    setLoading(1);
    setData([]);

    const config = {
      method: "get",
      url: `${STUDENT_ADMISSION}/${userId}`,
      data: {
        user_id: userId,
      },
      headers: {
        Authorization: `Bearer  ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const config1 = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      // console.log("student data - ", res?.data);
      let temp = res?.data;

      // Filter only "ACTIVE" sessions
      let activeSession = temp?.session?.filter((s) => s?.status === "ACTIVE");

      // Check if there are any active sessions
      if (activeSession.length > 0) {
        setFaculty(res?.data?.college_id);
        setCurrentClass(activeSession[0]?.class_id);
        setSession(activeSession[0]?.session_id);
        let obj = {};
        // obj["id"] = temp?.data?.id;
        obj["id"] =
          temp?.semester[temp?.semester.length - 1]?.student_session_id;
        obj["class_name"] = temp?.session[temp?.session.length - 1]?.class_name;
        obj["department_id"] = temp?.data?.department_id;
        obj["class_id"] = activeSession[0]?.class_id;
        obj["session_id"] = activeSession[0]?.session_id;
        obj["semester_id"] =
          temp?.semester[temp?.semester.length - 1]?.semester_id;
        obj["section_id"] =
          temp?.semester[temp?.semester.length - 1]?.section_id;
        obj["user_id"] = temp?.data?.user_id;
        obj["name"] = temp?.data?.name;
        obj["email"] = temp?.data?.email;
        obj["phone"] = temp?.data?.phone;
        obj["dob"] = temp?.data?.dob?.substring(0, 10);
        obj["gender"] = temp?.data?.gender;
        obj["category"] = temp?.data?.category;
        obj["is_hostel"] = activeSession[0]?.is_hostel;
        obj["is_transport"] = activeSession[0]?.is_transport;
        obj["status"] = temp?.data?.status;
        obj["college_name"] = collegeOpt.find(
          (s) => s.id == temp?.data?.college_id
        ).name;
        obj["department_name"] = department.find(
          (s) => s.id == temp?.data?.department_id
        )?.name;
        console.log("obj - ", obj);
        setStudentData(obj);
        let targetDiv = document.getElementById("datatable");
        // targetDiv.scrollIntoView({ behavior: "smooth" });
      } else {
        // Handle the case when there are no active sessions
        // You might want to set default values or display a message
        console.log("No active sessions found");
      }

      setLoading(0);
    } catch (err) {
      setLoading(0);
      console.log(err);
    }

    try {
      const res = await axios({
        ...config1,
        url: STUDENT_ADVANCE_PAY,
      });
      // console.log("adv - ", res.data.data);
      setAdv(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getStudentData();
  }, []);

  const getGatePassData = async () => {
    const config = {
      method: "get",
      url: `${STUDENT_GATE_PASS_GET1}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);
        const gatePassData = res.data.data.filter(
          (g) => g.student_id == userId && g.status == "ACTIVE"
        );

        setGatePassData(gatePassData);
        // setGatePassData(res.data.data);
        console.log("gatePassData-", gatePasssData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getGatePassData();
  }, []);

  // const checkForExistingApplications = () => {
  //   const fromDate = new Date(user.out_from_date);
  //   const toDate = new Date(user.out_to_date);

  //   return gatePasssData.some((entry) => {
  //     const entryFromDate = new Date(entry.out_from_date);
  //     const entryToDate = new Date(entry.out_to_date);

  //     return (
  //       (fromDate >= entryFromDate && fromDate <= entryToDate) ||
  //       (toDate >= entryFromDate && toDate <= entryToDate) ||
  //       (fromDate <= entryFromDate && toDate >= entryToDate)
  //     );
  //   });
  // };

  const checkForExistingApplications = (name, value) => {
    const fromDate =
      name === "out_from_date" ? new Date(value) : new Date(user.out_from_date);
    const toDate =
      name === "out_to_date" ? new Date(value) : new Date(user.out_to_date);

    const isOverlap = gatePasssData.some((entry) => {
      const entryFromDate = new Date(entry.out_from_date);
      const entryToDate = new Date(entry.out_to_date);

      return (
        (fromDate >= entryFromDate && fromDate <= entryToDate) ||
        (toDate >= entryFromDate && toDate <= entryToDate) ||
        (fromDate <= entryFromDate && toDate >= entryToDate)
      );
    });

    if (isOverlap) {
      toast.error("An application for the selected date range already exists.");
    }

    return isOverlap;
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
    if (name === "out_from_date" || name === "out_to_date") {
      checkForExistingApplications(name, value);
    }
  };

  const handleSubmit = async () => {
    // if (checkForExistingApplications()) {
    //   toast.error("An application for the selected date range already exists.");
    //   return;
    // }

    const config = {
      method: "post",
      url: STUDENT_GATE_PASS_UPLOAD,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        student_id: studentData?.user_id,
        college_id: collegeId,
        student_session_id: studentData?.id,
        no_of_days: days,
        req_created_by: userId,
        request_date: new Date(),
        attachment: attachment,
      },
    };

    console.log("studentData?.student_session_id - ", studentData?.id);

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Gate Pass Request successfull");
        clearData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const calculateDays = () => {
    const fromDate = new Date(user.out_from_date);
    const toDate = new Date(user.out_to_date);

    // Check if both dates are selected
    if (!user.out_from_date || !user.out_to_date || fromDate > toDate) {
      return 0;
    }

    // Set both dates to midnight
    fromDate.setHours(0, 0, 0, 0);

    // Set toDate to the end of the day (23:59:59)
    toDate.setHours(23, 59, 59, 999);

    // Calculate the difference in milliseconds
    const timeDifference = toDate.getTime() - fromDate.getTime();

    // Calculate the number of days
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Round to the nearest whole number
    const numberOfDays = Math.round(daysDifference);

    return numberOfDays >= 0 ? numberOfDays : 0;
  };

  const days = calculateDays();

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Student Gate Pass</h4>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">
                        <h2 className="card-title text-info">
                          <b>Request Detail</b>
                        </h2>
                      </div>
                      <div className="col-6">
                        <button
                          className="btn btn-primary float-right"
                          onClick={() => {
                            navigate(ROUTES.StudentPortal.ViewGatePass);
                          }}
                        >
                          View Gate Pass
                        </button>
                      </div>
                    </div>
                    <br />
                    <div className="row mt-2">
                      <div className="col-md-12 mt-2">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            <strong>Out Going Period</strong>
                          </label>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label htmlFor="validationCustom02">
                                From Date
                              </label>
                              <input
                                type="date"
                                name="out_from_date"
                                className="form-control"
                                placeholder="From Date"
                                value={user.out_from_date}
                                onChange={handleChange1}
                              />
                            </div>
                            <div className="col-md-3">
                              <label htmlFor="validationCustom02">
                                To Date
                              </label>
                              <input
                                type="date"
                                name="out_to_date"
                                className="form-control"
                                placeholder="To Date"
                                value={user.out_to_date}
                                onChange={handleChange1}
                              />
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label htmlFor="validationCustom02">
                                  No of Days
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={calculateDays()}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-3"></div> */}

                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Out Going Purpose Type
                          </label>
                          <select
                            name="purpose_type"
                            id="class"
                            className="form-control"
                            value={user.purpose_type}
                            onChange={handleChange1}
                          >
                            <option value="">Select Purpose Type</option>
                            <option value="LEAVE">Leave</option>
                            <option value="GENERAL">General</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Out Going Purpose
                          </label>
                          <input
                            type="text"
                            name="out_going_purpose"
                            className="form-control"
                            placeholder="Enter Out Going Purpose"
                            value={user.out_going_purpose}
                            onChange={handleChange1}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Request Remark
                          </label>
                          <input
                            type="text"
                            name="request_remark"
                            className="form-control"
                            placeholder="Enter Request Remark"
                            value={user.request_remark}
                            onChange={handleChange1}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">Attachment</label>
                          <input
                            type="file"
                            placeholder="Attach the file"
                            className="form-control"
                            name="attachment"
                            ref={fileref}
                            // onChange={(e) => {
                            //   handleChange(e);
                            // }}
                            onChange={(e) => Change(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                    <div className="row float-right mr-5">
                      <button
                        className="btn btn-nex  btn-md"
                        type="submit"
                        name="submit"
                        onClick={handleSubmit}
                      >
                        <i aria-hidden="true" /> Submit
                      </button>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GatePassStudent;
