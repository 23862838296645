import React, { useState } from "react";

import axios from "axios";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import getUniversityDetails from "../../utils/universityDetails.api";
import ProfileSwitches from "./PersonalDetails/ProfileSwitches.jsx";
import BasicInformation from "./PersonalDetails/BasicInformation.jsx";
import TransportFees from "./PersonalDetails/transportFees.jsx";
import CollegeDetails from "./PersonalDetails/CollegeDetails.jsx";
import Fee from "./PersonalDetails/Fee.jsx";
import Documents from "./PersonalDetails/Documents.jsx";
import HostelFees from "./PersonalDetails/hostelFees.jsx";
import { STUDENT, STUDENT_SESSION } from "./student.Api.js";
import CredentialsStudent from "./StudentCredentials.jsx";
import ParentsView from "./ParentsView.jsx";

const Profile = ({ setLoading }) => {
  const [tab, setTab] = useState("Basic");
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("app") == 1) {
      setTab("Fee");
    }
  }, []);
  //Data for Payment
  const [user, setUser] = useState({
    name: "",
    user_id: "",
    amount: "",
    email: "",
    college_id: "",
    phone: "",
    department_id: "",
    program_id: "",
    city: "",
    address: "",
    state: "Gujarat",
    country: "India",
    pincode: "",
  });

  //Student Data
  const [college, setCollege] = useState();
  const [program, setProgram] = useState();
  const [department, setDepartment] = useState();
  const [details, setDetails] = useState([]);
  const [hostel, setHostel] = useState([]);
  const [flag, setFlag] = useState(false);
  const [save, saveFlag] = useState(false);
  const [hid, setHid] = useState();
  const [tid, setTid] = useState();
  const [class3, setClass] = useState("");
  const [sem, setSem] = useState("");
  const [section, setSection] = useState("");

  const [transport, setTransport] = useState([]);

  let uid = sessionStorage.getItem("uid");
  let y, z;
  const [dept, setDept] = useState();
  const [clg, setClg] = useState();
  const programs = JSON.parse(localStorage.getItem("PROGRAM"));
  const departments = JSON.parse(localStorage.getItem("DEPARTMENT"));
  const colleges = JSON.parse(localStorage.getItem("COLLEGE"));

  const is_provisional = sessionStorage.getItem("is_provisional");

  const getAllData = async () => {
    try {
      const college = sessionStorage.getItem("college");
      const program = sessionStorage.getItem("program");
      const department = sessionStorage.getItem("department")

      setCollege(college);
      setProgram(program);
      setDepartment(department);
      for (var i = 0; i < departments.length; i++) {
        if (departments[i].id == department) {
          y = departments[i].name;
          setDept(y);
          break;
        }
      }

      for (var i = 0; i < colleges.length; i++) {
        if (colleges[i].id == college) {
          z = colleges[i].name;
          setClg(z);
          break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${STUDENT}/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(async (res) => {
        console.log(res.data.data);
        setDetails(res.data.data);
        const userData = res.data.data;
        await setUser((prev) => ({
          ...prev,
          name: userData.name,
          user_id: userData.user_id,
          email: userData.email,
          college_id: userData.college_id,
          program_id: userData.program_id,
          phone: userData.phone,
          department_id: userData.department_id,
          city: userData.city,
          address: userData.address,
          pincode: userData.pincode,
        }));
        sessionStorage.setItem("name", res.data.data?.name);
      })
      .catch((err) => {
        console.log(err);
      });

    //get Hostel and Transport Data
    const config1 = {
      method: "get",
      url: `${STUDENT_SESSION}?id=${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        res.data.data = res.data.data?.filter(
          (s) => s.status == "ACTIVE" && s.user_id == uid
        );
        console.log(res.data.data);
        setClass(res.data.data[0]?.class_name);
        setSem(res.data.data[0]?.semester);
        setSection(res.data.data[0]?.section);
        setHid(res.data.data[0]?.hostel_id);
        setTid(res.data.data[0]?.transport_id);
        setHostel(res.data.data[0]?.is_hostel);
        setTransport(res.data.data[0]?.is_transport);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const [universityData, setUniversityData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="Profile">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Student Profile</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Student</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        <a href="students-details.php"> Students Details</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Students Profile
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            <div>
              <hr />
              <h4
                className="text-center text-primary bg-white"
                style={{ padding: "18px" }}
              >
                STUDENT PROFILE
              </h4>
              <hr />
            </div>

            <div className="container-fluid mt-2">
              <div className="row">
                <div className="col-md-4">
                  <div className="box box-primary">
                    <div className="box-body box-profile">
                      <div className="card py-2">
                        <ul className="list-group list-group-unbordered pt-3">
                          <img
                            className="profile-user-img img-responsive rounded-circle mx-auto d-block"
                            src={
                              details.student_picture
                                ? details.student_picture
                                : `${"/assets/images/ananya-logo.png"}`
                            }
                            width="50%"
                            style={{ aspectRatio: "1/1" }}
                          />
                          <br />
                          <h3 className="profile-username text-center">
                            {details.name}
                          </h3>
                          <li className="list-group-item listnoback d-flex justify-content-between">
                            <b>{is_provisional ? "Provisional " : ""}Enrollment Number :</b>{" "}
                            <a className="float-right text-aqua">
                              {details.user_id}
                            </a>
                          </li>
                          <li className="list-group-item listnoback d-flex justify-content-between">
                            <b>Phone :</b>{" "}
                            <a className="float-right text-aqua">
                              +91{details.phone}
                            </a>
                          </li>
                          <li className="list-group-item listnoback d-flex justify-content-between">
                            <b>Email :</b>{" "}
                            <a className="float-right text-aqua">
                              {details.email}
                            </a>
                          </li>
                          <li className="list-group-item listnoback d-flex justify-content-between">
                            <b>Program :</b>{" "}
                            <a className="float-right text-aqua">
                              {programs &&
                                programs.find((s) => s.id === program)?.name}
                            </a>
                          </li>
                          <li className="list-group-item listnoback d-flex justify-content-between">
                            <b>College :</b>{" "}
                            <a className="float-right text-aqua">{clg}</a>
                          </li>
                          <li className="list-group-item listnoback d-flex justify-content-between">
                            <b>Department :</b>{" "}
                            <a className="float-right text-aqua">{dept}</a>
                          </li>
                          <li className="list-group-item listnoback d-flex justify-content-between">
                            <b>Gender :</b>{" "}
                            <a className="float-right text-aqua">
                              {details.gender}
                            </a>
                          </li>
                          <li className="list-group-item listnoback d-flex justify-content-between p-1">
                            <b className="ml-2">Admission Date :</b>{" "}
                            <a className="mr-2">
                              {is_provisional ? "-" : details?.createdAt?.substring(0, 10)}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-8 col-lg-8 col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <ProfileSwitches
                        hostel={hostel}
                        transport={transport}
                        tab={tab}
                        setTab={setTab}
                        setFlag={setFlag}
                        save={save}
                        saveFlag={saveFlag}
                      />
                      {tab === "Basic" && (
                        <BasicInformation
                          basic_data={details}
                          data={details}
                          flag5={flag}
                          getData={getData}
                          setLoading={setLoading}
                          saveflag={save}
                        />
                      )}

                      {tab === "Hostel Fees" && (
                        <HostelFees
                          setLoading={setLoading}
                          uid={uid}
                          user={user}
                          hid={hid}
                          class1={dept}
                        />
                      )}
                      {tab === "Transport Fees" && (
                        <TransportFees
                          setLoading={setLoading}
                          uid={uid}
                          tid={tid}
                          class1={dept}
                        />
                      )}
                      {tab === "College Details" && (
                        <CollegeDetails
                          setLoading={setLoading}
                          uid={uid}
                          class1={class3}
                          semester={sem}
                          section={section}
                          Program={
                            programs &&
                            programs.find((s) => s.id === program)?.name
                          }
                          college={clg}
                          department={dept}
                        />
                      )}
                      {tab === "Parents" && (
                        <ParentsView
                          id={uid}
                          flag={flag}
                          setLoading={setLoading}
                          studentDetails={details}
                        />
                      )}
                      {tab === "Fee" && (
                        <Fee
                          setLoading={setLoading}
                          uid={uid}
                          className={class3}
                          department={dept}
                        />
                      )}
                      {tab === "Documents" && (
                        <Documents
                          setLoading={setLoading}
                          uid={uid}
                          id={uid}
                          data={details}
                        />
                      )}
                      {tab === "Credentials" && (
                        <CredentialsStudent
                          id={uid}
                          flag={flag}
                          setLoading={setLoading}
                          studentDetails={details}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
