import React from "react";
import { useState } from "react";

const InputDoc = ({ onChange, value, lable, name, important }) => {
  const [flag, setFlag] = useState(1);
  console.log(value);
  return (
    <div className="form-group">
      <h5>
        {lable}
        {important ? <span className="text-danger">*</span> : null}
      </h5>
      {flag && value ? (
        <div className="form-control d-flex justify-content-between align-items-center">
          <a href={value} target="_blank">
            View Document
          </a>
          <div
            className="cursor-pointer d-flex flex-column align-items-center"
            onClick={() => {
              setFlag(0);
            }}
          >
            <i className="ri ri-refresh-line"></i>
            reupload
          </div>
        </div>
      ) : (
        <input
          type="file"
          name={name}
          id=""
          className="form-control"
          onChange={(e) => onChange(e)}
        />
      )}
    </div>
  );
};

export default InputDoc;
