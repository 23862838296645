import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const ProfileCard = ({ basic_data, collegeOpt, departmentOpt, programOpt }) => {
  return (
    <div className="register">
      <div className="box box-primary">
        <div className="box-body box-profile">
          <ul className="list-group list-group-unbordered">
            <img
              className="profile-user-img img-responsive rounded-circle mx-auto d-block"
              src={`${
                basic_data?.student_picture
                  ? basic_data?.student_picture
                  : "../assets/images/avatar1.avif"
              }`}
              width="40%"
              style={{ aspectRatio: "1/1" }}
            />
            <br />
            <h3 className="profile-username text-center">{basic_data?.name}</h3>
            <li className="list-group-item listnoback">
              <div className="row">
                <div className="col-5">
                  <b>Phone :</b>
                </div>
                <div className="col-7">{basic_data?.phone}</div>
              </div>
            </li>
            <li className="list-group-item listnoback">
              <div className="row">
                <div className="col-5">
                  <b>Email :</b>
                </div>
                <div className="col-7">
                  <a
                    className="text-aqua"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {basic_data?.email}
                  </a>
                </div>
              </div>
            </li>
            <li className="list-group-item listnoback">
              <div className="row">
                <div className="col-5">
                  <b>Program :</b>
                </div>
                <div className="col-7">
                  <a className="text-aqua">
                    {
                      programOpt?.find(
                        (s) => s?.id == sessionStorage.getItem("program")
                      )?.name
                    }
                  </a>
                </div>
              </div>
            </li>
            <li className="list-group-item listnoback">
              <div className="row">
                <div className="col-5">
                  <b>College :</b>
                </div>
                <div className="col-7">
                  <a className=" text-aqua">
                    {
                      collegeOpt?.find(
                        (s) => s?.id == sessionStorage.getItem("college")
                      )?.name
                    }
                  </a>
                </div>
              </div>
            </li>
            <li className="list-group-item listnoback">
              <div className="row">
                <div className="col-5">
                  <b>Department :</b>
                </div>
                <div className="col-7">
                  <a className=" text-aqua">
                    {
                      departmentOpt?.find(
                        (s) => s?.id == sessionStorage.getItem("department")
                      )?.name
                    }
                  </a>
                </div>
              </div>
            </li>
            <li className="list-group-item listnoback">
              <div className="row">
                <div className="col-5">
                  <b>Gender :</b>
                </div>
                <div className="col-7">
                  <a className="text-aqua">{basic_data?.gender}</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
