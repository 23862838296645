import { BASE_URL } from "../../utils/apiConstants";

export const STUDENT = `${BASE_URL}/api/student`;
export const STUDENT_SESSION = `${BASE_URL}/api/student/session/all`;
// export const FEE_DETAILS_BY_STUDENT_ID = `${BASE_URL}/fee/student-fee`;
export const FEE_DETAILS_BY_STUDENT_ID = `${BASE_URL}/api/student/portal/fee`


export const SUBJECTS = `${BASE_URL}/api/student/portal/subjects`;
export const LESSONS = `${BASE_URL}/api/student/portal/lessons`;
export const TOPICS = `${BASE_URL}/api/student/portal/topics`;
export const SYLLABUS_STATUS = `${BASE_URL}/api/student/portal-web/syllabus-status`;
export const ACADEMIC_STUDENT_SESSION_SEMESTER = `${BASE_URL}/api/student/portal-web/academic-student-session-semester`;

export const STUDENT_ATTENDANCE = `${BASE_URL}/api/student/portal/attendance`;
export const STUDENT_ATTENDANCE_BY_COURSE = `${BASE_URL}/api/student/portal/attendance-by-course-id`;