import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
  ACADEMICS_ADD_SUBJECT,
} from "./Academic.Api";
import {
  COURSE_REGISTRATION_STUDENT,
  STUDENT_SESSION,
} from "../../utils/apiConstants";

function CourseRegistrationStudent({ setLoading, collegeId }) {
  const college_id = sessionStorage.getItem("college");
  // console.log("college_id",college_id);

  const department = sessionStorage.getItem("department");
  // console.log("department",department);

  const classes = sessionStorage.getItem("class_id");
  // console.log("class_id",classes);

  const userid = sessionStorage.getItem("uid");
  console.log("user_id", userid);

  const semester_id = sessionStorage.getItem("semseter_id");
  console.log("semid - ", semester_id);

  const section_id = sessionStorage.getItem("section_id");

  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  useEffect(() => {
    setDepartmentOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == college_id
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), college_id]);

  const [classOpt, setClassOpt] = useState();
  const [semOpt, setSemOpt] = useState();

  // console.log("classOpt",classOpt);

  const collegesOpt = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  //Fucntion to get data of classes
  const getClassData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data2] = await Promise.all([
      await axios({
        ...config,
        url: ACADEMICS_ADD_CLASS + `?college_id=${college_id}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
          setLoading(0);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        }),

      await axios({
        ...config,
        url: ACADEMICS_ADD_SEMESTER + `?college_id=${college_id}`,
      })
        .then((res) => {
          setSemOpt(res.data.data);
          setLoading(0);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        }),
    ]);
  };

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const getData = async (a, b, c) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_SUBJECT}?college_id=${college_id}&department_id=${department}&class_id=${classes}&semester_id=${semester_id}&secion_id=${section_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setData(res.data.data);
        console.log("course registration -", data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const getData2 = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${STUDENT_SESSION}?college_id=${college_id}&department_id=${department}&class_id=${classes}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        // setData2(res.data.data);
        let tempData = res.data.data.filter(
          (element) =>
            element.user_id == userid && element.sem_status == "ACTIVE"
        );
        console.log(res.data.data);
        for (let i in tempData) {
          if (tempData[i].courses) {
            let c = 0;
            let jsonCourseData = JSON.parse(tempData[i].courses);
            // for(let j in jsonCourseData) {
            //     c = c + (parseInt(subjectOpt?.find((s) => s.id == jsonCourseData[j].course_id).credit) ? parseInt(subjectOpt?.find((s) => s.id == jsonCourseData[j].course_id).credit) : 0);
            // }
            tempData[i].credits = c;
            tempData[i].courses = jsonCourseData;
          } else {
            tempData[i].credits = 0;
            tempData[i].courses = [];
          }
        }
        setData2(tempData);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getData();
    getClassData();
  }, []);

  useEffect(() => {
    getData2();
  }, []);

  const handleSubmit = async (student, course_id) => {
    let coursesArr = [...student.courses];

    // Check if the course is already registered
    const existingCourseIndex = coursesArr.findIndex(
      (course) => course.course_id == course_id
    );

    if (existingCourseIndex !== -1) {
      // If already registered, update the status to "ACTIVE"
      coursesArr[existingCourseIndex].status = "ACTIVE";
      coursesArr[existingCourseIndex].updatedAt = new Date()
        ?.toISOString()
        .slice(0, 19)
        .replace("T", " ");
    } else {
      // If not registered, add a new object for the course
      let obj = {
        course_id: course_id,
        status: "ACTIVE",
        createdAt: new Date()?.toISOString().slice(0, 19).replace("T", " "),
        updatedAt: new Date()?.toISOString().slice(0, 19).replace("T", " "),
      };

      coursesArr.push(obj);
    }

    // Update the courses array in the user's data
    const updatedUserData = { ...student, courses: coursesArr };

    const config = {
      method: "put",
      url: COURSE_REGISTRATION_STUDENT + `/${student.student_semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        courses: JSON.stringify(updatedUserData.courses),
      },
    };

    setLoading(1);
    try {
      await axios(config);
      setLoading(0);
      getData();
      getData2();
      toast.success("Course Registration successful");
    } catch (err) {
      setLoading(0);
      console.log(err);
      toast.error("Something went wrong");
    }
  };

  console.log("data2 -", data2);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Course Registration</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title"> Courses List</h4>
                      </div>
                    </div>
                    <hr />
                    <table
                      id="datatable"
                      className="table table-bordered dt-responsive nowrap table-hover "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th> Sl. No.</th>
                          {/* <th>course_id</th> */}
                          <th>Course Name</th>
                          <th>Course Code</th>
                          <th>Course Type</th>
                          <th>Type</th>
                          <th>Credits</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((d, key) => {
                          return (
                            <tr>
                              <td>{key + 1}</td>
                              {/* <td>{d.id}</td> */}
                              <td>{d.name}</td>
                              <td>{d.code}</td>
                              <td>{d.type}</td>
                              <td>
                                {d.is_open_elective == 1
                                  ? "OPEN ELECTIVE"
                                  : d.is_elective == 1
                                  ? "ELECTIVE"
                                  : "CORE"}
                              </td>
                              <td>{d.credit}</td>
                              <td>
                                {data2[0]?.courses &&
                                data2[0]?.courses.find(
                                  (s) => s.course_id == d.id
                                )?.status == "ACTIVE" ? (
                                  "Registered"
                                ) : (
                                  <button
                                    className="btn btn-success"
                                    onClick={() => {
                                      handleSubmit(data2[0], d.id);
                                    }}
                                  >
                                    Register
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* {data?.length == 0 ? <Nodata /> : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseRegistrationStudent;
