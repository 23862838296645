import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";

function CollegeDetails({
  setLoading,
  uid,
  class1,
  section,
  semester,
  Program,
  college,
  department,
}) {
  console.log(semester);
  return (
    <div>
      <div className="table-responsive mt-3">
        <table className="table table-bordered">
          <tr>
            <th>Program</th>
            <td>{Program}</td>
          </tr>
          <tr>
            <th>College</th>
            <td>{college}</td>
          </tr>
          <tr>
            <th>Department</th>
            <td>{department}</td>
          </tr>
          <tr>
            <th>Class</th>
            <td>{class1}</td>
          </tr>
          <tr>
            <th>Semester</th>
            <td>{semester}</td>
          </tr>
          <tr>
            <th>Section</th>
            <td>{section}</td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default CollegeDetails;
