import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
// import events from '../../Data/events'
// import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import { HOLIDAY_CALENDER, LEAVE_LEAVE_TYPE } from "../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import { head } from "lodash";

function Calender({ setLoading, leaveApplications, collegeId, emp_id }) {
  let role = sessionStorage.getItem("role");

  const [holidays, setHolidays] = useState();
  const [events, setEvents] = useState();

  const date = new Date();
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const prevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);

  const prevIsoStrDate = prevMonth.toISOString();
  const nextIsoStrDate = nextMonth.toISOString();

  const [leaveColors, setLeaveColors] = useState([]);
  const [leaveColors2, setLeaveColors2] = useState([]);

  const [leaveTypes, setLeaveTypes] = useState([]);

  const [startDate, setStartDate] = useState(nextIsoStrDate);
  const [endDate, setEndDate] = useState(prevIsoStrDate);
  const [dynamicStyles, setDynamicStyles] = useState("");

  const handleDateClick = (p) => {
    console.log(p);
  };

  function getRandomBackgroundColor() {
    const bgColors = [
      "bg-success",
      "bg-danger",
      "bg-warning",
      "bg-secondary",
      "bg-info",
    ];
    return bgColors[Math.floor(Math.random() * bgColors.length)];
  }

  const getLeaveData = async () => {
    setLoading(1);
  };

  const getHolidays = async () => {
    const config = {
      method: "get",
      url:
        HOLIDAY_CALENDER +
        `?from_date=${startDate?.split("T")[0]}&to_date=${
          endDate?.split("T")[0]
        }&college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const config1 = {
      method: "get",
      url: LEAVE_LEAVE_TYPE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    setLoading(1);
    await axios(config1)
      .then(async (res) => {
        console.log("leave types - ", res.data.data);
        let leavetypes = res.data.data;
        setLeaveTypes(res.data.data);

        const styles =
          leavetypes
            .map(
              (type) => `
        .leave-type-${type.id} {
          background-color: ${type.color_code};
        }
      `
            )
            .join("") +
          `
          .default-leave {
            background-color: #E74C3C;
          }
        `;

        setDynamicStyles(styles);

        console.log(config);
        await axios(config)
          .then((res) => {
            console.log("calender - ", res.data.data);
            let tempData = res.data.data;

            const leaveTypes = [
              ...new Set(
                tempData.map((i) => (i.leave_type ? i.leave_type : "Holiday"))
              ),
            ];

            if (role == "STAFF") {
              tempData = tempData?.filter(
                (s) => !s.employee_id || s.employee_id == emp_id
              );
            }

            setHolidays(
              tempData?.map((i) => ({
                title:
                  role == "STAFF"
                    ? i.employee_id
                      ? i.leave_type
                        ? i.leave_type
                        : "Leave"
                      : i.name
                    : i.name,
                start: i.from_date,
                end: i.to_date ? i.to_date : i.from_date,
                classNames: i.leave_type
                  ? `leave-type-${i.leave_type}`
                  : "default-leave",
              }))
            );
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message);
          });
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
    setLoading(0);
  };

  const handleDateChange = (event) => {
    setStartDate(event?.startStr);
    setEndDate(event?.endStr);
  };

  useEffect(() => {
    getHolidays();
  }, [startDate, endDate]);

  useEffect(() => {
    console.log(leaveColors);
  }, [leaveColors]);

  return (
    <div>
      <div className="mb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <h6 className="mt-2 ml-3">Leave Types : </h6>
              {leaveTypes?.map((i, k) => {
                return (
                  <div className="ml-3">
                    <span>
                      <i
                        className="ri-focus-fill font-size-18"
                        style={{
                          color: `${i?.color_code ? i?.color_code : "#E74C3C"}`,
                        }}
                      ></i>
                    </span>{" "}
                    {i?.code}
                  </div>
                );
              })}
              <div className="ml-3">
                <span>
                  <i
                    className="ri-focus-fill font-size-18"
                    style={{ color: "#E74C3C" }}
                  ></i>
                </span>{" "}
                Holiday
              </div>
            </div>
            {/* <span>
              <i className="ri-focus-fill text-success font-size-18"></i>
            </span>{" "}
            Casual or sick Leave
            <span>
              {" "}
              <i className="ri-focus-fill text-danger font-size-18"></i>
            </span>{" "}
            LWP
            <span>
              <i className="ri-focus-fill text-warning font-size-18"></i>
            </span>{" "}
            Compensatory Leave
            <span>
              <i className="ri-focus-fill text-secondary font-size-18"></i>
            </span>{" "}
            Duty Leave
            <span>
              <i className="ri-focus-fill text-info font-size-18"></i>
            </span>{" "}
            Restricted Holiday
            <span>
              <i className="ri-focus-fill text-primary font-size-18"></i>
            </span>{" "}
            Holiday */}
          </div>
        </div>
      </div>
      <div>
        <style>{dynamicStyles}</style>
        <div>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={holidays}
            dateClick={(p) => {
              handleDateClick(p);
            }}
            datesSet={(event) => handleDateChange(event)}
          />
        </div>
      </div>
    </div>
  );
}

export default Calender;
