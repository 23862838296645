import React, { useEffect, useState, useRef } from 'react';
import { Progress } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL, EMPLOYEE, EMPLOYEE_EDUCATION, EMPLOYEE_EMPLOYEMENT_TERMS, EMPLOYEE_EXPERIENCE, EMPLOYEE_LEGAL_DOCUMENTS, EMPLOYEE_PLACEMENT, EMPLOYEE_PUBLICATION, EMPLOYEE_TRAINING } from '../../../utils/apiConstants';
import StaffBasic from '../../../Components/HR/Staff/StaffBasic'
import StaffEducation from '../../../Components/HR/Staff/StaffEducation'
import StaffEmploymentTerms from '../../../Components/HR/Staff/StaffEmploymentTerms'
import StaffExperience from '../../../Components/HR/Staff/StaffExperience'
import StaffLegalDocs from '../../../Components/HR/Staff/StaffLegalDocs'
import StaffPlacement from '../../../Components/HR/Staff/StaffPlacement'
import StaffSwitcher from '../../../Components/HR/Staff/StaffSwitcher'
import StaffTraining from '../../../Components/HR/Staff/StaffTraining'
import {useLocation, useParams} from 'react-router-dom'
import { SESSION_EMPLOYEE_ID, SESSION_ROLE } from '../../../utils/sessionStorageContants'
import StaffPublications from '../../../Components/HR/Staff/StaffPublications'
import { useReactToPrint } from "react-to-print";
import { LOCAL_COLLEGE, LOCAL_DEPARTMENT } from '../../../utils/LocalStorageConstants';

function EditStaff({setLoading, collegeId}) {

  const location = useLocation()

  const params = useParams()

  

  const getEmployeeId = () =>{
    return sessionStorage.getItem(SESSION_EMPLOYEE_ID)
  }  

  var id;

  if(sessionStorage.getItem(SESSION_ROLE) == "SUPERADMIN" || sessionStorage.getItem(SESSION_ROLE) == "ADMIN" || sessionStorage.getItem(SESSION_ROLE) == "HR" || sessionStorage.getItem(SESSION_ROLE) == 'SHR'){
    id = params.id
  }else{
    id = getEmployeeId()
  }
  

  const [tab,setTab] = useState('Basic')
  const [score, setScore] = useState(null);

  const fetchProgressData = async () => {
    try {
      const response = await axios.get(
        BASE_URL + '/api/employee/profile-progress/' + id
      );
      setScore(response.data.score);
    } catch (error) {
      console.error('Error fetching profile progress:', error);
    }
  };

  useEffect(() => {

      fetchProgressData();

  }, []);

  
  return (
    <div className='EditStaff'>
        <div className="main-content">
  <div className="page-content">
    <div className="container-fluid">
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0">Human Resource</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="/">Human Resource</a>
                </li>
                <li className="breadcrumb-item active">Add New Staff</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* end page title */}



        {/* switcher */}
        <StaffSwitcher setTab={setTab} tab={tab}/>

        {/* PROGRESS BAR START  */}
          <div className="mb-3">
              <h6>Profile Completion Progress</h6>
              {score !== null ? (
                <Progress percent={score} />
              ) : (
                <p>Loading...</p>
              )}
          </div>
        {/* PROGRESS BAR END  */}


      {/* <div className="alert alert-success alert-rounded " role="alert">
        Your Email Id is your Username and Password would be sent to your registered Mobile Number and Email Id
      </div> */}
      <div className="card">
                          
      <div className="card-body">
      {tab==='Basic'&&<StaffBasic tab={tab} id={id} setLoading={setLoading} collegeId={collegeId} fetchProgressData={fetchProgressData}/>}
      {tab==='Placement'&&<StaffPlacement id={id} tab={tab} setLoading={setLoading} fetchProgressData={fetchProgressData}/>}
      {tab==='Employment Terms'&&<StaffEmploymentTerms id={id} tab={tab} setLoading={setLoading} fetchProgressData={fetchProgressData}/>}
      {tab==='Education'&&<StaffEducation tab={tab} id={id} setLoading={setLoading} fetchProgressData={fetchProgressData}/>}
      {tab==='Experience'&&<StaffExperience tab={tab} id={id} setLoading={setLoading} fetchProgressData={fetchProgressData}/>}
      {tab==='Training'&&<StaffTraining tab={tab} id={id} setLoading={setLoading} fetchProgressData={fetchProgressData}/>}
      {tab==='Document'&&<StaffLegalDocs tab={tab} id={id} setLoading={setLoading} fetchProgressData={fetchProgressData}/>}
      {tab==='Publications'&&<StaffPublications tab={tab} id={id} setLoading={setLoading} fetchProgressData={fetchProgressData}/>}
      {/* {tab==='Print' } */}
      </div>
      </div> 
      
      
    </div>
    {/* container-fluid */}
  </div>
  {/* End Page-content */}
</div>

    </div>
  )
}

export default EditStaff;