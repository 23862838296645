import React, { useState, useEffect } from "react";
import { getFileUrl } from "../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_IMAGE } from "../../../utils/AssetsReferenceTypes";
import InputDoc from "../InputDoc/InputDoc";

const BscNursing = ({
  setStep,
  updateForm,
  data,
  form_data,
  set_form_data,
  setLoading,
}) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDay();
  const futureDate = date.getDate() + 3;
  // date.setDate(futureDate);
  const [defaultDate, setDefaultDate] = useState(
    date.toISOString().split("T")[0]
  );
  console.log(defaultDate);
  const [info, setInfo] = useState({});
  // useEffect(() => {

  //   if (data?.formData) {
  //     setInfo({
  //       "chem_marks": data.formData?.chem_marks,
  //       "chem_botained_marks": data.formData?.chem_botained_marks,
  //       "phy_marks": data.formData?.phy_marks,
  //       "phy_botained_marks": data.formData?.phy_botained_marks,
  //       "bio_marks": data.formData?.bio_marks,
  //       "bio_botained_marks": data.formData?.bio_botained_marks,
  //       "chem_pect_marks": data.formData?.chem_pect_marks,
  //       "chem_pect_botained_marks": data.formData?.chem_pect_botained_marks,
  //       "phy_pect_marks": data.formData?.phy_pect_marks,
  //       "phy_pect_botained_marks": data.formData?.phy_pect_botained_marks,
  //       "bio_pect_marks": data.formData?.bio_pect_marks,
  //       "bio_pect_botained_marks": data.formData?.bio_pect_botained_marks,
  //       "guj_marks": data.formData?.guj_marks,
  //       "guj_botained_marks": data.formData?.guj_botained_marks,
  //       "sanskrit_marks": data.formData?.sanskrit_marks,
  //       "sanskrit_botained_marks": data.formData?.sanskrit_botained_marks,
  //       "phil_marks": data.formData?.phil_marks,
  //       "phil_botained_marks": data.formData?.phil_botained_marks,
  //       "sociology_marks": data.formData?.sociology_marks,
  //       "sociology_botained_marks": data.formData?.sociology_botained_marks,
  //       "psycho_marks": data.formData?.psycho_marks,
  //       "psycho_botained_marks": data.formData?.psycho_botained_marks,
  //       "geo_marks": data.formData?.geo_marks,
  //       "geo_botained_marks": data.formData?.geo_botained_marks,
  //       "total_marks": data.formData?.total_marks,
  //       "total_botained_marks": data.formData?.total_botained_marks,
  //       "student_photo": data.formData?.student_photo,
  //       "tenth_marks_card": data.formData?.tenth_marks_card,
  //       "twelve_marks_card": data.formData?.twelve_marks_card,
  //       "credit_certi": data.formData?.credit_certi,
  //       "gucet_sheet": data.formData?.gucet_sheet,
  //       "neet_sheet": data.formData?.neet_sheet,
  //       "aadhaar_card": data.formData?.aadhaar_card,
  //       "freeship_card": data.formData?.freeship_card,
  //       "caste_certi": data.formData?.caste_certi,
  //       "trail_certi": data.formData?.trail_certi,
  //       "declaration": data.formData?.declaration,
  //       "place": data.formData?.place,
  //       "date": data.formData?.date,

  //     })
  //   }

  // }, [data])

  //Function upload attachment to the s3
  const addAttachment = async (e, str) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_IMAGE,
        `${info.user_id}_${str}`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      setInfo({
        ...info,
        [e.target.name]: d ? d : "",
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (data?.formData) {
      setInfo({
        ...form_data,
      });
    }
  }, [data]);

  useEffect(() => {
    set_form_data(info);
  }, [info]);

  const submitForm = (e) => {
    e.preventDefault();

    console.log({ ...data, formData: info });
    // updateForm({ ...data, formData: info });
  };
  return (
    <div>
      <div className="register-area mt-2 pb-70">
        <div className="">
          <div className="register" style={{ maxWidth: "100%" }}>
            <form>
              <div className="row mt-5">
                <h5 className="mb-3">
                  Educational Qualification: (Graduation and onwards)
                </h5>
                <div className="col-md-12 table-responsive">
                  <table className="table table-bordered border-danger rounded">
                    <tbody>
                      <tr>
                        <th>Subjects</th>
                        <th>Theory Marks</th>
                        <th>Theory Obtained Marks</th>
                        <th>Subjects</th>
                        <th>Theory Marks</th>
                        <th>Theory Obtained Marks</th>
                      </tr>
                      <tr>
                        <th>Chemistry</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.chem_marks}
                            onChange={(e) =>
                              setInfo({ ...info, chem_marks: e.target.value })
                            }
                            name="chem_marks"
                            id="chem_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.chem_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                chem_botained_marks: e.target.value,
                              })
                            }
                            name="chem_obtained"
                            id="chem_obtained"
                            className="form-control"
                          />
                        </td>
                        <th>Gujarati</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.guj_marks}
                            onChange={(e) =>
                              setInfo({ ...info, guj_marks: e.target.value })
                            }
                            name="guj_marsk"
                            id="guj_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.guj_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                guj_botained_marks: e.target.value,
                              })
                            }
                            name="guj_obtained"
                            id="guj_obtained"
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Physics</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.phy_marks}
                            onChange={(e) =>
                              setInfo({ ...info, phy_marks: e.target.value })
                            }
                            name="phy_marks"
                            id="phy_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.phy_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                phy_botained_marks: e.target.value,
                              })
                            }
                            name="phy_obtained"
                            id="phy_obtained"
                            className="form-control"
                          />
                        </td>
                        <th>English</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.eng_marsk}
                            onChange={(e) =>
                              setInfo({ ...info, eng_marsk: e.target.value })
                            }
                            name="eng_marsk"
                            id="guj_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.eng_obtained}
                            onChange={(e) =>
                              setInfo({ ...info, eng_obtained: e.target.value })
                            }
                            name="eng_obtained"
                            id="guj_obtained"
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Biology</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.bio_marks}
                            onChange={(e) =>
                              setInfo({ ...info, bio_marks: e.target.value })
                            }
                            name="bio_marks"
                            id="bio_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.bio_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                bio_botained_marks: e.target.value,
                              })
                            }
                            name="bio_obtained"
                            id="bio_obtained"
                            className="form-control"
                          />
                        </td>
                        <th>Sanskrit’s</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.sanskrit_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                sanskrit_marks: e.target.value,
                              })
                            }
                            name="san_marsk"
                            id="san_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.sanskrit_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                sanskrit_botained_marks: e.target.value,
                              })
                            }
                            name="san_obtained"
                            id="san_obtained"
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Chemistry pect</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.chem_pect_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                chem_pect_marks: e.target.value,
                              })
                            }
                            name="chemp_marks"
                            id="chemp_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.chem_pect_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                chem_pect_botained_marks: e.target.value,
                              })
                            }
                            name="chemp_obtained"
                            id="chem_obtained"
                            className="form-control"
                          />
                        </td>
                        <th>Philosophy</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.phil_marks}
                            onChange={(e) =>
                              setInfo({ ...info, phil_marks: e.target.value })
                            }
                            name="phil_marsk"
                            id="phil_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.phil_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                phil_botained_marks: e.target.value,
                              })
                            }
                            name="phil_obtained"
                            id="phil_obtained"
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Physics pect</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.phy_pect_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                phy_pect_marks: e.target.value,
                              })
                            }
                            name="phyp_marks"
                            id="phyp_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.phy_pect_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                phy_pect_botained_marks: e.target.value,
                              })
                            }
                            name="phyp_obtained"
                            id="phyp_obtained"
                            className="form-control"
                          />
                        </td>
                        <th>Sociology</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.sociology_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                sociology_marks: e.target.value,
                              })
                            }
                            name="soci_marsk"
                            id="soci_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.sociology_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                sociology_botained_marks: e.target.value,
                              })
                            }
                            name="soci_obtained"
                            id="soci_obtained"
                            className="form-control"
                          />
                        </td>
                      </tr>

                      <tr>
                        <th>Biology pect</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.bio_pect_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                bio_pect_marks: e.target.value,
                              })
                            }
                            name="biop_marks"
                            id="biop_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.bio_pect_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                bio_pect_botained_marks: e.target.value,
                              })
                            }
                            name="biop_obtained"
                            id="biop_obtained"
                            className="form-control"
                          />
                        </td>
                        <th>Psychology</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.psycho_marks}
                            onChange={(e) =>
                              setInfo({ ...info, psycho_marks: e.target.value })
                            }
                            name="psy_marsk"
                            id="psy_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.psycho_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                psycho_botained_marks: e.target.value,
                              })
                            }
                            name="psy_obtained"
                            id="psy_obtained"
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>English</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.eng2_marks}
                            onChange={(e) =>
                              setInfo({ ...info, eng2_marks: e.target.value })
                            }
                            name="eng2_marks"
                            id="eng2_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.eng2_obtained}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                eng2_obtained: e.target.value,
                              })
                            }
                            name="eng2_obtained"
                            id="eng2_obtained"
                            className="form-control"
                          />
                        </td>
                        <th>Geography</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.geo_marks}
                            onChange={(e) =>
                              setInfo({ ...info, geo_marks: e.target.value })
                            }
                            name="geo_marsk"
                            id="geo_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.geo_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                geo_botained_marks: e.target.value,
                              })
                            }
                            name="geo_obtained"
                            id="geo_obtained"
                            className="form-control"
                          />
                        </td>
                      </tr>

                      <tr>
                        <th>Total</th>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.total_marks}
                            onChange={(e) =>
                              setInfo({ ...info, total_marks: e.target.value })
                            }
                            name="total_marks"
                            id="total_marks"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            value={info?.total_botained_marks}
                            onChange={(e) =>
                              setInfo({
                                ...info,
                                total_botained_marks: e.target.value,
                              })
                            }
                            name="total_obtained"
                            id="total_obtained"
                            className="form-control"
                          />
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-6">
                  <InputDoc
                    lable={"Upload Credit Certificate"}
                    value={info?.credit_certi}
                    important={true}
                    name={"credit_certi"}
                    onChange={(e) => {
                      addAttachment(e, "credit_certi");
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <InputDoc
                    lable={"Upload GuCET Markssheet"}
                    value={info?.gucet_sheet}
                    important={true}
                    name={"gucet_sheet"}
                    onChange={(e) => {
                      addAttachment(e, "gucet_sheet");
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <InputDoc
                    lable={"Upload NEET Markssheet"}
                    value={info?.neet_sheet}
                    important={true}
                    name={"neet_sheet"}
                    onChange={(e) => {
                      addAttachment(e, "neet_sheet");
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <InputDoc
                    lable={"Upload Leaving Certificate"}
                    value={info?.lc}
                    important={true}
                    name={"lc"}
                    onChange={(e) => {
                      addAttachment(e, "lc");
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <InputDoc
                    lable={"Upload Freeship Card"}
                    value={info?.freeship_card}
                    important={true}
                    name={"freeship_card"}
                    onChange={(e) => {
                      addAttachment(e, "freeship_card");
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <InputDoc
                    lable={"Upload Caste Certificate"}
                    value={info?.caste_certi}
                    important={true}
                    name={"caste_certi"}
                    onChange={(e) => {
                      addAttachment(e, "caste_certi");
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <InputDoc
                    lable={"Upload Trail Certificate"}
                    value={info?.trail_certi}
                    important={true}
                    name={"trail_certi"}
                    onChange={(e) => {
                      addAttachment(e, "trail_certi");
                    }}
                  />
                </div>
              </div>

              <div className="row mt-5">
                <p className="mt-2">
                  <b> DECLARATION BY THE APPLICANT</b> <br />
                  <input
                    className="form-check-input check mr-5"
                    name="agree"
                    value={info?.declaration}
                    onChange={(e) =>
                      setInfo({ ...info, declaration: e.target.value })
                    }
                    required
                    type="checkbox"
                    defaultChecked=""
                    id="inlineCheckbox1"
                    defaultValue={1}
                    checked
                  />{" "}
                  Hereby declare that I have read and understood the eligibility
                  criteria for the Diploma Astrology programme for which I seek
                  admission. I fulfill the minimum eligibility criteria and I
                  have provided necessary information in this regard in the
                  application form. I shall provide proof of my eligibility. I
                  accept the rules of the university and shall not raise any
                  dispute in future about the rules. In the event of any
                  information being found incorrect or misleading my candidature
                  is liable to be cancelled by the University any time and shall
                  NOT entitle me for refund of any fee paid by me to the
                  university. I accept the allotment of study centre by the
                  university.
                </p>

                <div className="col-md-12">
                  <p>
                    <b> Instruction to Candidates</b> <br />
                  </p>
                  <ol>
                    <li>
                      Those fulfilling eligibility criteria fixed by the
                      University only will be considered for admission.{" "}
                    </li>
                    <li>
                      If admission is confirmed, enrolment number will be given
                      and attested identity card will be mailed by the Diploma
                      Astrology Programme Office, Directorate Admissions
                    </li>
                    <li>
                      For admitted learners, study materials will be mailed/
                      given by hand.
                    </li>
                    <li>
                      Schedule for contact programme/ practical will be
                      intimated.
                    </li>
                    <li>
                      Allotment of Study Centres: It will done by the University
                      and decision of the university is final.
                    </li>
                    <li>
                      Submission of Assignment is compulsory Since it Carriers
                      20% weightage
                    </li>
                    <li>
                      Refund of fee is NOT permitted. if denied admission,
                      programme fee will be refunded
                    </li>
                    <li>
                      For theory courses, first year examination will be held in
                      April-2023 only.
                    </li>
                    {/*<li>Examination form format is enclosed.</li>*/}
                  </ol>
                  <p />
                </div>
                <div className="col-md-4">
                  <h5>Place:</h5>
                  <input
                    required
                    type="text"
                    className="form-control"
                    name="place"
                    value={info?.place}
                    onChange={(e) =>
                      setInfo({ ...info, place: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-4">
                  <h5>Date:</h5>
                  <input
                    required
                    type="date"
                    onChange={(e) => setInfo({ ...info, date: e.target.value })}
                    value={info?.date ? info?.date?.split("T")[0] : defaultDate}
                    className="form-control"
                    name="date"
                    defaultValue=""
                    readOnly=""
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BscNursing;
