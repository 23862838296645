import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  STUDENT_GATE_PASS_GET1,
  STUDENT_GATE_PASS_GET2,
  STUDENT_GATE_PASS_PUT1,
} from "../../utils/InfoUploadingApiConstants";
import { EMPLOYEE_ALL } from "../../utils/apiConstants";
import Nodata from "../../Components/NoData/Nodata";
import ModalStudentGatePass from "./ModalStudentGatePass";
import { Modal } from "antd";

function ViewGatePass({ setLoading }) {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const user_id = sessionStorage.getItem("uid");
  const [employee, setEmployee] = useState("");

  const [data2, setData2] = useState("");
  const [flag, setFlag] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const navigate = useNavigate();

  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState("");

  const handleAttachmentClick = (url) => {
    setSelectedAttachment(url);
    setIsAttachmentModalOpen(true);
  };

  const getData = async () => {
    const config = {
      method: "get",
      url: `${STUDENT_GATE_PASS_GET1}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.forEach((element) => {
          element.attachment = JSON.parse(element.attachment);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        const gatePassData = res.data.data.filter(
          (g) => g.student_id == user_id && g.status == "ACTIVE"
        );

        setData(gatePassData);
        console.log("gatePassData-", gatePassData);
      })
      .catch((err) => {
        console.log(err);
      });
    const config1 = {
      method: "get",
      url: EMPLOYEE_ALL,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        setEmployee(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const handleCancelClick = (id) => {
    const config = {
      method: "put",
      url: `${STUDENT_GATE_PASS_PUT1}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Successfully Withdrawn Gate Pass");
        const updatedData = data.map((item) =>
          item.id === id ? { ...item, status: "INACTIVE" } : item
        );
        setData(updatedData);
        getData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  return (
    <div>
      <Modal
        title="Attachment"
        visible={isAttachmentModalOpen}
        onCancel={() => setIsAttachmentModalOpen(false)}
        footer={null}
        width="50%"
        style={{ textAlign: "center" }}
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <iframe
          src={selectedAttachment}
          title="Attachment"
          style={{ width: "80%", height: "400px", border: "none" }}
        />
      </Modal>
      <ModalStudentGatePass
        flag={flag}
        setFlag={setFlag}
        setLoading={setLoading}
        data={data2}
        getData={getData}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-primary">
                    {" "}
                    Student Gate Pass List{" "}
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table table-responsive dt-responsive table-bordered nowrap table-hove">
                      <table
                        id="datatable"
                        className="table  nowrap table-hover  "
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          boarder: 0,
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th width="1%">Sl.No.</th>
                            <th width="1%">Request date</th>
                            <th width="1%">Purpose Type</th>
                            <th width="2%">Out Going Purpose</th>
                            <th width="2%">Out going From date</th>
                            <th width="2%">Out going To date</th>
                            <th width="1%">No Of Days</th>
                            <th width="1%">Attachment</th>
                            <th width="1%">Proctor status</th>

                            <th width="3%">Proctor</th>
                            <th width="2%">Date</th>

                            <th width="1%">Warden Status</th>

                            <th width="3%">Warden</th>
                            <th width="2%">Date</th>

                            <th className="text-center" width="2%">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.map((d, k) => (
                              <tr>
                                <td>{k + 1}</td>
                                <td>{d?.request_date.split("T")[0]}</td>
                                <td>{d?.purpose_type}</td>
                                <td>{d?.out_going_purpose}</td>
                                <td>{d?.out_from_date}</td>
                                <td>{d?.out_to_date}</td>
                                <td>{d?.no_of_days}</td>
                                {/* <td>
                                  <a href={d?.attachment} target="_blank">
                                    View
                                  </a>
                                </td> */}

                                <td>
                                  <a
                                    href="#"
                                    onClick={() =>
                                      handleAttachmentClick(d?.attachment)
                                    }
                                  >
                                    View
                                  </a>
                                </td>

                                <td>
                                  <p
                                    className={` mt-1 badge ${
                                      d?.approval_status_1 == "APPROVED"
                                        ? "badge-soft-success"
                                        : d?.approval_status_1 == "PENDING"
                                        ? "badge-soft-warning"
                                        : d?.approval_status_1 == "DECLINED"
                                        ? "badge-soft-danger"
                                        : d?.approval_status_1 == "CANCELLED"
                                        ? "badge-soft-danger"
                                        : ""
                                    }`}
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "10px",
                                      textAlign: "center",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {d?.approval_status_1}
                                  </p>
                                </td>
                                {d?.approval_status_1 != "PENDING" ? (
                                  <>
                                    <td>
                                      {employee &&
                                        employee
                                          ?.filter(
                                            (s) => s.user_id == d?.approved_by_1
                                          )
                                          ?.map(
                                            (s) =>
                                              `${s.first_name} ${s.last_name}`
                                          )}
                                    </td>

                                    <td>{d?.approved_date_1}</td>
                                  </>
                                ) : (
                                  <>
                                    <td></td>
                                    <td></td>
                                  </>
                                )}

                                <td>
                                  <p
                                    className={` mt-1 badge ${
                                      d?.approval_status_2 == "APPROVED"
                                        ? "badge-soft-success"
                                        : d?.approval_status_2 == "PENDING"
                                        ? "badge-soft-warning"
                                        : d?.approval_status_2 == "DECLINED"
                                        ? "badge-soft-danger"
                                        : d?.approval_status_2 == "CANCELLED"
                                        ? "badge-soft-danger"
                                        : ""
                                    }`}
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "10px",
                                      textAlign: "center",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {d?.approval_status_2}
                                  </p>
                                </td>
                                {d?.approval_status_2 != "PENDING" ? (
                                  <>
                                    <td>
                                      {employee &&
                                        employee
                                          ?.filter(
                                            (s) => s.id == d?.approved_by_2
                                          )
                                          ?.map(
                                            (s) =>
                                              `${s.first_name} ${s.last_name}`
                                          )}
                                    </td>

                                    <td>{d?.approved_date_2}</td>
                                  </>
                                ) : (
                                  <>
                                    <td></td>
                                    <td></td>
                                  </>
                                )}

                                {d?.approval_status_1 == "PENDING" ||
                                d?.approval_status_2 == "PENDING" ? (
                                  <td className="justify-content-center">
                                    {d?.approval_status_1 == "PENDING" ? (
                                      <>
                                        <a
                                          data-toggle="modal"
                                          data-target="#StudentGatePass"
                                          onClick={() => {
                                            setData2({
                                              ...d,
                                            });
                                          }}
                                        >
                                          <button
                                            className="btn btn-primary btn-nex btn-rounded ml-4 mt-1"
                                            type="button"
                                            name="submit"
                                            onClick={() => handleEditClick(d)}
                                          >
                                            Edit
                                          </button>
                                        </a>
                                        <button
                                          className="btn bg-danger btn-nex btn-rounded ml-3 mt-1"
                                          type="button"
                                          name="submit"
                                          onClick={() =>
                                            handleCancelClick(d?.id)
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          className="btn bg-danger btn-nex btn-rounded ml-3 mt-1"
                                          type="button"
                                          name="submit"
                                          onClick={() =>
                                            handleCancelClick(d?.id)
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </>
                                    )}
                                  </td>
                                ) : (
                                  <>
                                    <td></td>
                                  </>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {data?.length == 0 ? <Nodata /> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewGatePass;
