import React from "react";
import axios from "axios";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  RECRUITMENT_UPLOAD,
  VISITORS_LIST,
} from "../../utils/InfoUploadingApiConstants";
import { getFileUrl } from "../../Helpers/Helpers";
import { ASSET_MEDIA } from "../../utils/AssetsReferenceTypes";
import { ROUTES } from "../../Router/routerConfig";
import { useNavigate } from "react-router-dom";

function VisitorsList({ setLoading }) {
  const navigate = useNavigate();

  const fileref = useRef(null);
  let role = sessionStorage.getItem("role");
  const [info, setInfo] = useState({
    name: "",
    purpose: "",
    date: "",
    image: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearData = () => {
    setInfo({
      name: "",
      purpose: "",
      date: "",
      image: "",
    });
    fileref.current.value = null;
  };

  const currentDate = new Date();

  const handleSubmit = async () => {
    if (!info?.name || !info?.purpose || !info?.image) {
      toast.error("Please Enter all the required Details");
      return;
    }

    setLoading(1);
    const config = {
      method: "post",
      url: VISITORS_LIST,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: {
        ...info,
        date: currentDate,
      },
    };

    axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Succesfully Uploaded Details");
        clearData();
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    let empId = Math.floor(Math.random() * 100);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      info.image = d;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="container mt-5">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div class="card-header">
                  <h2 class="text-danger"> Visitors </h2>
                </div>
                <br />
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    placeholder="Enter Visitors Name"
                    className="form-control"
                    name="name"
                    value={info?.name}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Purpose</label>
                  <input
                    type="text"
                    placeholder="Enter Visitors Purpose"
                    className="form-control"
                    name="purpose"
                    value={info?.purpose}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Image</label>
                  <input
                    type="file"
                    placeholder="Attach the file"
                    className="form-control"
                    name="image"
                    ref={fileref}
                    onChange={(e) => {
                      handleChange1(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <button
                  className="btn btn-success float-right"
                  id="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisitorsList;
