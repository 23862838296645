import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { ROUTES } from "../../../Router/routerConfig";
import { UNIVERSITY_DETAILS } from "../AdmissionApiConstants";

function NavbarAdmission({ auth, setLoading }) {
  const navigate = useNavigate();

  const [data, setData] = useState({});

  const getData = async () => {
    setLoading(1);
    var config = {
      method: "get",
      url: UNIVERSITY_DETAILS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // const [user,setUser] = useState(1)
  return (
    <div className="" style={{ backgroundColor: "white" }}>
      <ToastContainer autoClose={2000} />
      <div className="navbar-area nav-bg-2">
        {/* <div className="mobile-responsive-nav">
          <div className="container">
            <div className="mobile-responsive-menu">
              <div className="logo">
                <a href="index-3.html">
                  <img
                    src={data?.logo_primary}
                    className="main-logo"
                    lt="logo"
                  />
                  <img
                    src={data?.logo_primary}
                    className="white-logo"
                    alt="logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div> */}
        <div className="desktop-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="index-3.html">
                {/* <img
                  src={data?.logo_primary}
                  className="main-logo nav-logo"
                  alt="logo"
                  width="30%"
                /> */}
                {/* <img
                  src={data?.logo_primary}
                  className="white-logo nav-logo"
                  alt="logo"
                  width="60%"
                /> */}
              </a>

              <div
                className="collapse navbar-collapse mean-menu "
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto ">
                  {auth ? (
                    <>
                      <li className="nav-item">
                        <a className="navbar-brand" href="index-3.html">
                          <img
                            // src={data?.logo_primary}
                            src="https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/kbn_assets/employee_image/university_logo_primary/undefined_1709470215717.png"
                            className="main-logo nav-logo"
                            alt="logo"
                            width="30%"
                          />
                        </a>
                      </li>
                      <li className="nav-item" style={{ marginLeft: "270px" }}>
                        <a
                          href=""
                          className="nav-link"
                          onClick={() => {
                            navigate(ROUTES.AdmissionPortal.Dashboard);
                          }}
                          style={{ fontSize: "0.6em" }}
                        >
                          <h5>Dashboard</h5>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href=""
                          className="nav-link"
                          onClick={() => {
                            navigate(
                              ROUTES.AdmissionPortal.Admission.basicHome
                            );
                          }}
                          style={{ fontSize: "0.6em" }}
                        >
                          <h5>New Application</h5>
                        </a>
                      </li>
                      {/* <li className="nav-item">
                                            <a href="#" className="nav-link ">
                                                Status
                                            </a>
                                        </li>  */}
                    </>
                  ) : null}
                  {auth ? (
                    <li className="nav-item">
                      <a
                        href="javascript:void(0)"
                        className="nav-link "
                        onClick={() => {
                          localStorage.clear();
                          sessionStorage.clear();
                          navigate("/admission-portal");
                        }}
                        // style={{ fontSize: "0.6em" }}
                      >
                        <h5>Logout</h5>
                      </a>
                    </li>
                  ) : (
                    // <li className="nav-item">
                    //   <a
                    //     href="javascript:void(0)"
                    //     className="nav-link "
                    //     onClick={() => {
                    //       navigate("/admission-portal");
                    //       sessionStorage.clear();
                    //     }}
                    //   >
                    //     {sessionStorage.getItem("phone") ? "Logout" : "Login"}
                    //   </a>
                    // </li>
                    <></>
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <hr />
        {/* <div className="others-option-for-responsive">
          <div className="container">
            <div className="dot-menu">
              <div className="inner">
                <div className="icon">
                  <i
                    className="ri-menu-3-fill"
                    data-bs-toggle="modal"
                    data-bs-target="#sidebarModal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default NavbarAdmission;
