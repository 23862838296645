import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FEEDBACK_QUESTIONS_GET, FEEDBACK_QUESTIONS_PUT, FEEDBACK_QUESTIONS_TYPE_GET, FEEDBACK_QUESTIONS_UPLOAD } from '../../../utils/InfoUploadingApiConstants';
import Select from "react-select";


function AddFeedbackQuestions({ setLoading }) {

    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);

    const [edit, setEdit] = useState(false)

    const [user, setUser] = useState({
        type_id: "",
        question: "",
      })
    
      const clearData = () => {
        setUser({
            type_id: "",
            question: "",
        })
      }
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setUser(prev => ({
          ...prev,
          [name]: value
        }))
      }

      const handleTypeChange = (selectedOption) => {
        setUser((prevUser) => ({
          ...prevUser,
          type_id: selectedOption?.value || null,
        }));
      };

      const getData = async () => {
        setLoading(1)
        const config = {
          method: "get",
          url: FEEDBACK_QUESTIONS_TYPE_GET,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        }
    
        await axios(config)
          .then(res => {
            setData1(res.data.data);
            console.log("data1",res.data.data);
            setLoading(0)
          })
          .catch(err => {
            setLoading(0)
            console.log(err);
            toast.error('Something went wrong')
          })

          const config1 = {
            method: "get",
            url: FEEDBACK_QUESTIONS_GET,
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              "Content-Type": "application/json",
            },
          }
      
          await axios(config1)
            .then(res => {
            //   setData(res.data.data);
            //   console.log("data",res.data.data);

              const feedbackdata = res.data.data;

              feedbackdata.sort((a, b) => b.id - a.id);
              setData(feedbackdata);
              console.log("data",feedbackdata);
              setLoading(0)
            })
            .catch(err => {
              setLoading(0)
              console.log(err);
              toast.error('Something went wrong')
            })
      }

      const handleSubmit = async () => {
        setLoading(1)
        const config = {
          method: "post",
          url: FEEDBACK_QUESTIONS_UPLOAD,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: {
            ...user,
          },
        }
    
        await axios(config)
          .then(res => {
            setLoading(0)
            toast.success("Data added successfully")
            clearData();
            getData();
          })
          .catch(err => {
            setLoading(0)
            console.log(err);
            toast.error('Something went wrong')
          })
      }

      useEffect(() => {
        getData();
      },[]);

      const handleEdit = async () => {
        setLoading(1)
        const config = {
          method: "put",
          url: FEEDBACK_QUESTIONS_PUT + `/${user.id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: {
            ...user
          },
        }
    
        await axios(config)
          .then(res => {
            setLoading(0)
            toast.success("Data Updated successfully")
            clearData();
            getData();
          })
          .catch(err => {
            setLoading(0)
            console.log(err);
            toast.error('Something went wrong')
          })
      }

      const handleDelete = async (i) => {
        setLoading(1)
        const config = {
          method: "put",
          url: `${FEEDBACK_QUESTIONS_PUT}/${i?.id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: {
            status: "INACTIVE"
          },
        }
        axios(config)
          .then(res => {
            setLoading(0)
            toast.success('Data Deleted')
            getData();
          })
          .catch(err => {
            setLoading(0)
            toast.error('Some Error Occured')
          })
      }

  return (
    <div>

        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">Add Feedback Questions</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    {" "}
                    <div className="col-sm-6">
                      <h2 className="card-title">Add Criteria</h2>
                      <br />
                    </div>
                    <div className="col-sm-6"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">
                          Feedback Type<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          options={data1?.map((i) => ({ value: i.id, label: i.type_name }))}
                          value={user.type_id ? { value: user.type_id, label: data1?.find((i) => i.id == user.type_id)?.type_name } : null}
                          onChange={handleTypeChange}
                          placeholder="Select Feedback Type"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">
                          Add Question<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          name="question"
                          className="form-control"
                          placeholder="Enter Class Name"
                          value={user.question}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12 ml-auto">
                      {
                        edit == false ? <button
                          className="btn btn-nex btn-rounded float-right "
                          type="submit"
                          name="submit"
                          onClick={handleSubmit}
                        >
                          <i className="fa fa-save" aria-hidden="true" /> Save
                        </button> :
                          <button
                            className="btn btn-nex btn-rounded float-right "
                            type="submit"
                            name="Update"
                            onClick={handleEdit}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Update
                          </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* end card */}
            </div>
          </div>

          <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    {" "}
                    <h4 className="card-title"> Feedback Questions Lists</h4>
                  </div>
                </div>
                <hr />
                <table
                  className="table table-bordered dt-responsive nowrap table-hover "
                  style={{
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th> Sl. No.</th>
                      <th>Feedback Type</th>
                      <th>Questions</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.map((d, key) => {
                      return (
                        <tr>
                          <td>{key + 1}</td>
                          {/* <td>{d?.type_id}</td> */}
                          <td>{data1?.find(s=>s?.id==d?.type_id)?.type_name}</td>
                          <td>{d?.question}</td>
                          <td>
                            <acronym title="Edit">
                              <a href="javascript:void(0)" onClick={() => { setEdit(true); setUser({ ...d }) }}>
                                <i className="fa fa-edit " aria-hidden="true" />
                              </a>
                            </acronym> &nbsp;&nbsp;&nbsp;
                            <acronym title="Inactive">
                              <a
                                href="javascript:void(0)"
                                onClick={() => handleDelete(d)}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                  style={{ color: "red" }}
                                />
                              </a>
                            </acronym>
                            <a href="javascript:void(0)"> </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>  

                </div>
            </div>
        </div>
      
    </div>
  )
}

export default AddFeedbackQuestions;
