import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_LESSON,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
  ACADEMICS_ADD_SUBJECT,
  ACADEMICS_UPDATE_SYLLABUS,
  ACADEMICS_ADD_SYLLABUS,
  ACADEMICS_ADD_TOPIC,
  SYLLABUS_STATUS,
  EMP_WISE_SUBJECTS,
  SYLLABUS_STATUS_LESSON,
  ALL_CLASS_TIME_TABLE,
  ALL_SUBJECTS
} from "../../utils/Academics.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import Toggler from "./../../Components/Toggler/Toggler";
import swal from "sweetalert";
import Nodata from "../../Components/NoData/Nodata";
import { sessionOpt } from "./../../Data/jsonData/Academics/Academics";
import Select from "react-select";
import { DEPARTMENTS, EMPLOYEE } from "../../utils/apiConstants";

function SyllabusStatus({ collegeId, setLoading }) {

  const domainOptions = [
    { label: "K - Knowledge", value: "K" },
    { label: "S - Skill", value: "S" },
    { label: "A - Attitude", value: "A" },
    { label: "C - Communication", value: "C" },
  ]

  const levelOptions = [
    { label: "K - Knows", value: "K" },
    { label: "KH - Knows How", value: "KH" },
    { label: "SH - Shows How", value: "SH" },
    { label: "P - Performs Independently", value: "P" },
  ]

  const teachingOptions = [
    { label: "Lectures", value: "Lectures" },
    { label: "Small group discussion", value: "Small group discussion" },
    { label: "Bed side Clinic", value: "Bed side Clinic" },
    { label: "DOAP", value: "DOAP" },
    { label: "Case Discussion", value: "Case Discussion" },
    { label: "Demonstration", value: "Demonstration" }
  ]

  const assessmentOptions = [
    { label: "Written", value: "Written" },
    { label: "Viva", value: "Viva" },
    { label: "Skill Assessment", value: "Skill Assessment" },
    { label: "OSCE", value: "OSCE" },
    { label: "Practical", value: "Practical" },
  ]
  const role = sessionStorage.getItem("role")
  const [semOpt, setSemOpt] = useState([]);

  const [classOpt, setClassOpt] = useState([]);

  const [subjectOpt, setSubjectOpt] = useState([]);
  console.log("subjectOpt", subjectOpt)
  console.log("classOpt", classOpt);

  const [sectionOpt, setSectionOpt] = useState([]);

  const [lessonOpt, setLessonOpt] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  // console.log("DISPLAY DATA", displayData);

  const [selectedSubjectOpt, setSelectedSubjectOpt] = useState([]);
  const [selectedSectionOpt, setSelectedSectionOpt] = useState([]);
  const [selectedLessonOpt, setSelectedLessonOpt] = useState([]);

  const [classId, setClassId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [sub, setsub] = useState("");
  // console.log("departmentId", departmentId)
  // const [departmentOpt, setDepartmentOpt] = useState(
  //   JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  // );

  const [departmentOpt, setDepartmentOpt] = useState([]);
  // console.log("departmentOpt -", departmentOpt);
  const employeeId = sessionStorage.getItem("employee_id");
  // console.log("EMP IDDDD--", employeeId);
  const [employeeIdss, setEmployeeId] = useState(null);

  useEffect(() => {

    const storedEmployeeId = sessionStorage.getItem('employee_id');
    if (storedEmployeeId) {
      setEmployeeId(parseInt(storedEmployeeId, 10)); // Convert to number
    }
  }, []);
  const college_id = sessionStorage.getItem("college_id");
  const getColleges = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };
  const [collegeOpt, setCollegeOpt] = useState(getColleges);
  const [empDetails, setEmpDetails] = useState([]);
  const [empColleges, setEmpColleges] = useState([]);
  const getEmployeeandDepartments = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${employeeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        let clgs =
          res.data.data["multi_clg_id"] != "[undefined]" &&
            res.data.data["multi_clg_id"] != "undefined"
            ? JSON.parse(res.data.data["multi_clg_id"])
            : "";
        setEmpColleges(collegeOpt.filter((item) => clgs?.includes(item.id)));
        setEmpDetails(res.data.data);
        // console.log("emp details - ", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    const config2 = {
      method: "get",
      url: `${DEPARTMENTS}?college_id=${college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config2)
      .then((res) => {
        setDepartmentOpt(res.data.data);
        // console.log("emp details - ", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getEmployeeandDepartments();
  }, []);
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  const [data, setData] = useState([]);

  const [data1, setData1] = useState([]);

  const [sessions, setSelectedSession] = useState("")
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSub, setSelectedSub] = useState(null)
  const [selectedlesson, setselectedlesson] = useState(null)

  const [user, setUser] = useState({
    department_id: selectedDepartment?.value,
    session_id: sessionOpt?.find((s) => s.status == "ACTIVE")?.id,
    semester_id: selectedSemester?.value,
    section_id: selectedSection?.value,
    lesson_id: selectedlesson?.value,
    course_id: selectedSub?.value,
  });
  // console.log("USER", user)
  console.log("selectedDepartment", selectedDepartment);
  console.log("selectedClass", selectedClass);
  console.log("selectedSemester", selectedSemester);
  console.log("selectedSection", selectedSection);













  // console.log("SELECTED DPT", selectedDepartment)
  const handleSessionChange = (selectedOption) => {
    setSelectedSession(selectedOption);
    setSelectedDepartment(null);
    setSelectedClass(null);
    setSelectedSemester(null);
    setSelectedSection(null);
    setSelectedSub(null)
    setselectedlesson(null)
  };

  const handleDepartmentChange = (selectedOption) => {

    setSelectedDepartment(selectedOption);
    setSelectedClass(null);
    setSelectedSemester(null);
    setSelectedSection(null);
    setSelectedSub(null);
    setselectedlesson(null)
  };

  const handleClassChange = (selectedOption) => {
    setSelectedClass(selectedOption)
    setSelectedSemester(null);
    setSelectedSection(null);
    setSelectedSub(null)
    setselectedlesson(null)
  }

  const handleSemesterChange = (selectedOption) => {
    setSelectedSemester(selectedOption)
    setSelectedSection(null)
    setSelectedSub(null)
    setselectedlesson(null)
  }

  const handleSectionChange = (selectedOption) => {
    setSelectedSection(selectedOption)
    setSelectedSub(null)
    setselectedlesson(null)
    setselectedlesson(null)

  }
  const handleSelectSub = (selectedOption) => {
    setSelectedSub(selectedOption)
    setselectedlesson(null)
  }
  const handleSelectedLesson = (selectedOption) => {
    setselectedlesson(selectedOption)
  }
  const getOtherData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data2, data3, data4, data5] = await Promise.all([
      axios({
        ...config,
        url: ACADEMICS_ADD_SUBJECT + `?college_id=${collegeId}`,
      })
        .then((res) => {
          setSubjectOpt(res.data.data);
          setLoading(0);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        }),

      axios({
        ...config,
        url: ACADEMICS_ADD_LESSON + `?college_id=${collegeId}`,
      })
        .then((res) => {
          // console.log('lessons - ', res.data.data);

          setLessonOpt(res.data.data);
          setLoading(0);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        }),

      axios({
        ...config,
        url: ACADEMICS_ADD_SEMESTER + `?college_id=${collegeId}`,
      }).catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      }),
      axios({
        ...config,
        url: ACADEMICS_ADD_SECTION + `?college_id=${collegeId}`,
      })
        .then((res) => {
          setSectionOpt(res.data.data);
          setLoading(0);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        }),
      axios({
        ...config,
        url: ACADEMICS_ADD_CLASS + `?college_id=${collegeId}`,
      }).catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        return null;
      }),
    ]);

    // console.log(data3, data5)

    const data = data3?.data.data;

    // for (var i of data) {
    //   let ff = classData?.find(item => item.id == i.class_id)
    //   if (ff) {
    //     i.name = ff.name + " (" + i.name + ") "
    //   }
    // }
    setClassOpt(data5?.data.data);
    setSemOpt(data);
  };
 
  const getData = async () => {

    if (!selectedSub) return toast.error("Mandatory fields are required");
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${SYLLABUS_STATUS_LESSON}?course_id=${selectedSub?.value}&session_id=${sessions?.value}&lesson_id=${selectedlesson?.value}`
    };

    let tempData = [];

    setLoading(1);
    await axios(config)
      .then((res) => {
        tempData = res.data.data;
        setLoading(0);
        // console.log('syllabus status - ', res.data.data);

      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      })

    // const [data1, data2] = await Promise.all([
    //   await axios({
    //     ...config,
    //     url:
    //       ACADEMICS_ADD_TOPIC +
    //       `?college_id=${collegeId}&&course_id=${user.course_id}`,
    //   }).catch((err) => {
    //     console.log(err);
    //     toast.error("Something went wrong");
    //     return [];
    //   }),
    //   await axios({
    //     ...config,
    //     url:
    //       ACADEMICS_ADD_SYLLABUS +
    //       `?college_id=${collegeId}&&course_id=${user.course_id}&&session_id=${user.session_id}`,
    //   }).catch((err) => {
    //     // setLoading(0)
    //     console.log(err);
    //     toast.error("Something went wrong");
    //     return null;
    //   }),
    // ]);

    // let cData = data2.data.data;
    // let ncData = data1.data.data;
    // console.log('data - ', cData, ncData);
    // setData1(cData);
    // setLoading(0);
    // cData = cData?.filter((s) => s.status == "ACTIVE" && lessonOpt?.filter((s) => s.course_id == user?.course_id)?.map((i,k) => i?.id)?.includes(s.lesson_id));
    // ncData = ncData?.filter((s) => lessonOpt?.filter((s) => s.course_id == user?.course_id)?.map((i,k) => i?.id)?.includes(s.lesson_id));
    // const processedCData = [];
    // const processedNCData = [];

    // const completeId = cData?.map((item) => item.topic_id);

    // for (var j of ncData) {
    //   if (completeId.includes(j.id)) {
    //     console.log("exists");
    //   } else {
    //     const lObj = {
    //       id: j.id,
    //       complete: false,
    //       completion_date: null,
    //       session_id: j?.session_id,
    //       ...j
    //     };

    //     processedCData.push(lObj);
    //   }
    // }

    // for (var k of cData) {
    //   const pObj = {
    //     id: k.topic_id,
    //     complete: true,
    //     completion_date: k?.completion_date,
    //     session_id: k?.session_id,
    //    ...ncData?.find((item) => item.id == k.topic_id),
    //   };

    //   processedNCData.push(pObj);
    //   // setData(item => [...item, pObj])
    // }

    // let tempData = [...processedCData, ...processedNCData];

    // tempData?.sort((a,b) => a.id - b.id);
  


    for (let i of tempData) {
      try {
        i.assessment_methods = JSON.parse(i.assessment_methods) || [];
      } catch (e) {
        i.assessment_methods = [];
      }

      try {
        i.domain = JSON.parse(i.domain) || [];
      } catch (e) {
        i.domain = [];
      }

      try {
        i.horizontal_integration = JSON.parse(i.horizontal_integration) || [];
      } catch (e) {
        i.horizontal_integration = [];
      }

      try {
        i.teaching_learning_methods = JSON.parse(i.teaching_learning_methods) || [];
      } catch (e) {
        i.teaching_learning_methods = [];
      }

      try {
        i.vertical_integration = JSON.parse(i.vertical_integration) || [];
      } catch (e) {
        i.vertical_integration = [];
      }
    }

    // console.log('tempData - ', tempData);
    // console.log('lessonOpt - ', lessonOpt);

    const combinedData = tempData.reduce((acc, current) => {
      const { lesson_id, ...rest } = current;
      if (!acc[lesson_id]) {
        acc[lesson_id] = { lesson_id, lesson_name: lessonOpt?.find((s) => s.id == lesson_id)?.name, topics: [] };
      }
      acc[lesson_id].topics.push({ ...rest, lesson_id });
      return acc;
    }, {});

    const result = Object.values(combinedData);

    // console.log('result - ', result);

    setDisplayData(result);

    // setData([...processedCData, ...processedNCData]);

  };

  const [semid, setsem] = useState()
  // console.log("SEMESTER", semid)

  const college_Id = sessionStorage.getItem("college_id")
  const [empsub, setempsub] = useState();
  console.log("EMPLOYEES SUBJECT", empsub)

  const getsubata = async () => {
    const config = {
      method: "get",
      url: `${EMP_WISE_SUBJECTS}?session_id=${sessions?.value}&department_id=${selectedDepartment?.value}&semester_id=${selectedSemester?.value}&section_id=${selectedSection?.value}&college_id=${college_Id}&employee_id=${sessionStorage.getItem("employee_id")}`,
      headers: {
        "Content-Type": "application/json",
      },

    };
    // setseedata(config.data)
    await axios(config)
      .then((res) => {
        setempsub(res.data.data);
        // console.log("SUBJECT DATA", res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });


  };
  useEffect(() => {
    getsubata();
  }, [collegeId, employeeId, selectedClass, selectedDepartment, selectedSection, selectedSemester, selectedSection]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  console.log("filteredSubjects", filteredSubjects)
  useEffect(() => {
    if (employeeId !== null) {
      const filtered = empsub
        ?.filter((d) => d.department_id == selectedDepartment?.value)
        ?.filter((s) => {

          const employeeIdArray = s.employee_id.replace(/[\[\]]/g, '').split(',').map(id => parseInt(id.trim(), 10));

          return employeeIdArray[0] == employeeId;
        })
        ?.map((s) => {

          const matchedSubject = subjectOpt.find(subject => subject.id == s.course_id);
          return matchedSubject ? { value: matchedSubject.id, label: matchedSubject.name } : null;
        })
        .filter(Boolean);
      setFilteredSubjects(filtered);
    }
  }, [employeeId, selectedDepartment, empsub, subjectOpt]);


  const handleEdit = async (id, name, value) => {

    let obj = {};

    if (name == "duration") {
      obj = {
        duration: value || ""
      };
    }
    else {
      obj = {
        [name]: JSON.stringify(value) || "[]"
      };
    }

    const config = {
      method: "put",
      url: ACADEMICS_ADD_TOPIC + `/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
        college_id: collegeId,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data updated successfully");
        getData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const completeTopic = async (d) => {
    console.log("Hello");
    const obj = {
      section_id: selectedSection?.value,
      session_id: sessions?.value,
      course_id: selectedSub?.value,
      lesson_id: selectedlesson?.value,
      topic_id: d.id,
      college_id: collegeId,
      completion_date: new Date().toLocaleString(),
      employee_id: sessionStorage.getItem("employee_id"),
      emp_name: sessionStorage.getItem("emp_name"),
    };

    // console.log('obj - ', obj);

    const config = {
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: obj,
    };

    swal({
      title: "Are you sure?",
      text: "Once Completed, cannot be changed else contact higher authority",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(1);
        axios({ ...config, url: ACADEMICS_ADD_SYLLABUS })
          .then((data) => {
            setLoading(0);
            toast.success("Marked as Completed");
            getData();
          })
          .catch((err) => {
            console.log(err);
            setLoading(0);
            toast.error("Something went wrong");
          });
      } else {
        swal("Not Changed");
      }
    });
  };

  const deleteTopic = async (d) => {
    // console.log(d.id);
    // let id = data1?.filter(
    //   (s) => s?.topic_id == d.id && s?.session_id == parseInt(user?.session_id)
    // )[0];
    // console.log(id);
    // let obj = {};
    // if (id?.status == "ACTIVE") {
    //   obj.status = "INACTIVE";
    // } else {
    //   obj.status = "ACTIVE";
    // }

    let obj = { status: "INACTIVE" }
    const config = {
      method: "put",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: obj,
    };

    swal({
      title: "Are you sure?",
      text: "Once Completed, cannot be changed else contact higher authority",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(1);
        axios({ ...config, url: `${ACADEMICS_UPDATE_SYLLABUS}/${d?.acs_id}` })
          .then((data) => {
            setLoading(0);
            toast.success("Marked as InCompleted");
            getData();
          })
          .catch((err) => {
            console.log(err);
            getData();
            setLoading(0);
            toast.error("Something went wrong");
          });
      } else {
        swal("Not Changed");
      }
    });
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // second: "numeric",
      // hour12: true,
      // timeZone: "UTC",
    };

    const formatter = new Intl.DateTimeFormat("en-IN", options);
    return formatter.format(date);
  };

  const handleSearch = () => {
    getData();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setDepartmentOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == collegeId
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), , collegeId]);

  useEffect(() => {
    getOtherData();
  }, []);

  const subjectOptions = role !== "SUPERADMIN" || role !== "ADMIN"
    ? subjectOpt
      ?.filter((subject) =>
        empsub?.some((emp) => emp.course_id == subject.id)
      )
      ?.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    : selectedSubjectOpt?.map((d) => ({
      value: d.id,
      label: d.name,
    }));



  const selectedSubject = subjectOptions.find((option) => option.value == sub);

 const [allData, setAllData] = useState([])
    console.log("ALLDATA", allData);

    const getTimetableData = async () => {
     

 
        const config = {
          method: "get",
          url: `${ALL_SUBJECTS}?session_id=${sessions?.value}&department_id=${selectedDepartment?.value}&class_id=${selectedClass?.value}&semester_id=${selectedSemester?.value}&section_id=${selectedSection?.value}&college_id=${college_Id}}`,
          headers: {
            "Content-Type": "application/json",
          },
    
        };
        // setseedata(config.data)
        await axios(config)
          .then((res) => {
            setAllData(res.data.data);
            // console.log("SUBJECT DATA", res.data.data)
          })
          .catch((err) => {
            console.log(err);
          });
    

    };
    useEffect(() => {
      getTimetableData()
    }, [setSelectedDepartment,selectedSemester,selectedSection, sessions, college_Id, selectedClass])

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <form action="" method="POST"> */}
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Syllabus Status</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Lession Plan</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Syllabus Status
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />
                    <div className="row">

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Session<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            name="session_id"
                            id="session"
                            value={sessions}
                            onChange={handleSessionChange}
                            options={sessionOpt?.map((i) => ({
                              value: i.id,
                              label: i.name,
                            }))}
                            placeholder="Please Select"
                          />
                        </div>
                      </div>

                      {/* Department Select */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Department<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            // value={departmentOpt
                            //   .map((d) => ({
                            //     value: d.id,
                            //     label: `${d.name}, ${programOpt.find((item) => item.id == d.program_id)?.name} (Class: ${classOpt?.filter((s) => s.department_id == d.id)?.length})`,
                            //   }))
                            //   .find((option) => option.value == departmentId)
                            // }
                            value={selectedDepartment}
                            onChange={handleDepartmentChange}
                            // onChange={(selectedOption) => {
                            //   setDepartmentId(selectedOption.value);

                            //   setClassId("null");
                            //   setsem(null);
                            //   setSelectedSectionOpt([]);
                            //   setSelectedSubjectOpt([]);
                            //   setsub(null);
                            //   handleChange({
                            //     target: {
                            //       name: "department_id",
                            //       value: selectedOption.value,
                            //     },
                            //   });
                            // }}
                            options={
                              role == "SUPERADMIN" || role == "ADMIN"
                                ? departmentOpt.map((d) => ({
                                  value: d.id,
                                  label: `${d.name}, ${programOpt.find((item) => item.id === d.program_id)?.name} (Class: ${classOpt?.filter((s) => s.department_id == d.id)?.length})`,
                                }))
                                : departmentOpt
                                  ?.filter((d) => {
                                    const multiDeptIds =
                                      empDetails?.multi_dept_id &&
                                        empDetails?.multi_dept_id != "[undefined]"
                                        ? JSON.parse(empDetails.multi_dept_id)
                                        : [];
                                    return multiDeptIds.includes(d.id);
                                  })
                                  ?.map((d) => ({
                                    value: d.id,
                                    label: `${d.name}, ${programOpt.find((item) => item.id === d.program_id)?.name} (Class: ${classOpt?.filter((s) => s.department_id == d.id)?.length})`,
                                  }))
                            }
                            placeholder="Select Department"
                          />
                        </div>
                      </div>

                      {/* Class Select */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Class<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select

                            value={selectedClass}
                            onChange={handleClassChange}
                            options={classOpt
                              ?.filter((s) => s?.department_id == selectedDepartment?.value)
                              ?.map((d) => ({
                                value: d.id,
                                label: `${d.name} (Semesters: ${semOpt?.filter((s) => s.class_id == d.id)?.length})`,
                              }))}
                            placeholder="Please Select Class"
                          />
                        </div>
                      </div>

                      {/* Semester Select */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Semester<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            name="semester_id"
                            id="semester"

                            value={selectedSemester}
                            onChange={handleSemesterChange}
                            options={semOpt
                              ?.filter((s) => s?.class_id == selectedClass?.value)
                              ?.map((d) => ({
                                value: d.id,
                                label: `${d.name} (Sections: ${sectionOpt?.filter((s) => s.semester_id == d.id)?.length})`,
                              }))}
                            placeholder="Please Select Semester"
                          />
                        </div>
                      </div>

                      {/* Section Select */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Section<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            name="section_id"
                            id="section"

                            value={selectedSection}
                            onChange={handleSectionChange}
                            options={sectionOpt
                              ?.filter((s) => s.semester_id == selectedSemester?.value)
                              ?.map((d) => ({
                                value: d.id,
                                label: d.name,
                              }))}
                            placeholder="Select Section"
                          />
                        </div>
                      </div>

                      {/* Subject Select */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Subject<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            name="course_id"
                            id="subject"
                            required

                            value={selectedSub}
                            onChange={handleSelectSub}
                            options={
                              (role === "SUPERADMIN" || role === "ADMIN")
                                ? allData 
                                ?.filter((sub) => {
                                  const matchedSubject = subjectOpt.find(subject => subject.id == sub.id);
                                  return matchedSubject !== undefined;
                                })
                                .map((sub) => {
                                  const matchedSubject = subjectOpt.find(subject => subject.id == sub.id);
                                  return {
                                    value: matchedSubject.id,
                                    label: matchedSubject.name,
                                  };
                                })
                                : empsub
                                  
                                  ?.map((sub) => {
                                    const matchedSubject = subjectOpt.find(subject => subject.id == sub.course_id);
                                    return {
                                      value: matchedSubject.id,
                                      label: matchedSubject.name,
                                    };
                                  })
                            }
                            placeholder="Select Subject"
                          />
                        </div>
                      </div>

                      {/* Lesson Select */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Lesson<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            value={selectedlesson}
                            onChange={handleSelectedLesson}
                            options={lessonOpt
                              ?.filter((l) => l.course_id == selectedSub?.value)
                              .map((i) => ({
                                value: i.id,
                                label: i.name,
                              }))}
                            placeholder="Select Lesson"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-12 ml-auto">
                        <button
                          className="btn btn-nex btn-rounded float-right"
                          // type="submit"
                          // name="submit"
                          onClick={() => handleSearch()}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            {/* </form> */}

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">Topics List</h4>
                      </div>
                    </div>
                    <hr />
                    <div className="table table-responsive">
                      <table
                        id=""
                        className="table no-wrap table-bordered"
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th className="text-center">Sl. No.</th>
                            <th>Topic</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Topic Completion Date</th>

                            <th className="text-center">Duration (Hrs)</th>
                            <th className="text-center">Action</th>
                            {collegeId == 1111011
                              ? <>
                                {/* <th>Competency</th> */}
                                <th>Domain&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                <th>Miller's&nbsp;&nbsp;Level&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                <th>Core&nbsp;Subject&nbsp;?</th>
                                <th>Teaching Learning Methods</th>
                                <th>Assessment Methods</th>
                                <th>Number&nbsp;&nbsp;Required&nbsp;&nbsp;to Certify</th>
                                <th>Vertical&nbsp;Integration&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                <th>Horizontal&nbsp;Integration&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                              </>
                              : <></>}


                          </tr>
                        </thead>
                        <tbody>
                          {displayData
                            // ?.filter((s) => s.lesson_name == "Abdominal cavity")
                            ?.map((i, k) => {
                              return (
                                <>
                                  <tr style={{ backgroundColor: "#364277", color: "white" }}>
                                    <td><b>{k + 1}</b></td>
                                    <td colSpan={13}><b>Lesson - {i?.lesson_name}</b></td>
                                  </tr>
                                  {i?.topics?.map((d, key) => {
                                    return (
                                      <tr>
                                        <td className="text-center">{k + 1}.{key + 1}</td>
                                        <td>{d.name}</td>
                                        <td className="text-center">
                                          {d.status == "ACTIVE"
                                            ?
                                            <span className="badge badge-soft-success">Complete</span>
                                            : <span className="badge badge-soft-danger">Incomplete</span>}
                                        </td>
                                        <td className="text-center">
                                          {d.status == "ACTIVE"
                                            ? // new Date(d.completion_date).toLocaleString('en-IN', {
                                            //   timeZone:'Asia/Calcutta'
                                            // })

                                            convertDate(d?.completion_date)
                                            : "-"}
                                        </td>

                                        <td className="text-center">{d.duration}</td>
                                        <td className="text-center">
                                          {d.status == "ACTIVE" ? (
                                            <Toggler
                                              label={""}
                                              checkedData={data1}
                                              id={d.id}
                                              session={user?.session_id}
                                              // defaultchecked={d.complete}
                                              checkboxValue={() => deleteTopic(d)}
                                              // checkboxValue={() => completeTopic(d)}
                                              checked={d.status == "ACTIVE"}
                                            />
                                          ) : (
                                            <Toggler
                                              label={""}
                                              checkedData={data1}
                                              id={d.id}
                                              session={user?.session_id}
                                              checked={false}
                                              // checkboxValue1={() => deleteTopic(d)}
                                              checkboxValue={() => completeTopic(d)}
                                            />
                                          )}
                                        </td>
                                        {/* <td>
                                  <input
                                    name="duration"
                                    placeholder="Enter the duration"
                                    className="form-control"
                                    value={d.duration}
                                    onChange={(e) => {
                                      console.log(e);
                                      handleEdit(d?.id, "duration", e.target.value)
                                      // setUser({...user, domain: e?.map((i,k) => i.value)})
                                    }}
                                  />
                                </td> */}
                                        {collegeId == 1111011
                                          ? <>
                                            {/* <td>{d.competency}</td> */}


                                            <td >
                                              <Select
                                                name="domain"
                                                value={d?.domain?.map((value) =>
                                                  domainOptions.find((option) => option.value === value)
                                                ) || []}
                                                onChange={(e) => {
                                                  console.log(e);
                                                  handleEdit(d?.id, "domain", e?.map((i, k) => i.value))
                                                  // setUser({...user, domain: e?.map((i,k) => i.value)})
                                                }}
                                                autoFocus={true}
                                                isMulti={true}
                                                options={domainOptions}
                                                getOptionLabel={(option) => option.label} // Set the label display
                                                getOptionValue={(option) => option.value} // Set the value to use for selection
                                              />
                                            </td>
                                            <td>
                                              <Select
                                                name="millers_level"
                                                value={
                                                  levelOptions.find((option) => option.value === d?.level)
                                                }
                                                onChange={(e) => {
                                                  console.log(e);
                                                  handleEdit(d?.id, "level", e.value)
                                                  // setUser({...user, level: e?.value})
                                                }}
                                                autoFocus={true}
                                                options={levelOptions}
                                                getOptionLabel={(option) => option.label} // Set the label display
                                                getOptionValue={(option) => option.value} // Set the value to use for selection
                                              />
                                            </td>
                                            <td className="w-100">
                                              <Select
                                                name="core"
                                                value={
                                                  [
                                                    { label: "Yes, Its Core", value: "Y" },
                                                    { label: "No, Its Non Core", value: "N" },
                                                  ]?.find((s) => s.value == d?.core)
                                                }
                                                onChange={(e) => {
                                                  console.log(e);
                                                  // setUser({...user, core: e?.value})
                                                  handleEdit(d?.id, "core", e.value)
                                                }}
                                                autoFocus={true}
                                                options={[
                                                  { label: "Yes, Its Core", value: "Y" },
                                                  { label: "No, Its Non Core", value: "N" },
                                                ]}
                                                getOptionLabel={(option) => option.label} // Set the label display
                                                getOptionValue={(option) => option.value} // Set the value to use for selection
                                              />
                                            </td>
                                            <td>
                                              <Select
                                                name="teaching_learning_methods"
                                                value={d?.teaching_learning_methods?.map((value) =>
                                                  teachingOptions?.find((option) => option.value === value)
                                                ) || []}
                                                onChange={(e) => {
                                                  console.log(e);
                                                  handleEdit(d?.id, "teaching_learning_methods", e?.map((i, k) => i.value))
                                                  // setUser({...user, teaching_learning_methods: e?.map((i,k) => i.value)})
                                                }}
                                                autoFocus={true}
                                                isMulti={true}
                                                options={teachingOptions}
                                                getOptionLabel={(option) => option.label} // Set the label display
                                                getOptionValue={(option) => option.value} // Set the value to use for selection
                                              />
                                            </td>
                                            <td>
                                              <Select
                                                name="assessment_methods"
                                                value={d?.assessment_methods?.map((value) =>
                                                  assessmentOptions?.find((option) => option.value === value)
                                                ) || []}
                                                onChange={(e) => {
                                                  console.log(e);
                                                  // setUser({...user, assessment_methods: e?.map((i,k) => i.value)})
                                                  handleEdit(d?.id, "assessment_methods", e?.map((i, k) => i.value))

                                                }}
                                                autoFocus={true}
                                                isMulti={true}
                                                options={assessmentOptions}
                                                getOptionLabel={(option) => option.label} // Set the label display
                                                getOptionValue={(option) => option.value} // Set the value to use for selection
                                              />
                                            </td>
                                            <td>
                                              <Select
                                                name="number_required_to_certify"
                                                value={
                                                  [
                                                    { label: "1 - DOAP", value: "1" },
                                                    { label: "2 - Skill Assessment", value: "2" },
                                                  ]?.find((s) => s.value == d?.number_required_to_certify)
                                                }
                                                onChange={(e) => {
                                                  console.log(e);
                                                  // setUser({...user, number_required_to_certify: e?.value})
                                                  handleEdit(d?.id, "number_required_to_certify", e?.value)
                                                }}
                                                autoFocus={true}
                                                options={[
                                                  { label: "1 - DOAP", value: "1" },
                                                  { label: "2 - Skill Assessment", value: "2" },
                                                ]}
                                                getOptionLabel={(option) => option.label} // Set the label display
                                                getOptionValue={(option) => option.value} // Set the value to use for selection
                                              />
                                            </td>
                                            <td>
                                              <Select
                                                name="vertical_integration"
                                                value={d?.vertical_integration}
                                                onChange={(e) => {
                                                  console.log(e);
                                                  // setUser({...user, vertical_integration: e})
                                                  handleEdit(d?.id, "vertical_integration", e)
                                                }}
                                                autoFocus={true}
                                                isMulti={true}
                                                options={subjectOpt?.map((i) => ({
                                                  // Added a default empty array if classOpt is undefined
                                                  value: i.id,
                                                  label: i.name,
                                                }))}
                                                getOptionLabel={(option) => option.label} // Set the label display
                                                getOptionValue={(option) => option.value} // Set the value to use for selection
                                              />
                                            </td>
                                            <td>
                                              <Select
                                                name="horizontal_integration"
                                                value={d?.horizontal_integration}
                                                onChange={(e) => {
                                                  console.log(e);
                                                  // setUser({...user, horizontal_integration: e})
                                                  handleEdit(d?.id, "horizontal_integration", e)
                                                }}
                                                autoFocus={true}
                                                isMulti={true}
                                                options={subjectOpt?.map((i) => ({
                                                  value: i.id,
                                                  label: i.name,
                                                }))}
                                                getOptionLabel={(option) => option.label} // Set the label display
                                                getOptionValue={(option) => option.value} // Set the value to use for selection
                                              />
                                            </td>
                                            {/* <td>{d.domain?.map((i,k) => i)?.join(", ")}</td> */}
                                            {/* <td>{d.level}</td> */}
                                            {/* <td>{d.core}</td> */}
                                            {/* <td>{d.teaching_learning_methods?.map((i,k) => i)?.join(", ")}</td> */}
                                            {/* <td>{d.assessment_methods?.map((i,k) => i)?.join(", ")}</td> */}
                                            {/* <td>{d.number_required_to_certify}</td> */}
                                            {/* <td>{d.vertical_integration?.map((i,k) => i?.label)?.join(", ")}</td> */}
                                            {/* <td>{d.horizontal_integration?.map((i,k) => i?.label)?.join(", ")}</td> */}
                                          </>
                                          : <></>}
                                        {/* <td>{d.complete ? d.completion_date : 'Incomplete'}</td> */}


                                      </tr>
                                    );
                                  })}
                                </>
                              )
                            })}

                        </tbody>
                      </table>
                    </div>
                    {data?.length == 0 ? <Nodata /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SyllabusStatus;
