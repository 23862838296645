import React from "react";
import "./Status.scss";

const Status = ({ basic_data }) => {
  return (
    <div className="Status">
      <div className="container-fluid">
        <div>
          <hr />
          <h4 className="my-3">Student Application Status</h4>
          <hr />
        </div>
        <div className="row p-4 table-responsive">
          <table className="table table-bordered border-danger rounded">
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Details</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>
                <td>College Application</td>
                <td
                  className={`${
                    basic_data?.application_status === "ACTIVE"
                      ? "text-danger"
                      : basic_data?.application_status === "SUBMITTED"
                      ? "text-warning"
                      : ""
                  }`}
                >
                  {basic_data?.application_status === "ACTIVE"
                    ? "Application Not Yet Submitted"
                    : basic_data?.application_status === "SUBMITTED"
                    ? "Application Submitted Pending For Approval"
                    : basic_data?.application_status === "APPROVED_FACULTY"
                    ? "Pending for Registrar approval"
                    : ""}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div
              className="col-12 d-flex  align-items-center text-secondary"
              style={{ gap: "10px" }}
            >
              <i className="ri-information-fill"></i>
              Once Appication Submitted and Approved you will Recieve Permanent
              UserName and Password to access Student Portal of Swaminarayan
              University
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Status;
