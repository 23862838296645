import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import { toast } from "react-toastify";
import ModalAnnouncementAttachment from './ModalAnnouncementAttachment';
import { ADD_EVENT, ADD_EVENT_GLIMPS } from '../../utils/apiConstants';
import { LOCAL_EMPLOYEE } from '../../utils/LocalStorageConstants';
import ReactQuill from 'react-quill';
import { ASSET_EMPLOYEE_DOCUMENT } from '../../utils/AssetsReferenceTypes';
import { getFileUrl } from '../../Helpers/Helpers';
function ModalEventGlimps(props, { title, img, setLink }) {
    const [alldata, setAllData] = useState()
    const [modalTitle, setModalTitle] = useState("");
    const [modalLink, setModalLink] = useState("");
    const [desc, setDesc] = useState("");
    const [attachment, setAttachment] = useState([]);
    const [getid,setid] = useState("");
    console.log("ATTACHMENT", attachment)
    console.log("getid",  alldata?.length > 0 ? alldata[0].id : "");
    
    const getLocalEmployee = () => {
        return localStorage.getItem(LOCAL_EMPLOYEE)
            ? JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE))
            : null;
    };
    const [emp, setEmpData] = useState(getLocalEmployee)
    const getAlldata = async () => {
        try {
            const config = {
                method: "get",
                url: `${ADD_EVENT}/${props.data.id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                },
            };

            const res = await axios(config);
            setAllData(res.data.data);
        } catch (err) {
            console.error(err);
            toast.error("Something Went Wrong");
        }
    };
    useEffect(() => {
        if (props.data) {

            getAlldata();
            //    / getcreateby(props.data.createdBy)

        }
    }, [props.data]);
    const getEmployeeNameById = (id) => {
        const employee = emp.find(e => e.id.toString() === id.toString());
        return employee ? `${employee.first_name} ${employee.last_name}` : 'Unknown';
    };
    const extractTextContent = (html) => {
        if (!html) return '';
        return html
            .replace(/<[^>]+>/g, '')
            .replace(/\s+/g, ' ')
            .trim();
    };
    const [info, setInfo] = useState({
        no_of_people: "",

    });

    const clearData = () => {
     
        setDesc("");
       info.no_of_people=""

    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setInfo((prevValue) => ({
            ...prevValue,
            [name]: value,
        }));
    };
    const handleSubmit = async (e) => {
        const config = {
            method: "post",
            url: `${ADD_EVENT_GLIMPS}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
            data: {
                event_id: alldata?.length > 0 ? alldata[0].id : "",
                description: desc,
                event_attachment:JSON.stringify(attachment),
                createdBy: sessionStorage.getItem("employee_id"),
                no_of_people: info.no_of_people,
                // college_id: getcollegeid,
            },
        };
        try {
            const response = await axios(config);
            // console.log(response);
            clearData();
            toast.success("Event Created");
        } catch (err) {
            console.error("Error details:", err.response || err.message || err);
            toast.error("Something Went Wrong: " + (err.response?.data?.message || "An unknown error occurred"));
        }
    }


    const fileref = useRef(null);
 
    const addAttachment = async (e, name) => {
        try {
            const d = await getFileUrl(
                ASSET_EMPLOYEE_DOCUMENT,
                `Attachment`,
                e.target.value.split(".")[1],

                e.target.files[0]
            );
            setAttachment(d);
        } catch (error) {
            console.log(error);
        }
    };










    return (

        <div
            className="modal fade"
            id="ModalEventGlimps"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
            >
                <div className="modal-content ">
                    <div className="modal-header">
                        <h5 className="modal-title fw-2" id="exampleModalLongTitle">
                            Event View
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span
                                aria-hidden="true"
                                onClick={() => {
                                    setLink("");
                                }}
                            >
                                ×
                            </span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <table
                            id="datatable"
                            className="table table-bordered  table-hover"
                            style={{
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                                width: "100%",
                            }}
                        >
                            <tbody>

                                {alldata?.map((item, index) => (<>
                               
                                    <tr key={index}>
                                        <th>Event Name</th>
                                        <td colSpan={4}><b>{item?.title}</b></td>
                                    </tr>
                                    <tr>
                                        <th>From Date</th>
                                        <th>To Date</th>
                                        <th>Created on</th>
                                        <th>Created by</th>
                                    </tr>
                                    <tr>
                                        <td>{item?.from_date?.split('T')[0]}</td>
                                        <td>{item?.to_date?.split('T')[0]}</td>
                                        <td>{item?.createdAt?.split('T')[0]}</td>
                                        <td>{getEmployeeNameById(item?.createdBy)}</td>
                                    </tr>
                                    <tr>
                                        <th>Attachment</th>
                                        <th>Event Level</th>
                                        <th>Event Visitors</th>
                                        <th>Description</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    console.log(item?.attachment)
                                                    setModalLink(item?.attachment);
                                                    setModalTitle(item?.title);
                                                  
                                                }}
                                                data-toggle="modal"
                                                data-target="#ModalAnnouncementAttachment"
                                                className="btn btn-nex btn-sm my-1 ml-3"
                                                title="View"
                                            >
                                                View
                                            </button>
                                            <ModalAnnouncementAttachment title={modalTitle} img={modalLink} setLink={setModalLink} />
                                        </td>
                                        <td>{item?.level}</td>
                                        <td>{item?.no_of_people}</td>
                                        <td>{extractTextContent(item?.description)}</td>
                                    </tr>
                                    <tr>
                                        <th>Venue</th>
                                        <td>{item?.venue}</td>
                                    </tr>
                                </>))}
                            </tbody>
                        </table>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="validationCustom01">
                                        Upload Event Glimps<span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="file"
                                        multiple
                                        className="form-control"

                                      
                                        name="attachment"
                                        ref={fileref}
                                        onChange={(e) => {
                                            addAttachment(e, "attachment");
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="validationCustom01">
                                        Event Visitors<span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Enter Number of People"
                                        value={info?.no_of_people}
                                        name="no_of_people"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-2 ms-2 ml-1">
                            <p><b>Description <span className='text-danger'>*</span></b></p>
                            <div>
                                <ReactQuill
                                    theme="snow"
                                    style={{ width: '97%' }}
                                    value={desc}
                                    onChange={(e) => {
                                        setDesc(e);

                                    }}
                                    placeholder="Write Detailed Description of Announcement Here..."
                                />
                            </div>
                        </div>
                        <button
                            className="btn btn-success float-right btn-rounded btn-outline mt-2"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={handleSubmit}
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>









































    )
}

export default ModalEventGlimps
