import { BASE_URL } from "../../utils/apiConstants";

export const LOGIN = `${BASE_URL}/api/admission/login`;

export const OTP_GENRATE = `${BASE_URL}/api/admission/otp`;
export const UNIVERSITY_DETAILS = `${BASE_URL}/api/admission/getUniverstiyDetails`;
export const VERIFY_OTP = `${BASE_URL}/api/frontOffice/admission-enquiry`;
export const GET_DETAILS = `${BASE_URL}/api/admission/details`;
export const UPDATE_FORM = `${BASE_URL}/api/admission/update`;
export const GET_PRE_ADMISSION = `${BASE_URL}/api/admission/preAdmission`;
export const GET_ALL_DROP_DATA = `${BASE_URL}/api/admission/getAllData`;
export const UPDATE_PRE_ADMISSION = `${BASE_URL}/api/admission/preAdmission`;
export const GET_DEPT = `${BASE_URL}/api/hr/department`;
export const ACADEMICS_ADD_CLASS = `${BASE_URL}/api/academics/class`;
export const ACADEMICS_ADD_SEMESTER = `${BASE_URL}/api/academics/semester`;
export const HDFC_PAYMENT_GATEWAY = `${BASE_URL}/api/payment/hdfc`;
export const WITHDRAW_APPLICATION = `${BASE_URL}/api/admission/preAdmission1`;
export const UPDATEADMISSIONENQUIRY = `${BASE_URL}/api/admission/updateAdmissionEnquiry`;