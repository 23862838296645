import React, { useEffect, useState } from 'react'
import AddEventModal from './AddEventModal';
import { ADD_EVENT, EMPLOYEE, EVENT_WORKFLOW } from '../../utils/apiConstants';
import axios from "axios";
import { toast } from "react-toastify";
import ModalAnnouncementAttachment from './ModalAnnouncementAttachment';
import ViewEventModel from './ViewEventModel';
import Nodata from '../../Components/NoData/Nodata';
import { LOCAL_EMPLOYEE } from '../../utils/LocalStorageConstants';
import ModalEventGlimps from './ModalEventGlimps';

function AddEvent({ setLoading }) {
  const [type, setType] = useState()
  const [edit, setEdit] = useState();
  const [editpages, seteditpages] =useState([]);

  // getdata
  const [eventflow, seteventflow] = useState()
  const [alldata, setData] = useState()
  const [data, settingdata] = useState()
  const [data2, setdata2] =useState()
  let employeeId = sessionStorage.getItem("employee_id")
  // console.log("employeeId",employeeId);
  // console.log("alldata", alldata);
  // console.log("eventflow", eventflow)
console.log("SEND DATA2", data2);



  let role = sessionStorage.getItem("role")


  const [filterdata, setFilterdata] = useState([]);

  useEffect(() => {
    if (Array.isArray(eventflow)) {
      eventflow.forEach(flow => {
        const approverIds = [flow.approver_1, flow.approver_2, flow.approver_3, flow.approver_4];
        // console.log("approverIds", approverIds);

        const flowFilteredData = alldata.filter(item => {
          return item.approval_flow === flow.id && approverIds.includes(parseInt(employeeId, 10));
        });

        // console.log("filteredData222", flowFilteredData);

        setFilterdata(flowFilteredData);
      });
    }
  }, [alldata, eventflow, employeeId]);


  const getdata = async () => {
    const config = {
      method: "get",
      url: `${ADD_EVENT}?type=event`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);

        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };
  useEffect(() => {
    getdata();
  }, [])


  //  event flow
  const [filteredevent, getneweve] = useState()
// console.log("NEWWWWW",filteredevent)

  // console.log("eventflow", eventflow)
  const getData = async () => {


    const config = {
      method: 'get',
      url: `${EVENT_WORKFLOW}?type=LEAVE`,
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`,
        'Content-Type': 'application/json'
      },
    }


    await axios(config)
      .then((res) => {

        // console.log('workflows - ', res.data.data);
        seteventflow(res.data.data)

      })
      .catch(err => {

        toast.error(err.response.data.message)
      })
  }

  useEffect(() => {
    getData()

  }, []);
  // table
  const [modalTitle, setModalTitle] = useState("");
  const [modalLink, setModalLink] = useState("");
  const [image, setImage] = useState("");

  let collegeId = sessionStorage.getItem("college_id");
  const getLocalEmployee = () => {
    return localStorage.getItem(LOCAL_EMPLOYEE)
      ? JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE))
      : null;
  };
  // const [emp, setEmpData] = useState(getLocalEmployee)

  const [emp, sety] =useState([]);
console.log("EMPLOYEE", emp);



  const getempData = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${employeeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        sety(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    };
    useEffect(()=>{
      getempData()
    },[])
  

    
      
  

  
  const getEmployeeNameById = (id) => {
    console.log("Looking for ID:", id); // Log the ID you're looking for
    const employee = emp.id == id ;
    return employee == true ? `${emp.first_name} ${emp.last_name}` : 'Unknown';
  };
  
    
   

  let CollegeId = sessionStorage.getItem("collge_id")
  // const showEditButton = (event) => {
  //   const {

  //     approver_1,
  //     approver_2,
  //     approver_3,
  //     approver_4,

  //   } = event;
  //   let isEditButtonShown = false;


  //   if ((employeeId == approver_1 && approver_1 !== 0 && approver_2 == 0 && approver_3 == 0 && approver_4 == 0)) {
  //     if (!isEditButtonShown) {
  //       isEditButtonShown = true;
  //       return true;
  //     }
  //   }
  //   if ((employeeId == approver_2 && approver_1 !== 0 && approver_2 != 0 && approver_3 == 0 && approver_4 == 0)) {
  //     if (!isEditButtonShown) {
  //       isEditButtonShown = true;
  //       return true;
  //     }
  //   }
  //   if ((employeeId == approver_3 && approver_1 !== 0 && approver_2 != 0 && approver_3 != 0 && approver_4 == 0)) {
  //     if (!isEditButtonShown) {
  //       isEditButtonShown = true;
  //       return true;
  //     }
  //   }
  //   if ((employeeId == approver_4 && approver_1 !== 0 && approver_2 != 0 && approver_3 != 0 && approver_4 != 0)) {
  //     if (!isEditButtonShown) {
  //       isEditButtonShown = true;
  //       return true;
  //     }

  //   }

  //   return false;
  // };
  const showEditButton = (event) => {
    const {
      approver_1,
      approver_2,
      approver_3,
      approver_4,
    } = event;

    // Conditions to display the Edit button
    if ((employeeId == approver_1 && approver_1 !== 0 && approver_2 == 0 && approver_3 == 0 && approver_4 == 0)) {
      return true;
    }
    if ((employeeId == approver_2 && approver_1 !== 0 && approver_2 != 0 && approver_3 == 0 && approver_4 == 0)) {
      return true;
    }
    if ((employeeId == approver_3 && approver_1 !== 0 && approver_2 != 0 && approver_3 != 0 && approver_4 == 0)) {
      return true;
    }
    if ((employeeId == approver_4 && approver_1 !== 0 && approver_2 != 0 && approver_3 != 0 && approver_4 != 0)) {
      return true;
    }
    if (role === "SUPERADMIN") {
      return true;
    }
    return false;
  };


  function shouldShowViewEventButton(event) {
    // Check if the fields exist and are equal to "APPROVED"
    const isApproved =
      (event.approval_status_1 === "APPROVED") ||
      (event.approval_status_2 === "APPROVED") ||
      (event.approval_status_3 === "APPROVED") ||
      (event.approval_status_4 === "APPROVED")
    return isApproved;
  }
  const colors = ["#364277", "#FF9F43"];



  const filteredData = alldata?.filter(item => item.college_id == collegeId);

console.log("filtered datas",filteredData);

  return (
    <div>

      <ModalAnnouncementAttachment title={modalTitle} img={image} setLink={setModalLink} />
      <AddEventModal reloadData={getdata} type={type} editdata={editpages} data2={data2} data={edit} collegeId={collegeId} />
      <ModalEventGlimps title={modalTitle} type={type} filteredevent={filteredevent} img={modalLink} setLink={setModalLink} data={data} collegeId={collegeId} />
      <ViewEventModel title={modalTitle} type={type} filteredevent={filteredevent} img={modalLink} setLink={setModalLink} data={data} collegeId={collegeId} />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Add Event</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <button
                        className="float-right btn btn-nex ml-2"
                        data-toggle="modal"
                        data-target="#AddEventModal"
                        onClick={() => { setType("add"); setEdit() }}
                      >
                        + Add New
                      </button>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">Added Events</h4>
                      </div>
                    </div>
                    <hr />
                    <table
                      id="datatable"
                      className="table table-bordered dt-responsive nowrap table-hover "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Sl No.</th>
                          <th>Title</th>
                          <th>Event Level</th>
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>Created At</th>
                          <th>Created BY</th>
                          <th>Attachment</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {role == "STAFF" || role === "ADMIN" ? (
                        <tbody>
                          {filterdata?.map((item, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.title}</td>
                              <td>{item?.level}</td>
                              <td>{item?.from_date?.split('T')[0]}</td>
                              <td>{item?.to_date?.split('T')[0]}</td>
                              <td>{item?.createdAt?.split('T')[0]}</td>
                              <td>{getEmployeeNameById(item?.createdBy)} </td>
                              <td>
                                

                              <button
                                  onClick={() => {
                                    // console.log(item?.attachment)
                                    setModalLink(item?.attachment);
                                    setModalTitle(item?.title);
                                    setImage(item?.attachment)
                                  }}
                                  data-toggle="modal"
                                  data-target="#ModalAnnouncementAttachment"
                                  className="btn btn-nex btn-sm my-1 ml-5"
                                  title="View"
                                >
                                  View
                                </button>
                               
                              </td>
                              <td>
                                {eventflow
                                  ?.filter(d => d.id === item.approval_flow)
                                  .map(event => {
                                    const approvers = [
                                      { id: event.approver_1, status: event.approval_status_1 },
                                      { id: event.approver_2, status: event.approval_status_2 },
                                      { id: event.approver_3, status: event.approval_status_3 },
                                      { id: event.approver_4, status: event.approval_status_4 }
                                    ].filter(approver => approver.id && approver.id !== 0);


                                    const hasPending = approvers.some(approver => approver.status === "PENDING");

                                    return (
                                      <div >
                                        <span
                                          className={`badge px-2 py-2 ${hasPending === true ? 'badge-soft-warning' : 'badge-soft-success'}`}
                                        >
                                          {hasPending === true ? 'PENDING' : 'APPROVE'}
                                        </span>
                                      </div>
                                    );
                                  })}
                              </td>
                              <td>
                                <div className="d-flex">
                                 
                                    {eventflow && eventflow.length > 0 && eventflow.map((event, index) => (
                                      showEditButton(event) ? (
                                        <i
                                          onClick={() => {
                                            setType("edit");
                                            setEdit(event);
                                            setdata2(item)                                          }}
                                          data-toggle="modal"
                                          data-target="#AddEventModal"
                                          // className="btn btn-nex btn-sm my-1 ml-3"
                                          // style={{ backgroundColor: colors[index % colors.length], borderRadius: "20%" }}
                                          className="ri-file-edit-fill ml-2 px-2 py-1 mt-1 btn-nex rounded text-white"
                                          title="Edit"
                                        >
                                        </i>
                                      ) : null
                                    ))}
                                  
                                 
                                    <i
                                      onClick={() => {
                                        setModalLink(item?.attachment);
                                        setModalTitle(item?.title);
                                        settingdata(item);
                                        const matchedEvents = eventflow?.filter((d) => d.id == item.approval_flow);
                                        // console.log("matchedEvents", matchedEvents)
                                        if (matchedEvents.length > 0) {
                                          getneweve(matchedEvents);
                                        } else {
                                          console.warn('No matching events found for approval_flow:', item.approval_flow);
                                        }
                                      }}
                                      data-toggle="modal"
                                      data-target="#Vieweventmodel"
                                      // className="btn btn-nex btn-sm my-1 ml-3"
                                      className="ri-eye-fill ml-2 btn-nex rounded px-2 mt-1 py-1 text-white"
                                    // style={{ backgroundColor: colors[index % colors.length], borderRadius: "20%" }}
                                    >
                                    </i>
                                 
                                </div>

                              </td>
                            </tr>
                          ))}

                        </tbody>
                      ) : (
                        <></>
                      )}



















                      {role == "SUPERADMIN" ? (
                        <tbody>
                          {filteredData?.map((item, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.title}</td>
                              <td>{item?.level}</td>
                              <td>{item?.from_date?.split('T')[0]}</td>
                              <td>{item?.to_date?.split('T')[0]}</td>
                              <td>{item?.createdAt?.split('T')[0]}</td>
                              <td>{getEmployeeNameById(item?.createdBy)}</td>
                              <td>
                                <button
                                  onClick={() => {
                                    // console.log(item?.attachment)
                                    setModalLink(item?.attachment);
                                    setModalTitle(item?.title);
                                    setImage(item?.attachment)
                                  }}
                                  data-toggle="modal"
                                  data-target="#ModalAnnouncementAttachment"
                                  className="btn btn-nex btn-sm my-1 ml-3"
                                  title="View"
                                >
                                  View
                                </button>
                              </td>
                              <td>
                                {eventflow
                                  ?.filter(d => d.id === item.approval_flow)
                                  .map(event => {
                                    const approvers = [
                                      { id: event.approver_1, status: event.approval_status_1 },
                                      { id: event.approver_2, status: event.approval_status_2 },
                                      { id: event.approver_3, status: event.approval_status_3 },
                                      { id: event.approver_4, status: event.approval_status_4 }
                                    ].filter(approver => approver.id && approver.id !== 0);

                                    const hasPending = approvers.some(approver => approver.status === "PENDING");

                                    return (
                                      <div key={event.id}>
                                        <span
                                          className={`badge px-2 py-2 ${hasPending ? 'badge-soft-warning' : 'badge-soft-success'}`}
                                        >
                                          {hasPending ? 'PENDING' : 'APPROVE'}
                                        </span>
                                      </div>
                                    );
                                  })}
                              </td>
                              <td>
                              <div className="d-flex">
                                
                                {eventflow && eventflow.length > 0 && eventflow.some((event) => showEditButton(event)) && (
                                  <i
                                    onClick={() => {
                                      const eventToEdit = eventflow.find((event) => showEditButton(event));
                                      setType("edit");
                                      setEdit(eventToEdit);
                                      setdata2(item)
                                    }}
                                    data-toggle="modal"
                                    data-target="#AddEventModal"
                                    className="ri-file-edit-fill ml-2 px-2 py-1 mt-1 btn-nex rounded text-white"
                                    title="Edit"
                                  >
                               
                                  </i>
                                )}
                                <i
                                  onClick={() => {
                                    setModalLink(item?.attachment);
                                    setModalTitle(item?.title);
                                    settingdata(item);
                                    const matchedEvents = eventflow?.filter((d) => d.id == item.approval_flow);
                                    // console.log("matchedEvents", matchedEvents)
                                    if (matchedEvents.length > 0) {
                                      getneweve(matchedEvents);
                                    } else {
                                      console.warn('No matching events found for approval_flow:', item.approval_flow);
                                    }
                                  }}
                                  data-toggle="modal"
                                  data-target="#Vieweventmodel"
                                  className="ri-eye-fill ml-2 mt-1 btn-nex rounded px-2 py-1 text-white"

                                >
                                
                                </i>
                                <i
                                  onClick={() => {
                                    setModalLink(item?.attachment);
                                    setModalTitle(item?.title);
                                    settingdata(item);
                                    const matchedEvents = eventflow?.filter((d) => d.id == item.approval_flow);
                                    // console.log("matchedEvents", matchedEvents)
                                    if (matchedEvents.length > 0) {
                                      getneweve(matchedEvents);
                                    } else {
                                      console.warn('No matching events found for approval_flow:', item.approval_flow);
                                    }
                                  }}
                                  data-toggle="modal"
                                  data-target="#ModalEventGlimps"
                                  className="ri-image-add-fill ml-2 mt-1 btn-nex rounded px-2 py-1 text-white"
                                  >

                               
                                </i>
                               
                                </div>  
                                {/* {eventflow && eventflow.length > 0 && eventflow.map((event, index) => (
                                   <div key={index}>
                                     {shouldShowViewEventButton(event) && (
                                       <button>View Event</button>
                                     )}
                                   </div>
                                 ))} */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (<></>)}
                    </table>























                    {alldata?.length == 0 ? <Nodata /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default AddEvent
