import React from 'react'

function Admission() {
  return (
    <div>
        <div className="register-area mt-2 pb-70">
  <div className="container">
    <div className="register" style={{ maxWidth: "100%" }}>
      {/* <h3></h3> */}
      <form action="modal/addLawClass.php" method="POST">
        <div className="row">
          <div className="col-lg-12">
            <label>Name of Student</label>
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Name of Student*"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              
              <input
                type="text"
                name="place"
                className="form-control"
                placeholder="Birth Place*"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="taluk"
                className="form-control"
                placeholder="Taluk*"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="district"
                className="form-control"
                placeholder="District*"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="dob"
                className="form-control"
                placeholder="Date of Birth*"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="cast"
                className="form-control"
                placeholder="Caste and Sub Caste*"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="category"
                className="form-control"
                placeholder="Category*"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="mother_name"
                className="form-control"
                placeholder="Mother Name"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="nation"
                className="form-control"
                placeholder="Nationality*"
              />
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 program-level"
            style={{ marginBottom: 0 }}
          >
            <div className="form-group">
              <p className="mb-1">Marital Status</p>
              {/* <div class="form-check"> */}
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  name="marital"
                  type="radio"
                  id="inlineCheckbox1"
                  defaultValue="single"
                />
                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  Single
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  name="marital"
                  type="radio"
                  id="inlineCheckbox2"
                  defaultValue="married"
                />
                <label className="form-check-label" htmlFor="inlineCheckbox2">
                  Married
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <select className="form-control" name="gender">
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="form-group">
              <div className="form-floating">
                <textarea
                  className="form-control"
                  cols={1}
                  rows={1}
                  name="address"
                  placeholder="Students Parmanent Address*"
                  id="floatingTextarea2"
                  defaultValue={""}
                />
                <label htmlFor="floatingTextarea2">Address</label>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="aadhar"
                className="form-control"
                placeholder="Aadhar Card No*"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="bank"
                className="form-control"
                placeholder="Bank Account No*"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email of Student*"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <input
                type="text"
                name="guardian"
                className="form-control"
                placeholder="Father / Husband / Guardians Name*"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="mobile"
                className="form-control"
                placeholder="Mobile No*"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="income"
                className="form-control"
                placeholder="Yearly Income*"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="university"
                className="form-control"
                placeholder="Name of University*"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="uni_place"
                className="form-control"
                placeholder="University Place*"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="exam"
                className="form-control"
                placeholder="Exam"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="pass_year"
                className="form-control"
                placeholder="Passing Year"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="seat_no"
                className="form-control"
                placeholder="Seat No"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="class"
                className="form-control"
                placeholder="Class"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <input
                type="text"
                name="college"
                className="form-control"
                placeholder="Name of College"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="percent"
                className="form-control"
                placeholder="Percentage"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="total_marks"
                className="form-control"
                placeholder="Total Marks"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="obt_marks"
                className="form-control"
                placeholder="Obtian Marks"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <div className="form-floating">
                <textarea
                  className="form-control"
                  name="choice_sub"
                  placeholder="Choice of Game and Subjects"
                  id="choice_sub"
                  style={{ height: 100 }}
                  defaultValue={""}
                />
                <label htmlFor="choice_sub">Choice of Game and Subjects</label>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <div className="form-floating">
                <textarea
                  className="form-control"
                  name="scholarship"
                  placeholder="If Obtain any Scholarship Please Fill Detail"
                  id="floatingTextarea2"
                  style={{ height: 100 }}
                  defaultValue={""}
                />
                <label htmlFor="floatingTextarea2">
                  If Obtained any Scholarship Please Fill Detail
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-12 table-responsive">
            <table className="table table-bordered border-danger rounded">
              <thead>
                <tr>
                  <th colSpan={2} className="text-center">
                    Exam{" "}
                  </th>
                  <th>Passing Year</th>
                  <th>Name of College</th>
                  <th>Seat No</th>
                  <th>Name of University and Board</th>
                  <th>Obtianed Marks</th>
                  <th>Class</th>
                  <th>Mark Sheet</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={10} align="center">
                    B.A <br /> B.COM <br /> B.S.C <br /> B.B.A <br /> or Others
                  </td>
                  <td>Semester-1</td>
                  <td>
                    <input
                      type="month"
                      name="pyear1"
                      id="pyear1"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="clg1"
                      id="clg1"
                      className="form-control"
                      pattern="[a-z]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="seat1"
                      id="seat1"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="board1"
                      id="board1"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="marks1"
                      id="marks1"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="class1"
                      id="class1"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      name="sheet1"
                      id="sheet1"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Semester-2</td>
                  <td>
                    <input
                      type="month"
                      name="pyear2"
                      id="pyear2"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="clg2"
                      id="clg2"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="seat2"
                      id="seat2"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="board2"
                      id="board2"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="marks2"
                      id="marks2"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="class2"
                      id="class2"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      name="sheet2"
                      id="sheet2"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Semester-3</td>
                  <td>
                    <input
                      type="month"
                      name="pyear3"
                      id="pyear3"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="clg3"
                      id="clg3"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="seat3"
                      id="seat3"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="board3"
                      id="board3"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="marks3"
                      id="marks3"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="class3"
                      id="class3"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      name="sheet3"
                      id="sheet3"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Semester-4</td>
                  <td>
                    <input
                      type="month"
                      name="pyear4"
                      id="pyear4"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="clg4"
                      id="clg4"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="seat4"
                      id="seat4"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="board4"
                      id="board4"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="marks4"
                      id="marks4"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="class4"
                      id="class4"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      name="sheet4"
                      id="sheet4"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Semester-5</td>
                  <td>
                    <input
                      type="month"
                      name="pyear5"
                      id="pyear5"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="clg5"
                      id="clg5"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="seat5"
                      id="seat5"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="board5"
                      id="board5"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="marks5"
                      id="marks5"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="class5"
                      id="class5"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      name="sheet5"
                      id="sheet5"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Semester-6</td>
                  <td>
                    <input
                      type="month"
                      name="pyear6"
                      id="pyear6"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="clg6"
                      id="clg6"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="seat6"
                      id="seat6"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="board6"
                      id="board6"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="marks6"
                      id="marks6"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="class6"
                      id="class6"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      name="sheet6"
                      id="sheet6"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Semester-7</td>
                  <td>
                    <input
                      type="month"
                      name="pyear7"
                      id="pyear7"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="clg7"
                      id="clg7"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="seat7"
                      id="seat7"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="board7"
                      id="board7"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="marks7"
                      id="marks7"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="class7"
                      id="class7"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      name="sheet7"
                      id="sheet7"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Semester-8</td>
                  <td>
                    <input
                      type="month"
                      name="pyear8"
                      id="pyear8"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="clg8"
                      id="clg8"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="seat8"
                      id="seat8"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="board8"
                      id="board8"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="marks8"
                      id="marks8"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="class8"
                      id="class8"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      name="sheet8"
                      id="sheet8"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Semester-9</td>
                  <td>
                    <input
                      type="month"
                      name="pyear9"
                      id="pyear9"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="clg9"
                      id="clg9"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="seat9"
                      id="seat9"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="board9"
                      id="board9"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="marks9"
                      id="marks9"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="class9"
                      id="class9"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      name="sheet9"
                      id="sheet9"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Semester-10</td>
                  <td>
                    <input
                      type="month"
                      name="pyear10"
                      id="pyear10"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="clg10"
                      id="clg10"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="seat10"
                      id="seat10"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="board10"
                      id="board10"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="marks10"
                      id="marks10"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="class10"
                      id="class10"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      name="sheet10"
                      id="sheet10"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Other</td>
                  <td>
                    <input
                      type="text"
                      name="other"
                      id="other"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="month"
                      name="pyear11"
                      id="pyear11"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="clg11"
                      id="clg11"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="seat11"
                      id="seat11"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="board11"
                      id="board11"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="marks11"
                      id="marks11"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="class11"
                      id="class11"
                      className="form-control"
                    />
                  </td>
                  <td>
                    <input
                      type="file"
                      name="sheet11"
                      id="sheet11"
                      className="form-control"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-3 col-md-6 ">
            <div className="form-group">
              <h5 htmlFor="">
                Upload Photo <small className="text-danger">*</small>
              </h5>
              <input
                type="file"
                name="image"
                className="form-control"
                required="required"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <div className="form-group">
              <h5 htmlFor="">
                Upload LC <small className="text-danger">*</small>
              </h5>
              <input
                type="file"
                name="lc"
                className="form-control"
                required="required"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <div className="form-group">
              <h5 htmlFor="">
                Upload Aadhar Card <small className="text-danger">*</small>
              </h5>
              <input
                type="file"
                name="aadhar"
                className="form-control"
                required="required"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <div className="form-group">
              <h5 htmlFor="">
                Upload 10<sup>th</sup> Mark Sheet
                <small className="text-danger">*</small>
              </h5>
              <input
                type="file"
                name="marksheeet_10"
                className="form-control"
                required="required"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <div className="form-group">
              <h5 htmlFor="">
                Upload 12<sup>th</sup> Mark Sheet
                <small className="text-danger">*</small>
              </h5>
              <input
                type="file"
                name="marksheet_12"
                className="form-control"
                required="required"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <div className="form-group">
              <h5 htmlFor="">
                Upload Caste Certificate<small className="text-danger">*</small>
              </h5>
              <input
                type="file"
                name="caste_certi"
                className="form-control"
                required="required"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <div className="form-group">
              <h5 htmlFor="">Upload Degree Certificate</h5>
              <input type="file" name="degree_certi" className="form-control" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <div className="form-group">
              <h5 htmlFor="">Upload Cermy Layer Certificate</h5>
              <input type="file" name="cermy_certi" className="form-control" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <div className="form-group">
              <h5 htmlFor="">Upload Income Certificate</h5>
              <input type="file" name="income_certi" className="form-control" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 ">
            <div className="form-group">
              <h5 htmlFor="">Upload Education Gap Affidavit</h5>
              <input type="file" name="cermy_certi" className="form-control" />
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12">
            <div className="form-group ">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input check mr-5"
                  name="agree"
                  type="checkbox"
                  defaultChecked=""
                  required=""
                  id="inlineCheckbox1"
                  defaultValue={1}
                />
                <h5 htmlFor="inlineCheckbox1 ml-5">
                  I Don't Apply in Any Other Course for Academic Course in the
                  Academic Sessions.
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-5">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input check mr-5"
                  name="promise"
                  type="checkbox"
                  defaultChecked=""
                  required=""
                  id="inlineCheckbox2"
                  defaultValue={1}
                />
                <h5 htmlFor="inlineCheckbox2 ">
                  I Promise, I Follow Swaminarayan University and College Rules
                  and Regulations Strictly.
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <h5>Place:</h5>
            <input type="text" className="form-control" name="place" />
          </div>
          <div className="col-md-4">
            <h5>Date:</h5>
            <input
              type="text"
              className="form-control"
              name="sign_date"
              defaultValue=""
              readOnly=""
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <h5>
              <u>Note :</u>
            </h5>
            <ol>
              <li>
                Students Pay Tuition Fee and Others Fee As per University and
                College Norms
              </li>
              <li>If Student was any Job Submit "No Objection Certificate"</li>
              <li>All Students Follow Bar Council of India's Rules Strictly</li>
              <li>Students Follow College and Institution's Rules Strictly </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button
              type="submit"
              className="default-btn float-end "
              value="law_insert"
              name="submit"
              style={{ pointerEvents: "all", cursor: "pointer" }}
            >
              Apply
              <span />
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

        </div>
  )
}

export default Admission