import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { toast } from 'react-toastify'
import { FEE_DETAILS, FEE_DETAILS_BY_STUDENT_ID, FEE_DETAILS_BY_STUDENT_ID_VIEW } from '../../../utils/fees.apiConst'
import { update } from "lodash";

import { ADDHOSTELFEE1 } from "../../../utils/fees.apiConst";

const FeeView = ({id, setLoading}) => {
    const [feeType, setFeeType] = useState("Academic");
    const [studentSessions, setStudentSessions] = useState([]);
    const [feeData, setFeeData] = useState([]);
    const [feeData2, setFeeData2] = useState([]);

    const [feeToBeCollected, setFeeToBeCollected] = useState("");
    const [paidFee, setPaidFee] = useState(0);
    const [pendingFee, setPendingFee] = useState(0);
    const [currPaidFee, setCurrPaidFee] = useState(0);
    const [currPendingFee, setCurrPendingFee] = useState(0);

    const [hostelFeeToBeCollected, setHostelFeeToBeCollected] = useState("");
    const [hostelPaidFee, setHostelPaidFee] = useState(0);
    const [hostelPendingFee, setHostelPendingFee] = useState(0);
    const [hostelCurrPaidFee, setHostelCurrPaidFee] = useState(0);
    const [hostelCurrPendingFee, setHostelCurrPendingFee] = useState(0);

    const [session, setSession] = useState("");

    const [hostelData, setHostelData] = useState([]);
    const [hostelTotal, setHostelTotal] = useState([]);

    console.log(feeData);

    async function getFeeData() {
        setLoading(1)
        const config = {
        method:'get',
        url:`${FEE_DETAILS_BY_STUDENT_ID}/${id}`,
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
        },
        }
        
        await axios(config)
        .then(res=>{
            setLoading(0)
            console.log('fee data 1 - ', res.data.data);
            let temp = [];
            for(let i in res.data.data) {
                temp.push(res.data.data[i].session_id);
            }
            console.log('sessions - ', temp);
            setStudentSessions(temp);
            setFeeData(res.data.data);
        })
        .catch(err=>{
        setLoading(0)
        toast.error('Something went wrong')
        })

        console.log('hi');
        const config1 = {
            method:'get',
            url:`${FEE_DETAILS_BY_STUDENT_ID_VIEW}/${id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
        }
        setLoading(1);
        await axios(config1)
        .then(res=>{
        setLoading(0);
        console.log('fee data 2 - ', res.data.data);
        setFeeData2(res.data.data)
        })
        .catch(err=>{
        setLoading(0)
        toast.error('Something went wrong')
        })
    }

    const setFeeValues = () => {
        console.log("feeData",feeData)
        let amount = 0;
        let paid = 0;
        let currPaid=0,currPending=0;
        feeData?.map((value, idx) => {
            amount = amount + value.amount;
            paid = paid + value.paid_amount;
            currPaid = feeData[feeData.length-1]?.paid_amount;
            currPending = feeData[feeData.length-1]?.amount - feeData[feeData.length-1]?.paid_amount;
            console.log('hi1');
        });

        feeData2?.filter((s) => !studentSessions.includes(s.session_id)).map((i,key)=> {
            console.log('hihi');
            amount = amount + i.amount;
            currPending = currPending + i.amount;
        });

        const formattedAmount = amount.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0
          });
        setFeeToBeCollected(formattedAmount);

        const formattedPaid = paid.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0
          });
        setPaidFee(formattedPaid);

        const formattedPending = (amount-paid).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0
          });
        setPendingFee(formattedPending);

        const formattedCurrPaid = (currPaid).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0
          });
          setCurrPaidFee(formattedCurrPaid);
        
        const formattedCurrPending = (currPending).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0
          });
        setCurrPendingFee(formattedCurrPending);
    }

    let x;

  
  const handleSearch = async () => {
    if (!session) {
    //   toast.error("Please Enter Required Details");
      return;
    }
    let main = [];
    setLoading(1);
    let url1;

    id
      ? (url1 = `${ADDHOSTELFEE1}?session_id=${session}&student_id=${id}&college_id=${""}`)
      : (url1 = `${ADDHOSTELFEE1}?session_id=${session}&college_id=${""}`);

    if(id) {

    const config2 = {
      method: "get",
      url: url1,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setLoading(0);
        console.log("Hostel data - ", res.data.data);
        setHostelData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        // toast.error("Something went wrong");
      });
    }
  };

    useEffect(()=>{
        getFeeData();
    },[]);

    useEffect(()=>{
        setFeeValues();
        console.log("id", id);
        setSession(feeData[0]?.session_id);
        handleSearch();
    },[feeData]);

    useEffect(()=> {
        setFeeValues();
    },[feeData2]);


    useEffect(()=> {
        let newData = []
        const updatedData = hostelData?.map((value,idx) => {
            let amt = 0;
            value.payment?.map((v,index)=> {
                amt = amt + parseInt(v.payment_amount);
            })
            return { ...value, total: amt};
        })
        console.log("updated hostel data - ", updatedData);
        setHostelTotal(updatedData);
    },[hostelData]);

    useEffect(()=> {
        let feeCollected = 0;
        let paid = 0;
        let bal = 0;
        let currPaid=0;
        let currBal=0;
        hostelTotal?.map((i,key)=>{
            feeCollected = feeCollected + parseInt(i.amount);
            paid = paid +  i.total;
            bal = bal + (i.amount - i.total);
        })
        currPaid = hostelTotal?[hostelTotal.length-1].total:0;
        currBal = hostelTotal?[hostelTotal.length-1].amount:0 - hostelTotal?[hostelTotal.length-1].total:0;
        setHostelCurrPaidFee(currPaid);
        setHostelCurrPendingFee(currBal);
        setHostelFeeToBeCollected(feeCollected);
        setHostelCurrPaidFee(paid);
        setHostelCurrPendingFee(bal);
        console.log('hostel total - ', hostelTotal);
    },[hostelTotal]);

    if(feeType === "Academic" && (feeData.length !== 0 || feeData2.length !== 0)) return (
        <div>
            <div className="pt-3">
                <ul className="nav nav-tabs">
                    <li className="nav-item cursor-pointer">
                        <a className={`nav-link ${feeType === "Academic" && "active"}`}
                         onClick={() => setFeeType("Academic")}
                        >Academic</a>
                        
                    </li>
                    <li className="nav-item cursor-pointer">
                        <a className={`nav-link ${feeType === "Hostel" && "active"}`}
                        onClick={() => setFeeType("Hostel")}
                        >Hostel</a>
                    </li>
                </ul>
            </div>
            <div className="d-flex justify-content-between pt-3">
                <div className="card mx-3">
                    <div className="card-body p-1">
                        <p className="mt-2 pl-2 text-success" style={{ fontSize: '1.2em' }}>{currPaidFee}</p>
                        <hr className="bg-success my-1" style={{ height: '2px'}} />
                        <p className="text-success pl-2 small">Current Paid Amount</p>
                    </div>
                </div>
                <div className="card mx-3">
                    <div className="card-body p-1 px-1">
                        <p className="mt-2 pl-2 text-warning" style={{ fontSize: '1.2em' }}>{currPendingFee}</p>
                        <hr className="bg-warning my-1" style={{ height: '2px'}} />
                        <p className="text-warning pl-2 small">Current Pending Amount</p>
                    </div>
                </div>
                <div className="card mx-3">
                    <div className="card-body p-1 px-1">
                        <p className="mt-2 pl-2 text-primary" style={{ fontSize: '1.2em' }}>{feeToBeCollected}</p>
                        <hr className="bg-primary my-1 mt-0" style={{ height: '2px'}} />
                        <p className="text-primary pl-2 small">Fees to be Collected</p>
                    </div>
                </div>
                <div className="card mx-3">
                    <div className="card-body p-1 px-1">
                        <p className="mt-2 pl-2 text-success" style={{ fontSize: '1.2em' }}>{paidFee}</p>
                        <hr className="bg-success my-1" style={{ height: '2px' }} />
                        <p className="text-success pl-2 small">Total Paid Amount</p>
                    </div>
                </div>
                <div className="card mx-3">
                    <div className="card-body p-1 px-1">
                        <p className="mt-2 pl-2 text-danger" style={{ fontSize: '1.2em' }}>{pendingFee}</p>
                        <hr className="bg-danger my-1" style={{ height: '2px' }} />
                        <p className="text-danger pl-2 small">Total Pending Amount</p>
                    </div>
                </div>
                
            </div>
            <div className='StudentFee'>
                <div className="row">
                    <div className="col-12 table-responsive">
                    <table className="table table-bordered nowrap overflow-auto">
                            <thead>
                                <tr>
                                    <th>Academic Year</th>
                                    <th>Class</th>
                                    <th>Amount</th>
                                    <th>Balance</th>
                                    <th>Paid</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                feeData?.map((i,key)=>(
                                <tr>
                                    <td>{i?.session_id}</td>
                                    <td>{i?.class}</td>
                                    <td>{i?.amount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                    <td>{(i?.amount - i?.paid_amount)?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                    <td>{i?.paid_amount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                    <td>
                                        <span className={`badge badge-soft-${i?.amount - i?.paid_amount == 0 ? 'success' :i?.paid_amount == 0?'danger':'warning'}`}>
                                            {i?.amount - i?.paid_amount == 0 ? 'Paid' :i?.paid_amount == 0?'Not Paid':'partial'}
                                        </span> 
                                    </td>
                                </tr>
                                ))
                            }
                            {
                                feeData2?.filter((s) => !studentSessions.includes(s.session_id)).map((i,key)=>(
                                    <tr>
                                        <td>{i?.session_id}</td>
                                        <td>{i?.class}</td>
                                        <td>{i?.amount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                        <td>{i?.amount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                        <td>₹0</td>
                                        <td>
                                            <span className={`badge badge-soft-danger`}>
                                                Not Paid
                                            </span> 
                                        </td>
                                    </tr>
                                    ))
                            }
                            </tbody>
                            <br/>
                            <br/>
                            <br/>
                            <br/>      
                        </table>
                    </div>
                </div>
            </div>
        </div>
        )
        else if(feeType === "Hostel" && hostelTotal.length !== 0) return (
            <div>
            <div className="pt-3">
                <ul className="nav nav-tabs">
                    <li className="nav-item cursor-pointer">
                        <a className={`nav-link ${feeType === "Academic" && "active"}`}
                         onClick={() => setFeeType("Academic")}
                        >Academic</a>
                        
                    </li>
                    <li className="nav-item cursor-pointer">
                        <a className={`nav-link ${feeType === "Hostel" && "active"}`}
                        onClick={() => setFeeType("Hostel")}
                        >Hostel</a>
                    </li>
                </ul>
            </div>
            <div className="d-flex justify-content-between pt-3">
                <div className="card mx-3">
                    <div className="card-body p-1">
                        <p className="mt-2 pl-2 text-success" style={{ fontSize: '1.2em' }}>{hostelCurrPaidFee.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</p>
                        <hr className="bg-success my-1" style={{ height: '2px'}} />
                        <p className="text-success pl-2 small">Current Paid Amount</p>
                    </div>
                </div>
                <div className="card mx-3">
                    <div className="card-body p-1 px-1">
                        <p className="mt-2 pl-2 text-warning" style={{ fontSize: '1.2em' }}>{hostelCurrPendingFee.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</p>
                        <hr className="bg-warning my-1" style={{ height: '2px'}} />
                        <p className="text-warning pl-2 small">Current Pending Amount</p>
                    </div>
                </div>
                <div className="card mx-3">
                    <div className="card-body p-1 px-1">
                        <p className="mt-2 pl-2 text-primary" style={{ fontSize: '1.2em' }}>{hostelFeeToBeCollected.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</p>
                        <hr className="bg-primary my-1 mt-0" style={{ height: '2px'}} />
                        <p className="text-primary pl-2 small">Fees to be Collected</p>
                    </div>
                </div>
                <div className="card mx-3">
                    <div className="card-body p-1 px-1">
                        <p className="mt-2 pl-2 text-success" style={{ fontSize: '1.2em' }}>{hostelCurrPaidFee.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</p>
                        <hr className="bg-success my-1" style={{ height: '2px' }} />
                        <p className="text-success pl-2 small">Total Paid Amount</p>
                    </div>
                </div>
                <div className="card mx-3">
                    <div className="card-body p-1 px-1">
                        <p className="mt-2 pl-2 text-danger" style={{ fontSize: '1.2em' }}>{hostelCurrPendingFee.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</p>
                        <hr className="bg-danger my-1" style={{ height: '2px' }} />
                        <p className="text-danger pl-2 small">Total Pending Amount</p>
                    </div>
                </div>
                
            </div>
            <div className='StudentFee'>
                <div className="row">
                    <div className="col-12 table-responsive">
                    <table className="table table-bordered nowrap overflow-auto">
                            <thead>
                                <tr>
                                    <th>Sl No.</th>
                                    <th>Session</th>
                                    <th>Year</th>
                                    {/* <th>Route</th> */}
                                    {/* <th>Pickup Point</th> */}
                                    <th>Amount</th>
                                    <th>Balance</th>
                                    <th>Paid</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                hostelTotal.map((i,key)=>(
                                <tr>
                                    <td>{key+1}</td>
                                    <td>{i?.session_id}</td>
                                    <td>{i?.year}</td>
                                    {/* <td>{routeData?.find(obj => obj.id == i.route_id).title}</td> */}
                                    {/* <td>{pickuppointData?.find(obj => obj.id == i.pickuppoint_id).name}</td> */}
                                    <td>{i.amount?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                    <td>{(i.amount - i.total)?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                    <td>{i.total?.toLocaleString('en-IN',{style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                    <td>
                                        <span className={`badge badge-soft-${i.amount - i.total == 0 ? 'success' :i?.total == 0?'danger':'warning'}`}>
                                            {i.amount - i.total == 0 ? 'Paid' :i?.total == 0?'Not Paid':'partial'}
                                        </span> 
                                    </td>
                                </tr>
                                ))
                            }
                            </tbody>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        )
        else return (
            <div>
            <div className="pt-3">
                <ul className="nav nav-tabs">
                    <li className="nav-item cursor-pointer">
                        <a className={`nav-link ${feeType === "Academic" && "active"}`}
                         onClick={() => setFeeType("Academic")}
                        >Academic</a>
                        
                    </li>
                    <li className="nav-item cursor-pointer">
                        <a className={`nav-link ${feeType === "Hostel" && "active"}`}
                        onClick={() => setFeeType("Hostel")}
                        >Hostel</a>
                    </li>
                </ul>
            </div>
            <div className="pt-3">
                <h6 className="text-danger">Data Not Found</h6>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
            </div>
    )
}

export default FeeView;