import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Nodata from '../../../../Components/NoData/Nodata'
import { ANNOUNCEMENTS, EMPLOYEE, TEAM_ANNOUNCEMENTS } from '../../../../utils/apiConstants'
import ModalAnnouncement from '../../../Communicate/ModalAnnouncement'
import ModalAnnouncementView from '../../../Communicate/ModalAnnouncementView'
import { LOCAL_COLLEGE } from '../../../../utils/LocalStorageConstants'
import { SESSION_EMPLOYEE_ID } from '../../../../utils/sessionStorageContants'

function EmployeeTeamAnnouncement({ setLoading, collegeId }) {
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([]);
  const [type, setType] = useState('')
  const [edit, setEdit] = useState()
  const [modalTitle, setModalTitle] = useState("");
  const [modalLink, setModalLink] = useState("");

  const [image, setImage] = useState("");


    
  let collegeIdss=sessionStorage.getItem("college_id")
  console.log("COLLEGE ID FROM EMPLOYEE ANOUNCEMENT",collegeIdss, typeof(collegeIdss))
  
  const getData = async () => {

    setLoading(1)

    const config = {
      method: 'get',
      url: `${TEAM_ANNOUNCEMENTS}?college_id=${collegeId}`,
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`,
        'Content-Type': 'application/json'
      },
    }


    await axios(config)
      .then((res) => {
        console.log(res);
        setData(res.data.data)
        // toast.success("data fetched")
        setLoading(0)
      })
      .catch(err => {
        toast.error(err.response.data.message)
        setLoading(0)
      })
  }

  useEffect(() => {
    getData()
  }, [])
  const getAlldata = async () => {
    const config = {
      method: "get",
      url: `${ANNOUNCEMENTS}?type=announcement`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);

        setAllData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    getAlldata();
  }, [])
  const [getCollegeId, setcollegeid] = useState()
  useEffect(() => {
    let getCollegeId = sessionStorage.getItem("college_id")
    setcollegeid(getCollegeId)
    // console.log("getCollegeId", getCollegeId)
  }, [sessionStorage.getItem("college_id")])

  // console.log("alllData", allData)
 
  const getColleges = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };
  const [collegeOpt, setCollegeOpt] = useState(getColleges);

  useEffect(() => {
    setCollegeOpt(getColleges);
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  const [employeeId, setEmployeeId] = useState(
    sessionStorage.getItem(SESSION_EMPLOYEE_ID)
  );
  const [empColleges, setEmpColleges] = useState([])

  const [empDetails, setEmpDetails] = useState();

  const getEmployee = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${employeeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        let clgs = JSON.parse(res.data.data['multi_clg_id']);
        setEmpColleges(collegeOpt.filter(item => clgs?.includes(item.id)));
        console.log('emp details - ', res.data.data);
        setEmpDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (employeeId) getEmployee();
  }, [employeeId]);


  const ids = empColleges.map(college => college.id);

  // console.log("ids", ids);


  const mappedData = allData?.map(item => {
    let staffData = [];
    try {
      staffData = item.staff ? JSON.parse(item.staff) : [];
    } catch (e) {
      console.error("Error parsing staff data for item:", item.id, e);
    }

    // Ensure staffData is an array
    if (!Array.isArray(staffData)) {
      staffData = [];
    }

    return { ...item, staffData };
  });

 
  const filteredData = mappedData?.filter(item =>
    item.staffData.some(data => {

      const collegeIdArray = Array.isArray(data.college_id) ? data.college_id : [];
      const isCollegeIdAll = collegeIdArray.some(college => college.value === "All");
     
       
        const isCollegeIdMatch = collegeIdArray.some(college => collegeIdss.includes(parseInt(college.value)));
 
    
    

      return isCollegeIdAll || isCollegeIdMatch;
    })
  );


  return (
    <div className='TeamAnnouncement'>
      <ModalAnnouncement reloadData={getAlldata} type={type} data={edit} setLoading={setLoading} collegeId={collegeId} />
      <ModalAnnouncementView title={modalTitle} img={modalLink} setLink={setModalLink} data={data} collegeId={collegeId} />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Staff Announcement</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Team</li>
                      <li className="breadcrumb-item active">Announcement</li>
                    </ol>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card">
                <div className="card-body">

                  <div className="row mt-4">
                    <div className="col-md-4">
                      {" "}
                      <h4 className="card-title">All Announcements</h4>
                    </div>
                  </div>
                  <hr />


                  <table
                    id="datatable"
                    className=" table table-bordered  table-hover"
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Sl No.</th>
                        <th>Title</th>
                        <th>Anncouncement For</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Created on</th>
                        <th>Attachment</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData?.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.title}</td>
                          <td>{item.students != 0 && item.staff != 0 && item.parents != 0 ? "Staff, Student and Parent" :
                            item.students != 0 && item.staff != 0 && item.parents == 0 ? "Staff and Student " :
                              item.students != 0 && item.staff == 0 && item.parents != 0 ? "Student and Parent" :
                                item.students == 0 && item.staff != 0 && item.parents != 0 ? "Staff and Parents" :
                                  item.students == 0 && item.staff != 0 && item.parents == 0 ? "Staff" :
                                    item.students != 0 && item.staff == 0 && item.parents == 0 ? "Student" :
                                      item.students == 0 && item.staff == 0 && item.parents != 0 ? "Parent" : ""}</td>
                          <td>{item?.from_date?.split('T')[0]}</td>
                          <td>{item?.to_date?.split('T')[0]}</td>
                          <td>{item?.createdAt?.split('T')[0]}</td>
                          <td>
                            <button
                              onClick={() => {
                                console.log(item?.attachment)
                                setModalLink(item?.attachment);
                                setModalTitle(item?.title);
                                setImage(item?.attachment)
                              }}
                              data-toggle="modal"
                              data-target="#ModalAnnouncementAttachment"
                              className="btn btn-nex btn-sm my-1 ml-3"
                              title="View"
                            >
                              View
                            </button>
                          </td>
                          <td>


                            <button
                              onClick={() => {
                                setModalLink(item?.attachment);
                                setModalTitle(item?.title);
                                setData(item);
                              }}
                              data-toggle="modal"
                              data-target="#ModalAnnouncementView"
                              className="btn btn-nex btn-sm my-1 ml-3"
                            >
                              View
                            </button>


                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {allData?.length == 0 ? <Nodata /> : null}
                </div>
              </div>
            </div>
            {/*         
            <div className="container-fluid">
              <div className="card">
                <div className="card-body">
                  <div >
                    <div>
                      {data && data.length !== 0 ? data?.map((i, key) => (
                        <div
                          className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow cursor-normal"

                        >
                          <div className="col-11" key={key}>
                            <div className="role-title">
                              <h4> {i.title}</h4>
                            </div>
                            <div className="role-code">
                              <p>{i.content}</p>
                            </div>
                            <div className="role-date">
                              <p className='text-muted'> {i.date.split("T")[0]}</p>
                            </div>
                          </div>
                          <div className="col-1 d-flex align-items-center justify-content-end">
                            <div className='d-flex justify-content-between' style={{ fontSize: '18px' }}>


                              {i.attachment &&
                                <div className='px-1 rounded secondary mr-2'>
                                  <a href={i.attachment} target="_" className='text-secondary'><i className="ri-attachment-2"></i></a>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      ))
                        :
                        <Nodata titleTop={'No Announcements Added'} />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div> */}




          </div>
        </div>
      </div>


    </div>
  )
}


export default EmployeeTeamAnnouncement