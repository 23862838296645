import React, { useState, useRef, useEffect } from 'react'
import ModalEarningPolicy from '../../../../modals/HR/Leave/ModalEarningPolicy'
import { LEAVE_EARNING_POLICY } from '../../../../utils/apiConstants';
import axios from 'axios';
import Nodata from '../../../../Components/NoData/Nodata';
import { useDownloadExcel } from "react-export-table-to-excel";
import { LOCAL_COLLEGE } from '../../../../utils/LocalStorageConstants';
import { useReactToPrint } from "react-to-print";

function EarningPolicy({setLoading}) {
    const [data, setData] = useState([]);
    const [type, setType] = useState()
    const [edit, setEdit] = useState();
    const ctData = JSON.parse(localStorage.getItem("ALL_DATA")).college_type

    const getCollegeData = () => {
        return localStorage.getItem(LOCAL_COLLEGE)
          ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
          : null;
      };
    
    const [collegeOpt, setCollegeOpt] = useState(getCollegeData());


    const getData = async () => {
        setLoading(1)
        const config = {
            method: 'get',
            url: LEAVE_EARNING_POLICY,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },
        };

        await axios(config)
            .then((res) => {
                setLoading(0)
                console.log(res.data.data);
                setData(res.data.data)
            })
            .catch(err => {
                setLoading(0)
                console.log(err)
            })

    }

    useEffect(() => {
        getData();
    }, [])

    const dept = [
        {
            title: 'Proterated over a year, earned on daily basis',
            code: 'PRORATED - DAILY'
        },
        {
            title: 'Proterated over a year, earned on monthly basis',
            code: 'PRORATED - MONTHLY'
        },
        {
            title: 'Earned immediately at Earning Start Date',
            code: 'EARNED IMMEDIATELY'
        },
    ]

    const tableRef = useRef();

    const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: " Earning Policy List ",
      sheet: "Users",
    });

    const PrintRecipt = useReactToPrint({
        content: () => tableRef.current,
      });

  
    // const handlePrint = () => {
    //   PrintRecipt();
    // };

    const handlePrint = () => {
        // Make the table visible
        tableRef.current.style.display = 'table';
      
        // Delay the PDF export
        setTimeout(() => {
          PrintRecipt();
          // Hide the table again if needed
          tableRef.current.style.display = 'none';
        }, 1); // Adjust the delay as needed
      };

    const getCurrentDate = () => {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
      return formattedDate;
    };
    
  return (
    <div className='EarningPolicy'>
        <ModalEarningPolicy reloadData={getData} type={type} data={edit} setLoading={setLoading}/>
        <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Earning Policy</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="/">Leave</a>
                                            </li>
                                            <li className="breadcrumb-item active">Custom Approver</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end page title */}


                        <div className="container">
                            <div className="card">
                                <div className="card-body">

                                    <div className="row d-flex justify-content-end p-3">
                                        <button
                                            className="btn btn-rounded btn-success btn-outline px-4"
                                            data-toggle="modal"
                                            data-target="#ModalEarningPolicy"
                                            onClick={() => { setType("add"); setEdit() }}
                                        >
                                            Add +
                                        </button>
                                        <button
                      className="btn btn-primary rounded-pill ml-3 float-right"
                      onClick={onDownload}
                    >
                      Export Excel
                    </button>
                    <button
                              className="btn btn-primary rounded-pill ml-2"
                              onClick={handlePrint}
                            >
                              Export PDF
                            </button>
                                    </div>

                                    

                                    <div>
                                        {data && data.length !== 0 ?data.map((i, key) => (
                                            <div
                                                className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow"
                                                data-toggle="modal"
                                                data-target="#ModalEarningPolicy"
                                                onClick={() => { setType("edit"); setEdit(i); }}
                                            >
                                                <div className="col-11" key={key}>
                                                    <div className="role-title">
                                                        {i.description}
                                                    </div>
                                                    <div className="role-code">
                                                    <span className="badge badge-soft-success ml-3 mt-1" style={{fontSize:'1em'}}><b>Code : {i.id}</b></span>
                                                    </div>
                                                </div>
                                                <div className="col-1 d-flex align-items-center justify-content-end">
                                                    {'>'}
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <Nodata/>
                                        }
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                                <br/>
                                <br/>
                            </div>
                            <table
                                      id="table_id"
                                      ref={tableRef}
                                      className="display table table-bordered  nowrap table-hover "
                                      style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%", display: 'none' }}
                                    >
                                        <div className="col-12 text-center">
                                        

                                        <thead>
                                          <tr>
                                            <td colSpan={10}>
                                              <div>
                                                <h4 className="text-center">
                                                  Earning Policy List -{" "}
                                                    {data?.length > 0 && data[0]?.college_id &&
                                                      collegeOpt?.find((s) => s.id === data[0]?.college_id)?.name}
                                                </h4>
                                                <span>
                                                  <p className="text-right float-right">
                                                    Printed On - {getCurrentDate()}
                                                  </p>
                                                </span>
                                              </div>
                                            </td>
                                          </tr>
                                            <tr>
                                                <th>Sl.No.</th>
                                                <th>Earning Policy</th>
                                                <th>Description</th>
                                                <th>Method</th>
                                                <th>Accrual Period</th>
                                                <th>Earned At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                          data && data?.length == 0
                                          ?
                                          <tr>
                                            <td colSpan={10}>
                                              <Nodata />
                                            </td>
                                          </tr>
                                          :
                                          data
                                          .map((i, key) => {
                                            return <tr key={key}>
                                              <td>{key + 1}</td>
                                              <td>{i?.id}</td>
                                              <td>{i?.description}</td>
                                              <td>{i?.method}</td>
                                              <td>{i?.accrual_period}</td>
                                              <td>{i?.earned_at}</td>
                                              </tr>
                                          })
                                        }
                                        </tbody>
                                        </div>
                                    </table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default EarningPolicy;