import React, { useState, useEffect, useRef } from "react";
import { ANNOUNCEMENTS, LEAVE_LEAVE_TYPE } from "../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import "../../utils/input.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import {
  ASSET_EMPLOYEE_DOCUMENT,
  ASSET_MEDIA,
} from "../../utils/AssetsReferenceTypes";
import { Input, Form, Button, FormInstance } from "antd";
import { Checkbox } from "antd";
import { getFileUrl } from "../../Helpers/Helpers";
import DocumentsModal from "../../modals/Students/DocumentsModal";
import { Switch } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { DatePicker } from "antd";

import moment from "moment";
import {
  ALL_DATA,
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_EMPLOYEE,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import {
  sessionOpt,
  sessionOpt as SessionOpt,
} from "../../Data/jsonData/Academics/Academics";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
  CLASS_DATA,
  CLASS_NAME,
} from "../../utils/Academics.apiConst";
import {
  SESSION_COLLEGE_ID,
  SESSION_EMPLOYEE_ID,
} from "../../utils/sessionStorageContants";
import ModalAnnouncementAttachment from "./ModalAnnouncementAttachment";
const { RangePicker } = DatePicker;
function ModalAnnouncement(props) {
  console.log("props", props.setLoading);
  const [loading, setLoading] = useState(false);

  const getColleges = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };
  const getDepartment = () => {
    return localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;
  };
  const getCollegeId = () => {
    return sessionStorage.getItem(SESSION_COLLEGE_ID)
      ? sessionStorage.getItem(SESSION_COLLEGE_ID)
      : "1111000";
  };
  const getEmployee = () => {
    return localStorage.getItem(LOCAL_EMPLOYEE)
      ? JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE))
      : null;
  };

  const getLocalEmployee = () => {
    return localStorage.getItem(LOCAL_EMPLOYEE)
      ? JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE))
      : null;
  };

  const changeCollegeId = (id) => {
    setSelectedCollege(id);
  };
  const changeCollegeId2 = (id) => {
    setSelectedCollegestaff(id);
  };
  const changeemployee = (id) => {
    setemployeeOpt(id);
  };
  const changedepartment = (id) => {
    setDepartmentOpt(id);
    setdepartmentOptp(id);
  };

  const getLocalDepartment = () => {
    return localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;
  };

  const [title, setTitle] = useState("");

  const [desc, setDesc] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [staff, setStaff] = useState(false);
  // console.log("staff", staff)
  const [selectedCollege, setSelectedCollege] = useState("All", getCollegeId);
  const [selectedCollegestaff, setSelectedCollegestaff] = useState([]);
  const [selectfilter, setselectfilter] = useState([]);
  const [stafffilter, setstafffilter] = useState([]);
  const [selectedCollegestudnt, setselectedCollegestudnt] = useState([]);

  const [selectcollegeparent, setselectcollegeparent] = useState([]);

  const [employeeOpt, setemployeeOpt] = useState([]);
  const [collegeOpt, setCollegeOpt] = useState(getColleges);
  const [collegeOptp, setCollegeOptp] = useState(getColleges);
  const [departmentOpt, setDepartmentOpt] = useState([]);
  const [departmentOptp, setdepartmentOptp] = useState([]);

  const [selectedSessions, setSelectedSessions] = useState([]);
  const [selectedSessionp, setSelectedSessionp] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState(getDepartment);
  const [selectedDepartmentp, setSelectedDepartmentp] = useState(getDepartment);
  const [selectedemployee, setselectedemployee] = useState(getLocalEmployee);

  const [classOpt, setClassOpt] = useState([]);
  const [parenrclassOpt, setClassOptp] = useState([]);
  const [semOpt, setsemOpt] = useState([]);
  const [semOptp, setsemOptp] = useState([]);
  const [semdata, getsemdata] = useState([]);
  const [announcement, setannouncement] = useState();
  const [students, setStudents] = useState(false);
  const [parents, setParents] = useState(false);
  const [allColleges, setAllColleges] = useState(false);

  const [dayCount, setDayCount] = useState("");
  const [active, setActive] = useState(1);
  const [paid, setPaid] = useState(0);
  const [negative, setNegative] = useState(0);
  const [reason, setReason] = useState(1);
  const [paidLeaveAmount, setPaidLeaveAmount] = useState(0);

  const [info, setInfo] = useState({
    multi_clg_id: [],
    college_id: "",
    multi_employee_id: [],
    employee_id: "",

    multi_clg_id2: [],
    college_id2: "",
    multi_dpt_id: [],
    department_id: "",
    session_id: "",
    multi_class_id: [],
    class_id: "",
    multi_sem_id: [],
    current_semester: "",
    attachment: "",
    attachmentUrl: "",

    multi_clg_id3: [],
    college_id3: "",
    multi_dpt_idp: [],
    department_idp: "",
    session_idp: "",
    multi_class_idp: [],
    class_idp: "",
    multi_sem_idp: [],
    current_semesterp: "",
  });

  // console.log("prop", props)
  console.log("INFO.ATTACHMENT", info.attachment);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  useEffect(() => {
    setCollegeOpt(getColleges);
    setCollegeOptp(getColleges);
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  useEffect(() => {
    setDepartmentOpt(getDepartment);
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);
  const [attachment, setAttachment] = useState("");

  //Function to clear the data
  const clearData = () => {
    setTitle("");
    setDesc("");
    setDayCount("");
    setActive(1);
    setPaid(0);
    setNegative(0);
    setReason(1);
    setPaidLeaveAmount(0);

    setFromDate("");
    setToDate("");
    // fileref.current.value = null;
    setStaff(0);
    setParents(0);
    setStudents(0);
    setAllColleges(0);
    setemployeeOpt("");
    setselectedCollegestudnt("");
    setSelectedCollegestaff("");
    setselectcollegeparent("");
    setDepartmentOpt("");
    setSelectedSessions("");
    setSelectedSessionp("");
    setClassOpt(" ");
    setdepartmentOptp("");
    setClassOptp("");
    setsemOpt("");
    setsemOptp("");
    info.multi_clg_id = [];
    info.college_id = "";
    info.multi_employee_id = [];
    info.employee_id = 0;
    info.multi_clg_id2 = [];
    info.college_id2 = "";
    info.multi_dpt_id = [];
    info.department_id = "";
    info.session_id = "";
    info.multi_class_id = [];
    info.class_id = "";
    info.multi_sem_id = [];
    info.current_semester = "";

    info.multi_clg_id3 = [];
    info.college_id3 = "";
    info.multi_dpt_idp = [];
    info.department_idp = "";
    info.session_idp = "";
    info.multi_class_idp = [];
    info.class_idp = "";
    info.multi_sem_idp = [];
    info.current_semesterp = "";
  };

  const modules = {
    placeholder: "Write Detailed Description of Announcement Here...",
  };

  ////////////////////////////////////////STAFF ///////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  let options = [
    { value: "All", label: "All" },
    ...(collegeOpt?.map((item) => ({
      value: item?.id,
      label: item?.name,
    })) || []),
  ];
  const [option1, setoptions] = useState([
    { value: "All", label: "All" },
    ...selectedemployee?.map((item) => ({
      value: item?.id,
      label: `${item?.first_name} ${" "} ${item?.last_name}`,
    })),
  ]);

  const handleCollegeSelect = (selectedOption) => {
    setSelectedCollegestaff(selectedOption);

    const selectedValues = selectedOption.map((option) => option.value);
    info.college_id = selectedValues[0];
    setInfo((prevUser) => ({ ...prevUser, multi_clg_id: selectedValues }));

    if (selectedOption.some((option) => option.value === "All")) {
      setSelectedCollegestaff([{ value: "All", label: "All" }]);
      setemployeeOpt([{ value: "All", label: "All" }]);
      info.college_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_clg_id: [{ value: "All", label: "All" }],
      }));
      info.employee_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_employee_id: [{ value: "All", label: "All" }],
      }));
    } else {
      setemployeeOpt([{ value: "All", label: "All" }]);
      info.employee_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_employee_id: [{ value: "All", label: "All" }],
      }));
      setSelectedCollegestaff(selectedOption);

      setInfo((prevUser) => ({ ...prevUser, multi_clg_id: selectedOption }));
      const collegeIds = selectedOption.map((college) => college.value);
      const filteredEmployees = selectedemployee.filter((employee) =>
        collegeIds.includes(employee.college_id)
      );

      const newOption1 = [
        { value: "All", label: "All" },
        ...(filteredEmployees.map((item) => ({
          value: item.id,
          label: `${item.first_name} ${item.last_name} ${item.college_id == "1111000"
              ? "(NIT)"
              : item.college_id == "1111001"
                ? "(NIM)"
                : item.college_id == "1111002"
                  ? "(NIP)"
                  : item.college_id == "1111003"
                    ? "(NIS)"
                    : item.college_id == "1111004"
                      ? "(NICL)"
                      : item.college_id == "1111005"
                        ? "(NCP)"
                        : item.college_id == "1111006"
                          ? "(NSN)"
                          : item.college_id == "1111007"
                            ? "(NCA)"
                            : item.college_id == "1111008"
                              ? "(NCH)"
                              : item.college_id == "1111009"
                                ? "(AB)"
                                : item.college_id == "11110010"
                                  ? "(NICS)"
                                  : item.college_id == "11110011"
                                    ? "(NCMR)"
                                    : item.college_id == "11110012"
                                      ? "(KIRC)"
                                      : item.college_id
            }`,
        })) || []),
      ];
      setoptions(newOption1);
      setSelectedCollegestaff(selectedOption);
    }
  };

  const handleEmployeeselect = (selectedOption) => {
    setemployeeOpt(selectedOption);
    setInfo((prevUser) => ({ ...prevUser, multi_employee_id: selectedOption }));
    const selectedValues = selectedOption.map((option) => option);
    info.employee_id = selectedValues[0];
    setInfo((prevUser) => ({ ...prevUser, multi_employee_id: selectedValues }));
    if (selectedOption.some((option) => option.value === "All")) {
      info.employee_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_employee_id: [{ value: "All", label: "All" }],
      }));
    } else {
      info.employee_id = selectedOption[0];
      setInfo((prevUser) => ({
        ...prevUser,
        multi_employee_id: selectedValues,
      }));
    }
  };

  useEffect(() => { }, [selectedCollegestaff]);

  useEffect(() => { }, [employeeOpt]);

  ////////////////////////////////////////STUDENT ///////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const option2 = [
    { value: "All", label: "All" },
    ...(collegeOpt?.map((item) => ({
      value: item?.id,
      label: item?.name,
    })) || []),
  ];

  const [option3, setoption3] = useState([
    { value: "All", label: "All" },
    ...selectedDepartment?.map((item) => ({
      value: item?.id,
      label: item?.name,
      dep_name: item.department_id,
    })),
  ]);

  // const [clgid, setclgid] = useState([])

  const handlestdCollegeSelect = (selectedOption) => {
    setselectedCollegestudnt(selectedOption);

    const selectedValues = selectedOption.map((option) => option.value);
    info.college_id2 = selectedValues[0];
    setInfo((prevUser) => ({ ...prevUser, multi_clg_id2: selectedValues }));

    if (selectedOption.some((option) => option.value === "All")) {
      setInfo((prevUser) => ({
        ...prevUser,
        multi_clg_id2: [{ value: "All", label: "All" }],
      }));
      const onlyAll = selectedOption.filter((option) => option.value == "All");
      setselectedCollegestudnt(onlyAll);

      info.department_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_dpt_id: [{ value: "All", label: "All" }],
      }));
      info.session_id = selectedSessions;
      setInfo((prevUser) => ({ ...prevUser, session_id: selectedSessions }));
      info.class_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_class_id: [{ value: "All", label: "All" }],
      }));
      info.current_semester = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_id: [{ value: "All", label: "All" }],
      }));

      setDepartmentOpt([{ value: "All", label: "All" }]);
      setoption3([{ value: "All", label: "All" }]);
      setClassOpt([{ value: "All", label: "All" }]);
      setsemOpt([{ value: "All", label: "All" }]);
    } else if (selectedOption.some((option) => option.value != "All")) {
      // set options
      setInfo((prevUser) => ({ ...prevUser, multi_clg_id2: selectedOption }));

      setDepartmentOpt([{ value: "All", label: "All" }]);
      setSelectedSessions("");
      setClassOpt([{ value: "All", label: "All" }]);
      setsemOpt([{ value: "All", label: "All" }]);

      // send options to backend
      info.department_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_dpt_id: [{ value: "All", label: "All" }],
      }));
      info.session_id = selectedSessions;
      setInfo((prevUser) => ({ ...prevUser, session_id: selectedSessions }));
      info.class_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_class_id: [{ value: "All", label: "All" }],
      }));
      info.current_semester = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_id: [{ value: "All", label: "All" }],
      }));

      const collegeIds = selectedOption.map((college) => college.value);
      // setclgid(collegeIds[0])
      const filteredDepartment = selectedDepartment.filter((department) =>
        collegeIds.includes(department.college_id)
      );

      const newOption1 = [
        { value: "All", label: "All" },
        ...(filteredDepartment.map((item) => ({
          value: item.id,
          label: item.name,
          dep_name: item.department_id,
        })) || []),
      ];

      setoption3(newOption1);
      setselectedCollegestudnt(selectedOption);

      info.session_id = selectedSessions;
      setInfo((prevUser) => ({ ...prevUser, session_id: selectedSessions }));
      info.class_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_class_id: [{ value: "All", label: "All" }],
      }));
      info.current_semester = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_id: [{ value: "All", label: "All" }],
      }));
    } else {
      setselectedCollegestudnt("");
      setDepartmentOpt("");
      setSelectedSessions("");
      setClassOpt("");
      setsemOpt("");

      info.college_id2 = "";
      setInfo((prevUser) => ({ ...prevUser, multi_clg_id2: "" }));
      info.department_id = "";
      setInfo((prevUser) => ({ ...prevUser, multi_dpt_id: "" }));
      info.session_id = "";
      setInfo((prevUser) => ({ ...prevUser, session_id: "" }));
      info.class_id = "";
      setInfo((prevUser) => ({ ...prevUser, multi_class_id: "" }));
      info.current_semester = "";
      setInfo((prevUser) => ({ ...prevUser, multi_sem_id: "" }));
    }
  };

  const [getdpt, setgetdpt] = useState([]);

  const handleDeptSelect = (selectedOption) => {
    setDepartmentOpt(selectedOption);
    const selectedValues = selectedOption.map((option) => option.value);
    info.department_id = selectedValues[0];
    setInfo((prevUser) => ({ ...prevUser, multi_dpt_id: selectedValues }));

    if (selectedOption.some((option) => option.value === "All")) {
      info.department_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_dpt_id: [{ value: "All", label: "All" }],
      }));
      const onlyAll = selectedOption.filter((option) => option.value == "All");
      setDepartmentOpt(onlyAll);

      setoption5([{ value: "All", label: "All" }]);
      setClassOpt([{ value: "All", label: "All" }]);
      setsemOpt([{ value: "All", label: "All" }]);

      info.class_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_class_id: [{ value: "All", label: "All" }],
      }));
      info.current_semester = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_id: [{ value: "All", label: "All" }],
      }));
    } else if (selectedOption.some((option) => option.value !== "All")) {
      setInfo((prevUser) => ({ ...prevUser, multi_dpt_id: selectedOption }));
      const nullyfyAll = selectedOption.filter(
        (option) => option.value !== "All"
      );
      setDepartmentOpt(nullyfyAll);

      const deptIds = selectedOption.map((department) => department.value);
      const matchedDepartment = classdata.filter((c, i) =>
        deptIds.includes(parseInt(c.department_id))
      );
      const getdptlabels = selectedDepartment.filter((s) =>
        matchedDepartment.some((d) => s.id === parseInt(d.department_id))
      );

      const newOption1 = [
        { value: "All", label: "All" },
        ...matchedDepartment.map((item) => ({
          value: item.id,
          label: `${item.name} (${getdptlabels.find((d) => d.id === parseInt(item.department_id))
              ?.department_id || ""
            })`,
        })),
      ];

      setoption5(newOption1);
      setDepartmentOpt(selectedOption);

      const selectedValues = selectedOption.map((option) => option.value);
      setInfo((prevUser) => ({ ...prevUser, multi_dept_id: selectedValues }));
      setSelectedSessions("");
      setClassOpt([{ value: "All", label: "All" }]);
      setsemOpt([{ value: "All", label: "All" }]);

      info.session_id = selectedSessions;
      setInfo((prevUser) => ({ ...prevUser, session_id: selectedSessions }));
      info.class_id = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_class_id: [{ value: "All", label: "All" }],
      }));
      info.current_semester = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_id: [{ value: "All", label: "All" }],
      }));
      setDepartmentOpt(selectedOption);
    } else {
      setDepartmentOpt(" ");
      setSelectedSessions("");
      setClassOpt("");
      setsemOpt("");

      info.department_id = "";
      setInfo((prevUser) => ({ ...prevUser, multi_dpt_id: "" }));
      info.session_id = "";
      setInfo((prevUser) => ({ ...prevUser, session_id: "" }));
      info.class_id = "";
      setInfo((prevUser) => ({ ...prevUser, multi_class_id: "" }));
      info.current_semester = "";
      setInfo((prevUser) => ({ ...prevUser, multi_sem_id: "" }));
    }
  };

  useEffect(() => { }, [selectedCollegestudnt, selectcollegeparent]);

  useEffect(() => { }, [departmentOpt]);

  const [classdata, setclassdata] = useState([]);

  const [getclassid, setgetclassid] = useState([]);

  const handleClassChange = (selectedoption) => {
    setClassOpt(selectedoption);
    const selectedValues = selectedoption.map((option) => option.value);
    info.class_id = selectedValues[0];
    setInfo((prevUser) => ({ ...prevUser, multi_class_id: selectedValues }));

    if (selectedoption.some((option) => option.value === "All")) {
      const onlyAll = selectedoption.filter((option) => option.value == "All");
      setClassOpt(onlyAll);
      setsemOpt([{ value: "All", label: "All" }]);

      info.current_semester = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_id: [{ value: "All", label: "All" }],
      }));
    } else if (selectedoption.some((option) => option.value != "All")) {
      setInfo((prevUser) => ({ ...prevUser, multi_class_id: selectedoption }));
      const nullyfyAll = selectedoption.filter(
        (option) => option.value !== "All"
      );
      setClassOpt(nullyfyAll);
      const classid = selectedoption.map((department) => department.value);
      const matchedClass = semdata.filter((c, i) =>
        classid.includes(parseInt(c.class_id))
      );
      console.log("matchedClass", matchedClass);

      const getclaslabels = classdata.filter((s) =>
        matchedClass.some((d) => s.id == d.class_id)
      );
      console.log("getclaslabels", getclaslabels);
      const newOption1 = [
        { value: "All", label: "All" },
        ...matchedClass.map((item) => ({
          value: item.id,
          label: `${item.name} (${getclaslabels.find((d) => d.id === item.class_id)?.name || ""
            })`,
        })),
      ];
      console.log("newOption1", newOption1);

      setoption6(newOption1);

      setClassOpt(selectedoption);
      setsemOpt([{ value: "All", label: "All" }]);

      info.current_semester = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_id: [{ value: "All", label: "All" }],
      }));

      const classIds = selectedoption.map((classs) => classs.value);
      setgetclassid(classIds[0]);
      setClassOpt(selectedoption);
    } else {
      setsemOpt("");
      info.current_semester = "";
      setInfo((prevUser) => ({ ...prevUser, multi_sem_id: "" }));
    }
  };

  const getData = async () => {
    const config = {
      method: "get",
      url: CLASS_DATA,
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        setclassdata(res.data.data);
        //  console.log("CLASS DATA",res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: ACADEMICS_ADD_SEMESTER,
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config2)
      .then((res) => {
        getsemdata(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getData();
  }, [getdpt, getclassid]);

  const [option6, setoption6] = useState([
    {
      value: "All",
      label: "All",
    },
    ...(semdata?.map((item) => ({
      value: item?.id,
      label: item?.name,
    })) || []),
  ]);

  const option4 = [
    ...(SessionOpt?.map((item) => ({
      value: item?.id,
      label: item?.name,
    })) || []),
  ];

  const handleAcadmicChange = (selectedOption) => {
    setSelectedSessions(selectedOption);
    setInfo((prevUser) => ({ ...prevUser, session_id: selectedOption.value }));
  };

  useEffect(() => { }, [selectedSessions]);
  useEffect(() => { }, [selectedSessionp]);

  const [option5, setoption5] = useState([
    {
      value: "All",
      label: "All",
    },
    ...(classdata?.map((item) => ({
      value: item?.id,
      label: item.name,
    })) || []),
  ]);

  const handlesemchange = (selectedoption) => {
    const selectedValues = selectedoption.map((option) => option.value);
    info.current_semester = selectedValues[0];
    setInfo((prevUser) => ({ ...prevUser, multi_sem_id: selectedValues }));

    if (selectedoption.some((option) => option.value === "All")) {
      const onlyAll = selectedoption.filter((option) => option.value == "All");
      setsemOpt(onlyAll);

      info.current_semester = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_id: [{ value: "All", label: "All" }],
      }));
    } else {
      const nullyfyAll = selectedoption.filter(
        (option) => option.value !== "All"
      );
      setsemOpt(nullyfyAll);

      setInfo((prevUser) => ({ ...prevUser, multi_sem_id: selectedoption }));
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////PARENT////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const option7 = [
    { value: "All", label: "All" },
    ...(collegeOpt?.map((item) => ({
      value: item?.id,
      label: item?.name,
    })) || []),
  ];
  const handlecollegechange = (selectedOption) => {
    setselectcollegeparent(selectedOption);

    const selectedValues = selectedOption.map((option) => option.value);
    info.college_id3 = selectedValues[0];
    setInfo((prevUser) => ({ ...prevUser, multi_clg_id3: selectedValues }));

    if (selectedOption.some((option) => option.value === "All")) {
      info.college_id3 = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_clg_id3: [{ value: "All", label: "All" }],
      }));
      const onlyAll = selectedOption.filter((option) => option.value == "All");
      setselectcollegeparent(onlyAll);

      info.department_idp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_dpt_idp: [{ value: "All", label: "All" }],
      }));
      info.session_idp = selectedSessions;
      setInfo((prevUser) => ({ ...prevUser, session_idp: selectedSessions }));
      info.class_idp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_class_idp: [{ value: "All", label: "All" }],
      }));
      info.current_semesterp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_idp: [{ value: "All", label: "All" }],
      }));

      setdepartmentOptp([{ value: "All", label: "All" }]);
      setoption8([{ value: "All", label: "All" }]);
      setClassOptp([{ value: "All", label: "All" }]);
      setsemOptp([{ value: "All", label: "All" }]);
    } else if (selectedOption.some((option) => option.value != "All")) {
      setInfo((prevUser) => ({ ...prevUser, multi_clg_id3: selectedOption }));
      // set options
      setdepartmentOptp([{ value: "All", label: "All" }]);
      setSelectedSessionp("");
      setClassOptp([{ value: "All", label: "All" }]);
      setsemOptp([{ value: "All", label: "All" }]);

      // send options to backend

      info.department_idp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_dpt_idp: [{ value: "All", label: "All" }],
      }));
      info.session_idp = selectedSessions;
      setInfo((prevUser) => ({ ...prevUser, session_idp: selectedSessions }));
      info.class_idp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_class_idp: [{ value: "All", label: "All" }],
      }));
      info.current_semesterp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_idp: [{ value: "All", label: "All" }],
      }));

      const collegeIds = selectedOption.map((college) => college.value);
      // setclgid(collegeIds[0])
      const filteredDepartment = selectedDepartment.filter((department) =>
        collegeIds.includes(department.college_id)
      );

      const newOption1 = [
        { value: "All", label: "All" },
        ...(filteredDepartment.map((item) => ({
          value: item.id,
          label: item.name,
          dep_name: item.department_id,
        })) || []),
      ];

      setoption8(newOption1);
      setselectcollegeparent(selectedOption);

      info.session_idp = selectedSessions;
      setInfo((prevUser) => ({ ...prevUser, session_idp: selectedSessions }));
      info.class_idp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_class_idp: [{ value: "All", label: "All" }],
      }));
      info.current_semesterp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_idp: [{ value: "All", label: "All" }],
      }));
    } else {
      setselectcollegeparent("");
      setdepartmentOptp("");
      setSelectedSessionp("");
      setClassOptp("");
      setsemOptp("");

      info.college_id3 = "";
      setInfo((prevUser) => ({ ...prevUser, multi_clg_id3: "" }));
      info.department_idp = "";
      setInfo((prevUser) => ({ ...prevUser, multi_dpt_idp: "" }));
      info.session_idp = "";
      setInfo((prevUser) => ({ ...prevUser, session_idp: "" }));
      info.class_idp = "";
      setInfo((prevUser) => ({ ...prevUser, multi_class_idp: "" }));
      info.current_semesterp = "";
      setInfo((prevUser) => ({ ...prevUser, multi_sem_idp: "" }));
    }
  };
  const [option8, setoption8] = useState([
    { value: "All", label: "All" },
    ...selectedDepartment?.map((item) => ({
      value: item?.id,
      label: item?.name,
      dep_name: item.department_id,
    })),
  ]);

  const handleDeptSelectparent = (selectedOption) => {
    setdepartmentOptp(selectedOption);
    const selectedValues = selectedOption.map((option) => option.value);
    info.department_idp = selectedValues[0];
    setInfo((prevUser) => ({ ...prevUser, multi_dpt_idp: selectedValues }));

    if (selectedOption.some((option) => option.value === "All")) {
      const onlyAll = selectedOption.filter((option) => option.value == "All");
      setdepartmentOptp(onlyAll);

      setoption8([{ value: "All", label: "All" }]);
      setClassOptp([{ value: "All", label: "All" }]);
      setsemOptp([{ value: "All", label: "All" }]);

      info.department_idp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_dpt_idp: [{ value: "All", label: "All" }],
      }));
      info.class_idp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_class_idp: [{ value: "All", label: "All" }],
      }));
      info.current_semesterp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_idp: [{ value: "All", label: "All" }],
      }));
    } else if (selectedOption.some((option) => option.value !== "All")) {
      setInfo((prevUser) => ({ ...prevUser, multi_dpt_idp: selectedOption }));
      const nullyfyAll = selectedOption.filter(
        (option) => option.value !== "All"
      );
      setdepartmentOptp(nullyfyAll);

      const deptIds = selectedOption.map((department) => department.value);
      const matchedDepartment = classdata.filter((c, i) =>
        deptIds.includes(parseInt(c.department_id))
      );
      const getdptlabels = selectedDepartment.filter((s) =>
        matchedDepartment.some((d) => s.id === parseInt(d.department_id))
      );

      const newOption1 = [
        { value: "All", label: "All" },
        ...matchedDepartment.map((item) => ({
          value: item.id,
          label: `${item.name} (${getdptlabels.find((d) => d.id === parseInt(item.department_id))
              ?.department_id || ""
            })`,
        })),
      ];

      setoption10(newOption1);
      setdepartmentOptp(selectedOption);

      const selectedValues = selectedOption.map((option) => option.value);
      setInfo((prevUser) => ({ ...prevUser, multi_dept_idp: selectedValues }));
      setSelectedSessionp("");
      setClassOptp([{ value: "All", label: "All" }]);
      setsemOptp([{ value: "All", label: "All" }]);

      info.session_idp = selectedSessionp;
      setInfo((prevUser) => ({ ...prevUser, session_id: selectedSessionp }));
      info.class_idp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_class_idp: [{ value: "All", label: "All" }],
      }));
      info.current_semesterp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_idp: [{ value: "All", label: "All" }],
      }));

      setdepartmentOptp(selectedOption);
    } else {
      setdepartmentOptp(selectedOption);
      setSelectedSessionp("");
      setClassOptp("");
      setsemOptp("");
      info.department_id = "";
      setInfo((prevUser) => ({ ...prevUser, multi_dpt_idp: "" }));
      info.session_idp = "";
      setInfo((prevUser) => ({ ...prevUser, session_idp: "" }));
      info.class_idp = "";
      setInfo((prevUser) => ({ ...prevUser, multi_class_idp: "" }));
      info.current_semesterp = "";
      setInfo((prevUser) => ({ ...prevUser, multi_sem_idp: "" }));
    }
  };
  const option9 = [
    ...(SessionOpt?.map((item) => ({
      value: item?.id,
      label: item?.name,
    })) || []),
  ];

  const handleAcadmicparent = (selectedOption) => {
    setSelectedSessionp(selectedOption);

    setInfo((prevUser) => ({ ...prevUser, session_idp: selectedOption.value }));
  };

  const [option10, setoption10] = useState([
    {
      value: "All",
      label: "All",
    },
    ...(classdata?.map((item) => ({
      value: item?.id,
      label: item.name,
    })) || []),
  ]);

  const handleparentClassChange = (selectedoption) => {
    setClassOptp(selectedoption);
    const selectedValues = selectedoption.map((option) => option.value);
    info.class_idp = selectedValues[0];
    setInfo((prevUser) => ({ ...prevUser, multi_class_idp: selectedValues }));

    if (selectedoption.some((option) => option.value === "All")) {
      const onlyAll = selectedoption.filter((option) => option.value == "All");
      setClassOptp(onlyAll);
      setsemOptp([{ value: "All", label: "All" }]);

      info.current_semesterp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_idp: [{ value: "All", label: "All" }],
      }));
    } else if (selectedoption.some((option) => option.value != "All")) {
      setInfo((prevUser) => ({ ...prevUser, multi_class_idp: selectedoption }));
      const nullyfyAll = selectedoption.filter(
        (option) => option.value !== "All"
      );
      setClassOptp(nullyfyAll);
      const classid = selectedoption.map((department) => department.value);
      const matchedClass = semdata.filter((c, i) =>
        classid.includes(parseInt(c.class_id))
      );

      const getclaslabels = classdata.filter((s) =>
        matchedClass.some((d) => s.id == d.class_id)
      );

      const newOption1 = [
        { value: "All", label: "All" },
        ...matchedClass.map((item) => ({
          value: item.id,
          label: `${item.name} (${getclaslabels.find((d) => d.id === item.class_id)?.name || ""
            })`,
        })),
      ];
      console.log("newOption1", newOption1);

      setoption11(newOption1);

      setClassOptp(selectedoption);
      setsemOptp([{ value: "All", label: "All" }]);

      const classIds = selectedoption.map((classs) => classs.value);
      setgetclassid(classIds[0]);
      setClassOptp(selectedoption);
    } else {
      setsemOptp("");
      info.current_semesterp = "";
      setInfo((prevUser) => ({ ...prevUser, multi_sem_idp: "" }));
    }
  };
  const [option11, setoption11] = useState([
    {
      value: "All",
      label: "All",
    },
    ...(semdata?.map((item) => ({
      value: item?.id,
      label: item?.name,
    })) || []),
  ]);

  const handleparentsemchange = (selectedoption) => {
    const selectedValues = selectedoption.map((option) => option.value);
    info.current_semesterp = selectedValues[0];
    setInfo((prevUser) => ({ ...prevUser, multi_sem_idp: selectedValues }));

    if (selectedoption.some((option) => option.value === "All")) {
      const onlyAll = selectedoption.filter((option) => option.value == "All");
      setsemOpt(onlyAll);

      info.current_semesterp = "All";
      setInfo((prevUser) => ({
        ...prevUser,
        multi_sem_idp: [{ value: "All", label: "All" }],
      }));
    } else {
      setInfo((prevUser) => ({ ...prevUser, multi_sem_idp: selectedoption }));
      const nullyfyAll = selectedoption.filter(
        (option) => option.value !== "All"
      );
      setsemOptp(nullyfyAll);
    }
  };
  const handleStaffSwitchChange = (checked) => {
    setStaff(checked);
    let btn = document.querySelector("#swithcolour1");
    if (btn) {
      btn.style.backgroundColor = checked ? "#ff5e00" : "#364277";
    }
  };

  const handleStudentSwitchChange = (checked) => {
    setStudents(checked);
    let btn = document.querySelector("#swithcolour2");
    if (btn) {
      btn.style.backgroundColor = checked ? "#ff5e00" : "#364277";
    }
  };

  const handleParentSwitchChange = (checked) => {
    setParents(checked);
    let btn = document.querySelector("#swithcolour3");
    if (btn) {
      btn.style.backgroundColor = checked ? "#ff5e00" : "#364277";
    }
  };

  let Staff =
    info.college_id !== ""
      ? [{ college_id: info.multi_clg_id, staff: info.multi_employee_id }]
      : 0;

  let Student =
    info.college_id2 !== ""
      ? [
        {
          college: info.multi_clg_id2,
          department: info.multi_dpt_id,
          session: info.session_id,
          class: info.multi_class_id,
          semester: info.multi_sem_id,
        },
      ]
      : 0;
  let Parent =
    info.college_id3 !== ""
      ? [
        {
          college: info.multi_clg_id3,
          department: info.multi_dpt_idp,
          session: info.session_idp,
          class: info.multi_class_idp,
          semester: info.multi_sem_idp,
        },
      ]
      : 0;

  const addAttachment = async (e, name) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Attachment`,
        e.target.value.split(".")[1],
        props.setLoading,
        e.target.files[0]
      );
      setAttachment(d);
      console.log("d-", d);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    if (!title) return toast.error("Please Enter Title");
    if (!fromDate) return toast.error("Please Enter From Date");
    if (!toDate) return toast.error("Please Enter To Date");
    if (
      staff &&
      (Object.keys(selectedCollegestaff).length === 0 ||
        Object.keys(employeeOpt).length === 0)
    ) {
      return toast.error("Please Enter Mandatory Fields for Staff");
    }

    if (
      students &&
      (Object.keys(selectedCollegestudnt).length === 0 ||
        Object.keys(departmentOpt).length === 0 ||
        Object.keys(selectedSessions).length === 0 ||
        Object.keys(classOpt).length === 0 ||
        Object.keys(semOpt).length === 0)
    ) {
      return toast.error("Please Enter Mandatory Fields for Students");
    }

    if (
      parents &&
      (Object.keys(selectedCollege).length === 0 ||
        Object.keys(departmentOptp).length === 0 ||
        Object.keys(selectedSessionp).length === 0 ||
        Object.keys(parenrclassOpt).length === 0 ||
        Object.keys(semOptp).length === 0)
    ) {
      return toast.error("Please Enter Mandatory Fields for Parents");
    }

    const config = {
      method: "post",
      url: `${ANNOUNCEMENTS}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        title: title,
        description: desc,
        from_date: fromDate,
        to_date: toDate,
        attachment: info?.attachment,

        createdBy: sessionStorage.getItem("employee_id"),
        college_id: props.collegeId,
        students: Student.length > 0 ? Student : 0,
        staff: Staff.length > 0 ? Staff : 0,
        parents: Parent.length > 0 ? Parent : 0,
        allColleges: allColleges ? 1 : 0,
        type: "announcement",
        announcetype: announcement,
      },
    };
    props.setLoading(1);

    await axios(config)
      .then((res) => {
        props.setLoading(0);
        console.log(res);
        clearData();
        // Staff = [];
        // Student = [];
        // Parent = [];
        props.reloadData();
        toast.success("Announcement Created");
      })
      .catch((err) => {
        props.setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };
  ////////////////Staff college select///////////

  const handleEdit = async () => {
    if (!title) return toast.error("Please Enter Title");
    if (!fromDate) return toast.error("Please Enter From Date");
    if (!toDate) return toast.error("Please Enter To Date");
    if (
      staff &&
      Object.keys(selectedCollegestaff).length === 0 &&
      Object.keys(employeeOpt).length === 0
    ) {
      return toast.error("Please Enter Mandatory Fields for Staff");
    }

    if (
      students &&
      Object.keys(selectedCollegestudnt).length === 0 &&
      Object.keys(departmentOpt).length === 0 &&
      Object.keys(selectedSessions).length === 0 &&
      Object.keys(classOpt).length === 0 &&
      Object.keys(semOpt).length === 0
    ) {
      return toast.error("Please Enter Mandatory Fields for Students");
    }

    if (
      parents &&
      Object.keys(selectedCollege).length === 0 &&
      Object.keys(departmentOptp).length === 0 &&
      Object.keys(selectedSessionp).length === 0 &&
      Object.keys(parenrclassOpt).length === 0 &&
      Object.keys(semOptp).length === 0
    ) {
      return toast.error("Please Enter Mandatory Fields for Parents");
    }
    if (!props.data.id) {
      return toast.error("Unable to get Announcement");
    }
    const config = {
      method: "put",
      url: `${ANNOUNCEMENTS}/${props.data.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        title: title,
        description: desc,
        from_date: fromDate ? fromDate.format("YYYY-MM-DD") : null,
        to_date: toDate ? toDate.format("YYYY-MM-DD") : null,
        attachment: props.data.attachment,
        createdBy: sessionStorage.getItem("employee_id"),
        college_id: props.collegeId,
        students: Student ? Student : 0,
        staff: Staff ? Staff : 0,
        parents: Parent ? Parent : 0,
        allColleges: allColleges ? 1 : 0,
        type: "announcement",
        announcetype: announcement,
      },
    };
    props.setLoading(1);
    await axios(config)
      .then((res) => {
        props.setLoading(0);

        clearData();
        props.reloadData();
        toast.success("Announcement Updated");
      })
      .catch((err) => {
        props.setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  const filterData = () => {
    try {
      const staffData = JSON.parse(props.data.staff);
      const collegeIds = staffData[0].college_id;
      const staffId = staffData[0].staff;

      const formattedColleges = collegeIds.map((college) => ({
        value: college.value,
        label: college.label,
      }));
      const formattedStaff = staffId.map((college) => ({
        value: college.value,
        label: college.label,
      }));

      setSelectedCollegestaff(formattedColleges);
      setemployeeOpt(formattedStaff);
    } catch (error) {
      console.error("Error parsing staff data:", error);
    }
  };
  const filterStudentData = () => {
    try {
      const studentData = JSON.parse(props.data.students);
      const collegeIds2 = studentData[0].college;
      const department = studentData[0].department; // This is a string
      const sessionids = studentData[0].session;
      const classids = studentData[0].class;
      const semid = studentData[0].semester;

      const formattedColleges = collegeIds2.map((college) => ({
        value: college.value,
        label: college.label,
      }));

      const formatDepartment = department.map((college) => ({
        value: college.value,
        label: college.label,
      }));
      const formatsession = sessionOpt.find(
        (session) => Number(session.id) == Number(sessionids)
      );
      const formatClass = classids.map((classs) => ({
        value: classs.value,
        label: classs.label,
      }));
      const formatSem = semid.map((sem) => ({
        value: sem.value,
        label: sem.label,
      }));

      setselectedCollegestudnt(formattedColleges);
      setDepartmentOpt(formatDepartment);
      setSelectedSessions({
        value: formatsession.id,
        label: formatsession.name,
      });
      setClassOpt(formatClass);
      setsemOpt(formatSem);
    } catch (error) {
      console.error("Error parsing student data:", error);
    }
  };

  const filterParent = () => {
    try {
      const parentdata = JSON.parse(props.data.parents);

      const collegeIds3 = parentdata[0].college;
      const departmentp = parentdata[0].department;
      const sessionidsp = parentdata[0].session;
      const classidsp = parentdata[0].class;
      const semidp = parentdata[0].semester;

      const formattedCollegesp = collegeIds3.map((college) => ({
        value: college.value,
        label: college.label,
      }));
      const formatDepartmentp = departmentp.map((college) => ({
        value: college.value,
        label: college.label,
      }));
      const formatsessionp = sessionOpt.find(
        (session) => Number(session.id) == Number(sessionidsp)
      );
      const formatClass = classidsp.map((classs) => ({
        value: classs.value,
        label: classs.label,
      }));
      const formatSemp = semidp.map((sem) => ({
        value: sem.value,
        label: sem.label,
      }));

      setselectcollegeparent(formattedCollegesp);
      setdepartmentOptp(formatDepartmentp);
      setSelectedSessionp({
        value: formatsessionp.id,
        label: formatsessionp.name,
      });
      setClassOptp(formatClass);
      setsemOptp(formatSemp);
    } catch (error) {
      console.error("Error parsing parent data:", error);
    }
  };

  useEffect(() => {
    if (props.type === "edit") {
      setTitle(props.data.title);
      setDesc(props.data.description || props.data.desc);
      setFromDate(moment(props.data.from_date));
      setToDate(moment(props.data.to_date));

      setAttachment(props.data.attachment);

      // setSelectedCollegestaff([]);
      // setemployeeOpt([]);
      // setselectedCollegestudnt([]);
      // setDepartmentOpt([]);
      // setSelectedSessions(null);
      // setClassOpt([]);
      // setsemOpt([]);
      // setselectcollegeparent([]);
      // setdepartmentOptp([]);
      // setSelectedSessionp(null);
      // setClassOptp([]);
      // setsemOptp([]);

      if (props.data.parents != 0) {
        filterParent();
      }
      if (props.data.students != 0) {
        filterStudentData();
      }
      if (props.data.staff != 0) {
        filterData();
      }
    } else {
      clearData();
    }
  }, [props.type, props.data]);

  const disabledDate = (current) => {
    return fromDate && current && current.isBefore(fromDate, "day");
  };

  const props2 = {
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";
      const isPDF = file.type === "application/pdf";

      if (!isPNG && !isPDF) {
        message.error(`${file.name} is not a PNG or PDF file`);
        return toast.error(`${file.name} is not a PNG or PDF file`);
      }

      // Allow upload if file is PNG or PDF, or ignore otherwise
      return isPNG || isPDF || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
    },
  };

  const fileref = useRef(null);
  const [modalTitle, setModalTitle] = useState("");
  const [modalLink, setModalLink] = useState("");

  const handleChange1 = async (e) => {
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      console.log("d -", d);
      info.attachment = d;
    } catch (error) {
      console.log(error);
    }
  };


  console.log("info?.attachment -", info?.attachment);
  console.log("modalLink -", modalLink);
  console.log("props?.data?.attachment -", props?.data?.attachment);

  return (
    <div>
      <DocumentsModal
        title={modalTitle}
        img={modalLink}
        setLink={setModalLink}
      />
      <div
        className="modal fade"
        id="ModalAnnouncement"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Announcement
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12">
                <div className="inputBox">
                  <input
                    type="text"
                    id="title"
                    name="title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    required="required"
                  />
                  <span>Title *</span>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <p>
                    <b>From Date</b>
                  </p>
                  <DatePicker
                    className="form-control"
                    onChange={(date) => {
                      setFromDate(date);

                      if (toDate && date && date.isAfter(toDate)) {
                        setToDate(null);
                      }
                    }}
                    value={fromDate}
                    format="DD-MM-YYYY"
                  />
                </div>

                <div className="col-md-4">
                  <p>
                    <b>To Date </b>
                  </p>

                  <DatePicker
                    className="form-control"
                    onChange={(date) => setToDate(date)}
                    value={toDate}
                    disabledDate={disabledDate}
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
              {/* <RangePicker
  className="col-md-5 fw-bold"
  style={{ height: '40px', fontSize: '16px', fontWeight: 400 }}
  onChange={onChange}
  value={fromDate && toDate ? [fromDate, toDate] : []}
  disabledDate={disabledDate}
  format="DD-MM-YYYY"
/> */}

              {/* <div className="col-md-3 mt-3">
                <p><b className="mb-2">Attachment</b></p>

                <div className="row">

                  {/* <Upload {...props2}>
                    <Button
                      icon={<UploadOutlined />}
                      className=" ml-2 "
                      style={{ height: '40px', fontSize: '16px', width: '350px' }}
                      onChange={(e) => {
                        handleChange1(e)
                      }}
                      ref={fileref}
                    >Click to Upload</Button>
                  </Upload> */}

              {/* </div>
              </div> */}
                     <div className="col-md-6 mt-3">
                <label>Attachment</label>
                <br />
                <div className="row">
                  <input
                    {...props2}
                    type="file"
                    name="attachment"
                    className="form-control ml-2"
                    ref={fileref}
                    onChange={(e) => handleChange1(e)}

                    // style={{ width: "70%" }}
                  />
                  {/* {attachment?.length > 0 ? ( */}
                  <button
                    onClick={() => {
                      setModalLink(
                        info?.attachment
                        // ? info?.attachment
                        // : props?.data?.attachment
                      );
                      setModalTitle("Announcement Attachment");
                    }}
                    data-toggle="modal"
                    data-target="#DocumentsModal"
                    className="btn btn-nex btn-sm my-1 ml-3"
                    title="View"
                  >
                    View
                  </button>
                  {/* ) : (
                    <></>
                  )} */}
                </div>
              </div>


              <div className="col-md-12">
                <div className="mt-4">
                  <p>
                    <b>Announcement</b>
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-2 mt-2">
                    <Switch
                      checked={staff}
                      onChange={handleStaffSwitchChange}
                      style={{ backgroundColor: staff ? "#ff5e00" : "#364277" }}
                      id="swithcolour1"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <label className="fw-3 text-dark">Staff</label>
                  </div>

                  <div className="col-md-2 mt-2">
                    <Switch
                      checked={students}
                      onChange={handleStudentSwitchChange}
                      style={{
                        backgroundColor: students ? "#ff5e00" : "#364277",
                      }}
                      id="swithcolour2"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <label className="fw-3 text-dark">Student</label>
                  </div>

                  <div className="col-md-2 mt-2">
                    <Switch
                      checked={parents}
                      onChange={handleParentSwitchChange}
                      style={{
                        backgroundColor: parents ? "#ff5e00" : "#364277",
                      }}
                      id="swithcolour3"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <label className="fw-3 text-dark">Parent</label>
                  </div>
                </div>
              </div>
            </div>

            {staff ? (
              <div className="col-md-12">
                <div className=" mt-2 ml-3 text-dark fw-bolder">
                  <p>
                    <b>Announcement for Staff</b>
                  </p>
                </div>
                <div className="row ml-2">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="validationCustom02">College</label>
                      <span style={{ color: "red" }}>*</span>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="info.multi_clg_id"
                        onChange={handleCollegeSelect}
                        autoFocus={true}
                        isMulti={true}
                        value={selectedCollegestaff}
                        options={options}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      ></Select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="validationCustom02">College Staff</label>
                      <span style={{ color: "red" }}>*</span>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        autoFocus={true}
                        isMulti={true}
                        name="info.multi_employee_id"
                        value={employeeOpt}
                        onChange={(selectedOption) => {
                          handleEmployeeselect(selectedOption);
                        }}
                        options={option1}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {students ? (
              <>
                <div className="col-md-12  text-dark fw-bolder">
                  <div className="mt-2 ml-3 text-dark fw-bolder">
                    <p>
                      <b>Announcement for Student</b>
                    </p>
                  </div>
                  <div className="row align-items-between ml-2">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">College</label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="info.multi_clg_id2"
                          id="class"
                          autoFocus={true}
                          isMulti={true}
                          value={selectedCollegestudnt}
                          onChange={handlestdCollegeSelect}
                          options={option2}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">Departmet</label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="info.multi_dpt_id"
                          id="class"
                          autoFocus={true}
                          isMulti={true}
                          value={departmentOpt}
                          onChange={handleDeptSelect}
                          options={option3}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">Session</label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="info.multi_acadamic_year"
                          id="class"
                          value={selectedSessions}
                          onChange={handleAcadmicChange}
                          options={option4}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">Class</label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          id="class"
                          name="info.multi_class_id"
                          autoFocus={true}
                          isMulti={true}
                          value={classOpt}
                          onChange={handleClassChange}
                          options={option5}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </div>
                    </div>

                    <div className="col-md-3 ">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">Semester</label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          id="class"
                          name="info.multi_sem_id"
                          autoFocus={true}
                          isMulti={true}
                          value={semOpt}
                          onChange={handlesemchange}
                          options={option6}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {parents ? (
              <>
                <div className="col-md-12 ">
                  <div className="mt-2 ml-3 text-dark fw-bolder">
                    <p>
                      <b>Announcement for Parents</b>
                    </p>
                  </div>
                  <div className="row ml-2">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">College</label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="info.multi_clg_id3"
                          id="class"
                          autoFocus={true}
                          isMulti={true}
                          value={selectcollegeparent}
                          onChange={handlecollegechange}
                          options={option7}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">Departmet</label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="info.multi_dpt_idp"
                          id="class"
                          autoFocus={true}
                          isMulti={true}
                          value={departmentOptp}
                          onChange={handleDeptSelectparent}
                          options={option8}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">Session</label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="info.session_idp"
                          id="class"
                          value={selectedSessionp}
                          onChange={handleAcadmicparent}
                          options={option9}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">Class</label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          id="class"
                          name="info.multi_class_idp"
                          autoFocus={true}
                          isMulti={true}
                          value={parenrclassOpt}
                          onChange={handleparentClassChange}
                          options={option10}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </div>
                    </div>

                    <div className="col-md-3 ">
                      <div className="form-group">
                        <label htmlFor="validationCustom02">Semester</label>
                        <span style={{ color: "red" }}>*</span>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          id="class"
                          name="info.multi_sem_idp"
                          autoFocus={true}
                          isMulti={true}
                          value={semOptp}
                          onChange={handleparentsemchange}
                          options={option11}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="col-md-12 mt-2 ms-2 ml-3">
              <p>
                <b>Description </b>
              </p>
              <div>
                <ReactQuill
                  theme="snow"
                  style={{ width: "97%" }}
                  value={desc}
                  onChange={(e) => {
                    setDesc(e);
                  }}
                  placeholder="Write Detailed Description of Announcement Here..."
                />
              </div>
            </div>

            <br />
            <div className="row d-flex justify-content-between px-2 m-2">
              <button
                className="btn btn-danger btn-rounded btn-outline"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  clearData();
                }}
              >
                Close
              </button>
              {props.type == "edit" ? (
                <button
                  className="btn btn-success btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleEdit}
                >
                  Update Changes
                </button>
              ) : (
                <button
                  className="btn btn-success btn-rounded btn-outline"
                  // data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleSubmit}
                >
                  Send
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalAnnouncement;
