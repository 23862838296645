import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import "./StudentPortal.scss";

import Select from "react-select";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import { SESSION_ROLE } from "../../utils/sessionStorageContants";
import { international } from "../../Data/Countries/international";

const ParentsView = ({
  id,
  setLoading,
  collegeId,
  studentDetails,
  setStudentDetails,
  flag,
}) => {
  const [info, setInfo] = useState({});

  let y = [];

  const fileref = useRef(null);

  const fileref1 = useRef(null);
  const fileref2 = useRef(null);
  const fileref3 = useRef(null);

  const [routeData, setRouteData] = useState([]);

  const [pickuppointData1, setPickuppointData1] = useState([]);

  const [searchParams] = useSearchParams();

  const [pickuppointData, setPickuppointData] = useState([]);

  //Hostel Beds
  const [floors, setFloors] = useState([]);

  const [rooms, setRooms] = useState([]);

  const [beds, setBeds] = useState([]);

  const [roomData, setRoomData] = useState([]);

  const [hostelData, setHostelData] = useState([]);

  const [data1, setData1] = useState([]);

  const localDepartments = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));
  const localPrograms = JSON.parse(localStorage.getItem(LOCAL_PROGRAM));
  const localColleges = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const options = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const [info1, setInfo1] = useState({
    transport_amount: "",
    occupied_id: "",
    route_id: "",
    pickuppoint_id: "",
    ug_university: "",
    session_id: "",
    ug_university_percentage: "",
    pg_university: "",
    pg_university_percentage: "",
    ug_marksheet: "",
    pg_marksheet: "",
    student_session_id: "",
    room_hostel_id: "",
    floor_number: "",
    hostel_room_type_id: "",
    room_name_number: "",
    bed_no: "",
    hostel_amount: "",
  });

  const clearData = () => {
    setInfo1({
      transport_amount: "",
      route_id: "",
      pickuppoint_id: "",
      transport_amount: "",
      ug_university: "",
      session_id: "",
      ug_university_percentage: "",
      pg_university: "",
      pg_university_percentage: "",
      ug_marksheet: "",
      pg_marksheet: "",
      student_session_id: "",
      room_hostel_id: "",
      floor_number: "",
      hostel_room_type_id: "",
      room_name_number: "",
      bed_no: "",
      hostel_amount: "",
    });
  };

  const [user, setUser] = useState();

  const [Id, setId] = useState("");
  const [s_id, setS_Id] = useState("");

  const [role, setRole] = useState(sessionStorage.getItem(SESSION_ROLE));
  const [editor, setEditor] = useState(true);

  useEffect(() => {
    setRole(sessionStorage.getItem(SESSION_ROLE));
  }, [sessionStorage.getItem(SESSION_ROLE), flag]);

  useEffect(() => {
    if (role == "ADMIN" || role == "SUPERADMIN") {
      setEditor(false);
    } else {
      setEditor(true);
    }
  }, [role, flag]);

  const selectAllOption = { label: "Select All", value: "all" };

  const [selectedMonths, setSelectedMonths] = useState([]);

  const isSelectAllSelected = selectedMonths.length === options.length;

  const selectedValue = isSelectAllSelected ? [...options] : selectedMonths;

  const [countries, setCountries] = useState([]);

  const setCountryAndState = async () => {
    let arr = [];
    await international?.map((item, key) => {
      arr.push(item?.country);
    });
    setCountries(arr);
  };

  const [componentLoaded, setComponentLoaded] = useState(false);
  useEffect(() => {
    if (!componentLoaded) {
      console.log(studentDetails);
      setUser(studentDetails);
      setComponentLoaded(true);
    }
  }, [componentLoaded]);
  return (
    <div className="BasicInformation">
      <div>
        {" "}
        <div className="fatherDetails">
          <h5 className="mt-4">Father's Information</h5>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <table className="p-0 mr-2" style={{ fontSize: "1em" }}>
                <tr>
                  <td className="float-right font-weight-bold">
                    Father's Name :
                  </td>
                  <td className="ml-2">{user?.father_name || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">Occupation :</td>
                  <td className="ml-2">{user?.father_occupation || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Mobile (Father) :
                  </td>
                  <td className="ml-2">{user?.father_phone || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Occupation City (Father) :
                  </td>
                  <td className="ml-2">
                    {user?.father_occupation_city || "-"}
                  </td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Email (Father) :
                  </td>
                  <td className="ml-2">{user?.father_email || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Company / Organization :
                  </td>
                  <td className="ml-2">{user?.father_company || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    PAN (Father) :
                  </td>
                  <td className="ml-2">{user?.father_pan || "-"}</td>
                </tr>
              </table>
            </div>
            <div className="col-lg-6 col-md-6">
              <table className="p-0 mr-2" style={{ fontSize: "1em" }}>
                <tr>
                  <td className="float-right font-weight-bold">
                    Industry Type :
                  </td>
                  <td className="ml-2">{user?.father_industry_type || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Aadhar No. (Father) :
                  </td>
                  <td className="ml-2">{user?.father_aadhar_number || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Sector Type :
                  </td>
                  <td className="ml-2">{user?.father_sector_type || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Education Qualification :
                  </td>
                  <td className="ml-2">
                    {user?.father_education_qualification || "-"}
                  </td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Annual Income (Father) :
                  </td>
                  <td className="ml-2">{user?.father_annual_income || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Designation :
                  </td>
                  <td className="ml-2">{user?.father_designation || "-"}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div>
        {" "}
        <div className="motherDetails">
          <h5 className="mt-4">Mother's Information</h5>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <table className="p-0 mr-2" style={{ fontSize: "1em" }}>
                <tr>
                  <td className="float-right font-weight-bold">
                    Mother's Name :
                  </td>
                  <td className="ml-2">{user?.mother_name || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">Occupation :</td>
                  <td className="ml-2">{user?.mother_occupation || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Mobile (Mother) :
                  </td>
                  <td className="ml-2">{user?.mother_phone || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Occupation City (Mother) :
                  </td>
                  <td className="ml-2">{user?.mother_occupation_city}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Email (Mother) :
                  </td>
                  <td className="ml-2">{user?.mother_email || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Company / Organization :
                  </td>
                  <td className="ml-2">{user?.mother_company || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    PAN (Mother) :
                  </td>
                  <td className="ml-2">{user?.mother_pan || "-"}</td>
                </tr>
              </table>
            </div>
            <div className="col-lg-6 col-md-6">
              <table className="p-0 mr-2" style={{ fontSize: "1em" }}>
                <tr>
                  <td className="float-right font-weight-bold">
                    Industry Type :
                  </td>
                  <td className="ml-2">{user?.mother_industry_type || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Aadhar No. (Mother) :
                  </td>
                  <td className="ml-2">{user?.mother_aadhar_number || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Sector Type :
                  </td>
                  <td className="ml-2">{user?.mother_sector_type || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Education Qualification :
                  </td>
                  <td className="ml-2">
                    {user?.mother_education_qualification || "-"}
                  </td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Annual Income (Mother) :
                  </td>
                  <td className="ml-2">{user?.mother_annual_income || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Designation :
                  </td>
                  <td className="ml-2">{user?.mother_designation || "-"}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div>
        {" "}
        <br />
        <div className="guardianDetails">
          <h5 className="mt-4">Guardian Information</h5>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <table className="p-0 mr-2 guardian" style={{ fontSize: "1em" }}>
                <tr>
                  <td className="float-right font-weight-bold">
                    Guardian's Name :
                  </td>
                  <td className="ml-2">{user?.guardian_name || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">Occupation :</td>
                  <td className="ml-2">{user?.guardian_occupation || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Mobile (Guardian) :
                  </td>
                  <td className="ml-2">{user?.guardian_phone || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Occupation City (Guardian) :
                  </td>
                  <td className="ml-2">
                    {user?.guardian_occupation_city || "-"}
                  </td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Email (Guardian) :
                  </td>
                  <td className="ml-2">{user?.guardian_email || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Company / Organization :
                  </td>
                  <td className="ml-2">{user?.guardian_company || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    PAN (Guardian) :
                  </td>
                  <td className="ml-2">{user?.guardian_pan || "-"}</td>
                </tr>
              </table>
            </div>
            <div className="col-lg-6 col-md-6">
              <table className="p-0 mr-2" style={{ fontSize: "1em" }}>
                <tr>
                  <td className="float-right font-weight-bold">
                    Industry Type :
                  </td>
                  <td className="ml-2">
                    {user?.guardian_industry_type || "-"}
                  </td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Aadhar No. (Guardian) :
                  </td>
                  <td className="ml-2">
                    {user?.guardian_aadhar_number || "-"}
                  </td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Sector Type :
                  </td>
                  <td className="ml-2">{user?.guardian_sector_type || "-"}</td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Education Qualification :
                  </td>
                  <td className="ml-2">
                    {user?.guardian_education_qualification || "-"}
                  </td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Annual Income (Guardian) :
                  </td>
                  <td className="ml-2">
                    {user?.guardian_annual_income || "-"}
                  </td>
                </tr>
                <tr>
                  <td className="float-right font-weight-bold">
                    Designation :
                  </td>
                  <td className="ml-2">{user?.guardian_designation || "-"}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentsView;
