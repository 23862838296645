import React from "react";

function Receipt({
  setLoading,
  data,
  collegeId,
  className,
  mainData,
  department,
}) {
  let collegeOpt = JSON.parse(localStorage.getItem("COLLEGE"));
  let employee = JSON.parse(localStorage.getItem("EMPLOYEE"));
  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  function inWords(num) {
    if (!num) return;
    if ((num = num.toString()).length > 9) return "overflow";
    var n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str;
  }

  return (
    <>
      <div className="FeeCollectionFeeReciept">
        <div className="container" style={{ padding: "20px" }}>
          <div className="row">
            <div className="col-12" style={{ padding: "20px" }}>
              <div className="row">
                <div className="col-1">
                  <img
                    src="/assets/images/Nexenstial Logo.png"
                    alt=""
                    width={100}
                  />
                </div>
                <div className="col-11">
                  <div style={{ fontSize: "15px", textAlign: "center" }}>
                    Swaminarayan University, Kalol
                  </div>
                  <div style={{ textAlign: "center", fontSize: "17px" }}>
                    {collegeOpt?.find((s) => s.id == collegeId)?.name}
                  </div>
                  <div style={{ textAlign: "center", fontSize: "17px" }}>
                    Contact : +91 8908908908
                  </div>
                  <div style={{ textAlign: "center", fontSize: "17px" }}>
                    Email : info@Swaminarayanuniversity.ac.in
                  </div>
                </div>
              </div>
              <div className="row mt-3 bg-dark py-2">
                <div
                  className="col-12"
                  style={{
                    fontSize: "15px",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  RECEIPT
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 row">
                  <div className="col-6">
                    <div>Name: {mainData?.name}</div>
                    <div>Department: {department}</div>
                    <div>Registered No: {mainData?.user_id}</div>
                  </div>
                  <div className="col-6">
                    <div>Class : {className}</div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 bg-dark py-2">
                <div
                  className="col-12 row"
                  style={{
                    fontSize: "15px",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <div
                    className="col-3"
                    style={{ borderRight: "1px solid white" }}
                  >
                    Fee Name
                  </div>
                  <div className="col-2">Amount</div>
                  <div className="col-3">Payment Id</div>
                  <div className="col-2">Date</div>
                  <div className="col-2">Collected By</div>
                </div>
              </div>
              <div
                className="row py-2 d-flex flex-column"
                style={{ minHeight: "300px" }}
              >
                {data?.map((item, key) => {
                  return (
                    <div
                      className="col-12 row mb-2"
                      style={{ maxHeight: "50px" }}
                    >
                      <div
                        className="col-3"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        {item?.fee_type_id}
                      </div>
                      <div
                        className="col-2"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        ₹ {item?.amount}
                      </div>
                      <div
                        className="col-3"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        {item?.payment_id}
                      </div>
                      <div
                        className="col-2"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        {item?.date.split("T")[0]}
                      </div>
                      <div
                        className="col-2"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        {employee &&
                        employee?.find((s) => s.id == item?.collected_by)
                          ? employee?.find((s) => s.id == item?.collected_by)
                              ?.first_name +
                            employee?.find((s) => s.id == item?.collected_by)
                              ?.last_name
                          : item?.collected_by != "Student"
                          ? "-"
                          : item?.collected_by}
                      </div>
                    </div>
                  );
                })}
              </div>
              <hr />
              <div className="row mb-5">
                <div className="col-12 row">
                  <div className="col-9">Grand Total:</div>
                  <div className="col-3">
                    ₹{data?.reduce((acc, it) => acc + it.amount, 0)}
                  </div>
                </div>
                <div className="col-12 row">
                  <div className="col-4">In Words :</div>
                  <div
                    className="col-8"
                    style={{
                      textTransform: "capitalize",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {inWords(data?.reduce((acc, it) => acc + it.amount, 0))}{" "}
                    rupees only
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6"></div>
                <div className="col-6 row" style={{ textAlign: "center" }}>
                  <div className="col-12">For Admin</div>
                  <hr />
                  <div className="col-12">
                    <strong>Recievers Signature</strong>
                  </div>
                </div>
              </div>
              {/* <table className="table table-bordered">
                  <tr style={{fontSize:'15px',textAlign:'center',color:'white'}}>
                    <th className='bg-dark'>
                    Fee Name
                    </th>
                    <th className='bg-dark'>
                    Amount
                    </th>
                  </tr>
                  <tr >
                    <td >
                      Helo
                    </td>
                    <td >
                      Helo
                    </td>
                  </tr>
                </table> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Receipt;
