import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {COURSE_PROGRAM_MAP} from "../../utils/Academics.apiConst";

function ModalCourseProgramMap({
  mappingData,
  setMappingData,
  course,
  outcome,
  getProgrammingMappings,
  programOutcomes,
  setLoading
}) {

    const handleSubmit = async () => {
        console.log('data to backend - ', mappingData);
        const config = {
          method: "post",
          url: `${COURSE_PROGRAM_MAP}`,
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              "Content-Type": "application/json",
            },
          data: {
            mappingData,
            course_outcome_id: outcome.id
          }
        };
        setLoading(1);
        axios(config)
          .then((res) => {
            setLoading(0);
            getProgrammingMappings(outcome.id);
            toast.success("Updated Successfully")
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Some Error Occured");
          });
        
        setMappingData([]);
    }

    const handleChange = (i,v) => {
        const updatedMappingData = mappingData.map(item => {
            if (item.id == i) {
                return { ...item, value: v };
            }
            return item;
        });
        setMappingData(updatedMappingData);
    }

    return (
        <div className="ModalCourseProgramMap">
        <div className="ModalCourseProgramMap">
            <div
            className="modal fade"
            id="ModalCourseProgramMap"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"

            >
            <div
                className="modal-dialog modal-dialog-centered mw-100 w-75"
                role="document"
            >
                <div className="modal-content ">
                <div className="modal-header">
                    <div className="row">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                    Map Course Outcomes to Program Outcomes for Course : 
                    </h5>
                    <h5 className="modal-title ml-3 text-danger" >
                        {course.name}
                    </h5>
                    </div>
                    <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setMappingData([])}
                    >
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                <h5 className="">Course Outcome : {outcome.outcome}</h5>
                <p><b>Description : </b>{outcome.description}</p>
                <div className="table-responsive mt-3 fixTableHead">
                        <table className="table table-bordered" style={{}}>
                            <thead className="">
                            <tr>
                                <th>Sl No.</th>
                                <th>Type</th>
                                <th>Outcome</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Null (Nil)</th>
                                <th>Low (L)</th>
                                <th>Med (M)</th>
                                <th>High (H)</th>
                            </tr>
                            </thead>
                            <tbody>
                                {mappingData?.map((i,k) => {
                                    return (
                                        <tr>
                                            <td>{k+1}</td>
                                            <td>{i?.type}</td>
                                            <td>{i?.outcome}</td>
                                            <td>{i?.name}</td>
                                            <td>{i?.description}</td>
                                            <td><input type="checkbox" checked={i?.value == 0} onChange={(e)=> handleChange(i.id,0)}/></td>
                                            <td><input type="checkbox" checked={i?.value == 1} onChange={(e)=> handleChange(i.id,1)}/></td>
                                            <td><input type="checkbox" checked={i?.value == 2} onChange={(e)=> handleChange(i.id,2)}/></td>
                                            <td><input type="checkbox" checked={i?.value == 3} onChange={(e)=> handleChange(i.id,3)}/></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        </div>
                    <div className="row d-flex justify-content-between mt-3 px-2">
                    <button
                        className="btn btn-danger btn-rounded btn-outline ml-3"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setMappingData([])}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-success btn-outline mr-3"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        Save Changes
                    </button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
}

export default ModalCourseProgramMap;
