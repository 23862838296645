import React from "react";
import { useState, useEffect, useRef } from "react";
import { Http } from "../../../Services/Services";

import { toast } from "react-toastify";

import { useReactToPrint } from "react-to-print";
import { FEE_DETAILS_BY_STUDENT_ID, STUDENT } from "../student.Api";
import Receipt from "../feeReceipt";
import FeePayment from "../FeePayment";

function Fee({ setLoading, className, department }) {
  const printRef = useRef();

  const PrintReceipt = useReactToPrint({
    content: () => printRef.current,
  });

  const [x, setX] = useState(true);
  const [show, setShow] = useState(false);
  const [modaldata, setmodalData] = useState([]);
  const [printfees, setPrintfees] = useState([]);
  const [allData, setAlldata] = useState([]);
  const [all, setAll] = useState(false);
  const [data, setData] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [feetypes, setFeetypes] = useState([]);
  const [user, setUser] = useState({
    name: "",
    user_id: "",
    amount: "",
    email: "",
    college_id: "",
    phone: "",
    department_id: "",
    program_id: "",
    city: "",
    address: "",
    state: "Gujarat",
    country: "India",
    pincode: "",
  });

  const getData1 = async () => {
    user.user_id = sessionStorage.getItem("uid");
    setLoading(1);

    await Http.get(`${STUDENT}/${user?.user_id}`)
      .then(async (res) => {
        const userData = res.data.data;
        await setUser((prev) => ({
          ...prev,
          name: userData.name,
          user_id: userData.user_id,
          email: userData.email,
          college_id: userData.college_id,
          program_id: userData.program_id,
          phone: userData.phone,
          department_id: userData.department_id,
          city: userData.city,
          address: userData.address,
          pincode: userData.pincode,
        }));
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please Enter Valid Enrollment Number");
        return;
      });

    await Http.get(`${FEE_DETAILS_BY_STUDENT_ID}/${user?.user_id}`)
      .then((res) => {
        console.log("Fee Details", res.data.data.fee);
        let y = [];
        res.data.data.fee?.map((item, key) => {
          let parsed_data = isStringified(item.payment_details);
          if (parsed_data?.length > 0) {
            setShow(true);
          }
          parsed_data?.map((item1, key) => {
            item1.fee_type_id = item.fee_type_name;
            y.push(item1);
          });
        });
        setAlldata(y);
        setData(res.data.data.fee);
        for (var i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].balance > 0) {
            setX(true);
            break;
          } else {
            setX(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const getInfo = async () => {
    try {
      let x = data?.filter((s) => s.amount - s.paid_amount > 0);
      let x1 = [];
      let y = new Set();
      x?.forEach((element) => {
        y.add(element.session_id);
        x1.push({
          label: element.fee_type_id,
          value: element.fee_type_id,
          value1: element.session_id,
          fee_id: element.fee_id,
        });
      });

      let z = [...y];
      await setSessions(z);
      await setFeetypes(x1);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData1();
  }, []);

  function isStringified(str) {
    try {
      return JSON.parse(str);
    } catch (err) {
      return str;
    }
  }

  const printReceipts = async (check) => {
    if (!all && printfees?.length == 0 && !check) {
      return toast.error("Please select receipts to print");
    }
    PrintReceipt();
  };

  const handlePrint = async (item) => {
    let x = [];
    try {
      x.push(item);
      await setPrintfees(x);
    } catch (err) {
      console.log(err);
    }
    printReceipts(true);
  };

  return (
    <div className="StudentFee">
      <FeePayment
        data={modaldata}
        user={user}
        allData={data}
        sessions={sessions}
        feetypes={feetypes}
      />
      <div style={{ display: "none" }}>
        <div ref={printRef}>
          <Receipt
            setLoading={setLoading}
            data={printfees}
            collegeId={user?.college_id}
            className={className}
            department={department}
            mainData={user}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 my-3">
          <h4>Student Fees</h4>
        </div>
        <div className="col-md-4">
          <button
            data-toggle="modal"
            style={{
              cursor: "pointer",
            }}
            data-target="#FeeModal"
            onClick={() => {
              getInfo();
              setmodalData(data);
            }}
            className="btn mb-2 mt-2 ml-3 btn-primary btn-small waves-effect waves-light float-right"
          >
            {" "}
            Pay Online
          </button>
        </div>

        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-bordered nowrap overflow-auto">
              <thead>
                <tr>
                  <th colSpan={1}>Session</th>
                  <th colSpan={4} style={{ textAlign: "center" }}>
                    Academic Fees
                  </th>
                  <th colSpan={3}>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th></th>
                  <th>Fee Type</th>
                  <th>Amount</th>
                  <th>Paid</th>
                  <th>Balance</th>
                  <th></th>
                </tr>
                {data &&
                  data?.map((item, key) => {
                    if (item?.balance != 0) {
                      return (
                        <>
                          <tr>
                            <td>{item?.session_id}</td>
                            <td>{item?.fee_type_id}</td>
                            <td>{item?.amount}</td>
                            <td>{item?.paid_amount}</td>
                            <td>{item?.amount - item?.paid_amount}</td>
                            <td>
                              {item?.amount - item?.paid_amount ? (
                                <>
                                  <span className="badge badge-soft-danger">
                                    Un Paid
                                  </span>
                                </>
                              ) : (
                                <span className="badge badge-soft-success">
                                  Paid
                                </span>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-md-8 my-3">
          <h4>Payment History</h4>
        </div>
        {show ? (
          <div className="col-md-4">
            <button
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                printReceipts();
              }}
              className="btn mb-2 mt-2 ml-3 btn-primary btn-small waves-effect waves-light float-right"
            >
              {" "}
              Print Receipts
            </button>
          </div>
        ) : null}
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-bordered nowrap overflow-auto">
              <tbody>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={all ? true : false}
                      onClick={() => {
                        setPrintfees(allData);
                        setAll((flag) => !flag);
                      }}
                    />{" "}
                    All
                  </th>
                  <th>Fee Code</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Payment Id</th>
                  <th>Mode</th>
                  <th>Date</th>
                  <th>Paid Amount</th>
                  <th>Action</th>
                </tr>
                {data &&
                  data.map((item, key) => {
                    let parsed_data = isStringified(item?.payment_details);
                    if (parsed_data?.length > 0) {
                      return parsed_data.map((item1, key1) => (
                        <tr key={key1}>
                          <td>
                            <input
                              type="checkbox"
                              checked={
                                all
                                  ? true
                                  : printfees?.find(
                                      (s) => s.payment_id == item1?.payment_id
                                    )
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let check = printfees?.find(
                                  (s) => s.payment_id == item1?.payment_id
                                );
                                if (!check) {
                                  let newArr = [];
                                  item1.fee_type_id = item.fee_type_id;
                                  newArr.push(item1);
                                  if (printfees.length > 0) {
                                    newArr = [...newArr, ...printfees];
                                  }
                                  setPrintfees(newArr);
                                } else {
                                  let newArr1 = [];
                                  newArr1 = printfees?.filter(
                                    (s) => s.payment_id != item1.payment_id
                                  );
                                  setPrintfees(newArr1);
                                }
                                setAll(false);
                              }}
                            />
                          </td>
                          <td>{item?.fee_type_name}</td>
                          <td>
                            {item?.amount - item?.paid_amount ? (
                              <span className="badge badge-soft-danger">
                                Unpaid
                              </span>
                            ) : (
                              <span className="badge badge-soft-success">
                                Paid
                              </span>
                            )}
                          </td>
                          <td>{item?.amount}</td>
                          <td>{item1?.payment_id}</td>
                          <td>{item1?.mode}</td>
                          <td>{item1?.date?.split("T")[0]}</td>
                          <td>{item1?.amount}</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="badge badge-light"
                              onClick={() => {
                                item1.fee_type_id = item.fee_type_id;
                                handlePrint(item1);
                              }}
                            >
                              {" "}
                              <i className="fa fa-print" />{" "}
                            </a>
                          </td>
                        </tr>
                      ));
                    }
                    return null; // Return null if parsed_data is empty
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fee;
