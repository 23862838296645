import React, { useState, useEffect } from "react";
import { getFileUrl } from "../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_IMAGE } from "../../../utils/AssetsReferenceTypes";
import InputDoc from "../InputDoc/InputDoc";

function AyurvedaPG({
  setStep,
  updateForm,
  data,
  form_data,
  set_form_data,
  setLoading,
}) {
  const [info, setInfo] = useState({});

  //Function upload attachment to the s3
  const addAttachment = async (e, str) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_IMAGE,
        `${info?.user_id}_${str}`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      setInfo({
        ...info,
        [e.target.name]: d ? d : "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDay();
  const futureDate = date.getDate() + 3;
  // date.setDate(futureDate);
  const [defaultDate, setDefaultDate] = useState(
    date.toISOString().split("T")[0]
  );
  console.log(defaultDate);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (data?.form_data) {
      setInfo({
        ...form_data,
      });
    }
  }, [data.form_data]);

  useEffect(() => {
    set_form_data(info);
  }, [info]);

  return (
    <div>
      <div className="register-area mt-2 pb-70">
        <div className="">
          <div className="register" style={{ maxWidth: "100%" }}>
            <form action="">
              {/* <div className="ror">
                <div className="col-md-4">
                    <select 
                    name="application_for"
                    value={info?.application_for}
                    onChange={handleChange}
                    className="form-control"
                    >
                        <option value="">Select Course</option>
                        <option value="BAMS">BAMS</option>
                        <option value="BHMS">BHMS</option>
                    </select>
                </div>
            </div> */}
              <div className="row mt-3">
                <div className="col-md-12 mb-3">
                  <h4>(B)Academic Information:</h4>
                </div>
                <br />
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Board</label>
                    <select
                      name="board"
                      value={info?.board}
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option value="">Select Board</option>

                      <option value="GHSEB">GHSEB</option>
                      <option value="CBSE">CBSE</option>
                      <option value="ICSE">ICSE</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Month & Year of Passing</label>

                    <input
                      type="month"
                      className="form-control"
                      name="month_year"
                      value={info?.month_year}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Examination Seat No. </label>

                    <input
                      type="text"
                      className="form-control"
                      name="examination_seat_no"
                      value={info?.examination_seat_no}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>GUJCET No. </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="GUJCET Seat No."
                      name="gujcet_no"
                      value={info?.gujcet_no}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Marks </label>

                    <input
                      type="number"
                      className="form-control"
                      placeholder="GUJCET Marks"
                      name="gujcet_marks"
                      value={info?.gujcet_marks}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>AIPGT No. </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="AIPGT No."
                      name="aipgt_no"
                      value={info?.aipgt_no}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Obtained Marks in AIPGT </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Obtained Marks in AIPGT"
                      name="aipgt_marks"
                      value={info?.aipgt_marks}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <hr />
              <div className="row">
                <h4>(D) Upload Documents</h4>
                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"AIPGT Marksheet"}
                    value={info?.attach_aipgt_markssheet}
                    important={true}
                    name={"attach_aipgt_markssheet"}
                    onChange={(e) => {
                      addAttachment(e, "attach_aigpt_markssheet");
                    }}
                  />
                </div>
                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Reg Form"}
                    value={info?.reg_form}
                    important={true}
                    name={"reg_form"}
                    onChange={(e) => {
                      addAttachment(e, "reg_form");
                    }}
                  />
                </div>
                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Degree Ceritificate"}
                    value={info?.degree}
                    important={true}
                    name={"degree"}
                    onChange={(e) => {
                      addAttachment(e, "degree");
                    }}
                  />
                </div>
                <div className="col-lg-4 col-md-6 mt-4">
                  <InputDoc
                    lable={"School Leaving Certificate"}
                    value={info?.attach_school_lc}
                    name={"attach_school_lc"}
                    onChange={(e) => {
                      addAttachment(e, "attach_school_lc");
                    }}
                  />
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Caste Certificate (if Applicable)"}
                    value={info?.attach_caste_cert}
                    name={"attach_caste_cert"}
                    onChange={(e) => {
                      addAttachment(e, "attach_caste_cert");
                    }}
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <h5 className="text-center">
                    <u> UNDERTAKING </u>
                  </h5>
                  <div className="form-group ">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input check mr-5"
                        name="agree"
                        value={info?.undertaking}
                        onChange={(e) =>
                          setInfo({ ...info, undertaking: e.target.value })
                        }
                        type="checkbox"
                        defaultChecked=""
                        required=""
                        id="inlineCheckbox1"
                        defaultValue={1}
                      />
                      <p
                        htmlFor="inlineCheckbox1 ml-5"
                        style={{ textAlign: "justify" }}
                      >
                        I / We hereby declare that:
                      </p>
                      <ol>
                        <li>
                          All the information provided by me is true to the best
                          of my knowledge and belief.
                        </li>
                        <li>
                          Any change in any information provided earlier will be
                          informed to the institute with documentary proof.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4">
                  <h5>Place:</h5>
                  <input
                    type="text"
                    className="form-control"
                    name="place"
                    value={info?.place}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4">
                  <h5>Date:</h5>
                  <input
                    type="date"
                    className="form-control"
                    name="date"
                    value={info?.date?.split("T")[0]}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AyurvedaPG;
