import React from "react";
import "./Topbar.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import getUniversityDetails from "../../../Data/navbar/StudentPortal/universityDetails.api";
import { STUDENT } from "../student.Api";

function TopbarStudent() {
  const navigate = useNavigate();
  let name = sessionStorage.getItem("name");

  const Logout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const [open, setOpen] = useState(false);
  const [universityData, setUniversityData] = useState([]);
  const [studentdata, setStudentData] = useState([]);

  let uid = sessionStorage.getItem("uid");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
        console.log("universityData -", universityData);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();
  }, []);

  const getData = async () => {
    const config = {
      method: "get",
      url: `${STUDENT}/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setStudentData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="Topbar">
        <header id="page-topbar">
          <div className=" d-flex justify-content-between">
            <div className="d-flex justify-content-start mh-100">
              <div className="d-flex justify-content-start">
                <Link
                  to="#"
                  className="d-flex justify-content-center align-items-center px-2"
                  style={{ background: "white" }}
                >
                  <img
                    src={
                      "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/kbn_assets/employee_image/university_logo_primary/undefined_1709470215717.png"
                    }
                    // src={universityData.logo_primary}
                    alt=""
                    className=""
                    width={180}
                  />
                </Link>
              </div>
            </div>

            <div className="d-flex">
              <p
                style={{
                  margin: "auto",
                  fontSize: "1.3rem",
                  marginRight: "1rem",
                  color: "white",
                }}
              >
                {name}
              </p>
              <button
                style={{ background: "none", border: "none" }}
                onClick={() => setOpen(!open)}
              >
                {/* <img
                  className="rounded-circle header-profile-user"
                  src="/assets/images/users/avatar-2.jpg"
                  alt="Header Avatar"
                /> */}
                <img
                  className="rounded-circle header-profile-user"
                  src={
                    studentdata?.student_picture
                      ? studentdata?.student_picture
                      : "/assets/images/users/avatar-2.jpg"
                  }
                  alt="Header Avatar"
                />
              </button>

              {open ? (
                <div className="action-list">
                  <ul>
                    <li>
                      <div
                        className="dropdown-item text-danger w-100 cursor-pointer"
                        onClick={() => {
                          Logout();
                        }}
                      >
                        <i className="ri-shut-down-line align-middle mr-1 text-danger" />{" "}
                        Logout
                      </div>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

export default TopbarStudent;
