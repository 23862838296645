import React, { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
  ACADEMICS_ADD_SUBJECT,
  ACADEMICS_CLASS_TIME_TABLE,
  COURSE_TIME_TABLE_GET,
} from "../../utils/Academics.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import useEmployee from "../../Hooks/Employee/useEmployee";
import "./TimeTable.scss";
import Nodata from "../../Components/NoData/Nodata";
import TimetableDaySwitches from "../../Components/Academics/TimetableDaySwitches";
import { sessionOpt } from "./../../Data/jsonData/Academics/Academics";
import Select from "react-select";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";

import "rc-time-picker/assets/index.css";
import moment from "moment";

function ClassTimeTable({ collegeId, setLoading }) {
  const selectInputRef = useRef();
  let role = sessionStorage.getItem("role");

  const [visibility, setVisibility] = useState(true);

  const now = moment().hour(0).minute(0);

  function onChange(value) {
    console.log(value && value.format(format));
  }

  const format = "h:mm a";

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: " Class Time Table List ",
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [courseTimeTable, setCourseTimeTableData] = useState([]);

  console.log("courseTimeTable -", courseTimeTable);

  // console.log("collegeOpt -", collegeOpt);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    const formattedTime = `${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
    return `${formattedDate} - ${formattedTime}`;
  };

  const getDate2 = (day) => {
    const day_index =
      [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ].indexOf(day) + 1;
    let date_for_day = week[day_index];
    date_for_day = new Date(date_for_day);
    date_for_day = date_for_day.toISOString()?.split("T")[0];
    return date_for_day;
  };

  //DayData
  const tabData = [
    {
      name: "Monday",
      id: "MONDAY",
      color: "bg-sky",
    },
    {
      name: "Tuesday",
      id: "TUESDAY",
      color: "bg-green",
    },
    {
      name: "Wednesday",
      id: "WEDNESDAY",
      color: "bg-yellow",
    },
    {
      name: "Thursday",
      id: "THURSDAY",
      color: "bg-lightred",
    },
    {
      name: "Friday",
      id: "FRIDAY",
      color: "bg-purple",
    },
    {
      name: "Saturday",
      id: "SATURDAY",
      color: "bg-pink",
    },
    {
      name: "Sunday",
      id: "SUNDAY",
    },
  ];

  const [employeeOpt] = useEmployee(collegeId);

  const mapTime = (time) => {
    var hoursTime = parseInt(time?.split(":")[0]);
    var minutesTime = parseInt(time?.split(":")[1]);
    var timeFormat = "";

    if (hoursTime > 12) {
      hoursTime = hoursTime - 12;
      timeFormat = "pm";
    } else {
      timeFormat = "am";
    }

    return hoursTime + ":" + minutesTime + " " + timeFormat;
  };

  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  const getWeekNumber = () => {
    var currentDate = new Date();
    var startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    var weekNumber = Math.ceil(days / 7);
    return weekNumber;
  };

  function firstDayOfThisWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }
  const [week, setWeek] = useState([]);
  const [thisWeeek, setThisWeek] = useState(getWeekNumber());

  const [firstDayOfWeek, setFirstDayOfWeek] = useState(
    firstDayOfThisWeek(getWeekNumber(), new Date().getFullYear())
  );

  useEffect(() => {
    setDepartmentOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)).filter(
        (item) => item.college_id == collegeId
      )
    );
    setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const [classopt, setClassOpt] = useState([]);

  const [sectionopt, setSectionOpt] = useState([]);

  const [subOpt, setSubOpt] = useState([]);

  const [edit, setEdit] = useState(false);

  const [editId, setEditId] = useState();

  const [transfers, setTransfers] = useState([]);

  const check = sessionStorage.getItem("is_Coordinator");

  const navigateToCreate = () => {
    if (
      !user.department_id ||
      !user?.session_id ||
      !user?.semester_id ||
      !user?.section_id
    ) {
      return toast.error("Please Select All Fields");
    }
    setVisibility((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //get The Inintila Data
  const getData = async () => {
    if (!user.section_id) return toast.error("Please Select Section to search");
    if (!user.session_id)
      return toast.error("Please Select Session or Academic Year ");
    setLoading(1);
    const config = {
      method: "get",
      url:
        ACADEMICS_CLASS_TIME_TABLE +
        `?college_id=${collegeId}&section_id=${user.section_id}&session_id=${user.session_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("time table - ", res.data.data);
        console.log("transfers - ", res.data.transfers);
        setTransfers(res.data.transfers);
        setData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });

    const config2 = {
      method: "get",
      url: COURSE_TIME_TABLE_GET,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setCourseTimeTableData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const getAllData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data2, data3, data4, data5] = await Promise.all([
      axios({
        ...config,
        url: ACADEMICS_ADD_SEMESTER + `?college_id=${collegeId}`,
      }).catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      }),

      axios({
        ...config,
        url: ACADEMICS_ADD_SUBJECT + `?college_id=${collegeId}&getInactive=0`,
      }).catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      }),
      axios({
        ...config,
        url: ACADEMICS_ADD_CLASS + `?college_id=${collegeId}`,
      }).catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      }),
      axios({
        ...config,
        url: ACADEMICS_ADD_SECTION + `?college_id=${collegeId}`,
      }).catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      }),
    ]);

    setLoading(0);

    setSubOpt(data2?.data.data);
    setSectionOpt(data4?.data.data);

    const data = data1.data.data;
    const classData = data3.data.data;

    console.log("classdata - ", classData);
    for (var i of data) {
      let ff = classData?.find((item) => item.id == i.class_id);
      if (ff) {
        i.name = ff.name + " (" + i.name + ") ";
        i.department_id = ff.department_id;
      }
    }

    console.log("classes - ", data);

    setClassOpt(data);
  };

  useEffect(() => {
    getAllData();
  }, []);

  const [tab, setTab] = useState(tabData[0].id);
  const [data, setData] = useState([]);

  const [user, setUser] = useState({
    day: tab,
    class_id: "",
    semester_id: "",
    department_id: "",
    section_id: "",
    course_id: "",
    employee_id: [],
    time_from: "",
    time_to: "",
    session_id: "",
  });

  useEffect(() => {
    setUser({ ...user, day: tab });
  }, [tab]);

  const clearData = () => {
    setUser((prev) => ({
      ...prev,
      employee_id: [],
      time_from: "",
      time_to: "",
      course_id: "",
    }));
  };

  //get The Inintila Data
  const getDataCreate = async () => {
    if (!user.section_id) return toast.error("Please Select Section to search");
    if (!user.session_id)
      return toast.error("Please Select Session or Academic Year ");
    setLoading(1);
    const config = {
      method: "get",
      url:
        ACADEMICS_CLASS_TIME_TABLE +
        `?college_id=${collegeId}&&section_id=${user.section_id}&&session_id=${user.session_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  //Add New Data
  const handleSubmit = async () => {
    if (!user.course_id) return toast.error("Please select subject");
    if (!user.employee_id || user.employee_id.length == 0)
      return toast.error("Please select staff");
    if (!user.time_from || !user.time_to)
      return toast.error("Please select from and to time");

    setLoading(1);
    const config = {
      method: "post",
      url: ACADEMICS_CLASS_TIME_TABLE,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        college_id: collegeId,
        employee_id: JSON.stringify(user.employee_id),
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data added successfully");
        clearData();

        getDataCreate();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };
  //Add New Data
  const handleEdit = async () => {
    setLoading(1);
    const config = {
      method: "put",
      url: ACADEMICS_CLASS_TIME_TABLE + "/" + editId,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        employee_id: JSON.stringify(user.employee_id),
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data added successfully");
        clearData();
        getData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });

    setEdit(false);
  };

  //Delete Data
  const handleDelete = async (i) => {
    setLoading(1);
    const config = {
      method: "put",
      url: ACADEMICS_CLASS_TIME_TABLE + "/" + i.id,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data Deleted successfully");
        // clearData();
        getData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    clearData();
  }, [tab]);

  const updatedEmployeeOpt = employeeOpt?.map((item) => {
    return {
      value: item.id,
      label: item.first_name + " " + item.last_name,
    };
  });

  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    const idToSelect = () => {
      try {
        if (user.employee_id) {
          const temp = user.employee_id.map((item) => {
            const filteredObject = updatedEmployeeOpt.find(
              (item2) => item2.value === item
            );

            return {
              value: item,
              label: filteredObject ? filteredObject.label : "Not Found",
            };
          });

          return temp;
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return [];
      }
    };

    setSelectedValue(idToSelect());

    console.log(user);
  }, [user]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setFirstDayOfWeek(firstDayOfThisWeek(thisWeeek, currentYear));
  }, [thisWeeek]);

  useEffect(() => {
    const arr = [];

    for (var i = 0; i < 7; i++) {
      var nextDay = new Date(firstDayOfWeek);
      nextDay.setDate(firstDayOfWeek.getDate() + i);
      arr[i] = nextDay;
    }
    setWeek(arr);
  }, [firstDayOfWeek]);

  console.log("data -", data);
  const emp_id = sessionStorage.getItem("employee_id");
  // console.log("emp_id -", emp_id);
  console.log("transfers", transfers);
  const transferData = transfers?.find(
    (s) => s.emp_from == data?.find((d) => d.employee_id)
  );
  // console.log("transferData", transferData);
  // console.log("Timetable ID:", transfers[1]?.timetable_id);
  // console.log(
  //   "Matching Course:",
  //   courseTimeTable?.find((s) => s.id === transferData?.timetable_id)
  // );

  return (
    <div className="ClassTimeTable">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Academics</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Academics</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Create Time Table
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h2 className="card-title">Select Criteria</h2>

                        {role != "STAFF" || check ? (
                          <button
                            onClick={navigateToCreate}
                            type="button"
                            className="btn btn-primary btn-rounded btn-sm float-lg-right"
                            name="submit"
                          >
                            {visibility ? (
                              <>
                                <i className="fa fa-plus" aria-hidden="true" />{" "}
                                Add more{" "}
                              </>
                            ) : (
                              <>View</>
                            )}
                          </button>
                        ) : null}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Department<span style={{ color: "red" }}>*</span>
                          </label>

                          <Select
                            value={
                              user?.department_id
                                ? {
                                    value: user?.department_id,
                                    label: departmentOpt.find(
                                      (data) => data.id == user?.department_id
                                    )?.name,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                department_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                                semester_id: "",
                                section_id: "",
                              }));
                            }}
                            options={departmentOpt.map((i) => ({
                              value: i.id,
                              label: `${i.name}, ${
                                programOpt?.find((s) => s.id == i.program_id)
                                  ?.name
                              }`,
                            }))}
                            placeholder="Select Department"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Session / Academic Year
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <Select
                            value={
                              user?.session_id
                                ? {
                                    value: user?.session_id,
                                    label: sessionOpt.find(
                                      (data) => data.id == user?.session_id
                                    )?.name,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                session_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                              }));
                            }}
                            options={sessionOpt.map((i) => ({
                              value: i.id,
                              label: i.name,
                            }))}
                            placeholder="Select Session"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Semester<span style={{ color: "red" }}>*</span>
                          </label>

                          <Select
                            value={
                              user?.semester_id
                                ? {
                                    value: user?.semester_id,
                                    label: classopt.find(
                                      (data) => data.id == user?.semester_id
                                    )?.name,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                semester_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                                section_id: "",
                              }));
                            }}
                            options={classopt
                              ?.filter(
                                (s) => s.department_id == user.department_id
                              )
                              ?.map((i) => ({
                                value: i.id,
                                label: i.name,
                              }))}
                            placeholder="Select Semester"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Section<span style={{ color: "red" }}>*</span>
                          </label>

                          <Select
                            value={
                              user?.section_id
                                ? {
                                    value: user?.section_id,
                                    label: sectionopt
                                      ?.filter(
                                        (s) =>
                                          s.semester_id == user.semester_id &&
                                          s.department_id == user.department_id
                                      )
                                      ?.find(
                                        (data) => data.id == user?.section_id
                                      )?.name,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                section_id: selectedOption
                                  ? selectedOption.value
                                  : "",
                              }));
                            }}
                            options={sectionopt
                              ?.filter(
                                (s) =>
                                  s.semester_id == user.semester_id &&
                                  s.department_id == user.department_id
                              )
                              ?.map((i) => ({
                                value: i.id,
                                label: i.name,
                              }))}
                            placeholder="Select Section"
                          />
                        </div>
                      </div>
                    </div>
                    {visibility ? (
                      <div className="row ">
                        <div className="col-md-12 ml-auto">
                          <button
                            className="btn btn-nex btn-rounded float-lg-right "
                            type="submit"
                            name="submit"
                            onClick={getData}
                          >
                            <i className="fa fa-search" aria-hidden="true" />{" "}
                            Search
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="row ">
                        <div className="col-md-12 ml-auto">
                          <button
                            className="btn btn-nex btn-rounded float-lg-right "
                            type="submit"
                            name="submit"
                            onClick={getDataCreate}
                          >
                            <i className="fa fa-search" aria-hidden="true" />{" "}
                            Search
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            {visibility ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-md-12">
                          <button
                            className="btn btn-primary rounded-pill ml-2 float-right"
                            // onClick={handlePrint}

                            onClick={handlePrint}
                          >
                            Print Time Table
                          </button>
                        </div>
                        <div className="col-md-12 d-flex align-items-center justify-content-center">
                          <h5 className="text-center d-flex align-items-center justify-content-center mt-4">
                            {" "}
                            <a
                              href="javascript:void(0)"
                              className=""
                              onClick={() => setThisWeek(thisWeeek - 1)}
                            >
                              <i className="ri-arrow-left-s-line" />
                            </a>{" "}
                            &nbsp;&nbsp;
                            {week.length != 0 && (
                              <>
                                {week[0]?.toDateString()} To{" "}
                                {week[6]?.toDateString()}
                              </>
                            )}
                            &nbsp;&nbsp;
                            <a
                              href="javascript:void(0)"
                              className=""
                              onClick={() => setThisWeek(thisWeeek + 1)}
                            >
                              <i className="ri-arrow-right-s-line" />
                            </a>{" "}
                          </h5>
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-bordered text-center">
                          <thead className="font-weight-bold ">
                            <tr>
                              {week?.map((d, k) => (
                                <td className="text-center">
                                  {d.toDateString()}
                                </td>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {tabData?.map((i, key) => (
                                <td key={key}>
                                  <table
                                    style={{ width: "100%" }}
                                    className="table-row-gap"
                                  >
                                    {data
                                      ?.filter((s) => s.day == i.id)
                                      ?.map((j, key2) => (
                                        <tr key={key2} className="mb-2">
                                          <td>
                                            <span
                                              style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "inline-block",
                                              }}
                                              className={`${i.color} padding-5px-tb padding-15px-lr border-radius-5 margin-10px-bottom text-white font-size16  xs-font-size13`}
                                            >
                                              {/* {
                                                subOpt?.find(
                                                  (s) => s.id == j.course_id
                                                )?.name
                                              }{" "}
                                              - {j?.course_type}{" "}
                                              {subOpt?.find(
                                                (s) => s.id == j.course_id
                                              )?.status == "INACTIVE" ? (
                                                <span className="badge badge-soft-danger">
                                                  Subject Deleted
                                                </span>
                                              ) : (
                                                ""
                                              )} */}
                                              {transfers?.find(
                                                (s) =>
                                                  s.timetable_id == j.id &&
                                                  getDate2(j.day) ==
                                                    s.date.split("T")[0]
                                              ) ? (
                                                <>
                                                  <p>
                                                    {/* {
                                                      courseTimeTable?.find(
                                                        (s) =>
                                                          s.id ===
                                                          transfers[0]
                                                            ?.timetable_id
                                                      )?.course_name
                                                    } */}
                                                    {/* {j.employee_id} */}
                                                    {
                                                      courseTimeTable?.find(
                                                        (ct) =>
                                                          ct.id ==
                                                          transfers?.find(
                                                            (tr) =>
                                                              tr.emp_to ===
                                                              JSON.parse(
                                                                j.employee_id
                                                              )[0]
                                                          )?.timetable_id
                                                      )?.course_name
                                                    }
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  <p>
                                                    {
                                                      subOpt?.find(
                                                        (s) =>
                                                          s.id == j?.course_id
                                                      )?.name
                                                    }{" "}
                                                    - {j?.course_type}{" "}
                                                    {subOpt?.find(
                                                      (s) => s.id == j.course_id
                                                    )?.status == "INACTIVE" ? (
                                                      <span className="badge badge-soft-danger">
                                                        Subject Deleted
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>
                                                </>
                                              )}
                                            </span>
                                            <div className="margin-10px-top font-size14">
                                              {/* {mapTime(j.time_from)}&nbsp;
                                              &rarr; &nbsp;{mapTime(j.time_to)} */}
                                              {j.time_from}&nbsp; &rarr; &nbsp;
                                              {j.time_to}
                                            </div>
                                            {transfers?.find(
                                              (s) =>
                                                s.timetable_id == j.id &&
                                                getDate2(j.day) ==
                                                  s.date.split("T")[0]
                                            ) ? (
                                              <div className="font-size13 text-primary">
                                                {/* {
                                                  transfers?.find(
                                                    (s) =>
                                                      s.timetable_id == j.id &&
                                                      getDate2(j.day) ==
                                                        s.date.split("T")[0]
                                                  )?.type
                                                } */}
                                                <p
                                                  className="badge badge-soft-primary"
                                                  //                     className={`badge badge-soft-${
                                                  //   j.status ? "info" : "info"
                                                  // }`}
                                                >
                                                  {transfers?.find(
                                                    (s) =>
                                                      s.timetable_id == j.id &&
                                                      getDate2(j.day) ==
                                                        s.date.split("T")[0]
                                                  )?.type === "SWAP"
                                                    ? "SWAPPED"
                                                    : transfers?.find(
                                                        (s) =>
                                                          s.timetable_id ==
                                                            j.id &&
                                                          getDate2(j.day) ==
                                                            s.date.split("T")[0]
                                                      )?.type}
                                                </p>
                                                <br />
                                                {
                                                  employeeOpt?.find(
                                                    (s) =>
                                                      s.id ===
                                                      transfers?.find(
                                                        (s) =>
                                                          s.timetable_id ==
                                                            j.id &&
                                                          getDate2(j.day) ==
                                                            s.date.split("T")[0]
                                                      )?.emp_to
                                                  )?.first_name
                                                }{" "}
                                                {
                                                  employeeOpt?.find(
                                                    (s) =>
                                                      s.id ===
                                                      transfers?.find(
                                                        (s) =>
                                                          s.timetable_id ==
                                                            j.id &&
                                                          getDate2(j.day) ==
                                                            s.date.split("T")[0]
                                                      )?.emp_to
                                                  )?.last_name
                                                }
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            <div className="font-size13 text-danger">
                                              {Array.isArray(
                                                JSON.parse(j.employee_id)
                                              )
                                                ? JSON.parse(j.employee_id).map(
                                                    (item, index, array) => {
                                                      const employee =
                                                        employeeOpt?.find(
                                                          (s) => s.id === item
                                                        );
                                                      const employeeName =
                                                        employee &&
                                                        `${employee.first_name} ${employee.last_name}`;

                                                      return (
                                                        <span key={item}>
                                                          {transfers?.find(
                                                            (s) =>
                                                              s.timetable_id ==
                                                                j.id &&
                                                              getDate2(j.day) ==
                                                                s.date.split(
                                                                  "T"
                                                                )[0]
                                                          ) ? (
                                                            <>
                                                              <p></p>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <p className="mt-2">
                                                                {" "}
                                                                {employeeName
                                                                  ? index +
                                                                    1 +
                                                                    ". " +
                                                                    employeeName
                                                                  : "Staff Deleted"}
                                                                {index <
                                                                  array.length -
                                                                    1 && <br />}
                                                              </p>
                                                            </>
                                                          )}
                                                        </span>
                                                      );
                                                    }
                                                  )
                                                : `Invalid employee IDs: ${j.employee_id}`}
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </table>
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "none" }}>
                  <table
                    class="table table-bordered text-center"
                    ref={tableRef}
                  >
                    <thead>
                      <tr>
                        <th colSpan={10}>
                          <div className="row">
                            <div className="col-12">
                              <p className="text-dark float-left">
                                Printed On - {getCurrentDate()}
                              </p>
                            </div>
                            <div className="col-12">
                              <h3 className="text-primary">
                                <img
                                  className=""
                                  src="../../../assets/images/Nexenstial Logo.jpg"
                                  width="80px"
                                  height="60px"
                                />
                                <span className="ml-3 ">
                                  {
                                    collegeOpt.find((s) => s.id == collegeId)
                                      ?.name
                                  }
                                </span>
                              </h3>
                            </div>
                            <div className="col-3 mt-4">
                              Academic year : {user?.session_id}
                            </div>
                            <div className="col-4 mt-4 ml-5">
                              Department :{" "}
                              {
                                departmentOpt.find(
                                  (data) => data.id == user?.department_id
                                )?.name
                              }
                            </div>
                            <div className="col-4 mt-4">
                              {
                                classopt.find(
                                  (data) => data.id == user?.semester_id
                                )?.name
                              }{" "}
                              -{" "}
                              {
                                sectionopt
                                  ?.filter(
                                    (s) =>
                                      s.semester_id == user.semester_id &&
                                      s.department_id == user.department_id
                                  )
                                  ?.find((data) => data.id == user?.section_id)
                                  ?.name
                              }
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <br />
                    <thead className="text-dark">
                      <tr>
                        <th class="text-uppercase">Day / Time</th>
                        {[...Array(9)].map((_, idx) => {
                          const hour = 9 + idx;
                          return (
                            <th key={idx} class="text-uppercase">
                              {hour}:00 - {hour + 1}:00
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                      ].map((day, dayIdx) => (
                        <tr key={dayIdx}>
                          <td class="text-uppercase">{day}</td>
                          {[...Array(9)].map((_, hourIdx) => {
                            const hour = 9 + hourIdx;
                            const slotStart = `${hour}:00`;
                            const slotEnd = `${hour + 1}:00`;
                            const entry = data?.find(
                              (s) =>
                                s.day === tabData[dayIdx].id &&
                                s.time_from.startsWith(
                                  `${hour < 10 ? "0" + hour : hour}`
                                )
                            );
                            return (
                              <td key={hourIdx}>
                                {entry ? (
                                  <div>
                                    <span className="text-dark">
                                      <h6>
                                        {
                                          subOpt?.find(
                                            (s) => s.id == entry.course_id
                                          )?.name
                                        }{" "}
                                        {subOpt?.find(
                                          (s) => s.id == entry.course_id
                                        )?.status == "INACTIVE" ? (
                                          <span className="badge badge-soft-danger">
                                            Subject Deleted
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </h6>
                                    </span>
                                    <div className="margin-10px-top font-size14">
                                      {entry.time_from}&nbsp; &rarr; &nbsp;
                                      {entry.time_to}
                                    </div>
                                    <div className="font-size13 text-dark mt-1">
                                      {Array.isArray(
                                        JSON.parse(entry.employee_id)
                                      )
                                        ? JSON.parse(entry.employee_id).map(
                                            (item, index, array) => {
                                              const employee =
                                                employeeOpt?.find(
                                                  (s) => s.id === item
                                                );
                                              const employeeName =
                                                employee &&
                                                `${employee.first_name} ${employee.last_name}`;
                                              return (
                                                <span key={item}>
                                                  {employeeName
                                                    ? index +
                                                      1 +
                                                      ". " +
                                                      employeeName
                                                    : "Staff Deleted"}
                                                  {index < array.length - 1 && (
                                                    <br />
                                                  )}
                                                </span>
                                              );
                                            }
                                          )
                                        : `Invalid employee IDs: ${entry.employee_id}`}
                                    </div>
                                  </div>
                                ) : (
                                  <div>---</div>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <>
                        <TimetableDaySwitches
                          tabData={tabData}
                          tab={tab}
                          setTab={setTab}
                        />
                        <div
                          class="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            class="table"
                            width="100%"
                          >
                            <thead>
                              <tr>
                                <th>Subject</th>
                                <th>Teacher</th>
                                <th> From Time </th>
                                <th>To Time</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <select
                                    name="course_id"
                                    id="subject"
                                    className="form-control"
                                    value={user.course_id}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Subject</option>
                                    {subOpt
                                      ?.filter(
                                        (s) => s.semester_id == user.semester_id
                                      )
                                      ?.map((i, key) => (
                                        <option value={i.id} key={key}>
                                          {i.name} - {i?.type}
                                        </option>
                                      ))}
                                  </select>
                                </td>

                                <td>
                                  <Select
                                    isMulti
                                    value={selectedValue}
                                    name="employee_id"
                                    options={updatedEmployeeOpt}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e) => {
                                      const selectedIds = e.map(
                                        (item) => item.value
                                      );
                                      setUser((prev) => ({
                                        ...prev,
                                        employee_id: selectedIds,
                                      }));
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="time"
                                    name="time_from"
                                    id="from"
                                    className="form-control"
                                    value={user.time_from}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="time"
                                    name="time_to"
                                    id="from"
                                    className="form-control"
                                    value={user.time_to}
                                    onChange={handleChange}
                                  />
                                  {/* <TimePicker
                                    showSecond={false}
                                    defaultValue={now}
                                    className="xxx"
                                    onChange={onChange}
                                    format={format}
                                    use12Hours
                                    inputReadOnly
                                  /> */}
                                </td>
                                <td>
                                  {edit ? (
                                    <button
                                      class="btn btn-success"
                                      onclick="saveTimetable('sa')"
                                      type="button"
                                      onClick={handleEdit}
                                    >
                                      <i
                                        class="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : (
                                    <button
                                      class="btn btn-success"
                                      onclick="saveTimetable('sa')"
                                      type="button"
                                      onClick={handleSubmit}
                                    >
                                      <i
                                        class="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                              {data && data.length != 0 ? (
                                data
                                  ?.filter((s) => s.day == tab)
                                  ?.map((i, key) => (
                                    <tr key={key}>
                                      <td style={{ verticalAlign: "inherit" }}>
                                        <font
                                          style={{ verticalAlign: "inherit" }}
                                        >
                                          {
                                            subOpt?.find(
                                              (s) => s.id == i.course_id
                                            )?.name
                                          }
                                        </font>
                                      </td>
                                      <td style={{ verticalAlign: "inherit" }}>
                                        <font
                                          style={{ verticalAlign: "inherit" }}
                                        >
                                          {Array.isArray(
                                            JSON.parse(i.employee_id)
                                          )
                                            ? JSON.parse(i.employee_id).map(
                                                (item, index, array) => {
                                                  const employee =
                                                    employeeOpt?.find(
                                                      (s) => s.id === item
                                                    );
                                                  const employeeName =
                                                    employee &&
                                                    `${employee.first_name} ${employee.last_name}`;

                                                  return (
                                                    <span key={item}>
                                                      {index +
                                                        1 +
                                                        ". " +
                                                        employeeName}
                                                      {index <
                                                        array.length - 1 && (
                                                        <br />
                                                      )}
                                                    </span>
                                                  );
                                                }
                                              )
                                            : `Invalid employee IDs: ${i.employee_id}`}
                                          {/* {i.employee_id} */}
                                        </font>
                                      </td>
                                      <td style={{ verticalAlign: "inherit" }}>
                                        <font
                                          style={{ verticalAlign: "inherit" }}
                                        >
                                          {i?.time_from}
                                        </font>
                                      </td>
                                      <td style={{ verticalAlign: "inherit" }}>
                                        <font
                                          style={{ verticalAlign: "inherit" }}
                                        >
                                          {i?.time_to}
                                        </font>
                                      </td>
                                      <td>
                                        <abbr title="Edit">
                                          {" "}
                                          <button
                                            className="btn"
                                            onClick={() => {
                                              setEdit(true);
                                              setEditId(i?.id);

                                              // Avoid direct mutation, create a new array
                                              const parsedEmployeeIds =
                                                JSON.parse(i?.employee_id) ||
                                                [];

                                              const temp =
                                                parsedEmployeeIds.map(
                                                  (itemId) => {
                                                    const filteredObject =
                                                      updatedEmployeeOpt.find(
                                                        (item) =>
                                                          item.value === itemId
                                                      );

                                                    return {
                                                      value: itemId,
                                                      label: filteredObject
                                                        ? filteredObject.label
                                                        : "Not Found",
                                                    };
                                                  }
                                                );

                                              setUser({
                                                ...i,
                                                employee_id: parsedEmployeeIds,
                                              }); // Avoid direct mutation
                                              setSelectedValue(temp);
                                            }}
                                          >
                                            <i
                                              className="fa fa-pencil text-primary"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        </abbr>
                                        <abbr title="Delete">
                                          <button
                                            className="btn"
                                            id="sa-del"
                                            onClick={() => {
                                              handleDelete(i);
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash text-danger"
                                              aria-hidden="true"
                                            />{" "}
                                          </button>
                                        </abbr>
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr>
                                  <td colSpan={10}>
                                    <Nodata />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassTimeTable;
