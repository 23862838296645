import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { USERS_GET_DATA } from "../../utils/InfoUploadingApiConstants";
import { GET_EMP_DATA, UPDATE_PASSWORD } from "../../utils/apiConstants";

function ResetPasswordModal({ setLoading }) {
  const buttonRef = useRef(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    // console.log("close modal called");
    if (buttonRef.current) {
      buttonRef.current.click();
    }
    setModalOpen(false);
  };

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [oldPassword, setOldPassword] = useState([]);
  const [newPassword, setNewPassword] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState([]);
  const [isOldPasswordCorrect, setIsOldPasswordCorrect] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [isConfirmPasswordCorrect, setIsConfirmPasswordCorrect] =
    useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const employee_id = sessionStorage.getItem("employee_id");
  // console.log("employee_id -", employee_id);

  const getData = async () => {
    const config = {
      method: "get",
      url: `${GET_EMP_DATA}/${employee_id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setData(res.data.data);
        // console.log("users data -", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleOldPasswordChange = (e) => {
    const value = e.target.value;
    setOldPassword(value);
    if (value === data.password) {
      setIsOldPasswordCorrect(true);
    } else {
      setIsOldPasswordCorrect(false);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value === newPassword) {
      setIsConfirmPasswordCorrect(true);
    } else {
      setIsConfirmPasswordCorrect(false);
    }
  };

  // console.log("confirmPassword -", confirmPassword);

  const handleUpdate = async () => {
    // setLoading(1);
    const config2 = {
      method: "put",
      url:
        UPDATE_PASSWORD + `/${employee_id}` + `?passwords=${confirmPassword}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setLoading(0);
        toast.success("Updated Password Successfully");
        console.log(res);
        getData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };

  // useEffect(()=> {
  //   handleUpdate();
  // },[]);

  const handleResetPasswordClick = () => {
    setTriggerUpdate(true);
    toast.success("Updated Password Successfully");
    setLoading(0);
  };

  // console.log("triggerUpdate -", triggerUpdate);

  useEffect(() => {
    if (triggerUpdate) {
      handleUpdate();
    }
  }, [triggerUpdate]);

  return (
    <div className="ModalLeaveType">
      <div
        className="modal fade"
        id="ResetPassword"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content resett">
            <div className="row">
              <div className="col-12">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Reset Password{" "}
                  </h5>
                  <button
                    ref={buttonRef}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <i className="ri-lock-2-line auti-custom-input-icon" />
                      <i
                        className={`${
                          showPassword ? "ri-eye-line" : "ri-eye-close-line"
                        } password-toggler`}
                        style={{
                          position: "absolute",
                          top: "42%",
                          right: "30px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          transform: "translateY(-25%)",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      ></i>
                      <lable>
                        {" "}
                        Old Password <span style={{ color: "red" }}>*</span>
                      </lable>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control mt-1"
                        name="oldPassword"
                        value={oldPassword}
                        // onChange={(e) => {
                        //   setOldPassword(e.target.value);
                        // }}
                        onChange={handleOldPasswordChange}
                        placeholder="Enter Old Password"
                      />

                      {!isOldPasswordCorrect && oldPassword && (
                        <div style={{ color: "red" }} className="mt-1">
                          Old password is not correct
                        </div>
                      )}
                    </div>
                  </div>
                  {isOldPasswordCorrect && (
                    <>
                      <div className="col-12">
                        <div className="form-group">
                          <i className="ri-lock-2-line auti-custom-input-icon" />
                          <i
                            className={`${
                              showPassword2
                                ? "ri-eye-line"
                                : "ri-eye-close-line"
                            } password-toggler`}
                            style={{
                              position: "absolute",
                              top: "42%",
                              right: "30px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              transform: "translateY(-25%)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowPassword2(!showPassword2);
                            }}
                          ></i>
                          <lable>
                            {" "}
                            New Password <span style={{ color: "red" }}>*</span>
                          </lable>
                          <input
                            // type="password"
                            type={showPassword2 ? "text" : "password"}
                            className="form-control mt-1"
                            name="newPassword"
                            value={newPassword}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                            }}
                            placeholder="Enter New Password"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <i className="ri-lock-2-line auti-custom-input-icon" />
                          <i
                            className={`${
                              showPassword3
                                ? "ri-eye-line"
                                : "ri-eye-close-line"
                            } password-toggler`}
                            style={{
                              position: "absolute",
                              top: "42%",
                              right: "30px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              transform: "translateY(-25%)",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowPassword3(!showPassword3);
                            }}
                          ></i>
                          <lable>
                            {" "}
                            Confirm Password{" "}
                            <span style={{ color: "red" }}>*</span>
                          </lable>
                          <input
                            // type="password"
                            type={showPassword3 ? "text" : "password"}
                            className="form-control mt-1"
                            name="confirmPassword"
                            value={confirmPassword}
                            // onChange={(e) => {
                            //   setConfirmPassword(e.target.value);
                            // }}
                            onChange={handleConfirmPasswordChange}
                            placeholder="Confirm Password"
                          />
                          {!isConfirmPasswordCorrect && confirmPassword && (
                            <div style={{ color: "red" }} className="mt-1">
                              confirm password is not correct
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {isOldPasswordCorrect && (
                  <>
                    <div className="row mt-4 float-right">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          data-dismiss="modal"
                          aria-label="Close"
                          className="btn btn-primary"
                          //   onClick={handleUpdate}
                          onClick={handleResetPasswordClick}
                        >
                          Reset Password
                        </button>
                      </div>
                      <br />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordModal;
