export const USER_AUTH = 'student_auth'
export const COLLEGE = 'college'
export const DEPARTMENT = 'department'
export const PROGRAM = 'program'
export const UID = 'uid'
export const CLASS_ID = 'class_id'
export const CLASS_NAME = 'class_name'
export const SEMESTER_ID = 'semseter_id'
export const SEMESTER_NAME = 'semseter_name'
export const SECTION_ID = 'section_id'
export const SECTION_NAME = 'section_name'
