import React, { useState } from "react";
import ModalAdmissionEnquiry from "../../../modals/FrontOffice/ModalAdmissionEnquiry";
import "./AdmissionEnquiry.scss";
import ModalAdmissionEnquirryFollowUp from "../../../modals/FrontOffice/ModalAdmissionEnquirryFollowUp";
import {
  FRONT_OFFICE_ADMISSION_ENQUIRRY,
  FRONT_OFFICE_ADMISSION_ENQUIRRY2,
  FRONT_OFFICE_ADMISSION_ENQUIRRY_WITHOUT_OTP,
  FRONT_OFFICE_SETUP_REFERENCE,
  FRONT_OFFICE_SETUP_SOURCE,
} from "../../../utils/FrontOffice.apiConst";
import Nodata from "../../../Components/NoData/Nodata";
import axios from "axios";
import { toast } from "react-toastify";
import { useRef, useEffect } from "react";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
import Select from "react-select";
import { ACADEMICS_ADD_CLASS } from "../../../utils/Academics.apiConst";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";

function AdmissionEnquiry({ setLoading, collegeId }) {
  const [data, setData] = useState();

  const [refOpt, setRefOpt] = useState([]);

  const [sourceOpt, setSourceOpt] = useState([]);

  console.log("sourceOpt -", sourceOpt);

  // Get the current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const [fromDate, setFromdate] = useState(getCurrentDate());
  const [toDate, setTodate] = useState(getCurrentDate());

  const [user, setUser] = useState({
    college_id: "ALL",
    department_id: "ALL",
    class_id: "ALL",
    source: "ALL",
    status: "ALL",
  });

  const clearData = () => {
    setUser((prev) => ({
      ...prev,
      college_id: "ALL",
      department_id: "ALL",
      class_id: "ALL",
      source: "ALL",
      status: "ALL",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  const [departmentData, setDepartmentData] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const [classData, setClassData] = useState([]);
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    setDepartmentOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == user?.college_id
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), , user?.college_id]);

  useEffect(() => {
    setDepartmentData(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const handleCollegeChange = (selectedOption) => {
    setUser((prevUser) => ({
      ...prevUser,
      college_id: selectedOption?.value || "ALL",
      department_id: "ALL",
      class_id: "ALL", // Reset class_id when department changes
    }));
  };

  const handleDepartmentChange = (selectedOption) => {
    setUser((prevUser) => ({
      ...prevUser,
      department_id: selectedOption?.value || "ALL",
      class_id: "ALL", // Reset class_id when department changes
    }));
  };

  const handleChangeSelect = (name, selectedOption) => {
    setUser((prevUser) => ({
      ...prevUser,
      [name]: selectedOption?.value || "ALL",
    }));
  };

  const getData = async () => {
    if (new Date(fromDate) > new Date(toDate)) {
      toast.error("From Date cannot be more than To Date");
      return;
    }

    setLoading(1);
    const config = {
      method: "get",
      url:
        FRONT_OFFICE_ADMISSION_ENQUIRRY2 +
        `?college_id=${user?.college_id}&department_id=${user?.department_id}&class_id=${user?.class_id}&fromDate=${fromDate}&toDate=${toDate}&source=${user?.source}&status=${user?.status}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    console.log("Request URL -", config.url);

    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log(res.data.data);
        setData(res.data.data.filter((s) => s.status !== "INACTIVE"));
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };

  const getAllData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data2] = await Promise.all([
      axios({
        ...config,
        url: `${FRONT_OFFICE_SETUP_SOURCE}?college_id=${collegeId}`,
      })
        .then((res) => {
          console.log(res);
          setSourceOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),
      axios({
        ...config,
        url: `${FRONT_OFFICE_SETUP_REFERENCE}?college_id=${collegeId}`,
      })
        .then((res) => {
          setLoading(0);
          setRefOpt(res.data.data);
          console.log(res);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),

      axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}`,
      })
        .then((res) => {
          setLoading(0);
          setClasses(res.data.data);
          console.log(res);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),
    ]);
  };

  useEffect(() => {
    getData();
    getAllData();
  }, []);

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: " Admission Enquiry List ",
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  // const handlePrint = () => {
  //   PrintRecipt();
  // };

  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      tableRef.current.style.display = "none";
    }, 1); // Adjust the delay as needed
  };

  const handleDelete = async (i) => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${FRONT_OFFICE_ADMISSION_ENQUIRRY}/${i?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };
    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data Deleted");
        getData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Some Error Occured");
      });
  };

  console.log("data -", data);

  return (
    <div className="AdmissionEnquiry">
      <ModalAdmissionEnquiry
        setLoading={setLoading}
        collegeId={collegeId}
        sourceOpt={sourceOpt}
        refOpt={refOpt}
      />

      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <h5>ADMISSION ENQUIRY</h5>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Select Faculty
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <Select
                            className="form-group"
                            name="college"
                            value={
                              user.college_id === "ALL"
                                ? { value: "ALL", label: "ALL" }
                                : collegeOpt?.find(
                                    (option) =>
                                      option.value === user?.college_id
                                  )
                            }
                            onChange={handleCollegeChange}
                            options={[
                              { value: "ALL", label: "ALL" },
                              ...collegeOpt?.map((i) => {
                                return {
                                  value: i.id,
                                  label: `${i.name}`,
                                };
                              }),
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Select Department
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <Select
                            className="form-group"
                            name="department_id"
                            value={
                              user.department_id === "ALL"
                                ? { value: "ALL", label: "ALL" }
                                : departmentOpt.find(
                                    (option) =>
                                      option.value === user.department_id
                                  )
                            }
                            onChange={handleDepartmentChange}
                            options={[
                              { value: "ALL", label: "ALL" },
                              ...departmentOpt.map((i) => {
                                const programName =
                                  programOpt.find(
                                    (item) => i.program_id === item.id
                                  )?.name || "Program Not Found";
                                // console.log(`Department ID: ${i.id}, Program Name: ${programName}`);
                                return {
                                  value: i.id,
                                  label: `${i.name}, ${programName}`,
                                };
                              }),
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Select Class
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <Select
                            className="form-group"
                            name="class_id"
                            value={
                              user.class_id === "ALL"
                                ? { value: "ALL", label: "ALL" }
                                : classes.find(
                                    (option) => option.value === user.class_id
                                  )
                            }
                            onChange={(e) => handleChangeSelect("class_id", e)}
                            options={[
                              { value: "ALL", label: "ALL" },
                              ...classes
                                ?.filter(
                                  (s) => s?.department_id == user.department_id
                                )
                                ?.map((i) => {
                                  return {
                                    value: i.id,
                                    label: `${i.name}`,
                                  };
                                }),
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Enquiry From Date
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="fromDate"
                            value={fromDate}
                            onChange={(e) => {
                              setFromdate(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Enquiry To Date
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="toDate"
                            value={toDate}
                            onChange={(e) => {
                              setTodate(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Source<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            name="source"
                            className="form-control"
                            value={user?.source}
                            onChange={handleChange}
                          >
                            <option value="ALL">ALL</option>
                            {sourceOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Status<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            name="status"
                            className="form-control"
                            value={user?.status}
                            onChange={handleChange}
                          >
                            <option value="ALL">ALL</option>
                            <option value="ACTIVE">Active</option>
                            <option value="PASSIVE">Passive</option>
                            <option value="WIN">Win</option>
                            <option value="LOSS">Loss</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row float-right mt-2">
                      <button
                        className="btn btn-primary btn-rounded"
                        type="submit"
                        name="submit"
                        onClick={getData}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            <div class="row">
              <h4 className="card-title ml-3">Admission Enquiry</h4>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div className="row d-flex justify-content-end">
                      <button
                        className="btn btn-success btn-sm btn-rounded float-right"
                        type="submit"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                        name="add  "
                      >
                        <i className="fa fa-plus" aria-hidden="true" /> Add New
                      </button>
                      <button
                        className="btn btn-primary ml-2"
                        onClick={onDownload}
                      >
                        Export Excel
                      </button>
                      <button
                        className="btn btn-primary ml-2"
                        onClick={handlePrint}
                      >
                        Export PDF
                      </button>
                    </div>
                    <hr />
                    <div className="table-responsive mt-2">
                      <table
                        className="table table-bordered"
                        // ref={tableRef}
                        style={{
                          width: "100%",
                        }}
                      >
                        <thead className="text-center">
                          <tr>
                            <th>Sl.No.</th>
                            <th>Faculty</th>
                            <th>Department</th>
                            <th>Class</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Source</th>
                            <th>Enquiry Date</th>

                            <th>
                              Next <br />
                              Follow-up Date
                            </th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {data && data ? (
                            data?.map((i, key) => {
                              return (
                                <>
                                  <tr>
                                    <th>{key + 1}</th>
                                    <td>{i?.college_name}</td>
                                    <td>{i?.department_name}</td>
                                    <td>{i?.class_name}</td>
                                    <td>{i.name}</td>
                                    <td>{i.phone}</td>
                                    <td>{i.source}</td>

                                    <td>
                                      {i.date
                                        ? i.date.substring(0, 10)
                                        : i.date}
                                    </td>
                                    <td>
                                      {i.follow_up_date
                                        ? i.follow_up_date.substring(0, 10)
                                        : i.follow_up_date}
                                    </td>
                                    <td
                                      className={` mt-1 badge badge-soft-${
                                        i.status === "ACTIVE"
                                          ? "success"
                                          : i.status === "WIN"
                                          ? "success"
                                          : "danger"
                                      }`}
                                    >
                                      {i.status}
                                    </td>
                                    <td>
                                      <abb title="Follow Up">
                                        <button
                                          className="btn badge badge-light p-2 mr-3 "
                                          data-toggle="modal"
                                          data-target={`#followup-${key}`}
                                        >
                                          {" "}
                                          <i
                                            className="fa fa-phone text-success"
                                            aria-hidden="true"
                                          />{" "}
                                        </button>
                                      </abb>{" "}
                                      <abb title="Delete">
                                        {/* <acronym title="Inactive"> */}
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => handleDelete(i)}
                                        >
                                          <i
                                            className="fa fa-trash text-danger"
                                            aria-hidden="true"
                                          />
                                        </a>
                                        {/* </acronym> */}
                                      </abb>{" "}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan={10}>
                                <Nodata />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* end col */}
                </div>
              </div>
            </div>
          </div>
          <table
            className="table table-bordered"
            ref={tableRef}
            style={{
              width: "100%",
              display: "none",
            }}
          >
            <thead className="text-center">
              <tr>
                <th>Sl.No.</th>
                <th>Faculty</th>
                <th>Department</th>
                <th>Class</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Source</th>
                <th>Enquiry Date</th>

                <th>
                  Next <br />
                  Follow-up Date
                </th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {data && data ? (
                data?.map((i, key) => {
                  return (
                    <>
                      <tr>
                        <th>{key + 1}</th>
                        <td>{i?.college_name}</td>
                        <td>{i?.department_name}</td>
                        <td>{i?.class_name}</td>
                        <td>{i.name}</td>
                        <td>{i.phone}</td>
                        <td>{i.source}</td>

                        <td>{i.date ? i.date.substring(0, 10) : i.date}</td>
                        <td>
                          {i.follow_up_date
                            ? i.follow_up_date.substring(0, 10)
                            : i.follow_up_date}
                        </td>
                        <td>{i.status}</td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  {" "}
                  <td colSpan={10}>
                    <Nodata />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdmissionEnquiry;
