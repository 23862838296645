import React, { useState, useEffect } from 'react'

function Homeopathy({ setStep, updateForm, data, form_data , set_form_data }) {

  const [info, setInfo] = useState({})


  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDay();
  const futureDate = date.getDate() + 3;
  // date.setDate(futureDate);
  const [defaultDate, setDefaultDate] = useState(
    date.toISOString().split("T")[0]
  );
  console.log(defaultDate);

  const handleChange = (e) => {
    const {name, value} = e.target
    setInfo(prev=>({
      ...prev,
      [name]:value
    }))
  }

  useEffect(() => {

    if (data?.form_data) {
      setInfo({
        ...form_data
      })
    }

  }, [data.form_data])

  useEffect(()=>{
    set_form_data(info)
  },[info])

    
  return (
    <div>
    <div className="register-area mt-2 pb-70">
      <div className="">
        <div className="register" style={{ maxWidth: "100%" }}>
          <form action="">

            {/* <div className="ror">
                <div className="col-md-4">
                    <select 
                    name="application_for"
                    value={info?.application_for}
                    onChange={handleChange}
                    className="form-control"
                    >
                        <option value="">Select Course</option>
                        <option value="BAMS">BAMS</option>
                        <option value="BHMS">BHMS</option>
                    </select>
                </div>
            </div> */}
            <div className="row mt-3">
                <div className="col-md-12">
            <h4>(B)Academic Information:</h4>

                </div>
                <div className="col-md-4">
                   <div className="form-group">
                   <label htmlFor="">Board</label>
                    <select 
                    name="board"
                    value={info?.board}
                    onChange={handleChange} 
                    className="form-control">
                        <option value="">Select Board</option>

                        <option value="GHSEB">GHSEB</option>
                        <option value="CBSE">CBSE</option>
                        <option value="ICSE">ICSE</option>

                    </select>
                   </div>
                </div>
                <div className="col-md-4">
               
                  <div className="form-group">
                    <label>Month & Year of Passing</label>

                    <input
                      type="month"
                      className="form-control"
                      name="month_year"
                      value={info?.month_year}
                      onChange={handleChange} 
                    />
                
                </div>
                </div>
                <div className="col-md-4">
               
               <div className="form-group">
                 <label>Examination Seat No. </label>

                 <input
                  type="text"
                  className="form-control"
                  name="examination_seat_no"
                  value={info?.examination_seat_no}
                  onChange={handleChange} 
                 />
             
             </div>
             </div>


             <div className="col-md-4">
               
               <div className="form-group">
                 <label>GUJCET No. </label>

                 <input
                   type="text"
                   className="form-control"
                   placeholder="GUJCET Seat No."
                   name="gujcet_no"
                   value={info?.gujcet_no}
                   onChange={handleChange}
                 />
             
             </div>
             </div>
             <div className="col-md-4">
               
               <div className="form-group">
                 <label>Marks </label>

                 <input
                   type="number"
                   className="form-control"
                   placeholder="Examination Seat No."
                   name="gujcet_marks"
                   value={info?.gujcet_marks}
                   onChange={handleChange}
                 />
             
             </div>
             </div>
             <div className="col-md-4">
               
               <div className="form-group">
                 <label>NEET No. </label>

                 <input
                   type="text"
                   className="form-control"
                   placeholder="NEET  No."
                   name="neet_no"
                   value={info?.neet_no}
                   onChange={handleChange}
                 />
             
             </div>
             </div>
             <div className="col-md-4">
               
               <div className="form-group">
                 <label>Obtained Marks in NEET </label>

                 <input
                   type="text"
                   className="form-control"
                   placeholder="Obtained Marks in NEET"
                   name="neet_marks"
                   value={info?.neet_marks}
                   onChange={handleChange}
                 />
             
             </div>
             </div>

            </div>

            <div className="row mt-4">
                <div className="col-md-12">
            <h4>(C)Marks obtained at H.S.C.E. or Equivalent Examination</h4>
            <table className="table table-bordered text-center">
                <thead>
                    <tr>
                        <th rowSpan={2}>Sl. No.</th>
                        <th  rowSpan={2}>SUBJECTS</th>
                        <th colSpan={2} >THEORY MARKS</th>
                        <th colSpan={2}>PRACTICAL MARKS</th>

                    </tr>
                    <tr>
                   
                        <th>MAXIMUM</th>
                        <th>OBTAINED</th>
                        <th>MAXIMUM</th>
                        <th>OBTAINED</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>01</th>
                        <th>CHEMISTRY</th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='t_chem_max' 
                            value={info?.t_chem_max}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='t_chem_obt' 
                            value={info?.t_chem_obt}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='p_chem_max' 
                            value={info?.p_chem_max}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='p_chem_obt' 
                            value={info?.p_chem_obt}
                            onChange={handleChange}
                          />
                        </th>

                    </tr>

                    <tr>
                        <th>02</th>
                        <th>PHYSICS</th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='t_phy_max' 
                            value={info?.t_phy_max}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='t_phy_obt' 
                            value={info?.t_phy_obt}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='p_phy_max' 
                            value={info?.p_phy_max}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='p_phy_obt' 
                            value={info?.p_phy_obt}
                            onChange={handleChange}
                          />
                        </th>

                    </tr>
                    <tr>
                        <th>03</th>
                        <th>BIOLOGY</th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='t_bio_max' 
                            value={info?.t_bio_max}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='t_bio_obt' 
                            value={info?.t_bio_obt}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='p_bio_max' 
                            value={info?.p_bio_max}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='p_bio_obt' 
                            value={info?.p_bio_obt}
                            onChange={handleChange}
                          />
                        </th>

                    </tr>
                    <tr>
                        <th>
                            04
                        </th>
                        <th>TOTAL</th>
                        <th></th>
                        <th></th>

                        <th></th>
                        <th></th>

                        
                    </tr>
                    <tr>
                        <th>05</th>
                        <th>SANSKRIT / COMPUTER</th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='t_san_or_cs_max' 
                            value={info?.t_san_or_cs_max}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='t_san_or_cs_obt' 
                            value={info?.t_san_or_cs_obt}
                            onChange={handleChange}
                          />
                        </th>
                        
                    </tr>
                    <tr>
                        <th>06</th>
                        <th>ENGLISH</th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='t_english_max' 
                            value={info?.t_english_max}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='t_english_obt' 
                            value={info?.t_english_obt}
                            onChange={handleChange}
                          />
                        </th>
                        
                    </tr>
                    <tr>
                        <th>05</th>
                        <th>GRAND TOTAL</th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='totalmax' 
                          />
                          </th>
                        <th>
                          <input 
                            type="number" 
                            className="form-control" 
                            name='totalobt' 
                          />
                        </th>
                        
                    </tr>
                </tbody>

            </table>


                </div>
            </div>

       
        <div className="row ">
        <h4>(D) Upload Documents</h4>
        <div className="col-lg-3 col-md-3 mt-5">
              <div className="form-group">
                <h5 htmlFor="">
                NEET Marksheet <small className="text-danger">*</small>
                </h5>
                <input
                  type="file"
                  name="neet_card"
                  // value={info?.other_marks_card}
                  onChange={(e) => setInfo({ ...info, neet_marks_card: e.target.value })}
                  id="neet_card"
                  className="mb-3 form-control"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 mt-5">
              <div className="form-group">
                <h5 htmlFor="">
                School Leaving Certificate <small className="text-danger">*</small>
                </h5>
                <input
                  type="file"
                  name="lc"
                  // value={info?.other_marks_card}
                  onChange={(e) => setInfo({ ...info, lc: e.target.value })}
                  id="lc"
                  className="mb-3 form-control"
                />
              </div>
            </div>
          
            <div className="col-lg-3 col-md-3 mt-5">
              <div className="form-group">
                <h5 htmlFor="">
                Caste Certificate (if Applicable)
                </h5>
                <input
                  type="file"
                  name="caste"
                  // value={info?.other_marks_card}
                  onChange={(e) => setInfo({ ...info, caste: e.target.value })}
                  id="caste"
                  className="mb-3 form-control"
                />
              </div>
            </div>
        </div>

        <div className="row mt-5">
            <div className="col-md-12">
              <h5 className="text-center">
                <u> UNDERTAKING </u>
              </h5>
              <div className="form-group ">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input check mr-5"
                    name="agree"
                    value={info?.undertaking}
                    onChange={(e) => setInfo({ ...info, undertaking: e.target.value })}
                    type="checkbox"
                    defaultChecked=""
                    required=""
                    id="inlineCheckbox1"
                    defaultValue={1}
                  />
                  <p htmlFor="inlineCheckbox1 ml-5" style={{ textAlign: "justify" }}>
                  I / We hereby declare that:
                  </p>
                  <ol>
                    <li>All the information provided by me is true to the best of my knowledge and belief.</li>
                        <li>
                        Any change in any information provided earlier will be informed to the institute with
documentary proof.
                        </li>
                    </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <h5>Place:</h5>
              <input 
              type="text" 
              className="form-control" 
              name='place'
              value={info?.place}
              onChange={handleChange}
              />
            </div>
            <div className="col-md-4">
              <h5>Date:</h5>
              <input
                type="text"
                className="form-control"
                name='date'
                value={info?.date}
                onChange={handleChange}
              />
            </div>
          </div>






          </form>
        </div>
      </div>
    </div>

  </div>
  )
}

export default Homeopathy