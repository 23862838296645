import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import {
  CLINICAL_NOTES
} from "./Academic.Api";

function ClinicalNotesModal({ getClinicalNotes, date, student_session_id, notes, edit, setEdit, patient, setLoading }) {

  const [user, setUser] = useState({
    diagnosis_note: "",
    clinical_findings: "",
    differential_diagnosis: "",
    diagnosis: "",
    treatment: "",
    note: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleSubmit = async () => {
    
    const config = {
      method: "post",
      url: CLINICAL_NOTES,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {...user, student_session_id, ...patient, date}
    };
    
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Note Added Successfully");
        getClinicalNotes();
        console.log("success");
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error('Something went wrong');
      });
  }

  const handleEdit = async () => {
    
    const config = {
      method: "put",
      url: `${CLINICAL_NOTES}/${notes?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {...user}
    };
    
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Note Updated Successfully");
        getClinicalNotes();
        console.log("success");
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error('Something went wrong');
      });
  }

  useEffect(() => {
    if(edit == "edit") {
      setUser({...notes});
    }
    else {
      setUser({
        clinical_findings: "",
        differential_diagnosis: "",
        diagnosis: "",
        treatment: "",
        note: "",
      })
    }
  },[edit]);

  
  return (
    <div
      className="modal fade text-left"
      id="ClinicalNotesModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ zIndex: 1060 }} // Adjust the z-index for ClinicalNotesModal
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-75"
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Clinical Note
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => {
                  
                }}
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
          <h5>Patient's Details : </h5>
          <h6>Name : {patient?.name}</h6>
          <h6>Gender : {patient?.gender}</h6>
          <h6>Age : {patient?.age}</h6>
          <h6>Department : {patient?.department}</h6>
          <h6>Date : {formatDate(date)}</h6>
          <hr />
          <div className="row mt-3">
              <div className="col-12">
                <div className="form-group">
                  <label>Clinical Findings</label>
                  <textarea
                    className="form-control mt-2"
                    name="clinical_findings"
                    placeholder="Enter Clinical Findings..."
                    rows={3}
                    value={user.clinical_findings}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Diagnosis Note</label>
                  <textarea
                    className="form-control mt-2"
                    name="diagnosis"
                    placeholder="Enter Diagnosis Note..."
                    rows={3}
                    value={user.diagnosis}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Differential Diagnosis</label>
                  <textarea
                    className="form-control mt-2"
                    name="differential_diagnosis"
                    placeholder="Enter Differential Diagnosis..."
                    rows={3}
                    value={user.differential_diagnosis}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Treatment</label>
                  <textarea
                    className="form-control mt-2"
                    name="treatment"
                    placeholder="Enter Treatment..."
                    rows={3}
                    value={user.treatment}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Additional Note</label>
                  <textarea
                    className="form-control mt-2"
                    name="note"
                    placeholder="Enter Additional Note..."
                    rows={3}
                    value={user.note}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <button className="btn btn-primary" data-dismiss="modal" aria-label="Close">
                  Cancel
                </button>
                {edit == "edit"
                ?
                <button 
                  className="btn btn-success float-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleEdit();
                  }
                  }
                >
                  Save Changes
                </button>
                : 
                <button 
                  className="btn btn-success float-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleSubmit();
                  }
                  }
                >
                  Add
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClinicalNotesModal;
