import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UID } from "../../Data/navbar/StudentPortal/storageConstant";
import { REPORT_STUDENT_ATTENDANCE_ALL } from "../../utils/Reports.apiConst";
import { ACADEMICS_ADD_SEMESTER } from "../../utils/Academics.apiConst";
import { Http } from "../../Services/Services";
import { ROUTES } from "../../Router/routerConfig";
import { ToastContainer } from "react-toastify";
import "./StudentPortal.scss";

const Attendance = ({ setLoading }) => {
  const navigate = useNavigate();
  const student_id = sessionStorage.getItem(UID);

  const [attendanceData, setAttendanceData] = useState([]);
  const [sessionsSet, setSessionsSet] = useState(new Set());
  const [sessions, setSessions] = useState([]);
  const [sessionType, setSessionType] = useState([]);
  const [semesterOpt, setSemesterOpt] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [validSemesters, setValidSemesters] = useState([]);
  const [semester, setSemester] = useState("");
  const [displayData, setDisplayData] = useState([]);

  const getAttendance = async () => {
    setLoading(1);
    await Http.get(`${REPORT_STUDENT_ATTENDANCE_ALL}?student_id=${student_id}`)
      .then((res) => {
        setLoading(0);
        console.log("attendance data - ", res.data.data);
        setAttendanceData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    attendanceData?.forEach((value, index) => {
      setSessionsSet((prev) => new Set([...prev, value.student_session_id]));
    });
    if (attendanceData && attendanceData.length !== 0) {
      getSemesters(attendanceData[0]?.college_id);
    }
  }, [attendanceData]);

  const getSemesters = async (college_id) => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios({
      ...config,
      url: `${ACADEMICS_ADD_SEMESTER}?college_id=${college_id}`,
    })
      .then((res) => {
        setLoading(0);
        setSemesterOpt(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    if (sessions.length !== 0) {
      setSessionType(sessions[0]);
    }
  }, [sessions]);

  // useEffect(() => {
  //   if (sessionType && semesterOpt) {
  //     let clas = attendanceData?.find(
  //       (s) => s.student_session_id == sessionType
  //     );
  //     let arr = semesterOpt?.filter((s) => s.class_id == clas?.class_id);
  //     let semNames = arr?.map((value, idx) => {
  //       return value.name;
  //     });
  //     setSemesters(semNames);
  //   }

  // }, [sessionType, semesterOpt]);

  useEffect(() => {
    if (sessionType && semesterOpt) {
      let clas = attendanceData?.find(
        (s) => s.student_session_id == sessionType
      );
      let arr = semesterOpt?.filter((s) => s.class_id == clas?.class_id);
      let semNames = arr?.map((value, idx) => {
        return value.name;
      });
      setSemesters(semNames);

      const validSemNames = semNames.filter((sem) =>
        attendanceData.some(
          (att) =>
            att.semester === sem && att.student_session_id === sessionType
        )
      );
      setValidSemesters(validSemNames);
    }
  }, [sessionType, semesterOpt]);

  useEffect(() => {
    if (semesters) {
      setSemester(semesters[0]);
    }
  }, [semesters]);

  useEffect(() => {
    const arr = Array.from(sessionsSet);
    arr.sort((a, b) => b - a);
    setSessions(arr);
  }, [sessionsSet]);

  useEffect(() => {
    getAttendance();
  }, []);

  useEffect(() => {
    let temp = attendanceData.filter(
      (s) => s.semester == semester && s.student_session_id == sessionType
    );
    setDisplayData(temp);
  }, [semester, sessionType]);

  console.log("displayData -", displayData);

  return (
    <>
      <ToastContainer />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h4 className="card-header text-center bg-white text-primary my-attendance">
                      My Attendance
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="pt-3">
                <ul className="nav nav-tabs ml-3">
                  {sessions?.map((value, index) => (
                    <li className="nav-item cursor-pointer" key={index}>
                      <a
                        className={`nav-link ${
                          sessionType === value && "active"
                        } bg-primary`}
                        style={{
                          // backgroundColor: "blueviolet",
                          color: "white",
                        }}
                        onClick={() => setSessionType(value)}
                      >
                        {value}-{value + 1}
                      </a>
                    </li>
                  ))}
                </ul>
                <br />
                <ul className="nav nav-tabs ml-3">
                  {/* {semesters?.map((value, index) => {
                    return (
                      <li className="nav-item cursor-pointer">
                        <a
                          className={`nav-link ${
                            semester === value && "active"
                          }`}
                          onClick={() => setSemester(value)}
                        >
                          {value}
                        </a>
                      </li>
                    );
                  })} */}
                  {validSemesters?.map((value, index) => (
                    <li className="nav-item cursor-pointer" key={index}>
                      <a
                        className={`nav-link ${
                          semester === value && "active"
                        } bg-warning`}
                        onClick={() => setSemester(value)}
                        style={{
                          // backgroundColor: "orange",
                          color: "black",
                        }}
                      >
                        {value}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <br />
            <div className="bg-white text-dark p-3 shadow-sm rounded">
              <table className="table table-bordered table-hover bg-white text-dark">
                <thead className="thead-dark attendancee">
                  <tr>
                    <th>Sl No.</th>
                    <th>Course</th>
                    <th>Present</th>
                    <th>Absent</th>
                    <th>Total</th>
                    <th>Present%</th>
                  </tr>
                </thead>
                <tbody className="attendancee">
                  {displayData && displayData.length > 0 ? (
                    displayData?.map((value, key) => {
                      let percentage = 0;
                      if (
                        parseInt(value.present) + parseInt(value.absent) !==
                        0
                      ) {
                        percentage = (
                          (parseInt(value.present) * 100) /
                          (parseInt(value.present) + parseInt(value.absent))
                        ).toFixed(2);
                      }
                      let color = "#CCFFCD";
                      if (percentage < 60) color = "#FFCCCB";
                      else if (percentage < 85 && percentage >= 60)
                        color = "#fffdd0";
                      return (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td
                            onClick={() =>
                              navigate(
                                ROUTES.StudentPortal.StudentAttendance +
                                  "/" +
                                  value.course_id
                              )
                            }
                            className="text-primary cursor-pointer"
                          >
                            {value.course}
                          </td>
                          <td className="text-success">
                            <b>{value.present}</b>
                          </td>
                          <td className="text-danger">
                            <b>{value.absent}</b>
                          </td>
                          <td className="text-primary">
                            <b>
                              {parseInt(value.present) + parseInt(value.absent)}
                            </b>
                          </td>
                          <td style={{ backgroundColor: color }}>
                            <b>{percentage}%</b>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div align="center" className="text-danger">
                          No data available in table <br /> <br />
                          <img
                            src="/assets/images/addnewitem.svg"
                            width={150}
                            alt="No data"
                          />
                          <br />
                          <br />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendance;
