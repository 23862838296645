import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getFileUrl } from "../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_IMAGE } from "../../../utils/AssetsReferenceTypes";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
  GET_DEPT,
} from "../AdmissionApiConstants";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import InputDoc from "../InputDoc/InputDoc";

const BasicInformations = ({
  data,
  setLoading,
  basic_data,
  set_basic_data,
  auth,
}) => {
  const date = new Date();

  const getCollegeId = () =>
    sessionStorage.getItem("college")
      ? sessionStorage.getItem("college")
      : null;
  const getProgramId = () =>
    sessionStorage.getItem("program")
      ? sessionStorage.getItem("program")
      : null;

  const [collegeId, setCollegeId] = useState(getCollegeId());

  const [programId, setProgramId] = useState(getProgramId());

  //Dropdown Datas
  const [departmentDropdown, setDepartmentDropdown] = useState([]);

  const [classDropdown, setClassDropdown] = useState([]);

  console.log("Here");

  const [semesterDropdown, setSemesterDropdown] = useState([]);

  // const [basicData, setBasicData] = useState(data?.basicData)

  // date.setDate(futureDate);
  const [defaultDate, setDefaultDate] = useState(
    date.toISOString().split("T")[0]
  );

  const [info, setInfo] = useState({});

  console.log("info -", info);

  //Function upload attachment to the s3
  const addAttachmentImage = async (e, str) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_IMAGE,
        `${info.user_id}_${str}`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      setInfo({ ...info, [e.target.name]: d ? d : "" });
    } catch (error) {
      console.log(error);
    }
  };

  //Function upload attachment to the s3
  const addAttachment = async (e, str) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_IMAGE,
        `${info.user_id}_${str}`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      setInfo({
        ...info,
        [e.target.name]: {
          link: d ? d : "",
          status: "ACTIVE",
          reason: "",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (basic_data) {
      setInfo({
        ...basic_data,
      });
    }
  }, [data]);

  const getDepartmentData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${GET_DEPT}?college_id=${collegeId}&program_id=${programId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log(res.data.data);
        setDepartmentDropdown(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };

  const getAllDropdownData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };

    const [data1, data2] = await Promise.all([
      await axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}&program_id=${programId}`,
      })
        .then((res) => {
          console.log(res.data.data);
          setClassDropdown(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          toast.error("something went wrong");
        }),

      await axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}&program_id=${programId}`,
      })
        .then((res) => {
          setLoading(0);
          setSemesterDropdown(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
        }),
    ]);
  };

  useEffect(() => {
    set_basic_data(info);
  }, [info]);

  useEffect(() => {
    getDepartmentData();
  }, []);

  useEffect(() => {
    if (info?.department_id) {
      getAllDropdownData();
    }
  }, [info?.department_id]);

  useEffect(() => {
    setCollegeId(getCollegeId());
    setProgramId(getProgramId());
  }, [sessionStorage.getItem("college"), sessionStorage.getItem("program")]);

  const dept_id = sessionStorage.getItem("department");
  console.log("dept_id -", dept_id);

  return (
    <div>
      <div className="register-area mt-2 pb-70">
        <div className="container px-0">
          <div className="register" style={{ maxWidth: "100%" }}>
            {/* <h3>Master In  Nursing Application Form</h3> */}
            <form>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Name of Student</label>
                    <input
                      required
                      type="text"
                      name="fullname"
                      className="form-control"
                      value={info?.name}
                      onChange={(e) =>
                        setInfo({ ...info, name: e.target.value })
                      }
                      placeholder="Student Full Name*"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 row">
                  <div className="form-group">
                    <label className="ml-3">Mobile Number</label>

                    <div className="row">
                      <div className="col-3">
                        <input
                          type="text"
                          readOnly={true}
                          value="+91"
                          name="mob_no"
                          className="form-control"
                          placeholder="Mobile Number"
                        />
                      </div>
                      <div className="col-9">
                        <input
                          type="number"
                          value={info?.phone}
                          onChange={(e) =>
                            setInfo({ ...info, phone: e.target.value })
                          }
                          name="mob_no"
                          className="form-control"
                          placeholder="Mobile Number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label className="ml-2">Father Name</label>

                    <input
                      requiredff
                      type="text"
                      value={info?.father_name}
                      onChange={(e) =>
                        setInfo({ ...info, father_name: e.target.value })
                      }
                      name="parent_mobile"
                      className="form-control ml-2"
                      placeholder="Father Name *"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Father Mobile Number</label>

                    <input
                      required
                      type="text"
                      value={info?.father_phone}
                      onChange={(e) =>
                        setInfo({ ...info, father_phone: e.target.value })
                      }
                      name="parent_mobile"
                      className="form-control"
                      placeholder="Father Mobile Number *"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Mother Name</label>

                    <input
                      required
                      type="text"
                      value={info?.mother_name}
                      onChange={(e) =>
                        setInfo({ ...info, mother_name: e.target.value })
                      }
                      name="parent_mobile"
                      className="form-control"
                      placeholder="Mother Name *"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Mother Mobile Number</label>

                    <input
                      required
                      type="text"
                      value={info?.mother_phone}
                      onChange={(e) =>
                        setInfo({ ...info, mother_phone: e.target.value })
                      }
                      name="parent_mobile"
                      className="form-control"
                      placeholder="Mother Mobile Number *"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Date of Birth</label>

                    <div className="input-group">
                      <input
                        type="date"
                        placeholder="Date of Birth*"
                        name="dob"
                        value={info?.dob?.split("T")[0]}
                        onChange={(e) =>
                          setInfo({ ...info, dob: e.target.value })
                        }
                        data-date-format="yy-mm-dd"
                        className="form-control"
                        id="date"
                      />
                      {/* <span className="input-group-append d-print-none h-auto">
                        <span className="input-group-text bg-light h-100">
                          <i className="ri-calendar-2-line" />
                        </span>
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Nationality</label>
                    <input
                      required
                      type="text"
                      value={info?.nationality}
                      onChange={(e) =>
                        setInfo({ ...info, nationality: e.target.value })
                      }
                      name="nationality"
                      className="form-control"
                      placeholder="Nationality"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Email of Student</label>

                    <input
                      required
                      type="email"
                      value={info?.email}
                      readOnly={true}
                      onChange={(e) =>
                        setInfo({ ...info, email: e.target.value })
                      }
                      name="email"
                      className="form-control"
                      placeholder="Email ID*"
                    />
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6 program-level"
                  style={{ marginBottom: 0 }}
                >
                  <div className="form-group">
                    <p className="mb-1">
                      <b> Marital Status</b>
                    </p>
                    {/* <div class="form-check"> */}
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        value={"single"}
                        onChange={(e) =>
                          setInfo({ ...info, marital_status: e.target.value })
                        }
                        name="marital_status"
                        required
                        type="radio"
                        id="inlineCheckbox1"
                        checked={
                          info?.marital_status == "single" ? true : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                      >
                        Single
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        value={"married"}
                        onChange={(e) =>
                          setInfo({ ...info, marital_status: e.target.value })
                        }
                        name="marital_status"
                        required
                        type="radio"
                        id="inlineCheckbox2"
                        checked={
                          info?.marital_status == "married" ? true : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox2"
                      >
                        Married
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Gender</label>
                    <select
                      className="form-control"
                      value={info?.gender}
                      onChange={(e) =>
                        setInfo({ ...info, gender: e.target.value })
                      }
                      name="gender"
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Category</label>
                    <select
                      value={info?.category}
                      onChange={(e) =>
                        setInfo({ ...info, category: e.target.value })
                      }
                      name="category"
                      className="form-control"
                    >
                      <option value="">Select Category</option>
                      <option value="General/Open">General/Open</option>
                      <option value="EWS">EWS</option>
                      <option value="SEBC">SEBC</option>
                      <option value="ST">ST</option>
                      <option value="SC">SC</option>
                      {/* <option value="OPEN_EWS">OPEN_EWS</option> */}
                      <option value="OTHERS">OTHERS</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Caste</label>
                    <input
                      required
                      type="text"
                      value={info?.caste}
                      onChange={(e) =>
                        setInfo({ ...info, caste: e.target.value })
                      }
                      name="caste"
                      className="form-control"
                      placeholder="Caste *"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Sub Caste</label>
                    <input
                      required
                      type="text"
                      value={info?.sub_caste}
                      onChange={(e) =>
                        setInfo({ ...info, sub_caste: e.target.value })
                      }
                      name="sub_caste"
                      className="form-control"
                      placeholder="Sub-Caste*"
                    />
                  </div>
                </div>

                {/* <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Select Session</label>
                    <select
                      value={info?.year_of_admission}
                      onChange={(e) =>
                        setInfo({ ...info, year_of_admission: e.target.value })
                      }
                      name="year_of_admission"
                      className="form-control"
                    >
                      <option value="">Select Session</option>
                      {sessionOpt &&
                        sessionOpt.map((item, key) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                    </select>
                  </div>
                </div> */}
                {/* <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Blood Group</label>
                    <select value={info?.blood_grp}
                      onChange={(e) => setInfo({ ...info, blood_grp: e.target.value })}
                      name="blood_grp" id="" className="form-control">
                      <option value="">Select Blood Group</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                    </select>
                  </div>
                </div> */}

                <div className="col-lg-12">
                  <div className="form-group">
                    <div className="form-floating">
                      <label htmlFor="floatingTextarea2">Address</label>
                      <textarea
                        className="form-control"
                        value={info?.address}
                        onChange={(e) =>
                          setInfo({ ...info, address: e.target.value })
                        }
                        name="address"
                        placeholder="Students Parmanent Address*"
                        id="floatingTextarea2"
                        rows={3}
                        cols={1}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      required
                      type="text"
                      value={info?.state}
                      onChange={(e) =>
                        setInfo({ ...info, state: e.target.value })
                      }
                      name="state"
                      className="form-control"
                      placeholder="State"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>District</label>
                    <input
                      required
                      type="text"
                      value={info?.dist}
                      onChange={(e) =>
                        setInfo({ ...info, dist: e.target.value })
                      }
                      name="dist"
                      className="form-control"
                      placeholder="District"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Taluk</label>
                    <input
                      required
                      type="text"
                      value={info?.taluk}
                      onChange={(e) =>
                        setInfo({ ...info, taluk: e.target.value })
                      }
                      name="taluk"
                      className="form-control"
                      placeholder="Taluk"
                    />
                  </div>
                </div>
                {/* <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Pin Code</label>
                    <input
                      required type="text"
                      value={info?.pin}
                      onChange={(e) => setInfo({ ...info, pin: e.target.value })}
                      name="pin"
                      className="form-control"
                      placeholder="Pin Code"
                    />
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Birth Place</label>
                    <input
                      required
                      type="text"
                      value={info?.birth_place}
                      onChange={(e) =>
                        setInfo({ ...info, birth_place: e.target.value })
                      }
                      name="pin"
                      className="form-control"
                      placeholder="Birth Place"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Aadhar Number</label>
                    <input
                      required
                      type="text"
                      value={info?.aadhar_number}
                      onChange={(e) =>
                        setInfo({ ...info, aadhar_number: e.target.value })
                      }
                      name="aadhar_number"
                      className="form-control"
                      placeholder="Aadhar Number *."
                    />
                  </div>
                </div>
                {/* <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>PAN Card Number</label>
                    <input
                      required type="text"
                      value={info?.pan}
                      onChange={(e) => setInfo({ ...info, pan: e.target.value })}
                      name="pan"
                      className="form-control"
                      placeholder="PAN Card Number *"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Bank Name</label>
                    <input
                      required type="text"
                      value={info?.bank_name}
                      onChange={(e) => setInfo({ ...info, bank_name: e.target.value })}
                      name="bank_name"
                      className="form-control"
                      placeholder="Name of the Bank *"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Bank Account Number</label>
                    <input
                      required type="text"
                      value={info?.acc_no}
                      onChange={(e) => setInfo({ ...info, acc_no: e.target.value })}
                      name="acc_no"
                      className="form-control"
                      placeholder="Bank Account Number *"
                    />
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>10th Passing Year</label>
                    <input
                      required
                      type="month"
                      value={info?.sslc_pass_month}
                      onChange={(e) =>
                        setInfo({ ...info, sslc_pass_month: e.target.value })
                      }
                      name="sslc_pass_month"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>10th Percentage</label>
                    <input
                      required
                      type="text"
                      value={info?.sslc_perce}
                      onChange={(e) =>
                        setInfo({ ...info, sslc_perce: e.target.value })
                      }
                      name="sslc_perce"
                      className="form-control"
                      placeholder="10th Percentage *"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>10th Board of Exam</label>
                    <input
                      required
                      type="text"
                      value={info?.sslc_board}
                      onChange={(e) =>
                        setInfo({ ...info, sslc_board: e.target.value })
                      }
                      name="sslc_board"
                      className="form-control"
                      placeholder="Board of Examination *"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>10th Seat Number</label>
                    <input
                      required
                      type="text"
                      value={info?.sslc_seat_no}
                      onChange={(e) =>
                        setInfo({ ...info, sslc_seat_no: e.target.value })
                      }
                      name="sslc_seat_no"
                      className="form-control"
                      placeholder="Seat Number *"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>12th Passing Year</label>
                    <input
                      required
                      type="month"
                      value={info?.pu_pass_month}
                      onChange={(e) =>
                        setInfo({ ...info, pu_pass_month: e.target.value })
                      }
                      name="pu_pass_month"
                      className="form-control"
                      placeholder="Student Mobile Number *"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>12th Percentage</label>
                    <input
                      required
                      type="text"
                      value={info?.pu_perce}
                      onChange={(e) =>
                        setInfo({ ...info, pu_perce: e.target.value })
                      }
                      name="pu_perce"
                      className="form-control"
                      placeholder="12th Percentage *"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>12th Subject Combination</label>
                    <input
                      required
                      type="text"
                      value={info?.pu_subjects}
                      onChange={(e) =>
                        setInfo({ ...info, pu_subjects: e.target.value })
                      }
                      name="pu_subjects"
                      className="form-control"
                      placeholder="Sub Combinations  *"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>12th Board of Exam</label>
                    <input
                      required
                      type="text"
                      value={info?.pu_board}
                      onChange={(e) =>
                        setInfo({ ...info, pu_board: e.target.value })
                      }
                      name="pu_board"
                      className="form-control"
                      placeholder="Board of Examination *"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>12th Seat Number</label>
                    <input
                      required
                      type="text"
                      value={info?.pu_seat_no}
                      onChange={(e) =>
                        setInfo({ ...info, pu_seat_no: e.target.value })
                      }
                      name="pu_seat_no"
                      className="form-control"
                      placeholder="Seat Number *"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-6">
                  <InputDoc
                    lable={"Upload Student Image"}
                    value={info?.student_picture}
                    name={"student_picture"}
                    onChange={(e) => {
                      addAttachmentImage(e, "Student_Photo");
                    }}
                  />
                </div>
                <div className="col-md-6">
                  {info?.aadhar?.status == "APPROVED" ? (
                    <div className="form-group">
                      <h5>Aadhar Card</h5>
                      <div className="form-control d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column align-items-center text-success">
                          Approved
                        </div>
                        <a href={info?.aadhar?.link} target="_blank">
                          View Document
                        </a>
                      </div>
                    </div>
                  ) : (
                    <InputDoc
                      lable={"Upload Aadhar"}
                      value={info?.aadhar?.link}
                      name={"aadhar"}
                      onChange={(e) => {
                        addAttachment(e, "aadhar");
                      }}
                    />
                  )}
                </div>
                {/* <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <h5>Upload Aadhar</h5>
                    <input
                      required type="file"
                      // value={info?.std_img}
                      onChange={(e) => { addAttachment(e, 'aadhar_Card') }}
                      name="aadhar"
                      className="form-control"

                    />
                  </div>
                </div> */}
                <div className="col-md-6">
                  {info?.pu_markscard?.status == "APPROVED" ? (
                    <div className="form-group">
                      <h5>H S C Marks Card</h5>
                      <div className="form-control d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column align-items-center text-success">
                          Approved
                        </div>
                        <a href={info?.pu_markscard?.link} target="_blank">
                          View Document
                        </a>
                      </div>
                    </div>
                  ) : (
                    <InputDoc
                      lable={"Upload HSC Marks Card"}
                      value={info?.pu_markscard?.link}
                      name={"pu_markscard"}
                      onChange={(e) => {
                        addAttachment(e, "pu_markscard");
                      }}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {info?.sslc_markscard?.status == "APPROVED" ? (
                    <div className="form-group">
                      <h5>S S C Marks Card</h5>
                      <div className="form-control d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column align-items-center text-success">
                          Approved
                        </div>
                        <a href={info?.sslc_markscard?.link} target="_blank">
                          View Document
                        </a>
                      </div>
                    </div>
                  ) : (
                    <InputDoc
                      lable={"Upload SSC Markssheet"}
                      value={info?.sslc_markscard?.link}
                      name={"sslc_markscard"}
                      onChange={(e) => {
                        addAttachment(e, "sslc_markscard");
                      }}
                    />
                  )}
                </div>
                {/* <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <h5>Upload PUC Markssheet</h5>
                    <input
                      required type="file"
                      onChange={(e) => { addAttachment(e, 'pu_markscard') }}
                      name="pu_markscard"
                      className="form-control"

                    />
                  </div>
                </div> */}
                {/* <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <h5>Upload SSLC Markssheet</h5>
                    <input
                      required type="file"
                      onChange={(e) => { addAttachment(e, 'sslc_markscard') }}
                      name="sslc_markscard"
                      className="form-control"
                    />
                  </div>
                </div> */}
                {/* <h5>Quota</h5>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <lable>Select Quota</lable>
                    <select
                      name=""
                      id=""
                      className="form-control"
                      value={info?.quota}
                      onChange={(e) =>
                        setInfo({ ...info, quota: e.target.value })
                      }
                    >
                      <option value="">Select Quota</option>
                      <option value="Management">Management</option>
                      <option value="General">General</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6"></div> */}

                <div className="col-md-12 mt-2">
                  <h5>Fill course details</h5>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <lable>Department</lable>
                    <select
                      name=""
                      id=""
                      className="form-control mt-1"
                      value={info?.department_id}
                      onChange={(e) =>
                        setInfo({ ...info, department_id: e.target.value })
                      }
                      // for nursing we are selecting department while registering so we dont allow them to change their department
                      disabled={
                        info?.department_id == 35 || info?.department_id == 36
                          ? true
                          : false
                      }
                    >
                      <option value="">Select Department</option>

                      {departmentDropdown?.map((i, key) => (
                        <option value={i?.id}>{i?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <lable>Class</lable>
                    <select
                      className="form-control mt-1"
                      value={info?.admission_class_id}
                      onChange={(e) =>
                        setInfo({ ...info, admission_class_id: e.target.value })
                      }
                    >
                      <option value="">Select Class</option>

                      {classDropdown
                        // ?.filter((s) => s.department_id == info?.department_id)
                        ?.filter((s) => s.department_id == dept_id)
                        ?.map((i, key) => (
                          <option value={i?.id}>{i?.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <lable>Semester</lable>
                    <select
                      className="form-control mt-1"
                      value={info?.admission_semester_id}
                      onChange={(e) =>
                        setInfo({
                          ...info,
                          admission_semester_id: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Semester</option>

                      {semesterDropdown
                        ?.filter((s) => s?.class_id == info?.admission_class_id)
                        ?.map((i, key) => (
                          <option value={i?.id}>{i?.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInformations;
