import React, { useEffect } from "react";
import { international } from "../../Data/Countries/international.js";
import { useState } from "react";
import { getFileUrl } from "../../Helpers/Helpers.js";
import { ASSET_EMPLOYEE_IMAGE } from "../../utils/AssetsReferenceTypes.js";
import getUniversityDetails from "../../utils/universityDetails.api";
import axios from "axios";
import { BASE_URL } from "../../utils/apiConstants.js";
import { toast } from "react-toastify";

export default function UmsSettings({ setLoading, collegeId }) {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pin_code: "",
    logo_primary: "",
    logo_secondary: "",
    favicon: "",
    banner_img_1: "",
    banner_img_2: "",
    banner_img_3: "",
    banner_img_4: "",
    email: "",
    dev_email: "",
    registrar_email: "",
    provost_email: "",
    admission_email: "",
    rd_email: "",
    admin_email: "",
    superadmin_email: "",
    noreply_email: "",
    university_url: "",
    admission_url: "",
    exams_url: "",
  });

  //Function upload attachment to the s3
  const addAttachment = async (e, str) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_IMAGE,
        `university_${str}`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );

      console.log(d);

      setFormData((prev) => {
        return {
          ...prev,
          [str]: d,
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  //UNIVERSITY DETAILS USE EFFECT

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(1);
        const data = await getUniversityDetails();
        setFormData(data);
        setLoading(0);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();
  }, []);

  //UNIVERSITY DETAILS END

  function handleChange(e) {
    const { value, name } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${BASE_URL}/api/university`, formData);
      toast.success("University Data Successfully Updated.");
      console.log(response.data);
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data || "Error while Updating the University Data."
      );
      throw error;
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">UMS Settings</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card-body">
                        <div className="card-title">
                          Update your UMS Settings
                        </div>

                        <hr />
                        <br />
                        <div className="row">
                          {/* University Name */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="university-name">
                                University Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="university-name"
                                value={formData?.name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/*Phone*/}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="phone">Phone</label>
                              <input
                                type="tel"
                                className="form-control"
                                name="phone"
                                id="phone"
                                value={formData?.phone}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Address */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="address">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                name="address"
                                id="address"
                                value={formData?.address}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Country */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="country">Country</label>
                              <select
                                className="form-control"
                                name="country"
                                id="country"
                                value={formData?.country}
                                onChange={(e) => {
                                  setFormData((prev) => {
                                    if (e.target.value != prev.country) {
                                      setFormData((prev) => {
                                        return { ...prev, state: "" };
                                      });
                                    }

                                    return {
                                      ...prev,
                                      country: e.target.value,
                                    };
                                  });
                                }}
                              >
                                <option disabled selected>
                                  Select Country
                                </option>

                                {international.map((item) => {
                                  return (
                                    <option value={item.country}>
                                      {item.country}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          {/* State  */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="state">State</label>
                              <select
                                className="form-control"
                                name="state"
                                id="state"
                                value={formData?.state}
                                onChange={handleChange}
                              >
                                <option selected value="">
                                  Select State
                                </option>

                                {international
                                  .filter(
                                    (item) => item.country === formData.country
                                  )
                                  .map((filteredItem) =>
                                    filteredItem.states.map((state) => (
                                      <option key={state} value={state}>
                                        {state}
                                      </option>
                                    ))
                                  )}
                              </select>
                            </div>
                          </div>

                          {/* City */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="city">City</label>
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                id="city"
                                value={formData?.city}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Pincode */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="pincode">Pincode</label>
                              <input
                                min={0}
                                type="number"
                                className="form-control"
                                name="pin_code"
                                id="pincode"
                                value={formData?.pin_code}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                        <hr />
                        <div className="card-title">University Images</div>
                        <hr />
                        <br />

                        <div className="row">
                          {/* University Primary Logo */}

                          <div className="col-md-12 table-responsive">
                            <table className="table table-bordered">
                              <tr>
                                <td>
                                  <label>UMS Primary Logo</label>
                                </td>
                                <td>
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="primary-logo"
                                    onChange={(e) => {
                                      addAttachment(e, "logo_primary");
                                    }}
                                  />
                                </td>
                                <td>
                                  <img
                                    src={formData.logo_primary}
                                    className="border border-dark"
                                    height={50}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label>UMS Secondary Logo</label>
                                </td>
                                <td>
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="secondary-logo"
                                    id="logo"
                                    onChange={(e) => {
                                      addAttachment(e, "logo_secondary");
                                    }}
                                  />
                                </td>
                                <td>
                                  <img
                                    src={formData.logo_secondary}
                                    className="border border-dark"
                                    height={50}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Slideshow Image 1</label>
                                  <br />
                                  <p className="text-danger">
                                  <small>* Upload Wide (16:9) Image.</small>
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="slideshow-image-1"
                                    onChange={(e) => {
                                      addAttachment(e, "banner_img_1");
                                    }}
                                  />
                                </td>
                                <td>
                                  <img
                                    src={formData.banner_img_1}
                                    className="border border-dark"
                                    height={50}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Slideshow Image 2</label>
                                  <br />
                                  <p className="text-danger">
                                  <small>* Upload Wide (16:9) Image.</small>
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="slideshow-image-2"
                                    onChange={(e) => {
                                      addAttachment(e, "banner_img_2");
                                    }}
                                  />
                                </td>
                                <td>
                                  <img
                                    src={formData.banner_img_2}
                                    className="border border-dark"
                                    height={50}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Slideshow Image 3</label>
                                  <br />
                                  <p className="text-danger">
                                  <small>* Upload Wide (16:9) Image.</small>
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="slideshow-image-3"
                                    onChange={(e) => {
                                      addAttachment(e, "banner_img_3");
                                    }}
                                  />
                                </td>
                                <td>
                                  <img
                                    src={formData.banner_img_3}
                                    className="border border-dark"
                                    height={50}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Slideshow Image 4</label>
                                  <br />
                                  <p className="text-danger">
                                    <small>* Upload Wide (16:9) Image.</small>
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="slideshow-image-4"
                                    onChange={(e) => {
                                      addAttachment(e, "banner_img_4");
                                    }}
                                  />
                                </td>
                                <td>
                                  <img
                                    src={formData.banner_img_4}
                                    className="border border-dark"
                                    height={50}
                                  />
                                </td>
                              </tr>
                            </table>
                          </div>

                          {/* <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="logo">UMS Primary Logo</label>
                              <input
                                type="file"
                                className="form-control"
                                name="logo"
                                id="logo"
                                onChange={(e) => {
                                  addAttachment(e, "logo_primary");
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <img
                                src={formData.logo_primary}
                                className="border border-dark"
                                height={50}
                              />
                            </div>
                          </div> */}
                        </div>

                        {/* <br /> */}

                        {/* <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="logo">UMS Secondary Logo</label>
                              <input
                                type="file"
                                className="form-control"
                                name="logo"
                                id="logo"
                                onChange={(e) => {
                                  addAttachment(e, "logo_secondary");
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <img
                                src={formData.logo_secondary}
                                className="border border-dark"
                                height={50}
                              />
                            </div>
                          </div>
                        </div> */}

                        <br />
                        <hr />
                        <div className="card-title">
                          Update University Emails
                        </div>
                        <hr />
                        <br />

                        <div className="row">
                          {/* University Email */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="university-email">
                                University Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                value={formData?.email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Dev Email */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="dev_email">Dev Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="dev_email"
                                id="dev_email"
                                value={formData?.dev_email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Registrar Email */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="registrar_email">
                                Registrar Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="registrar_email"
                                id="registrar_email"
                                value={formData?.registrar_email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Provost Email */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="provost_email">
                                Provost Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="provost_email"
                                id="provost_email"
                                value={formData?.provost_email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Admission Email */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="admission_email">
                                Admission Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="admission_email"
                                id="admission_email"
                                value={formData?.admission_email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* RD Email */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="rd_email">RD Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="rd_email"
                                id="rd_email"
                                value={formData?.rd_email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Admin Email */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="admin_email">Admin Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="admin_email"
                                id="admin_email"
                                value={formData?.admin_email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Super Admin Email */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="super_admin_email">
                                Super Admin Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="super_admin_email"
                                id="super_admin_email"
                                value={formData?.superadmin_email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* No Reply Email */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="noreply_email">
                                Noreply Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="noreply_email"
                                id="noreply_email"
                                value={formData?.noreply_email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                        <hr />
                        <div className="card-title">Update University URLs</div>
                        <hr />

                        <p className="text-danger">
                          * To update the URLs of the sites listed below, please
                          contact Nexenstial Support.
                        </p>

                        <br />

                        <div className="row">
                          {/* University URL */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="university_url">
                                University URL
                              </label>
                              <input
                                type="url"
                                className="form-control"
                                name="university_url"
                                id="university_url"
                                style={{
                                  background: "#e4e4e4",
                                  borderColor: "#404040",
                                  cursor: "not-allowed",
                                }}
                                readOnly
                                disabled
                                value={formData?.university_url}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Admission URL */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="admission_url">
                                Admission URL
                              </label>
                              <input
                                type="url"
                                className="form-control"
                                name="admission_url"
                                id="admission_url"
                                style={{
                                  background: "#e4e4e4",
                                  borderColor: "#404040",
                                  cursor: "not-allowed",
                                }}
                                readOnly
                                disabled
                                value={formData?.admission_url}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Exams URL */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="exams_url">Exams URL</label>
                              <input
                                type="url"
                                className="form-control"
                                name="exams_url"
                                id="exams_url"
                                style={{
                                  background: "#e4e4e4",
                                  borderColor: "#404040",
                                  cursor: "not-allowed",
                                }}
                                readOnly
                                disabled
                                value={formData?.exams_url}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                        <hr />

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group text-right">
                              <button
                                onClick={handleSubmit}
                                className="btn btn-success btn-rounded btn-outline"
                              >
                                Update Details
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
