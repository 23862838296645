export const feeJsonData = [
    {
        name:'Cash',
        id:"Cash"
    },
    {
        name:'Cheque',
        id:"Cheque"
    },
    {
        name:'DD',
        id:"DD"
    },
    {
        name:'UPI',
        id:"UPI"
    },
    {
        name:'Card',
        id:"Card"
    },
    {
        name:'Bank Transfer',
        id:"Bank Transfer"
    }

]