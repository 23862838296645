import React ,{useState , useEffect} from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import Loader from '../../../Components/Loader/Loader'
import { EMPLOYEE_EDUCATION } from '../../../utils/apiConstants'
import { getFileUrl } from '../../../Helpers/Helpers'
import { ASSET_EMPLOYEE_DOCUMENT } from '../../../utils/AssetsReferenceTypes'
import DocumentsModal from '../../Students/DocumentsModal'

function ModalStaffEducation({ type, id, data, reloadData ,setLoading,fetchProgressData}) {

    //object for all input values
    const [user, setUser] = useState({
        education_type:"",
        other_education_type:"",
        school_name:"",
        university_name:"",
        performance_score:"",
        from_year:"",
        to_year:"",
        attachment:"",
        remark:""
    })
    
    const [profilePhoto, setProfilePhoto] = useState();

    const [link, setLink] = useState("");
    const [title, setTitle] = useState("");

    let tempPhoto = "";

    //Function for document Upload
    const addAttachment = async(e) =>{
        try {
            const d = await getFileUrl(ASSET_EMPLOYEE_DOCUMENT,`${id}_Education`,e.target.value.split(".")[1],setLoading,e.target.files[0]);
            setUser(prevValue => ({
                ...prevValue,
                attachment:d?d:''
            }))
            tempPhoto = d;
        } catch(error) {
            console.log(error);
        }

    }

    //handleChange for all input fields
    const handleChange = (e) => {
        const { name, value } = e.target

        setUser(prevValue => ({
            ...prevValue,
            [name]: value
        }));
    }


    //fuction to clear the input fields after completion of any operation
    const clearData = () =>{
        setUser({
            education_type:"",
            other_education_type:"",
            school_name:"",
            university_name:"",
            performance_score:"",
            from_year:"",
            to_year:"",
            attachment:"",
            remark:""
        })
    }


    //fuction to call after post or put
    const handleSubmit = async(d) =>{
        let attachmentPromises = [];
        if(profilePhoto) attachmentPromises.push(addAttachment(profilePhoto));

        await Promise.all(attachmentPromises);
        setLoading(1);

        let data1 = user;
        if(tempPhoto) data1 = { ...data1, "attachment":tempPhoto};
        console.log('data - ', data1);
        //config for axios
        const config = {
            method: type==='edit'?'put':'post',
            url: `${EMPLOYEE_EDUCATION}${type==='edit'?'/'+data?.id:''}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },
            data: {
                "employee_id": id,
                ...data1,
                "status":d?"INACTIVE":"ACTIVE"
            }
        };
        console.log(config);
    
    
            setLoading(1)
            await axios(config)
            .then((res)=>{
                fetchProgressData()
                setLoading(0)
                toast.success(res.data.message)
                console.log(res);
                reloadData()
                tempPhoto = "";
            })
            .catch(err=>{
                setLoading(0)
                tempPhoto = "";
                console.log(err);
                toast.error("Something went wrong")
            })
        }


    useEffect(() => {

        if(type==='edit'){
            if(data){
                setUser({
                    education_type:data.education_type,
                    other_education_type:data.other_education_type,
                    school_name:data.school_name,
                    university_name:data.university_name,
                    performance_score:data.performance_score,
                    from_year:data.from_year,
                    to_year:data.to_year,
                    attachment:data.attachment,
                    remark:data.remark
                })
            }
        }

        if(type==='add'){
            clearData()
        }

    }, [data,type])

    return (
        <>
        <DocumentsModal title={title} img={link} setLink={setLink}/>
        <div className='ModalStaffEducation'>
            <div
                className="modal fade"
                id="ModalStaffEducation"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered mw-100 w-75"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">
                                Add New Education
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {" "}
                                            Select Education Type <span style={{ color: "red" }}>*</span>
                                        </label>
                                        
                                        <select
                                            name="education_type"
                                            className='form-control'
                                            value={user.education_type}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setUser((prev) => {
                                                const other_education_type = prev.education_type !== 'other' ? '' : prev.other_education_type;

                                                return {
                                                    ...prev,
                                                    other_education_type,
                                                };
                                                });
                                            }}
                                            >
                                            <option selected>Choose Option</option>
                                            <option value="sslc/10th">SSLC / 10th</option>
                                            <option value="12th / Diploma">12th / Diploma</option>
                                            <option value="UG">UG</option>
                                            <option value="other">Other</option>
                                        </select>

                                    </div>
                                </div>
                                {user.education_type === 'other' && (
                                    
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                {" "}
                                                Enter Education Type<span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="other_education_type"
                                                value={user.other_education_type}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                )}

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {" "}
                                            School / College Name<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="school_name"
                                            value={user.school_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {" "}
                                            University Name<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="university_name"
                                            value={user.university_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {" "}
                                            Grade / CGPA / GPA / Percentile / Percentage
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="performance_score"
                                            value={user.performance_score}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {" "}
                                            From (Year)
                                        </label>
                                        <input
                                            type="month"
                                            className="form-control"
                                            name="from_year"
                                            value={user.from_year}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {" "}
                                            To (Year)<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="month"
                                            className="form-control"
                                            name="to_year"
                                            value={user.to_year}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {" "}
                                            Completion Proof Document
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="attachment"
                                            onChange={(e)=>{
                                                // addAttachment(e);
                                                setProfilePhoto(e)
                                            }}
                                        />
                                        {user?.attachment?.length > 0 ? (
                                          <button
                                            onClick={() => {
                                                setLink(user?.attachment);
                                                setTitle("Education");
                                            }}
                                            data-toggle="modal"
                                            data-target="#DocumentsModal"
                                            className="btn btn-primary btn-sm my-1"
                                          >
                                            View
                                          </button>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {" "}
                                            Remark
                                        </label>
                                        <textarea
                                            className="form-control"
                                            name="remark"
                                            placeholder='max 200 characters'
                                            value={user.remark}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between px-2">
                                <button 
                                className='btn btn-danger btn-rounded btn-outline'
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={()=>{handleSubmit(1)}}
                                >
                                    Delete
                                </button>
                                <button 
                                className='btn btn-success btn-rounded btn-outline'
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={()=>{handleSubmit(0)}}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ModalStaffEducation;