import React, { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GET_PROCTORING_DETAILS } from "../utils/apiConstants";
import { STUDENT_GATE_PASS_GET1 } from "../utils/InfoUploadingApiConstants";
import ModalStudentGatePass from "./Student_Portal/ModalStudentGatePass";
import HostelModalStudentGatePass from "../modals/HostelModalStudentGatePass";
import Nodata from "../Components/NoData/Nodata";

function GatePassInOut({ setLoading, collegeId }) {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  let date = new Date().toISOString().split("T")[0];

  const [data2, setData2] = useState("");
  const [flag, setFlag] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // When the user clicks the edit button
  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  console.log("collegeId", collegeId);

  const role = sessionStorage.getItem("role");

  const [data1, setData1] = useState([]);

  const getData1 = async () => {
    const employee_id = sessionStorage.getItem("employee_id");
    setLoading(1);
    const config1 = {
      method: "get",
      url: `${GET_PROCTORING_DETAILS}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config1)
      .then((res) => {
        const procteeData = res.data.data.filter(
          (p) => p.proctor_id == employee_id
        );
        // setData1(res.data.data);
        setData1(procteeData);
        console.log("data1", res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };
  useEffect(() => {
    getData1();
  }, []);

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${STUDENT_GATE_PASS_GET1}?from_date=${fromDate}&to_date=${toDate}`,
    };

    console.log("Fetching data with URL: ", config.url);

    await axios(config)
      .then((res) => {
        // setData(res.data.data);

        let data;
        if (role === "STAFF") {
          console.log("Role is STAFF");
          data = res.data.data.filter((s) =>
            data1?.some((d) => d.proctee_id == s.student_session_id)
          );
          console.log("Filtered data: ", data);
        } else {
          console.log("Role is not STAFF");
          data = res.data.data;
        }
        setData(data);
        console.log("gatepassdata -", data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });

    setLoading(0);
  };

  return (
    <div>
      <ModalStudentGatePass
        flag={flag}
        setFlag={setFlag}
        setLoading={setLoading}
        data={data2}
        getData={getData}
      />

      <HostelModalStudentGatePass
        flag={flag}
        setFlag={setFlag}
        setLoading={setLoading}
        data={data2}
        getData={getData}
      />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> Student Gate Pass List </h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            From date<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            name="fromDate"
                            className="form-control"
                            placeholder="From Date"
                            value={fromDate ? fromDate : date}
                            onChange={(e) => setFromDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            To Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            name="toDate"
                            className="form-control"
                            placeholder="To Date"
                            value={toDate ? toDate : date}
                            onChange={(e) => setToDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 d-flex align-items-center justify-content-end">
                      <button
                        className="btn btn-nex btn-rounded float-lg-left "
                        onClick={getData}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table table-responsive dt-responsive table-bordered nowrap table-hove">
                      <table
                        id="datatable"
                        className="table  nowrap table-hover  "
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          boarder: 0,
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sl.No.</th>
                            <th>Student Name</th>
                            <th>Enrollment No</th>
                            <th>Faculty</th>
                            <th>Department</th>
                            <th>Class</th>
                            <th>Request date</th>
                            <th>Purpose Type</th>
                            <th>Out Going Purpose</th>
                            <th>Out going From date</th>
                            <th>Out going To date</th>
                            <th>No Of Days</th>
                            <th>Proctor status</th>
                            <th>Proctor</th>
                            <th>Date</th>
                            <th>Warden Status</th>
                            {/* <th>Warden</th> */}
                            {/* <th>Date</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.map((d, k) => (
                              <tr>
                                <td>{k + 1}</td>
                                <td>{d.Student_Name}</td>
                                <td>{d?.Enrollment_No}</td>
                                <td>{d.College_Name}</td>
                                <td>{d.department_name}</td>
                                <td>{d.class_name}</td>
                                <td>{d?.request_date.split("T")[0]}</td>
                                <td>{d?.purpose_type}</td>
                                <td>{d?.out_going_purpose}</td>
                                <td>{d?.out_from_date}</td>
                                <td>{d?.out_to_date}</td>
                                <td>{d?.no_of_days}</td>

                                <td>
                                  <p
                                    className={` mt-3 badge ${
                                      d?.approval_status_1 == "APPROVED"
                                        ? "badge-soft-success"
                                        : d?.approval_status_1 == "PENDING"
                                        ? "badge-soft-warning"
                                        : d?.approval_status_1 == "DECLINED"
                                        ? "badge-soft-danger"
                                        : d?.approval_status_1 == "CANCELLED"
                                        ? "badge-soft-danger"
                                        : ""
                                    }`}
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "10px",
                                      textAlign: "center",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {d?.approval_status_1}
                                  </p>
                                </td>

                                <td>
                                  {d?.first_name} {d?.last_name}
                                </td>

                                <td>{d?.approved_date_1}</td>

                                <td>
                                  <p
                                    className={` mt-3 badge ${
                                      d?.approval_status_2 == "APPROVED"
                                        ? "badge-soft-success"
                                        : d?.approval_status_2 == "PENDING"
                                        ? "badge-soft-warning"
                                        : d?.approval_status_2 == "DECLINED"
                                        ? "badge-soft-danger"
                                        : d?.approval_status_2 == "CANCELLED"
                                        ? "badge-soft-danger"
                                        : ""
                                    }`}
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "10px",
                                      textAlign: "center",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {d?.approval_status_2}
                                  </p>
                                </td>

                                <td>
                                  {role == "WARDEN" ? (
                                    <a
                                      data-toggle="modal"
                                      data-target="#HostelStudentGatePass"
                                      onClick={() => {
                                        setData2({
                                          ...d,
                                        });
                                      }}
                                    >
                                      <button
                                        className="btn btn-primary btn-nex btn-rounded mr-2"
                                        type="button"
                                        name="submit"
                                        onClick={() => handleEditClick(d)}
                                      >
                                        View
                                      </button>
                                    </a>
                                  ) : (
                                    <a
                                      data-toggle="modal"
                                      data-target="#StudentGatePass"
                                      onClick={() => {
                                        setData2({
                                          ...d,
                                        });
                                      }}
                                    >
                                      <button
                                        className="btn btn-primary btn-nex btn-rounded mr-2"
                                        type="button"
                                        name="submit"
                                        onClick={() => handleEditClick(d)}
                                      >
                                        View
                                      </button>
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {data?.length == 0 ? <Nodata /> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GatePassInOut;
