import React, { useEffect, useRef, useState } from 'react'
import { ALL_COURSE_COUNT, ALL_LESSON_COUNT, ALL_SYLLABUS_COUNT, ALL_TOPIC_COUNT } from '../../../utils/Academics.apiConst';
import axios from "axios";
import { toast } from "react-toastify";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";

function ModalSyllabusCount(props) {
    console.log("props", props?.semId)
    const [alldata, setallsyllabus] = useState()
    console.log("ALLDATA - ", alldata);
    const collegeId = sessionStorage.getItem("college_id")

    const getData = async () => {

        const config = {
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            url: `${ALL_COURSE_COUNT}?college_id=${collegeId}`
        };

        // setLoading(1);
        await axios(config)
            .then((res) => {

                // setLoading(0);
                console.log("res.data.data", res.data.data);

                setallsyllabus(res.data.data);

            })
            .catch((err) => {
                // setLoading(0);
                console.log(err);
                toast.error("Something Went Wrong");
            })
    }
    useEffect(() => {
        getData()
    }, [props?.semId])

    const [alllesson, setalllesson] = useState({})
    console.log("all lesson", alllesson);

    const getlessonData = async () => {

        const config = {
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            url: `${ALL_LESSON_COUNT}?college_id=${collegeId}`
        };

        // setLoading(1);
        await axios(config)
            .then((res) => {

                // setLoading(0);
                console.log("res.data.data", res.data.data);

                setalllesson(res.data.data);

            })
            .catch((err) => {
                // setLoading(0);
                console.log(err);
                toast.error("Something Went Wrong");
            })
    }
    useEffect(() => {
        getlessonData()
    }, [props?.semId])

    const [alltopic, setalltopic] = useState({})
    console.log("all alltopic", alltopic);

    const gettopicData = async () => {

        const config = {
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            url: `${ALL_TOPIC_COUNT}?college_id=${collegeId}`
        };

        // setLoading(1);
        await axios(config)
            .then((res) => {

                // setLoading(0);
                console.log("res.data.data", res.data.data);

                setalltopic(res.data.data);

            })
            .catch((err) => {
                // setLoading(0);
                console.log(err);
                toast.error("Something Went Wrong");
            })
    }
    useEffect(() => {
        gettopicData()
    }, [props?.semId])

    const tableRef = useRef();
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Syllabus Status-" + new Date().getFullYear(),
        sheet: "Syllabus Status",
    });
   
    const PrintRecipt = useReactToPrint({
        content: () => tableRef.current,
      });
    const handlePrint = () => {
     
        tableRef.current.style.display = 'table';
      
      
        setTimeout(() => {
          PrintRecipt();
 
          tableRef.current.style.display = 'none';
        }, 1); 
      };

    return (
        <div
            className="modal fade"
            id="ModalSyllabusCount"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">


            <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
            >
                <div className="modal-content ">
                    <div className="modal-header">
                        {props?.type == "course" ? (
                            <h5 className="modal-title" id="exampleModalLongTitle">
                                Subject List
                            </h5>
                        ) : (<></>)}
                        {props?.type == "lesson" ? (
                            <h5 className="modal-title" id="exampleModalLongTitle">
                                Lesson List
                            </h5>
                        ) : (<></>)}
                        {props?.type == "topic" ? (
                            <h5 className="modal-title" id="exampleModalLongTitle">
                                Topic List
                            </h5>
                        ) : (<></>)}

                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                    <div className="row d-flex justify-content-end p-3">
                              
                                <button onClick={onDownload} className="btn btn-success ml-auto">
                                    Export Excel
                                </button>
                                <button
                                  className="btn btn-primary ml-2"
                                  onClick={handlePrint}
                                >
                                    Export PDF
                                </button>
                            </div>
                        <table className="table border table-sm">
                            <thead>
                                <tr>
                                    <th>Sl No.</th>
                                    <th>Class</th>
                                    <th>Department</th>
                                    <th>Semester</th>

                                    {props?.type == "course" ? (
                                        <th>Subject Name</th>
                                    ) : (<></>)}
                                    {props?.type == "lesson" ? (
                                      <th>Lesson Name</th>
                                    ) : (<></>)}
                                    {props?.type == "topic" ? (
                                        <th>Topic Name</th>
                                    ) : (<></>)}


                                </tr>
                            </thead>
                            <tbody>
                                {alldata && props?.type === "course" ? (
                                    alldata
                                        .filter((d) => d.semester_id === props?.semId)
                                        .map((d, i) => (
                                            <tr key={`${d.semester_id}-${i}`}>
                                                <td>{i + 1}</td>
                                                <td>{d.class_name}</td>
                                                <td>{d.department_name}</td>
                                                <td>{d.semester_name}</td>
                                                <td>{d.sub_name}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <></>
                                )}
                                {alllesson && props?.type === "lesson" ? (
                                    alllesson
                                        .filter((d) => d.semester_id === props?.semId)
                                        .map((d, i) => (
                                            <tr key={`${d.semester_id}-${i}`}>
                                                <td>{i + 1}</td>
                                                <td>{d.class_name}</td>
                                                <td>{d.dpt_name}</td>
                                                <td>{d.sem_name}</td>
                                                <td>{d.lesson_name}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <></>
                                )}
                                {alltopic && props?.type === "topic" ? (
                                    alltopic
                                        .filter((d) => d.semester_id == props?.semId)
                                        .map((d, i) => (
                                            <tr key={`${d.semester_id}-${i}`}>
                                                <td>{i + 1}</td>
                                                <td>{d.class_name}</td>
                                                <td>{d.dpt_name}</td>
                                                <td>{d.sem_name}</td>
                                                <td>{d.topic_name}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <></>
                                )}
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalSyllabusCount











