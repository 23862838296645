import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd';
import { ASSET_EMPLOYEE_DOCUMENT } from '../../utils/AssetsReferenceTypes';
import { useRef } from 'react';
import DocumentsModal from '../../modals/Students/DocumentsModal';
import { getFileUrl } from '../../Helpers/Helpers';
import ReactQuill from 'react-quill';
import { toast } from "react-toastify";
import { ADD_EVENT, EVENT_WORKFLOW, EVENT_WORKFLOW_STATUS, EVENTWORKFLOW } from '../../utils/apiConstants';
import axios from "axios";
import moment from 'moment';
function AddEventModal(props) {
    // console.log("props.type", props.type)
    // title
    const [title, setTitle] = useState("");

    // dates
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const disabledDate = (current) => {
        return fromDate && current && current.isBefore(fromDate, 'day');
    };

    // attachment
    const [attachment, setAttachment] = useState("");
    const fileref = useRef(null);
    const [modalTitle, setModalTitle] = useState("");
    const [modalLink, setModalLink] = useState("");
    const addAttachment = async (e, name) => {
        try {
            const d = await getFileUrl(
                ASSET_EMPLOYEE_DOCUMENT,
                `Attachment`,
                e.target.value.split(".")[1],

                e.target.files[0]
            );
            setAttachment(d);
        } catch (error) {
            console.log(error);
        }
    };

    // guest count and names

    // assign event


    const [data, setData] = useState([])
    // console.log("DATA -", data)
    const [desc, setDesc] = useState("");
   
    const getData = async () => {

        // setLoading(1)

        const config = {
            method: 'get',
            url: `${EVENT_WORKFLOW}?type=LEAVE`,
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`,
                'Content-Type': 'application/json'
            },
        }


        await axios(config)
            .then((res) => {
                // setLoading(0)

                setData(res.data.data);
                // console.log('workflows - ', res.data.data);

            })
            .catch(err => {

                toast.error(err.response.data.message)
            })
    }

    useEffect(() => {
        getData();

    }, []);
    const [guestNames, setGuestNames] = useState([]);
    const [cordinateName, setcordinateName] = useState([]);
    console.log("guestNames",guestNames )
    console.log("cordinateName",cordinateName);
    
    const [info, setInfo] = useState({
        level: "",
        no_of_people: "",
        approval_flow: "",
        venue: "",
        guest_count: "",
        guest_list: JSON.stringify(guestNames),
        coordinator_count: "",
        coordinator_list: JSON.stringify(cordinateName),
    });



    const handleChange = (e) => {
        const { name, value } = e.target;
        setInfo((prevValue) => ({
            ...prevValue,
            [name]: value,
        }));
    };
    const handleGuestCountChange = (e) => {
        const { value } = e.target;
        let newValue = parseInt(value, 10);
    
        if (isNaN(newValue) || newValue < 0) {
            newValue = 0;
        }
    
        setGuestNames(new Array(newValue).fill(''));
        setInfo((prevInfo) => ({
            ...prevInfo,
            guest_count: newValue,
            guest_list: new Array(newValue).fill('') // Ensure info.guest_list is updated
        }));
    };
    
    const handleGuestNameChange = (index, e) => {
        const newGuestNames = [...guestNames];
        newGuestNames[index] = e.target.value;
        setGuestNames(newGuestNames);
    
        setInfo((prevInfo) => ({
            ...prevInfo,
            guest_list: newGuestNames // Update info.guest_list with the new values
        }));
    };
    
    const handleCoordinateCount = (e) => {
        const { value } = e.target;
        let newValue = parseInt(value, 10);
    
        if (isNaN(newValue) || newValue < 0) {
            newValue = 0;
        }
    
        setcordinateName(new Array(newValue).fill(''));
        setInfo((prevInfo) => ({
            ...prevInfo,
            coordinator_count: newValue,
            coordinator_list: new Array(newValue).fill('') // Ensure info.cordinate_list is updated
        }));
    };
    
    const handleCordinateName = (index, e) => {
        const newCordDNames = [...cordinateName];
        newCordDNames[index] = e.target.value;
        setcordinateName(newCordDNames);
    
        setInfo((prevInfo) => ({
            ...prevInfo,
            coordinator_list: newCordDNames // Update info.cordinate_list with the new values
        }));
    };
    
    // clear data
    const clearData = () => {
        setTitle("");
        setDesc("");
        setFromDate("");
        setToDate("");
        setInfo("")

    };


    let employeeId = sessionStorage.getItem("employee_id");

    //  const matchFound = data.some(item => {
    //     if (item.id === parseInt(info.approval_flow)) {


    //         if (item.approver_1 === Number(employeeId)) {
    //             console.log("APPROVE");
    //             return true;
    //         }
    //     }
    //     return false; 
    // });
    const approval_status_1 = data.some(item => item.approver_1 !== undefined && item.id === parseInt(info.approval_flow, 10) && item.approver_1 === Number(employeeId)) ? "APPROVE" : data.some(item => item.approver_1 === undefined && item.id === parseInt(info.approval_flow, 10)) ? "" : "PENDING"


    // console.log({ approval_status_1 });
    // console.log("app", info);

    const getId = (data.find(item => item.id === parseInt(info.approval_flow, 10))?.id) ?? 0;

    // console.log("getId",getId);




    let getcollegeid = sessionStorage.getItem("college_id")


    const handleSubmit = async (e) => {


        if (!title) return toast.error("Please Enter Title");
        if (!fromDate) return toast.error("Please Enter From Date");
        if (!toDate) return toast.error("Please Enter To Date");
        const config = {
            method: "post",
            url: `${ADD_EVENT}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
            data: {
                title: title,
                description: desc,
                from_date: fromDate,
                to_date: toDate,
                attachment: attachment,
                createdBy: sessionStorage.getItem("employee_id"),
                level: info.level,
                venue: info.venue,
                no_of_people: info.no_of_people,
                approval_flow: info.approval_flow,
                type: "event",
                college_id: getcollegeid,
                guest_count: info.guest_count,
                guest_list: JSON.stringify(guestNames),
                coordinator_count: info.guest_count,
                coordinator_list: JSON.stringify(cordinateName),
            },
        };
console.log("CONFIG.DATA", config.data);


        try {
            const response = await axios(config);
            // console.log(response);
            clearData();
            toast.success("Event Created");

        } catch (err) {
            console.error("Error details:", err.response || err.message || err);
            toast.error("Something Went Wrong: " + (err.response?.data?.message || "An unknown error occurred"));
        }

        const config2 = {
            method: "put",
            url: `${EVENT_WORKFLOW_STATUS}/${(data.find(item => item.id === parseInt(info.approval_flow, 10))?.id) ?? 0}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
            data: {

                approval_status_1: data.some(item => item.approver_1 === 0 && item.id === parseInt(info.approval_flow, 10)) ? "" : "PENDING",
                approval_status_2: data.some(item => item.approver_2 === 0 && item.id === parseInt(info.approval_flow, 10)) ? "" : "PENDING",
                approval_status_3: data.some(item => item.approver_3 === 0 && item.id === parseInt(info.approval_flow, 10)) ? "" : "PENDING",
                approval_status_4: data.some(item => item.approver_4 === 0 && item.id === parseInt(info.approval_flow, 10)) ? "" : "PENDING"

            },
        };
        //   console.log(config2);

        //   setLoading(1);
        await axios(config2)
            .then((res) => {
                //   setLoading(0);
                toast.success(res.data.message);
                console.log(res);
                //   reloadData();
            })
            .catch((err) => {
                //   setLoading(0);
                console.log(err);
                toast.error("Something went wrong");
            });
    }

    // edit
    const handleEdit = async () => {

        if (!title) return toast.error("Please Enter Title");
        if (!fromDate) return toast.error("Please Enter From Date");
        if (!toDate) return toast.error("Please Enter To Date");
        if (!props.data.id) {
            return toast.error("Unable to get Announcement")
        }



        const config = {
            method: "put",
            url: `${ADD_EVENT}/${props.data2.id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
            data: {
                title: title,
                description: desc,
                from_date: fromDate ? fromDate.format('YYYY-MM-DD') : null,
                to_date: toDate ? toDate.format('YYYY-MM-DD') : null,
                attachment: props.data2.attachment,
                createdBy: sessionStorage.getItem("employee_id"),
                college_id: props.collegeId,
                type: "event",
                level: info.level,
                venue: info.venue,
                approval_flow: info.approval_flow,
                no_of_people: info.no_of_people,
                guest_count: info.guest_count,
                guest_list:  JSON.stringify(guestNames),
                coordinator_count: info.guest_count,
                coordinator_list: JSON.stringify(cordinateName),


            },
        };

        await axios(config)
            .then((res) => {
                // props.setLoading(0);

                clearData();
                props.reloadData();
                toast.success("Event Updated");
            })
            .catch((err) => {
                // props.setLoading(0);
                console.log(err);
                toast.error("Something Went Wrong");
            });
    }


    console.log("PROPPS", props.data2)


    useEffect(() => {
        if (props.type !== "add" && props.data2) {
            console.log("Loaded data:", props.data2);
    
            // Set all values, making sure to parse guest_list and cordinate_list
            setTitle(props.data2.title);
            setDesc(props.data2.description);
            setFromDate(moment(props.data2.from_date));
            setToDate(moment(props.data2.to_date));
    
            setInfo((prevInfo) => ({
                ...prevInfo,
                level: props.data2.level,
                venue: props.data2.venue,
                no_of_people: props.data2.no_of_people,
                approval_flow: props.data2.approval_flow,
                guest_count: props.data2.guest_count,
                guest_list: JSON.parse(props.data2.guest_list || "[]"),
                coordinator_count: props.data2.coordinator_count,
                coordinator_list: JSON.parse(props.data2.coordinator_list || "[]")
            }));
    
            setGuestNames(JSON.parse(props.data2.guest_list || "[]"));
            setcordinateName(JSON.parse(props.data2.coordinator_list || "[]"));
        }
    }, [props.data2]);



    useEffect(() => {
        console.log("Guest Names:", guestNames);
        console.log("Coordinator Names:", cordinateName);
    }, [guestNames, cordinateName]);












    return (
        <div
            className="modal fade"
            id="AddEventModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <DocumentsModal title={modalTitle} img={modalLink} setLink={setModalLink} />
            <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
            >
                <div className="modal-content ">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            Add Event
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12">
                            <div className="inputBox">
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={title}

                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                    }}
                                    required="required"
                                />
                                <span>Event Name *</span>
                            </div>
                        </div> {/* title */}
                        <div className="row mt-3 ml-1">
                            <div className="col-md-4">
                                <p><b>From Date<span className='text-danger'>*</span></b></p>
                                <DatePicker
                                    className="form-control"
                                    onChange={(date) => {
                                        setFromDate(date);
                                        if (toDate && date && date.isAfter(toDate)) {
                                            setToDate(null);
                                        }
                                    }}
                                    value={fromDate}
                                    format="DD-MM-YYYY"
                                />
                            </div>
                            <div className="col-md-4">
                                <p><b>To Date <span className='text-danger'>*</span> </b></p>
                                <DatePicker
                                    className="form-control"
                                    onChange={(date) => setToDate(date)}
                                    value={toDate}
                                    disabledDate={disabledDate}
                                    format="DD-MM-YYYY"
                                />
                            </div>
                            <div className="col-md-4">
                                <p><b>Atttachment <span className='text-danger'>*</span></b></p>

                                <div>
                                    <input
                                        type="file"
                                        name="attachment"
                                        className="form-control "
                                        ref={fileref}
                                        onChange={(e) => {
                                            addAttachment(e, "attachment");
                                        }}
                                    />
                                    {/* <button
                                        onClick={() => {
                                            setModalLink(attachment);
                                            setModalTitle("Event Attachment");
                                        }}
                                        data-toggle="modal"
                                        data-target="#DocumentsModal"
                                        className="btn btn-nex btn-sm my-1 mr-"
                                        title="View"
                                    >
                                        View
                                    </button> */}
                                </div>
                            </div>{/* attachment */}

                        </div>{/* dates */}
                        <div className="col-md-12 mt-3">
                            <p><b>Assign Event <span className='text-danger'>*</span></b></p>
                            <div className="row">



                                <div className=" col-md-4">
                                    <div className="form-group">
                                        <label>Level <span className='text-danger'>*</span></label>
                                        <select
                                            value={info?.level}
                                            onChange={handleChange}
                                            name="level"

                                            className="form-control"
                                        >
                                            <option value="">Select option...</option>
                                            <option value="University">University Level</option>
                                            <option value="College">College Level</option>
                                            <option value="Department">Department Level</option>
                                            <option value="Class">Class Level</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="validationCustom01">
                                            Event Visitors<span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Number of People"
                                            value={info?.no_of_people}
                                            name="no_of_people"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className=" col-md-4">
                                    <div className="form-group">
                                        <label>Approval Flow<span className='text-danger'>*</span></label>
                                        <select

                                            value={info?.approval_flow}
                                            onChange={handleChange}
                                            name="approval_flow"

                                            className="form-control"
                                        >
                                            <option>Select option...</option>
                                            {data?.map((i, key) => (
                                                <option value={i.id} key={key}>
                                                    {i.title}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                                <div className=" col-md-4">
                                    <div className="form-group">
                                        <label>Guest Count<span className='text-danger'>*</span></label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Number of Guest"
                                            value={info?.guest_count}
                                            name="guest_count"
                                            onChange={handleGuestCountChange}
                                        >
                                        </input>
                                    </div>
                                </div>
                                {guestNames.map((guest, index) => (
                                    <div key={index} className="col-md-4">
                                        <div className="form-group">
                                            <label>Guest Name{index + 1}(Designation) <span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={guest}
                                                onChange={(e) => handleGuestNameChange(index, e)}
                                                placeholder={`Enter Guest Name ${index + 1} With Designation`}
                                            />
                                        </div>
                                    </div>
                                ))}
                                <div className=" col-md-4">
                                    <div className="form-group">
                                        <label>Coordinator Count<span className='text-danger'>*</span></label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Number of Guest"
                                            value={info?.coordinator_count}
                                            name="coordinator_count"
                                            onChange={handleCoordinateCount}
                                        >
                                        </input>
                                    </div>
                                </div>
                                {cordinateName.length > 0 && cordinateName.map((cordinate, index) => (
                                    <div key={index} className="col-md-4">
                                        <div className="form-group">
                                            <label>Coordinator Name {index + 1}<span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={cordinate}
                                                onChange={(e) => handleCordinateName(index, e)}
                                                placeholder={`Enter Coordinator Name ${index + 1}`}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="col-md-12 mt-2 ms-2 ml-1">

                            <div className="form-group">
                                <label htmlFor="validationCustom01">
                                    Venue<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Venue"
                                    value={info?.venue}
                                    name="venue"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mt-2 ms-2 ml-1">
                            <p><b>Description <span className='text-danger'>*</span></b></p>
                            <div>
                                <ReactQuill
                                    theme="snow"
                                    style={{ width: '97%' }}
                                    value={desc}
                                    onChange={(e) => {
                                        setDesc(e);

                                    }}
                                    placeholder="Write Detailed Description of Announcement Here..."
                                />
                            </div>
                        </div>

                        <br />

                        <div className="row d-flex justify-content-between px-2 m-2">
                            <button
                                className="btn btn-danger btn-rounded btn-outline"
                                data-dismiss="modal"
                                aria-label="Close"
                            // onClick={() => {
                            //     clearData();
                            // }}
                            >
                                Close
                            </button>
                            {props.type === "edit"
                                ?

                                <button
                                    className="btn btn-success btn-rounded btn-outline"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleEdit}
                                >
                                    Update Changes
                                </button>

                                :
                                <button
                                    className="btn btn-success btn-rounded btn-outline"
                                    // data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleSubmit}
                                >
                                    Send
                                </button>
                            }
                        </div>






                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddEventModal
