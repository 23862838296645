import React from "react";
import "./Documetns.scss";

const DocumentsAdmission = ({ basic_data }) => {
  return (
    <div className="Documents">
      <div>
        <hr />
        <h4 className="my-3">Student Document Attached</h4>
        <hr />
      </div>
      <div className="row p-4 table-responsive">
        <table className="table table-bordered border-danger rounded text-center">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>Documents</th>
              <th>Status</th>
              <th>Attachment</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td>01</td>
              <td>Aadhar Card</td>
              {basic_data?.aadhar?.link ? (
                <td>
                  {basic_data?.aadhar?.status == "ACTIVE"
                    ? "Not Reviewed"
                    : basic_data?.aadhar?.status}
                </td>
              ) : (
                <td>Not Uploaded</td>
              )}
              {basic_data?.aadhar?.link ? (
                <td>
                  <a
                    className="d-flex align-items-center  justify-content-center"
                    target={"_blank"}
                    href={basic_data?.aadhar?.link}
                    style={{ color: "black" }}
                  >
                    <i
                      className="ri-attachment-2"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    ></i>
                    &nbsp; View
                  </a>
                </td>
              ) : (
                <td>Not Uploaded</td>
              )}
            </tr>
            <tr className="text-center">
              <td>02</td>
              <td>10th Marks Sheet</td>
              {basic_data?.sslc_markscard?.link ? (
                <td>
                  {basic_data?.sslc_markscard?.status == "ACTIVE"
                    ? "Not Reviewed"
                    : basic_data?.sslc_markscard?.status}
                </td>
              ) : (
                <td>Not Uploaded</td>
              )}
              {basic_data?.sslc_markscard?.link ? (
                <td>
                  <a
                    className="d-flex align-items-center  justify-content-center"
                    target="_blank"
                    href={basic_data?.sslc_markscard?.link}
                    style={{ color: "black" }}
                  >
                    <i
                      className="ri-attachment-2"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    ></i>
                    &nbsp; View
                  </a>
                </td>
              ) : (
                <td>Not Uploaded</td>
              )}
            </tr>
            <tr className="text-center">
              <td>03</td>
              <td>12th Marks Sheet</td>
              {basic_data?.pu_markscard?.link ? (
                <td>
                  {basic_data?.pu_markscard?.status == "ACTIVE"
                    ? "Not Reviewed"
                    : basic_data?.pu_markscard?.status}
                </td>
              ) : (
                <td>Not Uploaded</td>
              )}
              {basic_data?.pu_markscard?.link ? (
                <td>
                  <a
                    className="d-flex align-items-center  justify-content-center"
                    target="_blank"
                    href={basic_data?.pu_markscard?.link}
                    style={{ color: "black" }}
                  >
                    <i
                      className="ri-attachment-2"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    ></i>
                    &nbsp; View
                  </a>
                </td>
              ) : (
                <td>Not Uploaded</td>
              )}
            </tr>
            {/* <tr>
                                        <td>04</td>
                                        <td>AIPGT Marks Sheet</td>
                                        {
                                            basic_data?.attach_aipgt_markssheet?.link
                                            ?
                                            <td>{basic_data?.attach_aipgt_markssheet?.status == 'ACTIVE'?'Not Reviewed' : basic_data?.attach_aipgt_markssheet?.status}</td>
                                            :
                                            <td>Not Uploaded</td>
                                        }
                                        {
                                            basic_data?.attach_aipgt_markssheet?.link
                                            ?
                                            <td><a className='d-flex align-items-center  justify-content-center' target='_blank' href={basic_data?.attach_aipgt_markssheet?.link} style={{color:'black'}}><i className='ri-attachment-2' style={{fontSize:'18px',cursor:'pointer'}}></i>&nbsp; View</a></td>
                                            :
                                            <td>Not Uploaded</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>05</td>
                                        <td>Reg form</td>
                                        {
                                            basic_data?.reg_form?.link
                                            ?
                                            <td>{basic_data?.reg_form?.status == 'ACTIVE'?'Not Reviewed' : basic_data?.reg_form?.status}</td>
                                            :
                                            <td>Not Uploaded</td>
                                        }
                                        {
                                            basic_data?.reg_form?.link
                                            ?
                                            <td><a className='d-flex align-items-center  justify-content-center' target='_blank' href={basic_data?.reg_form?.link} style={{color:'black'}}><i className='ri-attachment-2' style={{fontSize:'18px',cursor:'pointer'}}></i>&nbsp; View</a></td>
                                            :
                                            <td>Not Uploaded</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>06</td>
                                        <td>Degree Certificate</td>
                                        {
                                            basic_data?.degree?.link
                                            ?
                                            <td>{basic_data?.degree?.status == 'ACTIVE'?'Not Reviewed' : basic_data?.degree?.status}</td>
                                            :
                                            <td>Not Uploaded</td>
                                        }
                                        {
                                            basic_data?.degree?.link
                                            ?
                                            <td><a className='d-flex align-items-center  justify-content-center' target='_blank' href={basic_data?.degree?.link} style={{color:'black'}}><i className='ri-attachment-2' style={{fontSize:'18px',cursor:'pointer'}}></i>&nbsp; View</a></td>
                                            :
                                            <td>Not Uploaded</td>
                                        }
                                    </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DocumentsAdmission;
