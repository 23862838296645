import React, { useEffect, useState } from "react";
import axios from "axios";
import { Http } from "../../Services/Services";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { STUDENT, SYLLABUS_STATUS } from "./student.Api";
import { Devlog } from "./Logger";

const Lessons = ({ setLoading }) => {
  const [data, setData] = useState([]);
  const [studentsessiondata, setStudentSessionData] = useState([]);
  const { course_id, semester_id } = useParams();
  const [session, setSession] = useState("");
  const [studentData, setStudentData] = useState([]);

  console.log("semester_id -", semester_id);
  console.log(course_id);

  const navigate = useNavigate();

  const getStudentData = async () => {
    const userId = sessionStorage.getItem("uid");
    console.log("uid -", userId);

    await Http.get(`${STUDENT}/${userId}`)
      .then((res) => {
        let temp = res?.data;
        let activeSession = temp?.session?.filter((s) => s?.status == "ACTIVE");
        setSession(activeSession[0]?.session_id);
        let obj = {
          id: temp?.semester[temp?.semester.length - 1]?.student_session_id,
          class_name: temp?.session[temp?.session.length - 1]?.class_name,
          department_id: temp?.data?.department_id,
          class_id: temp?.session[temp?.session.length - 1]?.class_id,
          session_id: temp?.session[temp?.session.length - 1]?.session_id,
          status: temp?.data?.status,
        };
        console.log("obj - ", obj);
        setStudentData(obj);
      })
      .catch((err) => {
        console.error("Error fetching student data:", err);
        toast.error("Something went wrong");
      });
  };

  console.log("session -", session);

  const getData = () => {
    // const session_id = session;
    // console.log("session_id -", session_id);
    Http.get(`${SYLLABUS_STATUS}/${course_id}?session_id=${session}`)
      // Http.get(`${SYLLABUS_STATUS}/${course_id}`)
      .then((res) => {
        Devlog("Lessons", res.data.data);
        // const lessondata = res.data.data.filter((s) => s.session_id == session);
        setData(res.data.data);
        console.log("lessondata -", data);
      })
      .catch((err) => {
        Devlog("Lessons", err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getStudentData();
  }, []);

  useEffect(() => {
    if (session) {
      getData();
    }
    // getData();
  }, [session]);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h6 className="card-header text-center bg-white">
                    My Lessons
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <tr className="text-center bg-dark text-white">
                        <th>Sl No.</th>
                        <th>Lesson</th>
                        <th>Topics</th>
                        <th>Status</th>
                        <th>Staff Name</th>
                      </tr>
                      {/* {
                                    data.map((item, index)=>(
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                <button onClick={()=>navigate(ROUTES.Topics+'/'+item.id)} className="btn btn-sm btn-primary">
                                                    View Topics
                                                </button>
                                            </td>
                                        </tr>
                                    ))

                                } */}

                      {data.map((item, index) => (
                        <>
                          <tr className="text-center">
                            <td rowSpan={item?.topics?.length + 1}>
                              {index + 1}
                            </td>
                            <td rowSpan={item?.topics?.length + 1}>
                              {item?.lesson_name}
                            </td>

                            <td className="d-none"></td>
                            <td className="d-none"></td>
                          </tr>
                          {/* {item?.topics?.map((j, key) => (
                            <tr>
                              <td>{j.topic_name}</td>
                              <td>
                                <div className="d-flex justify-content-center">
                                  <span
                                    className={`badge badge-soft-${
                                      j.status ? "success" : "danger"
                                    }`}
                                  >
                                    {j.status ? "Completed" : "InComplete"}
                                  </span>
                                </div>
                                {j?.status ? (
                                  <div className="text-center mt-1">
                                    {j?.completion_date?.split("T")[0]}
                                  </div>
                                ) : null}
                              </td>
                              <td>{j?.employee_id}</td>
                            </tr>
                          ))} */}

                          {item?.topics?.map((j, key) => {
                            console.log("item?.topics", item?.topics); // Log each individual topic item

                            return (
                              <tr key={key} className="text-center">
                                <td>{j.topic_name}</td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <span
                                      className={`badge badge-soft-${
                                        j.status ? "success" : "danger"
                                      }`}
                                    >
                                      {j.status ? "Completed" : "InComplete"}
                                    </span>
                                  </div>
                                  {j?.status ? (
                                    <div className="text-center mt-1">
                                      {j?.completion_date?.split("T")[0]}
                                    </div>
                                  ) : null}
                                </td>
                                <td>{j?.emp_name}</td>
                              </tr>
                            );
                          })}
                        </>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
