import React from "react";
import "./Dashboard.scss";
import ReactApexChart from "react-apexcharts";
import Calender from "../../Components/Calender/Calender";
import axios from "axios";
import Chart from 'react-apexcharts';
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { DASHBOARD_REGISTRAR, SUPERADMIN_DASHBOARD, MEDICAL_SUPERADMIN_DASHBOARD, ANNOUNCEMENTS, ADD_EVENT } from "../../utils/apiConstants";
import { SESSION_EMPLOYEE_ID } from "../../utils/sessionStorageContants";
import ModalLeaveReview from "../../modals/HR/Leave/ModalLeaveReview";
import {
  LEAVE_APPLICATION,
  LEAVE_ENTITLEMENT,
  LEAVE_REVIEW_EMPLOYEE,
  LEAVE_APPROVER_APPLICATION,
  LEAVE_REVIEW_EMPLOYEE_SQL,
  LEAVE_LEAVE_TYPE,
} from "../../utils/apiConstants";
import { SESSION_ROLE } from "../../utils/sessionStorageContants";
import { ALL_DATA, LOCAL_COLLEGE } from "../../utils/LocalStorageConstants";
import { Select, Button, Input, Space, Table, Tag } from "antd";
import { Divider, List, Typography } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from '../../Router/routerConfig';
// import ReactApexChart from 'react-apexcharts';

function DashboardRegistrar2({ setLoading, collegeId }) {

  var emp_id = sessionStorage.getItem("employee_id");

  const navigate = useNavigate();

  const colors = ["#364277", "#FF9F43"];

  const employee = JSON.parse(localStorage.getItem(ALL_DATA)).employee;

  const getCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

  const getEmployeeId = () => {
    return sessionStorage.getItem(SESSION_EMPLOYEE_ID)
      ? sessionStorage.getItem(SESSION_EMPLOYEE_ID)
      : null;
  };

  const formatMonthYear = (date) => {
    const d = new Date(date);
    const moptions = { day: '2-digit', month: 'short', year: 'numeric' };
    return d?.toLocaleDateString('en-US', moptions);
  };

  const [employeeId, setEmployeeId] = useState(getEmployeeId());
  const [displayReviews, setDisplayReviews] = useState([]);
  const [leaveReviewData, setLeaveReviewData] = useState([]);

  //States
  const [data, setData] = useState();
  const [pending, setPending] = useState(0);
  const [upcoming, setUpcoming] = useState(0);
  const [leaveType, setLeaveType] = useState([]);
  const [edit, setEdit] = useState();
  const [entitlement, setEntitlement] = useState([]);

  const [collegeData, setCollegeData] = useState(getCollegeData());

  const getDashboardData = async () => {

    const config = {
      method: "get",
      url: emp_id == 502 ? `${MEDICAL_SUPERADMIN_DASHBOARD}` : `${SUPERADMIN_DASHBOARD}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("dashboard data - ", res.data);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        // toast.error(err.response.data.message);
      });
  }

  const [allData, setAllData] = useState([]);
  const getAlldata = async () => {
    const config = {
      method: "get",
      url: `${ANNOUNCEMENTS}?type=announcement`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);

        setAllData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    getAlldata();
  }, [])

  const [notifications, setNotifications] = useState([
    'University wins national championship in robotics competition.',
    'New scholarship program announced for underprivileged students.',
    'Research team discovers new species of marine life in the campus lake.',
    'University ranked in top 10 for computer science programs by prestigious magazine.',
    'Renowned author to give keynote speech at the annual literature festival.',
    'University receives grant for new research facility where students get various opportunities.',
    'Alumni association to host networking event for recent graduates.',
    'Faculty member awarded prestigious fellowship for groundbreaking research.',
    'University to host international conference on climate change.',
    'Student-run charity event raises record amount for local homeless shelter.',
  ])

  const [events, setEvents] = useState([
    'Guest lecture on Machine Learning by Dr. Smith - May 15, 2024, 10:00 AM',
    'Workshop on Web Development and Andriod development - May 17, 2024, 2:00 PM',
    'University womens coding ninja hackathon - May 20-21, 2024',
    'Career fair to find jobs which suits best for you - May 25, 2024, 9:00 AM - 5:00 PM',
    'Seminar on Renewable Energy and its importance - May 28, 2024, 11:00 AM',
    'Panel discussion on Artificial Intelligence - June 2, 2024, 3:00 PM',
    'Coding competition conducted by Google - June 5, 2024, 9:00 AM - 12:00 PM',
    'Seminar on Cybersecurity and cloud computing - June 10, 2024, 11:00 AM',
    'Workshop on Mobile App Development - June 15, 2024, 2:00 PM',
    'Networking event to learn firewalls and their importance - June 20, 2024, 4:00 PM - 6:00 PM',
  ])
  const [eventdata, seteventData] = useState()

  const geteventdata = async () => {
    try {
      const config = {
        method: "get",
        url: `${ADD_EVENT}?type=event`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        },
      };

      const res = await axios(config);
      seteventData(res.data.data);
    } catch (err) {
      console.error(err);
      toast.error("Something Went Wrong");
    }
  };
  useEffect(() => {
    geteventdata()
  }, [])

  const optionsEmployee1 = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
    },
    stroke: {
      width: [1, 1],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1, 2, 3]
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
      },
    },
    // labels: ['ENG', 'MED', 'AYU', 'LAW', 'NUR', 'ART', 'COM',
    //   'ITC'
    // ],
    labels: data?.employee_data?.map((s) => s.code) || [],
    markers: {
      size: 0
    },
    yaxis: {
      title: {
        text: 'Staff Count',
      },
      min: 0
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;

        }
      }
    },
    colors: ['#1B2850', 'green', 'red'],
  };

  const optionsStudent = {
    //   chart: {
    //   height: 350,
    //   type: 'bar',
    //   stacked: false,
    // },
    stroke: {
      width: [1, 1],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        dataLabels: {
          position: "top", // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      position: "top",
    },
    fill: {
      gradient: {
        // inverseColors: false,
        type: "vertical",
        stops: [0, 100, 100, 100]
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "45%",
      },
    },
    // labels: ['ENG', 'MED', 'AYU', 'LAW', 'NUR', 'ART', 'COM',
    //   'ITC'
    // ],
    labels: data?.student_data?.map((s) => s.code) || [],
    markers: {
      size: 0
    },
    yaxis: {
      title: {
        text: 'Student Count',
      },
      min: 0
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        }
      }
    },
  };

  const seriesStudents = [{
    name: "Male",
    data: data?.student_data?.map((s) => s.male_count) || [],
    // data: [367, 250, 446, 389, 150, 677, 520, 60],
    color: "#364277",
    type: "bar"
  },
  {
    name: "Female",
    data: data?.student_data?.map((s) => s.female_count) || [],
    // data: [230, 170, 647, 167, 450, 280, 400, 460],
    color: "#FF9F43",
    type: "bar"
  },]

  const seriesEmployee1 = [{
    name: 'Total Staff',
    type: 'column',
    data: data?.employee_data?.map((s) => s.employee_count) || [],
    color: "#4D44B5"
  }, {
    name: 'Present',
    type: 'area',
    data: data?.employee_data?.map((s) => s.present) || [],
    color: "green"
  }, {
    name: 'Absent',
    type: 'line',
    data: data?.employee_data?.map((s) => s.absent) || [],
    color: "red"
  }]

  const optionsFee = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "5%",
      },
    },
    // colors: ["#364277", "#FF9F43"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
      width: 2, // thin line
      color: "#FF9F43"
    },
    markers: {
      size: 1
    },
    xaxis: {
      // categories: ['ENG', 'MED', 'AYU', 'LAW', 'NUR', 'ART', 'COM',
      // 'ITC'
      // ],
      categories: data?.employee_data?.map((s) => s.code) || [],
      title: {
        text: 'Faculty'
      }
    },
    yaxis: {
      title: {
        text: 'Amount (₹)'
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    }
  };

  const seriesFee = [
    {
      name: "Amount",
      data: [280000, 200000, 330000, 460000, 240000, 320000, 330000],
      color: "#364277",
      // "#FF9F43", "#364277",
    },
  ];

  const optionsFee2 = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "5%",
      },
    },
    colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
      width: 2, // thin line
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: ['ENG', 'MED', 'AYU', 'LAW', 'NUR', 'ART', 'COM',
        'ITC'
      ],
      title: {
        text: 'Faculty'
      }
    },
    yaxis: {
      title: {
        text: 'Amount (₹)'
      },
      min: 5,
      max: 40
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  };

  const seriesFee2 = [
    {
      name: "Amount",
      data: [28, 29, 33, 36, 32, 32, 33],
      color: "#1B2850",
    },
    {
      name: "Amount",
      data: [13, 19, 10, 25, 19, 15, 18],
      color: "#1B2850",
    },
  ];

  const leaveReviews = [
    {
      sl_no: 1,
      photo: "https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
      name: "Siddaraj Hubballi",
      department: "Computer Science",
      leave: "Casual",
      fromDate: "10-02-2024",
      toDate: "12-02-2024",
      days: 3
    },
    {
      sl_no: 2,
      photo: "",
      name: "Abubakar Shaik",
      department: "Artifical Intelligence",
      leave: "LWP",
      fromDate: "11-02-2024",
      toDate: "12-02-2024",
      days: 2
    },
  ]

  const leaveReviewColumns = [
    {
      title: "Sl No.",
      dataIndex: "sl_no",
      key: "sl_no",

    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, data) => {
        return (
          <>
            <div className="row">

              <p >{data?.name}</p>
            </div>
          </>
        );
      },
    },
    {
      title: "Leave",
      dataIndex: "leave",
      key: "leave",

    },
    {
      title: "Submission Date",
      dataIndex: "submissionDate",
      key: "submissionDate",
      render: (_, data) => {
        return (
          <>
            <p>{data?.submissionDate}</p>
          </>
        )
      }
    },
    {
      title: "From Date",
      dataIndex: "fromDate",
      key: "date",
      render: (_, data) => {
        return (
          <>
            <p>{data?.fromDate}</p>
          </>
        )
      }
    },
    {
      title: "To Date",
      dataIndex: "toDate",
      key: "date",
      render: (_, data) => {
        return (
          <>
            <p>{data?.toDate}</p>
          </>
        )
      }
    },
    {
      title: "Session",
      dataIndex: "session",
      key: "session",
      render: (_, data) => {
        return (
          <>
            <p>{data?.session}</p>
          </>
        );
      },
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
      render: (_, data) => {
        return (
          <>
            <p>{data?.days}</p>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, data) => {
        return (
          <>
            <span className={`badge badge-${data?.status == "APPROVED" ? "success" : data?.status == "PENDING" ? "warning" : data?.status == "REJECTED" ? "danger" : "primary"} p-2`}>{data?.status}</span>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, data) => {
        return (
          <>
            <button
              className="mb-2 btn btn-sm btn-nex"
              type="button"
              class="btn btn-nex"
              data-toggle="modal"
              data-target="#ModalLeaveReview"
              onClick={() => {
                console.log(data)
                setEdit(data);
                getEntitlement(data);
              }}
            >
              <i
                className="fa fa-edit"
                aria-hidden="true"
                style={{ cursor: "pointer", fontSize: "13px" }}
              />
            </button>
          </>
        );
      },
    },
  ];

  const procurements = [
    {
      sl_no: 1,
      name: "Siddaraj Hubballi",
      department: "Computer Science",
      supplier: "Nexenstial",
      poNo: "PUCH011",
      poDate: "10-05-2024",
      status: "PENDING",
      approvedBy: "-",
    },
    {
      sl_no: 2,
      name: "Abubakar Shaikh",
      department: "Artificial Intelligence",
      supplier: "Nexenstial",
      poNo: "PUCH012",
      poDate: "13-05-2024",
      status: "APPROVED",
      approvedBy: "Manjunath",
    },
  ]

  const procurementColumns = [
    {
      title: "Sl No.",
      dataIndex: "sl_no",
      key: "sl_no",

    },
    {
      title: "Po No.",
      dataIndex: "poNo",
      key: "poNo",
      render: (_, data) => {
        return (
          <>
            <div className="row">

              <p >{data?.poNo}</p>
            </div>
          </>
        );
      },
    },
    {
      title: "Po Date",
      dataIndex: "poDate",
      key: "poDate",

    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",

    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",

    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, data) => {
        return (
          <>
            <span className={`badge badge-${data?.status == "APPROVED" ? "success" : data?.status == "PENDING" ? "warning" : "danger"} p-2`}>{data?.status}</span>
          </>
        );
      },
    },
    {
      title: "Approved by",
      dataIndex: "toDate",
      key: "date",
      render: (_, data) => {
        return (
          <>
            <p>{data?.approvedBy}</p>
          </>
        )
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, data) => {
        return (
          <>
            <button className="mb-2 btn btn-sm btn-nex">
              <i
                className="fa fa-eye"
                aria-hidden="true"
                style={{ cursor: "pointer", fontSize: "13px" }}
              />
            </button>
          </>
        );
      },
    },
  ];

  const [pieChartData] = useState({
    series: [5000, 3000],
    options: {
      chart: {
        width: 380,
        height: 350,
        type: 'pie',
      },
      labels: ['Income', "Expenses"],
      legend: {
        position: 'bottom',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
              height: 350,
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ],
      colors: ["#364277", "#FF9F43"]
    }
  });

  const taskColumns = [
    {
      title: "Sl No.",
      dataIndex: "sl_no",
      key: "sl_no",
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "Assigned Professors",
      dataIndex: "employee",
      key: "employee",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, data) => {
        return (
          <>
            <span className={`badge badge-${data?.status == "Done" ? "success" : data?.status == "Pending" ? "warning" : "danger"} p-2`}>{data?.status}</span>
          </>
        );
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      render: (_, data) => {
        return (
          <>
            <div className="progress">
              <div
                className="progress-bar progress-bar-warning progress-bar-striped active"
                role="progressbar"
                aria-valuenow={data?.progress}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: "80%" }}
              >
                {" "}
                <span className="sr-only">{ }</span>{" "}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const [assignTasksData, setAssignTasksData] = useState([
    {
      sl_no: 1,
      task: "Preparation for cricket team",
      employee: "Kenny Josh",
      status: "Done",
      progress: "100% Complete"
    },
    {
      sl_no: 2,
      task: "Annual function preparation",
      employee: "Mark",
      status: "Pending",
      progress: "70% Complete"
    },
    {
      sl_no: 4,
      task: "Final year exam paper set",
      employee: "Felix",
      status: "Suspended",
      progress: "50% Complete"
    },
    {
      sl_no: 5,
      task: "Placement report",
      employee: "Beryl",
      status: "Done",
      progress: "100% Complete"
    },
    {
      sl_no: 6,
      task: "Fees collection report",
      employee: "Jayesh",
      status: "Done",
      progress: "100% Complete"
    },
    {
      sl_no: 7,
      task: "Library book status",
      employee: "Sharma",
      status: "Suspended",
      progress: "20% Complete"
    },
    {
      sl_no: 8,
      task: "Exam Paper set",
      employee: "John Deo",
      status: "Pending",
      progress: ""
    }
  ])

  const getLeaveData = async () => {
    // setLoading(1);
    const config = {
      method: "get",
      url: LEAVE_LEAVE_TYPE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLeaveType(res.data.data);
        // console.log("leave types - ", res.data.data);
      })
      .catch((err) => {
        // setLoading(0);
        console.log(err);
      });
  };

  const getEntitlement = async (i) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${LEAVE_ENTITLEMENT}?employee_id=${i?.employee_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("entitlements - ", res.data.data);
        setEntitlement(res.data.data);
        console.log(res);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };

  const getLeaveReviewsData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${LEAVE_REVIEW_EMPLOYEE_SQL}/${employeeId}?college_id=${collegeId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then(async (res) => {
        // console.log("review applications - ", res.data.data);
        setLoading(0);
        // console.log("leave applications - ", res.data.data);
        let tempData = res.data.data;
        let finalData = [];
        for (let i in tempData) {
          let approvals = [];
          let config1 = {
            method: "get",
            url: `${LEAVE_APPROVER_APPLICATION}/${tempData[i].id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
          };
          setLoading(1);
          await axios(config1)
            .then((res) => {
              setLoading(0);
              approvals = res.data.data;
              let num = approvals.find(
                (s) => s.employee_id == employeeId
              )?.approver_num;
              let permission = true;
              let before_approver = "";
              for (let k in approvals) {
                if (
                  approvals[k].approver_num < num &&
                  approvals[k].status == "ACTIVE"
                ) {
                  permission = false;
                  before_approver = `${approvals[k].first_name} ${approvals[k].last_name}`;
                  break;
                }
              }
              let obj = {
                ...tempData[i],
                approvals,
                permission,
                before_approver,
              };
              finalData.push(obj);
            })
            .catch((err) => {
              setLoading(0);
              console.log(err);
              // toast.error(err.response.data.message);
            });
        }
        console.log("leave review data - ", finalData);
        setLeaveReviewData(finalData);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        // toast.error("Error while loading");
      });
  };

  // useEffect(() => {
  //   if (collegeId) getData();
  // }, [sessionStorage.getItem(SESSION_ROLE), collegeId]);

  useEffect(() => {
    setEmployeeId(getEmployeeId());
  }, [sessionStorage.getItem(SESSION_EMPLOYEE_ID)]);

  useState(() => {
    setCollegeData(getCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  useEffect(() => {
    getLeaveData();
    getLeaveReviewsData();
    getDashboardData();
  }, []);

  useEffect(() => {
    if (leaveReviewData?.length > 0) {
      let temp = [];
      let cnt = 1;
      for (let i of leaveReviewData) {
        let obj = {
          ...i,
          sl_no: cnt,
          name: employee?.find((j) => j.id === i.employee_id)
            ?.first_name +
            " " +
            employee.find((j) => j.id === i.employee_id)
              ?.last_name,
          leave: "Casual",
          submissionDate: i.submission_date?.split("T")[0],
          sessionName: i.session == "full_day" ? "Full Day" : `Half Day (${i.halfDaySession == "1" ? "Morning" : "Evening"})`,
          fromDate: i.from_date?.split("T")[0],
          toDate: i.from_date?.split("T")[0],
          days: i?.number_of_days,
          status: i?.status
        };
        temp.push(obj);
      }
      setDisplayReviews(temp);
    }
    else {
      setDisplayReviews([]);
    }
  }, [leaveReviewData]);
  return (
    <div className="DashboardHR">
      <ModalLeaveReview
        empId={employeeId}
        data={edit}
        reloadData={(d) => getLeaveReviewsData()}
        setLoading={setLoading}
        entitlement={entitlement}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">

              </div>
            </div>

            <div className="container-fluid">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">
                  {/* Hello , {sessionStorage.getItem(SESSION_ROLE)} */}
                  Dashboard
                </h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="card" style={{ borderRadius: "10px", backgroundColor: "#364277", color: "white" }}>
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Staff
                          </p>
                          <h4 className="mb-0" style={{ color: "white" }}><b>{data?.employee_data?.reduce((acc, curr) => acc + curr?.employee_count, 0)}</b></h4>
                        </div>
                        <div style={{ color: "white" }}>
                          <i className="ri-group-line font-size-48" style={{ fontSize: "50px" }}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card" style={{ borderRadius: "10px", backgroundColor: "#FF9F43", color: "white" }}>
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Teaching Staff
                          </p>
                          <h4 className="mb-0" style={{ color: "white" }}><b>{data?.employee_data?.reduce((acc, curr) => acc + parseInt(curr?.teaching_staff_count), 0)}</b></h4>
                        </div>
                        <div style={{ color: "white" }}>
                          <i className="ri-user-2-line font-size-48" style={{ fontSize: "50px" }}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card" style={{ borderRadius: "10px", backgroundColor: "#364277", color: "white" }}>
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Non Teaching Staff
                          </p>
                          <h4 className="mb-0" style={{ color: "white" }}><b>{data?.employee_data?.reduce((acc, curr) => acc + parseInt(curr?.non_teaching_staff_count), 0)}</b></h4>
                        </div>
                        <div style={{ color: "white" }}>
                          <i className="ri-user-3-line font-size-48" style={{ fontSize: "50px" }}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card" style={{ borderRadius: "10px", backgroundColor: "#FF9F43", color: "white" }}>
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Students
                          </p>
                          <h4 className="mb-0" style={{ color: "white" }}><b>{data?.student_data?.reduce((acc, curr) => acc + parseInt(curr?.male_count) + parseInt(curr?.female_count), 0)}</b></h4>
                        </div>
                        <div style={{ color: "white" }}>
                          <i className="ri-team-line font-size-48" style={{ fontSize: "50px" }}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">

                <div className="col-md-9">
                  <div className="row">
                    <div className="col-6">
                      <div className="card ">
                        <div className="card-body">
                          <h4 className="card-title mb-4">{emp_id == 502 ? "Department wise Staff Attendance" : "Faculty wise Staff Attendance"}</h4>
                          <div id="chart">
                            <Chart options={optionsEmployee1} series={seriesEmployee1} type="line" height={350} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card ">
                        <div className="card-body">
                          <h4 className="card-title mb-4">Student Overview</h4>
                          <div id="chart">
                            <Chart options={optionsStudent} series={seriesStudents} type="line" height={350} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card ">
                        <div className="card-body">
                          <h4 className="card-title mb-4">{emp_id == 502 ? "Department wise Collection Report" : "Faculty wise Collection Report"}</h4>
                          <div id="chart">
                            <Chart options={optionsFee} series={seriesFee} type="line" height={350} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="card ">
                        <div className="card-body">
                          <h4 className="card-title mb-4">{emp_id == 502 ? "Department wise Collection Report" : "Faculty wise Collection Report"}</h4>
                          <div id="chart">
                            <ReactApexChart options={pieChartData.options} series={pieChartData.series} type="pie" width={478} />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-6">
                    <div className="card ">
                      <div className="card-body">
                        <h4 className="card-title mb-4">Faculty wise Collection Report</h4>
                        <div id="chart">
                          <Chart options={optionsFee2} series={seriesFee2} type="line" height={350} />
                        </div>
                      </div>
                    </div>
                  </div> */}


                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Announcements</h4>
                      <div style={{ overflowY: "auto", height: "310px" }}>
                        <List
                          size="small"
                          bordered
                          dataSource={allData?.filter((s) => s.type == "announcement")?.map((i, k) => i)}
                          renderItem={(item, index) =>
                            <List.Item>
                              <>
                                <i className="ri-notification-2-line mr-2 px-2 py-1 text-white" style={{ backgroundColor: colors[index % colors.length], borderRadius: "20%" }}></i>
                                <Link to="" style={{ color: "black" }}>{item?.title?.length > 35 ? `${item?.title?.substring(0, 35)}...` : item?.title} <span style={{ color: "gray" }}>- {formatMonthYear(item?.to_date)}</span></Link>
                              </>
                            </List.Item>}

                        />
                      </div>
                      <div className="row justify-content-center mt-2">
                        <div className="col-md-6">
                          <button
                            type="button"
                            className="btn btn-nex mt-2 btn- mw-100 w-100"
                            onClick={() => {
                              navigate(ROUTES.Registar.Communicate.Announcements);
                            }}
                          >
                            View All
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="card ">
                      <div className="card-body">
                        <h4 className="card-title mb-4">Upcoming Events</h4>
                        <div style={{ overflowY: "auto", height: "310px" }}>
                          <List
                            size="small"
                            bordered
                            dataSource={eventdata?.map((i, k) => i)}
                            renderItem={(item, index) =>
                              <List.Item>
                                <>
                                  <i className="ri-calendar-event-line mr-2 px-2 py-1 text-white" style={{ backgroundColor: colors[index % colors.length], borderRadius: "20%" }}></i>
                                  {/* <img
                                src={"https://cdn2.iconfinder.com/data/icons/admin-tools-2/25/image2-512.png"}
                                style={{width: "30px", height: "30px", borderRadius:"10%"}}
                                className="mr-2"
                                alt="https://cdn2.iconfinder.com/data/icons/admin-tools-2/25/image2-512.png"
                              /> */}
                                  <Link to="" style={{ color: "black" }}>{item?.title?.length > 75 ? `${item.substring(0, 75)}...` : item?.title} <span style={{ color: "gray" }}>- {formatMonthYear(item?.to_date)}</span></Link>
                                </>
                              </List.Item>}

                          />
                        </div>
                        <div className="row justify-content-center mt-2">
                          <div className="col-md-6">
                            <button
                              type="button"
                              className="btn btn-nex mt-2 btn- mw-100 w-100"
                              onClick={() => {
                                navigate(ROUTES.Registar.Communicate.UpcomingEvents);
                              }}
                            >View All</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>




                <div className="col-md-12">
                  <div className="card ">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Staff Leave Application Approvals</h4>
                      <Table columns={leaveReviewColumns} dataSource={displayReviews} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card ">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Procurement Application Approvals</h4>
                      <Table columns={procurementColumns} dataSource={procurements} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card p-3 w-100">
                    <h6 className="my-3 ml-2">Assign Task</h6>
                    <Table columns={taskColumns} dataSource={assignTasksData} />
                  </div>

                </div>
                <div className="col-md-6 mb-3">
                  <div className="card p-3 w-100">
                    <h3 className="my-3">Event Calendar</h3>
                    <Calender setLoading={setLoading} collegeId={collegeId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardRegistrar2;
