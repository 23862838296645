import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "./BasicHome.scss";
import { ToastContainer } from "react-toastify";
import { ROUTES } from "../../../Router/routerConfig";
import { HEADER } from "../AdmissionStorageConstats";
import { UPDATEADMISSIONENQUIRY } from "../AdmissionApiConstants";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";

function BasicHome({ setLoading, collegeId }) {
  const navigate = useNavigate();
  const locate = useLocation();

  var program = JSON.parse(localStorage.getItem("PROGRAM"));
  console.log("program -", program);
  const [college, setCollege] = useState("");
  const [department, setDepartment] = useState("");
  const [session, setSession] = useState("");
  const [prog, setProg] = useState([]);
  const [dept, setDept] = useState("");
  const [facul, setFacul] = useState("");
  console.log("prog -", prog);
  console.log("facul -", facul);
  const getCollegeData = () => {
    return localStorage.getItem("COLLEGE")
      ? JSON.parse(localStorage.getItem("COLLEGE"))
      : null;
  };

  const getData = async () => {
    await setDepartment(() => JSON.parse(localStorage.getItem("DEPARTMENT")));
    await setCollege(getCollegeData());
  };

  console.log("locate.state -", locate?.state);
  sessionStorage.setItem("enquiry_id", locate?.state?.id);

  const selectRoute = (specialization, program, department) => {
    switch (String(specialization) + "|" + program) {
      case "1111010|04":
        navigate(ROUTES.AdmissionPortal.Admission.bed);
        sessionStorage.setItem(HEADER, "BED Application Form");
        break;

      case "1111008|04":
        navigate(ROUTES.AdmissionPortal.Admission.UGAdmissionPharmacy);
        break;

      case "1111008|05":
        navigate(ROUTES.AdmissionPortal.Admission.PGAdmissionPharmacy);
        break;

      case "1111003|04":
        switch (department) {
          case 35:
            navigate(ROUTES.AdmissionPortal.Admission.bscNursing);
            sessionStorage.setItem(HEADER, "BSC Nursing Application Form");
            break;
          case 36:
            navigate(ROUTES.AdmissionPortal.Admission.AdmissionPostBasicBscNur);
            sessionStorage.setItem(HEADER, "Post Basic Bsc Application Form");
            break;
          default:
            navigate(ROUTES.AdmissionPortal.Admission.AdmissionPostBasicBscNur);
            sessionStorage.setItem(HEADER, "Post Basic Bsc Application Form");
            break;
        }
        break;

      case "1111011|04":
        navigate(ROUTES.AdmissionPortal.Admission.bsc);
        sessionStorage.setItem(HEADER, "BSC Application Form");
        break;

      case "1111005|04":
      case "1111005|05":
        navigate(ROUTES.AdmissionPortal.Admission.commerce);
        sessionStorage.setItem(HEADER, "Commerce Application Form");
        break;

      case "1111003|02":
        navigate(ROUTES.AdmissionPortal.Admission.gnm);
        sessionStorage.setItem(HEADER, "GNM Application Form");
        break;

      case "1111002|04":
      case "1111002|05":
        console.log("here");
        navigate(ROUTES.AdmissionPortal.Admission.law);
        sessionStorage.setItem(HEADER, "LAW Application Form");
        break;

      case "1111003|05":
        navigate(ROUTES.AdmissionPortal.Admission.AdmissionMscNursing);
        sessionStorage.setItem(HEADER, "MSC Nursing Application Form");
        break;

      case "1111011|05":
        navigate(ROUTES.AdmissionPortal.Admission.msc);
        sessionStorage.setItem(HEADER, "MSC Application Form");
        break;

      // case "1111007|04":
      //   navigate(ROUTES.AdmissionPortal.Admission.homeopathy);
      //   sessionStorage.setItem(HEADER, "B.H.M.S Application Form");
      //   break;
      case "1111007|04":
        navigate(ROUTES.AdmissionPortal.Admission.ayurveda);
        sessionStorage.setItem(HEADER, "B.A.M.S Application Form");
        break;
      case "1111007|05":
        navigate(ROUTES.AdmissionPortal.Admission.ayurvedaPG);
        sessionStorage.setItem(HEADER, "PG Ayurveda Application Form");
        break;
    }
  };

  console.log("dept -", dept);

  const handleSubmit = async () => {
    let obj = {
      college_id: facul,
      department_id: dept,
      session: session,
      program_id: prog,
      temp_usn: sessionStorage.getItem("temp_usn"),
    };

    console.log("obj -", obj);
    console.log("locate.state -", locate.state);
    setLoading(1);
    const config = {
      method: "put",
      url: `${UPDATEADMISSIONENQUIRY}/${locate.state?.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("user_auth")}`,
      },
      data: obj,
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log(res);
        sessionStorage.setItem("temp_usn", res.data.data);
        sessionStorage.setItem("college", facul);
        sessionStorage.setItem("program", prog);
        sessionStorage.setItem("department", dept);
        sessionStorage.setItem("application_no", res.data.application_no);
        toast.success("Successfully Updated Details");
        selectRoute(facul, prog, department);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const [selectedBox, setSelectedBox] = useState(null);

  const selectBox = (index) => {
    setSelectedBox(index);
  };

  return (
    <div className="container">
      <ToastContainer autoClose={2000} />
      <div>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="text-center">Choose Program</h3>
                      <div className="container mt-5">
                        <div className="row">
                          {program?.length > 0 &&
                            program?.map((item, index) => (
                              <div key={index} className="col-md-3 mb-3">
                                {/* <div
                                  className={`border p-3 ${
                                    selectedBox === index
                                      ? "bg-danger text-white cursor-pointer"
                                      : "cursor-pointer"
                                  }`}
                                  onClick={() => {
                                    setProg(item.id);
                                    if (item.id != prog) {
                                      selectBox(index);
                                    } else {
                                      selectBox("");
                                    }
                                  }}
                                >
                                  <p>{item?.name}</p>
                                </div> */}
                                <div>
                                  <button
                                    className={`btn btn-outline-danger p-3 ${
                                      item.id === prog
                                        ? "text-white bg-danger cursor-pointer"
                                        : "cursor-pointer"
                                    }`}
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                      setProg(item.id);
                                      if (item.id != prog) {
                                        selectBox(index);
                                      } else {
                                        selectBox("");
                                      }
                                    }}
                                  >
                                    <p
                                      style={{
                                        color:
                                          item.id === prog ? "white" : "black",
                                      }}
                                    >
                                      {item?.name}
                                    </p>
                                  </button>
                                </div>
                              </div>
                            ))}
                        </div>

                        <div className="row mt-3">
                          <div className="col-md-12">
                            <h3>Select Academic Session</h3>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setSession(e.target.value);
                              }}
                            >
                              <option value="">Select Session</option>
                              {sessionOpt?.map((item, key) => {
                                return (
                                  <option value={item?.id}>{item?.name}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-md-12">
                            <h3>Select College</h3>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setFacul(e.target.value);
                              }}
                            >
                              <option value="">Select College</option>
                              {college &&
                                college?.map((item, key) => {
                                  return (
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-md-12">
                            <h3>Select Department</h3>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setDept(e.target.value);
                              }}
                            >
                              <option value="">Select Department</option>
                              {department &&
                                department
                                  ?.filter((s) => s.college_id == facul)
                                  ?.map((item, key) => {
                                    return (
                                      <option value={item?.id}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                            </select>
                          </div>
                        </div>

                        <div className="d-flex mt-4">
                          <button
                            className="btn btn-success ml-auto"
                            type="submit"
                            name="submit"
                            onClick={handleSubmit}
                          >
                            Save and Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicHome;
