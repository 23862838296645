import React, { useEffect } from "react";
import Calender from "../../../Components/Calender/Calender";
import "./DashboardEmployee.scss";
import Chart from 'react-apexcharts';
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import { List } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "../../Dashboard/Dashboard.css";
import { ROUTES } from "../../../Router/routerConfig";
import { ADD_EVENT, ANNOUNCEMENTS, EMPLOYEE, EMPLOYEE_DETAILS_BASIC, MEDICAL_SUPERADMIN_DASHBOARD, SUPERADMIN_DASHBOARD } from "../../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import { SESSION_EMPLOYEE_ID } from "../../../utils/sessionStorageContants";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";


function DashboardEmployee({ setLoading }) {

  const navigate = useNavigate();

  const [user, setUser] = useState();
  const [userId, setUserId] = useState();

  const getEmployeeId = () =>
    sessionStorage.getItem(SESSION_EMPLOYEE_ID)
      ? sessionStorage.getItem(SESSION_EMPLOYEE_ID)
      : null;

  const id = getEmployeeId();

  const [greeting, setGreeting] = useState("");
  const [announcementTab, setAnnouncementTab] = useState("announcements");

  const [links, setLinks] = useState([
    {
      name: "Class Attendance",
      link: "",
    },
    {
      name: "Lab Attendance",
      link: "",
    },
    {
      name: "My Timetable",
      link: "",
    },
    {
      name: "Class Timetable",
      link: "",
    },
    {
      name: "Student Details",
      link: "employee/studentDetails",
    },

    {
      name: "Leave Application",
      link: "",
    },
    {
      name: "Review Applications",
      link: "",
    },
  ]);

  const getEmpData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE_DETAILS_BASIC}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        // toast.success("Data Success");
        setUser(res.data.data);
        setUserId(res.data.data.user_id);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setLoading(0);
      });
  };

  useState(() => {
    const currentTime = new Date().getHours();
    let greetingMessage;

    if (currentTime < 12) {
      greetingMessage = "Good Morning";
    } else if (currentTime < 18) {
      greetingMessage = "Good Afternoon";
    } else {
      greetingMessage = "Good Evening";
    }

    setGreeting(greetingMessage);
  }, []);

  const optionsEmployee1 = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
    },
    stroke: {
      width: [1, 1],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1, 2, 3]
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
      },
    },
    labels: ['ENG', 'MED', 'AYU', 'LAW', 'NUR', 'ART', 'COM',
      'ITC'
    ],
    markers: {
      size: 0
    },
    yaxis: {
      title: {
        text: 'Student Count',
      },
      min: 0
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;

        }
      }
    },
    colors: ['#1B2850', 'green', 'red'],
  };
  const colors = ["#364277", "#FF9F43"];

  var emp_id = sessionStorage.getItem("employee_id");
  const [data, setData] = useState();
  console.log("DATA", data)
  const getDashboardData = async () => {

    const config = {
      method: "get",
      url: emp_id == 502 ? `${MEDICAL_SUPERADMIN_DASHBOARD}` : `${SUPERADMIN_DASHBOARD}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("dashboard data - ", res.data);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        // toast.error(err.response.data.message);
      });
  }
  useEffect(() => {

    getDashboardData();
  }, []);
  const [allData, setAllData] = useState([]);
  const getAlldata = async () => {
    const config = {
      method: "get",
      url: `${ANNOUNCEMENTS}?type=announcement`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);

        setAllData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    getAlldata();
  }, [])
  const [notifications, setNotifications] = useState([
    'University wins national championship in robotics competition.',
    'New scholarship program announced for underprivileged students. New scholarship program announced for underprivileged students.',
    'Research team discovers new species of marine life in the campus lake.',
    'University ranked in top 10 for computer science programs by prestigious magazine.',
    'Renowned author to give keynote speech at the annual literature festival.',
    'University receives grant for new research facility where students get various opportunities.',
    'Alumni association to host networking event for recent graduates.',
    'Faculty member awarded prestigious fellowship for groundbreaking research.',
    'University to host international conference on climate change.',
    'Student-run charity event raises record amount for local homeless shelter.',
  ])

  const [events, setEvents] = useState([
    'Guest lecture on Machine Learning by Dr. Smith and Mr. Abubakar Shaikh',
    'Workshop on Web Development and Andriod development',
    'University womens coding ninja hackathon, open for all departments',
    'Career fair and interviews to find jobs which suits best for you',
    'Seminar on Renewable Energy and its importance',
    'Panel discussion on Artificial Intelligence and Machine Learning',
    'Coding competition conducted by Google and Microsoft',
    'Seminar on Cybersecurity and cloud computing, attend and get a certificate',
    'Workshop on Mobile App Development and Web development',
    'Networking event to learn firewalls and their importance',
  ]);

  const [eventdata, seteventData] = useState()
  let collegeids = sessionStorage.getItem("college_id")
  const geteventdata = async () => {
    try {
      const config = {
        method: "get",
        url: `${ADD_EVENT}?type=event`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        },
      };

      const res = await axios(config);
      seteventData(res.data.data);
    } catch (err) {
      console.error(err);
      toast.error("Something Went Wrong");
    }
  };
  useEffect(() => {
    geteventdata()
  }, [])

  const getColleges = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };
  const [collegeOpt, setCollegeOpt] = useState(getColleges);

  useEffect(() => {
    setCollegeOpt(getColleges);
  }, [localStorage.getItem(LOCAL_COLLEGE)]);
  const [employeeId, setEmployeeId] = useState(
    sessionStorage.getItem(SESSION_EMPLOYEE_ID)
  );
  const [empColleges, setEmpColleges] = useState([])

  const [empDetails, setEmpDetails] = useState();

  const getEmployee = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${employeeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        let clgs = JSON.parse(res.data.data['multi_clg_id']);
        setEmpColleges(collegeOpt.filter(item => clgs?.includes(item.id)));
        console.log('emp details - ', res.data.data);
        setEmpDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (employeeId) getEmployee();
  }, [employeeId]);




  const mappedData = allData?.map(item => {
    let staffData = [];
    try {
      staffData = item.staff ? JSON.parse(item.staff) : [];
    } catch (e) {
      console.error("Error parsing staff data for item:", item.id, e);
    }

    // Ensure staffData is an array
    if (!Array.isArray(staffData)) {
      staffData = [];
    }

    return { ...item, staffData };
  });

  let collegeIdss = sessionStorage.getItem("college_id")
  const filteredData = mappedData?.filter(item =>
    item.staffData.some(data => {

      const collegeIdArray = Array.isArray(data.college_id) ? data.college_id : [];
      const isCollegeIdAll = collegeIdArray.some(college => college.value === "All");
      const isCollegeIdMatch = collegeIdArray.some(college => collegeIdss.includes(parseInt(college.value)));
      return isCollegeIdAll || isCollegeIdMatch;
    })
  );











  // console.log("Filtered data:", filteredData);


  const seriesEmployee1 = [{
    name: 'Total Students',
    type: 'column',
    data: [44, 55, 41, 67, 22, 43, 21, 41],
    color: "#4D44B5"
  }, {
    name: 'Present',
    type: 'area',
    data: [23, 11, 22, 27, 13, 22, 37, 21],
    color: "green"
  }, {
    name: 'Absent',
    type: 'line',
    data: [30, 25, 36, 30, 45, 35, 64, 52],
    color: "red"
  }]

  //Chart Data 1
  const overviewChartOptions = {
    series: [0, 0, 0, 0, 0],
    labels: ["Ayurveda", "Management", "Engineering", "Law", "Arts"],
    chart: {
      width: 180,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100px",
            height: "100px",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  //Chart Data 2
  const overviewChartOptions2 = {
    series: [
      {
        name: "Male",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Female",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: [
          "Ayrveda",
          "Engineering",
          "Law",
          "Dental",
          "Management",
          "Arts",
          "Commerce",
        ],
      },
    },
  };
  const formatMonthYear = (date) => {
    const d = new Date(date);
    const moptions = { day: '2-digit', month: 'short', year: 'numeric' };
    return d?.toLocaleDateString('en-US', moptions);
  };
  return (
    <div className="DashboardEmployee">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 ml-3">
                    {greeting}, {sessionStorage.getItem("emp_name")}
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Home</li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-8">

                  <div className="row">
                    <div className="col-md-3">
                      <div className="card" style={{ borderRadius: "10px", backgroundColor: "#364277", color: "white" }}>
                        <div className="card-body" style={{}}>
                          <div className="media">
                            <div className="media-body overflow-hidden">
                              <p className="text-truncate font-size-14 mb-2">
                                {" "}
                                Total Students
                              </p>
                              <h4 className="mb-0" style={{ color: "white" }}><b>3800</b></h4>
                            </div>
                            <div style={{ color: "white" }}>
                              <i className="ri-team-line font-size-48" style={{ fontSize: "35px" }}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card" style={{ borderRadius: "10px", backgroundColor: "#FF9F43", color: "white" }}>
                        <div className="card-body" style={{}}>
                          <div className="media">
                            <div className="media-body overflow-hidden">
                              <p className="text-truncate font-size-14 mb-2">
                                {" "}
                                Present
                              </p>
                              <h4 className="mb-0" style={{ color: "white" }}><b>10</b></h4>
                            </div>
                            <div style={{ color: "white" }}>
                              <i className="ri-check-line font-size-48" style={{ fontSize: "35px", color: "white" }}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="card" style={{ borderRadius: "10px", backgroundColor: "#364277", color: "white" }}>
                        <div className="card-body" style={{}}>
                          <div className="media">
                            <div className="media-body overflow-hidden">
                              <p className="text-truncate font-size-14 mb-2">
                                {" "}
                                Absent
                              </p>
                              <h4 className="mb-0" style={{ color: "white" }}><b>2</b></h4>
                            </div>
                            <div style={{ color: "white" }}>
                              <i className="ri-close-line font-size-48" style={{ fontSize: "35px" }}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card" style={{ borderRadius: "10px", backgroundColor: "#FF9F43", color: "white" }}>
                        <div className="card-body" style={{}}>
                          <div className="media">
                            <div className="media-body overflow-hidden">
                              <p className="text-truncate font-size-14 mb-2">
                                {" "}
                                Upcoming Leaves
                              </p>
                              <h4 className="mb-0" style={{ color: "white" }}><b>4</b></h4>
                            </div>
                            <div style={{ color: "white" }}>
                              <i className="ri-calendar-event-line font-size-48" style={{ fontSize: "35px" }}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card ">
                    <div className="card-body p-2">
                      {/* <div className="row">
                        <h4 className="card-title mb-4">Announcements</h4>
                        </div>
                        <div style={{overflowY:"auto", height: "365px"}}>
                        <List
                          size="small"
                          bordered
                          dataSource={notifications}
                          renderItem={(item, index) => 
                          <List.Item>
                            <>
                              <i className="ri-notification-2-line mr-2 px-2 py-1 text-white" style={{backgroundColor:colors[index % colors.length], borderRadius: "20%"}}></i>  
                              <Link to="" style={{color: "black", fontSize:"13px"}}>{item.length > 75 ? `${item.substring(0, 75)}...` : item} <span className="float-right row" style={{ color: "gray" }}>May 15, 2024</span></Link>
                            </>
                          </List.Item>}
                          
                        />

                        </div> */}
                      <div className="announcementsSwitches mb-2">
                        <ul className="nav nav-tabs m-0">
                          <li className={`nav-item cursor-pointer`}>
                            <a
                              className={`nav-link ${announcementTab === "announcements" ? "active" : ""} nav-link`}
                              onClick={() => {
                                setAnnouncementTab("announcements");
                              }}
                            >
                              <b>Announcements</b>
                            </a>
                          </li>
                          <li className="nav-item cursor-pointer">
                            <a
                              className={`nav-link ${announcementTab === "events" ? "active" : ""} nav-link`}
                              onClick={() => {
                                setAnnouncementTab("events");
                              }}
                            >
                              <b>Events</b>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div style={{ overflowY: "auto", height: "250px" }}>
                        <List
                          size="small"
                          bordered
                          dataSource={announcementTab === "announcements" ? filteredData?.filter((s) => s.type === "announcement" && s.staff && JSON.parse(s.staff).length > 0)
                            ?.map((i, k) => i) : eventdata?.filter(event => event.college_id == collegeids)}
                          renderItem={(item, index) =>
                            <List.Item>
                              <div className="d-flex w-100">
                                <div>

                                  {announcementTab === "announcements"
                                    ? <i className="ri-notification-2-line mr-2 px-2 py-2 text-white" style={{ backgroundColor: colors[index % colors.length], borderRadius: "20%", fontSize: "80%" }}></i>
                                    : <i className="ri-calendar-event-line mr-2 px-2 py-1 text-white" style={{backgroundColor:colors[index % colors.length], borderRadius: "20%"}}></i>  

                                    // <img
                                    //   src="https://cdn2.iconfinder.com/data/icons/admin-tools-2/25/image2-512.png"
                                    //   style={{ width: "30px", height: "30px", borderRadius: "10%" }}
                                    //   className="mr-2"
                                    // />
                                  }
                                </div>

                                <div>
                                  <Link to="" style={{ color: "black" }}>
                                    {item?.title?.length > 11 ? `${item?.title?.substring(0, 100)}...` : item?.title}
                                  </Link>
                                </div>
                                <div
                                  className="text-end"
                                  style={{
                                    color: "gray",
                                    whiteSpace: "nowrap",
                                    marginLeft: "auto",
                                  }}
                                >
                                  - {formatMonthYear(item?.to_date)}
                                </div>



                              </div>
                            </List.Item>}

                        />

                      </div>
                      {announcementTab === "announcements" ? (
                        <div className="row justify-content-end">
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn btn-nex btn-mw-100 w-100"
                              onClick={() => {
                                navigate(ROUTES.Employee.Team.Announcement);
                              }}
                            >
                              View All
                            </button>
                          </div>
                        </div>) : (<></>)}
                    </div>

                  </div>

                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-2 mt-2 text-uppercase"><b>Today's Class</b></h4>
                      <hr className="p-1 m-1 mt-2" />
                      <div className="row m-1" style={{ overflowY: "auto", height: "360px" }}>
                        <div className="col-12">
                          <div className="card" style={{ cursor: "pointer", marginBottom: "10px" }}>
                            <div className="card-body" style={{ padding: "0.8rem" }}>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <i class="ri-artboard-line mr-2 px-2 py-2 text-white" style={{ fontSize: "20px", backgroundColor: "#364277", borderRadius: "20%" }}></i>
                                  <div>
                                    <h5>01ECS20501 - Engineering Mathematics</h5>
                                    <p>1st Year - 1st Sem - A</p>
                                  </div>
                                </div>
                                <div>
                                  <p>12:30 PM - 1:30 PM</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card" style={{ cursor: "pointer", marginBottom: "10px" }}>
                            <div className="card-body" style={{ padding: "0.8rem" }}>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <i class="ri-artboard-line mr-2 px-2 py-2 text-white" style={{ fontSize: "20px", backgroundColor: "#364277", borderRadius: "20%" }}></i>
                                  <div>
                                    <h5>01ECS20501 - Engineering Mathematics</h5>
                                    <p>1st Year - 1st Sem - A</p>
                                  </div>
                                </div>
                                <div>
                                  <p>12:30 PM - 1:30 PM</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card" style={{ cursor: "pointer", marginBottom: "10px" }}>
                            <div className="card-body" style={{ padding: "0.8rem" }}>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <i class="ri-artboard-line mr-2 px-2 py-2 text-white" style={{ fontSize: "20px", backgroundColor: "#364277", borderRadius: "20%" }}></i>
                                  <div>
                                    <h5>01ECS20501 - Engineering Mathematics</h5>
                                    <p>1st Year - 1st Sem - A</p>
                                  </div>
                                </div>
                                <div>
                                  <p>12:30 PM - 1:30 PM</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card" style={{ cursor: "pointer", marginBottom: "10px" }}>
                            <div className="card-body" style={{ padding: "0.8rem" }}>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <i class="ri-artboard-line mr-2 px-2 py-2 text-white" style={{ fontSize: "20px", backgroundColor: "#364277", borderRadius: "20%" }}></i>
                                  <div>
                                    <h5>01ECS20501 - Engineering Mathematics</h5>
                                    <p>1st Year - 1st Sem - A</p>
                                  </div>
                                </div>
                                <div>
                                  <p>12:30 PM - 1:30 PM</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body" style={{ height: "250px" }}>
                      <h4 className="card-title mb-2 mt-2 text-uppercase"><b>Pending Attendance (5)</b></h4>
                      <hr className="p-1 m-1 mt-2" />
                    </div>
                  </div>

                </div>
                <div className="col-sm-4">
                  <div className="card" style={{ height: "425px" }}>
                    <div className="card-body">
                      <div className="text-center">
                        <img
                          src="../../../assets/images/reports/profile.png"
                          alt="Staff Name"
                          width="30%"
                          className="img-fluid"
                        />

                        <h3 className="mt-2 text-uppercase" style={{ color: "#364277" }}>Mr. Siddaraj Hubballi</h3>
                        <p>Assistant Professor</p>

                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-striped"
                          role="progressbar"
                          style={{ width: "70%" }}
                          aria-valuenow={75}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p>Profile Filled</p>
                        <p>70%</p>
                      </div>
                      <center><button onClick={() => { navigate(ROUTES.Employee.Profile) }} type="button" className="btn btn-secondary"><i class="ri-user-line"></i> My Profile</button></center>
                    </div>
                  </div>
                  <div className="card p-0">
                    <div className="card-body p-2">
                      <h4 className="card-title mb-2 mt-2"><b>Quick Links</b></h4>
                      <hr className="p-1 m-1 mt-2" />
                      <div style={{ overflowY: "auto", height: "380px" }}>

                        <List
                          size="small"
                          bordered
                          dataSource={links}
                          renderItem={(item, index) => (
                            <List.Item>
                              <div>
                                <i className="ri-links-line mr-2 px-2 py-1 text-white" style={{ backgroundColor: colors[index % colors.length], borderRadius: "20%" }}></i>
                                <Link to={`/${item?.link}`}  style={{ color: "black", fontSize: "13px" }}>{item?.name?.length > 75 ? `${item?.name?.substring(0, 75)}...` : item.name}</Link>
                              </div>
                            </List.Item>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-md-6">
                    <div className="row">
                    <div className="col-12">
                    <div className="card ">
                      <div className="card-body">
                        <h4 className="card-title mb-4">Student Attendance</h4>
                        
                        <div id="chart">
                          <Chart options={optionsEmployee1} series={seriesEmployee1} type="line" height={405} />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div> */}

              </div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="card p-3 w-100">
                    <h3 className="my-3">Event Calendar</h3>
                    <Calender
                      setLoading={setLoading}
                      collegeId={sessionStorage.getItem("college_id")}
                      emp_id={sessionStorage.getItem("employee_id")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardEmployee;
