import React from "react";
import { useState, useEffect } from "react";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import InputDoc from "../../InputDoc/InputDoc";

function PGPharmacy({ data, form_data, set_form_data, setLoading }) {
  const [info, setInfo] = useState({});
  const [x, setX] = useState();
  const Certificate_upload = async (e, type) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `${info?.user_id}_${type}`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      console.log(d);
      setInfo({
        ...info,
        [e.target.name]: d ? d : "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  let percent = 0;

  function calculate() {
    if (info?.degree_max_marks && info?.degree_obtained_marks) {
      let y = parseInt(info?.degree_max_marks);
      let x = parseInt(info?.degree_obtained_marks);
      percent = (x / y) * 100;
      console.log(percent);
      percent = percent.toFixed(3);
      setX(percent);
      info.degree_percentage = percent;
    } else {
      setX("");
    }
  }

  useEffect(() => {
    calculate();
  }, [info]);

  useEffect(() => {
    if (data?.form_data) {
      setInfo({
        ...form_data,
      });
    }
  }, [data.form_data]);

  useEffect(() => {
    set_form_data(info);
  }, [info]);

  return (
    <div>
      <div className="register-area mt-2 pb-70">
        <div className="">
          <div className="register" style={{ maxWidth: "100%" }}>
            <form action="">
              <div className="row">
                <div className="col-md-12">
                  <h4>(B) Previous Academic Details</h4>
                </div>
                <div className="col-lg-4 col-md-6 mt-3">
                  <label>Select Previous Degree</label>
                  <select
                    className="form-control mt-2"
                    name="degree"
                    value={info?.degree}
                    onChange={handleChange}
                  >
                    <option value="">Select Previous Degree</option>
                    <option value="M Pharmacy">M Pharmacy</option>
                    <option value="Pharmacy D">Pharmacy D</option>
                  </select>
                </div>

                <div className="col-md-12 mt-4">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th colSpan={1}>Examination</th>
                        <th colSpan={1}>Board/University</th>
                        <th colSpan={1}>Month and Passing Year</th>
                        <th colSpan={1}>Subjects</th>
                        <th colSpan={1}>Max. Marks</th>
                        <th colSpan={1}>Marks Obtained</th>
                        <th colSpan={1}>Percentage %</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="pt-4">{info?.degree} degree</th>
                        <th>
                          <input
                            type="text"
                            className="form-control"
                            name="degree_board"
                            value={info?.degree_board}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input
                            type="month"
                            className="form-control"
                            name="degree_passing_year"
                            value={info?.degree_passing_year}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input
                            type="text"
                            className="form-control"
                            name="degree_subjects"
                            value={info?.degree_subjects}
                            onChange={handleChange}
                          />
                        </th>

                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="degree_max_marks"
                            value={info?.degree_max_marks}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="degree_obtained_marks"
                            value={info?.degree_obtained_marks}
                            onChange={handleChange}
                          />
                        </th>
                        <th className="pt-4">{x}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row mt-2">
                  <h4>(C) Upload Documents</h4>

                  <div className="col-lg-4 col-md-6 mt-3">
                    <InputDoc
                      lable={"Caste Ceritificate"}
                      value={info?.caste_certificate}
                      important={true}
                      name={"caste_certificate"}
                      onChange={(e) => {
                        Certificate_upload(e, "caste_certificate");
                      }}
                    />
                  </div>

                  <div className="col-lg-4 col-md-6 mt-3">
                    <InputDoc
                      lable={"Income Ceritificate"}
                      value={info?.income_certificate}
                      important={true}
                      name={"income_certificate"}
                      onChange={(e) => {
                        Certificate_upload(e, "income_certificate");
                      }}
                    />
                  </div>

                  <div className="col-lg-4 col-md-6 mt-3">
                    <InputDoc
                      lable={"Freeship Card"}
                      value={info?.freeship_card}
                      important={true}
                      name={"freeship_card"}
                      onChange={(e) => {
                        Certificate_upload(e, "freeship_card");
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg-4 col-md-6 mt-3">
                    <InputDoc
                      lable={"Degree Markscard"}
                      value={info?.degree_markscard}
                      important={true}
                      name={"degree_markscard"}
                      onChange={(e) => {
                        Certificate_upload(e, "degree_markscard");
                      }}
                    />
                  </div>

                  <div className="col-lg-4 col-md-6 mt-3">
                    <InputDoc
                      lable={"Leaving Ceritificate"}
                      value={info?.leaving_certificate}
                      important={true}
                      name={"leaving_certificate"}
                      onChange={(e) => {
                        Certificate_upload(e, "leaving_certificate");
                      }}
                    />
                  </div>

                  <div className="col-lg-4 col-md-6 mt-3">
                    <InputDoc
                      lable={"Degree Ceritificate"}
                      value={info?.degree_certificate}
                      important={true}
                      name={"degree_certificate"}
                      onChange={(e) => {
                        Certificate_upload(e, "degree_certificate");
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-12">
                    <h5 className="text-center">
                      <u> UNDERTAKING </u>
                    </h5>
                    <div className="form-group ">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input check mr-5"
                          name="agree"
                          checked={info?.undertaking}
                          onChange={(e) =>
                            setInfo({
                              ...info,
                              undertaking: info?.undertaking ? 0 : 1,
                            })
                          }
                          type="checkbox"
                          defaultChecked=""
                          required=""
                          id="inlineCheckbox1"
                          defaultValue={1}
                        />
                        <p
                          htmlFor="inlineCheckbox1 ml-5"
                          style={{ textAlign: "justify" }}
                        >
                          I / We hereby declare that:
                        </p>
                        <ol>
                          <li>
                            All the information provided by me is true to the
                            best of my knowledge and belief.
                          </li>
                          <li>
                            Any change in any information provided earlier will
                            be informed to the institute with documentary proof.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-4">
                    <h5>Place:</h5>
                    <input
                      type="text"
                      className="form-control"
                      name="place"
                      value={info?.place}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <h5>Date:</h5>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={info?.date?.split("T")[0]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PGPharmacy;
