import React from "react";

function DocumentsModals({ title, img, setLink }) {
  let extension = img.slice(img.length - 3);
  console.log(title, img);
  console.log("img -", img);
  return (
    <div
      className="modal fade text-left"
      id="DocumentsModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setLink("");
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body text-center">
            {extension == "pdf" ? (
              <object
                data={img}
                type="application/pdf"
                width="100%"
                height="600px"
              ></object>
            ) : (
              <img src={img} width={500} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentsModals;
