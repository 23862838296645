import React, { useState, useEffect } from 'react'

const Bsc = ({ setStep, updateForm, data,  form_data , set_form_data }) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDay();
  const futureDate = date.getDate() + 3;
  // date.setDate(futureDate);
  const [defaultDate, setDefaultDate] = useState(
    date.toISOString().split("T")[0]
  );

  const [info, setInfo] = useState({})
  useEffect(() => {

    if (data?.form_data) {
      setInfo({
        ...form_data
      })
    }

  }, [data])

  useEffect(()=>{
    set_form_data(info)
  },[info])


  return (
    <div>

      <div className="register-area mt-2 pb-70">
        <div className="container">
          <div className="register" style={{ maxWidth: "100%" }}>
            <h3>B.Sc. Admission Form</h3>
            <form>
              <div className="row mt-5">
                <h5 className="mb-3">
                  12<sup>th</sup> Science Details:{" "}
                </h5>
                <div className="col-md-12 table-responsive">
                  <table className="table table-bordered border-danger rounded text-center align-middle">
                    <tbody>


                      <tr>
                        <th>No of Attempts:</th>
                        <th>
                          <input
                            type="number"
                            min={1}
                            max={9}
                            placeholder="No of Attempts:*"
                            required="required"
                            name="pu_attempts"
                            value={info?.no_attempts}
                            onChange={(e) => setInfo({ ...info, no_attempts: e.target.value })}
                            className="form-control"
                          />
                        </th>
                      </tr>

                      <tr>
                        <th>Subjects</th>
                        <th>Marks Obtained</th>
                      </tr>
                      <tr>
                        <th>Physics</th>
                        <td>
                          {" "}
                          <input
                            type="text"
                            placeholder="Enter Physics Marks*"
                            required="required"
                            id="phy"
                            name="phy"
                            value={info?.subject_phy}
                            onChange={(e) => setInfo({ ...info, subject_phy: e.target.value })}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Chemistry</th>
                        <td>
                          {" "}
                          <input
                            type="text"
                            placeholder="Enter Chemistry Marks*"
                            required="required"
                            id="chem"
                            name="chem"
                            value={info?.subject_chem}
                            onChange={(e) => setInfo({ ...info, subject_chem: e.target.value })}
                            className="form-control"
                          />
                        </td>
                      </tr>{" "}
                      <tr>
                        <th>Maths</th>
                        <td>
                          {" "}
                          <input
                            type="text"
                            placeholder="Enter Maths Marks*"
                            required="required"
                            id="maths"
                            name="maths"
                            value={info?.subject_mat}
                            onChange={(e) => setInfo({ ...info, subject_mat: e.target.value })}
                            className="form-control"
                          />
                        </td>
                      </tr>{" "}
                      <tr>
                        <th>Biology</th>
                        <td>
                          {" "}
                          <input
                            type="text"
                            placeholder="Enter Biology Marks*"
                            required="required"
                            id="bio"
                            name="bio"
                            value={info?.subject_bio}
                            onChange={(e) => setInfo({ ...info, subject_bio: e.target.value })}
                            className="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Total Marks</th>
                        <th id="total" />

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <h5 className="text-center">
                    <u> UNDERTAKING </u>
                  </h5>
                  <div className="form-group ">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input check mr-5"
                        name="agree"
                        value={info?.undertaking}
                        onChange={(e) => setInfo({ ...info, undertaking: e.target.value })}
                        type="checkbox"
                        defaultChecked=""
                        required=""
                        id="inlineCheckbox1"
                        defaultValue={1} checked
                      />
                      <p
                        htmlFor="inlineCheckbox1 ml-5"
                        style={{ textAlign: "justify" }}
                      >
                        I understand B.Sc. is a full time course and i hereby tender
                        undertaking that I will not study or work elsewhere during
                        college hours. Further I agree that if my attendance in theory
                        or practical class or both is not as per Swaminarayan
                        University rules. Shree Swaminarayan Science College has all
                        right to cancel any admission or withdraw my examination form
                        and I will be fully responsible for the same.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12">
                  <h5>Instruction to Candidates</h5>
                  <p></p>
                  <ol>
                    <li>
                      Admission form should be filled up by the student in neat and
                      legible handwriting{" "}
                    </li>
                    <li>Fees once paid is nonrefundable</li>
                    <li>
                      Candidate have to choose their subject and group carefully as no
                      change in choice of subject is permissible afterward
                    </li>
                    <li>
                      Candidate having studied in board other than Gujarat board/CBSE
                      are instructed to submit original provisional eligibility
                      certificate (PEC) which is issued by Swaminarayan university,
                      along with their application form
                    </li>
                    <li>
                      Original marksheet of last examination passed will have to be
                      deposited with the admission committee. This will be returned to
                      the student after their enrollment in the university
                    </li>
                    <li>
                      Candidate are advised to get enough photocopies of 12th standard
                      Original marksheet for their further use as he original will not
                      be given back until the enrollment or cancellation of their
                      admission whichever is earlier.
                    </li>
                    <li>Candidate have to attach along with this form</li>
                    <ul style={{ listStyleType: "square" }} stat="A">
                      <li>
                        Duly attested photocopies of marksheet of HSC and SSC /
                        Previous examination (Semester) marksheet.
                      </li>
                      <li>
                        Copy of examination trial certificate and school leaving
                        certificate.
                      </li>
                      <li>Identity Proof</li>
                      <li>Permanent residence proof</li>
                      <li>Copy of Disablement Certificate (If applicable).</li>
                      <li>Caste Certificate Original/ True Copy (Latest)</li>
                      <li>EBC certificate</li>
                      <li>Sports/Cultural certificate</li>
                    </ul>
                    <li>
                      Change in any information provided earlier will be informed
                      immediately to the institute with necessary documents.
                    </li>
                    <li>
                      As per Honorable Supreme Court order, ragging is strictly
                      prohibited legal action will be taken against students indulging
                      in ragging
                    </li>
                    <li>
                      The student should park their vehicles at proper place (at their
                      own risk) No valuable should be kept in vehicles.
                    </li>
                    <li>
                      It is compulsory to keep college Identity Card, without which
                      the student may not be allowed to enter the premises and the
                      college building
                    </li>
                  </ol>
                  <p />
                </div>
                <div className="col-md-4">
                  <h5>Place:</h5>
                  <input 
                  type="text" 
                  className="form-control" 
                  name="place" 
                  placeholder='Place'
                  value={info?.place}
                  onChange={(e) => setInfo({ ...info, place: e.target.value })} />
                </div>
                <div className="col-md-4">
                  <h5>Date:</h5>
                  <input
                    type="date"
                    className="form-control"
                    name="sign_date"
                    value={info?.date?info?.data?.split("T")[0]:defaultDate}
                    
                    onChange={(e) => setInfo({ ...info, date: e.target.value })}
                    defaultValue=""
                    placeholder='Date'
                    readOnly=""
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>



    </div>
  )
}

export default Bsc