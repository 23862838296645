import React, { useEffect, useRef, useState } from 'react'
import AdvanceFeeReciept from "../../Accounts/FeeCollection/AdvancePayFeeCollection";
import axios from "axios";
import { toast } from "react-toastify";
import {
    LOCAL_DEPARTMENT,
    LOCAL_COLLEGE,
} from "../../../utils/LocalStorageConstants";
import Select from "react-select";
import { SYLLABUS_COUNT } from '../../../utils/Academics.apiConst';
import ModalSyllabusCount from './ModalSyllabusCount';

function Syllabus({ setLoading }) {
    const [selectedDepartment, setDepartment] = useState()
    const printRef = useRef();
    const [alldepartment, setallDepartment] = useState(
        JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
    );

    const collegeId = sessionStorage.getItem("college_id");

    const handleDepartmentChange = (selectedOption) => {
        setDepartment(selectedOption);
    };
    const handleCourse = (selectedOption) => {
        console.log("selected", selectedOption)
    };
    const [syllabus, setsyllabus] = useState([])
    console.log("syllabus", syllabus);

    const getData = async () => {


        setLoading(1);
        const config = {
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            url: `${SYLLABUS_COUNT}?college_id=${collegeId}&department_id=${selectedDepartment?.value}`
        };


        setLoading(1);
        await axios(config)
            .then((res) => {

                setLoading(0);
                setsyllabus(res.data.data);

            })
            .catch((err) => {
                setLoading(0);
                console.log(err);
                toast.error("Something Went Wrong");
            })
    }

    const classOrder = ["1st Year", "2nd Year", "3rd Year", "4th Year"];

    // Filter and group the syllabus data
    const filteredSyllabus = syllabus.filter(s => s.class_id !== null);

    const groupedSyllabus = filteredSyllabus.reduce((acc, item) => {
        const key = `${item.department_name}_${item.class_name}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});

    // Sort groups by classOrder
    const sortedGroupedSyllabus = Object.entries(groupedSyllabus).sort(([keyA], [keyB]) => {
        const classNameA = keyA.split('_')[1];
        const classNameB = keyB.split('_')[1];
        return classOrder.indexOf(classNameA) - classOrder.indexOf(classNameB);
    });
    const [semId, getSemId] = useState()
    const [type, settype] =useState("")
    return (
        <div>
            <ModalSyllabusCount semId={semId} type={type} />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Syllabus Details</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Syllabus Information</a>
                                            </li>
                                            <li className="breadcrumb-item active">
                                                Syllabus Details
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h2 className="card-title">Search By Department</h2>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="validationCustom02">
                                                        Academic Year<span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <Select
                                                    // value={selectedDepartment}
                                                    // // onChange={handleDepartmentChange}
                                                    // placeholder="Select Department"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="validationCustom02">
                                                        Department<span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <Select
                                                        value={selectedDepartment}
                                                        onChange={handleDepartmentChange}
                                                        options={
                                                            alldepartment
                                                                ?.filter((s) => s.college_id == collegeId)
                                                                ?.map((i) => ({
                                                                    value: i.id,
                                                                    label: i.name,
                                                                }))
                                                        }

                                                        placeholder="Select Department"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="float-right ">
                                            <button
                                                className="btn btn-nex   btn-md"
                                                type="submit"
                                                name="submit"
                                                //   value="collect"
                                                onClick={getData}
                                            >
                                                <i className="fa fa-search mr-1" aria-hidden="true" />{" "}
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>






                                <div className="card">
                                    <div className="card-body">
                                        <h2 className="card-title">Syllabus Details</h2>
                                        <br />
                                        <table className="table border table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Sl No.</th>
                                                    <th>Semester</th>
                                                    <th>Subject Count</th>
                                                    <th>Lesson Count</th>
                                                    <th>Topic Count</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedGroupedSyllabus.map(([key, group]) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <th className="table-dark" colSpan={5}>
                                                                    {group[0].department_name}
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th className="table-dark" colSpan={5}>
                                                                    {group[0].class_name}
                                                                </th>
                                                            </tr>
                                                            {group.map((s, i) => (
                                                                <tr key={s.semester_id}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{s.sem_name}</td>
                                                                    <td
                                                                        data-toggle="modal"
                                                                        data-target="#ModalSyllabusCount"
                                                                        onClick={() => {

                                                                            settype("course")
                                                                            getSemId(s.semester_id);
                                                                        }}
                                                                        style={{ cursor: 'pointer', color:"blue",fontWeight:"bold" }}
                                                                    >
                                                                        {s.course_count}
                                                                    </td>
                                                                    <td
                                                                        data-toggle="modal"
                                                                        data-target="#ModalSyllabusCount"
                                                                        onClick={() => {

                                                                            settype("lesson")
                                                                            getSemId(s.semester_id);
                                                                        }}
                                                                        style={{ cursor: 'pointer', color:"blue",fontWeight:"bold" }}
                                                                    >
                                                                        {s.lcount}
                                                                    </td>
                                                                    <td
                                                                        data-toggle="modal"
                                                                        data-target="#ModalSyllabusCount"
                                                                        onClick={() => {

                                                                            settype("topic")
                                                                            getSemId(s.semester_id);
                                                                        }}
                                                                        style={{ cursor: 'pointer', color:"blue",fontWeight:"bold" }}
                                                                    >
                                                                       {s.topic_count}
                                                                    </td>
                                                             
                                                                  
                                                                </tr>
                                                            ))}
                                                        </>
                                                    );
                                                })}
                                            </tbody>
                                        </table>








                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    )
}

export default Syllabus
