import React, { useEffect, useState } from "react";
import { Http } from "../../Services/Services";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { UID } from "../../Data/navbar/StudentPortal/storageConstant";
import { STUDENT, STUDENT_ATTENDANCE_BY_COURSE, SUBJECTS } from "./student.Api";
import { Devlog } from "./Logger";
import { REPORT_STUDENT_ATTENDANCE_STAT_STU_SUB } from "../../utils/Reports.apiConst";
import avatar from "../../assets/images/reports/graduated.png";
import "./StudentPortal.scss";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../utils/LocalStorageConstants";
import { useReactToPrint } from "react-to-print";
// import state from "sweetalert/typings/modules/state";

const AttendanceCourse = ({ setLoading }) => {
  const { course_id } = useParams();

  // const {student_id, course_id} = useParams()

  const navigate = useNavigate();

  const semester_id = sessionStorage.getItem("semseter_id");
  const college_id = sessionStorage.getItem("college");

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    const formattedTime = `${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
    return `${formattedDate} - ${formattedTime}`;
  };

  const tableRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const [session, setSession] = useState("");
  const [faculty, setFaculty] = useState("");
  const [currentclass, setCurrentClass] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [subData, setSubData] = useState([]);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT), college_id]);

  const getStudentData = async () => {
    const userId = sessionStorage.getItem("uid");

    await Http.get(`${STUDENT}/${userId}`)
      .then((res) => {
        let temp = res?.data;
        console.log("temp?.data -", temp?.data?.father_name);
        let activeSession = temp?.session?.filter((s) => s?.status == "ACTIVE");
        setSession(activeSession[0]?.session_id);
        setFaculty(res?.data?.college_id);
        setCurrentClass(activeSession[0]?.class_id);
        let obj = {
          id: temp?.semester[temp?.semester.length - 1]?.student_session_id,
          class_id: temp?.session[temp?.session.length - 1]?.class_id,
          session_id: temp?.session[temp?.session.length - 1]?.session_id,
          status: temp?.data?.status,
          name: temp?.data?.name,
          user_id: temp?.data?.user_id,
          email: temp?.data?.email,
          phone: temp?.data?.phone,
          student_picture: temp?.data?.student_picture,
          class_name: temp?.session[temp?.session.length - 1]?.class_name,
          semester_name:
            temp?.semester[temp?.semester.length - 1]?.semester_name,
          college_name: collegeOpt.find((s) => s.id == temp?.data?.college_id)
            .name,
          department_name: department.find(
            (s) => s.id == temp?.data?.department_id
          )?.name,
          dob: temp?.data?.dob,
          nationality: temp?.data?.nationality,
          state: temp?.data?.state,
          district: temp?.data?.district,
          city: temp?.data?.city,
          caste: temp?.data?.caste,
          sub_caste: temp?.data?.sub_caste,
          gender: temp?.data?.gender,
          mother_name: temp?.data?.mother_name,
          father_name: temp?.data?.father_name,
          religion: temp?.data?.religion,
          category: temp?.data?.category,
          blood_grp: temp?.data?.blood_grp,
        };

        setStudentData(obj);
        // console.log("obj - ", obj);
      })
      .catch((err) => {
        console.error("Error fetching student data:", err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getStudentData();
  }, []);

  const student_id = studentData?.id;

  const getSubjectData = async () => {
    setLoading(1);
    await Http.get(`${SUBJECTS}?semester_id=${semester_id}`)
      .then((res) => {
        setLoading(0);
        const subdata = res.data.data.filter((s) => s.id == course_id);
        setSubData(subdata);
        // console.log("subdata -", subdata[0]?.name);
      })
      .catch((err) => {
        setLoading(0);
        Devlog("Subjects", err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getSubjectData();
  }, [semester_id]);

  const [data, setData] = useState([]);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);

  const getData = async () => {
    Http.get(
      `${REPORT_STUDENT_ATTENDANCE_STAT_STU_SUB}?course_id=${course_id}&student_id=${student_id}`
    )
      .then((res) => {
        setData(res.data.data);
        console.log("student data - ", res.data.data);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSearch = async () => {
    Http.get(
      `${REPORT_STUDENT_ATTENDANCE_STAT_STU_SUB}?course_id=${course_id}&student_id=${student_id}`
    )
      .then((res) => {
        // setData(res.data.data);
        setData({
          ...res.data.data,
          attendance: res.data.data.attendance,
        });
        console.log("student data - ", res.data.data);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    // getData2();
  }, []);

  // const handleSearch = async () => {
  //   try {
  //     setLoading(1);
  //     // console.log("student_id - ", student_id);
  //     const response = await Http.get(
  //       `${REPORT_STUDENT_ATTENDANCE_STAT_STU_SUB}?course_id=${course_id}&student_id=${student_id}`
  //     );
  //     // console.log("data - ", response.data.data);
  //     const filteredData = response.data.data.attendance.filter((item) => {
  //       const date = new Date(item.date).getTime();
  //       // console.log("date - ", item.date);

  //       const from = fromDate ? new Date(fromDate).getTime() : 0;

  //       // console.log("from - ", fromDate);
  //       const to = toDate ? new Date(toDate).getTime() : Infinity;

  //       // console.log("to - ", toDate);
  //       return date >= from && date <= to;
  //     });
  //     setData({ ...response.data.data, attendance: filteredData });
  //     // console.log("filtered data - ", filteredData);
  //     setLoading(0);
  //   } catch (error) {
  //     setLoading(0);
  //     toast.error("Something went wrong");
  //   }
  // };

  useEffect(() => {
    handleSearch(); // Call the handleSearch function on component mount
  }, []);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="page-title-box d-flex align-items-center">
                      <button
                        className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3 mt-2"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <i className="ri-arrow-left-line"></i>
                      </button>
                      <h6 className="mb-0">
                        <strong>STUDENT ATTENDANCE - </strong>
                        {studentData?.user_id}
                      </h6>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-2">
                        <img
                          src={studentData?.student_picture || avatar}
                          className=" rounded-pill ml-4"
                          // className="ml-4"
                          style={{ width: "160px", height: "160px" }}
                          alt="Student Picture"
                        />
                      </div>
                      <div className="col-md-10 mt-3">
                        <div>
                          <section>
                            <div className="col-sm-12">
                              <table className="table staff-print">
                                <tbody>
                                  <tr className="row">
                                    <td className="col-sm-8">
                                      <tr className="row ml-2 studatt">
                                        <td className="col-sm-6">
                                          <p style={{ margin: "0 0 0 14px" }}>
                                            <strong>Student Name :</strong>{" "}
                                            <span className="ml-3">
                                              {studentData?.name}
                                            </span>
                                          </p>
                                        </td>
                                        <td className="col-sm-6">
                                          <p style={{ margin: "0 0 0 14px" }}>
                                            <strong>User ID : </strong>
                                            <span className="ml-3">
                                              {studentData?.user_id}
                                            </span>
                                          </p>
                                        </td>
                                      </tr>

                                      <br />
                                      <tr className="row ml-2 studatt">
                                        <td className="col-sm-6">
                                          <p style={{ margin: "0 0 0 14px" }}>
                                            <strong>Faculty : </strong>
                                            <span className="ml-3">
                                              {studentData?.college_name}
                                            </span>
                                          </p>
                                        </td>
                                        <td className="col-sm-6">
                                          <p style={{ margin: "0 0 0 14px" }}>
                                            <strong>Department : </strong>
                                            <span className="ml-3">
                                              {studentData?.department_name}
                                            </span>
                                          </p>
                                        </td>
                                      </tr>

                                      <br />
                                      <tr className="row ml-2 studatt">
                                        <td className="col-sm-6">
                                          <p style={{ margin: "0 0 0 14px" }}>
                                            <strong>Class: </strong>
                                            <span className="ml-3">
                                              {studentData?.class_name}
                                            </span>
                                          </p>
                                        </td>
                                        <td className="col-sm-6">
                                          <p style={{ margin: "0 0 0 14px" }}>
                                            <strong>Semester : </strong>
                                            <span className="ml-3">
                                              {studentData?.semester_name}
                                            </span>
                                          </p>
                                        </td>
                                      </tr>
                                      <br />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h6
                      // className="card-header"
                      className="card-header"
                    >
                      <strong>Subject - </strong>
                      {subData[0]?.name}
                    </h6>

                    <div className="row mt-4">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            {" "}
                            From Date{" "}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="fromDate"
                            value={
                              fromDate
                                ? fromDate
                                : new Date().toISOString().split("T")[0]
                            }
                            onChange={(e) => {
                              setFromDate(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02"> To Date </label>
                          <input
                            type="date"
                            className="form-control"
                            id="validationCustom02"
                            placeholder="Purpose of Visiting"
                            name="toDate"
                            value={
                              toDate
                                ? toDate
                                : new Date().toISOString().split("T")[0]
                            }
                            onChange={(e) => setToDate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-2 mt-4">
                        <button
                          className="btn btn-primary btn-rounded ml-5"
                          type="submit"
                          name="submit"
                          // onClick={getData}
                          onClick={handleSearch}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>

                      <div className="col-md-2 mt-1">
                        <button
                          className="btn btn-primary mr-5 float-right"
                          onClick={handlePrint}
                        >
                          Print PDF
                        </button>
                      </div>
                    </div>

                    <div className="table-responsive mt-4">
                      <table className="table table-bordered">
                        <tr>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Time</th>
                        </tr>
                        {data?.attendance
                          // .filter((item) => {
                          //     const date = new Date(item.date).getTime();
                          //     const from = fromDate
                          //       ? new Date(fromDate).getTime()
                          //       : 0;
                          //     const to = toDate
                          //       ? new Date(toDate).getTime()
                          //       : Infinity;
                          //     return (
                          //       date >= from &&
                          //       date <= to
                          //     );
                          //  })
                          ?.map((i, key) => (
                            <tr>
                              <td>
                                <span
                                  className={`badge badge-soft-${
                                    i?.attendance_status == "PRESENT"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {i?.attendance_status}
                                </span>
                              </td>
                              <td>{i?.date}</td>
                              <td>
                                {i?.time_from} - {i?.time_to}
                              </td>
                            </tr>
                          ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <section ref={tableRef}>
              <div className="col-sm-12">
                <table className="table staff-print">
                  {data && (
                    <thead>
                      <tr className="row">
                        <th className="text-bold col-sm-12">
                          <h5 className="text-dark ml-2">
                            Printed On - {getCurrentDate()}
                          </h5>
                          <h3 className="mt-5 mb-4 ml-4 text-primary">
                            <img
                              className="profile-user-img img-responsive mx-auto ml-2"
                              src="../../../assets/images/Nexenstial Logo.jpg"
                              width="100px"
                              height="80px"
                              style={{ aspectRatio: "1/1" }}
                            />
                            <span className="ml-5 print-staff-details">
                              {studentData?.college_name}
                            </span>
                          </h3>

                          <div className="row">
                            <div className="col-4 float-right">
                              <p>
                                {studentData?.class_name} -{" "}
                                {studentData?.semester_name}
                              </p>
                            </div>
                            <div className="col-4 text-center">
                              <p>
                                {fromDate} To : {toDate}
                              </p>
                            </div>
                            <div className="col-4 text-right">
                              <p>{studentData?.name}</p>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                  )}

                  <tbody>
                    <tr className="row">
                      <td className="col-sm-12">
                        <h5 className="text-center my-3 mt-1 print-staff-details">
                          SUBJECT : {subData[0]?.name}
                        </h5>

                        <table className="table table-bordered mt-4">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Date</th>
                              <th>Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.attendance?.map((i, key) => (
                              <tr>
                                <td
                                  className={
                                    i?.attendance_status === "PRESENT"
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  {i?.attendance_status}
                                </td>
                                <td>{i?.date}</td>
                                <td>
                                  {i?.time_from} - {i?.time_to}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <br />
                        <br />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceCourse;
