import React, { useState, useEffect } from "react";
import {
  ACADEMICS_ADD_SUBJECT,
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
  ASSIGN_HOSPITAL_STUDENTS
} from "../../utils/Academics.apiConst";
import { STUDENT_SESSION, STUDENT_COURSE_REGISTRATION, ASSIGN_HOSPITAL } from '../../utils/apiConstants';
import { SessionOpt } from '../../Data/student/sessionData';
import axios from "axios";
import { toast } from "react-toastify";
import {
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import Nodata from "../../Components/NoData/Nodata";
import Select from "react-select";

function AssignHospital({ setLoading, collegeId }) {

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }


  const [session, setSession] = useState("");
  const [classOpt, setClassOpt] = useState();
  const [semOpt, setSemtOpt] = useState([])
  const [sectionOpt, setSectionOpt] = useState();
  const [subjectOpt, setSubjectOpt] = useState([]);
  const [selectedSubjectOpt, setSelectedSubjectOpt] = useState([]);
  const [hospitalDepartments, setHospitalDepartments] = useState([]);
  const [filterDept, setFilterDept] = useState("");
  const [assignedDept, setAssignedDept] = useState({});

  const [promotes, setPromotes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  useEffect(() => {
    setDepartmentOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == collegeId
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), , collegeId]);


  //Fucntion to get data of classes
  const getClassData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data4, data3] = await Promise.all([
        axios({ ...config, url: ACADEMICS_ADD_SEMESTER + `?college_id=${collegeId}` })
        .then(res => {
          setSemtOpt(res.data.data)
        })
        .catch(err => {
          setLoading(0)
          console.log(err);
          toast.error('Something went wrong')
        }),
      
        axios({ ...config, url: ACADEMICS_ADD_CLASS + `?college_id=${collegeId}` })
        .then(res => {
          setLoading(0);
          setClassOpt(res.data.data)
        })
        .catch(err => {
          setLoading(0)
          console.log(err);
          toast.error('Something went wrong')
        }),
      axios({ ...config, url: ACADEMICS_ADD_SECTION + `?college_id=${collegeId}` })
        .then(res => {
          setSectionOpt(res.data.data)
        })
        .catch(err => {
          setLoading(0)
          console.log(err);
          toast.error('Something went wrong')
        }),
    ]);
  };

  const [data, setData] = useState([]);

  const [user, setUser] = useState({
    class_id: "",
    department_id: "",
    semester_id: "",
    section_id: "",
    session_id: SessionOpt?.find((s) => s.status == "ACTIVE")?.id,
    date: getCurrentDate(),
  });

  const clearData = () => {
    setUser({
      ...user,
      class_id: "",
      department_id: "",
      semester_id: "",
      section_id: "",
      session_id: SessionOpt?.find((s) => s.status == "ACTIVE")?.id,
      date: getCurrentDate(),
    });
  };

 const getHospitalDepartments = async () => {
  try {
    setLoading(1);
    let result = await axios.post('https://nexenstial.in/hims/api/department.php');
    result.data = Object.keys(result?.data)?.map(key => result?.data[key]) || [];
    if(result?.data?.length > 0) {
    const temp = [...result?.data];
    temp.pop();
    console.log('hospital depts - ', temp);
    setHospitalDepartments(temp);
    }
    else {
    toast.error("No data present");
    }
  }catch(err) {
      setLoading(0);
      toast.error("Something went wrong")
      console.log(err);
  }
 }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getStudentData = async () => {

    if(!user.session_id || !user.semester_id || !user.class_id || !user.department_id || !user?.date || !filterDept) {
      toast.error("Please Enter Required Details");
      return;
    }

    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: STUDENT_SESSION + `?session_id=${user.session_id}&section_id=${user?.section_id}&semester_id=${user?.semester_id}&class_id=${user?.class_id}&department_id=${user?.department_id}&status=ACTIVE&date=${user?.date}&hospital=true&hospital_department=${filterDept}`
    }

    await axios(config)
    .then(
      res => {
        let tempData = res.data.data;
        tempData = tempData.sort((a,b) => a.user_id - b.user_id)
        setData(tempData);
        console.log('student data - ', tempData);
        setLoading(0);
      }
    ).catch(err => {
      setLoading(0)
      console.log(err);
      toast.error('Something went wrong')
    })
    setLoading(0);
  }

  const handleSubmit = async (student, type) => {

      console.log('data to backend - ', student);
      const config = {
          method: "post",
            url: ASSIGN_HOSPITAL,
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              "Content-Type": "application/json",
            },
            data: {
              student_session_id: student?.student_session_id,
              department: filterDept,
              date: user?.date,
            }
        };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        if(type == "multiple") {
          getStudentData();
        }
        else {
          getStudentData();
          toast.success("Department Allocated Successfully");
        }
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const unregister = async (student) => {

    let coursesArr = student.courses;
    
    if(coursesArr.find((s) => s.course_id == user?.course_id)) {
      coursesArr.find((s) => s.course_id == user?.course_id).status = "INACTIVE";
      coursesArr.find((s) => s.course_id == user?.course_id).updatedAt = new Date()?.toISOString().slice(0, 19).replace("T", " ");
    }
    else {
      return toast.success("Course is not registered");
    }
    console.log('courses array - ', JSON.stringify(coursesArr));
    let coursesString = JSON.stringify(coursesArr);
    const config = {
        method: "put",
          url: STUDENT_COURSE_REGISTRATION + `/${student.student_semester_id}`,
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: {
              courses: coursesString,
          }
      };
  setLoading(1);
  await axios(config)
    .then((res) => {
      setLoading(0);
      getStudentData();
      toast.success("Course Removal successful");
    })
    .catch((err) => {
      setLoading(0);
      console.log(err);
      toast.error("Something went wrong");
    });
};

  const registerMultipleStudents = async () => {
        if(promotes.length == 0) {
          return toast.error("No Students Selected");
        }
        promotes?.map((item, index)=> {
          handleSubmit(item, "multiple");
        })
        getStudentData();
        toast.success("Department Allocated Successfully");
        setPromotes([]);
  }

  const toggleSelectAll = () => {
    setSelectAllChecked((prev) => !prev);
    console.log('data - ', data);
    const updatedPromotes = data.filter(i => !(i?.has_id));
    setPromotes(selectAllChecked ? [] : updatedPromotes);
  };

  const togglePromotion = (student, sem_id) => {

    const isPromoted = promotes?.some((student) => student.student_semester_id === sem_id);

    if (isPromoted) {
      setPromotes((prevPromotes) => prevPromotes?.filter((student) => student.student_semester_id !== sem_id));
    } else {
      setPromotes((prevPromotes) => [...prevPromotes,student]);
    }
  };

  useEffect(() => {
    console.log('promotes - ', promotes);
  },[promotes]);

  useEffect(() => {
    getClassData();
    getHospitalDepartments();
  }, []);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Assign Hospital</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Assign Hospital</a>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="">Search Criteria</h5>
                    <br />
                    <div className="row">
                    <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Academic Year<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="session_id"
                              id="class"
                              value={user?.session_id}
                              onChange={(e) => {
                                handleChange({
                                    target: { name: "session_id", value: e.target.value },
                                  });
                              }}
                            >
                              <option value="">Select Academic</option>
                              {
                                SessionOpt.map((i, key) => (
                                  <option value={i.id} key={key}>{i.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Select Department
                            <span style={{ color: "red" }}>*</span>
                          </label>

                        <Select
                            value={
                              user?.department_id
                                ? {
                                  value: user?.department_id,
                                  label: departmentOpt.find((data) => data.id == user?.department_id)
                                    ?.name + `(Classes : ${classOpt?.filter((s) => s.department_id == user?.department_id)?.length})`,
                                }
                                : null
                            }
                            onChange={(selectedOption) => {
                              setUser((prev) => ({
                                ...prev,
                                department_id: selectedOption ? selectedOption.value : "",
                              }));
                            }}
                            options={departmentOpt.map((i) => ({
                              value: i.id,
                              label: `${i.name}, ${programOpt?.find((s) => s.id === i.program_id)?.name}` + `(Classes : ${classOpt?.filter((s) => s.department_id == i.id)?.length})`,
                            }))}
                            placeholder="Select Department"
                          />

                        </div>
                      </div>
                      
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Class<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="class_id"
                              id="class"
                              value={user.class_id}
                              onChange={(e) => {
                                handleChange({
                                    target: { name: "class_id", value: e.target.value },
                                  });
                              }}
                            >
                              <option value="">Select Class</option>
                              {
                                classOpt?.filter(s=>s?.department_id==user?.department_id)?.map((i, key) => (
                                  <option value={i.id} key={key}>{i.name} {`(Semesters : ${semOpt?.filter((s) => s.class_id == i.id)?.length})`}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Semester<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="semester_id"
                              id="class"
                              value={user.semester_id}
                              onChange={(e)=> {
                                handleChange({
                                    target: { name: "semester_id", value: e.target.value },
                                });
                            }}
                            >
                              <option value="">Select Semester</option>
                              {
                                semOpt?.filter(s=>s?.class_id==user?.class_id)?.map((i, key) => (
                                  <option value={i?.id} key={key} >{i.name}</option>
                                ))
                              }
                              {/* {
                                classOpt?.map((i, key) => (
                                  <option value={i.id}>{i.name}</option>
                                ))
                              } */}
                            </select>
                          </div>
                        </div>
                      <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Section
                            </label>
                            <select
                              className="form-control"
                              name="section_id"
                              id="class"
                              value={user?.section_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Section</option>
                              {
                                sectionOpt?.filter(s => (s.department_id == user.department_id && s.semester_id == user.semester_id))?.map((i, key) => (
                                  <option value={i.id} key={key}>{i.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="">Date<span style={{ color: "red" }}>*</span></label>
                            <input
                              type="date"
                              name="date"
                              onChange={handleChange}
                              className="form-control"
                              value={user?.date}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">Patient Department to be Allocated <span style={{ color: "red" }}>*</span></label>
                          <select
                            className="form-control"
                            value={filterDept}
                            onChange={(e) => {
                              setFilterDept(e.target.value)
                            }}
                          >
                            <option value="">Select Patient Department</option>
                            {hospitalDepartments?.map((i,k) => {
                              return (
                                <option value={i}>{i}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ml-auto">
                          <button
                            className="btn btn-nex float-lg-right mx-1 "
                            type="submit"
                            name="submit"
                            onClick={getStudentData}
                          >
                            <i className="fa fa-search" aria-hidden="true" />{" "}
                            Search
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                    
                    </div>
                    
                    <table
                      id="datatable"
                      className="table dt-responsive nowrap table-hover "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >   
                      <thead>
                        <tr>
                            <th>
                              <input
                                type="checkbox"
                                id="select-all"
                                checked={selectAllChecked}
                                onChange={toggleSelectAll}
                              />
                            </th>
                            <th colSpan = {5}></th>
                            <th>{<button className="btn btn-success ml-2" onClick={registerMultipleStudents}>Allocate</button>}</th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th colSpan={2}>Sl. No.</th>
                          <th>Enrollment No.</th>
                          <th>Name</th>
                          <th>Class</th>
                          <th>Semester</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                     <tbody>
                     {
                            data && data?.map((i, k) => (
                              <tr>
                                <td>
                                  {i?.has_id ? <input
                                    type="checkbox"
                                    name="checkbox[]"
                                    checked = {false}
                                    disabled={true}
                                  /> 
                                  : <input
                                  type="checkbox"
                                  name="checkbox[]"
                                //   value={d.user_id}
                                  checked={promotes?.some((student) => student.student_semester_id === i.student_semester_id)}
                                  onChange={() => togglePromotion(i, i.student_semester_id)}
                                />}
                                </td>
                                <td>{k+1}</td>
                                <td>{i?.user_id}</td>
                                <td>{i?.name}</td>
                                <td>{i?.class_name}</td>
                                <td>{i?.semester}</td>
                                <td>
                                {i?.has_id ? 
                                    <>
                                          <span className='badge badge-light mx-2' title="Allocated" data-toggle='tooltip'>Allocated : {i?.department}</span>
                                    </> : 
                                    <button className='btn-success btn mx-2' disabled={selectAllChecked || promotes?.length > 0} onClick={(e) => {handleSubmit(i, "single")}}>Allocate</button>
                                }
                                </td>
                              </tr>
                            ))
                          }
                          
                        </tbody>
                      </table>
                      {data?.length == 0 ?
                        <Nodata />
                        : null
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignHospital;
