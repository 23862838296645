import React, { useState, useEffect } from 'react'
import axios from "axios";
import { toast } from "react-toastify";
import { ANNOUNCEMENTS } from "../../utils/apiConstants";
import { LOCAL_EMPLOYEE } from '../../utils/LocalStorageConstants';
import ModalAnnouncementAttachment from './ModalAnnouncementAttachment';
import { Input, DatePicker, Form, Button, FormInstance } from "antd";
import moment from 'moment';
import { UploadOutlined, EyeOutlined } from '@ant-design/icons';
import { message, Upload, Modal } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
function ModalAnnouncementView(props, { title, img, setLink }) {

    const [alldata, setAllData] = useState([])


    const employee_id = sessionStorage.getItem("role");

    const getAlldata = async () => {
        try {
            const config = {
                method: "get",
                url: `${ANNOUNCEMENTS}/${props.data.id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                },
            };

            const res = await axios(config);
            setAllData(res.data.data);
        } catch (err) {
            console.error(err);
            toast.error("Something Went Wrong");
        }
    };

    useEffect(() => {
        if (props.data) {

            getAlldata();
        }
    }, [props.data]);






    const [modalTitle, setModalTitle] = useState("");
    const [modalLink, setModalLink] = useState("");

    return (
        <div className="ModalAnnouncementView">
         

            <div
                className="modal fade"
                id="ModalAnnouncementView"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-xl"
                    role="document"
                >
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h5 className="modal-title fw-2" id="exampleModalLongTitle">
                                Announcement View
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span
                                    aria-hidden="true"
                                    onClick={() => {
                                        setLink("");
                                    }}
                                >
                                    ×
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">





                            {/* {alldata?.map((item, index) => (<>
                                <div key={index} className="col-12">
                                    <div className="inputBox">
                                        <p><b>Title</b></p>
                                        <input
                                            type="text"
                                            value={item.title}
                                            readOnly
                                            className="inputField"
                                        />

                                    </div>
                                </div>
                                <div key={item.id || index} className="col-md-12 "  >
                                    <div className="row mt-4">
                                        <div className="col-md-3">
                                            <p><b>From Date</b></p>
                                            <DatePicker
                                                  inputReadOnly
                                                  readOnly
                                                className="form-control"
                                                value={moment(item.from_date)}
                                                  format="DD-MM-YYYY"

                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <p><b>To Date</b></p>
                                            <DatePicker
                                              inputReadOnly
                                              readOnly
                                                className="form-control"
                                                value={moment(item.to_date)}
                                                  format="DD-MM-YYYY"
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <p><b>Created On</b></p>
                                            <DatePicker
                                              inputReadOnly
                                              readOnly
                                                className="form-control"
                                                value={moment(item.createdAt)}
                                                  format="DD-MM-YYYY"
                                            />
                                        </div>
                                        <div key={index} className="col-md-3">
                                            <p><b>Created By</b></p>
                                            <div className="inputBox">
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    readOnly
                                                    value={employee_id}
                                                />

                                            </div>
                                        </div>




                                        <div className="col-md-3 mt-3">
                                            <p><b>Attachement</b></p>

                                            <Upload
                                                fileList={[{
                                                    uid: index.toString(),
                                                    name: item.attachment,
                                                    status: 'done',
                                                    url: item.attachment,
                                                }]}
                                                onRemove={() => false} // Disable file removal
                                                beforeUpload={() => false} // Disable additional uploads
                                            >
                                                <Button
                                                    className='form-control'
                                                    icon={<UploadOutlined />}
                                                    value={item.attachment}
                                                    style={{
                                                        width: "255px",
                                                        fontFamily: "sans-serif",
                                                        color: "#d3d3d3",
                                                        textAlign: "start",
                                                        fontWeight: "lighter",

                                                    }}

                                                >Click to Upload</Button>
                                            </Upload>

                                        </div>

                                        <div className="col-md-3 mt-5">
                                            <button
                                              onClick={() => {
                                                console.log(item?.attachment)
                                                setModalLink(item?.attachment);
                                                setModalTitle(item?.title);
                                              }}
                                                data-toggle="modal"
                                                data-target="#ModalAnnouncementAttachment"
                                                className="btn btn-nex btn-sm my-1 ml-3"
                                                title="View"
                                            >
                                                View
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-12 mt-4">
                                    <p><b>Description : </b></p>
                                    <div>
                                        <ReactQuill
                                            theme="snow"
                                            inputReadOnly
                                            readOnly
                                            value={item.description}
                                            placeholder="Write Detailed Description of Announcement Here..."
                                        />
                                    </div>
                                </div>
                            </>))} */}


                            <table
                                id="datatable"
                                className="table table-bordered  table-hover"
                                style={{
                                    borderCollapse: "collapse",
                                    borderSpacing: 0,
                                    width: "100%",
                                }}
                            >
                                {alldata?.map((item, index) => (<>

                                    <tr>
                                        <th>Title</th>
                                        <td colSpan={5}>{item?.title}</td>
                                    </tr>

                                    <tr>
                                        <th>Anncouncement For</th>
                                        <th>From Date</th>
                                        <th>To Date</th>
                                        <th>Created on</th>
                                        <th>Created by</th>
                                        <th>Attachment</th>
                                    </tr>

                                    <tr>
                                        <td>{item.students != 0 && item.staff != 0 && item.parents != 0 ? "Staff, Student and Parent" :
                                            item.students != 0 && item.staff != 0 && item.parents == 0 ? "Staff and Student " :
                                                item.students != 0 && item.staff == 0 && item.parents != 0 ? "Student and Parent" :
                                                    item.students == 0 && item.staff != 0 && item.parents != 0 ? "Staff and Parents" :
                                                        item.students == 0 && item.staff != 0 && item.parents == 0 ? "Staff" :
                                                            item.students != 0 && item.staff == 0 && item.parents == 0 ? "Student" :
                                                                item.students == 0 && item.staff == 0 && item.parents != 0 ? "Parent" : ""}</td>
                                        <td>{item?.from_date?.split('T')[0]}</td>
                                        <td>{item?.to_date?.split('T')[0]}</td>
                                        <td>{item?.createdAt?.split('T')[0]}</td>
                                        <td>{employee_id}</td>
                                        <td>

                                            <button
                                                onClick={() => {
                                                    console.log(item?.attachment)
                                                    setModalLink(item?.attachment);
                                                    setModalTitle(item?.title);
                                                }}
                                                data-toggle="modal"
                                                data-target="#ModalAnnouncementAttachment"
                                                className="btn btn-nex btn-sm my-1 ml-3"
                                                title="View"
                                            >
                                                View
                                            </button>
                                            <ModalAnnouncementAttachment title={modalTitle} img={modalLink} setLink={setModalLink} />
                                        </td>
                                    </tr>
                                    

                                        <th colSpan={6}>Description</th>
                                        <tr>
                                        <td colSpan={6}>
                                            <ReactQuill
                                                theme="snow"
                                                inputReadOnly
                                                readOnly
                                                value={item.description}

                                                placeholder="Write Detailed Description of Announcement Here..."
                                            />
                                        </td>
                                        </tr>

                                </>))}

                            </table>










                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalAnnouncementView
