import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { SessionOpt } from '../../Data/student/sessionData';
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SECTION, ACADEMICS_ADD_SEMESTER, ACADEMICS_TRANSFER_STUDENT, ACADEMICS_PROMOTE_STUDENT } from '../../utils/Academics.apiConst';
import { LOCAL_DEPARTMENT, LOCAL_PROGRAM } from '../../utils/LocalStorageConstants';

import Nodata from "../../Components/NoData/Nodata";
import { ALUMINI_UPLOAD, STUDENT_INFO_PUT, STUDENT_SESSION_GET, STUDENT_SESSION_PUT, STUDENT_SESSION_SEM_GET, STUDENT_SESSION_SEM_PUT } from '../../utils/InfoUploadingApiConstants';
import { STUDENT_SESSION } from '../../utils/apiConstants';


function ChangeBranch({ setLoading, collegeId }) {

  const [departmentOpt, setDepartmentOpt] = useState(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)))
  const [programOpt, setProgramOpt] = useState(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)))


  useEffect(() => {
    setDepartmentOpt(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)).filter(item => item.college_id == collegeId))
  }, [localStorage.getItem(LOCAL_DEPARTMENT)])


  const [classOpt, setClassOpt] = useState([])
  const [classToOpt, setClassToOpt] = useState([]);

  const [semOpt, setSemtOpt] = useState([])
  const [semTOOpt, setSemtToOpt] = useState([])

  const [sectionOpt, setSectionOpt] = useState([])
  const [sectionTOOpt, setSectiontToOpt] = useState([])

  const [data, setData] = useState([])

  const [departmentTo, setDepartmentTo] = useState();

  const [sessionTo, setSessionTo] = useState()

  const [classTo, setClassTo] = useState()

  const [semesterTo, setSemesterTo] = useState()

  const [sectionTo, setSectionTo] = useState()

  const [promotes, setPromotes] = useState([]);

  const [status, setStatus] = useState("ACTIVE");

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const [data3, setData3] = useState("");
  const [data4, setData4] = useState("");
  const [data5, setData5] = useState("");
  const [data6, setData6] = useState("");
  const [data7, setData7] = useState("");

  const [user, setUser] = useState({
    class_id: '',
    semester_id: '',
    department_id: '',
    section_id: '',
    course_id: '',
    employee_id: '',
    time_from: "",
    time_to: "",
    session_id: '',
    to_session_id: '',
    to_semester_id: '',
    to_section_id: ''
  })



  const getClassData = async () => {
    setLoading(1)
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    }

    const [data1, data2, data3] = await Promise.all([
      axios({ ...config, url: ACADEMICS_ADD_SEMESTER + `?college_id=${collegeId}` })
        .then(res => {
          setSemtOpt(res.data.data)
        })
        .catch(err => {
          setLoading(0)
          console.log(err);
          toast.error('Something went wrong')
        }),

      axios({ ...config, url: ACADEMICS_ADD_SECTION + `?college_id=${collegeId}` })
        .then(res => {
          setSectionOpt(res.data.data)
        })
        .catch(err => {
          setLoading(0)
          console.log(err);
          toast.error('Something went wrong')
        }),
      axios({ ...config, url: ACADEMICS_ADD_CLASS + `?college_id=${collegeId}` })
        .then(res => {
          setClassOpt(res.data.data)
        })
        .catch(err => {
          setLoading(0)
          console.log(err);
          toast.error('Something went wrong')
        }),
    ])

    setLoading(0)
  }

  const getData = async () => {

    if(!user.session_id || !user.semester_id || !user.class_id || !user.department_id || !status) {
      toast.error("Please Enter Required Details");
      return;
    }

    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: ACADEMICS_PROMOTE_STUDENT + '/all' + `?session_id=${user.session_id}&section_id=${user?.section_id}&semester_id=${user?.semester_id}&class_id=${user?.class_id}&department_id=${user?.department_id}&status=${status}`
    }

    await axios(config).then(
      res => {
        setData(res.data.data);
        console.log('data - ', res.data.data);
        setLoading(0);
      }
    ).catch(err => {
      setLoading(0)
      console.log(err);
      toast.error('Something went wrong')
    })
    setLoading(0)
  }


  const transferStudent = async (d) => {
    console.log('hi1');
    if(!departmentTo) {
      return toast.error('Please select new department to Transfer');
    }
    else if(!classTo) {
      return toast.error('Please select new class to Transfer');
    }
    else if(!semesterTo) {
      return toast.error('Please select new semester to Transfer');
    }
    else if(!sectionTo) {
      return toast.error('Please select new section to Transfer');
    }
    else if (!sessionTo) {
      return toast.error('Please Select new Session to Transfer');
    }
    else if(departmentTo == user?.department_id) {
      return toast.error('Cannot Transfer to the same department');
    }

    setLoading(1);
    const config = {
      method: 'post',
      url: `${ACADEMICS_TRANSFER_STUDENT}/${d?.user_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        "session_id": sessionTo,
        "student_id": d?.user_id,
        "department_id": departmentTo,
        "class_id": classTo,
        "semester_id": semesterTo,
        "section_id": sectionTo,
        "batch_id": null,
        "route_id": null,
        "hostel_room_id": null,
        "status": status,
        "program_id": d?.program_id,
        "college_id": d?.college_id
      }
    }
    console.log('data to backend - ',{
      "session_id": sessionTo,
      "student_id": d?.user_id,
      "department_id": departmentTo,
      "class_id": classTo,
      "semester_id": semesterTo,
      "section_id": sectionTo,
      "batch_id": null,
      "route_id": null,
      "hostel_room_id": null,
      "status": status,
      "program_id": d?.program_id,
      "college_id": d?.college_id
    });

    await axios(config)
      .then(res => {
        setLoading(0);
        console.log('transfered');
        getData();
        toast.success(`Student ${d?.user_id} Transferred from ${departmentOpt.find((s) => s.id == d?.department_id)?.name} to ${departmentOpt.find((s) => s.id == departmentTo)?.name}`);
      })
      .catch(err => {
        setLoading(0);
        toast.error("Something went wrong");
      })
    
    setLoading(0);
  }

  useEffect(() => {
    getClassData()
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setUser(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const getData2 = async () => {
    setLoading(1);
  
    const config2 = {
      method: "get",
      url: STUDENT_SESSION,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
  
    await axios(config2)
      .then((res) => {
        setData2(res.data.data);
        console.log('student data - ', res.data.data);
        // You may want to update the state or perform any other actions here
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error('Something went wrong');
      });

      const config3 = {
        method: "get",
        url: STUDENT_SESSION_GET,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
        },
      };
    
      await axios(config3)
        .then((res) => {
          setData3(res.data.data);
          console.log('data3 - ', res.data.data);
          // You may want to update the state or perform any other actions here
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error('Something went wrong');
        });

        const config4 = {
          method: "get",
          url: STUDENT_SESSION_SEM_GET,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
      
        await axios(config4)
          .then((res) => {
            setData4(res.data.data);
            console.log('data4 - ', res.data.data);
            // You may want to update the state or perform any other actions here
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error('Something went wrong');
          });

    setLoading(0);
  };

  useEffect(() => {
    getData2();
  },[]);

//   useEffect(() => {
//     console.log('department to - ', departmentTo);
//   },[departmentTo]);

  return (
    <div>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">

            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Student Department Transfer</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Admission</a>
                      </li>
                      <li className="breadcrumb-item active">Student Department Transfer</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <>
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="card-title text-danger">Transfer Student From</h2>
                      <hr />
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Department <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="department_id"
                              id="class"
                              value={user.department_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Department</option>
                              {
                                departmentOpt.map((i, key) => (
                                  <option value={i.id} key={key}>{i.name}, {programOpt.find(item => item.id == i.program_id)?.name} (Class : {classOpt?.filter((s) => s.department_id == i.id)?.length})</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Academic Year<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="session_id"
                              id="class"
                              value={user.session_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Academic Year</option>
                              {
                                SessionOpt.map((i, key) => (
                                  <option value={i.id} key={key}>{i.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Class<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="class_id"
                              id="class"
                              value={user.class_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Class</option>
                              {
                                classOpt?.filter(s=>s?.department_id==user?.department_id)?.map((i, key) => (
                                  <option value={i.id} key={key}>{i.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Semester<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="semester_id"
                              id="class"
                              value={user.semester_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Semester</option>
                              {
                                semOpt?.filter(s=>s?.class_id==user?.class_id)?.map((i, key) => (
                                  <option value={i?.id} key={key} >{i.name}</option>
                                ))
                              }
                              {/* {
                                classOpt?.map((i, key) => (
                                  <option value={i.id}>{i.name}</option>
                                ))
                              } */}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Section<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="section_id"
                              id="class"
                              value={user?.section_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Section</option>
                              {
                                sectionOpt?.filter(s => (s.department_id == user.department_id && s.semester_id == user.semester_id))?.map((i, key) => (
                                  <option value={i.id} key={key}>{i.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">Status<span style={{ color: "red" }}>*</span></label>
                            <select
                              className="form-control"
                              value={status}
                              onChange={(e) => {
                                setStatus(e.target.value);
                              }}
                            >
                              <option value="">Select Status</option>
                              <option value="ACTIVE">Active</option>
                              <option value="INACTIVE">Inactive</option>
                            </select>
                          </div>
                        </div>
                        {/* <div className="col-md-4"></div> */}
                        <div className="col-md-4"></div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4 d-flex align-items-center justify-content-end">
                          <button
                            className="btn btn-nex btn-rounded float-lg-left "
                            onClick={getData}
                          // style={{maxHeight:'40px'}}
                          >
                            <i className="fa fa-search" aria-hidden="true" /> Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end card */}
                </div>
              </div>
              {/* container-fluid */}
            </>

            {/* end card */}
            {/* container-fluid */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title text-danger">Transfer Student To</h4>
                      </div>
                      <div className="col-md-8 ">
                        <span className="float-right">
                          <acronym title="PDF">
                            {" "}
                            <a href="#">
                              <i className="fa fa-file-pdf-o " aria-hidden="true" />
                            </a>
                          </acronym>
                          <a href="#"> </a> &nbsp;{" "}
                          <acronym title="Excel">
                            <a href="#">
                              {" "}
                              <i className="fa fa-file-excel-o" aria-hidden="true" />
                            </a>
                          </acronym>
                          <a href="#"> </a>
                        </span>
                      </div>
                    </div>{" "}
                    <hr />
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                            <label htmlFor="validationCustom02">
                                Department<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                                className="form-control"
                                name="department_id"
                                id="class"
                                value={departmentTo}
                                onChange={(e) => setDepartmentTo(e.target.value)}
                            >
                                <option value="">Select Department</option>
                                {
                                departmentOpt.map((i, key) => (
                                    <option value={i.id} key={key}>{i.name}, {programOpt.find(item => item.id == i.program_id)?.name}</option>
                                ))
                                }
                            </select>
                            </div>
                        </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Academic Year<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="to_session_id"
                            id="class"
                            value={sessionTo}
                            onChange={(e) => { setSessionTo(e.target.value) }}
                          >
                            <option value="">Select Academic</option>
                            {
                              SessionOpt.map((i, key) => (
                                <option value={i.id} key={key}>{i.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Class<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="to_section_id"
                            id="class"
                            value={classTo}
                            onChange={(e) => { setClassTo(e.target.value) }}
                          >
                            <option value="">Select Class</option>
                            {
                              classOpt?.filter(s=>s?.department_id==departmentTo)?.map((i, key) => (
                                <option value={i.id} key={key}>{i.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Semester<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="to_semester_id"
                            id="class"
                            value={semesterTo}
                            onChange={(e) => { setSemesterTo(e.target.value) }}
                          >
                            <option value="">Select Semester</option>
                            {
                              semOpt?.filter(s => s.class_id == classTo)?.map((i, key) => (
                                <option value={i.id} key={key}>{i.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Section<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="to_semester_id"
                            id="class"
                            value={sectionTo}
                            onChange={(e) => { setSectionTo(e.target.value) }}
                          >
                            <option value="">Select Section</option>
                            {
                              sectionOpt?.filter(s => s.semester_id == semesterTo)?.map((i, key) => (
                                <option value={i.id} key={key}>{i.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                    <hr />{" "}
                    <h5>Students List</h5>
                    {/* <button>Pass</button> */}
                    <div className="table-responsive dt-responsive">
                      <table
                        id="datatable"
                        className="table  nowrap table-hover  "
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          boarder: 0,
                          width: "100%"
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sl No.</th>
                            <th className="text-wrap">
                              Enrollment Number
                            </th>
                            <th className="text-wrap">Name</th>
                            <th>Department</th>
                            <th>Class</th>
                            <th>Semester</th>
                            <th>Section</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            data && data.map((d, k) => (
                              <tr>
                                <td>{k+1}</td>
                                <td className="text-wrap">{d?.user_id}</td>
                                <td className="text-wrap">{d?.name}</td>
                                <td>{d?.dept_name}</td>
                                <td>{d?.class_name}</td>
                                <td>{d?.semester_id ? semOpt?.find(s => s.id == d?.semester_id)?.name : null}</td>
                                <td>{d?.section_id ? sectionOpt?.find(s => s.id == d?.section_id)?.name : null}</td>
                                <td>
                                  <button className="btn btn-success" onClick={() => {transferStudent(d)}}>Transfer</button>
                                </td>
                              </tr>
                            ))
                          }
                          
                        </tbody>
                      </table>
                      {data?.length == 0 ?
                        <Nodata />
                        : null
                      }

                    </div>

                  </div>
                </div>
              </div>
            </div>






          </div>
        </div>
      </div>


    </div>
  )
}

export default ChangeBranch;