import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getUniversityDetails from "../../utils/universityDetails.api";

import { FORGOT_PASSWORD, Login_Student } from "../../utils/apiConstants";
import { ROUTES } from "../../Router/routerConfig";
import {
  CLASS_ID,
  COLLEGE,
  DEPARTMENT,
  PROGRAM,
  SECTION_ID,
  SEMESTER_ID,
  UID,
  USER_AUTH,
} from "../../Data/navbar/StudentPortal/storageConstant";

function LoginStudent() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [universityData, setUniversityData] = useState([]);

  const [loading, setLoading] = useState(0);

  const [selected, setSelected] = useState("login");

  const navigate = useNavigate();

  // LOGIN
  const login = async (e) => {
    e.preventDefault();

    if (!userName) {
      toast.error("Email is required");
      return;
    } else if (!password) {
      toast.error("Password is required");
      return;
    }

    const loginObj = {
      id: userName,
      password,
    };

    var config = {
      method: "post",
      url: Login_Student,
      headers: {
        "Content-Type": "application/json",
      },
      data: loginObj,
    };

    setLoading(1);

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success(`User LoggedIn successfully`);
        console.log("res.data.data -", res);
        navigate(ROUTES.StudentPortal.Profile);
        sessionStorage.setItem(USER_AUTH, res.data.data.token);
        localStorage.setItem(USER_AUTH, res.data.data.token);
        localStorage.setItem("noty", true);
        sessionStorage.setItem(COLLEGE, res.data.data.college);
        sessionStorage.setItem(PROGRAM, res.data.data.program);
        sessionStorage.setItem(DEPARTMENT, res.data.data.department);
        sessionStorage.setItem(UID, res.data.data.id);
        sessionStorage.setItem(CLASS_ID, res.data.data.class_id);
        sessionStorage.setItem(SEMESTER_ID, res.data.data.semester_id);
        sessionStorage.setItem(SECTION_ID, res.data.data.section_id);
        sessionStorage.setItem("is_provisional", res.data.data.is_provisional);
        sessionStorage.setItem("is_hostel", res.data.data.is_hostel);
      })
      .catch((err) => {
        setLoading(0);
        localStorage.setItem("Student_Login_Id", err.response.data?.id);
        toast.error(err.response.data?.message);
        console.log(err);
      });
  };

  const handeResetPassword = async (e) => {
    e.preventDefault();
    setLoading(1);
    console.log(userName);
    if (!userName) return toast.error("Email is Required");
    const config = {
      method: "post",
      url: FORGOT_PASSWORD,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: userName,
      },
    };

    await axios(config)
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
    setLoading(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        console.log("unversiy data - ", data);
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="Login" style={{ maxWidth: "100vw", overflow: "hidden" }}>
      <Loader loading={loading} />
      <>
        <div>
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-4 m-auto">
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <div className="row justify-content-center">
                      <div className="col-lg-9">
                        <div>
                          <div className="text-center">
                            <div>
                              <a href="index.php" className="logo mb-5">
                                <img
                                  src={universityData.logo_primary}
                                  height={100}
                                  alt="logo"
                                />
                              </a>
                            </div>
                            <h4 className="font-size-18 mt-4">Welcome !</h4>
                            <p className="text-muted">
                              Sign in to continue to the <br />
                              {universityData.name} Portal.
                            </p>
                          </div>
                          <div className="p-2 mt-5">
                            {selected == "login" ? (
                              <form className="form-horizontal">
                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-user-2-line auti-custom-input-icon" />
                                  <label htmlFor="username">User Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    name="Email"
                                    placeholder="Enter User Name"
                                    value={userName}
                                    onChange={(e) => {
                                      setUserName(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon" />
                                  <label htmlFor="userpassword">Password</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="userpassword"
                                    name="userpassword"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => {
                                      setPassword(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="mt-4 text-center">
                                  <button
                                    className="btn btn-primary btn-block w-md waves-effect waves-light"
                                    type="submit"
                                    name="submit"
                                    value="login"
                                    onClick={login}
                                  >
                                    Log In
                                  </button>
                                </div>
                                <div className="mt-4 text-center">
                                  <a
                                    onClick={() => setSelected("forgot")}
                                    className="cursor-pointer text-muted"
                                  >
                                    <i className="mdi mdi-lock mr-1" /> Forgot
                                    your password?
                                  </a>
                                </div>
                              </form>
                            ) : (
                              <form className="form-horizontal">
                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon" />
                                  <label htmlFor="userpassword">Email</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Email"
                                    value={userName}
                                    name="userName"
                                    onChange={(e) => {
                                      setUserName(e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="mt-4 text-center">
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                    name="submit"
                                    value="login"
                                    onClick={handeResetPassword}
                                  >
                                    Send Reset Link
                                  </button>
                                </div>

                                <div className="mt-5 text-center">
                                  <a
                                    onClick={() => {
                                      setSelected("login");
                                    }}
                                    className="cursor-pointer text-muted"
                                  >
                                    Go to Login
                                  </a>
                                </div>
                              </form>
                            )}
                          </div>
                          <div className="mt-5 text-center">
                            <p>
                              © {new Date().getFullYear()} {universityData.name}
                              . <br />
                              Crafted with{" "}
                              <i className="mdi mdi-heart text-danger" /> by{" "}
                              <a href="https://www.nexenstial.com/">
                                {" "}
                                Nexenstial{" "}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-8 d-sm-flex justify-content-center align-items-center d-none "
                style={{ maxHeight: "100vh", overflow: "hidden" }}
              >
                <div
                  id="carousel-example-1z"
                  className="carousel slide mb-5"
                  data-ride="carousel"
                >
                  {/*Indicators*/}
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carousel-example-1z"
                      data-slide-to={0}
                      className="active"
                    />
                    <li data-target="#carousel-example-1z" data-slide-to={1} />
                    <li data-target="#carousel-example-1z" data-slide-to={2} />
                    <li data-target="#carousel-example-1z" data-slide-to={3} />
                  </ol>
                  {/*/.Indicators*/}
                  {/*Slides*/}
                  <div className="carousel-inner" role="listbox">
                    {/*First slide*/}
                    <div
                      className="carousel-item active"
                      style={{ height: "100vh" }}
                    >
                      <img
                        className="d-block w-100 h-100"
                        src={universityData.banner_img_1}
                        alt="First slide"
                      />
                    </div>
                    {/*/First slide*/}
                    {/*Second slide*/}
                    <div className="carousel-item" style={{ height: "100vh" }}>
                      <img
                        className="d-block w-100 h-100"
                        src={universityData.banner_img_2}
                        alt="Second slide"
                      />
                    </div>
                    {/*/Second slide*/}
                    {/*Third slide*/}
                    <div className="carousel-item" style={{ height: "100vh" }}>
                      <img
                        className="d-block w-100 h-100"
                        src={universityData.banner_img_3}
                        alt="Third slide"
                      />
                    </div>
                    {/*/Third slide*/}
                    {/*Third slide*/}
                    <div className="carousel-item" style={{ height: "100vh" }}>
                      <img
                        className="d-block w-100 h-100"
                        src={universityData.banner_img_4}
                        alt="Third slide"
                      />
                    </div>
                    {/*/Third slide*/}
                  </div>
                  {/*/.Slides*/}
                  {/*Controls*/}
                  <a
                    className="carousel-control-prev"
                    href="#carousel-example-1z"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carousel-example-1z"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Next</span>
                  </a>
                  {/*/.Controls*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default LoginStudent;
