import React, { useState, useEffect } from "react";
import { SESSION_AUTH } from "../../utils/sessionStorageContants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/routerConfig";
import getUniversityDetails from "../../utils/universityDetails.api";

function AdmintoExamination() {
  //UNIVERSITY DETAILS START
  const [universityData, setUniversityData] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const data = await getUniversityDetails();
      setUniversityData(data);
      console.log(data);
      const auth = sessionStorage.getItem(SESSION_AUTH);
      // window.location = 'https://exams.swaminarayanuniversity.ac.in/login/auth/'+auth
      window.open(`http://nexcorpdemoexam-production.up.railway.app/login/auth/` + auth, "_blank");

      navigate(ROUTES.Registar.dashboard);
      // window.location = 'http://localhost:3000/login/auth/'+auth
    } catch (error) {
      console.error(`Error fetching university details: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //UNIVERSITY DETAILS END

  // const redirect = () => {
  //   const auth = sessionStorage.getItem(SESSION_AUTH);
  //   // window.location = 'https://exams.swaminarayanuniversity.ac.in/login/auth/'+auth
  //   window.open(`${universityData.exams_url}/login/auth/` + auth, "_blank");

  //   navigate(ROUTES.Registar.dashboard);
  //   // window.location = 'http://localhost:3000/login/auth/'+auth
  // };

  return (
    <div>
      <div className="p-5">Redirecting............</div>
    </div>
  );
}

export default AdmintoExamination;
