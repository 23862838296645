import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { format } from "date-fns";
import { SESSION_ROLE } from "../utils/sessionStorageContants";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../utils/LocalStorageConstants";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
} from "../utils/Academics.apiConst";
import {
  EMPLOYEE_ALL,
  STUDENT_ADMISSION,
  STUDENT_ADVANCE_PAY,
  STUDENT_SESSION_UPDATE,
} from "../utils/apiConstants";
import {
  STUDENT_GATE_PASS_UPLOAD,
  STUDENT_SESSION_GET,
} from "../utils/InfoUploadingApiConstants";
import { HOSTEL, HOSTEL_ROOMS } from "../utils/Hostel.apiConst";
import { ADDHOSTELFEE } from "../utils/fees.apiConst";

function GatePass({ setLoading, collegeId }) {
  let role = sessionStorage.getItem(SESSION_ROLE);
  const locate = useLocation();

  const [adv, setAdv] = useState([]);
  const [data, setData] = useState([]);

  var emp_id = sessionStorage.getItem("employee_id");

  const navigate = useNavigate();

  const [x, setX] = useState(false);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);

  const [flag, setFlag] = useState(false);

  const [classOpt, setClassOpt] = useState([]);

  const [sectionOpt, setSectionOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [faculty, setFaculty] = useState("");

  const [currentclass, setCurrentClass] = useState("");

  const [currentSemester, setCurrentSemester] = useState("");

  const [currentSection, setCurrentSection] = useState("");

  const [session, setSession] = useState("");

  const [sessions, setSessions] = useState("");
  const [hostel, setHostel] = useState("");
  const [room, setRoom] = useState("");

  const [employee, setEmployee] = useState("");

  const [emp, setEmp] = useState("");

  const [userId, setUserId] = useState("");
  const [studentData, setStudentData] = useState([]);

  const [searchClicked, setSearchClicked] = useState(false);

  const [initialLoad, setInitialLoad] = useState(true);

  const getAllData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    const [data1, data2, data3, data4] = await Promise.all([
      await axios({
        ...config,
        url:
          emp_id != 316
            ? `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}`
            : `${ACADEMICS_ADD_CLASS}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),

      await axios({
        ...config,
        url:
          emp_id != 316
            ? `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}`
            : `${ACADEMICS_ADD_SEMESTER}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),

      await axios({
        ...config,
        url:
          emp_id != 316
            ? `${ACADEMICS_ADD_SECTION}?college_id=${collegeId}`
            : `${ACADEMICS_ADD_SECTION}`,
      })
        .then((res) => {
          setSectionOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),

      await axios({
        ...config,
        url: STUDENT_ADVANCE_PAY,
      })
        .then((res) => {
          setLoading(0);
          setAdv(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),
    ]);
    const config1 = {
      method: "get",
      url: EMPLOYEE_ALL,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        setEmployee(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: STUDENT_SESSION_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setSessions(res.data.data);
        // console.log("session data -",res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config3 = {
      method: "get",
      url: HOSTEL,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config3)
      .then((res) => {
        setHostel(res.data.data);
        // console.log("hostel data -",res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config4 = {
      method: "get",
      url: `${HOSTEL_ROOMS}?status=OCCUPIED`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config4)
      .then((res) => {
        setRoom(res.data.data);
        console.log("room data -", res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    if (locate?.state) {
      setCurrentClass(locate.state?.class);
      setCurrentSection(locate.state?.section);
      setCurrentSemester(locate.state?.sem);
      setSession(locate.state?.session);
      setFaculty(locate.state?.depart);
    }
  }, [locate?.state]);

  const handleChange = async (sid, h, t) => {
    setX(true);
    const config = {
      method: "put",
      url: `${STUDENT_SESSION_UPDATE}/${sid}`,
      data: {
        hostel: h,
        transport: t,
        session_id: session,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStudentData = async () => {
    setLoading(1);
    setData([]);

    const config = {
      method: "get",
      url: `${STUDENT_ADMISSION}/${userId}`,
      data: {
        user_id: userId,
      },
      headers: {
        Authorization: `Bearer  ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const config1 = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);
      // console.log("student data - ", res?.data);
      let temp = res?.data;

      // Filter only "ACTIVE" sessions
      let activeSession = temp?.session?.filter((s) => s?.status === "ACTIVE");

      // Check if there are any active sessions
      if (activeSession.length > 0) {
        setFaculty(res?.data?.college_id);
        setCurrentClass(activeSession[0]?.class_id);
        setSession(activeSession[0]?.session_id);
        let obj = {};
        // obj["id"] = temp?.data?.id;
        obj["id"] =
          temp?.semester[temp?.semester.length - 1]?.student_session_id;
        obj["class_name"] = temp?.session[temp?.session.length - 1]?.class_name;
        obj["department_id"] = temp?.data?.department_id;
        obj["class_id"] = activeSession[0]?.class_id;
        obj["session_id"] = activeSession[0]?.session_id;
        obj["semester_id"] =
          temp?.semester[temp?.semester.length - 1]?.semester_id;
        obj["section_id"] =
          temp?.semester[temp?.semester.length - 1]?.section_id;
        obj["user_id"] = temp?.data?.user_id;
        obj["name"] = temp?.data?.name;
        obj["email"] = temp?.data?.email;
        obj["phone"] = temp?.data?.phone;
        obj["dob"] = temp?.data?.dob?.substring(0, 10);
        obj["gender"] = temp?.data?.gender;
        obj["category"] = temp?.data?.category;
        obj["is_hostel"] = activeSession[0]?.is_hostel;
        obj["is_transport"] = activeSession[0]?.is_transport;
        obj["status"] = temp?.data?.status;
        obj["college_name"] = collegeOpt.find(
          (s) => s.id == temp?.data?.college_id
        ).name;
        obj["department_name"] = department.find(
          (s) => s.id == temp?.data?.department_id
        )?.name;
        console.log("obj - ", obj);
        setStudentData(obj);
        let targetDiv = document.getElementById("datatable");
        // targetDiv.scrollIntoView({ behavior: "smooth" });
      } else {
        // Handle the case when there are no active sessions
        // You might want to set default values or display a message
        console.log("No active sessions found");
      }

      setLoading(0);
    } catch (err) {
      setLoading(0);
      console.log(err);
    }

    try {
      const res = await axios({
        ...config1,
        url: STUDENT_ADVANCE_PAY,
      });
      // console.log("adv - ", res.data.data);
      setAdv(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const [flag1, setFlag1] = useState(false);
  const [hostelFeeData, setHostelFeeData] = useState([]);
  const [studentfloor, setStudentFloor] = useState("");

  useEffect(() => {
    // Fetch hostel fee data
    const fetchHostelFeeData = async () => {
      const config = {
        method: "get",
        url: ADDHOSTELFEE,
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await axios(config);
        setHostelFeeData(response.data.data);
        console.log("hostelfeedata -", response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchHostelFeeData();
  }, []);

  const [user, setUser] = useState({
    purpose_type: "",
    out_from_date: "",
    out_to_date: "",
    out_going_purpose: "",
    request_remark: "",
    approved_by_1: "",
    // approved_date_1: "",
    approved_date_1: format(new Date(), "yyyy-MM-dd"),
    // approval_status_1: "",
    approval_status_1: "APPROVED",
    approved_by_2: "",
    approved_date_2: "",
    approval_status_2: "PENDING",
    status: "ACTIVE",
  });

  const clearData = () => {
    setUser((prev) => ({
      ...prev,
      request_date: "",
      req_created_by: "",
      purpose_type: "",
      out_from_date: "",
      out_to_date: "",
      out_going_purpose: "",
      request_remark: "",
      approved_by_1: "",
      approved_date_1: "",
      approval_status_1: "",
      approved_by_2: "",
      approved_date_2: "",
      approval_status_2: "",
      status: "ACTIVE",
    }));
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const employee_id = sessionStorage.getItem("employee_id");
  // console.log("employee_id",employee_id);

  const handleSubmit = async () => {
    if (!user?.approved_by_1 || !user?.approved_by_2) {
      toast.error("Please Enter Required Fields");
      return;
    }
    const config = {
      method: "post",
      url: STUDENT_GATE_PASS_UPLOAD,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        student_id: studentData?.user_id,
        college_id: collegeId,
        student_session_id: studentData?.id,
        no_of_days: days,
        req_created_by: employee_id,
        request_date: new Date(),
      },
    };

    console.log("studentData?.student_session_id - ", studentData?.id);

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data added successfully");
        clearData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const calculateDays = () => {
    const fromDate = new Date(user.out_from_date);
    const toDate = new Date(user.out_to_date);

    // Check if both dates are selected
    if (!user.out_from_date || !user.out_to_date || fromDate > toDate) {
      return 0;
    }

    // Set both dates to midnight
    fromDate.setHours(0, 0, 0, 0);

    // Set toDate to the end of the day (23:59:59)
    toDate.setHours(23, 59, 59, 999);

    // Calculate the difference in milliseconds
    const timeDifference = toDate.getTime() - fromDate.getTime();

    // Calculate the number of days
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Round to the nearest whole number
    const numberOfDays = Math.round(daysDifference);

    return numberOfDays >= 0 ? numberOfDays : 0;
  };

  const days = calculateDays();

  // Inside your component function
  const handleSearchClick = () => {
    setSearchClicked(true);
    getStudentData(); // Call your existing getStudentData function
  };

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> STUDENT GATE PASS </h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">
                      Search By Enrollment Number
                      <span style={{ color: "red" }}>*</span>
                    </h2>
                    <br />

                    <div className="input-group mb-3 col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Student Enrollment No"
                        value={userId}
                        onChange={(e) => {
                          setUserId(e.target.value);
                        }}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-nex  btn-md"
                          type="submit"
                          name="submit"
                          value="collect"
                          // onClick={getStudentData}
                          onClick={handleSearchClick}
                        >
                          <i className="fa fa-search mr-1" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                    <br />

                    <br />

                    {searchClicked &&
                    studentData?.user_id &&
                    studentData?.is_hostel == 1 ? (
                      <div className="table-responsive">
                        <h6 className="ml-2 text-primary">STUDENT DETAILS</h6>
                        <table
                          id="datatable"
                          className="table table-bordered  nowrap table-hover"
                          style={{
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <br />
                            <tr>
                              <th>Enrollment No </th>
                              <td>{studentData?.user_id}</td>
                              <th>Name </th>
                              <td>{studentData?.name}</td>
                              <th>Faculty </th>
                              <td>{studentData?.college_name}</td>
                            </tr>
                            <tr>
                              <th>Department </th>
                              <td>{studentData?.department_name}</td>
                              <th>Class </th>
                              <td>{studentData?.class_name}</td>
                              <th>Semester</th>
                              <td>{studentData?.semester_id}</td>
                            </tr>
                            <tr>
                              <th>Session </th>
                              <td>
                                {studentData?.session_id}
                                {/* -
                                          {studentData?.session_id + 1} */}
                              </td>
                              {/* <th>is Hostel</th>
                                        <td>
                                      <input
                                        disabled={
                                          flag == true ? "" : "disabled"
                                        }
                                        type="checkbox"
                                        checked={studentData?.is_hostel}
                                        onChange={() => {
                                          studentData.is_hostel == 1
                                            ? (studentData.is_hostel = 0)
                                            : (studentData.is_hostel = 1);
                                          setFlag1((flag1) => !flag1);
                                          handleChange(
                                            studentData?.user_id,
                                            studentData.is_hostel,
                                          );
                                        }}
                                      />
                                    </td>  */}
                              <th>Hostel </th>
                              <td>
                                {hostelFeeData
                                  .filter(
                                    (fee) => fee.student_id == studentData.id
                                  )
                                  .map((fee) => {
                                    // console.log("fee.bed_no:", fee.bed_no);
                                    const matchingRoom = room?.find((r) => {
                                      // console.log("r.hostelRooms.hostel_no_bed:", r.hostelRooms.hostel_no_bed);
                                      // console.log("r:", r);
                                      return (
                                        r.hostelRooms.hostel_no_bed ==
                                        fee.bed_no
                                      );
                                    });
                                    // console.log("matchingRoom:", matchingRoom);
                                    return matchingRoom
                                      ? matchingRoom.hostel.hostel_name
                                      : null;
                                  })}
                              </td>
                              <th>Floor No </th>
                              <td>
                                {hostelFeeData
                                  .filter(
                                    (fee) => fee.student_id == studentData.id
                                  )
                                  .map((fee) => {
                                    const matchingRoom = room?.find((r) => {
                                      return (
                                        r.hostelRooms.hostel_no_bed ==
                                        fee.bed_no
                                      );
                                    });
                                    return matchingRoom
                                      ? matchingRoom.hostelRooms.floor_number
                                      : null;
                                  })}
                              </td>
                            </tr>
                            <tr>
                              <th>Room Type </th>
                              <td>
                                {hostelFeeData
                                  .filter(
                                    (fee) => fee.student_id == studentData.id
                                  )
                                  .map((fee) => {
                                    const matchingRoom = room?.find((r) => {
                                      return (
                                        r.hostelRooms.hostel_no_bed ==
                                        fee.bed_no
                                      );
                                    });
                                    return matchingRoom
                                      ? matchingRoom.roomType.room_type
                                      : null;
                                  })}
                              </td>
                              <th>Room No </th>
                              <td>
                                {hostelFeeData
                                  .filter(
                                    (fee) => fee.student_id == studentData.id
                                  )
                                  .map((fee) => fee.room_id)}
                              </td>
                              <th>Bed No</th>
                              <td>
                                {hostelFeeData
                                  .filter(
                                    (fee) => fee.student_id == studentData.id
                                  )
                                  .map((fee) => fee.bed_no)}
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    ) : (
                      <div>
                        <h5 className="text-danger">No student data found</h5>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {searchClicked &&
            studentData?.user_id &&
            studentData?.is_hostel == 1 ? (
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="card-title text-info">
                        <b>Request Detail</b>
                      </h2>
                      <br />
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Out Going Purpose Type
                            </label>
                            <select
                              name="purpose_type"
                              id="class"
                              className="form-control"
                              value={user.purpose_type}
                              onChange={handleChange1}
                            >
                              <option value="">Select Purpose Type</option>
                              <option value="LEAVE">Leave</option>
                              <option value="GENERAL">General</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Out Going Purpose
                            </label>
                            <input
                              type="text"
                              name="out_going_purpose"
                              className="form-control"
                              placeholder="Enter Out Going Purpose"
                              value={user.out_going_purpose}
                              onChange={handleChange1}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Request Remark
                            </label>
                            <input
                              type="text"
                              name="request_remark"
                              className="form-control"
                              placeholder="Enter Request Remark"
                              value={user.request_remark}
                              onChange={handleChange1}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Out Going Period
                            </label>
                            <div className="row">
                              <div className="col-md-5">
                                <label htmlFor="validationCustom02">
                                  From Date
                                </label>
                                <input
                                  type="date"
                                  name="out_from_date"
                                  className="form-control"
                                  placeholder="From Date"
                                  value={user.out_from_date}
                                  onChange={handleChange1}
                                />
                              </div>
                              <div className="col-md-5">
                                <label htmlFor="validationCustom02">
                                  To Date
                                </label>
                                <input
                                  type="date"
                                  name="out_to_date"
                                  className="form-control"
                                  placeholder="To Date"
                                  value={user.out_to_date}
                                  onChange={handleChange1}
                                />
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label htmlFor="validationCustom02">
                                    No of Days
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={calculateDays()}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                      <hr />
                      <h2 className="card-title text-info">
                        <b>Approval Detail</b>
                      </h2>
                      <br />
                      {/* <h6>Approval By Hostel Authority [Level-1] </h6> */}
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Approved By
                            </label>
                            <select
                              name="approved_by_1"
                              id="faculty"
                              className="form-control"
                              value={user.approved_by_1}
                              onChange={(e) => {
                                setUser((prevUser) => ({
                                  ...prevUser,
                                  approved_by_1: e.target.value,
                                }));
                              }}
                            >
                              <option value="">Select</option>
                              {employee &&
                                employee
                                  ?.filter((s) => s.college_id == collegeId)
                                  ?.map((i, key) => (
                                    <option value={i.id} key={key}>
                                      {i.first_name + i?.last_name}
                                    </option>
                                  ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Approved Date
                            </label>
                            <input
                              type="date"
                              name="approved_date_1"
                              className="form-control"
                              placeholder="Request Date"
                              value={user.approved_date_1}
                              onChange={handleChange1}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Approval Status
                            </label>
                            <select
                              name="approval_status_1"
                              id="class"
                              className="form-control"
                              value={user.approval_status_1}
                              onChange={handleChange1}
                            >
                              {/* <option value="">Select Approval Status</option> */}
                              <option value="APPROVED">Approved</option>
                              <option value="PENDING">Pending</option>
                              <option value="DECLINED">Declined</option>
                              <option value="CANCELLED">Cancelled</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="row float-right mr-3">
                        <button
                          className="btn btn-nex  btn-md"
                          type="submit"
                          name="submit"
                          onClick={handleSubmit}
                        >
                          <i aria-hidden="true" /> Submit
                        </button>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p></p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GatePass;
