import React, { useState, useEffect } from "react";
import {
  LOCAL_DEPARTMENT,
  LOCAL_USER_ROLES,
  LOCAL_COLLEGE,
} from "../../../utils/LocalStorageConstants";
import axios from "axios";
import { REPORT_STAFF_DETAILS } from "../../../utils/Reports.apiConst";
import { toast } from "react-toastify";

export default function ReportStaffDetails({ setLoading, collegeId }) {
  const [tableData, setTableData] = useState([]);

  //FORM HANDLE START
  const [formData, setFormData] = useState({
    college_id: "all",
    department: "all",
    role: "all",
    gender: "all",
    status: "all",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleSubmit = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${REPORT_STAFF_DETAILS}?college_id=${formData.college_id}&department=${formData.department}&role=${formData.role}&gender=${formData.gender}&status=${formData.status}`,
    };

    await axios(config)
      .then((res) => {
        setTableData(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      }).finally(()=>{
        setLoading(0);
      });
  };

  //FORM HANDLE END

  //DYNAMIC DROPDOWN START

  //>states
  const [facultyData, setFacultyData] = useState(
    JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
  );

  const [departmentData, setDepartmentData] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const [roleData, setRoleData] = useState(
    JSON.parse(localStorage.getItem(LOCAL_USER_ROLES))
  );

  //>useEffect
  useEffect(() => {
    setDepartmentData(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (item) => item.college_id == formData.college_id
      )
    );

    setFormData((prevValue) => {
      return {
        ...prevValue,
        department: "all",
      };
    });
  }, [formData.college_id]);

  //DYNAMIC DROPDOWN END

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Staff Details</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card-body">
                        <div className="card-title">Select Criteria</div>
                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="">Faculty</label>
                              <select
                                className="form-control"
                                name="college_id"
                                onChange={handleChange}
                              >
                                <option value="all">All</option>
                                {facultyData?.map((item) => (
                                  <option value={item?.id} key={item?.id}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="">Department</label>
                              <select
                                className="form-control"
                                name="department"
                                onChange={handleChange}
                              >
                                <option value="all">All</option>
                                {departmentData?.map((item) => (
                                  <option value={item?.id} key={item?.id}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="">Role</label>
                              <select
                                className="form-control"
                                name="role"
                                onChange={handleChange}
                              >
                                <option value="all">All</option>
                                {roleData?.map((item) => (
                                  <option value={item?.id} key={item?.id}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="">Gender</label>
                              <select
                                className="form-control"
                                name="gender"
                                onChange={handleChange}
                              >
                                <option value="all">All</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="others">Others</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="">Status</label>
                              <select
                                className="form-control"
                                name="status"
                                onChange={handleChange}
                              >
                                <option value="all">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                          <button
                            className="btn btn-success"
                            onClick={handleSubmit}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h5>Staff List</h5>
                  </div>
                  <div className="col-md-6">
                    <button className="btn btn-primary float-right">
                      Export
                    </button>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Employee Name</th>
                            <th>Employee ID</th>
                            <th>Designation</th>
                            <th>Department</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Gender</th>
                            <th>Experience</th>
                            <th>Date of Birth</th>
                            <th>Date of Joining</th>
                          </tr>
                        </thead>
                        
                        <tbody>
                          {tableData?.map((item, index) => (
                            <tr key={item.id}>
                              <td>{index+1}</td>
                              <td>{item.first_name+' '+item.last_name}</td>
                              <td>{item.user_id}</td>
                              <td>{item.role}</td>
                              <td>{item.department_name}</td>
                              <td>{item.email}</td>
                              <td>{item.phone}</td>
                              <td>{item.gender}</td>
                              <td>{item.work_experience}</td>
                              <td>{item.dob}</td>
                              <td>{item.date_of_joining}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
