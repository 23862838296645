import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  LOCAL_DEPARTMENT,
  LOCAL_COLLEGE
} from "../utils/LocalStorageConstants";
import { method } from 'lodash';
import {
  GATE_PASS_REQUEST,
  GATE_PASS_REQUEST_QUERY
} from "../utils/InfoUploadingApiConstants";
import HostelModalStudentGatePass2 from "../modals/HostelModalStudentGatePass2";

function GatePassWarden2({ setLoading }) {


  const [collegeOpt, setCollegeOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  useEffect(() => {
    setCollegeOpt(JSON.parse(localStorage.getItem(LOCAL_COLLEGE)))
  }, [localStorage.getItem(LOCAL_COLLEGE)])
  // console.log(collegeOpt);

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  )
  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)))
  }, [localStorage.getItem(LOCAL_DEPARTMENT)])
  // console.log(department);
  const role = sessionStorage.getItem("role")

  const [data, setData] = useState([])
  console.log("data from GPW2-", data);
  let getdata = async () => {
    setLoading(1)
    const config = {

      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${GATE_PASS_REQUEST}`,
      // params:{

      //    role:role,
      // }
    }
    await axios(config)
      .then((res) => {
        setLoading(0);
        setData(res.data.data)
        console.log(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      })
  }
  // console.log("gatepassdata-",data)
  useEffect(() => {
    getdata()
  }, [])
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [alldata, setalldata] = useState()
  const [data2, setData2] = useState("");
  const [flag, setFlag] = useState(false);
  const handleEditClick = async (item) => {
    setData2(item);
    setIsEditModalOpen(true);

  };
  console.log("selectedItem", selectedItem);



  return (
    <div>
      <HostelModalStudentGatePass2
        flag={flag}
        setFlag={setFlag}
        setLoading={setLoading}
        data={data2}
        getData={getdata}
        selectedItem={selectedItem}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> Student Gate Pass Requests </h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <table className="table table-responsive dt-responsive table-bordered nowrap table-hove">
                  {role == "WARDEN" || role== "SUPERADMIN"? (<div>
                    <thead>
                      <tr>
                        <th>Sl.No.</th>
                        <th>Student Name</th>
                        <th>Enrollment No</th>
                        <th>Faculty</th>
                        <th>Department</th>
                        <th>Class</th>
                        <th>Request date</th>
                        <th>Purpose Type</th>
                        <th>Out Going Purpose</th>
                        <th>Out going From date</th>
                        <th>Out going To date</th>
                        <th>No Of Days</th>
                        <th>Proctor status</th>
                        <th>Proctor</th>
                        <th>Date</th>
                        <th>Warden Status</th>
                        <th>Warden</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.map((t, k) => (
                        <tr>
                          <td>{k + 1}</td>
                          <td>{t.Student_Name}</td>
                          <td>{t.Enrollment_No}</td>
                          <td>{t.College_Name}</td>
                          <td>{t.deptname_name}</td>
                          <td>{t.acadamic_classname}</td>
                          <td>{t.request_date}</td>
                          <td>{t.purpose_type}</td>
                          <td>{t.out_going_purpose}</td>
                          <td>{t.out_from_date}</td>
                          <td>{t.out_to_date}</td>
                          <td>{t.no_of_days}</td>
                          <td><p
                            className={` mt-3 badge ${t?.approval_status_1 == "APPROVED"
                              ? "badge-soft-success"
                              : t?.approval_status_1 == "PENDING"
                                ? "badge-soft-warning"
                                : t?.approval_status_1 == "DECLINED"
                                  ? "badge-soft-danger"
                                  : t?.approval_status_1 == "CANCELLED"
                                    ? "badge-soft-danger"
                                    : ""
                              }`}
                            style={{
                              width: "80px",
                              height: "30px",
                              padding: "10px",
                              textAlign: "center",
                              lineHeight: "1.5",
                            }}
                          >
                            {t?.approval_status_1}
                          </p></td>
                         
                            <>
                              <td>{t.first_name} {t.last_name}</td>
                          <td>{t.approved_date_1}</td>
                          <td><p
                            className={` mt-3  ${t?.approval_status_2 == "APPROVED"
                              ? " badge badge-soft-success"
                              : t?.approval_status_2 == "PENDING"
                                ? " badge badge-soft-warning"
                                : t?.approval_status_2 == "DECLINED"
                                  ? " badge badge-soft-danger"
                                  : t?.approval_status_2 == "CANCELLED"
                                    ? " badge badge-soft-danger"
                                    : ""
                              }`}
                            style={{
                              width: "80px",
                              height: "30px",
                              padding: "10px",
                              textAlign: "center",
                              lineHeight: "1.5",
                            }}
                          >
                            {t?.approval_status_2}
                          </p></td>
                          <td>{ }</td>
                          <td>{t.approved_date_2}</td>
                            </>
                         
                          
                          <td>

                            <a
                              className="btn btn-primary btn-nex text-white btn-rounded mr-2"
                              data-toggle="modal"
                              data-target="#HostelStudentGatePass2"
                              type="button"
                              name="submit"
                              onClick={() => {
                                setData2({
                                  ...t,
                                });

                              }}


                            >

                              View

                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    </div>
                  ) : (<></>)}
                 
                 
                  </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GatePassWarden2
