import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SessionOpt } from "../../../Data/student/sessionData";
import HostelModalFee from "../../../modals/Accounts/FeeCollection/HostelFeeModal";
import HostelReturn from "../../../modals/Accounts/FeeCollection/HostelReturnModal";
import { ACADEMICS_ADD_CLASS } from "../../../utils/Academics.apiConst";
import { STUDENT_DETAILS, EMPLOYEE_ALL } from "../../../utils/apiConstants";
import { FEE_DETAILS, ADDHOSTELFEE } from "../../../utils/fees.apiConst";
import HostelRow from "../../Accounts/FeeCollection/HostelFeeCollectionPaymentRow";
import { useReactToPrint } from "react-to-print";
import HostelPrint from "../../Accounts/FeeCollection/Hostelprint";
import { useRef } from "react";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";

const AddHostelFee = ({ setLoading, collegeId }) => {
  const navigate = useNavigate();

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const getDepartmentData = () => {
    return localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [departmentData, setDepartmentData] = useState();

  useEffect(() => {
    setCollegeOpt(getCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  useEffect(() => {
    setDepartmentData(getDepartmentData());
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const [feeData, setFeeData] = useState();

  const [max, setMax] = useState();

  const [fee, setFee] = useState("");

  const [roomId, setRoomID] = useState();

  const [x, setX] = useState(false);

  const [studentData, setStudentData] = useState();

  const [feeassign, setFeeassign] = useState("");

  const [feeassign1, setFeeassign1] = useState("");

  const [feeid, setFeeid] = useState("");

  const [paid, setPaid] = useState();

  const [classData, setClassData] = useState([]);

  const [emp, setEmp] = useState([]);

  const [addData, setAddData] = useState();

  const [returnData, setReturnData] = useState();

  const [printData, setPrintData] = useState();

  const [printSubData, setPrintSubData] = useState();

  const [prevdata, setPrevdata] = useState([]);

  const [hostelFees, setHostelFees] = useState([]);

  const [paymentdetails, setPaymentdetails] = useState([]);

  const printRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => printRef.current,
  });

  const handlePrint = async (dd, ss) => {
    await setPrintData(dd);
    await setPrintSubData(ss);
    PrintRecipt();
  };

  const params = useParams();
  const [searchParams] = useSearchParams();

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios({
      ...config,
      url: `${FEE_DETAILS}?student_session_id=${params.id}`,
    })
      .then((res) => {
        setFeeData(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios({ ...config, url: `${ACADEMICS_ADD_CLASS}` })
      .then((res) => {
        setClassData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    await axios({
      ...config,
      url: `${STUDENT_DETAILS}/${searchParams.get("student_id")}`,
    })
      .then((res) => {
        setStudentData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios({
      ...config,
      url: `${ADDHOSTELFEE}?session_id=${
        params.id
      }&department_id=${searchParams.get(
        "department_id"
      )}&student_id=${searchParams.get("student_session_id")}`,
    })
      .then((res) => {
        console.log("hostel_fees_data", res.data.data);
        setPaymentdetails(res.data.data[0].payment);
        setRoomID(res.data.data[0].room_id);
        setFeeid(res.data.data[0].id);
        setFeeassign(res.data.data[0].amount);
        setFeeassign1(res.data.data[0].amount);
        setHostelFees(res.data.data);
        try {
          let sum = 0;
          for (var i = 0; i < res.data.data[0].payment.length; i++) {
            sum += parseInt(res.data.data[0].payment[i].payment_amount);
          }
          setPaid(sum);
          setMax(parseInt(res.data.data[0].amount) - sum);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await axios({
      ...config,
      url: `${ADDHOSTELFEE}?department_id=${searchParams.get(
        "department_id"
      )}&student_id=${searchParams.get("student_session_id")}`,
    })
      .then((res) => {
        let x = [];
        for (var i = 0; i < res.data.data.length; i++) {
          let amount = 0;
          for (var j = 0; j < res.data.data[i].payment.length; j++) {
            amount =
              amount + parseInt(res.data.data[i].payment[j].payment_amount);
          }
          // amount = 30000
          if (amount != res.data.data[i].amount) {
            x.push({
              year: SessionOpt.find((s) => s.id == res.data.data[i].session_id)
                ?.name,
              paid: amount,
              total: res.data.data[i].amount,
            });
          }
        }
        setPrevdata(x);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios({ ...config, url: EMPLOYEE_ALL })
      .then((res) => {
        setEmp(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  useEffect(() => {
    getData();
  }, []);

  const submit = async () => {
    const obj = {
      student_id: searchParams.get("student_session_id"),
      session_id: params.id,
      amount: fee,
      department_id: searchParams.get("department_id"),
      room_id: roomId,
    };
    const config = {
      method: "post",
      url: ADDHOSTELFEE,
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };
    setLoading(1);
    await axios(config)
      .then(async (res) => {
        console.log(res);
        setLoading(0);
        setFee("");
        await getData();
        toast.success("Successfully added Hostel Fee");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submit1 = async () => {
    console.log(feeassign);
    const obj = {
      amount: fee ? fee : feeassign,
      room_id: roomId,
    };
    setLoading(1);
    const config = {
      method: "put",
      url: `${ADDHOSTELFEE}/${feeid}`,
      headers: {
        "Content-type": "application/json",
      },
      data: obj,
    };
    await axios(config)
      .then(async (res) => {
        console.log(res);
        setLoading(0);
        setFee("");
        setFeeassign("");
        setX(!x);
        await getData();
        toast.success("SuccessFully Updated Hostel Fee");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [x]);

  return (
    <div className="FeeCollectionAddFee">
      <HostelModalFee
        setLoading={setLoading}
        reloadData={getData}
        maxAmount={max}
      />
      <HostelReturn
        setLoading={setLoading}
        reloadData={getData}
        feeid={feeid}
      />

      <div style={{ display: "none" }}>
        <div ref={printRef}>
          <HostelPrint
            mainData={hostelFees}
            empData={emp}
            subData={studentData}
            data={printData}
            collegeOpt={collegeOpt}
            classId={searchParams.get("classId")}
            classData={classData}
            departmentData={departmentData}
          />
        </div>
      </div>

      <div className="main-content">
        <div className="page-content">
          <div className="container">
            {/* start page title */}
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center">
                <button
                  className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <i className="ri-arrow-left-line"></i>
                </button>
                <h4 className="mb-0">HOSTEL FEE LIST</h4>
              </div>
            </div>
            <div className="row gutters">
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="account-settings">
                      <div className="user-profile">
                        <div className="user-avatar text-center">
                          <img
                            src={
                              feeData?.student?.student_picture
                                ? feeData?.student?.student_picture
                                : "https://bootdey.com/img/Content/avatar/avatar7.png"
                            }
                            class="img-fluid ml-auto"
                            alt="Maxwell Admin"
                            style={{ borderRadius: "50%", aspectRatio: "1/1" }}
                            width="50%"
                          />
                        </div>
                        <br />
                        <h5 className="user-name text-center">
                          {studentData?.name}
                        </h5>
                        <h6 className="user-email text-center">
                          {
                            classData?.find(
                              (s) => s.id === feeData?.student?.class_id
                            )?.name
                          }
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="row gutters">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h6 className="mb-2 text-primary">Personal Details</h6>
                        <hr />
                      </div>
                      <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="fullName">Father Name</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="fullName"
                            value={studentData?.father_name}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="eMail">Email</label>
                          <input
                            type="email"
                            className="form-control form-control-sm"
                            id="eMail"
                            value={studentData?.email}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="phone">Phone</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="phone"
                            value={studentData?.phone}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="website">Category</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="class"
                            value={studentData?.caste}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="website">Admission Number</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="class"
                            value={studentData?.user_id}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      {/* <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="website">Roll Number</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="class"
                      value={studentData?.user_id}
                      readOnly={true}
                    />
                  </div>
                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {sessionStorage.getItem("role") != "CASHIER" ? (
              <div className="col-xl-12 mt-3 p-0 col-lg-9 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="row-gutters">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h6 className="mb-2 text-primary">Add Hostel Fee</h6>
                        <hr />
                      </div>
                      <div className="col-xl-4 col-lg-3 d-flex col-md-6 col-sm-6 col-12">
                        <div className="form-group">
                          <label htmlFor="Fee">Fee</label>
                          <input
                            type="Number"
                            className="form-control form-control-sm"
                            min="0"
                            value={fee ? fee : feeassign >= 0 ? feeassign : ""}
                            onChange={(e) => {
                              setFee(e.target.value);
                              setFeeassign("");
                            }}
                          />
                        </div>

                        <div
                          className="form-group"
                          style={{ marginLeft: "1rem" }}
                        >
                          <label htmlFor="Fee">Hostel Room ID</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            min="0"
                            value={roomId ? roomId : ""}
                            onChange={(e) => {
                              setRoomID(e.target.value);
                            }}
                          />
                        </div>
                        <div className="flex items-center justify-center">
                          {feeassign1 >= 0 && hostelFees.length > 0 ? (
                            <button
                              autocomplete="off"
                              onClick={() => submit1()}
                              className="btn btn-nex mt-4 ml-3 align-items-center items-center my-[2rem] btn-sm btn-rounded"
                              type="button"
                            >
                              Update Fee
                            </button>
                          ) : (
                            <button
                              autocomplete="off"
                              onClick={() => submit()}
                              className="btn items-center mt-4 ml-3 btn-nex mx-[1rem] my-[2rem] btn-sm btn-rounded"
                              type="button"
                            >
                              Collect Fee
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {prevdata.length > 1 ? (
              <div className="col-xl-12 mt-3 p-0 col-lg-9 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="row-gutters">
                      <div className="card-title">
                        Previous Year Hostel Due Fees
                      </div>
                    </div>
                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0">
                        <table className="table table-hovered text-wrap">
                          <thead>
                            <tr>
                              <th>Year</th>
                              <th>Due Amount</th>
                              <th>Paid Amount</th>
                              <th>Total Amount</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {prevdata &&
                              prevdata.map((item, key) => {
                                if (
                                  item?.total - item?.paid != 0 &&
                                  item?.year !=
                                    SessionOpt.find((s) => s.id == params.id)
                                      ?.name
                                ) {
                                  return (
                                    <tr>
                                      <td>{item?.year}</td>
                                      <td>{item?.total - item?.paid}</td>
                                      <td>{item?.paid}</td>
                                      <td>{item?.total}</td>
                                      <td>
                                        {item?.total - item?.paid ==
                                        item?.total ? (
                                          <p className="badge badge-danger">
                                            Unpaid
                                          </p>
                                        ) : (
                                          <p className="badge badge-warning">
                                            Partial
                                          </p>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>{" "}
          <br />
          {/* container-fluid */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      {" "}
                      <h4 className="card-title">Student Fee Details</h4>
                    </div>
                    <div className="col-md-8 ">
                      <span className="float-right">
                        <a href="#">
                          <i className="fa fa-file-pdf-o " aria-hidden="true" />
                        </a>{" "}
                        &nbsp;{" "}
                        <a href="#">
                          <i
                            className="fa fa-file-excel-o"
                            aria-hidden="true"
                          />
                        </a>{" "}
                      </span>
                    </div>
                  </div>
                  <hr />

                  <div className="table-rep-plugin">
                    <div className="table-responsive mb-0">
                      <table
                        id="tech-companies-1"
                        className="table table-hovered text-wrap"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        <thead>
                          <tr>
                            <th>Fee Code</th>
                            <th>Due Date</th>
                            <th>status</th>
                            <th>Amount</th>
                            <th>Payment ID</th>
                            <th>Mode</th>
                            <th>Note</th>
                            <th
                              className="text-center"
                              style={{ minWidth: "150px" }}
                            >
                              Date
                            </th>
                            <th>Paid</th>
                            <th>Balance</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {hostelFees &&
                            hostelFees?.map((i, key) => (
                              <>
                                <tr key={key}>
                                  <td id="data2">Hostel Fees</td>
                                  <td>31.08.2022</td>
                                  <td>
                                    {paid == 0 ? (
                                      <p className="badge badge-danger">
                                        Unpaid
                                      </p>
                                    ) : paid == i?.amount ? (
                                      <p className="badge badge-success">
                                        Paid
                                      </p>
                                    ) : (
                                      <p className="badge badge-warning">
                                        Partial
                                      </p>
                                    )}
                                  </td>
                                  <td>
                                    ₹{i?.amount} +{" "}
                                    <span className="text-danger">₹0.00</span>{" "}
                                  </td>
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td>₹{paid}</td>
                                  <td>₹{parseInt(feeassign1) - paid}</td>
                                  <td width="220px">
                                    {" "}
                                    {parseInt(feeassign1) - paid === 0 &&
                                    sessionStorage.getItem("role") !=
                                      "CASHIER" ? (
                                      <a
                                        href=""
                                        className="badge badge-light"
                                        id="fire"
                                        data-toggle="modal"
                                        data-target="#hostelreturn"
                                        title="Return"
                                        onClick={() => {
                                          setReturnData(i);
                                        }}
                                      >
                                        {" "}
                                        <i
                                          className="fa fa-repeat"
                                          aria-hidden="true"
                                        />
                                      </a>
                                    ) : parseInt(feeassign1) - paid !== 0 ? (
                                      <a
                                        href=""
                                        className="badge badge-light "
                                        data-toggle="modal"
                                        data-target="#addhostelfee"
                                        title="Add Hostel Fee"
                                        onClick={() => {
                                          setAddData(i);
                                        }}
                                      >
                                        <i
                                          className="fa fa-plus"
                                          aria-hidden="true"
                                        />
                                      </a>
                                    ) : null}{" "}
                                  </td>
                                </tr>
                                {paymentdetails?.map((d, k) => (
                                  <HostelRow
                                    data={d}
                                    handlePrint={() => handlePrint(d)}
                                  />
                                ))}
                              </>
                            ))}

                          <input type="hidden" id="sn_count" defaultValue={3} />
                        </tbody>
                        <tfoot>
                          <tr className="table-light">
                            <th colSpan={3}>
                              <p className="float-right">Grand Total Amount</p>
                            </th>
                            <th colSpan={5}>
                              ₹{hostelFees[0]?.amount} +{" "}
                              <span className="text-danger">₹0.00 </span>
                            </th>
                            <th>₹{paid}</th>
                            <th colSpan={2}>₹ {parseInt(feeassign1) - paid}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* end col */}
          </div>{" "}
          {/* end row */}
        </div>
        {/* End Page-content */}
      </div>
    </div>
  );
};

export default AddHostelFee;
