import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../../utils/AssetsReferenceTypes";
import InputDoc from "../../InputDoc/InputDoc";

function MscNursing({ data, form_data, set_form_data, setLoading }) {
  const [info, setInfo] = useState({});
  let x;
  const [per1, setPer1] = useState();
  const [per2, setPer2] = useState();
  const [per3, setPer3] = useState();
  const [per4, setPer4] = useState();
  const [total, setTotal] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (data?.form_data) {
      setInfo({
        ...form_data,
      });
    }
  }, [data.form_data]);

  useEffect(() => {
    set_form_data(info);
  }, [info]);

  function calculate() {
    let total_max_1 = 0,
      total_max_2 = 0,
      total_max_3 = 0,
      total_max_4 = 0,
      total_obtained_1 = 0,
      total_obtained_2 = 0,
      total_obtained_3 = 0,
      total_obtained_4 = 0,
      percent_1 = 0,
      percent_2 = 0,
      percent_3 = 0,
      percent_4 = 0;
    if (
      info?.max_internal_1 ||
      info?.max_external_1 ||
      info?.obtained_internal_1 ||
      info?.obtained_external_1
    ) {
      console.log(parseInt(info?.obtained_external_1));
      total_max_1 +=
        parseInt(info?.max_internal_1) + parseInt(info?.max_external_1);
      total_obtained_1 +=
        parseInt(info?.obtained_internal_1) +
        parseInt(info?.obtained_external_1);
      percent_1 = (total_obtained_1 / total_max_1) * 100;
      percent_1 = percent_1.toFixed(3);
      setPer1(percent_1);
      info.percent_1 = percent_1;
    }

    if (
      info?.max_internal_2 ||
      info?.max_external_2 ||
      info?.obtained_internal_2 ||
      info?.obtained_external_2
    ) {
      total_max_2 +=
        parseInt(info?.max_internal_2) + parseInt(info?.max_external_2);
      total_obtained_2 +=
        parseInt(info?.obtained_internal_2) +
        parseInt(info?.obtained_external_2);
      percent_2 = (total_obtained_2 / total_max_2) * 100;
      percent_2 = percent_2.toFixed(3);
      setPer2(percent_2);
      info.percent_2 = percent_2;
    }

    if (
      info?.max_internal_3 ||
      info?.max_external_3 ||
      info?.obtained_internal_3 ||
      info?.obtained_external_3
    ) {
      total_max_3 +=
        parseInt(info?.max_internal_3) + parseInt(info?.max_external_3);
      total_obtained_3 +=
        parseInt(info?.obtained_internal_3) +
        parseInt(info?.obtained_external_3);
      percent_3 = (total_obtained_3 / total_max_3) * 100;
      percent_3 = percent_3.toFixed(3);
      setPer3(percent_3);
      info.percent_3 = percent_3;
    }

    if (
      info?.max_internal_4 ||
      info?.max_external_4 ||
      info?.obtained_internal_4 ||
      info?.obtained_external_4
    ) {
      total_max_4 +=
        parseInt(info?.max_internal_4) + parseInt(info?.max_external_4);
      total_obtained_4 +=
        parseInt(info?.obtained_internal_4) +
        parseInt(info?.obtained_external_4);
      percent_4 = (total_obtained_4 / total_max_4) * 100;
      percent_4 = percent_4.toFixed(3);
      console.log(percent_4);
      setPer4(percent_4);
      info.percent_4 = percent_4;
    }

    if (info.degree == "bsc_nursing") {
      if (per1 || per2 || per3) {
        setPer4("");
        info.obtained_external_4 = "";
        info.obtained_internal_4 = "";
        info.max_external_4 = "";
        info.max_internal_4 = "";

        x =
          (parseInt(percent_1) + parseInt(percent_2) + parseInt(percent_3)) / 3;
        x = x.toFixed(3);
        info.aggregate_percentage = x;
        setTotal(x);
      }
    } else {
      if (per1 && per2 && per3 && per4) {
        x =
          (parseInt(percent_1) +
            parseInt(percent_2) +
            parseInt(percent_3) +
            parseInt(percent_4)) /
          4;
        x = x.toFixed(3);
        info.aggregate_percentage = x;
        setTotal(x);
      }
    }
  }

  const Certificate_upload = async (e, type) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `${info?.user_id}_${type}`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      console.log(d);
      setInfo({
        ...info,
        [e.target.name]: d ? d : "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    calculate();
  }, [info]);

  return (
    <div>
      <div className="register-area mt-2 pb-70">
        <div className="">
          <div className="register" style={{ maxWidth: "100%" }}>
            <form action="">
              <div className="row">
                <div className="col-md-12">
                  <h4> (B) Information of XII (HSC Examination)</h4>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="form-group">
                    <label htmlFor="">Stream</label>
                    <select
                      className="form-control"
                      name="stream"
                      value={info?.stream}
                      onChange={handleChange}
                    >
                      <option value="">Select Stream</option>
                      <option value="Science">Science</option>
                      <option value="Commerce">Commerce</option>
                      <option value="Arts">Arts</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="form-group">
                    <label>Month and Year Of Passing</label>
                    <input
                      type="month"
                      className="form-control"
                      name="month_year"
                      value={info?.month_year}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="form-group">
                    <label>Percentage</label>
                    <input
                      type="number"
                      className="form-control"
                      name="XII_percentage"
                      value={info?.XII_percentage}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h4>(C) Other Academic Details</h4>
                </div>
                <div className="col-md-4 mt-3">
                  <div className="form-group">
                    <label>Select Previous Degree</label>
                    <select
                      className="form-control"
                      name="degree"
                      value={info?.degree}
                      onChange={handleChange}
                    >
                      <option value="">Select Previous Degree</option>
                      <option value="bsc_nursing">Bsc Nursing</option>
                      <option value="PB_bsc_nursing">
                        Post Basic Bsc Nursing
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 mt-3">
                  <div className="form-group">
                    <label>
                      Month and Year Of Passing{" "}
                      {info?.degree == "bsc_nursing"
                        ? "Bsc Nursing"
                        : "Post Basic Bsc Nursing"}
                    </label>
                    <input
                      type="month"
                      className="form-control"
                      name="degree_month_year"
                      value={info?.degree_month_year}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="form-group">
                    <label>Name of State Council Nursing</label>
                    <input
                      type="text"
                      className="form-control"
                      name="state_council_nursing"
                      value={info?.state_council_nursing}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="form-group">
                  <label>
                    Marks Obtained in{" "}
                    {info?.degree == "bsc_nursing"
                      ? "Bsc Nursing"
                      : "Post Basic Bsc Nursing"}{" "}
                    Examination
                  </label>
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th rowSpan={2}></th>
                        <th colSpan={2}>1st Year</th>
                        <th colSpan={2}>2nd Year</th>
                        <th colSpan={2}>3rd Year</th>
                        <th colSpan={2}>4th Year</th>
                        <th rowSpan={2}>External Aggregate Percentage</th>
                      </tr>
                      <tr>
                        <th>Internal</th>
                        <th>External</th>
                        <th>Internal</th>
                        <th>External</th>
                        <th>Internal</th>
                        <th>External</th>
                        <th>Internal</th>
                        <th>External</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Max Marks</th>
                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="max_internal_1"
                            value={info?.max_internal_1}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="max_external_1"
                            value={info?.max_external_1}
                            onChange={handleChange}
                          />
                        </th>

                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="max_internal_2"
                            value={info?.max_internal_2}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="max_external_2"
                            value={info?.max_external_2}
                            onChange={handleChange}
                          />
                        </th>

                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="max_internal_3"
                            value={info?.max_internal_3}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="max_external_3"
                            value={info?.max_external_3}
                            onChange={handleChange}
                          />
                        </th>

                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="max_internal_4"
                            value={info?.max_internal_4}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="max_external_4"
                            value={info?.max_external_4}
                            onChange={handleChange}
                          />
                        </th>
                        <th rowSpan={2}></th>
                      </tr>
                      <tr>
                        <th>Marks Obtained</th>
                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="obtained_internal_1"
                            value={info?.obtained_internal_1}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="obtained_external_1"
                            value={info?.obtained_external_1}
                            onChange={handleChange}
                          />
                        </th>

                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="obtained_internal_2"
                            value={info?.obtained_internal_2}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="obtained_external_2"
                            value={info?.obtained_external_2}
                            onChange={handleChange}
                          />
                        </th>

                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="obtained_internal_3"
                            value={info?.obtained_internal_3}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="obtained_external_3"
                            value={info?.obtained_external_3}
                            onChange={handleChange}
                          />
                        </th>

                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="obtained_internal_4"
                            value={info?.obtained_internal_4}
                            onChange={handleChange}
                          />
                        </th>
                        <th>
                          <input
                            type="number"
                            className="form-control"
                            name="obtained_external_4"
                            value={info?.obtained_external_4}
                            onChange={handleChange}
                          />
                        </th>
                      </tr>
                      <tr>
                        <th>Percentage</th>
                        <td colSpan={2}>{per1}</td>
                        <td colSpan={2}>{per2}</td>
                        <td colSpan={2}>{per3}</td>
                        <td colSpan={2}>{per4}</td>
                        <th colSpan={2}>{info?.aggregate_percentage}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 mt-3">
                  <div className="form-group">
                    <label>Examination Board University</label>
                    <input
                      type="text"
                      className="form-control"
                      name="exam_board"
                      value={info?.exam_board}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="form-group">
                    <label>Total Nursing Experience (In Years)</label>
                    <input
                      type="number"
                      className="form-control"
                      name="nursing_experience"
                      value={info?.nursing_experience}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-3">
                  <div className="form-group">
                    <label>Registration Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="user_id"
                      value={info?.user_id}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <hr className="mt-4" />

              <div className="row mt-2">
                <h4>(D) Upload Documents</h4>
                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"HSC Marksheet"}
                    value={info?.HSC_marks_card}
                    important={true}
                    name={"HSC_marks_card"}
                    onChange={(e) => {
                      Certificate_upload(e, "HSC_marks_card");
                    }}
                  />
                </div>
                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Registration Ceritificate"}
                    value={info?.registeration_certificate}
                    name={"registeration_certificate"}
                    onChange={(e) => {
                      Certificate_upload(e, "registeration_certificate");
                    }}
                  />
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Experience Ceritificate"}
                    value={info?.experience_certificate}
                    name={"experience_certificate"}
                    onChange={(e) => {
                      Certificate_upload(e, "experience_certificate");
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={
                      info?.degree == "bsc_nursing"
                        ? "Bsc Nursing 1st Year Marks Card"
                        : "Post Basic Bsc Nursing" + " 1st Year Marks Card"
                    }
                    value={info?.marks_card_1}
                    name={"marks_card_1"}
                    onChange={(e) => {
                      Certificate_upload(e, "marks_card_1");
                    }}
                  />
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={
                      info?.degree == "bsc_nursing"
                        ? "Bsc Nursing 2nd Year Marks Card"
                        : "Post Basic Bsc Nursing" + " 2nd Year Marks Card"
                    }
                    value={info?.marks_card_2}
                    name={"marks_card_2"}
                    onChange={(e) => {
                      Certificate_upload(e, "marks_card_2");
                    }}
                  />
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={
                      info?.degree == "bsc_nursing"
                        ? "Bsc Nursing 3rd Year Marks Card"
                        : "Post Basic Bsc Nursing" + " 3rd Year Marks Card"
                    }
                    value={info?.marks_card_3}
                    name={"marks_card_3"}
                    onChange={(e) => {
                      Certificate_upload(e, "marks_card_3");
                    }}
                  />
                </div>
              </div>

              <div className="row mt-2">
                {info.degree == "PB_bsc_nursing" ? (
                  <div className="col-lg-4 col-md-6 mt-3">
                    <InputDoc
                      lable={
                        info?.degree == "bsc_nursing"
                          ? "Bsc Nursing 4th Year Marks Card"
                          : "Post Basic Bsc Nursing" + " 4th Year Marks Card"
                      }
                      value={info?.marks_card_4}
                      name={"marks_card_4"}
                      onChange={(e) => {
                        Certificate_upload(e, "marks_card_4");
                      }}
                    />
                  </div>
                ) : null}

                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Transfer Certificate"}
                    value={info?.transfer_certificate}
                    name={"transfer_certificate"}
                    onChange={(e) => {
                      Certificate_upload(e, "transfer_certificate");
                    }}
                  />
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Age Certificate"}
                    value={info?.age_certificate}
                    name={"age_certificate"}
                    onChange={(e) => {
                      Certificate_upload(e, "age_certificate");
                    }}
                  />
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Domicile Certificate"}
                    value={info?.domicile_certificate}
                    name={"domicile_certificate"}
                    onChange={(e) => {
                      Certificate_upload(e, "domicile_certificate");
                    }}
                  />
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Caste Certificate"}
                    value={info?.caste_certificate}
                    name={"caste_certificate"}
                    onChange={(e) => {
                      Certificate_upload(e, "caste_certificate");
                    }}
                  />
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Govt Gazette"}
                    value={info?.Govt_gazette}
                    name={"Govt_gazette"}
                    onChange={(e) => {
                      Certificate_upload(e, "Govt_gazette");
                    }}
                  />
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Degree Certificate"}
                    value={info?.degree_certificate}
                    name={"degree_certificate"}
                    onChange={(e) => {
                      Certificate_upload(e, "degree_certificate");
                    }}
                  />
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"NOC Certificate From Current Working Department"}
                    value={info?.NOC_from_current_working_dept}
                    name={"NOC_from_current_working_dept"}
                    onChange={(e) => {
                      Certificate_upload(e, "NOC_from_current_working_dept");
                    }}
                  />
                </div>
                <div className="col-lg-4 col-md-6 mt-3">
                  <InputDoc
                    lable={"Medical Fitness Certificate"}
                    value={info?.Medical_Fitness_Certificate}
                    name={"Medical_Fitness_Certificate"}
                    onChange={(e) => {
                      Certificate_upload(e, "Medical_Fitness_Certificate");
                    }}
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <h5 className="text-center">
                    <u> UNDERTAKING </u>
                  </h5>
                  <div className="form-group ">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input check mr-5"
                        name="agree"
                        checked={info?.undertaking}
                        onChange={(e) =>
                          setInfo({
                            ...info,
                            undertaking: info?.undertaking ? 0 : 1,
                          })
                        }
                        type="checkbox"
                        defaultChecked=""
                        required=""
                        id="inlineCheckbox1"
                        defaultValue={1}
                      />
                      <p
                        htmlFor="inlineCheckbox1 ml-5"
                        style={{ textAlign: "justify" }}
                      >
                        I / We hereby declare that:
                      </p>
                      <ol>
                        <li>
                          All the information provided by me is true to the best
                          of my knowledge and belief.
                        </li>
                        <li>
                          Any change in any information provided earlier will be
                          informed to the institute with documentary proof.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4">
                  <h5>Place:</h5>
                  <input
                    type="text"
                    className="form-control"
                    name="place"
                    value={info?.place}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4">
                  <h5>Date:</h5>
                  <input
                    type="date"
                    className="form-control"
                    name="date"
                    value={info?.date?.split("T")[0]}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MscNursing;
