import React, { useState, useEffect } from 'react'
import { ADD_EVENT, EMPLOYEE, EVENT_WORKFLOW, EVENT_WORKFLOW_STATUS } from '../../utils/apiConstants';
import axios from "axios";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ModalAnnouncementAttachment from './ModalAnnouncementAttachment';
import { event } from 'jquery';
import { LOCAL_EMPLOYEE } from '../../utils/LocalStorageConstants';
import { Timeline } from "antd";
import './timeline.scss'
import { ClockCircleOutlined } from "@ant-design/icons";


function ViewEventModel(props, { title, img, setLink }) {
    const [alldata, setAllData] = useState()
    // console.log("ALLDATA", alldata)
    // console.log("PROPS", props.data)
    const employee_id = sessionStorage.getItem("role");
    const [modalTitle, setModalTitle] = useState("");
    const [modalLink, setModalLink] = useState("");
    const [remark, setRemark] = useState("");
    const [approval, setapproval] = useState("");

    const getAlldata = async () => {
        try {
            const config = {
                method: "get",
                url: `${ADD_EVENT}/${props.data.id}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                },
            };

            const res = await axios(config);
            setAllData(res.data.data);
        } catch (err) {
            console.error(err);
            toast.error("Something Went Wrong");
        }
    };
    // get employee
    let collegeId = sessionStorage.getItem("college_id")
    let role = sessionStorage.getItem("role")
    // const getEmpData = () => {
    //     // setLoading(1);
    //     const config = {
    //         method: "get",
    //         url: `${EMPLOYEE}?college_id=${collegeId}`,
    //         headers: {
    //             Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
    //         },
    //     };

    //     axios(config)
    //         .then((res) => {
    //             // setLoading(0);
    //             setEmpData(res.data.data);

    //             console.log('employees - ', res.data.data);
    //         })
    //         .catch((err) => {
    //             // setLoading(0);
    //             toast.error(err.response.data.message);
    //             console.log(err);
    //         });
    // };
    const getLocalEmployee = () => {
        return localStorage.getItem(LOCAL_EMPLOYEE)
            ? JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE))
            : null;
    };
    const [emp, setEmpData] = useState(getLocalEmployee)
    const [creatby, getcreateby] = useState()
    useEffect(() => {
        if (props.data) {

            getAlldata();
            getcreateby(props.data.createdBy)
            // getEmpData();
        }
    }, [props.data]);
    const getEmployeeDetails = (approverId) => {
        if (!emp || emp.length === 0) return ' ';

        const matchedEmployee = emp.find((e) => e.id === approverId);

        return matchedEmployee ? `${matchedEmployee.first_name} ${matchedEmployee.last_name} ( ${matchedEmployee.role})` : ' ';
    };
    const [emps, sety] =useState([]);




  const getempData = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${employeeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        sety(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    };
    useEffect(()=>{
      getempData()
    },[])
  

    const getEmployeeNameById = (id) => {
   
        const employee = emps.id == id ;
        return employee == true ? `${emps.first_name} ${emps.last_name}` : 'Unknown';
      };
      
    const extractTextContent = (html) => {
        if (!html) return '';
        return html
            .replace(/<[^>]+>/g, '') // Remove all HTML tags
            .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
            .trim(); // Trim leading and trailing whitespace
    };
    const formatDate = (dateString) => {
        // Your date formatting logic here
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };
    
    const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");
        const seconds = String(currentDate.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

console.log("FITER", props.filteredevent)

    let employeeId = sessionStorage.getItem("employee_id")
    const checkStatus = props.filteredevent && props.filteredevent.length > 0 ? {
        approval_status_1: props.filteredevent[0].approver_1 == employeeId ? approval : props.filteredevent[0].approval_status_1,
        approval_status_2: props.filteredevent[0].approver_2 == employeeId ? approval : props.filteredevent[0].approval_status_2,
        approval_status_3: props.filteredevent[0].approver_3 == employeeId ? approval : props.filteredevent[0].approval_status_3,
        approval_status_4: props.filteredevent[0].approver_4 == employeeId ? approval : props.filteredevent[0].approval_status_4,
        approve_date_1: props.filteredevent[0].approver_1 == employeeId ? getCurrentDate() : "",
        approve_date_2: props.filteredevent[0].approver_2 == employeeId ? getCurrentDate() : "",
        approve_date_3: props.filteredevent[0].approver_3 == employeeId ? getCurrentDate() : "",
        approve_date_4: props.filteredevent[0].approver_4 == employeeId ? getCurrentDate() : "",
        approver_remark_1:props.filteredevent[0].approver_1 == employeeId ? remark :"",
        approver_remark_2:props.filteredevent[0].approver_2 == employeeId ? remark :"",
        approver_remark_3:props.filteredevent[0].approver_3 == employeeId ? remark :"",
        approver_remark_4:props.filteredevent[0].approver_4 == employeeId ? remark :"",
        status:props.filteredevent[0].status,
    } : {};
    // console.log("check stat", checkStatus)
    const { filteredevent } = props;
    const canRenderForm = (event) => {
        const {
            approval_status_1,
            approval_status_2,
            approval_status_3,
            approval_status_4,
            approver_1,
            approver_2,
            approver_3,
            approver_4,
        } = event;

        if (employeeId == approver_1 && approval_status_1 !== 'APPROVE') return true;
        if (employeeId == approver_2 && approval_status_1 === 'APPROVE' && approval_status_2 !== 'APPROVE') return true;
        if (employeeId == approver_3 && approval_status_2 === 'APPROVE' && approval_status_3 !== 'APPROVE') return true;
        if (employeeId == approver_4 && approval_status_3 === 'APPROVE' && approval_status_4 !== 'APPROVE') return true;
        return false;
    };
    const handleSave = async () => {

        const config2 = {
            method: "put",
            url: `${EVENT_WORKFLOW_STATUS}/${props.filteredevent.length > 0 ? props.filteredevent[0].id : null}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
            data: checkStatus,


        };


        //   setLoading(1);
        await axios(config2)
            .then((res) => {
                //   setLoading(0);
                toast.success(res.data.message);
                console.log(res);
                //   reloadData();
            })
            .catch((err) => {
                //   setLoading(0);
                console.log(err);
                toast.error("Something went wrong");
            });

        const config = {
            method: "put",
            url: `${ADD_EVENT}/${alldata.length > 0 ? alldata[0].id : null}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
            data: {
                title: alldata.length > 0 ? alldata[0].title : null,
                remark: remark
            },
        };

        await axios(config)
            .then((res) => {
                // props.setLoading(0);
                toast.success(res.data.message);
            })
            .catch((err) => {
                // props.setLoading(0);
                console.log(err);
                toast.error("Something Went Wrong");
            });
    }

    return (
        <div className="Vieweventmodel">


            <div
                className="modal fade"
                id="Vieweventmodel"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-xl"
                    role="document"
                >
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h5 className="modal-title fw-2" id="exampleModalLongTitle">
                                Event View
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span
                                    aria-hidden="true"
                                    onClick={() => {
                                        setLink("");
                                    }}
                                >
                                    ×
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">







                            <table
                                id="datatable"
                                className="table table-bordered  table-hover"
                                style={{
                                    borderCollapse: "collapse",
                                    borderSpacing: 0,
                                    width: "100%",
                                }}
                            >
                                <tbody>

                                {alldata?.map((item, index) => (<>

                                    <tr>
                                        <th>Event Name</th>
                                        <td colSpan={4}><b>{item?.title}</b></td>
                                    </tr>

                                    <tr>

                                        <th>From Date</th>
                                        <th>To Date</th>
                                        <th>Created on</th>
                                        <th>Created by</th>

                                    </tr>

                                    <tr>

                                        <td>{item?.from_date?.split('T')[0]}</td>
                                        <td>{item?.to_date?.split('T')[0]}</td>
                                        <td>{item?.createdAt?.split('T')[0]}</td>
                                        <td>{getEmployeeNameById(item?.createdBy)}</td>

                                    </tr>
                                    <tr>
                                        <th>Attachment</th>
                                        <th>Event Level</th>
                                        <th>Event Visitors</th>
                                        <th>Description</th>


                                    </tr>
                                    <tr>
                                        <td>

                                            <button
                                                onClick={() => {
                                                    // console.log(item?.attachment)
                                                    setModalLink(item?.attachment);
                                                    setModalTitle(item?.title);
                                                }}
                                                data-toggle="modal"
                                                data-target="#ModalAnnouncementAttachment"
                                                className="btn btn-nex btn-sm my-1 ml-3"
                                                title="View"
                                            >
                                                View
                                            </button>
                                            <ModalAnnouncementAttachment title={modalTitle} img={modalLink} setLink={setModalLink} />
                                        </td>
                                        <td>{item?.level}</td>
                                        <td>{item?.no_of_people}</td>
                                        <td>{extractTextContent(item?.description)}</td>


                                    </tr>
                                    <tr>
                                        <th>Venue</th>
                                        <td>{item?.venue}</td>
                                        <th>Status</th>
                                        <td>
                                            {props.filteredevent.map(event => {
                                                const approvers = [
                                                    { id: event.approver_1, status: event.approval_status_1 },
                                                    { id: event.approver_2, status: event.approval_status_2 },
                                                    { id: event.approver_3, status: event.approval_status_3 },
                                                    { id: event.approver_4, status: event.approval_status_4 }
                                                ].filter(approver => approver.id && approver.id !== 0);


                                                const hasPending = approvers.some(approver => approver.status === "PENDING");

                                                return (
                                                    <div >
                                                        <span
                                                            className={`badge px-2 py-2 ${hasPending === true ? 'badge-soft-warning' : 'badge-soft-success'}`}
                                                        >
                                                            {hasPending === true ? 'PENDING' : 'APPROVE'}
                                                        </span>
                                                    </div>

                                                );
                                            })}
                                        </td>
                                    </tr>





                                    <tr>
                                        <th>Approval</th>
                                        <td colSpan={4}>
                                            {props.filteredevent.map(event => {
                                                const approvers = [
                                                    { id: event.approver_1, status: event.approval_status_1, approver_date: event.approve_date_1, details: getEmployeeDetails(event.approver_1) },
                                                    { id: event.approver_2, status: event.approval_status_2, approver_date: event.approve_date_2, details: getEmployeeDetails(event.approver_2) },
                                                    { id: event.approver_3, status: event.approval_status_3, approver_date: event.approve_date_3, details: getEmployeeDetails(event.approver_3) },
                                                    { id: event.approver_4, status: event.approval_status_4, approver_date: event.approve_date_4, details: getEmployeeDetails(event.approver_4) }
                                                ].filter(approver => approver.id && approver.id !== 0);



                                                return (
                                                    <div key={event.id} className="horizontal-timeline">
                                                        <Timeline>
                                                            {approvers.map((approver, i) => {
                                                                // console.log("APPROVER", approver)

                                                                let label = formatDate(event.updatedAt.split("T")[0]);
                                                                let updatedate;
                                                                if (approver.status === "APPROVE") {
                                                                    label = "Approved";
                                                                    updatedate = approver?.approver_date
                                                                        ? formatDate(approver.approver_date.split("T")[0])
                                                                        : null;
                                                                }

                                                                let name = approver.details;
                                                                let color = "red";
                                                                if (approver.status === "APPROVE") {
                                                                    color = "green";
                                                                }

                                                                return (
                                                                    <Timeline.Item
                                                                        key={i}
                                                                        dot={event.status === "APPROVE" ? (
                                                                            <ClockCircleOutlined style={{ fontSize: "15px" }} />
                                                                        ) : null}
                                                                        color={color}
                                                                    >
                                                                        <div className="timeline-content">
                                                                            <strong>{name}</strong>
                                                                            <p>{label} <br></br><span style={{ color: "gray" }}>{updatedate}</span> </p>


                                                                        </div>
                                                                    </Timeline.Item>
                                                                );
                                                            })}
                                                        </Timeline>
                                                    </div>
                                                );
                                            })}
                                        </td>

                                    </tr>

                                    <tr>
                                        <th>Remark</th>
                                        <td colSpan={3}>{item.remark}</td>
                                    </tr>



                                </>))}
                                </tbody>

                            </table>
                            {filteredevent && filteredevent.length > 0 && filteredevent.map((event, index) => (
                                canRenderForm(event) ? (
                                    <div key={index}>
                                        <h2 className="card-title text-info">
                                            <b>Approval Detail</b>
                                        </h2>
                                        <br />

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="validationCustom02">Approval Status</label>
                                                    <select
                                                        onChange={(e) => setapproval(e.target.value)}
                                                        id="class"
                                                        className="form-control"
                                                    >
                                                        <option value="">Select Approval Status</option>
                                                        <option value="APPROVE">Approve</option>
                                                        <option value="PENDING">Pending</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="validationCustom02">Remark</label>
                                                    <input
                                                        id="class"
                                                        className="form-control"
                                                        placeholder="Enter any changes to be made"
                                                        onChange={(e) => setRemark(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-2 float-right m-2 mt-4">
                                            <button
                                                className="btn btn-success btn-rounded btn-outline"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={handleSave}
                                            >
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                ) : null
                            ))}


















                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default ViewEventModel
