import React from "react";
import { Link } from "react-router-dom";
import NavbarAdmission from "../Navbar/Navbar";
import FooterAdmission from "../Footer/Footer";
import Footer from "../../../Components/Footer/Footer";
import Navbar from "../../../Components/Navbar/Navbar";

function AdmissionEnquirys() {
  return (
    <div className="admission_enquiry">
      <NavbarAdmission />
      {/* <Navbar /> */}

      <div className="register-area mt-2 ">
        <div className="container-fluid">
          <div className="register" style={{ maxWidth: "90%" }}>
            <h3>Select Criteria</h3>

            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="Course">Course</label>
                  <select name="course" className="form-control" id="course">
                    <option value="">Select Course</option>
                    <option value="UG">UG</option>
                    <option value="PG">PG</option>
                    <option value="Diploma">Diploma</option>
                    <option value="Ph.D.">Ph.D.</option>
                    <option value="Certificate">Certificate</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="Specialization">Specialization</label>
                  <select name="course" className="form-control" id="course">
                    <option value="">Select Specialization</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="register-area  ">
        <div className="container-fluid">
          <div className="register" style={{ maxWidth: "90%" }}>
            <div className="row mt-4">
              <div className="col-md-12">
                <h5>Student List</h5> <hr />
                <table className="table table-bordered border-danger rounded text-center">
                  <thead>
                    <tr>
                      <th>Sl No.</th>
                      <th>Student Name</th>
                      <th>Date of Birth</th>
                      <th>Gender</th>
                      <th>Category</th>
                      <th>Mobile Number</th>
                      <th>Email</th>
                      <th>Application Date</th>

                      <th>Course</th>
                      <th>Area Of Specialization</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>Abhishek</td>
                      <td>15-12-1998</td>
                      <td>Male</td>
                      <td>OBC</td>
                      <td>8904239164</td>
                      <td>abhishek@gmail.com</td>
                      <td>09-08-2022</td>

                      <td>UG</td>
                      <td>BSc. Nursing</td>
                      <td className="px-5">
                        <Link
                          to="AdmissionFrom.jsx"
                          className="btn btn-sm btn-danger d-flex align-items-center justify-content-around"
                        >
                          <i class="ri-eye-line"></i> view
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterAdmission />
      {/* <Footer /> */}
    </div>
  );
}

export default AdmissionEnquirys;
