import React, { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const HomeStudent = ({ setLoading }) => {
  let noty = localStorage.getItem("info");
  if (noty) {
    toast.success("Successfully Logged In");
    localStorage.removeItem("noty");
  }
  return <div>Home</div>;
};

export default HomeStudent;
