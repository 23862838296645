import React, { useState, useRef, useEffect } from "react";
import ModalAnnouncement from "./ModalAnnouncement";
import { ANNOUNCEMENTS } from "../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import Nodata from "../../Components/NoData/Nodata";
import { LOCAL_COLLEGE } from "../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import ModalAnnouncementAttachment from "./ModalAnnouncementAttachment";
import ModalAnnouncementView from "./ModalAnnouncementView";
import DocumentsModal from "../../modals/Students/DocumentsModal";

function Announcements({ setLoading, collegeId }) {
  console.log("setLoading", setLoading);
  const [data, setData] = useState();
  const [allData, setAllData] = useState([]);
  console.log("alldata - ", allData);
  const [type, setType] = useState();
  const [edit, setEdit] = useState();

  const [modalTitle, setModalTitle] = useState("");
  const [modalLink, setModalLink] = useState("");

  const [image, setImage] = useState("");

  console.log("modalLink", modalLink);
  // console.log("titleAnnouncemet", modalTitle);

  // console.log("setLinkAnnounc", modalLink);
  let role = sessionStorage.getItem("role");
  console.log("role,", role);
  const getAlldata = async () => {
    const config = {
      method: "get",
      url: `${ANNOUNCEMENTS}?type=announcement`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);

        setAllData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    getAlldata();
  }, []);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Announcements",
    sheet: "Announcements",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  //   const handlePrint = () => {
  //     PrintRecipt();
  //   };

  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      tableRef.current.style.display = "none";
    }, 1); // Adjust the delay as needed
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  return (
    <div className="LeaveLeaveType">
      <ModalAnnouncement
        reloadData={getAlldata}
        type={type}
        data={edit}
        setLoading={setLoading}
        collegeId={collegeId}
      />
      <ModalAnnouncementAttachment
        title={modalTitle}
        img={image}
        setLink={setModalLink}
      />

      <ModalAnnouncementView
        title={modalTitle}
        img={modalLink}
        setLink={setModalLink}
        data={data}
        collegeId={collegeId}
      />
      <DocumentsModal
        title={modalTitle}
        img={modalLink}
        setLink={setModalLink}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Announcements</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <button
                        className="float-right btn btn-nex ml-2"
                        data-toggle="modal"
                        data-target="#ModalAnnouncement"
                        onClick={() => {
                          setType("add");
                          setEdit();
                        }}
                      >
                        + Add New
                      </button>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">Announcements</h4>
                      </div>
                    </div>
                    <hr />
                    <table
                      id="datatable"
                      className="table table-bordered  table-hover"
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Sl No.</th>
                          <th>Title</th>
                          <th>Anncouncement For</th>
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>Created on</th>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allData?.map((item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item?.title}</td>
                            <td>
                              {item.students != 0 &&
                                item.staff != 0 &&
                                item.parents != 0
                                ? "Staff, Student and Parent"
                                : item.students != 0 &&
                                  item.staff != 0 &&
                                  item.parents == 0
                                  ? "Staff and Student "
                                  : item.students != 0 &&
                                    item.staff == 0 &&
                                    item.parents != 0
                                    ? "Student and Parent"
                                    : item.students == 0 &&
                                      item.staff != 0 &&
                                      item.parents != 0
                                      ? "Staff and Parents"
                                      : item.students == 0 &&
                                        item.staff != 0 &&
                                        item.parents == 0
                                        ? "Staff"
                                        : item.students != 0 &&
                                          item.staff == 0 &&
                                          item.parents == 0
                                          ? "Student"
                                          : item.students == 0 &&
                                            item.staff == 0 &&
                                            item.parents != 0
                                            ? "Parent"
                                            : ""}
                            </td>
                            <td>{item?.from_date?.split("T")[0]}</td>
                            <td>{item?.to_date?.split("T")[0]}</td>
                            <td>{item?.createdAt?.split("T")[0]}</td>
                            <td>
                             
                              <button
                                onClick={() => {
                                  setModalLink(
                                    item?.attachment);
                              
                                  setModalTitle(item?.title);
                                }}
                                data-toggle="modal"
                                data-target="#DocumentsModal"
                                className="btn btn-nex btn-sm my-1 ml-3"
                                title="View"
                              >
                                View
                              </button>
                            </td>
                            <td>
                              {role == "SUPERADMIN" || "ADMIN" ? (
                                <button
                                  onClick={() => {
                                    setType("edit");
                                    setEdit(item);
                                  }}
                                  data-toggle="modal"
                                  data-target="#ModalAnnouncement"
                                  className="btn btn-nex btn-sm my-1 ml-3"
                                  title="View"
                                >
                                  Edit
                                </button>
                              ) : (
                                <></>
                              )}

                              <button
                                onClick={() => {
                                  setModalLink(item?.attachment);
                                  setModalTitle(item?.title);
                                  setData(item);
                                }}
                                data-toggle="modal"
                                data-target="#ModalAnnouncementView"
                                className="btn btn-nex btn-sm my-1 ml-3"
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {allData?.length == 0 ? <Nodata /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcements;
