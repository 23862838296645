import React from "react";
import "./Dashboard.scss";
import ReactApexChart from "react-apexcharts";
import Calender from "../../../Components/Calender/Calender";
import axios from "axios";
import Chart from 'react-apexcharts';
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { DASHBOARD_HR, SUPERADMIN_DASHBOARD, MEDICAL_SUPERADMIN_DASHBOARD, ANNOUNCEMENTS, ADD_EVENT } from "../../../utils/apiConstants";
import { SESSION_ROLE } from "../../../utils/sessionStorageContants";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { List } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from '../../../Router/routerConfig';

function DashboardHR({ setLoading, collegeId }) {

  const formatMonthYear = (date) => {
    const d = new Date(date);
    const moptions = { day: '2-digit', month: 'short', year: 'numeric' };
    return d?.toLocaleDateString('en-US', moptions);
  };

  const navigate = useNavigate();

  const getCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

  var emp_id = sessionStorage.getItem("employee_id");

  //States
  const [data, setData] = useState();

  const [collegeData, setCollegeData] = useState(getCollegeData());

  console.log(data?.college_staff?.map((s) => s?.count));
  console.log(data?.college_staff?.map((s) => String(s.college_id)));

  const getDashboardData = async () => {

    const config = {
      method: "get",
      url: emp_id == 502 ? `${MEDICAL_SUPERADMIN_DASHBOARD}` : `${SUPERADMIN_DASHBOARD}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("dashboard data - ", res.data);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        // toast.error(err.response.data.message);
      });
  }
  const [allData, setAllData] = useState([]);
  const getAlldata = async () => {
    const config = {
      method: "get",
      url: `${ANNOUNCEMENTS}?type=announcement`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);

        setAllData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    getAlldata();
  }, [])
  // //Get Data
  // const getData = () => {
  //   setLoading(1);
  //   const config = {
  //     method: "get",
  //     url: DASHBOARD_HR + "?role=" + sessionStorage.getItem(SESSION_ROLE),
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   axios(config)
  //     .then((res) => {
  //       setLoading(0);
  //       setData(res.data.data);
  //     })
  //     .catch((err) => {
  //       setLoading(0);
  //       toast.error("Something went wrong");
  //       console.log(err);
  //     });
  // };
  const optionsEmployee1 = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
    },
    stroke: {
      width: [1, 1],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1, 2, 3]
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
      },
    },
    // labels: ['ENG', 'MED', 'AYU', 'LAW', 'NUR', 'ART', 'COM',
    //   'ITC'
    // ],
    labels: data?.employee_data?.map((s) => s.code) || [],
    markers: {
      size: 0
    },
    yaxis: {
      title: {
        text: 'Staff Count',
      },
      min: 0
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;

        }
      }
    },
    colors: ['#1B2850', 'green', 'red'],
  };
  const colors = ["#364277", "#FF9F43"];

  const [notifications, setNotifications] = useState([
    'University wins national championship in robotics competition.',
    'New scholarship program announced for underprivileged students.',
    'Research team discovers new species of marine life in the campus lake.',
    'University ranked in top 10 for computer science programs by prestigious magazine.',
    'Renowned author to give keynote speech at the annual literature festival.',
    'University receives grant for new research facility where students get various opportunities.',
    'Alumni association to host networking event for recent graduates.',
    'Faculty member awarded prestigious fellowship for groundbreaking research.',
    'University to host international conference on climate change.',
    'Student-run charity event raises record amount for local homeless shelter.',
  ])

  const [events, setEvents] = useState([
    'Guest lecture on Machine Learning by Dr. Smith',
    'Workshop on Web Development and Andriod development',
    'University womens coding ninja hackathon',
    'Career fair to find jobs which suits best for you',
    'Seminar on Renewable Energy and its importance',
    'Panel discussion on Artificial Intelligence',
    'Coding competition conducted by Google',
    'Seminar on Cybersecurity and cloud computing',
    'Workshop on Mobile App Development',
    'Networking event to learn firewalls and their importance',
  ]);
  let collegeids=sessionStorage.getItem("college_id")
  console.log("CCC",collegeids, typeof(collegeids))
  const [eventdata, seteventData] = useState()

  const geteventdata = async () => {
    try {
        const config = {
            method: "get",
            url: `${ADD_EVENT}?type=event`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
        };

        const res = await axios(config);
        seteventData(res.data.data);
    } catch (err) {
        console.error(err);
        toast.error("Something Went Wrong");
    }
};
useEffect(()=>{
  geteventdata()
},[])
  const seriesEmployee1 = [{
    name: 'Total Staff',
    type: 'column',
    // data: [44, 55, 41, 67, 22, 43, 21, 41],
    data: data?.employee_data?.map((s) => s.employee_count) || [],
    color: "#4D44B5"
  }, {
    name: 'Present',
    type: 'area',
    // data: [23, 11, 22, 27, 13, 22, 37, 21],
    data: data?.employee_data?.map((s) => s.present) || [],
    color: "green"
  }, {
    name: 'Absent',
    type: 'line',
    // data: [30, 25, 36, 30, 45, 35, 64, 52],
    data: data?.employee_data?.map((s) => s.absent) || [],
    color: "red"
  }]
  //Chart Data 1
  const overviewChartOptions = {
    series: [0, 0, 0, 0, 0],
    labels: ["Ayurveda", "Management", "Engineering", "Law", "Arts"],
    chart: {
      width: 180,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100px",
            height: "100px",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  //Chart Data 2
  const overviewChartOptions2 = {
    series: [
      {
        name: "Male",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Female",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: [
          "Ayrveda",
          "Engineering",
          "Law",
          "Dental",
          "Management",
          "Arts",
          "Commerce",
        ],
      },
    },
  };

  // useEffect(() => {
  //   getDgetata();
  // }, [sessionStorage.getItem(SESSION_ROLE)]);

  useEffect(() => {
    getDashboardData();
  }, []);

  useState(() => {
    setCollegeData(getCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);
  return (
    <div className="DashboardHR">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">
                    Hello , {sessionStorage.getItem("emp_name")}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Home</li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="card" style={{ borderRadius: "10px", backgroundColor: "#364277", color: "white" }}>
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Staff
                          </p>
                          <h4 className="mb-0" style={{ color: "white" }}><b>{data?.employee_data?.reduce((acc, curr) => acc + curr?.employee_count, 0)}</b></h4>
                        </div>
                        <div style={{ color: "white" }}>
                          <i className="ri-group-line font-size-48" style={{ fontSize: "50px" }}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card" style={{ borderRadius: "10px", backgroundColor: "#FF9F43", color: "white" }}>
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Teaching Staff
                          </p>
                          <h4 className="mb-0" style={{ color: "white" }}><b>{data?.employee_data?.reduce((acc, curr) => acc + parseInt(curr?.teaching_staff_count), 0)}</b></h4>
                        </div>
                        <div style={{ color: "white" }}>
                          <i className="ri-user-2-line font-size-48" style={{ fontSize: "50px" }}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card" style={{ borderRadius: "10px", backgroundColor: "#364277", color: "white" }}>
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Non Teaching Staff
                          </p>
                          <h4 className="mb-0" style={{ color: "white" }}><b>{data?.employee_data?.reduce((acc, curr) => acc + parseInt(curr?.non_teaching_staff_count), 0)}</b></h4>
                        </div>
                        <div style={{ color: "white" }}>
                          <i className="ri-user-3-line font-size-48" style={{ fontSize: "50px" }}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card" style={{ borderRadius: "10px", backgroundColor: "#FF9F43", color: "white" }}>
                    <div className="card-body" style={{}}>
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Absent Staff
                          </p>
                          <h4 className="mb-0" style={{ color: "white" }}><b>{data?.student_data?.reduce((acc, curr) => acc + parseInt(curr?.male_count) + parseInt(curr?.female_count), 0)}</b></h4>
                        </div>
                        <div style={{ color: "white" }}>
                          <i className="ri-team-line font-size-48" style={{ fontSize: "50px" }}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="card ">
                        <div className="card-body">
                          <h4 className="card-title mb-4">{emp_id == 502 ? "Department wise Staff Attendance" : "Faculty wise Staff Attendance"}</h4>
                          <div id="chart">
                            <Chart options={optionsEmployee1} series={seriesEmployee1} type="line" height={405} />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-3">

                  <div className="card ">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Announcement</h4>
                      <div style={{ overflowY: "auto", height: "365px" }}>
                        {/* <List
                          size="small"
                          bordered
                          dataSource={notifications}
                          renderItem={(item, index) => 
                          <List.Item>
                            <>
                              <i className="ri-notification-2-line mr-2 px-2 py-1 text-white" style={{backgroundColor:colors[index % colors.length], borderRadius: "20%"}}></i>  
                              <Link to="" style={{color: "black"}}>{item.length > 75 ? `${item.substring(0, 75)}...` : item} <span style={{ color: "gray" }}>- May 15, 2024</span></Link>
                            </>
                          </List.Item>}
                          
                        /> */}

                        <List
                          size="small"
                          bordered
                          dataSource={allData?.filter((s) => s.type == "announcement")?.map((i, k) => i)}
                          renderItem={(item, index) =>
                            <List.Item>
                              <>
                                <i className="ri-notification-2-line mr-2 px-2 py-1 text-white" style={{ backgroundColor: colors[index % colors.length], borderRadius: "20%" }}></i>
                                <Link to="" style={{ color: "black" }}>{item?.title?.length > 35 ? `${item?.title?.substring(0, 35)}...` : item?.title} <span style={{ color: "gray" }}>- {formatMonthYear(item?.to_date)}</span></Link>
                              </>
                            </List.Item>}

                        />
                      </div>
                      <div className="row justify-content-center mt-2">
                        <div className="col-md-6">
                          <button
                            type="button"
                            className="btn btn-nex mt-2 btn- mw-100 w-100"
                            onClick={() => {
                              navigate(ROUTES.Registar.Communicate.Announcements);
                            }}
                          >View All</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card ">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Upcoming Events</h4>

                      <div style={{ overflowY: "auto", height: "365px" }}>
                        {/* <List
                          size="small"
                          bordered
                          dataSource={events}
                          renderItem={(item, index) => 
                          <List.Item>
                            <>
                              <img
                                src="https://cdn2.iconfinder.com/data/icons/admin-tools-2/25/image2-512.png"
                                style={{width: "30px", height: "30px", borderRadius:"10%"}}
                                className="mr-2"
                              />
                              <Link to="" style={{color: "black"}}>{item.length > 75 ? `${item.substring(0, 75)}...` : item} <span style={{ color: "gray" }}>- May 15, 2024</span></Link>
                            </>
                          </List.Item>}
                          
                        /> */}
                        <List
                          size="small"
                          bordered
                          dataSource={eventdata?.filter(event => event.college_id == collegeids)}
                          renderItem={(item, index) =>
                            <List.Item>
                              <>
                              
                                <i className="ri-calendar-event-line mr-2 px-2 py-1 text-white" style={{backgroundColor:colors[index % colors.length], borderRadius: "20%"}}></i>  
                                {/* <img
                                  src={"https://cdn2.iconfinder.com/data/icons/admin-tools-2/25/image2-512.png"}
                                  style={{ width: "30px", height: "30px", borderRadius: "10%" }}
                                  className="mr-2"
                                  alt="https://cdn2.iconfinder.com/data/icons/admin-tools-2/25/image2-512.png"
                                /> */}
                                <Link to="" style={{ color: "black" }}>{item?.title?.length > 75 ? `${item.substring(0, 75)}...` : item?.title} <span style={{ color: "gray" }}>- {formatMonthYear(item?.to_date)}</span></Link>
                              </>
                            </List.Item>}

                        />
                      </div>
                      <div className="row justify-content-center mt-2">
                        <div className="col-md-6">
                          <button type="button" className="btn btn-nex mt-2 btn- mw-100 w-100" onClick={() => {
                            navigate(ROUTES.Registar.Communicate.UpcomingEvents);
                          }}>View All</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                {/* <div
                  className="col-md-6 row d-flex"
                  style={{ maxwidth: "500px" }}
                >
                  <div className="col-12">
                    <div className="card ">
                      <div
                        className="card-body p-5"
                        style={{ maxHeight: "600px", maxWidth: "600px" }}
                      >
                        <h4 className="card-title mb-4">Overview</h4>
                        <div id="chart">
                          <ReactApexChart
                            options={overviewChartOptions}
                            series={overviewChartOptions.series}
                            type="donut"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card ">
                      <div
                        className="card-body"
                        style={{ maxHeight: "600px", maxWidth: "600px" }}
                      >
                        <h4 className="card-title mb-4">Male vs Female</h4>
                        <div id="chart">
                          <ReactApexChart
                            options={overviewChartOptions2.options}
                            series={overviewChartOptions2.series}
                            type="bar"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12 mb-3">
                  <div className="card p-3 w-100">
                    <h3 className="my-3">Event Calendar</h3>
                    <Calender setLoading={setLoading} collegeId={collegeId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHR;
