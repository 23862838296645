import { BASE_URL } from "./apiConstants";

//Academics

//Add Subject
export const ACADEMICS_ADD_SUBJECT = `${BASE_URL}/api/academics/course`

export const ASSIGN_HOSPITAL_STUDENTS = `${BASE_URL}/api/student/hospital`

//Add Lesson
export const ACADEMICS_ADD_LESSON = `${BASE_URL}/api/academics/lesson`

//Add Topic
export const ACADEMICS_ADD_TOPIC = `${BASE_URL}/api/academics/topic`

//Add Class
export const ACADEMICS_ADD_CLASS = `${BASE_URL}/api/academics/class`

export const CLASS_DATA = `${BASE_URL}/api/academics/classdata`

export const CLASS_NAME = `${BASE_URL}/api/academics/classname`



//Add Semester
export const ACADEMICS_ADD_SEMESTER = `${BASE_URL}/api/academics/semester`

//blooms taxonomy levels
export const ACADEMICS_ADD_BLOOMS_LEVELS = `${BASE_URL}/api/academics/blooms-levels`;

//course outcomes
export const COURSE_OUTCOMES = `${BASE_URL}/api/academics/course-outcomes`;

//course-program-outcomes-mappings
export const COURSE_PROGRAM_MAP = `${BASE_URL}/api/academics/course-program-map`;

//program_outcome
export const ACADEMICS_ADD_PROGRAM_OUTCOMES = `${BASE_URL}/api/academics/program-outcomes`

//Add Semester
export const ACADEMICS_ADD_SECTION = `${BASE_URL}/api/academics/section`

//Class Time Table
export const ACADEMICS_CLASS_TIME_TABLE = `${BASE_URL}/api/academics/timetable`

export const ALL_CLASS_TIME_TABLE = `${BASE_URL}/api/academics/all-timetable`

export const ALL_SUBJECTS = `${BASE_URL}/api/academics/allsubject`


export const EMP_WISE_SUBJECTS = `${BASE_URL}/api/academics/emp-wise-sub`

export const SYLLABUS_COUNT = `${BASE_URL}/api/academics/syllabusCount`

export const ALL_COURSE_COUNT = `${BASE_URL}/api/academics/allcoursecount`

export const ALL_LESSON_COUNT = `${BASE_URL}/api/academics/alllessoncount`


export const ALL_TOPIC_COUNT = `${BASE_URL}/api/academics/alltopiccount`






//Course Time Table
export const COURSE_TIME_TABLE_GET = `${BASE_URL}/api/academics/getCourseTimeTable`

export const UPDATE_BY_TIMETABLEID_ACADEMICS_LESSON_PLAN = `${BASE_URL}/api/academics/updateCourseSyllabusByTimeTableId`

//Add Subject
// export const ACADEMICS_ADD_SUBJECT = `${BASE_URL}/api/academics/course`

export const ACADEMICS_ADD_SYLLABUS = `${BASE_URL}/api/academics/course-session`

export const SYLLABUS_STATUS = `${BASE_URL}/api/academics/syllabus-status`

export const SYLLABUS_STATUS_LESSON = `${BASE_URL}/api/academics/syllabus-status_lesson`

export const ACADEMICS_UPDATE_SYLLABUS = `${BASE_URL}/api/academics/course-session`


export const ACADEMICS_ADD_LESSON_PLAN = `${BASE_URL}/api/academics/lessonplan`


export const ACADEMICS_PROMOTE_STUDENT = `${BASE_URL}/api/student/session`

export const ACADEMICS_TRANSFER_STUDENT = `${BASE_URL}/api/student/transfer`

export const ACADEMICS_ADD_QUESTION_BANK = `${BASE_URL}/api/academics/questionbank`

export const ACADEMICS_ADD_BATCH = `${BASE_URL}/api/academics/createPracticalBatch`

export const ACADEMICS_GET_BATCH = `${BASE_URL}/api/academics/getPracticalBatch`

export const ACADEMICS_UPDATE_BATCH = `${BASE_URL}/api/academics/updateBatch`

export const ACADEMICS_ASSIGN_BATCH = `${BASE_URL}/api/academics/assignStudents`

export const ACADEMICS_GET_ASSIGN_STU = `${BASE_URL}/api/academics/getAssignStudents`

export const ACADEMICS_CREATE_P_ATT = `${BASE_URL}/api/academics/createPBatchAtt`

export const ACADEMICS_GET_P_ATT = `${BASE_URL}/api/academics/getPBatchAtt`

