import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../../Components/Loader/Loader";
import Nodata from "../../../../Components/NoData/Nodata";
import ModalPayRollDeduction from "../../../../modals/HR/PayRoll/ModalPayRollDeduction";
import {
  PAYROLL_DEDUCTION,
  PAYROLL_EMP_DEDUCTION,
} from "../../../../utils/apiConstants";
import ModalDeductionAssign from "./ModalDeductionAssign";
import { LOCAL_JOBROLES } from "../../../../utils/LocalStorageConstants";

function PayRollDeduction({ setLoading }) {
  const [data, setData] = useState([]);
  const [type, setType] = useState("");
  const [edit, setEdit] = useState();

  const jobPositionOpt = JSON.parse(localStorage.getItem(LOCAL_JOBROLES));

  const [empData, setEmpData] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  const getData = async () => {
    setLoading(1);

    const config = {
      method: "get",
      url: `${PAYROLL_DEDUCTION}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("data - ", res.data.data);
        setData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setLoading(0);
      });
  };

  const getEmpData = () => {
    const config = {
      method: "get",
      url: `${PAYROLL_EMP_DEDUCTION}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    setLoading(1);
    axios(config)
      .then((res) => {
        setLoading(0);
        setEmpData(res.data.data);
        setDisplayData(res.data.data);
        console.log("employees with deductions - ", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
        console.log(err);
      });
  };

  useEffect(() => {
    getEmpData();
    getData();
  }, []);

  return (
    <div className="PayRollDeduction">
      <div className="main-content">
        <ModalPayRollDeduction
          type={type}
          data={edit}
          reloadData={getData}
          setLoading={setLoading}
        />
        <ModalDeductionAssign
          jobPositionOpt={jobPositionOpt}
          getEmpData={getEmpData}
          edit={edit}
          empData={empData}
          displayData={displayData}
          setDisplayData={setDisplayData}
          setLoading={setLoading}
        />
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Deduction</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">PayRoll</a>
                      </li>
                      <li className="breadcrumb-item active">Deduction</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex justify-content-end p-3">
                    <button
                      className="btn btn-rounded btn-success btn-outline px-4"
                      data-toggle="modal"
                      data-target="#ModalPayRollDeduction"
                      onClick={() => {
                        setType("add");
                        setEdit();
                      }}
                    >
                      Add +
                    </button>
                  </div>

                  <div>
                    {data && data.length !== 0 ? (
                      data?.map((i, key) => (
                        <div className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow">
                          <div className="col-10" key={key}>
                            <div className="role-title">{i.title}</div>
                            <div className="role-code">{i.description}</div>
                          </div>
                          <div
                            className="col-1 d-flex align-items-center justify-content-end"
                            data-toggle="modal"
                            data-target="#ModalDeductionAssign"
                            onClick={() => {
                              setType("edit");
                              setEdit(i);
                            }}
                          >
                            <i
                              className="fa fa-tags "
                              aria-hidden="true"
                              style={{
                                color: "#364277",
                                cursor: "pointer",
                              }}
                              onmouseover="this.style.color='orange'"
                              onmouseout="this.style.color='#364277'"
                            />
                            Assign
                          </div>
                          <div
                            className="col-1
                                                    d-flex align-items-center 
                                                    justify-content-end
                                                    "
                            data-toggle="modal"
                            data-target="#ModalPayRollDeduction"
                            onClick={() => {
                              setType("edit");
                              setEdit(i);
                            }}
                          >
                            <i className="fa fa-edit mr-1" aria-hidden="true" />
                            Edit
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="mt-3">
                        <Nodata
                          titleTop={"No data available for your search"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayRollDeduction;
