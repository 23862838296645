import { BASE_URL } from "./apiConstants";
// export const BASE_URL = "http://localhost:4000"

export const FEE_DETAILS = `${BASE_URL}/api/fee/details`;

export const FEE_TYPE_AMOUNT = `${BASE_URL}/api/fee/amount`;

export const FEE_STUDENT = `${BASE_URL}/api/fee/student`

//Previous Year Due Fees

export const PREVIOUS_YEAR_DUE_FEES = `${BASE_URL}/api/fee/getpreviousdueFees`;

//Add Income 

export const FEE_INCOME = `${BASE_URL}/api/fee/income`

export const FEE_INCOME_SOURCE = `${BASE_URL}/api/fee/income-source`;

export const FEE_EXPENSE = `${BASE_URL}/api/fee/expense`;

export const FEE_EXPENSE_SOURCE = `${BASE_URL}/api/fee/expense-source`;

///////////////STUDENT REPORTS

export const FEE_DETAILS_BY_STUDENT_ID = `${BASE_URL}/api/fee/student-fee`;

export const FEE_PENDING_COLLEGE_WISE = `${BASE_URL}/api/fee/college-reports`;

export const ADDHOSTELFEE = `${BASE_URL}/api/fee/hostelfees`;

export const ADDHOSTELFEE1 = `${BASE_URL}/api/fee/sqlhostelfees`;

export const ADDTRANSPORTFEE = `${BASE_URL}/api/fee/transportfees`;

export const HOSTELPAYMENTDATA = `${BASE_URL}/api/fee/hostelpaymentdata`

//Student Complaint Api

export const STUDENT_COMPLAINT_GET = `${BASE_URL}/api/student/complaint/all`

export const ADD_OTHER_FEES = `${BASE_URL}/api/fee/addOtherFees`

export const FEE_DETAILS_BY_STUDENT_ID_VIEW = `${BASE_URL}/api/fee/student-fee-view`

export const UPDATE_HOSTEL_FEE_STATUS = `${BASE_URL}/api/payment/update-hostel-fee-status`;