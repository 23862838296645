import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { STUDENT_GATE_PASS_PUT1 } from "../../utils/InfoUploadingApiConstants";
import {
  ASSET_HOMEOMEDIA,
  ASSET_MEDIA,
} from "../../utils/AssetsReferenceTypes";
import { getFileUrl } from "../../Helpers/Helpers";

function ModalStudentGatePass({ data, getData, setLoading, flag, setFlag }) {
  const fileref = useRef(null);

  console.log("daat?.attachment -", data);

  const [attachment, setAttachment] = useState([]);

  const [user, setUser] = useState({
    purpose_type: data?.purpose_type || "",
    out_from_date: data?.out_from_date || "",
    out_to_date: data?.out_to_date || "",
    no_of_days: data?.no_of_days || "",
    out_going_purpose: data?.out_going_purpose || "",
    request_remark: data?.request_remark || "",
    approved_by_1: data?.approved_by_1 || "",
    approved_date_1: data?.approved_date_1 || "",
    approval_status_1: data?.approval_status_1 || "",
    approved_by_2: data?.approved_by_2 || "",
    approved_date_2: data?.approved_date_2 || "",
    approval_status_2: data?.approval_status_2 || "",
    attachment: data?.attachment || "",
  });

  // Update state when data prop changes
  useEffect(() => {
    setUser({
      purpose_type: data?.purpose_type || "",
      out_from_date: data?.out_from_date || "",
      out_to_date: data?.out_to_date || "",
      no_of_days: data?.no_of_days || "",
      out_going_purpose: data?.out_going_purpose || "",
      request_remark: data?.request_remark || "",
      approved_by_1: data?.approved_by_1 || "",
      approved_date_1: data?.approved_date_1 || "",
      approval_status_1: data?.approval_status_1 || "",
      approved_by_2: data?.approved_by_2 || "",
      approved_date_2: data?.approved_date_2 || "",
      approval_status_2: data?.approval_status_2 || "",
      attachment: data?.attachment || "",
    });
  }, [data]);

  // console.log("data --", data);

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearData = () => {
    setUser({
      purpose_type: "",
      out_from_date: "",
      out_to_date: "",
      no_of_days: "",
      out_going_purpose: "",
      request_remark: "",
      approved_by_1: "",
      approved_date_1: "",
      approval_status_1: "",
      approved_by_2: "",
      approved_date_2: "",
      approval_status_2: "",
      attachment: "",
    });
    data.purpose_type = "";
    data.out_from_date = "";
    data.out_to_date = "";
    data.no_of_days = "";
    data.out_going_purpose = "";
    data.request_remark = "";
    data.approved_by_1 = "";
    data.approved_date_1 = "";
    data.approval_status_1 = "";
    data.approved_by_2 = "";
    data.approved_date_2 = "";
    data.approval_status_2 = "";
    data.attachment = "";
  };

  const handleChange = async (e) => {
    // console.log(e.target.files[0]);

    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      user.attachment = d;
    } catch (error) {
      console.log(error);
    }
  };

  const Change = async (e) => {
    let files = [];
    // return;
    for (let i of e.target.files) {
      console.log(i);
      try {
        const d = await getFileUrl(
          ASSET_HOMEOMEDIA,
          "Homoeopathy_Assets/Events",
          i?.name.split(".")[1],
          setLoading,
          i
        );
        files.push(d);
      } catch (error) {
        console.log(error);
      }
    }
    setAttachment(files);
  };

  // console.log("data.student_id -", data.student_id);

  const handleSubmit = async () => {
    setLoading(1);

    const dataToSend = {
      ...user,
      no_of_days: days,
      attachment: attachment,
    };

    const config = {
      method: "put",
      url: `${STUDENT_GATE_PASS_PUT1}/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: dataToSend,
    };

    axios(config)
      .then((res) => {
        toast.success("Succesfully Updated Details");
        clearData();
        setFlag((flag) => !flag);
        getData();
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const calculateDays = () => {
    const fromDate = new Date(user.out_from_date);
    const toDate = new Date(user.out_to_date);
    if (!user.out_from_date || !user.out_to_date || fromDate > toDate) {
      return 0;
    }
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    const timeDifference = toDate.getTime() - fromDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    const numberOfDays = Math.round(daysDifference);
    return numberOfDays >= 0 ? numberOfDays : 0;
  };

  const days = calculateDays();

  return (
    <div className="ModalStudentGatePass">
      <div
        className="modal fade"
        id="StudentGatePass"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered mw-100 w-75"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="modal-header">
                    <h5 className="text-primary">EDIT STUDENT GATE PASS</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true" className="mr-4">
                        ×
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="card-title text-info">
                        <b>Request Detail</b>
                      </h2>
                      <br />
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Out Going Purpose Type
                            </label>
                            <select
                              name="purpose_type"
                              id="class"
                              className="form-control"
                              value={
                                user?.purpose_type
                                  ? user?.purpose_type
                                  : data.purpose_type
                              }
                              // onChange={handleChange1}
                              onChange={(e) => {
                                handleChange1(e);
                              }}
                            >
                              <option value="">Select Purpose Type</option>
                              <option value="LEAVE">Leave</option>
                              <option value="GENERAL">General</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Out Going Purpose
                            </label>
                            <input
                              type="text"
                              name="out_going_purpose"
                              className="form-control"
                              placeholder="Enter Out Going Purpose"
                              value={
                                user?.out_going_purpose
                                  ? user?.out_going_purpose
                                  : data.out_going_purpose
                              }
                              // onChange={handleChange1}
                              onChange={(e) => {
                                handleChange1(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Request Remark
                            </label>
                            <input
                              type="text"
                              name="request_remark"
                              className="form-control"
                              placeholder="Enter Request Remark"
                              value={
                                user?.request_remark
                                  ? user?.request_remark
                                  : data.request_remark
                              }
                              // onChange={handleChange1}
                              onChange={(e) => {
                                handleChange1(e);
                              }}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="">Attachment</label>
                            <input
                              type="file"
                              placeholder="Attach the file"
                              className="form-control"
                              name="attachment"
                              ref={fileref}
                              value={
                                user?.attachment
                                  ? user?.attachment
                                  : data.attachment
                              }
                              // onChange={(e) => {
                              //   handleChange(e);
                              // }}
                              onChange={(e) => Change(e)}
                            />
                          </div>
                        </div> */}
                        <div className="col-md-6 mt-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Out Going Period
                            </label>
                            <div className="row mt-2">
                              <div className="col-md-5">
                                <label htmlFor="validationCustom02">
                                  From Date
                                </label>
                                <input
                                  type="date"
                                  name="out_from_date"
                                  className="form-control"
                                  placeholder="From Date"
                                  value={
                                    user?.out_from_date
                                      ? user?.out_from_date
                                      : data.out_from_date
                                  }
                                  // onChange={handleChange1}
                                  onChange={(e) => {
                                    handleChange1(e);
                                  }}
                                />
                              </div>
                              <div className="col-md-5">
                                <label htmlFor="validationCustom02">
                                  To Date
                                </label>
                                <input
                                  type="date"
                                  name="out_to_date"
                                  className="form-control"
                                  placeholder="To Date"
                                  value={
                                    user?.out_to_date
                                      ? user?.out_to_date
                                      : data.out_to_date
                                  }
                                  // onChange={handleChange1}
                                  onChange={(e) => {
                                    handleChange1(e);
                                  }}
                                />
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label htmlFor="validationCustom02">
                                    No of Days
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={calculateDays()}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                      </div>

                      <br />
                      <br />
                      <div className="row float-right mr-3">
                        <button
                          className="btn btn-success float-right"
                          id="submit"
                          aria-label="Close"
                          type="button"
                          data-dismiss="modal"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalStudentGatePass;
