import React, { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_LOGIN_GATE_PASS_REQUEST, GATE_PASS_IN_OUT, STAFF_GATE_PASS_IN_OUT } from "../../utils/InfoUploadingApiConstants";
import Nodata from "../../Components/NoData/Nodata";
import ModalStudentGatePass from "../../modals/ModalStudentGatePass";
import HostelModalStudentGatePass2 from "../../modals/HostelModalStudentGatePass2";
import { useDownloadExcel } from "react-export-table-to-excel";
import { EMPLOYEE } from "../../utils/apiConstants";
import { useReactToPrint } from "react-to-print";
import { LOCAL_COLLEGE } from "./../../utils/LocalStorageConstants";
import {
  SESSION_COLLEGE_ID,
  SESSION_EMPLOYEE_ID,
} from "../../utils/sessionStorageContants";
import { method } from "lodash";
import { moment } from "moment";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

function ViewStudentGatePass({ setLoading, collegeId, changeCollege }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  let date = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState(date);
  const [toDate, setToDate] = useState(date);
  const [dates, setDate] = useState()
  console.log("datessss  -", dates);
  const [data2, setData2] = useState("");

  const [flag, setFlag] = useState(false);
  const [approvstat1, setapprovstat1] = useState()
  const [approvstat2, setapprovstat2] = useState()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
    setapprovstat1(item.approval_status_1)
    setapprovstat2(item.approval_status_2)
  };
  console.log("app stat1 n 2-", approvstat1, approvstat2);
  const [inoutdata, setinoutdata] = useState("");
  const [staffllogininout, setstaffllogininout] = useState()
  const [adminlogin, setadminlogin] = useState()

  const [empColleges, setEmpColleges] = useState([]);
  console.log("inoutdata-", inoutdata);
  const [optValue, setOptValue] = useState("All");
  const [optValue2, setOptValue2] = useState("All");
  const [empDetails, setEmpDetails] = useState();
  let role = sessionStorage.getItem("role");
  const employee_id = sessionStorage.getItem("employee_id")

  const getColleges = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const getCollegeId = () => {
    return sessionStorage.getItem(SESSION_COLLEGE_ID)
      ? sessionStorage.getItem(SESSION_COLLEGE_ID)
      : "1111000";
  };
  const [selectedCollege, setSelectedCollege] = useState("All", getCollegeId);
  const [collegeOpt, setCollegeOpt] = useState(getColleges);
  const [college, getcollege] = useState();
  const changeCollegeId = (id) => {
    setSelectedCollege(id);
    changeCollege(id);
  };
  console.log("selectclgid-", selectedCollege);
  useEffect(() => {
    setCollegeOpt(getColleges);
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  console.log("adminlogin-", adminlogin);
  console.log("optValue", optValue)
  const getData = async () => {

    if (fromDate > toDate) {
      toast.error("From date cannot be less then to date");
    }
    setLoading(1);
    const config5 = {
      method: "get",
      url: `${GATE_PASS_IN_OUT}`,
      params: {
        from_date: fromDate,
        to_date: toDate,
        option: optValue,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config5)
      .then((res) => {

        setinoutdata(res.data.data);
        console.log("inout data -", res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);

        console.log(err);
      });


    const config = {
      method: "get",
      url: `${STAFF_GATE_PASS_IN_OUT}`,
      params: {
        from_date: fromDate,
        to_date: toDate,
        employee_id: employee_id,
        selectedCollege: selectedCollege,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {

        setstaffllogininout(res.data.data);
        console.log("inout data -", res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);

        console.log(err);
      });


    const config2 = {
      method: "get",
      url: `${ADMIN_LOGIN_GATE_PASS_REQUEST}`,
      params: {
        option: optValue,
        option2: optValue2,
        selectedCollege: selectedCollege,
        from_date: fromDate,
        to_date: toDate,
      }
    }
    await axios(config2)
      .then((res) => {

        setadminlogin(res.data.data);
        console.log("admin data -", res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);

        console.log(err);
      });
    setLoading(0);


  };

  const getEmployee = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${employee_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        let clgs = JSON.parse(res.data.data['multi_clg_id']);
        setEmpColleges(collegeOpt.filter(item => clgs?.includes(item.id)));
        console.log('emp details - ', res.data.data);
        setEmpDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (employee_id) getEmployee();
  }, [employee_id]);


  console.log("staffllogininout-", staffllogininout);

  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: " Earning Policy List ",
    sheet: "Users",
  });
  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });


  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      // tableRef.current.style.display = "none";
    }, 1); // Adjust the delay as needed
  };

  return (
    <div>
      <ModalStudentGatePass
        flag={flag}
        setFlag={setFlag}
        setLoading={setLoading}
        data={data2}
        getData={getData}
      />
      <HostelModalStudentGatePass2
        flag={flag}
        setFlag={setFlag}
        setLoading={setLoading}
        data={data2}
        getData={getData}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">

              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0"> Student Gate Pass List </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {role == "STAFF" || role == "SUPERADMIN" ? (
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              College<span style={{ color: "red" }}>*</span>
                            </label>
                            <div>
                              {role == "SUPERADMIN" ? (
                                <select
                                  className="form-control"
                                  style={{ outline: "none" }}
                                  value={selectedCollege}
                                  onChange={(e) => {
                                    changeCollegeId(e.target.value);
                                    localStorage.setItem("clg", e.target.value);
                                  }}
                                >
                                  <option value="All" selected>All</option>
                                  {collegeOpt?.map((i, key) => (<>

                                    <option className="text-secondary" value={i.id}>
                                      {i.name}
                                    </option></>
                                  ))}
                                </select>
                              ) : (
                                <select
                                  className="form-control"
                                  style={{ outline: "none" }}
                                  value={selectedCollege}
                                  onChange={(e) => {
                                    changeCollegeId(e.target.value);
                                    localStorage.setItem("clg", e.target.value);
                                  }}
                                >
                                  {empColleges?.map((i, key) => (
                                    <option className="text-secondary" value={i.id}>
                                      {i.name}
                                    </option>
                                  ))}
                                </select>)}


                            </div>
                          </div>
                        </div>



                        {role == "SUPERADMIN" ? (<>

                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Options<span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-control"
                                value={optValue2}
                                onChange={(e) => setOptValue2(e.target.value)}
                              >
                                <option value="All">
                                  All
                                </option>
                                <option value="Proctor">Approved By Proctor</option>
                                <option value="Warden">Approved By Warden</option>
                              </select>

                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Select In/Out Time<span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-control"
                                value={optValue}
                                onChange={(e) => setOptValue(e.target.value)}
                              >
                                <option value="All">
                                  All
                                </option>
                                <option value="In">In</option>
                                <option value="Out">Out</option>
                              </select>
                            </div>
                          </div>



                        </>
                        ) : (
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Select In/Out Time<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                className="form-control"
                                value={optValue}
                                onChange={(e) => setOptValue(e.target.value)}
                              >
                                <option value="All">
                                  All
                                </option>
                                <option value="In">In</option>
                                <option value="Out">Out</option>
                              </select>
                            </div>
                          </div>

                        )}


                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              From date<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="date"
                              name="fromDate"
                              className="form-control"
                              placeholder="From Date"
                              value={fromDate}
                              onChange={(e) => setFromDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              To Date<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="date"
                              name="toDate"
                              className="form-control"
                              placeholder="To Date"
                              value={toDate}
                              onChange={(e) => setToDate(e.target.value)}
                            />
                          </div>
                        </div>

                        {/* <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              From Date/To Date<span style={{ color: "red" }}>*</span>
                            </label>
                         
                              
                            <RangePicker
                              className="form-control"
                              placeholder="From Date/To Date"
                              value={dates}
                            onChange={(values)=>{
                              const date1=moment(values[0]).format('DD-MM-YYYY')
                              console.log("DATE1- ",date1);
                              // const date2=moment(values[1]).format('DD-MM-YYYY')
                              // console.log("DATE2 -",date2);
                              
                              setDate(values)
                            }}
                            />
                           </div>
                        </div> */}
                      </div>
                    ) : (<div className="row">

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Options<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            value={optValue}
                            onChange={(e) => setOptValue(e.target.value)}
                          >
                            <option value="All">
                              All
                            </option>
                            <option value="In">In</option>
                            <option value="Out">Out</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            From date<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            name="fromDate"
                            className="form-control"
                            placeholder="From Date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            To Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            name="toDate"
                            className="form-control"
                            placeholder="To Date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                          />
                        </div>
                      </div>

                    </div>)}

                    <div className="col-md-12 d-flex align-items-center justify-content-end">
                      <button
                        className="btn btn-nex btn-rounded float-lg-left "
                        onClick={getData}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0"> GATE PASS IN AND OUT LIST</h4>
              </div>

              <div className="col-12">
                <div className="card">
                  <div className="col-md-8 ml-auto d-flex justify-content-end mt-3">

                    <button
                      className="btn btn-nex rounded-pill ml-3 float-right"
                      onClick={onDownload}
                    >
                      Export Excel
                    </button>
                    <button
                      className="btn btn-nex rounded-pill ml-2"
                      onClick={handlePrint}
                    >
                      Export PDF
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="table table-responsive dt-responsive table-bordered nowrap table-hove">
                      <table
                        id="datatable"
                        ref={tableRef}
                        className="table  nowrap table-hover table-responsive"
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          boarder: 0,
                          width: "100%",
                        }}
                      >
                        {role == "WARDEN" ? (<>
                          <thead>
                            <tr>
                              <th>Sl.No.</th>
                              <th>Student Name</th>
                              <th>Enrollment No</th>
                              <th>Faculty</th>
                              <th>Department</th>
                              <th>Class</th>
                              <th>Request date</th>
                              <th>Purpose Type</th>
                              <th>Out Going Purpose</th>
                              <th>Out going From date</th>
                              <th>Out going To date</th>
                              <th>No Of Days</th>
                              <th>In Time</th>
                              <th>Out Time</th>
                              <th>Proctor Status</th>
                              <th>Proctor</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {inoutdata &&
                              inoutdata.map((d, k) => (
                                <tr>
                                  <td>{k + 1}</td>
                                  <td>{d.Student_Name}</td>
                                  <td>{d.Enrollment_No}</td>
                                  <td>{d.College_Name}</td>
                                  <td>{d.deptname_name}</td>
                                  <td>{d.acadamic_classname}</td>
                                  <td>{d?.request_date.split("T")[0]}</td>
                                  <td>{d?.purpose_type}</td>
                                  <td>{d?.out_going_purpose}</td>
                                  <td>{d?.out_from_date}</td>
                                  <td>{d?.out_to_date}</td>
                                  <td>{d?.no_of_days}</td>
                                  <td>{d?.in_time}</td>
                                  <td>{d?.out_time}</td>
                                  <td><p
                                    className={` mt-3  ${d?.approval_status_1 == "APPROVED"
                                      ? " badge badge-soft-success"
                                      : d?.approval_status_1 == "PENDING"
                                        ? " badge badge-soft-warning"
                                        : d?.approval_status_1 == "DECLINED"
                                          ? " badge badge-soft-danger"
                                          : d?.approval_status_1 == "CANCELLED"
                                            ? " badge badge-soft-danger"
                                            : ""
                                      }`}
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "10px",
                                      textAlign: "center",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {d?.approval_status_1}
                                  </p></td>
                                  <td>{d?.first_name} {d?.last_name}</td>

                                  <td>

                                    <a
                                      data-toggle="modal"
                                      data-target="#HostelStudentGatePass2"
                                      onClick={() => {
                                        setData2({
                                          ...d,
                                        });
                                      }}
                                    >
                                      <button
                                        className="btn btn-primary btn-nex btn-rounded mr-2"
                                        type="button"
                                        name="submit"
                                        onClick={() => handleEditClick(d)}
                                      >
                                        View
                                      </button>
                                    </a>

                                  </td>
                                </tr>
                              ))}
                          </tbody></>
                        ) : (<></>)}
                        {role == "STAFF" ? (<>
                          <thead>
                            <tr>
                              <th>Sl.No.</th>
                              <th>Student Name</th>
                              <th>Enrollment No</th>
                              <th>Faculty</th>
                              <th>Department</th>
                              <th>Class</th>
                              <th>Request date</th>
                              <th>Purpose Type</th>
                              <th>Out Going Purpose</th>
                              <th>Out going From date</th>
                              <th>Out going To date</th>
                              <th>No Of Days</th>
                              <th>In Time</th>
                              <th>Out Time</th>
                              <th>Proctor Status</th>
                              <th>Proctor Approval Date</th>
                              <th>Warden Status</th>
                              <th>Warden Approval Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {staffllogininout &&
                              staffllogininout.map((d, k) => (
                                <tr>
                                  <td>{k + 1}</td>
                                  <td>{d.Student_Name}</td>
                                  <td>{d.Enrollment_No}</td>
                                  <td>{d.College_Name}</td>
                                  <td>{d.deptname_name}</td>
                                  <td>{d.acadamic_classname}</td>
                                  <td>{d?.request_date.split("T")[0]}</td>
                                  <td>{d?.purpose_type}</td>
                                  <td>{d?.out_going_purpose}</td>
                                  <td>{d?.out_from_date}</td>
                                  <td>{d?.out_to_date}</td>
                                  <td>{d?.no_of_days}</td>
                                  <td>{d?.in_time}</td>
                                  <td>{d?.out_time}</td>
                                  <td><p
                                    className={` mt-3  ${d?.approval_status_1 == "APPROVED"
                                      ? " badge badge-soft-success"
                                      : d?.approval_status_1 == "PENDING"
                                        ? " badge badge-soft-warning"
                                        : d?.approval_status_1 == "DECLINED"
                                          ? " badge badge-soft-danger"
                                          : d?.approval_status_1 == "CANCELLED"
                                            ? " badge badge-soft-danger"
                                            : ""
                                      }`}
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "10px",
                                      textAlign: "center",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {d?.approval_status_1}
                                  </p>

                                  </td>
                                  <td>{d.approved_date_2}</td>
                                  <td><p
                                    className={` mt-3  ${d?.approval_status_2 == "APPROVED"
                                      ? " badge badge-soft-success"
                                      : d?.approval_status_2 == "PENDING"
                                        ? " badge badge-soft-warning"
                                        : d?.approval_status_2 == "DECLINED"
                                          ? " badge badge-soft-danger"
                                          : d?.approval_status_2 == "CANCELLED"
                                            ? " badge badge-soft-danger"
                                            : ""
                                      }`}
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "10px",
                                      textAlign: "center",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {d?.approval_status_2}
                                  </p>

                                  </td>
                                  <td>{d.approved_date_2}</td>
                                  <td>

                                    <a
                                      data-toggle="modal"
                                      data-target="#HostelStudentGatePass2"
                                      onClick={() => {
                                        setData2({
                                          ...d,
                                        });
                                      }}
                                    >
                                      <button
                                        className="btn btn-primary btn-nex btn-rounded mr-2"
                                        type="button"
                                        name="submit"
                                        onClick={() => handleEditClick(d)}
                                      >
                                        View
                                      </button>
                                    </a>

                                  </td>
                                </tr>
                              ))}
                          </tbody></>

                        ) : (<></>)}
                        {role == "SUPERADMIN" ? (<>
                          <thead>
                            <tr>
                              <th>Sl.No.</th>
                              <th>Student Name</th>
                              <th>Enrollment No</th>
                              <th>Faculty</th>
                              <th>Department</th>
                              <th>Class</th>
                              <th>Request date</th>
                              <th>Purpose Type</th>
                              <th>Out Going Purpose</th>
                              <th>Out going From date</th>
                              <th>Out going To date</th>
                              <th>No Of Days</th>
                              <th>In Time</th>
                              <th>Out Time</th>
                              <th>Proctor Status</th>
                              <th>Proctor</th>
                              <th>Proctor Approval Date</th>
                              <th>Warden Status</th>
                              <th>Warden</th>
                              <th>Warden Approval Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {adminlogin &&
                              adminlogin.map((d, k) => (
                                <tr>
                                  <td>{k + 1}</td>
                                  <td>{d.Student_Name}</td>
                                  <td>{d.Enrollment_No}</td>
                                  <td>{d.College_Name}</td>
                                  <td>{d.deptname_name}</td>
                                  <td>{d.acadamic_classname}</td>
                                  <td>{d?.request_date}</td>
                                  <td>{d?.purpose_type}</td>
                                  <td>{d?.out_going_purpose}</td>
                                  <td>{d?.out_from_date}</td>
                                  <td>{d?.out_to_date}</td>
                                  <td>{d?.no_of_days}</td>
                                  <td>{d?.in_time}</td>
                                  <td>{d?.out_time}</td>
                                  <td><p
                                    className={` mt-3  ${d?.approval_status_1 == "APPROVED"
                                      ? " badge badge-soft-success"
                                      : d?.approval_status_1 == "PENDING"
                                        ? " badge badge-soft-warning"
                                        : d?.approval_status_1 == "DECLINED"
                                          ? " badge badge-soft-danger"
                                          : d?.approval_status_1 == "CANCELLED"
                                            ? " badge badge-soft-danger"
                                            : ""
                                      }`}
                                    style={{
                                      width: "80px",
                                      height: "30px",
                                      padding: "10px",
                                      textAlign: "center",
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {d?.approval_status_1}
                                  </p></td>
                                  <td>{d?.first_name} {d?.last_name}</td>

                                  <td>{d.approved_date_1}</td>
                                  <td>

                                    <p
                                      className={` mt-3  ${d?.approval_status_2 == "APPROVED"
                                        ? " badge badge-soft-success"
                                        : d?.approval_status_2 == "PENDING"
                                          ? " badge badge-soft-warning"
                                          : d?.approval_status_2 == "DECLINED"
                                            ? " badge badge-soft-danger"
                                            : d?.approval_status_2 == "CANCELLED"
                                              ? " badge badge-soft-danger"
                                              : ""
                                        }`}
                                      style={{
                                        width: "80px",
                                        height: "30px",
                                        padding: "10px",
                                        textAlign: "center",
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      {d?.approval_status_2}
                                    </p></td>
                                  <td>{d?.first_name} {d?.last_name}</td>
                                  <td>{d.approved_date_2}</td>

                                  <td>

                                    <a
                                      data-toggle="modal"
                                      data-target="#HostelStudentGatePass2"
                                      onClick={() => {
                                        setData2({
                                          ...d,
                                        });
                                      }}
                                    >
                                      <button
                                        className="btn btn-primary btn-nex btn-rounded mr-2"
                                        type="button"
                                        name="submit"
                                        onClick={() => handleEditClick(d)}
                                      >
                                        View
                                      </button>
                                    </a>

                                  </td>
                                </tr>
                              ))}

                          </tbody>

                        </>
                        ) : (<></>)}
                      </table>
                      {inoutdata?.length != 0 || adminlogin?.length != 0 || staffllogininout?.length != 0 ? null : <Nodata />}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ViewStudentGatePass;
