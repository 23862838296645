import axios from "axios";
import React, { useState, useEffect } from "react";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ROUTES } from "../../Router/routerConfig";
import {
  GET_PRE_ADMISSION,
  LOGIN,
  OTP_GENRATE,
  UNIVERSITY_DETAILS,
  VERIFY_OTP,
} from "./AdmissionApiConstants";
import { USER_AUTH } from "./AdmissionStorageConstats";

function LoginAdmission() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(0);

  const [selected, setSelected] = useState("login");

  const navigate = useNavigate();

  const getData = async () => {
    setLoading(1);
    var config = {
      method: "get",
      url: UNIVERSITY_DETAILS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // SEND_OTP
  const otpSender = async () => {
    const otpObj = {
      phone: phone,
    };

    var config = {
      method: "post",
      url: OTP_GENRATE,
      headers: {
        "Content-Type": "application/json",
      },
      data: otpObj,
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success(`otp sent successfully`);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  const sendOtp = (e) => {
    e.preventDefault();
    if (!phone) {
      toast.error("phone is required");
      return;
    }

    otpSender();
  };

  // VERIFY_OTP

  const verifyOtp = async () => {
    const otpObj = {
      name,
      email,
      otp,
      phone,
    };

    var config = {
      method: "post",
      url: VERIFY_OTP,
      headers: {
        "Content-Type": "application/json",
      },
      data: otpObj,
    };

    setLoading(1);

    await axios(config)
      .then(async (res) => {
        setLoading(0);
        toast.success(res.data.message);
        setEmail("");
        setPhone("");
        setName("");
        setOtp("");
        // setSelected("login");
        await login();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  // REGISTER

  const register = (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Name is required");
      return;
    } else if (!email) {
      toast.error("Email is required");
      return;
    } else if (!phone) {
      toast.error("phone is required");
      return;
    }
    // else if (!otp) {
    //   toast.error("Otp is required");
    //   return;
    // }

    verifyOtp();
  };

  // LOGIN
  const login = async (e) => {
    e?.preventDefault();

    let admission_id;
    if (!phone) {
      toast.error("Phone is required");
      return;
    }
    // else if (!otp) {
    //   toast.error("Otp is required");
    //   return;
    // }

    const loginObj = {
      phone: phone,
      otp: otp,
    };

    var config = {
      method: "post",
      url: LOGIN,
      headers: {
        "Content-Type": "application/json",
      },
      data: loginObj,
    };

    setLoading(1);
    await axios(config)
      .then(async (res) => {
        toast.success(`User LoggedIn successfully`);
        sessionStorage.setItem(
          "temp_usn",
          res.data.data.application_data?.temp_usn
        );
        admission_id = res.data.data.id;
        sessionStorage.setItem(USER_AUTH, res.data.data.token);

        var config1 = {
          method: "get",
          url: `${GET_PRE_ADMISSION}?phone=${phone}`,
          headers: {
            "Content-Type": "application/json",
          },
        };

        await axios(config1)
          .then((res) => {
            setLoading(0);
            console.log("PreadmissionData -", res.data.data);
            sessionStorage.setItem("phone", phone);
            if (res.data && res.data.data.applications?.length > 0) {
              navigate(ROUTES.AdmissionPortal.Dashboard, {
                state: {
                  phone: phone,
                },
              });
            } else {
              navigate(ROUTES.AdmissionPortal.Admission.basicHome, {
                state: {
                  id: admission_id,
                },
              });
            }
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
          });
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        return toast.error(err.response.data.message);
      });
  };

  return (
    <div
      className="Login"
      style={{
        maxWidth: "100vw",
        overflow: "hidden",
        backgroundColor: "white",
      }}
    >
      <Loader loading={loading} />
      <ToastContainer autoClose={2000} />
      <>
        <div style={{ backgroundColor: "white" }}>
          <div className="card">
            <div className="container-fluid p-0 mt-5">
              <div className="row no-gutters">
                <div className="col-lg-8 m-auto">
                  <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                    <div className="w-100">
                      <div className="row justify-content-center mt-5">
                        <div className="col-lg-9">
                          <div>
                            <div className="text-center">
                              <div>
                                <a href="index.php" className="logo mb-5 mt-5">
                                  <img
                                    src={data?.logo_primary}
                                    // src="https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/kbn_assets/employee_image/university_logo_primary/undefined_1709470215717.png"
                                    height={100}
                                    alt="logo"
                                  />
                                </a>
                              </div>
                              <h4 className="font-size-18 mt-4">Welcome !</h4>
                              <p className="text-muted">
                                Sign in to continue to Admission Portal.
                              </p>
                            </div>
                            <div className="p-2 mt-5">
                              {selected === "login" ? (
                                <form className="form-horizontal">
                                  <div className="form-group auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon" />
                                    <label htmlFor="username">
                                      Phone Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="username"
                                      name="Phone"
                                      placeholder="Enter Phone Number"
                                      value={phone}
                                      onChange={(e) => {
                                        setPhone(e.target.value);
                                      }}
                                    />
                                  </div>
                                  {phone.length >= 10 ? (
                                    <div
                                      className={`mt-0 p-0 mb-3 d-flex justify-content-end`}
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        onClick={(e) => sendOtp(e)}
                                        className={`mt-1 p-2 btn btn-primary`}
                                      >
                                        GET OTP
                                      </a>
                                    </div>
                                  ) : null}
                                  <div className="form-group auth-form-group-custom mb-4">
                                    <i className="ri-lock-2-line auti-custom-input-icon" />
                                    <label htmlFor="userpassword">OTP</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="otp"
                                      placeholder="Enter OTP"
                                      value={otp}
                                      onChange={(e) => {
                                        setOtp(e.target.value);
                                      }}
                                    />
                                  </div>

                                  <div className="mt-4 text-center">
                                    <button
                                      className="btn btn-primary btn-block w-md waves-effect waves-light"
                                      type="submit"
                                      name="submit"
                                      value="login"
                                      onClick={login}
                                    >
                                      Log In
                                    </button>
                                  </div>
                                  <div className="mt-4 text-center">
                                    <a href="/" className="text-muted">
                                      <i className="mdi mdi-lock mr-1" /> Forgot
                                      your password?
                                    </a>
                                  </div>

                                  <div className="mt-4 text-center">
                                    <a
                                      href=""
                                      className="text-primary"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setSelected("register");
                                      }}
                                    >
                                      Register
                                    </a>
                                  </div>
                                </form>
                              ) : (
                                <form className="form-horizontal">
                                  <div className="form-group auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon" />
                                    <label htmlFor="username">Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="username"
                                      name="name"
                                      placeholder="Enter Name"
                                      value={name}
                                      onChange={(e) => {
                                        setName(e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div className="form-group auth-form-group-custom mb-4">
                                    <i class="ri-mail-line auti-custom-input-icon"></i>
                                    <label htmlFor="username">Email</label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="username"
                                      name="username"
                                      placeholder="Enter Email"
                                      value={email}
                                      onChange={(e) => {
                                        setEmail(e.target.value);
                                      }}
                                    />
                                  </div>
                                  <div className="form-group auth-form-group-custom mb-3">
                                    <i className="ri-phone-line auti-custom-input-icon" />
                                    <label htmlFor="username">Phone</label>
                                    <input
                                      type="tel"
                                      className="form-control"
                                      id="username"
                                      name="phone"
                                      placeholder="Enter Phone"
                                      value={phone}
                                      onChange={(e) => {
                                        setPhone(e.target.value);
                                      }}
                                    />
                                    {/* <i className="ri-phone-line auti-custom-input-icon" /> */}
                                  </div>

                                  {phone.length >= 10 ? (
                                    <div
                                      className={`mt-0 p-0 mb-3 d-flex justify-content-end`}
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        onClick={(e) => sendOtp(e)}
                                        className={`mt-1 p-2 btn btn-primary`}
                                      >
                                        GET OTP
                                      </a>
                                    </div>
                                  ) : null}

                                  <div className="form-group auth-form-group-custom mb-4">
                                    <i className="ri-smartphone-line auti-custom-input-icon" />
                                    <label htmlFor="username">OTP</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="username"
                                      name="username"
                                      placeholder="Enter OTP"
                                      value={otp}
                                      onChange={(e) => {
                                        setOtp(e.target.value);
                                      }}
                                    />
                                  </div>

                                  <div className="mt-4 text-center">
                                    <button
                                      className="btn btn-primary w-md waves-effect waves-light"
                                      name="submit"
                                      onClick={(e) => register(e)}
                                    >
                                      Register
                                    </button>
                                  </div>
                                  <div className="mt-4 text-center">
                                    <a href="/" className="text-muted">
                                      <i className="mdi mdi-lock mr-1" /> Forgot
                                      your password?
                                    </a>
                                  </div>
                                  <div className="mt-4 text-center">
                                    <i className="mdi mdi-lock mr-1" /> Already
                                    have account ?
                                  </div>

                                  <div className="mt-4 text-center btn-block">
                                    <a
                                      href="JavaScript:Void(0)"
                                      className="text-primary"
                                      onClick={() => setSelected("login")}
                                    >
                                      Login
                                    </a>
                                  </div>
                                </form>
                              )}
                            </div>
                            <div className="mt-5 text-center">
                              <p className="d-flex align-items-center justify-content-center">
                                © {new Date().getFullYear()} College Name.
                                Crafted with&nbsp;
                                <i class="ri-heart-3-fill text-danger"></i>
                                &nbsp; by&nbsp;
                                <a href="https://www.nexenstial.com/">
                                  {" "}
                                  Nexenstial{" "}
                                </a>
                              </p>
                              <br />
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default LoginAdmission;
