import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Toggler from "../../../Components/Toggler/Toggler";
import { PAYROLL_EARNING, PAYROLL_PAYSCALE } from "../../../utils/apiConstants";

function ModalPayRollPayScale({ type, data, reloadData, setLoading }) {
  //object for all input values
  const [user, setUser] = useState({
    description: "",
    title: "",
    basic_salary: 0,
    status: "ACTIVE",
  });

  //handleChange for all input fields
  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  //fuction to clear the input fields after completion of any operation
  const clearData = () => {
    setUser({
      description: "",
      title: "",
      basic_salary: 0,
      status: "ACTIVE",
    });
  };

  const handleSubmit = async () => {
    //config for axios

    if (!user.title) return toast.error("Please Enter title");
    if (!user.description) return toast.error("Please Enter Description");
    if (!user.basic_salary) return toast.error("Please Enter Basic Salary");

    const config = {
      method: type === "edit" ? "put" : "post",
      url: `${PAYROLL_PAYSCALE}${type === "edit" ? "/" + data.id : ""}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        ...user,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success(res.data.message);
        reloadData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleDelete = async () => {
    const config = {
      method: type === "edit" ? "put" : "post",
      url: `${PAYROLL_PAYSCALE}${type === "edit" ? "/" + data.id : ""}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        ...user,
        status: "INACTIVE",
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Pay Scale Deleted");
        reloadData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    if (type === "edit") {
      if (data) {
        setUser({
          description: data.description,
          title: data.title,
          basic_salary: data.basic_salary,
          status: "ACTIVE",
        });
      }
    }
    if (type === "add") {
      clearData();
    }
  }, [data, type]);

  return (
    <div className="ModalPayRollPayScale">
      <div
        className="modal fade"
        id="ModalPayRollPayScale"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Pay Scale
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <lable>
                      {" "}
                      Title <span style={{ color: "red" }}>*</span>
                    </lable>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={user.title}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <lable>
                      {" "}
                      Description <span style={{ color: "red" }}>*</span>
                    </lable>
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      value={user.description}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <lable>
                      {" "}
                      Basic Salary <span style={{ color: "red" }}>*</span>
                    </lable>
                    <input
                      type="number"
                      className="form-control"
                      name="basic_salary"
                      min={1}
                      value={user.basic_salary}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <button
                    className="btn btn-primary btn-rounded btn-outline mr-1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  {type == "edit" ? (
                    <button
                      className="btn btn-danger btn-rounded btn-outline"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-9">
                  <button
                    className="btn btn-success btn-rounded btn-outline float-right"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPayRollPayScale;
