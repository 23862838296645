import { Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { GET_PROCTORING_MEETINGS, PROCTEE_LIST } from "../../utils/apiConstants";
import axios from "axios";
import { Link } from "react-router-dom";
import { EyeOutlined, PlusCircleFilled } from "@ant-design/icons";
import { ROUTES } from "../../Router/routerConfig";
import { toast } from "react-toastify";
import { sessionOpt } from "../../Data/jsonData/Academics/Academics";
import { Modal} from 'antd';
import { Http } from "../../Services/Services.js";
import { CREATE_PROCTORING_MEETING, GET_PROCTEE_BY_PROCTORING_ID } from "../../utils/apiConstants.js";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
} from "../../utils/Academics.apiConst";
import {
  GET_STUDENTS2,
} from "../../utils/apiConstants";
import {
  LOCAL_DEPARTMENT,
  LOCAL_COLLEGE,
} from "../../utils/LocalStorageConstants";
import { SESSION_ROLE } from "../../utils/sessionStorageContants";

export default function ProcteeList({ setLoading, collegeId }) {

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  useEffect(() => {
    setDepartment(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == collegeId
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);

  const [open, setOpen] = useState(false);

  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const [meetingdata, setmeetingsData] = useState([]);

  const [ selectedData , setSelectedData ] = useState([]);

  const [classOpt, setClassOpt] = useState([]);

  const [sectionOpt, setSectionOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [faculty, setFaculty] = useState("");

  const [currentclass, setCurrentClass] = useState("");

  const [currentSemester, setCurrentSemester] = useState("");

  const [currentSection, setCurrentSection] = useState("");

  const [status, setStatus] = useState("ACTIVE");

  const [session, setSession] = useState("");

  const [studentData, setStudentData] = useState([]);

  const [displayData, setDisplayData] = useState([]);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const getData1 = async () => {
    setLoading(1)
    const config1 = {
      method: "get",
      url: GET_PROCTORING_MEETINGS,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    }

    await axios(config1)
      .then(res => {
        setData1(res.data.data);
        // console.log("data1",res.data.data);
        setLoading(0)
      })
      .catch(err => {
        setLoading(0)
        console.log(err);
        toast.error('Something went wrong')
      })

  }

  useEffect(() => {
    getData1();
  },[]);

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await axios.get(GET_PROCTORING_MEETINGS);
        setData1(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData1();
  }, []);

  const getData = async () => {
    if (!faculty || !currentclass || !session)
      return toast.error("Mandatory fields are required");
    setLoading(1);
    const config = {
      method: "get",
      url:
        GET_STUDENTS2 +
        `?college_id=${collegeId}&department_id=${faculty}&class_id=${currentclass}&section_id=${currentSection}&semester_id=${currentSemester}&session_id=${session}&status=${status}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
   
    await axios(config)
      .then((res) => {
        console.log("student data - ", res.data.data);
        // console.log('proctee list - ', data);
        setStudentData(res.data.data);
        const filteredData = data.filter((student) =>
          res.data.data.some((d) => d.academic_student_session_id === student.proctee_id)
        );
        // console.log('filtered data - ', filteredData);
        setDisplayData(filteredData);

        const meetingsData = data1
        .filter((d) => filteredData.some((dataItem) => dataItem.id == d?.proctoring_details_id))
        .map((d) => {
            // console.log('Content of variable "d":', d);
            // console.log("d?.date",d?.date);
            return {
              ...d,
            };
          });
        // console.log('meetings data - ', meetingsData);
        setmeetingsData(meetingsData);

      })
      .catch((err) => {
        console.log(err);
        toast.error("Some Error Occured");
      }).finally(()=>{
        setLoading(0);
      });
  };

//   console.log("data1------",data1);

  const getAllData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data2, data3, data4] = await Promise.all([
      await axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),

      await axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),

      await axios({
        ...config,
        url: `${ACADEMICS_ADD_SECTION}?college_id=${collegeId}`,
      })
        .then((res) => {
          setSectionOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),

      
    ]);
  };

  const [proctoringData, setProctoringData] = useState({
    agenda: "",
    date: "",
    issue_discussed: "",
  });

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setProctoringData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }


  const handleSubmit = async () => {
    if(!proctoringData?.agenda || !proctoringData?.date) {
      return toast.error("Please enter agenda and date of the meeting");
    }
    if(selectedData?.length == 0) {
      return toast.error("No students selected");
    }
    // console.log('data - ', selectedData);

    setLoading(1);
    const promises = [];
    for(let i in selectedData) {
      const postPromise = await Http.post(
        `${CREATE_PROCTORING_MEETING}`,
        {
          ...proctoringData,
          proctoring_details_id : selectedData[i].id
        }
      ).then((data)=>{
        console.log(`Proctoring Meeting Created Successfully for ${selectedData[i].proctee_id}`)
      }).catch((err)=>{
        toast.error(err.response.data.message)
      }).finally(()=>{
        setLoading(0)
      });

      promises.push(postPromise);
    }

    Promise.all(promises)
      .then((results) => {
        setLoading(0);
        console.log('All requests completed successfully : ', results);
        toast.success(`Scheduled meeting with ${selectedData.length} students`);
      })
      .catch((error) => {
        setLoading(0);
        console.error('Error making POST requests : ', error);
        return toast.error('Something Went Wrong');
      });
    setOpen(false);
    setConfirmLoading(false);
    setLoading(0);
  }


  useEffect(() => {
    async function getDataByStaff() {
      setLoading(1);
      try {
        const employee_id = sessionStorage.getItem("employee_id");
        const config = {
          method: "get",
          url: `${PROCTEE_LIST}/${employee_id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
        const res = await axios(config);
        const modifiedData = res.data.data.map((obj, index) => ({ ...obj, key: obj.id }));
        // console.log('proctee data - ', modifiedData);
        setData(modifiedData);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(0);
      }
    }

    getDataByStaff();
    getAllData();
    setLoading(0);
  }, []);

//   // Helper function to check if the date is a previous meeting
//   const dateIsPreviousMeeting = (date) => {
//     const currentDate = new Date();
//     const meetingDate = new Date(date);
//     return meetingDate < currentDate;
//   };
  
//   // Helper function to check if the date is a next meeting
//   const dateIsNextMeeting = (date) => {
//     const currentDate = new Date();
//     const meetingDate = new Date(date);
//     return meetingDate >= currentDate;
//   };

// Helper function to check if the date is a previous meeting
const dateIsPreviousMeeting = (date) => {
    const currentDate = new Date();
    const meetingDate = new Date(date);
    return meetingDate < currentDate && !isSameDay(meetingDate, currentDate);
  };
  
  // Helper function to check if the date is a next meeting
  const dateIsNextMeeting = (date) => {
    const currentDate = new Date();
    const meetingDate = new Date(date);
    return meetingDate >= currentDate || isSameDay(meetingDate, currentDate);
  };
  
  // Helper function to check if two dates are the same day
  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const columns = [
    {
      title: "Enrollment Number",
      dataIndex: "student_id",
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "student_name",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      render: (gender) => {
        return gender === "male" ? "Male" : "Female";
      },
    },

    {
        title: "Previous Meeting",
        dataIndex: "date",
        render: (date, record) => {
          const filteredData = data.filter((student) =>
            studentData.some((d) => d.academic_student_session_id === student.proctee_id)
          );
          console.log('filtered data1 -- ', filteredData);
      
          const meetingsData = data1
            .filter((d) => filteredData.some((dataItem) => dataItem.id == d?.proctoring_details_id))
            .map((d) => d);
          console.log('meetings data1 ----- ', meetingsData);
      
        //   const currentStudentMeetings = meetingsData
        //     .filter((meeting) => meeting.proctoring_details_id === record.id)
        //     .sort((a, b) => new Date(b.date) - new Date(a.date));
      
        //   const previousMeeting = currentStudentMeetings.find((meeting) =>
        //     dateIsPreviousMeeting(meeting.date)
        //   );

        const currentStudentMeetings = meetingsData
        .filter((meeting) => meeting.proctoring_details_id === record.id)
        .sort((a, b) => new Date(b.date) - new Date(a.date));

      const previousMeeting = currentStudentMeetings.find((meeting) =>
        dateIsPreviousMeeting(meeting.date)
      );
      
          if (!previousMeeting) {
            return <span></span>;
          }
      
          const linkTo = `${ROUTES.Employee.NextProctoringMeetingDetails}/${previousMeeting.id}`;
      
          return (
            <Link to={linkTo} style={{ color: 'red' }}>
              {previousMeeting.date}
            </Link>
          );
        },
      },
      {
        title: "Next Meeting",
        dataIndex: "date",
        render: (date, record) => {
          const filteredData = data.filter((student) =>
            studentData.some((d) => d.academic_student_session_id === student.proctee_id)
          );
          console.log('filtered data2 -- ', filteredData);
      
          const meetingsData = data1
            .filter((d) => filteredData.some((dataItem) => dataItem.id == d?.proctoring_details_id))
            .map((d) => d);
          console.log('meetings data2 ----- ', meetingsData);
      
        //   const currentStudentMeetings = meetingsData
        //     .filter((meeting) => meeting.proctoring_details_id === record.id)
        //     .sort((a, b) => new Date(a.date) - new Date(b.date));
      
        //   const nextMeeting = currentStudentMeetings.find((meeting) =>
        //     dateIsNextMeeting(meeting.date)
        //   );

        const currentStudentMeetings = meetingsData
        .filter((meeting) => meeting.proctoring_details_id === record.id)
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      const nextMeeting = currentStudentMeetings.find((meeting) =>
        dateIsNextMeeting(meeting.date)
      );
      
          if (!nextMeeting) {
            return <span></span>;
          }
      
          const linkTo = `${ROUTES.Employee.NextProctoringMeetingDetails}/${nextMeeting.id}`;
      
          return (
            <Link to={linkTo} style={{ color: 'green' }}>
              {nextMeeting.date}
            </Link>
          );
        },
      },
    
    {
      title: "Add New Meeting",
      dataIndex: "id",
      fixed: "right",
      render: (item) => {
        return (
          <Link to={`${ROUTES.Employee.CreateProctoringMeeting}/${item}`}>
            <PlusCircleFilled />
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Department <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            name="faculty"
                            id="section"
                            className="form-control"
                            value={faculty}
                            onChange={(e) => {
                              setFaculty(e.target.value);
                            }}
                          >
                            {/* <option value="" selected>
                              ALL
                            </option> */}
                            {department?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Session</label>
                          <select
                            className="form-control"
                            value={session}
                            onChange={(e) => {
                              setSession(e.target.value);
                            }}
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option value={i?.id}>{i?.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            className="form-control"
                            value={currentclass}
                            onChange={(e) => {
                              setCurrentClass(e.target.value);
                            }}
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter((s) => s?.department_id == faculty)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            className="form-control"
                            value={currentSemester}
                            onChange={(e) => {
                              setCurrentSemester(e.target.value);
                            }}
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == currentclass)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Section</label>
                          <select
                            className="form-control"
                            value={currentSection}
                            onChange={(e) => {
                              setCurrentSection(e.target.value);
                            }}
                          >
                            <option value="">Select Section</option>
                            {sectionOpt
                              ?.filter((s) => s.semester_id == currentSemester)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Status</label>
                          <select
                            className="form-control"
                            value={status}
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >
                            <option value="">Select Status</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row float-right">
                      <button
                        className="btn btn-primary btn-rounded"
                        type="submit"
                        name="submit"
                        onClick={getData}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">MY PROCTEE LIST</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Proctoring</a>
                      </li>
                      <li className="breadcrumb-item active">
                        My Proctee List
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                  <div className="row">
                      <div className="col-md-6">
                        {" "}
                        <h4 className="card-title">Proctee List</h4>
                      </div>
                    {/* <div className="col-md-12">
                        <label className="ml-2">Schedule Meeting for Selected Students</label>
                        <span className="">
                          <button
                            disabled={selectedData.length == 0}
                            className="btn btn-success ml-2"
                            onClick={() => {
                                showModal();
                            }}
                          >
                            Schedule
                          </button>
                          <Modal
                            title="Schedule Meeting"
                            open={open}
                            onOk={handleSubmit}
                            confirmLoading={confirmLoading}
                            onCancel={() => {setOpen(false)}}
                            zIndex={1021}
                          > 
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Agenda <span className="text-danger">*</span></label>
                                  <input type="text" className="form-control" name="agenda" onChange={handleChange}/>
                                </div>
                              </div>


                              <div className="col-md-6"><div className="form-group">
                                <label>Date <span className="text-danger">*</span></label>
                                <input type="date" className="form-control" name="date" onChange={handleChange}/>
                              </div>
                              </div>
                              
                              <div className="col-md-12"><div className="form-group">
                                <label>Description</label>
                                <input type="text" className="form-control" name="issue_discussed" onChange={handleChange}/>
                              </div>
                              </div>
                            </div>
                          </Modal>

                        </span>
                    </div> */}
                    </div>
                    <div className="table-responsive">
                      <hr />
                      {data && <Table 
                      rowSelection={{
                        type: "checkbox",
                        onChange: (selectedRowKeys, selectedRows) => {
                          setSelectedData(selectedRows);
                          console.log(selectedRows)
                        },
                        getCheckboxProps: (record) => ({
                          disabled: record.name === "Disabled User",
                        }),
                      }}
                      columns={columns} 
                      dataSource={displayData 
                    //   && meetingdata
                      } 

                      />}
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* end col */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
