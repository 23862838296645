import React, { useState, useEffect } from "react";
import {useParams} from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import Nodata from "../../Components/NoData/Nodata";
import Select from "react-select";
import {COURSE_OUTCOMES, ACADEMICS_ADD_SUBJECT, COURSE_PROGRAM_MAP, ACADEMICS_ADD_PROGRAM_OUTCOMES} from "../../utils/Academics.apiConst";
import { useNavigate } from "react-router-dom";
import {sessionOpt} from '../../Data/jsonData/Academics/Academics';
import ModalCourseProgramMap from "./ModalCourseProgramMap";

function CourseOutcomes({ collegeId, setLoading }) {

    const [data, setData] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [course, setCourse] = useState([]);

    const [mappingData, setMappingData] = useState([]);
    const [outcomeData, setOutcomeData] = useState([]);
    const [programOutcomes, setProgramOutcomes] = useState([]);
    const [user, setUser] = useState({
        session_id: "",
        name: "",
        description: "",
        status: "ACTIVE",
    });

    const params = useParams();
    const course_id = params.id;
    console.group(params);
    const name=""
    const navigate = useNavigate();
    const changeDir = (dir, id) => {
      navigate(`${dir}/${id}`);
    };

    const clearData = () => {
    setUser({
        ...user, 
        name:"",
        desciption: "",
        status:"ACTIVE"
    });
    };

    const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
        ...prev,
        [name]: value,
    }));
    };

    const getCourseData = async () => {
      if(!course_id) {
        return toast.error("Unable to get Course")
      }

      const config = {
          method: "get",
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              "Content-Type": "application/json",
          },
          url: `${ACADEMICS_ADD_SUBJECT}?id=${course_id}` 
      };
      
      setLoading(1);
      await axios(config)
      .then((res) => {
          console.log('course data - ', res.data.data);
          res.data.data[0] ? setCourse(res.data.data[0]) : setCourse([]);
          setLoading(0);
      })
      .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
      })
    }

    const getProgramOutcomes = async (id) => {
      if(!id) {
        return toast.error("Unable to get Course")
      }

      const config = {
          method: "get",
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
              "Content-Type": "application/json",
          },
          url: `${ACADEMICS_ADD_PROGRAM_OUTCOMES}?department_id=${id}` 
      };
      
      setLoading(1);
      await axios(config)
      .then((res) => {
          console.log('program outcomes - ', res.data.data);
          setProgramOutcomes(res.data.data);
          setLoading(0);
      })
      .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
      })
    }

    const getAllData = async () => {
        if(!user.session_id) {
          return toast.error("Please select Academic Year")
        }
        if(!course_id) return toast.error("Unable to get course");

        const config = {
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            url: `${COURSE_OUTCOMES}?session_id=${user.session_id}&course_id=${course_id}` 
        };
        
        setLoading(1);
        await axios(config)
        .then((res) => {
            console.log('course outcomes data - ', res.data.data);
            setData(res.data.data);
            setLoading(0);
        })
        .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
        })
    }

    const handleSubmit = async () => {

        if(!user.session_id) return toast.error("Please select Academic year");
        if(!user.name) return toast.error("Please Enter name of the outcome");
        if(!user.description) return toast.error("Please Enter description");
        if(!course_id) return toast.error("Unable to get course");
        console.log('data to backend - ', user);
        setLoading(1);
        const config = {
          method: "post",
          url: COURSE_OUTCOMES,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: {
            ...user,
            course_id
          },
        };
    
        await axios(config)
          .then((res) => {
            setLoading(0);
            toast.success("Course Outcome Added");
            clearData();
            getAllData();
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
          });
    };

    const handleEdit = async () => {

        if(!user.name) return toast.error("Please Enter Name of the outcome");
        
        setLoading(1);
        const config = {
          method: "put",
          url: COURSE_OUTCOMES + `/${user.id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: user,
        };
    
        await axios(config)
          .then((res) => {
            setLoading(0);
            toast.success("Course Outcome Updated");
            setEdit(false);
            setAddNew(true);
            clearData();
            getAllData();
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
          });
      };

      const handleDelete = async (i) => {
        
        setLoading(1);
        const config = {
          method: "put",
          url: `${COURSE_OUTCOMES}/${i?.id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: {
            status: "INACTIVE",
          },
        };
        axios(config)
          .then((res) => {
            setLoading(0);
            toast.success("Course outcome Deleted");
            getAllData();
          })
          .catch((err) => {
            setLoading(0);
            toast.error("Some Error Occured");
          });
      };


      const getProgrammingMappings = async (id) => {
        setLoading(1);
        const config = {
          method: "get",
          url: `${COURSE_PROGRAM_MAP}?course_outcome_id=${id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then((res) => {
            setLoading(0);
            console.log('mappings data - ', res.data.data);
            setMappingData(res.data.data);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Some Error Occured");
          });
      }

    useEffect(() => {
      getCourseData();
    },[]);

    useEffect(() => {
      console.log(outcomeData);
    },[outcomeData])


    return (
      
        <div>
          <ModalCourseProgramMap setLoading={setLoading} programOutcomes={programOutcomes} outcome={outcomeData} mappingData={mappingData} setMappingData={setMappingData} course={course} getProgrammingMappings={getProgrammingMappings}/>
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                {/* start page title */}
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">Course Outcomes : {course?.name}</h4>
                      
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Course</a>
                          </li>
                          <li className="breadcrumb-item active"> Course Outcomes</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        {edit || addNew ? (
                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                  Academic Year
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select name="session_id" id="session_id" 
                                      required="" className="form-control" 
                                      value={user?.session_id} 
                                      onChange={handleChange}
                                      disabled={edit}
                                      >
                                <option value="">Academic Year</option>
                                {sessionOpt &&
                                  sessionOpt?.map((item, key) => {
                                    return (
                                      <option value={item?.id}>{item?.name.substring(0,4)}-{parseInt(item?.name.substring(0,4)) + 1}</option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Name<span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                name="name"
                                placeholder="Ex : CO1"
                                className="form-control"
                                value={user.name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Description<span style={{ color: "red" }}>*</span>
                              </label>
                              <textarea
                                name="description"
                                placeholder="Enter the Description"
                                className="form-control"
                                value={user.description}
                                onChange={handleChange}
                                rows="2"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      ) : null}
                        {edit == false ? (
                          <>
                            {addNew == false ? (
                              <div className="row">
                                <div className="col-md-2">
                                <div className="form-group">
                                  <select name="session_id" id="session_id" required="" className="form-control" value={user?.session_id} onChange={handleChange}>
                                    <option value="">Academic Year</option>
                                    {sessionOpt &&
                                      sessionOpt?.map((item, key) => {
                                        return (
                                          <option value={item?.id}>{item?.name.substring(0,4)}-{parseInt(item?.name.substring(0,4)) + 1}</option>
                                        );
                                      })}
                                  </select>
                                </div>
                                </div>
                                  <div className="col-md-3">
                                    <button
                                      className="btn btn-nex  btn-md"
                                      type="submit"
                                      name="submit"
                                      value="collect"
                                      onClick={getAllData}
                                    >
                                      <i className="fa fa-search mr-1" aria-hidden="true" />{" "}
                                      Search
                                    </button>
                                  </div>
                                <div className="col-md-7">
                                    <button
                                    className="btn btn-nex btn-rounded float-lg-right mx-1 "
                                    type="submit"
                                    name="submit"
                                    onClick={() => setAddNew(true)}
                                  >
                                    <i className="fa fa-add" aria-hidden="true" /> +
                                    Add New
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <>
                                <button
                                  className="btn btn-nex btn-rounded float-lg-right mx-1"
                                  type="submit"
                                  name="submit"
                                  onClick={handleSubmit}
                                >
                                  <i
                                    className="fa fa-save"
                                    aria-hidden="true"
                                  />{" "}
                                  Save
                                </button>
                                <button
                                  className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                                  type="submit"
                                  name="submit"
                                  // style={{aspectRatio:'1/1'}}
                                  onClick={() => {
                                    setEdit(false);
                                    setAddNew(false);
                                  }}
                                >
                                  {"<   "}{" "}
                                </button>
                              </>
                            )}
                          </>
                        ) : (
                            <>
                          <button
                            className="btn btn-nex btn-rounded float-lg-right mx-1"
                            type="submit"
                            name="submit"
                            onClick={handleEdit}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Edit
                          </button>
                          <button
                                  className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                                  type="submit"
                                  name="submit"
                                  // style={{aspectRatio:'1/1'}}
                                  onClick={() => {
                                    setEdit(false);
                                    setAddNew(false);
                                  }}
                                >
                                  {"<   "}{" "}
                                  
                                </button>
                          </>
                        )}
                        <br />
                        <br />
                        <div className="row">    
                          <div className="col-md-4">
                            {" "}
                            <h3 className="card-title">Course outcomes for {course?.name}</h3>
                          </div>
                        </div>
                        <table
                          id="datatable"
                          className="table table-bordered dt-responsive nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Year</th>
                              <th>Outcome</th>
                              <th>Description</th>
                              {sessionStorage.getItem("role") != "STAFF" ? (
                                <th>Action</th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((d, key) => {
                              return (
                                <tr>
                                  <td>{key + 1}</td>
                                  <td>{d?.session_id}-{parseInt(d?.session_id)+1}</td>
                                  <td>{d?.outcome}</td>
                                  <td>{d?.description}</td>
                                  {sessionStorage.getItem("role") != "STAFF" ? (
                                    <td>
                                      <div className="row ml-1">
                                      <acronym title="Edit">
                                        <a
                                          href="javascript:void(0)"
                                          className="badge badge-light"
                                          onClick={(e) => {
                                            setEdit(true);
                                            setUser({ ...d, name: d?.outcome });
                                          }}
                                        >
                                          <i
                                            className="fa fa-edit "
                                            aria-hidden="true"
                                          />
                                        </a>
                                      </acronym>
                                      <a href="javascript:void(0)"> </a> &nbsp;
                                      <acronym title="Delete">
                                        <a
                                          href="javascript:void(0)"
                                          className="badge badge-light ml-2"
                                          onClick={() => {
                                            handleDelete(d);
                                          }}
                                        >
                                          <i
                                            className="fa fa-thumbs-down"
                                            aria-hidden="true"
                                            style={{ color: "red" }}
                                          />
                                        </a>
                                      </acronym>
                                      <div className="div ml-2"
                                        data-toggle="modal"
                                        data-target="#ModalCourseProgramMap"
                                      >
                                      <acronym title="Map to Program Outcomes">
                                          <a

                                            href="javascript:void(0)"
                                            className=" badge badge-light"
                                            onClick={() => {
                                              setOutcomeData(d);
                                              getProgrammingMappings(d.id);
                                            }}
                                          >
                                            <i className="fa fa-bullseye" aria-hidden="true" style={{color: "black"}}></i>
                                          </a>
                                        </acronym>
                                      </div>
                                      
                                      <a href="javascript:void(0)"> </a>
                                      </div>
                                    </td>
                                  ) : null}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {data?.length == 0 ? <Nodata /> : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

}

export default CourseOutcomes;