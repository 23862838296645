import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Toggler from "../../../Components/Toggler/Toggler";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { HOSTEL_ASSIGN } from "../../../utils/Hostel.apiConst";
import { HR_ASSIGN_WORKFLOW } from "../../../utils/apiConstants";
import { getDateMeta } from "@fullcalendar/react";
import { SessionOpt } from "../../../Data/student/sessionData";
// import Select from "react-select";
import {
  HOSTEL_STUDENT_DATA,
  HOSTEL_ROOMS_DETAILS,
  HOSTEL_FEE_YEAR,
} from "../../../utils/Hostel.apiConst";
import { Flex, Select, DatePicker } from "antd";
import { Space } from "antd";
import { UPDATE_HOSTEL_FEE_STATUS } from "../../../utils/fees.apiConst";
import Swal from "sweetalert2";
const { RangePicker } = DatePicker;

function ModalHostelAssign({
  getData,
  setLoading,
  selectedStudent,
  hostel,
  floors,
  roomTypes,
  rooms,
  setRooms,
}) {
  const getCurrentDate = (currentDate) => {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  function calculateMonthsBetween(fromDate, toDate) {
    const start = new Date(fromDate);
    const end = new Date(toDate);

    const yearsDifference = end.getFullYear() - start.getFullYear();
    const monthsDifference = end.getMonth() - start.getMonth();

    const totalMonths = yearsDifference * 12 + monthsDifference + 1;

    return totalMonths;
  }

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  function formatDateToMonthYear(datetime) {
    const date = new Date(datetime);
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
  }

  var emp_id = sessionStorage.getItem("employee_id");
  const role = sessionStorage.getItem("role");

  let empHostelId = sessionStorage.getItem("HOSTEL_ID");

  const options = [
    { label: "January - June", value: "[1,2,3,4,5,6]", mul: true },
    { label: "July - December", value: "[7,8,9,10,11,12]", mul: true },
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const [fromMonth, setFromMonth] = useState();
  const [toMonth, setToMonth] = useState();

  const [selectedMonths, setSelectedMonths] = useState([]);
  const [studentHostelData, setStudentHostelData] = useState({});
  const [studentYearData, setStudentYearData] = useState([]);
  const isSelectAllSelected = selectedMonths.length === options.length;
  const [show, setShow] = useState([]);
  const [hostelFee, setHostelFee] = useState([]);
  const [edit, setEdit] = useState(false);

  const selectAllOption = { label: "Select All", value: "all" };

  let selectedValue = isSelectAllSelected ? [...options] : selectedMonths;

  const [collegeOpt, setCollegeOpt] = useState(
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null
  );
  const [user, setUser] = useState({
    year: "",
    months: [],
    admission_date: getCurrentDate(new Date()),
    from_month: "",
    to_month: "",
  });

  const updateHostelFeeStatus = async (
    status,
    id,
    inactive_note,
    inactive_date
  ) => {
    if (!id) return toast.error("Unable to get Hostel fee");
    if (!inactive_note) return toast.error("Reason for removal is required");
    if (!inactive_date) return toast.error("Reason for removal is required");
    setLoading(1);
    const config = {
      method: "put",
      url: `${UPDATE_HOSTEL_FEE_STATUS}/${id}`,
      headers: {
        "Content-type": "application/json",
      },
      data: {
        status: status,
        inactive_by: sessionStorage.getItem("employee_id"),
        // inactive_date: getCurrentDate(new Date()),
        inactive_date: inactive_date,
        inactive_note: inactive_note,
      },
    };
    await axios(config)
      .then(async (res) => {
        setLoading(0);
        toast.success(
          `${
            status == "INACTIVE"
              ? "Student Removed from Hostel"
              : "Hostel activated "
          } succesfully`
        );
        setUser({
          year: "",
          months: [],
          amount: "",
          floor: "",
          hostel_id: "",
          room_id: "",
          room_type: "",
          bed_no: "",
          admisssion_date: getCurrentDate(new Date()),
          from_month: "",
          to_month: "",
        });
        getData("hi");
        setStudentHostelData([]);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const getRooms = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios({
      ...config,
      url: `${HOSTEL_ROOMS_DETAILS}`,
    })
      .then((res) => {
        console.log("hostel rooms - ", res.data.data);
        setRooms(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Error while fetching Hostel rooms");
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("user - ", user);
  }, [user]);

  const getStudentHostelData = async (from_month, to_month) => {
    if (!from_month || !to_month || !selectedStudent?.student_session_id) {
      return;
    }
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    //   console.log(user.from_month);
    //   console.log(user?.to_month)
    //   console.log(selectedStudent?.student_session_id);
    await axios({
      ...config,
      url: `${HOSTEL_STUDENT_DATA}?from_month=${from_month}&to_month=${to_month}&student_id=${selectedStudent?.student_session_id}`,
    })
      .then((res) => {
        console.log("student hostel year data- ", res.data.data);
        setStudentHostelData(res.data.data[0]);
        setStudentYearData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Error while fetching Student hostel data");
        console.log(err);
      });
    setLoading(0);
  };

  const getHostelFeeDataYear = async (from_month, to_month) => {
    if (!from_month || !to_month) return;
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios({
      ...config,
      url: `${HOSTEL_FEE_YEAR}?from_month=${from_month}&to_month=${to_month}`,
    })
      .then((res) => {
        console.log("hostel year data - ", res.data.data);
        setHostelFee(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Error while fetching Hostel year wise data");
        console.log(err);
      });
    setLoading(0);
  };

  const handleSubmit = async () => {
    if (!user.from_month || !user.to_month)
      return toast.error("Please Select From to Period");
    if (!user.admission_date)
      return toast.error("Please Select Admission Date");
    if (!user.hostel_id) return toast.error("Please Select Hostel");
    if (!user.amount)
      return toast.error("Please Select Room type and Food type");
    if (
      !selectedStudent?.student_session_id ||
      !selectedStudent?.department_id ||
      !selectedStudent?.session_id
    )
      return toast.error("Unable to get student Id");
    if (!user.room_id || !user.bed_no) return toast.error("Please Select Room");

    console.log("user - ", {
      ...user,
      department_id: selectedStudent?.department_id,
      student_id: selectedStudent?.student_session_id,
      session_id: selectedStudent?.session_id,
    });

    const config = {
      method: "post",
      url: `${HOSTEL_ASSIGN}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        department_id: selectedStudent?.department_id,
        student_id: selectedStudent?.student_session_id,
        session_id: selectedStudent?.session_id,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        console.log("success");
        setUser({
          year: "",
          months: [],
          amount: "",
          floor: "",
          hostel_id: "",
          room_id: "",
          room_type: "",
          bed_no: "",
          admisssion_date: getCurrentDate(new Date()),
          from_month: "",
          to_month: "",
        });
        setStudentHostelData([]);
        getData("hi");
        toast.success("Hostel Assigned");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
    setLoading(0);
  };

  const handleEdit = async () => {
    if (!user.admission_date)
      return toast.error("Please Select Admission Date");
    if (!user.hostel_id) return toast.error("Please Select Hostel");
    if (!user.amount)
      return toast.error(
        "Please Select Room type and Food type or Enter Amount"
      );
    if (!user.room_id || !user.bed_no) return toast.error("Please Select Room");
    if (!user.id) {
      return toast.error("Unable to get Hostel id");
    }

    console.log("user - ", {
      ...user,
      department_id: selectedStudent?.department_id,
      student_id: selectedStudent?.student_session_id,
      session_id: selectedStudent?.session_id,
    });

    const config = {
      method: "put",
      url: `${HOSTEL_ASSIGN}/${user?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        department_id: selectedStudent?.department_id,
        student_id: selectedStudent?.student_session_id,
        session_id: selectedStudent?.session_id,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        console.log("success");
        setUser({
          amount: "",
          floor: "",
          hostel_id: "",
          room_id: "",
          room_type: "",
          bed_no: "",
          admisssion_date: getCurrentDate(new Date()),
          from_date: "",
          to_month: "",
        });
        setEdit(false);
        setStudentHostelData([]);
        setSelectedMonths([]);
        getData("hi");
        toast.success("Edited Successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
    setLoading(0);
  };

  useEffect(() => {
    let x =
      floors?.filter((s) => s.hostelname == user?.hostel_id)[0]?.floors + 1;
    let arr = [];
    let cnt = 0;
    while (x) {
      arr.push(cnt);
      cnt++;
      x--;
    }
    setShow(arr);
  }, [user?.hostel_id]);

  // useEffect(() => {
  // let tempData = studentYearData;
  // let flag = false;
  // for(let i of tempData) {
  //     console.log('i = ', i);
  //     if (i?.months?.some(month => user?.months?.some(userMonth => userMonth == month))) {
  //         console.log('student month hostel - ', i);
  //         setStudentHostelData(i);
  //         flag = true;
  //         break;
  //     }
  //     else {
  //         console.log('student month hostel null');
  //         setStudentHostelData({});
  //         flag = true;
  //     }
  // }
  // if(!flag) {
  //     console.log('student month hostel null');
  //     setStudentHostelData({});
  // }
  // },[user?.months]);

  useEffect(() => {
    console.log("user - ", user);
  }, [user]);

  return (
    <div className="ModalHostelAssign">
      <div className="ModalHostelAssign">
        <div
          className="modal fade"
          id="ModalHostelAssign"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered mw-100 w-75"
            role="document"
          >
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Assign Hostel
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setUser({
                      year: "",
                      months: [],
                      amount: "",
                      floor: "",
                      hostel_id: "",
                      room_id: "",
                      room_type: "",
                      bed_no: "",
                      admisssion_date: getCurrentDate(new Date()),
                    });
                    setStudentHostelData([]);
                    setEdit(false);
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <br />
                <div className="row">
                  <div className="col-md-3">
                    <img
                      className="profile-user-img img-responsive rounded-circle mx-auto d-block"
                      src={`${
                        selectedStudent?.student_picture
                          ? selectedStudent?.student_picture
                          : "../../../assets/images/reports/graduated.png"
                      }
                                `}
                      width="50%"
                      style={{ aspectRatio: "1/1" }}
                    />
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6">
                        <table>
                          <tr>
                            <th>Enrollment No </th>
                            <td> : {selectedStudent?.user_id}</td>
                          </tr>
                          <tr>
                            <th>Name</th>
                            <td> : {selectedStudent?.name}</td>
                          </tr>
                          <tr>
                            <th>Gender</th>
                            <td> : {selectedStudent?.gender}</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td> : {selectedStudent?.phone}</td>
                          </tr>
                        </table>
                      </div>
                      <div className="col-md-6">
                        <table>
                          <tr>
                            <th>Session </th>
                            <td>
                              {" "}
                              : {selectedStudent?.session_id}-
                              {selectedStudent?.session_id + 1}
                            </td>
                          </tr>
                          <tr>
                            <th>Department</th>
                            <td> : {selectedStudent?.dept_name}</td>
                          </tr>
                          <tr>
                            <th>Class</th>
                            <td> : {selectedStudent?.class_name}</td>
                          </tr>
                          <tr>
                            <th>Semester</th>
                            <td> : {selectedStudent?.semester}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <br />
                <div className="row">
                  {/* <div className="col-md-6">
                        <div className="form-group">
                        <label htmlFor="class">Select Year <span style={{ color: "red" }}>*</span></label>
                        <select
                            className="form-control"
                            name="year"
                            id="year"
                            value={user.year}
                            disabled = {edit}
                            onChange={(e) => {
                                setUser({...user, year: e.target.value, months: []});
                                setSelectedMonths([]);
                                if(e.target.value) {
                                    getStudentHostelData(e.target.value);
                                    getHostelFeeDataYear(e.target.value)
                                }
                                setEdit(false);
                            }}
                        >
                            <option value="">Select Year</option>
                            {SessionOpt &&
                            SessionOpt?.map((item, key) => {
                                return (
                                <option value={item?.id}>{item?.name?.split('-')[0]}</option>
                                );
                            })}
                        </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                        <label>Select Months <span style={{ color: "red" }}>*</span></label>
                        <div className="row">
                            <Select
                                mode="multiple"
                                defaultValue={['lucy']}
                                placeholder="Select Months"
                                variant="filled"
                                style={{width:"90%"}}
                                options={[selectAllOption, ...options]}
                                value={selectedValue}
                                onChange={handleSelectChange}
                                disabled={edit || !user.year}
                            />
                        </div>
                        </div>
                    </div> */}
                  <div className="col-md-6">
                    <label>
                      Select From to To Period{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <RangePicker
                      picker="month"
                      disabled={edit}
                      onChange={(e) => {
                        if (e[0] && e[1]) {
                          console.log("from_month - ", e[0]);
                          console.log("to_month - ", e[1]);
                          setUser({
                            ...user,
                            from_month: e[0],
                            to_month: e[1],
                            amount: "",
                            food_type_amount: "",
                          });
                          getStudentHostelData(e[0], e[1]);
                          getHostelFeeDataYear(e[0], e[1]);
                        }
                      }}
                      style={{ width: "80%" }}
                    />
                  </div>

                  {studentHostelData?.id ? (
                    edit ? (
                      //options for edit
                      <>
                        {/* <div className="col-md-4">
                                <div className="form-group">
                                <label>Select Months <span style={{ color: "red" }}>*</span></label>
                                <Select
                                    isMulti
                                    options={[selectAllOption, ...options]}
                                    value={selectedValue}
                                    onChange={handleSelectChange}
                                />
                                </div>
                            </div> */}
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">Admission Date</label>
                            <input
                              type="date"
                              name="admission_date"
                              onChange={(e) => {
                                setUser({
                                  ...user,
                                  admission_date: e.target.value,
                                });
                              }}
                              className="form-control"
                              value={user?.admission_date}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Hostel{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="hostel_id"
                              id="hostel_id"
                              value={user.hostel_id}
                              onChange={(e) =>
                                setUser({ ...user, hostel_id: e.target.value })
                              }
                            >
                              <option>Select Hostel</option>
                              {/* {role == "WARDEN" ?
                                    hostel?.filter((s) => s.id == empHostelId)?.map((i, key) => (
                                        <option value={i.id} key={key}>
                                        {i.hostel_name}
                                        </option>
                                    )): 
                                    hostel?.map((i, key) => (
                                        <option value={i.id} key={key}>
                                        {i.hostel_name}
                                        </option>
                                    ))} */}
                              {hostel?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.hostel_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Floor{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="floor"
                              id="floor"
                              value={
                                user.floor
                                  ? user.floor
                                  : rooms?.find((s) => s.id == user.room_id)
                                      ?.floor_number
                              }
                              onChange={(e) =>
                                setUser({ ...user, floor: e.target.value })
                              }
                            >
                              <option>Select Floor</option>
                              {show &&
                                show?.map((item, key) => (
                                  <option value={item}>{item}</option>
                                ))}
                              {/* {floors &&
                                    floors?.filter((s) => s.hostelname == user.hostel_id)?.map((item, key) => {
                                        return (
                                        <option value={item?.id}>{item?.floors}</option>
                                        );
                                    })} */}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Room Type{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="room_type"
                              id="room_type"
                              type="text"
                              value={
                                user.room_type
                                  ? user.room_type
                                  : roomTypes?.find(
                                      (f) =>
                                        f.id ==
                                        rooms.find((s) => s.id == user.room_id)
                                          ?.hostel_room_type_id
                                    )?.room_type
                              }
                              onChange={(e) =>
                                setUser({ ...user, room_type: e.target.value })
                              }
                            >
                              {console.log(
                                user.room_type
                                  ? user.room_type
                                  : roomTypes?.find(
                                      (f) =>
                                        f.id ==
                                        rooms.find((s) => s.id == user.room_id)
                                          ?.hostel_room_type_id
                                    )?.room_type
                              )}
                              <option value="">Select Room type</option>
                              {roomTypes &&
                                roomTypes
                                  ?.filter((s) => s.hostel_id == user.hostel_id)
                                  ?.map((item, key) => {
                                    return (
                                      <option value={item?.id}>
                                        {item?.room_type}
                                      </option>
                                    );
                                  })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Food type{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="amount"
                              id="amount"
                              value={user?.food_type_amount}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  food_type_amount: e.target.value,
                                  amount:
                                    (e.target.value / 12) *
                                      calculateMonthsBetween.toFixed(0) || 0,
                                })
                              }
                            >
                              <option value="">Select Food type</option>
                              {roomTypes?.find((s) => s.id == user.room_type)
                                ?.with_food ? (
                                <option
                                  value={
                                    roomTypes?.find(
                                      (s) => s.id == user.room_type
                                    )?.with_food
                                  }
                                >
                                  Food (₹
                                  {
                                    roomTypes?.find(
                                      (s) => s.id == user.room_type
                                    )?.with_food
                                  }
                                  )
                                </option>
                              ) : (
                                ""
                              )}
                              {roomTypes?.find((s) => s.id == user.room_type)
                                ?.without_food ? (
                                <option
                                  value={
                                    roomTypes?.find(
                                      (s) => s.id == user.room_type
                                    )?.without_food
                                  }
                                >
                                  Without Food (₹
                                  {
                                    roomTypes?.find(
                                      (s) => s.id == user.room_type
                                    )?.without_food
                                  }
                                  )
                                </option>
                              ) : (
                                ""
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Bed <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="room"
                              id="room"
                              value={user.room_id}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  room_id: e.target.value,
                                  bed_no: rooms.find(
                                    (s) => s.id == e.target.value
                                  )?.hostel_no_bed,
                                })
                              }
                            >
                              <option>Select Bed</option>
                              {rooms &&
                                rooms
                                  ?.filter(
                                    (s) =>
                                      s.id == studentHostelData?.room_id ||
                                      (s.floor_number == user.floor &&
                                        s.hostel_room_type_id == user.room_type)
                                  )
                                  ?.filter(
                                    (item) =>
                                      item.id == studentHostelData?.room_id ||
                                      !hostelFee?.some(
                                        (data) =>
                                          data.bed_no === item.hostel_no_bed &&
                                          data.room_id == item.id
                                      )
                                  )
                                  ?.map((item, key) => {
                                    return (
                                      <option value={item?.id}>
                                        Bed {item?.hostel_no_bed} (Room{" "}
                                        {item?.room_name_number})
                                      </option>
                                    );
                                  })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">Amount </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Amount"
                              value={user.amount}
                              onChange={(e) => {
                                setUser({ ...user, amount: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      //Display hostel assigned to student for particular year
                      <div className="col-md-12 mt-4">
                        <p className="alert alert-danger text-center ml-5 mr-5">
                          <b>
                            Bed Already Assigned from :{" "}
                            {formatDateToMonthYear(
                              studentHostelData?.from_month
                            )}{" "}
                            to{" "}
                            {formatDateToMonthYear(studentHostelData?.to_month)}{" "}
                          </b>
                        </p>
                        <div className="row">
                          <div className="col-md-3"></div>
                          <div className="col-md-9">
                            <div className="row">
                              <div className="col-md-6">
                                <table>
                                  <tr>
                                    <th>Hostel</th>
                                    <td>
                                      {" "}
                                      :{" "}
                                      {
                                        hostel.find(
                                          (s) =>
                                            s.id == studentHostelData?.hostel_id
                                        )?.hostel_name
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Floor</th>
                                    <td>
                                      {" "}
                                      :{" "}
                                      {
                                        rooms.find(
                                          (s) =>
                                            s.id == studentHostelData?.room_id
                                        )?.floor_number
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Room Type</th>
                                    <td>
                                      {" "}
                                      :{" "}
                                      {
                                        roomTypes?.find(
                                          (s) =>
                                            s.id ==
                                            rooms.find(
                                              (s) =>
                                                s.id ==
                                                studentHostelData?.room_id
                                            )?.hostel_room_type_id
                                        )?.room_type
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Room number</th>
                                    <td>
                                      {" "}
                                      :{" "}
                                      {
                                        rooms.find(
                                          (s) =>
                                            s.id == studentHostelData?.room_id
                                        )?.room_name_number
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Bed number</th>
                                    <td> : {studentHostelData?.bed_no}</td>
                                  </tr>
                                </table>
                              </div>
                              <div className="col-md-6">
                                <table>
                                  <tr>
                                    <th>Admission Date</th>
                                    <td>
                                      {" "}
                                      :{" "}
                                      {studentHostelData?.admission_date
                                        ? formatDate(
                                            studentHostelData?.admission_date?.split(
                                              "T"
                                            )[0]
                                          )
                                        : "Not Added"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Amount</th>
                                    <td>
                                      {" "}
                                      :{" "}
                                      {studentHostelData?.amount?.toLocaleString(
                                        "en-IN",
                                        {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Paid amount</th>
                                    <td>
                                      {" "}
                                      :{" "}
                                      {studentHostelData?.paid_amount?.toLocaleString(
                                        "en-IN",
                                        {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Balance</th>
                                    <td>
                                      {" "}
                                      :{" "}
                                      {(
                                        studentHostelData?.amount -
                                        studentHostelData?.paid_amount
                                      )?.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Fee Status</th>
                                    <td>
                                      {" "}
                                      :
                                      <span
                                        className={`ml-1 badge badge-soft-${
                                          studentHostelData?.amount -
                                            studentHostelData?.paid_amount ==
                                          0
                                            ? "success"
                                            : studentHostelData?.amount -
                                                studentHostelData?.paid_amount ==
                                              studentHostelData?.amount
                                            ? "danger"
                                            : "warning"
                                        }`}
                                      >
                                        {studentHostelData?.amount -
                                          studentHostelData?.paid_amount ==
                                        0
                                          ? "Paid"
                                          : studentHostelData?.amount -
                                              studentHostelData?.paid_amount ==
                                            studentHostelData?.amount
                                          ? "Not Paid"
                                          : "Partial"}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    // Dropdown for assigning new hostel
                    user?.from_month &&
                    user?.to_month && (
                      <>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">Admission Date</label>
                            <input
                              type="date"
                              name="admission_date"
                              onChange={(e) => {
                                setUser({
                                  ...user,
                                  admission_date: e.target.value,
                                });
                              }}
                              className="form-control"
                              value={user?.admission_date}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Hostel{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="hostel_id"
                              id="hostel_id"
                              value={user.hostel_id}
                              onChange={(e) =>
                                setUser({ ...user, hostel_id: e.target.value })
                              }
                            >
                              <option>Select Hostel</option>
                              {/* {role == "WARDEN"
                                ? hostel
                                    ?.filter((s) => s.id == empHostelId)
                                    ?.map((i, key) => (
                                      <option value={i.id} key={key}>
                                        {i.hostel_name}
                                      </option>
                                    ))
                                : hostel?.map((i, key) => (
                                    <option value={i.id} key={key}>
                                      {i.hostel_name}
                                    </option>
                                  ))} */}
                              {hostel?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.hostel_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Floor{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="floor"
                              id="floor"
                              value={user.floor}
                              onChange={(e) =>
                                setUser({ ...user, floor: e.target.value })
                              }
                            >
                              <option>Select Floor</option>
                              {show &&
                                show?.map((item, key) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Room Type{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="room_type"
                              id="room_type"
                              value={user.room_type}
                              onChange={(e) =>
                                setUser({ ...user, room_type: e.target.value })
                              }
                            >
                              <option>Select Room type</option>
                              {roomTypes &&
                                roomTypes
                                  ?.filter((s) => s.hostel_id == user.hostel_id)
                                  ?.map((item, key) => {
                                    return (
                                      <option value={item?.id}>
                                        {item?.room_type}
                                      </option>
                                    );
                                  })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Food type{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="amount"
                              id="amount"
                              value={user?.food_type_amount}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  food_type_amount: e.target.value,
                                  amount:
                                    (
                                      (e.target.value / 12) *
                                      calculateMonthsBetween(
                                        user?.from_month,
                                        user?.to_month
                                      )
                                    ).toFixed(0) || 0,
                                })
                              }
                            >
                              <option>Select Food type</option>
                              {roomTypes?.find((s) => s.id == user.room_type)
                                ?.with_food ? (
                                <option
                                  value={
                                    roomTypes?.find(
                                      (s) => s.id == user.room_type
                                    )?.with_food
                                  }
                                >
                                  Food (₹
                                  {
                                    roomTypes?.find(
                                      (s) => s.id == user.room_type
                                    )?.with_food
                                  }
                                  )
                                </option>
                              ) : (
                                ""
                              )}
                              {roomTypes?.find((s) => s.id == user.room_type)
                                ?.with_food ? (
                                <option
                                  value={
                                    roomTypes?.find(
                                      (s) => s.id == user.room_type
                                    )?.without_food
                                  }
                                >
                                  Without Food (₹
                                  {
                                    roomTypes?.find(
                                      (s) => s.id == user.room_type
                                    )?.without_food
                                  }
                                  )
                                </option>
                              ) : (
                                ""
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="class">
                              Select Bed <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="room"
                              id="room"
                              value={user.room_id}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  room_id: e.target.value,
                                  bed_no: rooms.find(
                                    (s) => s.id == e.target.value
                                  )?.hostel_no_bed,
                                })
                              }
                            >
                              <option>Select Bed</option>
                              {rooms &&
                                rooms
                                  ?.filter(
                                    (s) =>
                                      s.floor_number == user.floor &&
                                      s.hostel_room_type_id == user.room_type
                                  )
                                  ?.filter(
                                    (item) =>
                                      !hostelFee?.some(
                                        (data) =>
                                          data.bed_no === item.hostel_no_bed &&
                                          data.room_id == item.id
                                      )
                                  )
                                  ?.map((item, key) => {
                                    return (
                                      <option value={item?.id}>
                                        Bed {item?.hostel_no_bed} (Room{" "}
                                        {item?.room_name_number})
                                      </option>
                                    );
                                  })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="class">
                              Amount <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Amount"
                              value={user.amount}
                              onChange={(e) => {
                                setUser({ ...user, amount: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
              <div className="row px-2">
                <button
                  className="btn btn-info btn-rounded btn-outline ml-3 mr-3"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setUser({
                      from_month: "",
                      to_month: "",
                      amount: "",
                      floor: "",
                      hostel_id: "",
                      room_id: "",
                      room_type: "",
                      bed_no: "",
                      admission_date: new Date().toLocaleDateString(),
                    });
                    setStudentHostelData([]);
                    setSelectedMonths([]);
                    setEdit(false);
                  }}
                >
                  Close
                </button>
                {studentHostelData?.id ? (
                  edit ? (
                    <button
                      className="btn btn-success btn-outline mr-3"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setEdit(true);
                        handleEdit();
                      }}
                    >
                      Save Changes
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn btn-danger float-right mr-3"
                        title="Inactive Hostel"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() =>
                          Swal.fire({
                            title: "Remove Student from Hostel",
                            showCancelButton: true,
                            confirmButtonColor: "green",
                            cancelButtonColor: "red",
                            confirmButtonText: "Remove",
                            html: `
                                <label>Enter Reason</label><br />
                                <textarea type="text" id="inactive_note"></textarea>
                                <br/>
                                <br />
                                <label htmlFor="">Removal Date</label>
                                <br />
                                <input
                                    type="date"
                                    id="inactive_date"
                                    className="form-control"
                                />
                                `,
                            preConfirm: async () => {
                              const selectElement =
                                document.getElementById("inactive_note");
                              const selectDate =
                                document.getElementById("inactive_date");
                              const inactive_note1 = selectElement
                                ? selectElement.value
                                : "";
                              const inactive_date1 = selectElement
                                ? selectDate.value
                                : "";

                              // console.log(inactive_date1);

                              if (!inactive_note1) {
                                Swal.showValidationMessage(
                                  "Please Enter Reason for Removal"
                                );
                              }
                              if (!inactive_date1) {
                                Swal.showValidationMessage(
                                  "Please Enter Date of Removal"
                                );
                              }
                              return { inactive_note1, inactive_date1 };
                            },
                          }).then((inactive) => {
                            console.log(inactive);
                            if (inactive?.isConfirmed) {
                              let inactive_note =
                                inactive?.value?.inactive_note1;
                              let inactive_date =
                                inactive?.value?.inactive_date1;
                              updateHostelFeeStatus(
                                "INACTIVE",
                                studentHostelData?.id,
                                inactive_note,
                                inactive_date
                              );
                            }
                          })
                        }
                      >
                        Remove
                      </button>
                      <button
                        className="btn btn-primary btn-outline mr-3"
                        onClick={() => {
                          setEdit(true);
                          setUser({
                            id: studentHostelData?.id,
                            from_month: studentHostelData?.from_month,
                            from_month: studentHostelData?.to_month,
                            hostel_id: studentHostelData?.hostel_id,
                            year: studentHostelData?.year,
                            bed_no: studentHostelData?.bed_no,
                            room_id: parseInt(studentHostelData?.room_id),
                            amount: studentHostelData?.amount,
                            food_type_amount:
                              studentHostelData?.food_type_amount,
                            room_type: rooms.find(
                              (s) => s.id == studentHostelData?.room_id
                            )?.hostel_room_type_id,
                            floor: rooms?.find(
                              (s) => s.id == studentHostelData?.room_id
                            )?.floor_number,
                            admission_date: studentHostelData?.admission_date
                              ? getCurrentDate(
                                  new Date(studentHostelData?.admission_date)
                                )
                              : getCurrentDate(new Date()),
                          });
                          console.log("edit user - ", {
                            id: studentHostelData?.id,
                            from_month: studentHostelData?.from_month,
                            from_month: studentHostelData?.to_month,
                            hostel_id: studentHostelData?.hostel_id,
                            year: studentHostelData?.year,
                            bed_no: studentHostelData?.bed_no,
                            room_id: parseInt(studentHostelData?.room_id),
                            amount: studentHostelData?.amount,
                            food_type_amount:
                              studentHostelData?.food_type_amount,
                            room_type: rooms.find(
                              (s) => s.id == studentHostelData?.room_id
                            )?.hostel_room_type_id,
                            floor: rooms?.find(
                              (s) => s.id == studentHostelData?.room_id
                            )?.floor_number,
                            admission_date: studentHostelData?.admission_date
                              ? getCurrentDate(
                                  new Date(studentHostelData?.admission_date)
                                )
                              : getCurrentDate(new Date()),
                          });
                        }}
                      >
                        Edit
                      </button>
                    </>
                  )
                ) : (
                  <button
                    className="btn btn-success btn-outline float-right"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      handleSubmit();
                      setEdit(false);
                    }}
                  >
                    Assign
                  </button>
                )}
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalHostelAssign;
