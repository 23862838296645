import React from "react";
import { useState } from "react";
import Stepper from "react-stepper-horizontal";

const ProgressBar = ({ value, basic_percent, fee_status, form_percent }) => {
  if (fee_status == "PAID") {
    value++;
  }

  if (form_percent > 75) {
    value++;
  }
  const steps = [
    { title: "Personal Information" },
    { title: "Application Fee" },
    { title: "Educational Details" },
    { title: "Documents" },
  ];
  return (
    <div class="container">
      <Stepper
        steps={steps}
        activeColor="#dc3545"
        completeColor="#dc3545"
        activeStep={value}
      />
      <div
        className="row d-flex"
        style={{ width: "80rem", marginLeft: "6rem" }}
      >
        {steps?.map((item, key) => {
          return (
            <div
              className="col-md-3"
              style={
                key == 0
                  ? { paddingLeft: "2.3rem" }
                  : key == 1
                  ? { paddingLeft: ".7rem" }
                  : null
              }
            >
              {key == 0 ? (
                <p>{basic_percent + " %"}</p>
              ) : key == 2 ? (
                <p>{form_percent + " %"}</p>
              ) : key == 1 ? (
                <p>{fee_status}</p>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
