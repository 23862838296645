import React from "react";
import { useState } from "react";
import NoData from "./../../../Components/NoData/Nodata";
import axios from "axios";
import { toast } from "react-toastify";
import Nodata from "./../../../Components/NoData/Nodata";
import { FEE_DETAILS } from "../../../utils/fees.apiConst";
import { useEffect } from "react";
import { ROUTES } from "../../../Router/routerConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
} from "./../../../utils/Academics.apiConst";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
import * as XLSX from "xlsx/xlsx.mjs";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import Select from "react-select";

function ReportsPendingClasswise({ setLoading, collegeId }) {
  const navigate = useNavigate();

  //Query data for easy filtering
  const location = useLocation();
  const query_department = new URLSearchParams(location.search).get(
    "department_id"
  );
  const query_session = new URLSearchParams(location.search).get("session_id");
  const query_class = new URLSearchParams(location.search).get("class_id");

  const [data, setData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [semData, setSemData] = useState([]);

  const tableRef = useRef();

  const getDepartmentOpt = () => {
    return localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;
  };
  const getProgramOpt = () => {
    return localStorage.getItem(LOCAL_PROGRAM)
      ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      : null;
  };
  const getCollegeOpt = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const departmentOpt = getDepartmentOpt().filter(
    (s) => s.college_id == collegeId
  );
  const programOpt = getProgramOpt();
  const collegeOpt = getCollegeOpt();

  const [user, setUser] = useState({
    session_id: query_session ? query_session : "",
    class_id: query_class ? query_class : "",
    section_id: "",
    department_id: query_department ? query_department : "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = async () => {
    if (!user.session_id) return toast.error("Session is required");
    if (!user.department_id) return toast.error("Department is required");
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data2, data3] = await Promise.all([
      axios({
        ...config,
        url: `${FEE_DETAILS}?college_id=${collegeId}&department_id=${user.department_id}&session_id=${user.session_id}`,
      })
        .then((res) => {
          console.log("This is Data", res.data.data);
          return res.data.data;
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        }),
      axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}&department_id=${user.department_id}`,
      })
        .then((res) => {
          console.log(res);
          return res.data.data;
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        }),
      axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}&department_id=${user.department_id}`,
      })
        .then((res) => {
          console.log(res);
          return res.data.data;
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        }),
    ]);

    setLoading(0);

    setData(data1);
    setClassData(data2);
    setSemData(data3);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);

  const handleSessionChange = (selectedOption) => {
    setSelectedSession(selectedOption);
    handleChange({
      target: {
        name: "session_id",
        value: selectedOption ? selectedOption.value : "",
      },
    });
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    handleChange({
      target: {
        name: "department_id",
        value: selectedOption ? selectedOption.value : "",
      },
    });
  };

  return (
    <div className="FeeCollectionReports">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">Department Wise Pending Fee Report</h4>
                </div>
              </div>
            </div>
            {/* start page title */}
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select criteria</h2>
                    <br />
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Session <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <select
                            name="session_id"
                            id="fee_grp"
                            className="form-control"
                            value={user.session_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Session</option>
                            {sessionOpt &&
                              sessionOpt.map((data, key) => {
                                return (
                                  <option value={data?.id}>{data.name}</option>
                                );
                              })}
                          </select> */}

                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="session_id"
                            id="fee_grp"
                            value={selectedSession}
                            onChange={handleSessionChange}
                            options={sessionOpt.map((data) => ({
                              value: data.id,
                              label: data.name,
                            }))}
                          />

                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="">
                            Department<span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <select
                            value={user?.department_id}
                            name="department_id"
                            onChange={handleChange}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {departmentOpt?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name},{" "}
                                {
                                  programOpt?.find((s) => s.id == i.program_id)
                                    ?.name
                                }
                              </option>
                            ))}
                          </select> */}

                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="department_id"
                            id="class"
                            value={selectedDepartment}
                            onChange={handleDepartmentChange}
                            options={departmentOpt
                              ?.filter((s) => s?.college_id == collegeId)
                              ?.map((i) => ({
                                value: i?.id,
                                label: `${i?.name}, ${programOpt?.find((p) => p?.id == i?.program_id)?.name}`,
                              }))}
                          />

                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getData}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            {/* container-fluid */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">Students Due Fee Details</h4>
                      </div>
                      <div className="col-md-8 ">
                        <span className="float-right">
                          <button
                            className="btn btn-primary rounded-pill"
                            onClick={() => onDownload()}
                          >
                            Export{" "}
                            <i
                              className="fa fa-file-excel-o"
                              aria-hidden="true"
                            />
                          </button>
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div className="table-responsive">
                      <div
                        id="datatable_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              id="datatable"
                              className="table table-bordered dt-responsive table-hover no-wrap dataTable no-footer dtr-inline"
                              style={{ width: "100%" }}
                              role="grid"
                              aria-describedby="datatable_info"
                              ref={tableRef}
                            >
                              <thead>
                                <tr>
                                  <th colSpan={13}>
                                    Faculty :{" "}
                                    {
                                      collegeOpt?.find((s) => s.id == collegeId)
                                        ?.name
                                    }
                                  </th>
                                </tr>
                                <tr>
                                  <th colSpan={13}>
                                    Department :{" "}
                                    {
                                      departmentOpt?.find(
                                        (s) => s.id == user?.department_id
                                      )?.name
                                    }
                                  </th>
                                </tr>
                                <tr>
                                  <th colSpan={13}>
                                    Academic Year :{" "}
                                    {
                                      sessionOpt?.find(
                                        (s) => s.id == user?.session_id
                                      )?.name
                                    }
                                  </th>
                                </tr>
                                <tr>
                                  <td colSpan={3}></td>
                                  <td
                                    colSpan={7}
                                    style={{ textAlign: "center" }}
                                  >
                                    <h5>College Fees</h5>
                                  </td>
                                  <td
                                    colSpan={3}
                                    style={{ textAlign: "center" }}
                                  >
                                    <h5>Hostel Fees</h5>
                                  </td>
                                </tr>
                                <tr role="row">
                                  <th>Sl. No</th>
                                  <th>Addmision Number</th>
                                  <th>Student Name</th>
                                  <th>Category</th>
                                  <th>Due Date</th>
                                  <th>Amount</th>
                                  <th>Deposit</th>
                                  <th>Discounts</th>
                                  <th>Fine</th>
                                  <th>Balance</th>
                                  <th>Amount</th>
                                  <th>Deposit</th>
                                  <th>Balance</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data &&
                                  data?.length != 0 &&
                                  semData.length != 0 &&
                                  classData.length != 0 ? (
                                  classData?.map((s, key) => (
                                    <>
                                      {semData
                                        ?.filter((sem) => sem?.class_id == s.id)
                                        ?.map((sem, key) => (
                                          <>
                                            <tr className="bg-dark text-light">
                                              <td colSpan={5}>
                                                {s?.name} - {sem?.name}
                                              </td>
                                              <td>
                                                ₹{" "}
                                                {data
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.student
                                                        ?.semester_id == sem.id
                                                  )
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.feeData?.amount -
                                                      fee?.feeData
                                                        ?.paid_amount -
                                                      fee?.feeData
                                                        ?.discount_amount !=
                                                      0
                                                  )
                                                  ?.reduce(
                                                    (acc, current) =>
                                                      acc +
                                                      Number(
                                                        current?.feeData?.amount
                                                      ),
                                                    0
                                                  )}
                                              </td>
                                              <td>
                                                ₹{" "}
                                                {data
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.student
                                                        ?.semester_id == sem.id
                                                  )
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.feeData?.amount -
                                                      fee?.feeData
                                                        ?.paid_amount -
                                                      fee?.feeData
                                                        ?.discount_amount !=
                                                      0
                                                  )
                                                  ?.reduce(
                                                    (acc, current) =>
                                                      acc +
                                                      Number(
                                                        current?.feeData
                                                          ?.paid_amount
                                                      ),
                                                    0
                                                  )}
                                              </td>
                                              <td>
                                                ₹{" "}
                                                {data
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.student
                                                        ?.semester_id == sem.id
                                                  )
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.feeData?.amount -
                                                      fee?.feeData
                                                        ?.paid_amount -
                                                      fee?.feeData
                                                        ?.discount_amount !=
                                                      0
                                                  )
                                                  ?.reduce(
                                                    (acc, current) =>
                                                      acc +
                                                      Number(
                                                        current?.feeData
                                                          ?.discount_amount
                                                      ),
                                                    0
                                                  )}
                                              </td>
                                              <td>₹ 0</td>
                                              <th>
                                                ₹{" "}
                                                {data
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.student
                                                        ?.semester_id == sem.id
                                                  )
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.feeData?.amount -
                                                      fee?.feeData
                                                        ?.paid_amount -
                                                      fee?.feeData
                                                        ?.discount_amount !=
                                                      0
                                                  )
                                                  ?.reduce(
                                                    (acc, current) =>
                                                      acc +
                                                      Number(
                                                        current?.feeData
                                                          ?.amount -
                                                        current?.feeData
                                                          ?.paid_amount -
                                                        current?.feeData
                                                          ?.discount_amount
                                                      ),
                                                    0
                                                  )}
                                              </th>
                                              <td>
                                                ₹{" "}
                                                {data
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.student
                                                        ?.semester_id == sem.id
                                                  )
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.feeData?.hostelFees -
                                                      fee?.feeData
                                                        ?.paidHostelfees !=
                                                      0
                                                  )
                                                  ?.reduce(
                                                    (acc, current) =>
                                                      acc +
                                                      Number(
                                                        current?.feeData
                                                          ?.hostelFees
                                                      ),
                                                    0
                                                  )}
                                              </td>
                                              <td>
                                                ₹{" "}
                                                {data
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.student
                                                        ?.semester_id == sem.id
                                                  )
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.feeData?.hostelFees -
                                                      fee?.feeData
                                                        ?.paidHostelfees !=
                                                      0
                                                  )
                                                  ?.reduce(
                                                    (acc, current) =>
                                                      acc +
                                                      Number(
                                                        current?.feeData
                                                          ?.paidHostelfees
                                                      ),
                                                    0
                                                  )}
                                              </td>
                                              <td>
                                                ₹{" "}
                                                {data
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.student
                                                        ?.semester_id == sem.id
                                                  )
                                                  ?.filter(
                                                    (fee) =>
                                                      fee?.feeData?.hostelFees -
                                                      fee?.feeData
                                                        ?.paidHostelfees !=
                                                      0
                                                  )
                                                  ?.reduce(
                                                    (acc, current) =>
                                                      acc +
                                                      Number(
                                                        current?.feeData
                                                          ?.hostelFeebalance
                                                      ),
                                                    0
                                                  )}
                                              </td>
                                            </tr>
                                            {data
                                              ?.filter(
                                                (fee) =>
                                                  fee?.student?.semester_id ==
                                                  sem.id
                                              )
                                              ?.filter(
                                                (fee) =>
                                                  fee?.feeData?.amount -
                                                  fee?.feeData?.paid_amount -
                                                  fee?.feeData
                                                    ?.discount_amount !=
                                                  0 ||
                                                  fee?.feeData?.hostelFees -
                                                  fee?.feeData
                                                    ?.paidHostelfees !=
                                                  0
                                              )
                                              ?.map((i, key) => (
                                                <tr key={key}>
                                                  <td>{key + 1}</td>
                                                  <td>{i?.student?.id}</td>
                                                  <td>{i?.student?.name}</td>
                                                  <td>{i?.student?.category ? i?.student?.category : "Not Assigned"}</td>
                                                  {/* <td>{i?.student?.dob?.split("T")[0]}</td> */}
                                                  <td>
                                                    {
                                                      i?.feeData?.due_date?.split(
                                                        "T"
                                                      )[0]
                                                    }
                                                  </td>
                                                  <td>
                                                    ₹ {i?.feeData?.amount}
                                                  </td>
                                                  <td>
                                                    ₹ {i?.feeData?.paid_amount}
                                                  </td>
                                                  <td>
                                                    ₹{" "}
                                                    {
                                                      i?.feeData
                                                        ?.discount_amount
                                                    }
                                                  </td>
                                                  <td>₹ 0</td>
                                                  <td>
                                                    ₹{" "}
                                                    {i?.feeData?.amount -
                                                      i?.feeData?.paid_amount -
                                                      i?.feeData
                                                        ?.discount_amount}
                                                  </td>
                                                  <td>
                                                    ₹ {i?.feeData?.hostelFees}
                                                  </td>
                                                  <td>
                                                    ₹{" "}
                                                    {i?.feeData?.paidHostelfees}
                                                  </td>
                                                  <td>
                                                    ₹{" "}
                                                    {i?.feeData?.hostelFees -
                                                      i?.feeData
                                                        ?.paidHostelfees}
                                                  </td>
                                                </tr>
                                              ))}
                                          </>
                                        ))}
                                    </>
                                  ))
                                ) : (
                                  <tr className="odd">
                                    <td
                                      valign="top"
                                      colSpan={14}
                                      className="dataTables_empty"
                                    >
                                      <Nodata />
                                    </td>
                                  </tr>
                                )}

                                <tr>
                                  <th colSpan={5} className="text-center">
                                    Total
                                  </th>
                                  <td>
                                    ₹{" "}
                                    {data?.reduce(
                                      (acc, current) =>
                                        acc + Number(current?.feeData?.amount),
                                      0
                                    )}
                                  </td>
                                  <td>
                                    ₹{" "}
                                    {data?.reduce(
                                      (acc, current) =>
                                        acc +
                                        Number(current?.feeData?.paid_amount),
                                      0
                                    )}
                                  </td>
                                  <td>
                                    ₹{" "}
                                    {data?.reduce(
                                      (acc, current) =>
                                        acc +
                                        Number(
                                          current?.feeData?.discount_amount
                                        ),
                                      0
                                    )}
                                  </td>
                                  <td>₹ 0</td>
                                  <th>
                                    ₹{" "}
                                    {data?.reduce(
                                      (acc, current) =>
                                        acc +
                                        Number(
                                          current?.feeData?.amount -
                                          current?.feeData?.paid_amount -
                                          current?.feeData?.discount_amount
                                        ),
                                      0
                                    )}
                                  </th>
                                  <th>
                                    {data?.reduce(
                                      (acc, current) =>
                                        acc +
                                        Number(current?.feeData?.hostelFees),
                                      0
                                    )}
                                  </th>
                                  <th>
                                    {data?.reduce(
                                      (acc, current) =>
                                        acc +
                                        Number(
                                          current?.feeData?.paidHostelfees
                                        ),
                                      0
                                    )}
                                  </th>
                                  <th>
                                    {data?.reduce(
                                      (acc, current) =>
                                        acc +
                                        Number(
                                          current?.feeData?.hostelFeebalance
                                        ),
                                      0
                                    )}
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* end col */}
            </div>{" "}
            {/* end row */}
          </div>
          {/* End Page-content */}
        </div>
        {/* end main content*/}
      </div>
    </div>
  );
}

export default ReportsPendingClasswise;
