import React from "react";
import "./Profile.scss";

const AdmissionProfile = () => {
  return (
    <div className="row Profile" style={{ maxWidth: "1200px" }}>
      <div className="col-12 row">
        <div className="col-md-4">
          <div className="box box-primary">
            <div className="box-body box-profile">
              <ul className="list-group list-group-unbordered">
                {/* <img
                                                            className="profile-user-img img-responsive rounded-circle mx-auto d-block"
                                                            src=""
                                                            style={{
                                                                width: 100,
                                                                height: 100,
                                                                borderRadius: "50%",
                                                                alignSelf: "center"
                                                            }}
                                                        /> */}
                <img
                  className="profile-user-img img-responsive rounded-circle mx-auto d-block"
                  src="assets/images/Nexenstial Logo.jpg"
                  width="50%"
                />
                <br />
                <h3 className="profile-username text-center" />
                <li className="list-group-item listnoback">
                  <b>Disable Reason :</b>{" "}
                  <a className="float-right text-aqua">
                    {/*?= $std_res['dis_reason']?*/}
                  </a>
                </li>
                <li className="list-group-item listnoback">
                  <b>Disable Note :</b>{" "}
                  <a className="float-right text-aqua">
                    {/*?= $std_res['dis_note']?*/}
                  </a>
                </li>
                <li className="list-group-item listnoback">
                  <b>Disable Date :</b>{" "}
                  <a className="float-right text-aqua">
                    {/*?= $std_res['disable_at']?*/}{" "}
                  </a>
                </li>
                {/*?php } ?*/}
                <li className="list-group-item listnoback">
                  <b>Admission No :</b>{" "}
                  <a className="float-right text-aqua">
                    {/*?= $std_res['admission_no']?*/}
                  </a>
                </li>
                <li className="list-group-item listnoback">
                  <b>Roll Number :</b>{" "}
                  <a className="float-right text-aqua">
                    {/*?= $std_res['roll_no']?*/}
                  </a>
                </li>
                <li className="list-group-item listnoback">
                  <b>Class :</b>{" "}
                  <a className="float-right text-aqua">
                    {/*?= $std_res['class_id']?*/}{" "}
                  </a>
                </li>
                <li className="list-group-item listnoback">
                  <b>Section :</b>{" "}
                  <a className="float-right text-aqua">
                    {/*?= $std_res['section_id']?*/}
                  </a>
                </li>
                <li className="list-group-item listnoback">
                  <b>RTE :</b> <a className="float-right text-aqua" />
                </li>
                <li className="list-group-item listnoback">
                  <b>Gender :</b>{" "}
                  <a className="float-right text-aqua">
                    {/*?= $std_res['gender']?*/}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmissionProfile;
