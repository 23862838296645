import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
// import { LOCAL_COLLEGE, LOCAL_DEPARTMENT } from '../../../utils/LocalStorageConstants';
// import { EMPLOYEE_ALL, LEAVE_APPLICATION, LEAVE_ENTITLEMENT } from '../../../utils/apiConstants';
import Select from "react-select";
import { LOCAL_COLLEGE, LOCAL_DEPARTMENT } from '../../../../utils/LocalStorageConstants';
import { EMPLOYEE_ALL, LEAVE_APPLICATION, LEAVE_ENTITLEMENT } from '../../../../utils/apiConstants';

function StaffAttendanceReport({ setLoading, collegeId }) {

  const date = new Date().toISOString().split("T")[0];

    const getCollegeData = () => {
        return localStorage.getItem(LOCAL_COLLEGE)
          ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
          : null;
      };
    
    const [collegeOpt, setCollegeOpt] = useState(getCollegeData());    
    const [faculty1, setFaculty1] = useState("");
    const [selectedFaculty, setSelectedFaculty] = useState("");

    const [fromDate, setFromDate] = useState(date);
    const [toDate, setToDate] = useState(date);

    const [department, setDepartment] = useState(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
    );

    const [user, setUser] = useState({
      date: date,
      to_transaction_date: date,
    });

    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser((prev) => ({
          ...prev,
          [name]: value,
        }));
      };

    // const getData = async () => {

    //   // Adjust toDate to include data for the selected toDate
    //   const adjustedToDate = new Date(new Date(toDate).getTime() + 24 * 60 * 60 * 1000);
    //   const formattedAdjustedToDate = adjustedToDate.toISOString().split("T")[0];

    //     setLoading(1);
    //     const config = {
    //       method: "get",
    //       url: LEAVE_APPLICATION,
    //       params: {
    //         college_id: selectedFaculty,
    //         fromDate: fromDate,
    //         toDate: formattedAdjustedToDate,
    //       },
    //       //timeout: 50000, // Set a timeout in milliseconds (adjust as needed)
    //       headers: {
    //         Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
    //         "Content-Type": "application/json",
    //       },
    //     };
    
    //     await axios(config)
    //       .then((res) => {
    //         console.log("Selected Faculty:", selectedFaculty);
    //         console.log("From Date:", fromDate);
    //         console.log("To Date:", formattedAdjustedToDate);
    //         res.data.data.sort((a, b) => b.id - a.id);
    //         setData(res.data.data);
    //         console.log("FilteredData:",res.data.data);

    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //     setLoading(0);
    // };

    const getData = async () => {
      setLoading(1);
      
      // Check if the selected faculty is 'All'
      const isAllSelected = selectedFaculty === '';
    
      // Adjust toDate to include data for the selected toDate
      const adjustedToDate = new Date(new Date(toDate).getTime() + 24 * 60 * 60 * 1000);
      const formattedAdjustedToDate = adjustedToDate.toISOString().split("T")[0];
    
      const config = {
        method: "get",
        url: LEAVE_APPLICATION,
        params: {
          college_id: isAllSelected ? null : selectedFaculty, // Pass null for 'All'
          fromDate: fromDate,
          toDate: formattedAdjustedToDate,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
        },
      };
    
      await axios(config)
        .then((res) => {
          console.log("Selected Faculty:", selectedFaculty);
          console.log("From Date:", fromDate);
          console.log("To Date:", formattedAdjustedToDate);
          res.data.data.sort((a, b) => b.id - a.id);
          setData(res.data.data);
          console.log("FilteredData:", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    
      setLoading(0);
    };
    
    const getData1 = async () => {
        setLoading(1);
        const config = {
          method: "get",
          url: EMPLOYEE_ALL,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
    
        await axios(config)
          .then((res) => {
            setData1(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
        setLoading(0);
    };

    const getData2 = async () => {
        setLoading(1);
        const config = {
          method: "get",
          url: LEAVE_ENTITLEMENT,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
    
        await axios(config)
          .then((res) => {
            setData2(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
        setLoading(0);
    };
    
    useEffect(() => {
        getData();
      }, []);

    useEffect(() => {
        getData1();
        getData2();
      }, []);
      
    // const handleFacultyChange = (e) => {
    //   setSelectedFaculty(e.target.value);
    // };

    const handleFacultyChange = (selectedOption) => {
      setSelectedFaculty(selectedOption.value);
    };

    const handleFromDateChange = (e) => {
      setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
      setToDate(e.target.value);
    };

    const tableRef = useRef();

    const PrintRecipt = useReactToPrint({
      content: () => tableRef.current,
    });

    const handlePrint = () => {
      PrintRecipt();
    };

    // const options = collegeOpt?.map((i) => ({ value: i.id, label: i.name })) || [];

    const options = [
      { value: '', label: 'All' },  // Add an "All" option
      ...(collegeOpt?.map((i) => ({ value: i.id, label: i.name })) || [])
    ];

  return (
    <div>
      
      <div className="main-content">
        <div className="page-content">
            <div className="container-fluid">
                
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0"> STAFF ATTENDANCE REPORT </h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="mb-0">Select Criteria</h5>
                                <br/>

                                <div className="row">

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">Faculty</label>
                                            {/* <select
                                              name="faculty1"
                                              value={selectedFaculty}
                                              onChange={handleFacultyChange}
                                              className="form-control"
                                            >
                                            <option value="">ALL</option>
                                              {collegeOpt?.map((i, key) => (
                                              <option value={i.id} key={key}>
                                                {i.name}
                                              </option>
                                              ))}
                                            </select> */}

                                            <Select
                                              options={options}
                                              value={options.find((option) => option.value == selectedFaculty)}
                                              onChange={(selectedOption) => setSelectedFaculty(selectedOption.value)}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">From Date</label>
                                            <input
                                              type="date"
                                              name="submission_date"
                                              onChange={handleFromDateChange}
                                              className="form-control"
                                              value={fromDate ? fromDate : date}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">To Date</label>
                                            <input
                                              type="date"
                                              name="submission_date"
                                              onChange={handleToDateChange}
                                              className="form-control"
                                              value={toDate ? toDate : date}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="row ">
                                    <div className="col-md-12 ml-auto">
                                        <button
                                            className="btn btn-nex btn-rounded float-right"
                                            onClick={() => getData()}
                                        >
                                        <i className="fa fa-search" aria-hidden="true" />{" "}
                                            Search
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                      <br/>

                        <div className="row mb-3">
                          <div className="col-md-12 d-flex justify-content-between align-items-center">
                            <div className="card-title text-uppercase">
                              {" "}
                              <h5> STAFF ATTENDANCE REPORT</h5>
                            </div>
                            <button
                              className="btn btn-primary rounded-pill"
                              onClick={handlePrint}
                            >
                                Export PDF
                            </button>
                          </div>
                        </div>

                        <table
                          id="datatable"
                          ref={tableRef}
                          className="table table-bordered dt-responsive nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >

                          <thead>
                            <tr>
                              <th>Sl.No.</th>
                              <th>Employee Name</th>
                              <th>Faculty</th>
                              <th>Department</th>
                              <th>Leave Type</th>
                              <th>Applied Date</th>
                              <th>Session</th>
                              <th>Number of Days</th>
                              <th>From Date</th>
                              <th>To Date</th>
                              <th>Status</th>
                              <th>Approved By</th>
                            </tr>
                          </thead>

                          <tbody>

                            {data &&
                              data?.map((item, key) => {
                                return (
                                  <tr>
                                    <td>{key + 1}</td>
                                    <td>
                                      {data1?.filter(
                                        (s) => s.id == item?.employee_id
                                      )[0]?.first_name
                                        ? data1?.filter(
                                          (s) => s.id == item?.employee_id
                                        )[0]?.first_name + " " +
                                          data1?.filter(
                                            (s) => s.id == item?.employee_id
                                          )[0]?.last_name
                                        : null}
                                    </td>
                                    <td>
                                      {
                                        collegeOpt.filter(
                                          (s) => s.id === item?.college_id
                                        )[0]?.name
                                      }
                                    </td>
                                    <td>
                                      {
                                        data1
                                          .filter((s) => s.id == item?.employee_id)[0]?.department_id
                                            ? department.filter(
                                                (d) =>
                                                d.id ==
                                                data1
                                                .filter((s) => s.id == item?.employee_id)[0]?.department_id
                                              )[0]?.name
                                            : null
                                      }
                                    </td>
                                    <td>
                                      {
                                        data2.filter(
                                          (s) => s.employee_id == item?.employee_id
                                        )[0]?.leave_type
                                      }
                                    </td>
                                    <td>{item?.submission_date ? item?.submission_date.split('T')[0] : null}</td>
                                    <td>{item?.session}</td>
                                    <td>{item?.number_of_days}</td>
                                    <td>{item?.from_date ? item.from_date.split('T')[0] : null}</td>
                                    <td>{item?.to_date ? item.to_date.split('T')[0] : null}</td>
                                    <td>{item?.status}</td>
                                    <td>
                                      {data1?.filter(
                                        (s) => s.id == item?.approved_by
                                      )[0]?.first_name
                                      ? data1?.filter(
                                        (s) => s.id == item?.approved_by
                                      )[0]?.first_name + " " +
                                        data1?.filter(
                                        (s) => s.id == item?.approved_by
                                        )[0]?.last_name
                                      : null}
                                    </td>
                                  </tr>
                                );
                            })}
                                                    </tbody>
                                                    
                        </table>
                                                
                      </div>
                    </div>
                  </div>
                </div>

            </div>
        </div>
      </div>

    </div>
  )
}

export default StaffAttendanceReport;
