import React from "react";
import "./ProfileSwitches.scss";
import { toast } from "react-toastify";

function ProfileSwitches(props) {
  console.log("props -", props);

  return (
    <div className="ProfileSwitches d-flex justify-content-between">
      <ul className="nav nav-tabs ">
        <li
          className="nav-item"
          onClick={() => {
            props.setStep(1);
          }}
        >
          <div className={`nav-link ${props.step === 1 ? "active" : ""}`}>
            Basic Info
          </div>
        </li>
        <li
          className="nav-item"
          onClick={() => {
            props.setStep(2);
          }}
        >
          <div className={`nav-link ${props.step === 2 ? "active" : ""}`}>
            Application Fee Payment
          </div>
        </li>
        <li
          className="nav-item"
          // onClick={() => {
          //   if (props?.data?.form_data?.payment_status != "Success") {
          //     toast.error("Please complete Application Payment");
          //   } else {
          //     props.setStep(3);
          //   }
          // }}
          onClick={() => {
            props.setStep(3);
          }}
        >
          <div className={`nav-link ${props.step === 3 ? "active" : ""}`}>
            Application
          </div>
        </li>
        <li
          className="nav-item"
          onClick={() => {
            if (props?.data?.form_data?.payment_status != "Success") {
              toast.error("Please complete Application Payment");
            } else {
              props.setStep(4);
            }
          }}
          // onClick={() => {
          //   props.setStep(4);
          // }}
        >
          <div className={`nav-link  ${props.step === 4 ? "active" : ""}`}>
            Documents
          </div>
        </li>
        <li
          className="nav-item"
          onClick={() => {
            if (props?.data?.form_data?.payment_status != "Success") {
              toast.error("Please complete Application Payment");
            } else {
              props.setStep(5);
            }
          }}
          // onClick={() => {
          //   props.setStep(5);
          // }}
        >
          <div className={`nav-link  ${props.step === 5 ? "active" : ""}`}>
            Status
          </div>
        </li>
      </ul>

      {props?.data?.basic_data?.application_status == "ACTIVE" ||
      props?.data?.basic_data?.application_status == "SUBMITTED" ? (
        <button
          className="default-btn float-end"
          style={{ pointerEvents: "all", cursor: "pointer" }}
          onClick={() => {
            props.updateForm();
          }}
        >
          Save Changes
        </button>
      ) : null}
    </div>
  );
}

export default ProfileSwitches;
