import { BASE_URL } from "../../utils/apiConstants";
export const CLASS = `${BASE_URL}/api/academics/class`;

export const SECTION = `${BASE_URL}/api/academics/section`;

export const SEMESTER = `${BASE_URL}/api/academics/semester`;


//Add Subject
export const ACADEMICS_ADD_SUBJECT = `${BASE_URL}/api/academics/course`;

//Add Class
export const ACADEMICS_ADD_CLASS = `${BASE_URL}/api/academics/class`;

//Add Semester
export const ACADEMICS_ADD_SEMESTER = `${BASE_URL}/api/academics/semester`;


///////////////////// clinical notes /////////////////////

export const CLINICAL_NOTES = `${BASE_URL}/api/academics/clinical-notes`;

export const STUDENT_ID = `${BASE_URL}/api/academics/curr-student-session-id`;
