import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import AdvanceFeeReciept from "../../Accounts/FeeCollection/AdvancePayFeeCollection";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import Select from "react-select";
import { ROUTES } from "../../../Router/routerConfig";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import {
  STUDENT_ADMISSION,
  STUDENT_SESSION_UPDATE,
  STUDENT_GENDER,
  STUDENT_SESSION,
  STUDENT_ADVANCE_PAY,
} from "../../../utils/apiConstants";
import {
  LOCAL_DEPARTMENT,
  LOCAL_COLLEGE,
} from "../../../utils/LocalStorageConstants";
import { SESSION_ROLE } from "../../../utils/sessionStorageContants";

function StudentDetails({ setLoading, collegeId }) {
  let role = sessionStorage.getItem(SESSION_ROLE);
  const locate = useLocation();

  const printRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => printRef.current,
  });

  const [adv, setAdv] = useState([]);
  const [data, setData] = useState([]);

  const [clg, setClg] = useState("");

  var emp_id = sessionStorage.getItem("employee_id");

  const handlePrint = async (i, studentData) => {
    if (studentData) {
      let real = await adv?.filter((s) => s.user_id == studentData?.user_id);
      setA(real);
      await setSubdata(i);
      PrintRecipt();
    } else {
      let real = await adv?.filter((s) => s.user_id == i?.data?.user_id);
      setA(real);
      await setSubdata(i);
      PrintRecipt();
    }
  };

  const navigate = useNavigate();

  const changeDir = (dir, i) => {
    console.log("i -- ", i);
    navigate(
      `${dir}/${i?.user_id}?session_id=${i?.student_session_id}&depart=${i?.department_id}&session=${i?.session_id}&class=${i?.class_id}&sem=${i?.semester_id}&section=${i?.section_id}`,
      {
        state: {
          data: data,
          student: student,
        },
      }
    );
  };

  const changeDir1 = (dir, obj) => {
    navigate(
      `${dir}/${obj?.user_id}?session_id=${obj?.id}&depart=${obj?.department_id}&session=${obj?.session_id}&class=${obj?.class_id}&sem=${obj?.semester_id}&section=${obj?.section_id}`,
      {
        state: {
          data: data,
          student: student,
        },
      }
    );
  };

  const [x, setX] = useState(false);

  const [z, setZ] = useState(false);
  const [y, setY] = useState(false);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);

  const [gender, setGender] = useState([]);

  const [flag, setFlag] = useState(false);

  const [classOpt, setClassOpt] = useState([]);

  const [sectionOpt, setSectionOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [faculty, setFaculty] = useState("");

  const [currentclass, setCurrentClass] = useState("");

  const [currentSemester, setCurrentSemester] = useState("");

  const [currentSection, setCurrentSection] = useState("");

  const [status, setStatus] = useState("ACTIVE");

  const [allData, setAllData] = useState([]);


  const [session, setSession] = useState("");

  const [student, setStudent] = useState([]);

  const [subdata, setSubdata] = useState([]);

  const [a, setA] = useState([]);

  const [hostelYear, setHostelYear] = useState("");

  const [flag1, setFlag1] = useState(false);

  const [userId, setUserId] = useState("");
  const [studentData, setStudentData] = useState([]);

  const getAllStudents = async () => {
    const config = {
      method: "get",
      url: STUDENT_SESSION + `?college_id=${clg}&status=ACTIVE`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log('all students - ', res.data.data?.filter((s) => s.sem_status == "ACTIVE"));
        setAllData(res.data.data?.filter((s) => s.sem_status == "ACTIVE"));
      })
      .catch((err) => {
        console.log(err);
      }); 
  }

  const getData = async (x) => {
    setStudentData([]);
    if (
      !selectedClass ||
      !selectedSession ||
      !selectedDepartment ||
      !selectedClass ||
      !selectedSection ||
      !selectedDepartment
    ) {
      toast.error("All the fields are required");
      return;
    }

    setLoading(1);
    var url;
    if (emp_id == 316) {
      url =
        STUDENT_SESSION +
        `?college_id=${clg}&department_id=${selectedDepartment.value}&class_id=${selectedClass.value}&section_id=${selectedSection.value}&semester_id=${selectedSemester.value}&session_id=${selectedSession.value}&status=${status}&international=1`;
    } else {
      url =
        STUDENT_SESSION +
        `?college_id=${collegeId}&department_id=${selectedDepartment.value}&class_id=${selectedClass.value}&section_id=${selectedSection.value}&semester_id=${selectedSemester.value}&session_id=${selectedSession.value}&status=${status}`;
    }
    const config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    console.log('url - ', url);
    await axios(config)
      .then((res) => {
        console.log("Data Main", res.data.data);
        // res.data.data.sort((a, b) => b.user_id - a.user_id);

        res.data.data.sort((a, b) => {
          // Extract numerical part from user_id
          const numA = parseInt(a.user_id.match(/\d+$/)[0]);
          const numB = parseInt(b.user_id.match(/\d+$/)[0]);
          
          // Compare numerical parts
          return numA - numB;
        })

        setData(res.data.data);
        let data = [];
        for (var i = 0; i < res.data.data.length; i++) {
          data.push({
            id: res.data.data[i].user_id,
            gender: res.data.data[i].gender,
          });
        }
        setGender(data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some Error Occured");
      });

    setLoading(0);
    // await axios(config1)
    //   .then((res) => {
    //     setLoading(0);
    //     console.log(res.data.data);
    //     setStudent(res.data.data);
    //   })
    //   .catch((err) => {
    //     setLoading(0);
    //     console.log(err);
    //   });
  };

  const getAllData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    const [data1, data2, data3, data4] = await Promise.all([
      await axios({
        ...config,
        url:
          emp_id != 316
            ? `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}`
            : `${ACADEMICS_ADD_CLASS}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),

      await axios({
        ...config,
        url:
          emp_id != 316
            ? `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}`
            : `${ACADEMICS_ADD_SEMESTER}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),

      await axios({
        ...config,
        url:
          emp_id != 316
            ? `${ACADEMICS_ADD_SECTION}?college_id=${collegeId}`
            : `${ACADEMICS_ADD_SECTION}`,
      })
        .then((res) => {
          setSectionOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),

      await axios({
        ...config,
        url: STUDENT_ADVANCE_PAY,
      })
        .then((res) => {
          setLoading(0);
          setAdv(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        }),
    ]);
  };

  const handleEdit = async (id, stat) => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${STUDENT_ADMISSION}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: stat,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Update Success");
        console.log(res);
        getData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    if (locate?.state) {
      setCurrentClass(locate.state?.class);
      setCurrentSection(locate.state?.section);
      setCurrentSemester(locate.state?.sem);
      setSession(locate.state?.session);
      setFaculty(locate.state?.depart);
    }
  }, [locate?.state]);

  const edit2 = async (id, gender1) => {
    setY(true);
    for (var i = 0; i < gender.length; i++) {
      if (gender[i].id == id) {
        gender[i].gender = gender1.target.value;
        console.log(gender[i]);
      }
    }
  };

  const save = async () => {
    setLoading(1);
    for (var i = 0; i < data.length; i++) {
      if (data[i].gender == "" || data[i].gender == null) {
        toast.error("Gender is required for all Students");
        return;
      }
    }

    if (!x && !y) {
      toast.error("Nothing Changed");
      setFlag(false);
      return;
    }

    setFlag(false);

    const config = {
      method: "post",
      url: `${STUDENT_GENDER}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: gender,
    };

    await axios(config)
      .then(async (res) => {
        console.log(res);
        // await getData2(true);
        toast.success("Successfully Updated Details");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    setLoading(0);
  };

  const handleChange = async (sid, h, t) => {
    setX(true);
    const config = {
      method: "put",
      url: `${STUDENT_SESSION_UPDATE}/${sid}`,
      data: {
        hostel: h,
        transport: t,
        session_id: session,
      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStudentData = async () => {
    setLoading(1);
    setData([]);

    const config = {
      method: "get",
      url: `${STUDENT_ADMISSION}/${userId}`,
      data: {
        user_id: userId,
      },
      headers: {
        Authorization: `Bearer  ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const config1 = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("student data - ", res?.data);
        let temp = res?.data;
        let activeSession = temp?.session?.filter((s) => s?.status == "ACTIVE");
        setFaculty(res?.data?.college_id);
        setCurrentClass(activeSession[0]?.class_id);
        setSession(activeSession[0]?.session_id);
        let obj = {};
        // obj["id"] = temp?.data?.id;
        obj["id"] =
          temp?.semester[temp?.semester.length - 1]?.student_session_id;
        obj["class_name"] = temp?.session[temp?.session.length - 1]?.class_name;
        obj["department_id"] = temp?.data?.department_id;
        obj["class_id"] = activeSession[0]?.class_id;
        obj["session_id"] = activeSession[0]?.session_id;
        obj["semester_id"] =
          temp?.semester[temp?.semester.length - 1]?.semester_id;
          obj["semester_name"] =
          temp?.semester[temp?.semester.length - 1]?.semester_name;
        obj["section_id"] =
          temp?.semester[temp?.semester.length - 1]?.section_id;
        obj["user_id"] = temp?.data?.user_id;
        obj["name"] = temp?.data?.name;
        obj["email"] = temp?.data?.email;
        obj["phone"] = temp?.data?.phone;
        obj["dob"] = temp?.data?.dob?.substring(0, 10);
        obj["gender"] = temp?.data?.gender;
        obj["category"] = temp?.data?.category;
        obj["is_hostel"] = activeSession[0]?.is_hostel;
        obj["is_transport"] = activeSession[0]?.is_transport;
        obj["status"] = temp?.data?.status;
        obj["college_name"] = collegeOpt.find(
          (s) => s.id == temp?.data?.college_id
        ).name;
        obj["department_name"] = department.find(
          (s) => s.id == temp?.data?.department_id
        )?.name;
        console.log("obj - ", obj);
        setStudentData(obj);
        let targetDiv = document.getElementById("datatable");
        targetDiv.scrollIntoView({ behavior: "smooth" });
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    await axios({
      ...config1,
      url: STUDENT_ADVANCE_PAY,
    })
      .then((res) => {
        console.log("adv - ", res.data.data);
        setAdv(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllData();
    getAllStudents();
  }, []);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);

  const handleDepartmentChange = (selectedOption) => {
    console.log("Selected Department:", selectedOption);
    setSelectedDepartment(selectedOption);
    setSelectedClass(null);
    setSelectedSemester(null);
    setSelectedSection(null);
  };

  const handleClassChange = (selectedOption) => {
    console.log("Selected Class:", selectedOption);
    setSelectedClass(selectedOption);
    setSelectedSemester(null);
    setSelectedSection(null);
  };

  const handleSemesterChange = (selectedOption) => {
    console.log("Selected Semester:", selectedOption);
    setSelectedSemester(selectedOption);
    setSelectedSection(null);
  };

  const handleSectionChange = (selectedOption) => {
    console.log("Selected Section:", selectedOption);
    setSelectedSection(selectedOption);
  };

  const handleSessionChange = (selectedOption) => {
    console.log("Selected Session:", selectedOption);
    setSelectedSession(selectedOption);
  };

  return (
    <div className="StudentDetails">
      <div style={{ display: "none" }}>
        <div ref={printRef}>
          <AdvanceFeeReciept
            mainData={subdata?.data}
            studentInfo={subdata?.studentInfo}
            data={a}
            collegeId={subdata?.studentInfo?.college_id}
            collegeOpt={collegeOpt}
            classData={classOpt}
            class_id={subdata?.data?.class_id}
            departmentData={department}
          />
        </div>
      </div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Students Details</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Students Information</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Students Details
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Search By Enrollment Number</h2>
                    <br />

                    <div className="input-group mb-3 col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Student Enrollment No"
                        value={userId}
                        onChange={(e) => {
                          setUserId(e.target.value);
                        }}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-nex  btn-md"
                          type="submit"
                          name="submit"
                          value="collect"
                          onClick={getStudentData}
                        >
                          <i className="fa fa-search mr-1" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      {emp_id == 316 ? (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="validationCustom01">
                              Faculty <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              name="clg"
                              id="faculty"
                              className="form-control"
                              value={clg}
                              onChange={(e) => {
                                setClg(e.target.value);
                              }}
                            >
                              <option value="" selected>
                                ALL
                              </option>
                              {collegeOpt?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ) : null}
                     
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Department <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <select
                            name="faculty"
                            id="section"
                            className="form-control"
                            value={faculty}
                            onChange={(e) => {
                              setFaculty(e.target.value);
                            }}
                          >
                            <option value="" selected>
                              ALL
                            </option>
                            {emp_id == 316
                              ? department
                                  ?.filter((s) => s.college_id == clg)
                                  ?.map((i, key) => (
                                    <option value={i.id} key={key}>
                                      {i.name}
                                    </option>
                                  ))
                              : department
                                  ?.filter((s) => s.college_id == collegeId)
                                  ?.map((i, key) => (
                                    <option value={i.id} key={key}>
                                      {i.name}
                                    </option>
                                  ))}
                          </select> */}

                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="faculty"
                            id="section"
                            value={selectedDepartment}
                            onChange={handleDepartmentChange}
                            options={
                              department
                                    ?.filter((s) => s.college_id == collegeId)
                                    ?.map((i) => ({
                                      label: allData?.length > 0 ? `${i.name} (Students : ${allData?.filter((s) => s.department_id == i.id)?.length})`: i.name,
                                      value: i.id,
                                    }))
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Session <span style={{ color: "red" }}>*</span></label>
                          {/* <select
                            className="form-control"
                            value={session}
                            onChange={(e) => {
                              setSession(e.target.value);
                            }}
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option value={i?.id}>{i?.name}</option>
                            ))}
                          </select> */}

                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="session"
                            id="session"
                            value={selectedSession}
                            onChange={handleSessionChange}
                            options={sessionOpt?.map((i) => ({
                              label: allData?.length > 0 ? `${i.name} (Students : ${allData?.filter((s) => s.session_id == i.id && s.department_id == selectedDepartment?.value)?.length})`: i.name,
                              value: i.id,
                            }))}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          {/* <select
                            className="form-control"
                            value={currentclass}
                            onChange={(e) => {
                              setCurrentClass(e.target.value);
                            }}
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter((s) => s?.department_id == faculty)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select> */}

                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="currentclass"
                            id="class"
                            value={selectedClass}
                            onChange={handleClassChange}
                            options={classOpt
                              ?.filter(
                                (s) =>
                                  s.department_id == selectedDepartment?.value
                              )
                              ?.map((i) => ({ 
                                label: allData?.length > 0 ? `${i.name} (Students : ${allData?.filter((s) => s.class_id == i.id && s.session_id == selectedSession?.value && s.department_id == selectedDepartment?.value)?.length})`: i.name,
                                value: i.id 
                              }))}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          {/* <select
                            className="form-control"
                            value={currentSemester}
                            onChange={(e) => {
                              setCurrentSemester(e.target.value);
                            }}
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == currentclass)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select> */}

                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="currentSemester"
                            id="semester"
                            value={selectedSemester}
                            onChange={handleSemesterChange}
                            options={semesterOpt
                              ?.filter(
                                (s) => s.class_id == selectedClass?.value
                              )
                              ?.map((i) => ({ 
                                label: allData?.length > 0 ? `${i.name} (Students : ${allData?.filter((s) => s.class_id == selectedClass.value && s.session_id == selectedSession?.value && s.department_id == selectedDepartment?.value && s.semester_id == i.id)?.length})`: i.name,
                                value: i.id 
                              }))}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Section</label>
                          {/* <select
                            className="form-control"
                            value={currentSection}
                            onChange={(e) => {
                              setCurrentSection(e.target.value);
                            }}
                          >
                            <option value="">Select Section</option>
                            {sectionOpt
                              ?.filter((s) => s.semester_id == currentSemester)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select> */}

                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="currentSection"
                            id="section"
                            value={selectedSection}
                            onChange={handleSectionChange}
                            options={sectionOpt
                              ?.filter(
                                (s) => s.semester_id == selectedSemester?.value
                              )
                              ?.map((i) => ({ 
                                label: allData?.length > 0 ? `${i.name} (Students : ${allData?.filter((s) => s.class_id == selectedClass.value && s.session_id == selectedSession?.value && s.department_id == selectedDepartment?.value && s.semester_id == selectedSemester?.value && s.section_id == i.id)?.length})`: i.name,
                                value: i.id 
                              }))}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Status</label>
                          <select
                            className="form-control"
                            value={status}
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >
                            <option value="">Select Status</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row float-right mr-3">
                      <button
                        className="btn btn-nex  btn-md"
                        type="submit"
                        name="submit"
                        value="collect"
                        onClick={getData}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">Students Details</h4>
                      </div>
                      <div className="col-md-8">
                        <span className="float-right">
                          {/* <button
                            className="btn btn-primary mx-2"
                            onClick={() => {
                              setFlag(true);
                              setX(false);
                              setY(false);
                            }}
                          >
                            Edit
                          </button> */}
                          {(flag == true && data.length != 0) ||
                          (flag == true && studentData.length != 0) ? (
                            <button
                              className="btn btn-primary mx-2"
                              onClick={() => save()}
                            >
                              Save
                            </button>
                          ) : null}
                        </span>
                      </div>
                    </div>
                    <h6>Total Students - {data?.filter((s) => s.sem_status == "ACTIVE")?.length > 0 ? data?.filter((s) => s.sem_status == "ACTIVE")?.length : studentData?.user_id ? 1 : 0}</h6>
                    <div className="table-responsive">
                      <hr />
                      <table
                        id="datatable"
                        className="table table-bordered  nowrap table-hover"
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sl No</th>
                            <th>Enrollment No.</th>
                            <th>Name</th>
                            <th>College</th>
                            <th>Dept</th>
                            <th>Class</th>
                            <th>Session</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Date of Birth</th>
                            <th className="text-center">Gender</th>
                            {role != "STAFF" ? (
                              <>
                                <th>Category</th>
                                <th>Hostel</th>
                                <th>Transport</th>
                                <th style={{ minWidth: "100px" }}>Action</th>
                              </>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {data && data.length !== 0 ? (
                            data?.filter((s) => s.sem_status == "ACTIVE")?.map((i, key) => (
                              <tr>
                                <td>{key + 1}</td>
                                <td>{i?.user_id}</td>
                                <td>{i?.name}</td>
                                <td>
                                  {
                                    collegeOpt.find(
                                      (s) => s.id == i?.college_id
                                    )?.name
                                  }
                                </td>
                                <td>
                                  {
                                    department.find(
                                      (s) => s.id == i?.department_id
                                    )?.name
                                  }
                                </td>
                                <td>{i?.class_name}({i?.semester})</td>
                                <td>
                                  {i?.session_id}-{i?.session_id + 1}
                                </td>
                                <td>
                                  {i?.email}
                                </td>
                                <td>
                                {i?.phone}
                                </td>
                                <td>
                                {i?.dob?.split("T")[0]}
                                </td>
                                <td>
                                  <div className="">
                                    {(i?.gender == "Male" ||
                                      i?.gender == "male") &&
                                    !flag ? (
                                      <div className="">
                                        <input
                                          type="radio"
                                          checked={
                                            i?.gender == "male" ||
                                            i?.gender == "Male"
                                              ? 1
                                              : 0
                                          }
                                          className={
                                            flag === false ? "d-none" : ""
                                          }
                                          value={
                                            i?.gender == "Male"
                                              ? i?.gender
                                              : "Male"
                                          }
                                          onChange={(e) => {
                                            edit2(i?.user_id, e);
                                            i.gender = e.target.value;
                                            setZ(!z);
                                          }}
                                        />
                                        Male
                                      </div>
                                    ) : (i?.gender == "Female" ||
                                        i?.gender == "female") &&
                                      !flag ? (
                                      <div className="">
                                        <input
                                          type="radio"
                                          checked={
                                            i?.gender == "Female" ||
                                            i?.gender == "female"
                                              ? 1
                                              : 0
                                          }
                                          className={
                                            flag == false ? "d-none" : ""
                                          }
                                          value={
                                            i?.gender == "Female"
                                              ? i?.gender
                                              : "Female"
                                          }
                                          onChange={(e) => {
                                            edit2(i?.user_id, e);
                                            i.gender = e.target.value;
                                            setZ(!z);
                                          }}
                                        />
                                        Female
                                      </div>
                                    ) : (i?.gender == null ||
                                        i?.gender == "") &&
                                      !flag ? (
                                      <div>Not Assigned</div>
                                    ) : (
                                      <div className="flex-row d-flex justify-content-center">
                                        {i?.gender}
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>{i?.category}</td>
                                <td>{i?.is_hostel ? "YES" : "NO"}</td>
                                <td>{i?.is_transport ? "YES" : "NO"}</td>
                                {role != "STAFF" ? (
                                  <>
                                    
                                    <td className="flex-nowrap justify-content-between">
                                      <a
                                        onClick={() => {
                                          if (role == "ADMIN")
                                            changeDir(
                                              ROUTES.Principal.Student
                                                .StudentProfile,
                                              i
                                            );
                                          if (role == "SUPERADMIN")
                                            changeDir(
                                              ROUTES.Registar.Student
                                                .StudentProfile,
                                              i
                                            );
                                          if (role == "WARDEN")
                                            changeDir(
                                              ROUTES.Warden.ViewStudentProfile,
                                              i
                                            );
                                          if (role == "SUACC") {
                                            changeDir(
                                              ROUTES.Accountant.StudentProfile,
                                              i
                                            );
                                          }
                                        }}
                                      >
                                        <i
                                          className="fa fa-edit"
                                          aria-hidden="true"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </a>{" "}
                                      &nbsp; &nbsp;{" "}
                                      <a
                                        onClick={() => {
                                          console.log("i-", i);
                                          if (role == "ADMIN")
                                            changeDir(
                                              ROUTES.Principal.Student
                                                .ViewStudentProfile,
                                              i
                                            );
                                          if (role == "SUPERADMIN")
                                            changeDir(
                                              ROUTES.Registar.Student
                                                .ViewStudentProfile,
                                              i
                                            );
                                          if (role == "SUACC") {
                                            changeDir(
                                              ROUTES.Accountant
                                                .ViewStudentProfile,
                                              i
                                            );
                                          }
                                        }}
                                      >
                                        <i
                                          className="fas fa-eye mr-2"
                                          aria-hidden="true"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </a>
                                      <a>
                                        {i?.status === "INACTIVE" ? (
                                          <i
                                            className="fa fa-thumbs-up"
                                            style={{
                                              color: "green",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              handleEdit(i?.user_id, "ACTIVE");
                                            }}
                                          />
                                        ) : (
                                          <i
                                            className="fa fa-thumbs-down"
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              handleEdit(
                                                i?.user_id,
                                                "INACTIVE"
                                              );
                                            }}
                                          />
                                        )}
                                      </a>
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            ))
                          ) : studentData.length !== 0 ? (
                            <tr>
                              <td>1</td>
                              <td>{studentData?.user_id}</td>
                              <td>{studentData?.name}</td>
                              <td>{studentData?.college_name}</td>
                              <td>{studentData?.department_name}</td>
                              <td>{studentData?.class_name}({studentData?.semester_name})</td>

                              <td>
                                {studentData?.session_id}-
                                {studentData?.session_id + 1}
                              </td>
                              <td>{studentData?.email}</td>
                              <td>{studentData?.phone}</td>
                              <td>{studentData?.dob}</td>
                              <td>{studentData?.gender}</td>
                              <td>{studentData?.category}</td>
                              <td>{studentData?.is_hostel ? "YES" : "NO"}</td>
                              <td>{studentData?.is_transport ? "YES" : "NO"}</td>
                              {role != "STAFF" ? (
                                <>
                                  
                                  {/* <td>
                                    <input
                                      type="checkbox"
                                      checked={studentData?.is_transport}
                                      disabled={flag == true ? "" : "disabled"}
                                      className={flag == false ? "hidden" : ""}
                                      onChange={() => {
                                        studentData?.is_transport == 1
                                          ? (studentData.is_transport = 0)
                                          : (studentData.is_transport = 1);
                                        setFlag1((flag1) => !flag1);
                                        handleChange(
                                          studentData?.user_id,
                                          studentData?.is_hostel,
                                          studentData?.is_transport
                                        );
                                      }}
                                    />
                                  </td> */}
                                  <td className="flex-nowrap justify-content-between">
                                    <a
                                      onClick={() => {
                                        if (role == "ADMIN")
                                          changeDir1(
                                            ROUTES.Principal.Student
                                              .StudentProfile,
                                            studentData
                                          );
                                        if (role == "SUPERADMIN")
                                          changeDir1(
                                            ROUTES.Registar.Student
                                              .StudentProfile,
                                            studentData
                                          );
                                        if (role == "SUACC") {
                                          changeDir1(
                                            ROUTES.Accountant.StudentProfile,
                                            studentData
                                          );
                                        }
                                      }}
                                    >
                                      <i
                                        className="fa fa-edit"
                                        aria-hidden="true"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </a>{" "}
                                    &nbsp; &nbsp;{" "}
                                    <a
                                      onClick={() => {
                                        if (role == "ADMIN")
                                          changeDir1(
                                            ROUTES.Principal.Student
                                              .ViewStudentProfile,
                                            studentData
                                          );
                                        if (role == "SUPERADMIN")
                                          changeDir1(
                                            ROUTES.Registar.Student
                                              .ViewStudentProfile,
                                            studentData
                                          );
                                        if (role == "SUACC") {
                                          changeDir1(
                                            ROUTES.Accountant
                                              .ViewStudentProfile,
                                            studentData
                                          );
                                        }
                                      }}
                                    >
                                      <i
                                        className="fas fa-eye mr-2"
                                        aria-hidden="true"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </a>
                                    <a>
                                      {studentData?.status === "INACTIVE" ? (
                                        <i
                                          className="fa fa-thumbs-up"
                                          style={{
                                            color: "green",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            handleEdit(
                                              studentData?.user_id,
                                              "ACTIVE"
                                            );
                                          }}
                                        />
                                      ) : (
                                        <i
                                          className="fa fa-thumbs-down"
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            handleEdit(
                                              studentData?.user_id,
                                              "INACTIVE"
                                            );
                                          }}
                                        />
                                      )}
                                    </a>
                                  </td>
                                </>
                              ) : null}
                            </tr>
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan={16}>
                                <div align="center" className="text-danger">
                                  No data available in table <br /> <br />
                                  <img
                                    src="/assets/images/addnewitem.svg"
                                    width={150}
                                  />
                                  <br />
                                  <br />{" "}
                                  <span className="text-success bolds">
                                    <i className="fa fa-arrow-left" /> Add new
                                    record or search with different criteria.
                                  </span>
                                  <div />
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* end col */}
            </div>{" "}
            {/* end row */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDetails;
