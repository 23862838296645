export const studentAdmissionTemplate =
  "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/student/Admission.csv";
export const studentCollectFeeTemplate =
  "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/student/collect_fee.csv";
export const studentMarksTemplate1 =
  "https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/employee_docs/73_Leave_Application/undefined_1691776581811.csv";
export const studentMarksTemplate2 =
  "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/employee_docs/11_Leave_Application/undefined_1691826721619.csv";
export const libraryTemplate =
  "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/other_modules/library+bulk+upload+csv.csv";
export const bulkAbcUpload =
  "https://s3.ap-south-1.amazonaws.com/documents.swaminarayanuniversity.ac.in/university-assets/bulk_abc_update.e211cabc24b83e2adebd.csv";

