import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { QR_CODE, TOOGLE_2FA } from "./../../../utils/apiConstants";
import { Switch } from "antd";

function Modal2FA({ type, data, userdata, setLoading, getUsersData }) {
  const [qrCode, setQrCode] = useState("");
  const [setupKey, setSetupKey] = useState("");

  const generateQRCode = async () => {
    if (!userdata?.id) {
      return toast.error("User data not available to generate QR code");
    }
    const config = {
      method: "get",
      url: `${QR_CODE}/${userdata?.id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("qr code - ", res.data.data);
        toast.success("QR Code generated and saved");
        setQrCode(res.data.data);
        getUsersData(userdata?.id);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Unable to generate QR Code");
        console.log(err);
      });
    setLoading(0);
  };

  const toogle2FA = async (val) => {
    if (!userdata?.id) {
      return toast.error("User data not available to generate QR code");
    }
    const config = {
      method: "post",
      url: `${TOOGLE_2FA}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        val,
        id: userdata?.id,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Updated");
        getUsersData(userdata?.id);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Unable to update");
        console.log(err);
      });
    setLoading(0);
  };

  return (
    <div className="Modal2FA">
      <div
        className="modal fade"
        id="Modal2FA"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Two Factor Authentication
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {!userdata?.auth_secret_key ? (
                <div className="p-3">
                  <h5>Setup Two Factor Authentication</h5>
                  <ul>
                    <li>
                      Download any Authenticator app e.g. Google Authenticator.
                    </li>
                    <li>
                      <p>
                        Generate QR code and setup key by pressing below
                        generate button
                      </p>
                      <button
                        className="btn btn-success"
                        onClick={generateQRCode}
                      >
                        Generate
                      </button>
                    </li>
                  </ul>

                  <div className="div">
                    <div className="row">
                      {userdata?.auth_qr_code ? (
                        <div className="col-md-6">
                          <img
                            src={userdata?.auth_qr_code}
                            className="float-right"
                            alt="QR Code"
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                      {userdata?.auth_base32 ? (
                        <div className="col-md-6">
                          <p>Setup key : {userdata?.auth_base32}</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-3 ">
                  <h5>Setup Two Factor Authentication</h5>
                  <ul>
                    <li>
                      Download any Authenticator app e.g. Google Authenticator
                    </li>
                    <li>
                      Open your Authenticator app and click on Add new (+)
                    </li>
                    <li>
                      Either scan the below QR Code or enter the setup key in
                      your authenticator app
                    </li>
                  </ul>
                  <div>
                    <div className="row">
                      {userdata?.auth_base32 ? (
                        <div className="col-md-6">
                          <h6>Setup Key : </h6>
                          <p>{userdata?.auth_base32}</p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {userdata?.auth_qr_code ? (
                        <div className="col-md-4">
                          <h6>QR Code :</h6>
                          <img src={userdata?.auth_qr_code} alt="QR Code" />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="col-md-2">
                        <h6>Use in Login :</h6>
                        <Switch
                          onChange={(e) => (e ? toogle2FA(1) : toogle2FA(0))}
                          // value={userdata?.auth_flag ? true : false}
                          checked={userdata?.auth_flag == 1}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />

                  <div className="row">
                    <p>Click here to regenearte new setup key and QR Code : </p>
                    <button
                      className="btn btn-sm btn-info ml-2"
                      onClick={generateQRCode}
                    >
                      Regenerate
                    </button>
                  </div>
                  <p className="text-danger mt-2">
                    *If you have already made a setup in authenticator app for
                    UMS, make sure to delete it whenever you regenerate and add
                    new one.
                  </p>
                </div>
              )}
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal2FA;
