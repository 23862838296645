import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { useRef } from "react";
// import { ASSET_STUDENT_DOCUMENT } from "../../../utils/AssetReferenceTypes";
import { getFileUrl } from "../../../Helpers/Helpers";
import axios from "axios";
import { STUDENT } from "../student.Api";
import { ASSET_STUDENT_DOCUMENT } from "../../../utils/AssetsReferenceTypes";
import { international } from "../../../Data/Countries/international";
import { indian } from "../../../Data/Countries/india";

const BasicInformation = ({
  data,
  basic_data,
  setLoading,
  flag5,
  getData,
  saveflag,
}) => {
  const [info, setInfo] = useState({});
  const [countries, setCountries] = useState([]);
  const [flag, setFlag] = useState(flag5);
  const [flag1, setFlag1] = useState(false);
  const [img, setImg] = useState("");
  const fileref = useRef(null);

  const setCountryAndState = async () => {
    let arr = [];
    await international?.map((item, key) => {
      arr.push(item?.country);
    });
    setCountries(arr);
  };

  let uid = sessionStorage.getItem("uid");

  useEffect(() => {
    if (basic_data) {
      setInfo({
        ...basic_data,
        status: "ACTIVE",
      });
    }
  }, [data]);

  useEffect(() => {
    setCountryAndState();
  }, []);

  const save = async () => {
    console.log(info, uid);
    const config = {
      method: "put",
      url: `${STUDENT}/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: info,
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        toast.success("Successfully Updated Your Details");
        fileref.current.value = null;
        getData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const cancel = async () => {
    setFlag(false);
    await setInfo({
      ...basic_data,
      status: "ACTIVE",
    });
    fileref.current.value = null;
    setFlag1(!flag1);
  };

  const addImg = async (e) => {
    let empId = Math.floor(Math.random() * 100);
    try {
      setImg(e.target.files[0]);
      const d = await getFileUrl(
        ASSET_STUDENT_DOCUMENT,
        `${empId}_Leave_Application`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      setImg("");
      info.student_picture = d;
      console.log(info, d);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFlag(flag5);
    if (flag5 == false) {
      cancel();
    }
    if (saveflag == true) {
      save();
    }
  }, [flag5, saveflag]);

  return (
    <div className="BasicInformation">
      <div>
        {" "}
        <br />
        <br />
        <form>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label>Name of Student</label>
                <input
                  required
                  type="text"
                  name="name"
                  className="form-control"
                  value={info?.name}
                  readOnly={true}
                  onChange={(e) => {
                    info.name = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  placeholder="Student Full Name*"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Mobile Number</label>

                <input
                  required
                  type="number"
                  value={info?.phone}
                  readOnly={!flag ? true : false}
                  name="phone"
                  onChange={(e) => {
                    info.phone = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="Mobile Number"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Father's Mobile Number</label>

                <input
                  required
                  type="text"
                  value={info?.father_phone}
                  readOnly={!flag ? true : false}
                  onChange={(e) => {
                    info.father_phone = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  name="father_phone"
                  className="form-control"
                  placeholder="Parent Mobile Number *"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Mother's Mobile Number</label>

                <input
                  required
                  type="text"
                  value={info?.mother_phone}
                  readOnly={!flag ? true : false}
                  onChange={(e) => {
                    info.mother_phone = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  name="mother_phone"
                  className="form-control"
                  placeholder="Parent Mobile Number *"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Date of Birth</label>

                <div className="input-group date" id="datepicker">
                  <input
                    type="date"
                    placeholder="Date of Birth"
                    name="dob"
                    value={info.dob != null ? info?.dob.split("T")[0] : null}
                    readOnly={!flag ? true : false}
                    onChange={(e) => {
                      info.dob = e.target.value;
                      setFlag1(!flag1);
                    }}
                    className="form-control"
                    id="date"
                  />
                  <span className="input-group-append d-print-none h-100">
                    <span className="input-group-text bg-light ">
                      <i className="ri-calendar-2-line" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Email of Student</label>

                <input
                  required
                  type="email"
                  value={info?.email}
                  readOnly={!flag ? true : false}
                  onChange={(e) => {
                    info.email = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  name="email"
                  className="form-control"
                  placeholder="Email ID*"
                />
              </div>
            </div>

            <div
              className="col-lg-6 col-md-6 program-level"
              style={{ marginBottom: 0 }}
            >
              <div className="form-group">
                <p className="mb-1">
                  <b> Marital Status</b>
                </p>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    value={
                      info?.marital_status ? info?.marital_status : "single"
                    }
                    disabled={!flag ? true : false}
                    checked={info?.marital_status == "single" ? 1 : 0}
                    name="marital_status"
                    required
                    onChange={(e) => {
                      info.marital_status = e.target.value;
                      console.log(info);
                      setFlag1((flag1) => !flag1);
                    }}
                    type="radio"
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    Single
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    value={
                      info?.marital_status ? info?.marital_status : "married"
                    }
                    checked={info?.marital_status == "married" ? 1 : 0}
                    disabled={!flag ? true : false}
                    onChange={(e) => {
                      info.marital_status = "";
                      info.marital_status = e.target.value;
                      console.log(info);
                      setFlag1((flag1) => !flag1);
                    }}
                    name="marital"
                    required
                    type="radio"
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox2">
                    Married
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Gender</label>
                <select
                  className="form-control"
                  value={info?.gender}
                  readOnly={!flag ? true : false}
                  onChange={(e) => {
                    info.gender = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  name="gender"
                >
                  <option value="">Select Gender</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="validationCustom01">Religion</label>
                <select
                  className="form-control"
                  id="religion"
                  name="religion"
                  value={info?.religion}
                  onChange={(e) => {
                    info.religion = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                >
                  <option value=""> Please Select Religion</option>
                  <option value="Hindu">Hindu</option>
                  <option value="Muslim">Muslim</option>
                  <option value="Christian">Christian</option>
                  <option value="Buddha">Buddha</option>
                </select>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Category</label>
                <input
                  required
                  type="text"
                  value={info?.category}
                  readOnly={!flag ? true : false}
                  name="category"
                  onChange={(e) => {
                    info.category = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="Category*"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Caste</label>
                <input
                  required
                  type="text"
                  value={info?.caste}
                  onChange={(e) => {
                    info.caster = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  readOnly={!flag ? true : false}
                  name="caste"
                  className="form-control"
                  placeholder="Caste *"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Sub Caste</label>
                <input
                  required
                  type="text"
                  value={info?.sub_caste}
                  onChange={(e) => {
                    info.sub_caste = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  readOnly={!flag ? true : false}
                  name="subcaste"
                  className="form-control"
                  placeholder="Sub-Caste*"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Blood Group</label>
                <select
                  disabled={!flag ? true : false}
                  value={info?.blood_grp}
                  onChange={(e) => {
                    info.blood_grp = e.target.value;
                  }}
                  name="blood_grp"
                  className="form-control"
                >
                  <option value="">Select Blood Group</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                </select>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Physically Handicapped ?</label>
                <select
                  disabled={!flag ? true : false}
                  value={info?.physically_handiCap}
                  onChange={(e) => {
                    info.physically_handiCap = e.target.value;
                  }}
                  name="physically_handiCap"
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="1">YES</option>
                  <option value="0">NO</option>
                </select>
              </div>
            </div>

            {/*
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Blood Group</label>
                <select
                  value={info?.blood_grp}
                  readOnly={!flag ? true : false}
                  name="blood_grp"
                  id=""
                  className="form-control"
                >
                  <option value="">Select Blood Group</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                </select>
              </div>
                </div> */}

            {/* <div className="col-lg-12">
              <div className="form-group">
                <div className="form-floating">
                  <label htmlFor="floatingTextarea2">Address</label>

                  <textarea
                    className="form-control"
                    value={info?.address}
                    readOnly={!flag ? true : false}
                    name="address"
                    placeholder="Student's Parmanent Address*"
                    onChange={(e) => {
                      info.address = e.target.value;
                      setFlag1((flag1) => !flag1);
                    }}
                    id="floatingTextarea2"
                    rows={2}
                    cols={1}
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>State</label>
                <input
                  required
                  type="text"
                  value={info?.state}
                  onChange={(e) => {
                    info.state = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  readOnly={!flag ? true : false}
                  name="state"
                  className="form-control"
                  placeholder="State"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>District</label>
                <input
                  required
                  type="text"
                  value={info?.dist}
                  readOnly={!flag ? true : false}
                  name="dist"
                  onChange={(e) => {
                    info.dist = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="District"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Taluk</label>
                <input
                  required
                  type="text"
                  value={info?.taluk}
                  readOnly={!flag ? true : false}
                  name="taluk"
                  onChange={(e) => {
                    info.taluk = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="Taluk"
                />
              </div>
            </div> */}

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="">Nationality</label>
                <span style={{ color: "red" }}>*</span>
                <select
                  type="text"
                  name="nationality"
                  className="form-control"
                  placeholder="Enter Nationality"
                  value={info.nationality}
                  onChange={(e) => {
                    info.nationality = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                >
                  <option value="">Select Nationality</option>
                  <option value="INDIAN">Indian</option>
                  <option value="INTERNATIONAL">International</option>{" "}
                </select>
              </div>
            </div>
            {info?.nationality == "INTERNATIONAL" ? (
              <>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Country</label>
                    <select
                      type="text"
                      name="country"
                      className="form-control"
                      value={info.country}
                      onChange={(e) => {
                        info.country = e.target.value;
                        setFlag1((flag1) => !flag1);
                      }}
                    >
                      <option value="">Select Country</option>
                      {countries &&
                        countries?.map((item, key) => {
                          return <option value={item}>{item}</option>;
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="">State</label>
                    <span style={{ color: "red" }}>*</span>
                    <select
                      type="text"
                      name="state"
                      className="form-control"
                      value={info.state}
                      onChange={(e) => {
                        info.state = e.target.value;
                        setFlag1((flag1) => !flag1);
                      }}
                    >
                      <option value="">Select State</option>
                      {international
                        ?.filter((s) => s.country == info?.country)[0]
                        ?.states?.map((item, key) => {
                          return <option value={item}>{item}</option>;
                        })}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label htmlFor="">State</label>
                  <select
                    type="text"
                    name="state"
                    className="form-control"
                    value={info.state}
                    onChange={(e) => {
                      info.state = e.target.value;
                      setFlag1((flag1) => !flag1);
                    }}
                  >
                    <option>Select State</option>
                    {indian &&
                      indian?.map((item, key) => {
                        return (
                          <option value={item?.state}>{item?.state}</option>
                        );
                      })}
                  </select>
                </div>
              </div>
            )}

            {info?.nationality == "INDIAN" ? (
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label htmlFor="">District</label>
                  <span style={{ color: "red" }}>*</span>
                  <select
                    type="text"
                    name="district"
                    className="form-control"
                    value={info.district}
                    onChange={(e) => {
                      info.district = e.target.value;
                      setFlag1((flag1) => !flag1);
                    }}
                  >
                    <option>Select District</option>
                    {indian &&
                      indian
                        ?.filter((s) => s.state == info?.state)[0]
                        ?.districts?.map((item, key) => {
                          return <option value={item}>{item}</option>;
                        })}
                  </select>
                </div>
              </div>
            ) : null}

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="">City</label>
                <input
                  type="text"
                  name="city"
                  placeholder="Enter City"
                  className="form-control"
                  value={info.city}
                  onChange={(e) => {
                    info.city = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                />
              </div>
            </div>
            {info?.nationality == "INTERNATIONAL" ? (
              <>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="validationCustom01">Passport Number</label>
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Passport Number"
                      value={info?.passport_no}
                      name="passport_no"
                      onChange={(e) => {
                        info.passport_no = e.target.value;
                        setFlag1((flag1) => !flag1);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="validationCustom01">Visa Number</label>
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Visa Number"
                      value={info?.visa_no}
                      name="visa_no"
                      onChange={(e) => {
                        info.visa_no = e.target.value;
                        setFlag1((flag1) => !flag1);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="validationCustom01">Visa Issue Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={info?.visa_issue}
                      name="visa_issue"
                      onChange={(e) => {
                        info.visa_issue = e.target.value;
                        setFlag1((flag1) => !flag1);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="validationCustom01">Visa Expiry Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={info?.visa_expiry}
                      name="visa_expiry"
                      onChange={(e) => {
                        info.visa_expiry = e.target.value;
                        setFlag1((flag1) => !flag1);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="validationCustom01">Current Address</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter Address"
                  value={info?.current_address}
                  name="current_address"
                  onChange={(e) => {
                    info.current_address = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label htmlFor="validationCustom01">Permanent Address</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter Address"
                  value={info?.permanent_address}
                  name="permanent_address"
                  onChange={(e) => {
                    info.permanent_address = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Aadhar Number</label>
                <input
                  required
                  type="text"
                  value={info?.aadhar_number}
                  readOnly={!flag ? true : false}
                  name="aadhar_number"
                  onChange={(e) => {
                    info.aadhar_number = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="Aadhar Number *."
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>10th Passing Month</label>
                <input
                  required
                  type="month"
                  value={info?.sslc_pass_month}
                  readOnly={!flag ? true : false}
                  onChange={(e) => {
                    info.sslc_pass_month = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  name="sslc_pass_month"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>10th Percentage</label>
                <input
                  required
                  type="text"
                  value={info?.sslc_perce}
                  readOnly={!flag ? true : false}
                  name="sslc_perce"
                  onChange={(e) => {
                    info.sslc_perce = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="10th Percentage *"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>10th Board of Exam</label>
                <input
                  required
                  type="text"
                  value={info?.sslc_board}
                  readOnly={!flag ? true : false}
                  name="sslc_board"
                  onChange={(e) => {
                    info.sslc_board = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="Board of Examination *"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>10th Seat Number</label>
                <input
                  required
                  type="text"
                  value={info?.sslc_seat_no}
                  readOnly={!flag ? true : false}
                  name="sslc_seat_no"
                  onChange={(e) => {
                    info.sslc_seat_no = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="Seat Number *"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>12th Passing Month</label>
                <input
                  required
                  type="month"
                  value={info?.pu_pass_month}
                  readOnly={!flag ? true : false}
                  name="pu_pass_month"
                  onChange={(e) => {
                    info.pu_pass_month = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="Student Mobile Number *"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>12th Percentage</label>
                <input
                  required
                  type="text"
                  value={info?.pu_perce}
                  readOnly={!flag ? true : false}
                  name="pu_perce"
                  onChange={(e) => {
                    info.pu_perce = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="12th Percentage *"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>12th Subject Combination</label>
                <input
                  required
                  type="text"
                  value={info?.pu_subjects}
                  readOnly={!flag ? true : false}
                  name="pu_subjects"
                  onChange={(e) => {
                    info.pu_subjects = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="Sub Combinations  *"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>12th Board of Exam</label>
                <input
                  required
                  type="text"
                  value={info?.pu_board}
                  readOnly={!flag ? true : false}
                  name="pu_board"
                  onChange={(e) => {
                    info.pu_board = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="Board of Examination *"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>12th Seat Number</label>
                <input
                  required
                  type="text"
                  value={info?.pu_seat_no}
                  readOnly={!flag ? true : false}
                  name="pu_seat_no"
                  onChange={(e) => {
                    info.pu_seat_no = e.target.value;
                    setFlag1((flag1) => !flag1);
                  }}
                  className="form-control"
                  placeholder="Seat Number *"
                />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <h5>Upload Student Image</h5>
                <input
                  required
                  type="file"
                  ref={fileref}
                  disabled={!flag ? true : false}
                  onChange={(e) => addImg(e)}
                  name="student_picture"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BasicInformation;
