import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import {
  GATE_PASS_REQUEST_QUERY,
  STUDENT_GATE_PASS_PUT1,
} from "../utils/InfoUploadingApiConstants";
function HostelModalStudentGatePass2({
  data,
  getData,
  setLoading,
  flag,
  setFlag,
  selectedItem,
}) {
  const [datas, setdata] = useState([]);
  console.log("dataa -", data);
  const [date1, fromdate] = useState();
  console.log("datas", datas);
  const [user, setUser] = useState({
    purpose_type: data?.purpose_type || "",
    out_from_date: data?.out_from_date || "",
    out_to_date: data?.out_to_date || "",
    no_of_days: data?.no_of_days || "",
    out_going_purpose: data?.out_going_purpose || "",
    request_remark: data?.request_remark || "",
    approved_by_1: data?.approved_by_1 || "",
    approved_date_1: data?.approved_date_1 || "",
    approval_status_1: data?.approval_status_1 || "",
    approved_by_2: data?.approved_by_2 || "",
    approved_date_2: data?.approved_date_2 || "",
    approval_status_2: data?.approval_status_2 || "",
    status: data?.status || "",
  });
  console.log("user", user);
  // Update state when data prop changes
  useEffect(() => {
    setUser({
      purpose_type: data?.purpose_type || "",
      out_from_date: data?.out_from_date || "",
      out_to_date: data?.out_to_date || "",
      no_of_days: data?.no_of_days || "",
      out_going_purpose: data?.out_going_purpose || "",
      request_remark: data?.request_remark || "",
      approved_by_1: data?.approved_by_1 || "",
      approved_date_1: data?.approved_date_1 || "",
      approval_status_1: data?.approval_status_1 || "",
      approved_by_2: data?.approved_by_2 || "",
      approved_date_2: data?.approved_date_2 || "",
      approval_status_2: data?.approval_status_2 || "",
      status: data?.status || "",
    });
  }, [data]);
  const getAllData = async () => {
    const configs = {
      method: "get",
      url: `${GATE_PASS_REQUEST_QUERY}/${data.id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios(configs)
      .then((res) => {
        clearData();
        setdata(res.data.data);
        // toast.success("Succesfully Uploaded Details");
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };
  useEffect(() => {
    getAllData();
  }, [selectedItem]);
  console.log("DATAS-", datas);
  console.log("selected student id-", selectedItem);
  const role1 = sessionStorage.getItem("role");
  
  const handleChange1 = (e) => {
  
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
 
  };

  const handleChange2 = (selectedValue) => {
  

    setUser((prev) => ({
      ...prev,
      approval_status_1: selectedValue,
      approval_status_2: selectedValue,
    }));
 
  };
  const handleSubmit = async () => {
    setLoading(1);

    const dataToSend = {
      ...user,
      no_of_days: days,
      approved_by_1: employee_id,
      approved_date_1: new Date(),

    };
    const config = {
      method: "put",
      url: `${STUDENT_GATE_PASS_PUT1}/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: dataToSend,
    };
    axios(config)
      .then((res) => {
        toast.success("Succesfully Uploaded Details");
        clearData();
        setFlag((flag) => !flag);
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };
  const handleSubmit2 = async () => {
    setLoading(1);

    const dataToSend = {
      ...user,
      no_of_days: days,
      approved_by_2: employee_id,
      approved_date_2: new Date(),

    };
    const config = {
      method: "put",
      url: `${STUDENT_GATE_PASS_PUT1}/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: dataToSend,
    };
    axios(config)
      .then((res) => {
        toast.success("Succesfully Uploaded Details");
        clearData();
        setFlag((flag) => !flag);
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const appstatus = user?.approval_status_1 ? user?.approval_status_1 : "";

 

  const handleSubmit3 = async () => {
  
    setLoading(1);

    const dataToSend = {
      ...user,
      no_of_days: days,
      approved_by_1: employee_id,
      approved_date_1: new Date(),
      approved_by_2: employee_id,
      approved_date_2: new Date(),
      status:data.status,
      approval_status_1:appstatus,
      approval_status_2:appstatus,
    };
    const config = {
      method: "put",
      url: `${STUDENT_GATE_PASS_PUT1}/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: dataToSend,
    };
    axios(config)
      .then((res) => {
        toast.success("Succesfully Uploaded Details");
        clearData();
        setFlag((flag) => !flag);
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };
  const handleSubmit4 = async () => {
  
    setLoading(1);

    const dataToSend = {
      ...user,
        status:"INACTIVE"
    };
    const config = {
      method: "put",
      url: `${STUDENT_GATE_PASS_PUT1}/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: dataToSend,
    };
    axios(config)
      .then((res) => {
        toast.success("Succesfully Uploaded Details");
        clearData();
        setFlag((flag) => !flag);
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  let alldata = datas[0];
  console.log("alldata", alldata);
 
  const clearData = () => {
    setUser({
      purpose_type: "",
      out_from_date: "",
      out_to_date: "",
      no_of_days: "",
      out_going_purpose: "",
      request_remark: "",
      approved_by_1: "",
      approved_date_1: "",
      approval_status_1: "",
      approved_by_2: "",
      approved_date_2: "",
      approval_status_2: "",
      Enrollment_No: "",
    });
    data.purpose_type = "";
    data.out_from_date = "";
    data.out_to_date = "";
    data.no_of_days = "";
    data.out_going_purpose = "";
    data.request_remark = "";
    data.approved_by_1 = "";
    data.approved_date_1 = "";
    data.approval_status_1 = "";
    data.approved_by_2 = "";
    data.approved_date_2 = "";
    data.approval_status_2 = "";
    data.Enrollment_No = "";
  };
  const calculateDays = () => {
    const fromDate = new Date(user.out_from_date);
    const toDate = new Date(user.out_to_date);
    if (!user.out_from_date || !user.out_to_date || fromDate > toDate) {
      return 0;
    }
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    const timeDifference = toDate.getTime() - fromDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    const numberOfDays = Math.round(daysDifference);
    return numberOfDays >= 0 ? numberOfDays : 0;
  };
  const days = calculateDays();
  const employee_id = sessionStorage.getItem("employee_id");
  console.log("data", data);

  return (
    <div className="StudentGatePass">
      <div
        className="modal fade"
        id="HostelStudentGatePass2"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered mw-100 w-75"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <br />

              <div className="row">
                <div className="col-12">
                  <div className="modal-header">
                    <h5 className="text-primary">EDIT STUDENT GATE PASS</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true" className="mr-4">
                        ×
                      </span>
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <br />

                      <div className="table-responsive">
                        <h6 className="ml-2 text-primary">STUDENT DETAILS</h6>
                        <table
                          id="datatable"
                          className="table table-bordered  nowrap table-hover"
                          style={{
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <br />
                            <tr>
                              <th>Enrollment No </th>
                              <td>{data.Enrollment_No}</td>
                              <th>Name </th>
                              <td>{data.Student_Name}</td>
                              <th>Faculty </th>
                              <td>{data.College_Name}</td>
                            </tr>
                            <tr>
                              <th>Department </th>
                              <td>{data.deptname_name}</td>
                              <th>Class </th>
                              <td>{data.acadamic_classname}</td>
                              <th>Semester </th>
                              <td>{data.name}</td>
                            </tr>
                            <tr>
                              <th>Session </th>
                              <td>{data.session_id}</td>
                              <th>Hostel </th>
                              <td>{data.hostel_name}</td>
                              <th>Floor No </th>
                              <td>{data.floor_number}</td>
                            </tr>
                            <tr>
                              <th>Room Type </th>
                              <td>{data.room_type}</td>
                              <th>Room No </th>
                              <td>{data.room_no}</td>
                              <th>Bed No</th>
                              <td>{data.bed_no}</td>
                            </tr>
                            <tr>
                              <th>
                                <p>Proctor Aproval</p>
                              </th>
                              <td colSpan={5}>
                                <p
                                  className={`mt-3 badge ${data?.approval_status_1 == "APPROVED"
                                    ? "badge-soft-success"
                                    : data?.approval_status_1 == "PENDING"
                                      ? "badge-soft-info"
                                      : data.approval_status_1 == "DECLINED"
                                        ? "badge-soft-warning"
                                        : data.approval_status_1 == "CANCELLED"
                                          ? "badge-soft-danger"
                                          : data.approval_status_1 == "APPROVED" &&
                                            data.approval_status_1 == "PENDING"
                                            ? "badge-soft-primary"
                                            : ""
                                    }`}
                                  style={{
                                    width: "90px",
                                    height: "30px",
                                    padding: "0px 15px",
                                    textAlign: "center",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  {data.approval_status_1}
                                  <span
                                    style={{
                                      marginLeft: "160px",
                                      fontSize: "16px",
                                    }}
                                  ></span>
                                </p>
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {role1 == "STAFF" || role1 == "SUPERADMIN" || role1 == "WARDEN"? (
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title text-info">
                          <b>Request Detail</b>
                        </h2>
                        <br />
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Out Going Purpose Type
                              </label>
                              <select
                                name="purpose_type"
                                id="class"
                                className="form-control"
                                value={
                                  user?.purpose_type
                                    ? user?.purpose_type
                                    : data.purpose_type
                                }
                                readOnly
                                onChange={(e) => {
                                  handleChange1(e);
                                }}
                              >
                                <option value="">Select Purpose Type</option>
                                <option value="LEAVE">Leave</option>
                                <option value="GENERAL">General</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Out Going Purpose
                              </label>
                              <input
                                type="text"
                                name="out_going_purpose"
                                className="form-control"
                                placeholder="Enter Out Going Purpose"
                                readOnly
                                value={
                                  user?.out_going_purpose
                                    ? user?.out_going_purpose
                                    : data.out_going_purpose
                                }
                                onChange={(e) => {
                                  handleChange1(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Request Remark
                              </label>
                              <input
                                type="text"
                                name="request_remark"
                                className="form-control"
                                placeholder="Enter Request Remark"
                                value={
                                  user?.request_remark
                                    ? user?.request_remark
                                    : data.request_remark
                                }
                                readOnly
                                onChange={(e) => {
                                  handleChange1(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Out Going Period
                              </label>
                              <div className="row">
                                {role1=="STAFF" || role1=="SUPERADMIN" ?(
                                  <>
                                   <div className="col-md-4">
                                  <label htmlFor="validationCustom02">
                                    From Date
                                  </label>
                                  <input
                                    type="date"
                                    name="out_from_date"
                                    className="form-control"
                                    placeholder="From Date"
                                    value={
                                      user?.out_from_date
                                        ? user?.out_from_date
                                        : data.out_from_date
                                    }
                                    
                                    onChange={(e) => {
                                      handleChange1(e);
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label htmlFor="validationCustom02">
                                    To Date
                                  </label>
                                  <input
                                    type="date"
                                    name="out_to_date"
                                    className="form-control"
                                    placeholder="To Date"
                                    value={
                                      user?.out_to_date
                                        ? user?.out_to_date
                                        : data.out_to_date
                                    }
                                    
                                    onChange={(e) => {
                                      handleChange1(e);
                                    }}
                                  />
                                </div>
                                  </>
                                ):(<>
                                 <div className="col-md-4">
                                  <label htmlFor="validationCustom02">
                                    From Date
                                  </label>
                                  <input
                                    type="date"
                                    name="out_from_date"
                                    className="form-control"
                                    placeholder="From Date"
                                    value={
                                      user?.out_from_date
                                        ? user?.out_from_date
                                        : data.out_from_date
                                    }
                                    readOnly
                                    onChange={(e) => {
                                      handleChange1(e);
                                    }}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label htmlFor="validationCustom02">
                                    To Date
                                  </label>
                                  <input
                                    type="date"
                                    name="out_to_date"
                                    className="form-control"
                                    placeholder="To Date"
                                    value={
                                      user?.out_to_date
                                        ? user?.out_to_date
                                        : data.out_to_date
                                    }
                                    readOnly
                                    onChange={(e) => {
                                      handleChange1(e);
                                    }}
                                  />
                                </div>
                                </>)}
                               
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label htmlFor="validationCustom02">
                                      No of Days
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={calculateDays()}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                        <hr />
                        <h2 className="card-title text-info">
                          <b>Approval Detail</b>
                        </h2>
                        <br />

                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">

                              {role1 == "STAFF" ? (<>
                                <label htmlFor="validationCustom02">
                                  Approval Status
                                </label>
                                <select
                                  name="approval_status_1"
                                  id="class"
                                  className="form-control"
                                  value={
                                    user?.approval_status_1
                                      ? user?.approval_status_1
                                      : data.approval_status_1
                                  }
                                  onChange={(e) => {
                                    handleChange1(e);
                                  }}
                                >
                                  <option value="">Select Approval Status</option>
                                  <option value="APPROVED">Approve</option>
                                  <option value="PENDING">Pending</option>
                                  <option value="DECLINED">Decline</option>
                                  <option value="CANCELLED">Cancel</option>
                                </select></>
                              ) : (<></>)}
                              {role1 == "WARDEN" ? (
                                <>
                                  <label htmlFor="validationCustom02">
                                    Approval Status
                                  </label>
                                  <select
                                    name="approval_status_2"
                                    id="class"
                                    className="form-control"
                                    value={
                                      user?.approval_status_2
                                        ? user?.approval_status_2
                                        : data.approval_status_2
                                    }
                                    onChange={(e) => {
                                      handleChange1(e);
                                    }}
                                  >
                                    <option value="">Select Approval Status</option>
                                    <option value="APPROVED">Approve</option>
                                    <option value="PENDING">Pending</option>
                                    <option value="DECLINED">Decline</option>
                                    <option value="CANCELLED">Cancel</option>
                                  </select>
                                </>
                              ) : (<></>)}
                              {role1 == "SUPERADMIN" ? (
                                <>

                                  <div className="row">
                                    <div className="col-md-12">
                                      <label htmlFor="validationCustom02">
                                         Status
                                      </label>
                                      <select
                                        name="approval_status_1"
                                        id="class"
                                        className="form-control"
                                     
                                        value={
                                          user?.approval_status_1 
                                            ? user?.approval_status_1: data.approval_status_1 
                                          
                                        }
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;
                                          handleChange2(selectedValue);

                                        }}
                                      >
                                        <option value="">Select Approval Status</option>
                                        <option value="APPROVED">Approve</option>
                                        <option value="PENDING">Pending</option>
                                        <option value="DECLINED">Decline</option>
                                       
                                      </select>
                                    </div>

                                    {/* <div className="col-md-6">
                                      <label htmlFor="validationCustom02">
                                        Warden Status
                                      </label>
                                      <select
                                        name="approval_status_2"
                                        id="class"
                                        className="form-control"
                                        value={
                                          user?.approval_status_2
                                            ? user?.approval_status_2
                                            : data.approval_status_2
                                        }
                                        onChange={(e) => {
                                          handleChange1(e);
                                        }}
                                      >
                                        <option value="">Select Approval Status</option>
                                        <option value="APPROVED">Approve</option>
                                        <option value="PENDING">Pending</option>
                                        <option value="DECLINED">Decline</option>
                                        <option value="CANCELLED">Cancel</option>
                                      </select>
                                    </div> */}
                                  </div>
                                </>
                              ) : (<></>)}

                            </div>
                          </div>
                        </div>

                        <br />
                        <br />
                        <div className="row float-right mr-3">
                          {role1 == "STAFF" ? (
                            <button
                              className="btn btn-success float-right"
                              id="submit"
                              aria-label="Close"
                              type="button"
                              data-dismiss="modal"
                              onClick={() => {
                                handleSubmit();
                              }}
                            >
                              Submit
                            </button>
                          ) : (
                           <></>
                          )}
                          {role1 == "WARDEN" ?(
                            <>
                               <button
                              className="btn btn-success float-right"
                              id="submit"
                              aria-label="Close"
                              type="button"
                              data-dismiss="modal"
                              onClick={() => {
                                handleSubmit2();
                              }}
                            >
                              Submit
                            </button>
                            </>
                          ):(<></>)}
                          {role1 == "SUPERADMIN" ?(
                            <div className="row">

                              <button
                               className="btn btn-danger float-right mx-2"
                               id="submit"
                               aria-label="Close"
                               type="button"
                               data-dismiss="modal"
                               onClick={() => {
                                 handleSubmit4();
                               }}
                             >
                               Cancel
                             </button>
                                <button
                               className="btn btn-success float-right"
                               id="submit"
                               aria-label="Close"
                               type="button"
                               data-dismiss="modal"
                               onClick={() => {
                                 handleSubmit3();
                               }}
                             >
                               Submit
                             </button>

                            
                            </div>
                              
                          ):(<></>)}
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostelModalStudentGatePass2;
