import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Toggler from "../../../Components/Toggler/Toggler";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { ALL_DATA, EMPLOYEE, EMPLOYEE_DELETE, LEAVE_ENTITLEMENT } from "../../../utils/apiConstants";
import { HR_ASSIGN_WORKFLOW } from "../../../utils/apiConstants";
import { getDateMeta } from "@fullcalendar/react";
import '../Leave/TableScroll.css';

function ModalEntitlementAssign({
  empData,
  allData,
  edit,
  editType,
  reloadData,
  setLoading,
  CollegeId,
  displayData, 
  setDisplayData,
  getEmpData,
  leaveTypes,
}) {

    const [collegeOpt, setCollegeOpt] = useState(
            localStorage.getItem(LOCAL_COLLEGE)
        ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
        : null
    );

    var emp_id = sessionStorage.getItem("employee_id");

   const [promotes, setPromotes] = useState([]);
   const [selectAllChecked, setSelectAllChecked] = useState(false);
   const [searchName, setSearchName] = useState("");
   const [collegeSearch, setCollegeSearch] = useState("");

   let type = edit?.id;
   const [policy, setPolicy] = useState()
   const [fDate, setFDate] = useState()
   const [entitled, setEntitled] = useState()
   const [carried, setCarried] = useState()
   // const [loading,setLoading] = useState(0)

   const calculateDifference = (a1, a2) => {
       var Difference_In_Time = new Date(a2).getTime() - new Date(a1).getTime();

       var Difference_In_Days = Difference_In_Time / (1000 * 60 * 60 * 24);

       if (type != "DUTY" && type != "COMP") {
           setEntitled(entitledVAlue(Difference_In_Days + 1))
       }
   }

   const entitledVAlue = (d) => {
       return (parseInt(d) * 15 / 365).toFixed(2)
   }

   function getLastDayOfYear(year) {
       return new Date(year, 11, 31);
   }

   const [tDate, setTDate] = useState(getLastDayOfYear(new Date().getFullYear()).toISOString()?.split("T")[0])

   const toggleSelectAll = () => {
    setSelectAllChecked((prev) => !prev);
    const updatedPromotes = displayData?.filter((d) => {
        let leavetypes = d.leave_types?.split(',');
        let boole = 1;
        for(let i in leavetypes) {
            if(leavetypes[i] == edit?.id) {
                boole = 0;
            }
        }
        return boole;
    }).map((d) => ({
      id: d.id,
      data: d,
    }));
    setPromotes(selectAllChecked ? [] : updatedPromotes);
  };

   const togglePromotion = (id, data) => {

    const isPromoted = promotes?.some((student) => student.id === id);

    if (isPromoted) {
      setPromotes((prevPromotes) => prevPromotes?.filter((student) => student.id !== id));
    } else {
      setPromotes((prevPromotes) => [...prevPromotes, {id, data}]);
    }
  };

  const assign = async (id, emp) => {
    if(!id) {
        return toast.error("Unable to get Employee id");
    }

    if(!type || !policy || !entitled || !fDate || !tDate) {
        return toast.error("Please Enter all the details");
    }
    
    const data = {
        "employee_id": id,
        "leave_type": type,
        "earning_policy": policy,
        "from_date": fDate,
        "to_date": tDate,
        "entitled_days": entitled,
        "carried_over": carried ? carried : 0,
        "year": new Date().getFullYear()
    }

    console.log('data to backend - ', data);
    const config = {
        method: 'post',
        url: `${LEAVE_ENTITLEMENT}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
        },
        data: {
            ...data,
            college_id: emp.college_id
        }
    }

    setLoading(1);
    await axios(config)
        .then((res) => {
            toast.success('success')
            setLoading(0)
        })
        .catch(err => {
            toast.error(err.response.data.message)
            setLoading(0)
        })
        setLoading(0);
    setPromotes([]);
    getEmpData();
    ('#ModalEntitlementAssign').modal('hide');
  }


    const assignAll = async () => {
        if(promotes.length == 0) {
            return toast.error("Please Select Employees to assign");
        }
        if(!type || !policy || !entitled || !fDate || !tDate) {
            return toast.error("Please Enter all the details");
        }
        
        setLoading(1);
        let cnt = 0;
        for(let i in promotes) {
            const data = {
                "employee_id": promotes[i].id,
                "leave_type": type,
                "earning_policy": policy,
                "from_date": fDate,
                "to_date": tDate,
                "entitled_days": entitled,
                "carried_over": carried ? carried : 0,
                "year": new Date().getFullYear()
            }
            const config = {
                method: 'post',
                url: `${LEAVE_ENTITLEMENT}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
                },
                data: {
                    ...data,
                    college_id: promotes[i]?.data?.college_id
                }
            }
            setLoading(1);
            console.log('data to backend - ', data);
            await axios(config)
                .then((res) => {
                    cnt++;
                })
                .catch(err => {
                    toast.error(err.response.data.message)
                    setLoading(0)
                })
                setLoading(0);
        }
        setLoading(0);
        toast.success(`Assigned Entitlement to ${cnt} employees`);
        setPromotes([]);
        getEmpData();
        ('#ModalEntitlementAssign').modal('hide');
    }  
    
    const removeAssigned = async (id, emp) => {
        if(!id) {
            return toast.error("Unable to get Employee id");
        }
    
        console.log('id to be deleted - ', id);
        console.log('leave type - ', {
            leave_type: edit?.id
        });
        console.log('url - ',  `${LEAVE_ENTITLEMENT}/${id}`)
        const config = {
            method: 'delete',
            url: `${LEAVE_ENTITLEMENT}/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },
            data: {
                leave_type: edit?.id
            }
        }
    
        setLoading(1);
        await axios(config)
            .then((res) => {
                toast.success('successfully removed')
                setLoading(0)
            })
            .catch(err => {
                toast.error(err.response.data.message)
                setLoading(0)
            })
            setLoading(0);
        setPromotes([]);
        getEmpData();
        ('#ModalEntitlementAssign').modal('hide');
      }


    useEffect(() => {
        console.log('promotes - ', promotes);
    },[promotes]);

    useEffect(() => {
        let filteredData = [];
        if(collegeSearch) {
            console.log('hi1');
            console.log(collegeSearch);
            filteredData = empData.filter((item) => item.college_id == collegeSearch && 
          (item.first_name.toLowerCase().includes(searchName.toLowerCase()) || item.last_name.toLowerCase().includes(searchName.toLowerCase()) 
          || `${item.first_name} ${item.last_name}`.toLowerCase().includes(searchName.toLowerCase()))
        );
        }
        else {
            filteredData = empData?.filter((item) =>
          item.first_name.toLowerCase().includes(searchName.toLowerCase()) || item.last_name.toLowerCase().includes(searchName.toLowerCase()) 
          || `${item.first_name} ${item.last_name}`.toLowerCase().includes(searchName.toLowerCase())
        );
            if(emp_id == 502) {
                filteredData = filteredData?.filter((item) =>
                    item.college_id == 1111011);
            }
        }
        setDisplayData(filteredData);
      },[searchName]);

    useEffect(() => {
        let filteredData=[];
        if(collegeSearch) {
            if(searchName) {
                filteredData = empData.filter((item) => item.college_id == collegeSearch && 
          (item.first_name.toLowerCase().includes(searchName.toLowerCase()) || item.last_name.toLowerCase().includes(searchName.toLowerCase()) 
          || `${item.first_name} ${item.last_name}`.toLowerCase().includes(searchName.toLowerCase()))
        );
            }
            else {
                filteredData = empData.filter((item) =>
                item.college_id == collegeSearch)
            }
            setDisplayData(filteredData);
        }
        else {
            if(emp_id == 502) {
                filteredData = empData.filter((item) =>
                    item.college_id == 1111011);
                setDisplayData(filteredData);
            }
            else {
                setDisplayData(empData);
            }
        }
    },[collegeSearch]);

    return (
        <div className="ModalEntitlementAssign">
        <div className="ModalEarningPolicy">
            <div
            className="modal fade"
            id="ModalEntitlementAssign"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"

            >
            <div
                className="modal-dialog modal-dialog-centered mw-100 w-75"
                role="document"
            >
                <div className="modal-content ">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                    Assign Entitlement to Employees 
                    </h5>
                    <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                <h5 className="">Leave Type : {edit?.id}</h5>
                <br />
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <lable>
                                {" "}
                                Earning Policy <span style={{ color: "red" }}>*</span>
                            </lable>
                            <select
                                className='form-control'
                                value={policy}
                                onChange={(e) => { setPolicy(e.target.value) }}
                                // disabled={type == "COMP" ? true : false}
                            >
                                <option value="">Select Earning Policy</option>
                                {
                                    allData?.earningPolicy?.map((i, key) => (
                                        <option key={key} value={i.id}>{i.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <lable>
                                {" "}
                                Earning Start <span style={{ color: "red" }}>*</span>
                            </lable>
                            <input
                                type="date"
                                className="form-control"
                                value={fDate}
                                onChange={(e) => { setFDate(e.target.value); calculateDifference(e.target.value, tDate) }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <lable>
                                {" "}
                                Valid Until <span style={{ color: "red" }}>*</span>
                            </lable>
                            <input
                                type="date"
                                className="form-control"
                                value={tDate}
                                onChange={(e) => { setTDate(e.target.value); calculateDifference(fDate, e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <lable>
                                {" "}
                                Entiled Days <span style={{ color: "red" }}>*</span>
                            </lable>
                            <input
                                type="number"
                                className="form-control"
                                placeholder='Enter Entitled Days'
                                // readOnly={type=="COMP" ? true : false}
                                value={entitled}
                                onChange={(e) => { setEntitled(e.target.value) }}
                            />
                        </div>
                    </div>
                    {/* <div className="col-12">
                        <div className="form-group">
                            <lable>
                                {" "}
                                Carried Over
                            </lable>
                            <input
                                type="number"
                                className="form-control"
                                placeholder='Enter carried over Days'
                                value={carried}
                                onChange={(e) => { setCarried(e.target.value) }}
                            />
                        </div>
                    </div> */}
                </div>
                <hr />
                <div className="row">
                <div className="col-md-6 mt-3">
                    {emp_id != 502
                    ?
                    <div className="form-group">
                        <label htmlFor="college_id">
                        College
                        </label>
                        <select 
                        name="college_id" id="college_id" required="" className="form-control"
                        value={collegeSearch}
                        onChange={(e) => setCollegeSearch(e.target.value)}
                        >
                        <option value="">All</option>
                        {collegeOpt?.map((data, key) => {
                            return <option value={data.id}>{data.name}</option>
                        })}
                        </select>
                    </div>
                    :
                    <></>}
                </div>
                <div className="col-md-6 mt-3">
                        <label>
                            Search By Employee Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search By Employee Name"
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                </div>
                <br />
                <div className='col-md-12'>
                    <label>Assign Selected ({promotes.length}) : </label>
                    <button className="btn btn-success float-right mr-4"  
                    // data-dismiss="modal" aria-label="Close" 
                    onClick={assignAll}>Assign</button>
                </div>
                </div>
                <div className="table-responsive mt-3 fixTableHead">
                        <table className="table table-bordered" style={{}}>
                            <thead className="">
                            <tr>
                                <th>
                                <input
                                    type="checkbox"
                                    id="select-all"
                                    checked={selectAllChecked}
                                    onChange={toggleSelectAll}
                                />
                                </th>
                                <th>Sl No.</th>
                                <th>Name</th>
                                <th>College</th>
                                <th>Leave Types Assigned</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                                {displayData ? displayData?.map((i,k) => {
                                    return (
                                        <tr>
                                            <td>
                                            <input
                                                type="checkbox"
                                                name="checkbox[]"
                                                value={i.id}
                                                checked={promotes?.some((staff) => staff.id === i.id)}
                                                onChange={() => togglePromotion(i.id, i)}
                                                disabled={i.leave_types?.split(',').some((leaveType) => leaveType === edit?.id)}
                                            />
                                            </td>
                                            <td>{k+1}</td>
                                            <td>{`${i.first_name} ${i.last_name}`}</td>
                                            <td>{collegeOpt.find((s) => s.id == i.college_id)?.name}</td>
                                            <td>{i.leave_types ? i.leave_types?.split(',')?.map((i,k) =>  leaveTypes?.find((s) => s.id == i)?.code)?.join(', ') : <span className="badge badge-soft-danger">Not Assigned</span>}</td>
                                            <td>{i?.leave_approval_workflow_id ? <span className="badge badge-soft-success ml-3">Assigned</span> 
                                            : <><button className="btn btn-success ml-2"
                                                // data-dismiss="modal" aria-label="Close" s
                                                onClick={() => assign(i.id, i)} 
                                                disabled={(promotes.length > 0) || (i.leave_types?.split(',').some((leaveType) => leaveType === edit?.id))}>
                                                Assign
                                            </button>
                                            {(i.leave_types?.split(',').some((leaveType) => leaveType === edit?.id)) && 
                                                <button className="btn btn-danger ml-2"
                                                // data-dismiss="modal" aria-label="Close" 
                                                onClick={() => removeAssigned(i.id, i)} 
                                                disabled={(promotes.length > 0)}>
                                                Remove
                                                </button>

                                            }
                                            </>
                                            }
                                            </td>
                                        </tr>
                                    )
                                }) : <>Loading Employees...</>}
                            </tbody>
                        </table>
                        </div>
                    <div className="row d-flex justify-content-between px-2">
                    <button
                        className="btn btn-danger btn-rounded btn-outline ml-3"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-success btn-outline mr-3"
                        // data-dismiss="modal"
                        // aria-label="Close"
                        onClick={() => {
                        assignAll();
                        }}
                    >
                        Assign
                    </button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
}

export default ModalEntitlementAssign;
