import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { STUDENT_ADMISSION } from "../../../utils/apiConstants";
import { SESSION_ROLE } from "../../../utils/sessionStorageContants";
import { getFileUrl } from "./../../../Helpers/Helpers";
import {
  GET_ASSIGNED_PICKUPPOINTS,
  GET_TRANSPORT_FEES,
} from "../../../utils/Transport.apiConst";
import { indian } from "../../../Data/Countries/india";
import { international } from "../../../Data/Countries/international";
import { useSearchParams } from "react-router-dom";
import { ASSET_EMPLOYEE_IMAGE } from "./../../../utils/AssetsReferenceTypes";
import {
  TRANSPORT_ROUTE,
  PICKUP_POINTS,
} from "../../../utils/Transport.apiConst";
import {
  HOSTEL,
  HOSTEL_FLOORS,
  HOSTEL_ROOMS,
  HOSTEL_TYPE,
} from "../../../utils/Hostel.apiConst";
import { ADDHOSTELFEE } from "../../../utils/fees.apiConst";
import Select from "react-select";

import {
    LOCAL_COLLEGE,
    LOCAL_DEPARTMENT,
    LOCAL_PROGRAM,
  } from "../../../utils/LocalStorageConstants";

const StudentCredentials = ({
  id,
  setLoading,
  collegeId,
  studentDetails,
  setStudentDetails,
  flag,
}) => {

  const [user, setUser] = useState();

  

  const [role, setRole] = useState(sessionStorage.getItem(SESSION_ROLE));
  const [editor, setEditor] = useState(true);

  useEffect(() => {
    setRole(sessionStorage.getItem(SESSION_ROLE));
  }, [sessionStorage.getItem(SESSION_ROLE), flag]);

  useEffect(() => {
    if (role == "ADMIN" || role == "SUPERADMIN") {
      setEditor(false);
    } else {
      setEditor(true);
    }
  }, [role, flag]);

  
  const [componentLoaded, setComponentLoaded] = useState(false);
  useEffect(() => {
      if (!componentLoaded) {
        console.log(studentDetails);
        setUser(studentDetails);
        setComponentLoaded(true);
      }
    }, [componentLoaded]);
  return (
    <div className="BasicInformation">
        <div className="p-3">
            <h6>Student Credentials :</h6>
            <hr />
            <table>
                <tr>
                    <td width="30%">Username</td>
                    <td width="50%">: {studentDetails?.user_id}</td>
                    
                </tr>
                <tr>
                    <td>Password</td>
                    <td>: {studentDetails?.password}</td>
                    
                </tr>
            </table>
        </div>
    </div>
  );
};

export default StudentCredentials;
