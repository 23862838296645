import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../../Components/Loader/Loader";
import ModalLeaveReview from "../../../../modals/HR/Leave/ModalLeaveReview";
import {
  LEAVE_APPLICATION,
  LEAVE_ENTITLEMENT,
  LEAVE_REVIEW_EMPLOYEE,
  LEAVE_APPROVER_APPLICATION,
  LEAVE_REVIEW_EMPLOYEE_SQL,
  LEAVE_LEAVE_TYPE,
} from "../../../../utils/apiConstants";
import {
  ALL_DATA,
  LOCAL_COLLEGE,
} from "../../../../utils/LocalStorageConstants";
import "./../Leave.scss";
import NoData from "./../../../../Components/NoData/Nodata";
import { SESSION_EMPLOYEE_ID } from "../../../../utils/sessionStorageContants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import Nodata from "./../../../../Components/NoData/Nodata";
import { Radio, Timeline } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

function LeaveReview({ setLoading, collegeId }) {
  const [data, setData] = useState();
  const [edit, setEdit] = useState();
  const [entitlement, setEntitlement] = useState();

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      console.log("error while formating date :: ", dateString);
      return dateString;
    }
  }

  const getEmployeeId = () => {
    return sessionStorage.getItem(SESSION_EMPLOYEE_ID)
      ? sessionStorage.getItem(SESSION_EMPLOYEE_ID)
      : null;
  };

  const [employeeId, setEmployeeId] = useState(getEmployeeId());

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [leaveType, setLeaveType] = useState([]);

  const getLeaveData = async () => {
    // setLoading(1);
    const config = {
      method: "get",
      url: LEAVE_LEAVE_TYPE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLeaveType(res.data.data);
        console.log("leave types - ", res.data.data);
      })
      .catch((err) => {
        // setLoading(0);
        console.log(err);
      });
  };

  const getEntitlement = async (i) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${LEAVE_ENTITLEMENT}?employee_id=${i?.employee_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("entitlements - ", res.data.data);
        setEntitlement(res.data.data);
        console.log(res);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };

  const employee = JSON.parse(localStorage.getItem(ALL_DATA)).employee;

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${LEAVE_REVIEW_EMPLOYEE_SQL}/${employeeId}?college_id=${collegeId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then(async (res) => {
        console.log("review applications - ", res.data.data);
        setLoading(0);
        console.log("leave applications - ", res.data.data);
        let tempData = res.data.data;
        let finalData = [];
        for (let i in tempData) {
          let approvals = [];
          let config1 = {
            method: "get",
            url: `${LEAVE_APPROVER_APPLICATION}/${tempData[i].id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
          };
          setLoading(1);
          await axios(config1)
            .then((res) => {
              setLoading(0);
              approvals = res.data.data;
              let num = approvals.find(
                (s) => s.employee_id == employeeId
              )?.approver_num;
              let permission = true;
              let before_approver = "";
              for (let k in approvals) {
                if (
                  approvals[k].approver_num < num &&
                  approvals[k].status == "ACTIVE"
                ) {
                  permission = false;
                  before_approver = `${approvals[k].first_name} ${approvals[k].last_name}`;
                  break;
                }
              }
              let obj = {
                ...tempData[i],
                approvals,
                permission,
                before_approver,
              };
              finalData.push(obj);
            })
            .catch((err) => {
              setLoading(0);
              toast.error(err.response.data.message);
            });
        }
        console.log("final data - ", finalData);
        setData(finalData);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err.response.data.message);
        toast.error("Error while loading");
      });
  };

  useEffect(() => {
    getData();
    // getEntitlement();
    getLeaveData();
  }, []);

  useEffect(() => {
    setEmployeeId(getEmployeeId());
  }, [sessionStorage.getItem(SESSION_EMPLOYEE_ID)]);

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: " Leave Review Report List ",
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  // const handlePrint = () => {
  //   PrintRecipt();
  // };

  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      tableRef.current.style.display = "none";
    }, 0.5); // Adjust the delay as needed
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  return (
    <div className="LeaveReview Leave-Report">
      <ModalLeaveReview
        empId={employeeId}
        data={edit}
        reloadData={(d) => getData(d)}
        setLoading={setLoading}
        entitlement={entitlement}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Review</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">Leave</a>
                      </li>
                      <li className="breadcrumb-item active">Review</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="row  p-3">
                    <button
                      className="btn btn-primary rounded-pill ml-1 float-right"
                      onClick={onDownload}
                    >
                      Export Excel
                    </button>
                    <button
                      className="btn btn-primary rounded-pill ml-2"
                      onClick={handlePrint}
                    >
                      Export PDF
                    </button>
                  </div>

                  <></>

                  <div>
                    {data && data?.length !== 0 ? (
                      data?.map((i, key) => (
                        <div className="row my-3 mx-2 p-3 border rounded shadow report-div cursor-normal">
                          <div className="col-12 row" key={key}>
                            <div className="report-title col-12">
                              {employee?.find((j) => j.id === i.employee_id)
                                ?.first_name +
                                " " +
                                employee.find((j) => j.id === i.employee_id)
                                  ?.last_name}
                            </div>
                            <div className="col-12 row d-flex flex-nowrap justify-content-between align-items-center role-parts">
                              <div className="col-6 row">
                                <div className="align-self-start text-center col-6">
                                  <div>
                                    {formatDate(i.from_date?.split("T")[0])}
                                  </div>
                                  <div>&darr;</div>
                                  <div>
                                    {formatDate(i.to_date?.split("T")[0])}
                                  </div>
                                  <div
                                    className={`${
                                      i?.status === "PENDING"
                                        ? "text-warning"
                                        : i?.status === "APPROVED"
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                  >
                                    {i?.status}
                                  </div>
                                </div>
                                <div className="d-flex mt-4">
                                  {leaveType?.find((s) => s.id == (i?.approvals[0]?.leave_type))?.code}
                                </div>
                                <div className="col-2">
                                  <div className="d-flex">{i?.session}</div>
                                  <button className=" w-auto btn btn-dark p-1">
                                    {i?.number_of_days} Day
                                  </button>
                                </div>
                              </div>
                              <div className="col-4">
                                <div>
                                  <Timeline
                                    mode="left"
                                    items={i?.approvals?.map((j, k) => {
                                      let label =
                                        j?.status == "ACTIVE"
                                          ? "Pending"
                                          : formatDate(
                                              j?.updatedAt.split("T")[0]
                                            );

                                      let name = `${j?.first_name} ${j?.last_name}`;
                                      let color =
                                        j?.status == "ACTIVE"
                                          ? "#fcb92c"
                                          : j?.status == "APPROVED"
                                          ? "green"
                                          : "red";
                                      if (j?.status == "ACTIVE") {
                                        return {
                                          dot: (
                                            <ClockCircleOutlined
                                              style={{ fontSize: "15px" }}
                                            />
                                          ),
                                          label: name,
                                          children: label,
                                          color: color,
                                        };
                                      } else {
                                        return {
                                          label: name,
                                          children: label,
                                          color: color,
                                        };
                                      }
                                    })}
                                  />
                                  {/* {i?.approvals?.map((j,k) => {
                            return (<><p style={{fontSize:"0.8rem"}}>
                              Approver {`${j?.approver_num} : ${j?.first_name} ${j?.last_name}`} {j?.status == "ACTIVE" ? <span className="badge badge-soft-warning">PENDING</span> : j?.status == "APPROVED" ? <span className="badge badge-soft-success">APPROVED</span> : <span className="badge badge-soft-danger">{`${j?.status}`}</span>} <br /> 
                              <span>Date : {j?.status == "ACTIVE" ? "--" : formatDate(j?.updatedAt.split('T')[0])}</span>
                            </p>
                            
                            </>
                            )
                          })} */}
                                </div>
                              </div>
                              <div className="col-2 d-flex justify-content-between">
                                <div className=" d-flex justify-content-end align-items-center">
                                  <button
                                    type="button"
                                    class="btn btn-nex"
                                    data-toggle="modal"
                                    data-target="#ModalLeaveReview"
                                    onClick={() => {
                                      setEdit(i);
                                      getEntitlement(i);
                                    }}
                                  >
                                    <i className="ri-pencil-fill"></i>
                                  </button>
                                </div>
                                {i?.attachment &&
                                i?.attachment?.split(",")[0]?.length > 1 ? (
                                  <div className=" d-flex justify-content-end align-items-center">
                                    <a
                                      target={"_blank"}
                                      href={i?.attachment?.split(",")[0]}
                                      className="btn btn-nex"
                                      rel="noreferrer"
                                    >
                                      <i className="ri-attachment-2"></i>
                                    </a>
                                  </div>
                                ) : i?.attachment?.split(",")[1]?.length ==
                                  0 ? (
                                  <>
                                    <div className=" d-flex justify-content-end align-items-center">
                                      <a
                                        target={"_blank"}
                                        href={i?.attachment?.split(",")[0]}
                                        className="btn btn-nex"
                                        rel="noreferrer"
                                      >
                                        <i className="ri-attachment-2"></i>
                                      </a>
                                    </div>
                                    {i?.attachment?.split(",")[1] ? (
                                      <div className=" d-flex justify-content-end align-items-center">
                                        <a
                                          target={"_blank"}
                                          href={i?.attachment?.split(",")[1]}
                                          className="btn btn-nex"
                                          rel="noreferrer"
                                        >
                                          <i className="ri-attachment-2"></i>
                                        </a>
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <NoData />
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>

              <table
                id=""
                ref={tableRef}
                className="table table-bordered"
                style={{
                  borderCollapse: "collapse",
                  borderSpacing: 0,
                  width: "100%",
                  display: "none",
                }}
              >
                <div className="col-12 text-center">
                  {/* {data?.length > 0 && (
    <div>
      <h4 className="text-center">
      Leave Review List -
        {data[0]?.college_id && collegeOpt?.find((s) => s.id === data[0]?.college_id)?.name}
      </h4>
     <span> <p className="text-right float-right"> Printed On - {" " + getCurrentDate()} </p></span>
    </div>
)} */}
                  <thead>
                    <tr>
                      <td colSpan={10}>
                        <div>
                          <h4 className="text-center">
                            Leave Review List -{" "}
                            {data?.length > 0 &&
                              data[0]?.college_id &&
                              collegeOpt?.find(
                                (s) => s.id === data[0]?.college_id
                              )?.name}
                          </h4>
                          <span>
                            <p className="text-right float-right">
                              Printed On - {getCurrentDate()}
                            </p>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Sl.No.</th>
                      <th>Employee Name</th>
                      <th>From Date</th>
                      <th>To Date</th>
                      <th>Approval Status</th>
                      <th>Session</th>
                      <th>Number Of Days</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length == 0 ? (
                      <tr>
                        <td colSpan={10}>
                          <Nodata />
                        </td>
                      </tr>
                    ) : (
                      data?.map((i, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              {employee?.find((j) => j.id === i.employee_id)
                                ?.first_name +
                                " " +
                                employee.find((j) => j.id === i.employee_id)
                                  ?.last_name}
                            </td>
                            <td> {i.from_date?.split("T")[0]}</td>
                            <td> {i.to_date?.split("T")[0]}</td>
                            <td>{i?.status}</td>
                            <td>{i?.session}</td>
                            <td>{i?.number_of_days}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </div>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaveReview;
