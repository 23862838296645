import React, { useEffect } from "react";
import Calender from "../../../Components/Calender/Calender";
import "./DashboardEmployee.scss";
import Chart from 'react-apexcharts';
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import { List } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "../../Dashboard/Dashboard.css";
import { ROUTES } from "../../../Router/routerConfig";
import { ADD_EVENT, ANNOUNCEMENTS, EMPLOYEE, EMPLOYEE_DETAILS_BASIC, EMPLOYEE_FROM_USERS, MEDICAL_SUPERADMIN_DASHBOARD, STUDENT_ADMISSION_ENQUIRRY, SUPERADMIN_DASHBOARD } from "../../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import { SESSION_EMPLOYEE_ID } from "../../../utils/sessionStorageContants";
import { LOCAL_COLLEGE, LOCAL_DEPARTMENT, LOCAL_PROGRAM } from "../../../utils/LocalStorageConstants";
import ModalAdmissionEnquirry from "../../../modals/Students/ModalAdmissionEnquirry";


function DashboardAd_Co({ setLoading }) {
    let employe_login_name = sessionStorage.getItem("emp_name");
    const [empdata, setempdata] = useState([])
    console.log("empdata", empdata)
    const getusersemployee = async () => {
        const config = {
            method: "get",
            url: `${EMPLOYEE_FROM_USERS}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },

        };
        await axios(config)
            .then((res) => {
                console.log(res);
                // toast.success("Data Success");
                setempdata(res.data.data);


            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data.message);
                setLoading(0);
            });
    }
    useEffect(() => {
        getusersemployee()
    }, [])
    let empid = sessionStorage.getItem("employee_id")
    const matchingEmployee = empdata.find(emp => emp.id == parseInt(empid));

    // Initialize the role
    let emp_role = '';

    if (matchingEmployee) {
        // Get the role
        emp_role = matchingEmployee.role;

        // If the role is AD-CON, set it to Admission Co-ordinator
        if (emp_role === "AD-CON") {
            emp_role = "Admission Co-ordinator";
        }
    }
    const navigate = useNavigate();

    const [user, setUser] = useState();
    const [userId, setUserId] = useState();

    const getEmployeeId = () =>
        sessionStorage.getItem(SESSION_EMPLOYEE_ID)
            ? sessionStorage.getItem(SESSION_EMPLOYEE_ID)
            : null;

    const id = getEmployeeId();

    const [greeting, setGreeting] = useState("");
    const [announcementTab, setAnnouncementTab] = useState("announcements");
    const [enquiryTab, setenquiry] = useState("enquiry")

    const [links, setLinks] = useState([
        {
            name: "Online Enquiry",
            link: "con/onlineEnquiry",
        },

    ]);

    const getEmpData = async () => {
        setLoading(1);
        const config = {
            method: "get",
            url: `${EMPLOYEE_DETAILS_BASIC}/${id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
        };

        await axios(config)
            .then((res) => {
                console.log(res);
                // toast.success("Data Success");
                setUser(res.data.data);
                setUserId(res.data.data.user_id);
                setLoading(0);
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data.message);
                setLoading(0);
            });
    };

    useState(() => {
        const currentTime = new Date().getHours();
        let greetingMessage;

        if (currentTime < 12) {
            greetingMessage = "Good Morning";
        } else if (currentTime < 18) {
            greetingMessage = "Good Afternoon";
        } else {
            greetingMessage = "Good Evening";
        }

        setGreeting(greetingMessage);
    }, []);

    const optionsEmployee1 = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false,
        },
        stroke: {
            width: [1, 1],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [1, 2, 3]
        },
        fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        plotOptions: {
            bar: {
                columnWidth: "15%",
            },
        },
        labels: ['ENG', 'MED', 'AYU', 'LAW', 'NUR', 'ART', 'COM',
            'ITC'
        ],
        markers: {
            size: 0
        },
        yaxis: {
            title: {
                text: 'Student Count',
            },
            min: 0
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }
        },
        colors: ['#1B2850', 'green', 'red'],
    };
    const colors = ["#364277", "#FF9F43"];

    var emp_id = sessionStorage.getItem("employee_id");
    const [data, setData] = useState();
    console.log("DATA", data)
    const getDashboardData = async () => {

        const config = {
            method: "get",
            url: emp_id == 502 ? `${MEDICAL_SUPERADMIN_DASHBOARD}` : `${SUPERADMIN_DASHBOARD}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
        };

        setLoading(1);
        await axios(config)
            .then((res) => {
                setLoading(0);
                console.log("dashboard data - ", res.data);
                setData(res.data);
            })
            .catch((err) => {
                setLoading(0);
                console.log(err);
                // toast.error(err.response.data.message);
            });
    }
    useEffect(() => {

        getDashboardData();
    }, []);
    const [allData, setAllData] = useState([]);
    const getAlldata = async () => {
        const config = {
            method: "get",
            url: `${ANNOUNCEMENTS}?type=announcement`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
        };

        axios(config)
            .then((res) => {
                res.data.data.sort((a, b) => b.id - a.id);

                setAllData(res.data.data);
            })
            .catch((err) => {
                console.log(err);
                toast.error("Something Went Wrong");
            });
    };

    useEffect(() => {
        getAlldata();
    }, [])


    const [eventdata, seteventData] = useState()
    let collegeids = sessionStorage.getItem("college_id")
    const geteventdata = async () => {
        try {
            const config = {
                method: "get",
                url: `${ADD_EVENT}?type=event`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                },
            };

            const res = await axios(config);
            seteventData(res.data.data);
        } catch (err) {
            console.error(err);
            toast.error("Something Went Wrong");
        }
    };
    useEffect(() => {
        geteventdata()
    }, [])

    const getColleges = () => {
        return localStorage.getItem(LOCAL_COLLEGE)
            ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
            : null;
    };
    const [collegeOpt, setCollegeOpt] = useState(getColleges);

    useEffect(() => {
        setCollegeOpt(getColleges);
    }, [localStorage.getItem(LOCAL_COLLEGE)]);
    const [employeeId, setEmployeeId] = useState(
        sessionStorage.getItem(SESSION_EMPLOYEE_ID)
    );
    const [empColleges, setEmpColleges] = useState([])

    const [empDetails, setEmpDetails] = useState();

    const getEmployee = async () => {
        const config = {
            method: "get",
            url: `${EMPLOYEE}/${employeeId}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
        };

        await axios(config)
            .then((res) => {
                let clgs = JSON.parse(res.data.data['multi_clg_id']);
                setEmpColleges(collegeOpt.filter(item => clgs?.includes(item.id)));
                console.log('emp details - ', res.data.data);
                setEmpDetails(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (employeeId) getEmployee();
    }, [employeeId]);




    const mappedData = allData?.map(item => {
        let staffData = [];
        try {
            staffData = item.staff ? JSON.parse(item.staff) : [];
        } catch (e) {
            console.error("Error parsing staff data for item:", item.id, e);
        }

        // Ensure staffData is an array
        if (!Array.isArray(staffData)) {
            staffData = [];
        }

        return { ...item, staffData };
    });

    let collegeIdss = sessionStorage.getItem("college_id")
    const filteredData = mappedData?.filter(item =>
        item.staffData.some(data => {

            const collegeIdArray = Array.isArray(data.college_id) ? data.college_id : [];
            const isCollegeIdAll = collegeIdArray.some(college => college.value === "All");
            const isCollegeIdMatch = collegeIdArray.some(college => collegeIdss.includes(parseInt(college.value)));
            return isCollegeIdAll || isCollegeIdMatch;
        })
    );











    // console.log("Filtered data:", filteredData);


    const seriesEmployee1 = [{
        name: 'Total Students',
        type: 'column',
        data: [44, 55, 41, 67, 22, 43, 21, 41],
        color: "#4D44B5"
    }, {
        name: 'Present',
        type: 'area',
        data: [23, 11, 22, 27, 13, 22, 37, 21],
        color: "green"
    }, {
        name: 'Absent',
        type: 'line',
        data: [30, 25, 36, 30, 45, 35, 64, 52],
        color: "red"
    }]

    //Chart Data 1
    const overviewChartOptions = {
        series: [0, 0, 0, 0, 0],
        labels: ["Ayurveda", "Management", "Engineering", "Law", "Arts"],
        chart: {
            width: 180,
            type: "donut",
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 270,
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: "gradient",
        },
        legend: {
            formatter: function (val, opts) {
                return val + " - " + opts.w.globals.series[opts.seriesIndex];
            },
        },

        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100px",
                        height: "100px",
                    },
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    };

    //Chart Data 2
    const overviewChartOptions2 = {
        series: [
            {
                name: "Male",
                data: [0, 0, 0, 0, 0, 0, 0],
            },
            {
                name: "Female",
                data: [0, 0, 0, 0, 0, 0, 0],
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 430,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: "top",
                    },
                },
            },
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: "12px",
                    colors: ["#fff"],
                },
            },
            stroke: {
                show: true,
                width: 1,
                colors: ["#fff"],
            },
            tooltip: {
                shared: true,
                intersect: false,
            },
            xaxis: {
                categories: [
                    "Ayrveda",
                    "Engineering",
                    "Law",
                    "Dental",
                    "Management",
                    "Arts",
                    "Commerce",
                ],
            },
        },
    };
    const formatMonthYear = (date) => {
        const d = new Date(date);
        const moptions = { day: '2-digit', month: 'short', year: 'numeric' };
        return d?.toLocaleDateString('en-US', moptions);
    };
    const [penddata, setpendData] = useState();

    const getData = async () => {
        setLoading(1);
        const config = {
            method: "get",
            url:
                STUDENT_ADMISSION_ENQUIRRY +
                // `?college_id=${collegeId}&&status=${status}`,
                `?college_id=${collegeids}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
        };
        await axios(config)
            .then((res) => {
                const filteredData = res.data.data ? res.data.data?.slice(0, 5) : "";
                setpendData(filteredData);
                console.log("res.data.data -", res.data.data);
                setLoading(0);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                setLoading(0);
            });
    };
    useEffect(() => {
        getData();
    }, []);
    console.log("penddata -", penddata);
    const [edit, setEdit] = useState();
    const getLocalColleges = () => {
        return localStorage.getItem(LOCAL_COLLEGE)
            ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
            : null;
    };
    const [localColleges] = useState(getLocalColleges());

    const getLocalPrograms = () => {
        return localStorage.getItem(LOCAL_PROGRAM)
            ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
            : null;
    };

    const [localPrograms] = useState(getLocalPrograms());
    const getLocalDepartment = () => {
        return localStorage.getItem(LOCAL_DEPARTMENT)
            ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
            : null;
    };
    const [localDepartment] = useState(getLocalDepartment());
    return (
        <div>
            <div className="DashboardEmployee">
                <ModalAdmissionEnquirry
                    data={edit}
                    setLoading={setLoading}
                    reloadData={getData}
                />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 ml-3">
                                            {greeting}, {sessionStorage.getItem("emp_name")}
                                        </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">Home</li>
                                                <li className="breadcrumb-item active">Dashboard</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-8">

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="card" style={{ borderRadius: "10px", backgroundColor: "#FF9F43", color: "white" }}>
                                                    <div className="card-body" style={{}}>
                                                        <div className="media">
                                                            <div className="media-body overflow-hidden">
                                                                <p className="text-truncate font-size-14 mb-2">
                                                                    {" "}
                                                                    Present
                                                                </p>
                                                                <h4 className="mb-0" style={{ color: "white" }}><b>10</b></h4>
                                                            </div>
                                                            <div style={{ color: "white" }}>
                                                                <i className="ri-check-line font-size-48" style={{ fontSize: "35px", color: "white" }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="card" style={{ borderRadius: "10px", backgroundColor: "#364277", color: "white" }}>
                                                    <div className="card-body" style={{}}>
                                                        <div className="media">
                                                            <div className="media-body overflow-hidden">
                                                                <p className="text-truncate font-size-14 mb-2">
                                                                    {" "}
                                                                    Absent
                                                                </p>
                                                                <h4 className="mb-0" style={{ color: "white" }}><b>2</b></h4>
                                                            </div>
                                                            <div style={{ color: "white" }}>
                                                                <i className="ri-close-line font-size-48" style={{ fontSize: "35px" }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="card" style={{ borderRadius: "10px", backgroundColor: "#FF9F43", color: "white" }}>
                                                    <div className="card-body" style={{}}>
                                                        <div className="media">
                                                            <div className="media-body overflow-hidden">
                                                                <p className="text-truncate font-size-14 mb-2">
                                                                    {" "}
                                                                    Upcoming Leaves
                                                                </p>
                                                                <h4 className="mb-0" style={{ color: "white" }}><b>4</b></h4>
                                                            </div>
                                                            <div style={{ color: "white" }}>
                                                                <i className="ri-calendar-event-line font-size-48" style={{ fontSize: "35px" }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card ">
                                            <div className="card-body p-2">

                                                {/* <div className="announcementsSwitches mb-2">
                                                    <ul className="nav nav-tabs m-0">
                                                        <li className={`nav-item cursor-pointer`}>
                                                            <a
                                                                className={`nav-link ${enquiryTab === "enquiry" ? "active" : ""} nav-link`}
                                                                onClick={() => {
                                                                    setenquiry("enquiry");
                                                                }}
                                                            >
                                                                <b>New Enquiry</b>
                                                            </a>
                                                        </li>
                                                        {/* <li className="nav-item cursor-pointer">
                                                            <a
                                                                className={`nav-link ${announcementTab === "events" ? "active" : ""} nav-link`}
                                                                onClick={() => {
                                                                    setAnnouncementTab("events");
                                                                }}
                                                            >
                                                                <b>Events</b>
                                                            </a>
                                                        </li> */}
                                                {/* </ul>
                                                </div> */}
                                                <div>

                                                    <div style={{ overflowY: "auto", height: "225px" }}>
                                                        <h5>New Enquiry</h5>
                                                        <hr></hr>
                                                        <List
                                                            size="small"
                                                            bordered
                                                            dataSource={enquiryTab === "enquiry" ? penddata?.filter(enquiry => enquiry.college_id == collegeids && enquiry.status == "ACTIVE") : ""}
                                                            renderItem={(item, index) => (
                                                                <List.Item>
                                                                    <div className="d-flex w-100">
                                                                        <div className="mt-1">
                                                                            {enquiryTab === "enquiry" && (
                                                                                <i
                                                                                    className="ri-user-search-fill mr-2 px-2 py-2 text-white"
                                                                                    style={{
                                                                                        backgroundColor: colors[index % colors.length],
                                                                                        borderRadius: "20%",
                                                                                        fontSize: "80%",
                                                                                    }}
                                                                                ></i>
                                                                            )}
                                                                        </div>
                                                                        <div style={{ color: "black" }}>
                                                                            <b>{`${item?.name}`}</b><br></br>
                                                                            {`${`${localPrograms.find((s) => s?.id === item?.program_id)?.name} | ${localColleges.find((s) => s?.id == item?.college_id)?.name} | ${localDepartment.find((s) => s?.id == item?.department_id)?.name}`}`.substring(0, 75)}{`${localPrograms.find((s) => s?.id === item?.program_id)?.name} | ${localColleges.find((s) => s?.id == item?.college_id)?.name} | ${localDepartment.find((s) => s?.id == item?.department_id)?.name}`.length > 75 ? '...' : ''}

                                                                            <br></br> <div
                                                                                className="text-end"
                                                                                style={{ color: "gray", whiteSpace: "nowrap", marginLeft: "auto", }}
                                                                            > - {formatMonthYear(item?.date)}</div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row justify-content-end mr-2">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-nex"
                                                                            data-toggle="modal"
                                                                            data-target="#ModalAdmissionEnquirry"
                                                                            onClick={() => {
                                                                                setEdit(item);
                                                                            }}>
                                                                            View
                                                                        </button>
                                                                    </div>
                                                                </List.Item>
                                                            )}
                                                        />








                                                    </div>
                                                    <div className="row justify-content-center m-2">

                                                        <button
                                                            type="button"
                                                            className="btn btn-nex"
                                                            onClick={() => {
                                                                navigate(ROUTES.AdConsult.OnlineEnquiry);
                                                            }}
                                                        >View All</button>

                                                    </div>

                                                </div>
                                                {/* {announcementTab === "announcements" ? (
                                                    <div className="row justify-content-end">
                                                        <div className="col-md-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-nex btn-mw-100 w-100"
                                                                onClick={() => {
                                                                    navigate(ROUTES.Employee.Team.Announcement);
                                                                }}
                                                            >
                                                                View All
                                                            </button>
                                                        </div>
                                                    </div>) : (<></>)} */}
                                            </div>
                                        </div>










                                        {/*                                       
                                        <div className="card">
                                            <div className="card-body" style={{ height: "250px" }}>
                                                <h4 className="card-title mb-2 mt-2 text-uppercase"><b>Pending Attendance (5)</b></h4>
                                                <hr className="p-1 m-1 mt-2" />
                                            </div>
                                        </div> */}

                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" style={{ height: "425px" }}>
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <img
                                                        src="../../../assets/images/reports/profile.png"
                                                        alt="Staff Name"
                                                        width="30%"
                                                        className="img-fluid"
                                                    />

                                                    <h3 className="mt-2 text-uppercase" style={{ color: "#364277" }}>{employe_login_name}</h3>
                                                    <p>{emp_role}</p>

                                                </div>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar progress-bar-striped"
                                                        role="progressbar"
                                                        style={{ width: "70%" }}
                                                        aria-valuenow={75}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-between mt-2">
                                                    <p>Profile Filled</p>
                                                    <p>70%</p>
                                                </div>
                                                <center><button onClick={() => { navigate(ROUTES.Employee.Profile) }} type="button" className="btn btn-secondary"><i class="ri-user-line"></i> My Profile</button></center>
                                            </div>
                                        </div>
                                        {/* <div className="card p-0">
                                            <div className="card-body p-2">
                                                <h4 className="card-title mb-2 mt-2"><b>Quick Links</b></h4>
                                                <hr className="p-1 m-1 mt-2" />
                                                <div style={{ overflowY: "auto", height: "380px" }}>

                                                    <List
                                                        size="small"
                                                        bordered
                                                        dataSource={links}
                                                        renderItem={(item, index) => (
                                                            <List.Item>
                                                                <div>
                                                                    <i className="ri-links-line mr-2 px-2 py-1 text-white" style={{ backgroundColor: colors[index % colors.length], borderRadius: "20%" }}></i>
                                                                    <Link to={item?.link} style={{ color: "black", fontSize: "13px" }}>{item?.name?.length > 75 ? `${item?.name?.substring(0, 75)}...` : item.name}</Link>
                                                                </div>
                                                            </List.Item>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <div className="card p-3 w-100">
                                            <h3 className="my-3">Event Calendar</h3>
                                            <Calender
                                                setLoading={setLoading}
                                                collegeId={sessionStorage.getItem("college_id")}
                                                emp_id={sessionStorage.getItem("employee_id")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DashboardAd_Co
