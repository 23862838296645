import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import useEmployee from "../../../Hooks/Employee/useEmployee";
import ModalAddEntitlment from "../../../modals/HR/Leave/Management/ModalAddEntitlment";
import { LEAVE_ENTITLEMENT, LEAVE_GET_ALL, LEAVE_LEAVE_TYPE } from "../../../utils/apiConstants";
import { ALL_DATA, LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { SESSION_ROLE } from "../../../utils/sessionStorageContants";
import Loader from "../../Loader/Loader";
import Nodata from "../../NoData/Nodata";
import Select from "react-select";
import ModalEntitlementTemplate from "../../../modals/HR/Staff/ModalEntitlementTemplate";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";

function ManagementEntitlement({ setLoading, collegeId }) {
  const [data, setData] = useState([]);
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [employee] = useEmployee(collegeId);

  const [showBulkResult, setShowBulkResult] = useState(false);
  const [bulkResult, setBulkResult] = useState({});
  const [showBulkTemplate, setShowBulkTemplate] = useState(false);

  const [emp, setEmp] = useState("");
  const [alldata, setAllData] = useState([]);

  const [editType, setEditType] = useState("");

  const [edit, setEdit] = useState();

  const [role, setRole] = useState(sessionStorage.getItem(SESSION_ROLE));

  const [search, setSearch] = useState("");

  const [employeeOpt, setEmployeeOpt] = useState([]);

  // const collegeData = JSON.parse(localStorage.getItem(ALL_DATA)).college;

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const getData = async (p) => {
    if (!p) {
      return;
    }

    setLoading(1);

    let url1;

    if (p == "All") {
      url1 = `${LEAVE_ENTITLEMENT}?college_id=${collegeId}`;
    } else {
      url1 = `${LEAVE_ENTITLEMENT}?college_id=${collegeId}&employee_id=${p}`;
    }

    setEmp(p);
    const config = {
      method: "get",
      url: url1,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        let entitledData = res.data.data;
        if (sessionStorage.getItem("role") != "SUPERADMIN") {
          entitledData = entitledData.filter(
            (s) => s.year == new Date().getFullYear()
          );
        }
        console.log("entitle data - ", entitledData);
        setData(entitledData);
        // console.log(res);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };
  const getAlldata = async () => {
    const config = {
      method: "get",
      url: `${LEAVE_GET_ALL}?college_id=${collegeId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config)
      .then((res) => {
        setAllData(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });

      const config2 = {
        method: 'get',
        url: LEAVE_LEAVE_TYPE,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
        },
    };
    
    setLoading(1);
    await axios(config2)
    .then((res) => {
            setLoading(0)
            console.log('leave types - ', res.data.data);
            setLeaveTypeData(res.data.data)
        })
        .catch(err => {
            setLoading(0)
            console.log(err)
        })

  };

  useEffect(() => {
    let temp = [];
    temp.push({ value: "All", label: "All" });
    employee?.map((i, key) => {
      let obj = {};
      obj["value"] = i?.id;
      obj["label"] = i?.first_name + " " + i?.last_name;
      temp.push(obj);
    });
    setEmployeeOpt(temp);
  }, [employee]);

  useEffect(() => {
    getData("All");
    getAlldata();
  }, []);

console.log("collegeOpt:", collegeOpt);
console.log("data",data);
console.log("data?.college_id:", data?.college_id);

const foundCollege = collegeOpt?.find((s) => s.id === data?.college_id);
console.log("foundCollege:", foundCollege);

const collegeName = foundCollege?.name;
console.log("collegeName:", collegeName);

  const tableRef = useRef();

    const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: " Leave Entitlement List ",
      sheet: "Users",
    });

    const PrintRecipt = useReactToPrint({
      content: () => tableRef.current,
    });

    // const handlePrint = () => {
    //   PrintRecipt();
    // };

    const handlePrint = () => {
      // Make the table visible
      tableRef.current.style.display = 'table';
    
      // Delay the PDF export
      setTimeout(() => {
        PrintRecipt();
        // Hide the table again if needed
        tableRef.current.style.display = 'none';
      }, 1); // Adjust the delay as needed
    };

    const getCurrentDate = () => {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
      return formattedDate;
    };

  return (
    <div>
      <ModalEntitlementTemplate
        showBulkTemplate={showBulkTemplate}
        setShowBulkTemplate={setShowBulkTemplate}
      />

      <ModalAddEntitlment
        reloadData={(d) => {
          getData(d);
        }}
        setLoading={setLoading}
        empId={emp}
        allData={alldata}
        collegeId={collegeId}
        edit={edit}
        editType={editType}
      />
      {/* start page title */}
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Select Employee</h2>
              <br />
              <div className="row d-flex ">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="validationCustom01">
                      Employee<span style={{ color: "red" }}>*</span>
                    </label>
                    {/* <select
                                            id="role"
                                            name="role"
                                            className="form-control"
                                            value={emp}
                                            onChange={(e) => { getData(e.target.value) }}
                                        >
                                            <option value="All" selected>All</option>
                                            {
                                                employee?.map((i, key) => (
                                                    <option value={i.id} key={key}>{i.first_name} {i.last_name}</option>
                                                ))
                                            }
                                        </select> */}
                    <Select
                      id="role"
                      options={employeeOpt}
                      onChange={(e) => {
                        getData(e.value);
                        // getEntitlement(e.value);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-md-8">
                    <button
                      className="btn btn-success btn-sm btn-rounded float-right ml-3"
                      type="submit"
                      name="submit"
                      onClick={() => setShowBulkTemplate(true)}
                    >
                      <i className="fa fa-download" aria-hidden="true" />{" "}
                      Download Documents
                    </button>
                    <button
                      className="btn btn-primary btn-sm btn-rounded float-right"
                      data-toggle="modal"
                      data-target="#exampleModalLong"
                      type="button"
                      name="submit"
                    >
                      <i className="fa fa-upload" aria-hidden="true" />{" "}
                      Upload Documents
                    </button>
                  </div> */}
              </div>
            </div>
          </div>
          {/* end card */}
        </div>
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0">Entitlement Report</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="/">Leave</a>
                </li>
                <li className="breadcrumb-item active">Entitlement Report</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* end page title */}

      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row d-flex justify-content-end p-3">
              {role == "SUPERADMIN" && (
                <div>
                  {emp !== "" ? (
                    <button
                      className="btn btn-rounded btn-success btn-outline px-4"
                      data-toggle="modal"
                      data-target="#ModalAddEntitlment"
                      onClick={() => {
                        setEditType("add");
                      }}
                    >
                      New Entitlement
                    </button>
                  ) : (
                    <button className="btn text-danger">
                      Select Employee to add new Entitlement
                    </button>
                  )}
                </div>
              )}

              {/* <button className="btn btn-rounded btn-primary btn-outline px-4">
                Export &uarr;
              </button> */}
              <button
                className="btn btn-primary rounded-pill ml-5"
                onClick={onDownload}
              >
                Export Excel
              </button>
              <button
                              className="btn btn-primary rounded-pill ml-2"
                              onClick={handlePrint}
                            >
                              Export PDF
                            </button>
            </div>

                                    

            <div>
              {data && data.length !== 0 ? (
                data?.map((i, key) => (
                  <div
                    className="row my-3 mx-2 p-3 border rounded shadow report-div cursor-normal"
                    data-toggle="modal"
                    data-target="#ModalAddEntitlment"
                    onClick={() => {
                      setEditType("edit");
                      setEdit(i);
                    }}
                    key={key}
                  >
                    <div className="col-12 row">
                      <div className="report-title col-12">
                        {employee &&
                          employee?.find((j) => j.id === i.employee_id)
                            ?.first_name}
                        {}
                      </div>
                      <div className="row col-12  role-parts">
                        <div className="col-sm-3 col-12 role-part-left">
                          <button className="btn btn-primary btn-rounded">
                            {/* {i.leave_type} */}
                            {leaveTypeData?.find((s) => s.id == i.leave_type)?.code}
                          </button>
                          <div className="d-flex my-2 text-danger">
                            {formatDate(i.to_date.split("T")[0])}
                          </div>
                        </div>
                        <div className="col-sm-9 col-12  row role-part-right">
                          <div className={`col-6 report-items `}>
                            <div className="report-item-title">Balance</div>
                            <div className="report-item-value btn btn-danger p-1">
                              {i?.balance}
                            </div>
                          </div>
                          <div className={`col-6 report-items `}>
                            <div className="report-item-title">Earned</div>
                            <div className="report-item-value ">
                              {i?.earned}
                            </div>
                          </div>
                          {/* <div className={`col-6 report-items `}>
                                                    <div className='report-item-title'>
                                                        Carried Over
                                                    </div>
                                                    <div className='report-item-value '>
                                                        {i.carried_over}
                                                    </div>
                                                </div> */}
                          <div className={`col-6 report-items `}>
                            <div className="report-item-title">Entitlement</div>
                            <div className="report-item-value ">
                              {i.entitled_days}
                            </div>
                          </div>
                          <div className={`col-6 report-items `}>
                            <div className="report-item-title">Taken</div>
                            <div className="report-item-value ">
                              {i.taken_days ? i.taken_days : 0}
                            </div>
                          </div>
                          {/* <div className={`col-6 report-items `}>
                                                    <div className='report-item-title'>
                                                        Emergency
                                                    </div>
                                                    <div className='report-item-value '>
                                                        4
                                                    </div>
                                                </div> */}
                          {/* {
                                                                i.Data.map((j, key2) => (

                                                                    <div className={`col-6 report-items `} key={key2}>
                                                                        <div className='report-item-title'>
                                                                            {j.title}
                                                                        </div>
                                                                        <div className={`report-item-value ${j.title==='Balance'?'btn btn-danger p-1':''}`}>
                                                                            {j.value}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            } */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <Nodata />
              )}
            </div>
          </div>
        </div>
        <table
                                      id="table_id"
                                      ref={tableRef}
                                      className=" table table-bordered  "
                                      style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%", display: 'none' }}
                                    >
                                        <div className="col-12 text-center">
                                        
                                        <thead>
                                          <tr>
                                            <td colSpan={10}>
                                              <div>
                                                <h4 className="text-center">
                                                  Management Entitlement List -{" "}
                                                    {data?.length > 0 &&
                                                      collegeOpt?.find((s) => s.id === data[0]?.college_id)?.name}
                                                </h4>
                                                <span>
                                                  <p className="text-right float-right">
                                                    Printed On - {getCurrentDate()}
                                                  </p>
                                                </span>
                                              </div>
                                            </td>
                                          </tr>
                                            <tr>
                                                <th>Sl.No.</th>
                                                <th>Employee Name</th>
                                                <th>Leave Type</th>
                                                <th>Entitled Days</th>
                                                <th>Year</th>
                                                <th>From Date</th>
                                                <th>To Date</th>
                                                <th>Balance</th>
                                                <th>Earned</th>
                                                <th>Taken</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                          data && data?.length == 0
                                          ? (
                                          <tr>
                                            <td colSpan={10}>
                                              <Nodata />
                                            </td>
                                          </tr>
                                           ) : (
                                          data
                                          ?.map((i, key) => {
                                            return <tr key={key}>
                                              <td>{key + 1}</td>
                                              <td>{employee && employee?.find((j) => j.id === i.employee_id)?.first_name}
                                              </td>
                                              <td>{leaveTypeData?.find((s) => s.id == i.leave_type)?.description}</td>
                                              <td>{i?.entitled_days}</td>
                                              <td>{i?.year}</td>
                                              <td> 
                                                {/* {i?.from_date} */}
                                                {new Date(i?.from_date).toISOString().split('T')[0]}
                                                </td>
                                              <td> 
                                                {/* {i?.to_date} */}
                                                {new Date(i?.to_date).toISOString().split('T')[0]}
                                                </td>
                                              <td>{i?.balance}</td>
                                              <td>{i?.earned}</td>
                                              <td>{i?.taken_days}</td>
                                              </tr>
                                          })
                                           )
                                        }
                                        </tbody>
                                        </div>
                                    </table>

      </div>
    </div>
  );
}

export default ManagementEntitlement;
