import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { SESSION_ROLE } from "../../utils/sessionStorageContants";

function CredentialsStudent({
  id,
  setLoading,
  collegeId,
  studentDetails,
  setStudentDetails,
  flag,
}) {
  const [user, setUser] = useState();

  const [role, setRole] = useState(sessionStorage.getItem(SESSION_ROLE));
  const [editor, setEditor] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setRole(sessionStorage.getItem(SESSION_ROLE));
  }, [sessionStorage.getItem(SESSION_ROLE), flag]);

  useEffect(() => {
    if (role == "ADMIN" || role == "SUPERADMIN") {
      setEditor(false);
    } else {
      setEditor(true);
    }
  }, [role, flag]);

  const [componentLoaded, setComponentLoaded] = useState(false);
  useEffect(() => {
    if (!componentLoaded) {
      console.log(studentDetails);
      setUser(studentDetails);
      setComponentLoaded(true);
    }
  }, [componentLoaded]);

  return (
    <div className="BasicInformation">
      {/* <div className="p-3">
        <h6>Student Credentials :</h6>
        <hr />
        <table>
          <tr>
            <td width="30%">
              <strong>Username :</strong>
            </td>
            <td width="50%"> {studentDetails?.user_id}</td>
          </tr>
          <br />
          <tr>
            <td>
              <strong>Password :</strong>
            </td>
            <td> {studentDetails?.password}</td>
          </tr>
        </table>
      </div> */}
      <div className="p-3 mt-4" style={{ backgroundColor: '#f9f9f9', borderRadius: '10px', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
  <h6 style={{ fontSize: '1.1rem', marginBottom: '10px', borderBottom: '2px solid #ccc', paddingBottom: '10px' }}><i className="ri-lock-2-line mb-2"></i> &nbsp; Student Credentials </h6>
  <table style={{ width: '100%', fontSize: '0.9rem', color: '#333' }}>
    <tr>
      <td style={{ width: '20%', fontWeight: 'bold' }}>Username :</td>
      <td style={{ width: '90%' }}> {studentDetails?.user_id}</td>
    </tr>
    <br />
    <tr>
      <td style={{ fontWeight: 'bold' }}>Password &nbsp;:</td>
      <td> 
        {showPassword ? studentDetails?.password : '*'.repeat(studentDetails?.password?.length)} 
        {showPassword ? <button className="btn btn-sm btn-nex ml-2" onClick={()=> setShowPassword(false)}>Hide</button> : <button className="btn btn-sm btn-nex ml-2" onClick={()=> setShowPassword(true)}>View</button>} 
      </td>
    </tr>
  </table>
</div>

    </div>
  );
}

export default CredentialsStudent;
