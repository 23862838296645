export const sessionOpt = [
    // {
    //     name: '2025-26',
    //     id: '2025'
    // },
    {
        name: '2024-25',
        id: '2024',
        status: "ACTIVE",
    },
    {
        name: '2023-24',
        id: '2023'
    },
    {
        name: '2022-23',
        id: '2022'
    },
    {
        name: '2021-22',
        id: '2021'
    },
    {
        name: '2020-21',
        id: '2020'
    }
]