import React, { useRef } from "react";
import "./Topbar.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { LOCAL_COLLEGE } from "./../../utils/LocalStorageConstants";
import {
  SESSION_COLLEGE_ID,
  SESSION_EMPLOYEE_ID,
} from "../../utils/sessionStorageContants";
import { ROUTES } from "../../Router/routerConfig";
import { EMPLOYEE } from "../../utils/apiConstants";
import axios from "axios";
import getUniversityDetails from "../../utils/universityDetails.api";
import { Dropdown, Space } from "antd";
import Cookies from "js-cookie";
import { HTML5_FMT } from "moment";
import ResetPasswordModal from "./ResetPasswordModal";

// const Flag = ({ name, height, className }) => {
//   return (
//     <>
//       <img
//         src={`/assets/images/flags/lang_flag/${name}.jpg`}
//         height={height}
//         className={className}
//       />
//     </>
//   );
// };

function Topbar({ changeCollege }) {
  const [universityData, setUniversityData] = useState([]);
  const [localLangCode, setLocalLangCode] = useState("en");
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  const g_transalte_parent = useRef();

  const handleChangeLanguage = (e, reload) => {
    const lang_code = e?.target?.value || localStorage.getItem("lang_code");
    setLocalLangCode(lang_code);

    if (reload) {
      // window.location.reload();
      navigate(0);
      setDir(lang_code === "ur" ? "rtl" : "ltr");
    }

    localStorage.setItem("lang_code", lang_code);

    const selects = document.querySelectorAll(".goog-te-combo");

    if (selects.length > 0) {
      const lastSelect = selects[selects.length - 1];
      const langOption = lastSelect.querySelector(
        `option[value="${lang_code}"]`
      );

      if (langOption) {
        langOption.selected = true;

        const event = new Event("change", { bubbles: true });
        lastSelect.dispatchEvent(event);
      }
    }

    const existingGoogtrans = Cookies.get("googtrans");
    const languageCode = existingGoogtrans
      ? existingGoogtrans.split("/").pop()
      : null;

    const newGoogtrans = `/en/${lang_code}`;

    if (!languageCode || languageCode !== lang_code) {
      Cookies.set("googtrans", newGoogtrans, { path: "/" });
    }

    // const googtrans = Cookies.get('googtrans');

    // if(!googtrans){
    //   Cookies.set('googtrans',`/en/${lang_code}`,{ domain: '.kbnuniversity.org' });
    //   alert("okay")
    // }

    // const languageCode = Cookies.get('googtrans').split('/').pop();

    // if(languageCode !== lang_code){
    //   Cookies.remove('googtrans');
    //   Cookies.set('googtrans',`/en/${lang_code}`,{ domain: '.kbnuniversity.org' });
    // }
  };

  const items = [
    {
      label: <span translate="no">Engilsh</span>,
      key: "0",
      // icon: <Flag name={"en"} height={16} />,
      title: "en",
    },
    {
      label: <span translate="no">Hindi</span>,
      key: "1",
      // icon: <Flag name={"hi"} height={16} />,
      title: "hi",
    },
    {
      label: <span translate="no">Kannada</span>,
      key: "2",
      // icon: <Flag name={"kn"} height={16} />,
      title: "kn",
    },
    {
      label: <span translate="no">Urdu</span>,
      key: "3",
      // icon: <Flag name={"ur"} height={16} />,
      title: "ur",
    },
  ];

  useEffect(() => {
    const addScript = document.createElement("script");

    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );

    document.body.appendChild(addScript);

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          autoDisplay: true,
        },
        "google_translate_element"
      );
    };

    if (window.google && window.google.translate) {
      googleTranslateElementInit();
    }

    const storedLangCode = localStorage.getItem("lang_code");

    if (!storedLangCode) {
      localStorage.setItem("lang_code", "en");
    }

    handleChangeLanguage();

    return () => {};
  }, []);

  /////////////////////////////////////////
  /////////////////////////////////////////
  //////////RTL & LTR PROCESS START////////
  /////////////////////////////////////////
  /////////////////////////////////////////

  const [dir, setDir] = useState("ltr");

  useEffect(() => {
    const langCode = localStorage.getItem("lang_code");

    if (langCode === "ur") {
      setDir("rtl");
    } else {
      setDir("ltr");
    }
    document.documentElement.dir = dir;
  }, [dir]);

  /////////////////////////////////////////
  /////////////////////////////////////////
  //////////RTL & LTR PROCESS END//////////
  /////////////////////////////////////////
  /////////////////////////////////////////

  const location = useLocation();
  const navigate = useNavigate();
  const [role, setRole] = useState();
  let emp_id = sessionStorage.getItem("employee_id");
  const [empColleges, setEmpColleges] = useState([]);
  const [collegeTitle, setCollegeTitle] = useState("College");
  // const [collegeOpt, setCollegeOpt] = useState()
  const [dropdownOpen, setDropdownOpen] = useState();

  // const [employeeId, setEmployeeId] = useState(
  //   sessionStorage.getItem(SESSION_EMPLOYEE_ID)
  // );

  const employeeId = sessionStorage.getItem("employee_id");

  const Logout = () => {
    console.log("enterd function");
    sessionStorage.clear();
    navigate("/");
  };

  const getUserRole = () => {
    return sessionStorage.getItem("role")
      ? sessionStorage.getItem("role")
      : null;
  };

  const getColleges = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const checkRoleAndNavigateToTicket = () => {
    if (role == "SUPERADMIN") navigate(ROUTES.Registar.Ticket);
    if (role == "WARDEN") navigate(ROUTES.Warden.HostelRooms);

    if (role == "ADMIN") {
      navigate(ROUTES.Principal.Ticket);
    }

    if (role == "STAFF") navigate(ROUTES.Employee.Ticket);
    if (role == "NTSTAFF") navigate(ROUTES.NonTeachingStaff.Ticket);
    if (role == "SHR") navigate(ROUTES.HR.Ticket);

    if (role == "SUACC" || role == "ACC") navigate(ROUTES.Accountant.Ticket);
  };

  const [localCollege, setLocalCollege] = useState();

  const [empDetails, setEmpDetails] = useState();

  const [collegeOpt, setCollegeOpt] = useState(getColleges);

  useEffect(() => {
    setRole(getUserRole());
  }, [sessionStorage.getItem("role")]);

  useEffect(() => {
    setCollegeOpt(getColleges);
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  const getCollegeId = () => {
    return sessionStorage.getItem(SESSION_COLLEGE_ID)
      ? sessionStorage.getItem(SESSION_COLLEGE_ID)
      : "1111000";
  };

  const [selectedCollege, setSelectedCollege] = useState(getCollegeId);

  const changeCollegeId = (id) => {
    console.log("Selected College ID:", id);
    setSelectedCollege(id);
    changeCollege(id);
  };

  const paths = location?.pathname?.split("/");

  const getEmployee = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${employeeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        let clgs = JSON.parse(res.data.data["multi_clg_id"]);
        setEmpColleges(collegeOpt.filter((item) => clgs?.includes(item.id)));
        console.log("emp details - ", res.data.data);
        setEmpDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (employeeId) getEmployee();
  }, [employeeId]);

  useEffect(() => {
    setLocalLangCode(localStorage.getItem("lang_code"));
  }, [localLangCode]);

  //UNIVERSITY DETAILS USE EFFECT

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();
  }, []);

  //UNIVERSITY DETAILS END

  return (
    <>
      <ResetPasswordModal />
      {location.pathname !== "/login" && (
        <div className="Topbar">
          <header id="page-topbar">
            <div className=" d-flex justify-content-between">
              <div className="d-flex justify-content-start mh-100">
                <div className="d-flex justify-content-start">
                  <Link
                    to="#"
                    className="d-flex justify-content-center align-items-center px-2"
                    style={{ background: "white" }}
                  >
                    <img
                      src={universityData.logo_primary}
                      alt=""
                      className=""
                      width={180}
                    />
                  </Link>
                </div>
                <button
                  type="button"
                  className="btn btn-sm px-3 font-size-24 d-lg-none header-item"
                  data-toggle="collapse"
                  data-target="#topnav-menu-content"
                  style={{ marginLeft: "auto" }}
                >
                  <i className="ri-menu-2-line align-middle" />
                </button>
              </div>

              <div
                id="google_translate_element"
                style={{ marginLeft: "auto", marginTop: "1rem" }}
                ref={g_transalte_parent}
              ></div>

              {/* <Dropdown
              menu={{
                items,
                selectable: true,
                // defaultSelectedKeys: ['0'],
                onClick: (e) => {
                  handleChangeLanguage(e, true);
                },
              }}
              trigger={["click"]}
              className="user-select-none"
            >
              <a
                onClick={(e) => e.preventDefault()}
                style={{
                  marginLeft: "auto",
                  marginTop: "1.5rem",
                  cursor: "pointer",
                }}
              >
                <Space>
                   <Flag
                    name={localLangCode || "en"}
                    height={17}
                    className={"mr-1"}
                  /> 
                  <span className="text-light" translate="no">
                    {localLangCode === "en"
                      ? "EN"
                      : localLangCode === "hi"
                      ? "HI"
                      : localLangCode === "kn"
                      ? "KN"
                      : localLangCode === "ur"
                      ? "UR"
                      : ""}
                  </span>
                  <i class="ri-arrow-down-s-line text-white "></i>
                </Space>
              </a>
            </Dropdown> */}

              <div className="d-flex">
                {emp_id == 502 ? (
                  <div>
                    <h6 className="text-white mt-4">
                      {collegeOpt?.find((s) => s.id == 1111011)?.name}
                    </h6>
                  </div>
                ) : (
                  <></>
                )}

                {emp_id != 502 &&
                (role === "REGISTRAR" ||
                  role === "SHR" ||
                  role === "SUPERADMIN" ||
                  role === "SUACC" ||
                  role === "CASHIER" ||
                  role === "WARDEN" ||
                  (role === "AD-CON" && paths.find((s) => s == "con"))) ? (
                  <div>
                    <select
                      className="header-item text-right  bg-transparent text-light  border-0 d-sm-block d-none"
                      style={{ outline: "none" }}
                      value={selectedCollege}
                      onChange={(e) => {
                        changeCollegeId(e.target.value);
                        localStorage.setItem("clg", e.target.value);
                      }}
                    >
                      {collegeOpt?.map((i, key) => (
                        <option className="text-secondary" value={i.id}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
                <div>
                  <select
                    className="header-item text-right  bg-transparent text-light  border-0 d-sm-block d-none"
                    style={{ outline: "none" }}
                    value={localLangCode}
                    onChange={(e) => {
                      handleChangeLanguage(e, true);
                    }}
                  >
                    <option
                      className="text-secondary"
                      value="en"
                      translate="no"
                    >
                      English
                    </option>
                    <option
                      className="text-secondary"
                      value="hi"
                      translate="no"
                    >
                      Hindi
                    </option>
                    <option
                      className="text-secondary"
                      value="kn"
                      translate="no"
                    >
                      Kannada
                    </option>
                    <option
                      className="text-secondary"
                      value="ur"
                      translate="no"
                    >
                      Urdu
                    </option>
                  </select>
                </div>
                {/* {role === "STAFF" ? ( */}
                <a
                  href="https://nexenstial.in/unversity_assests/Staff%20Manual.pdf#toolbar=0"
                  target="_blank"
                  className="btn  text-light d-flex align-items-center"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Staff Manual"
                >
                  <i class="ri-newspaper-line mr-1"></i>Manual
                </a>
                {/* ) : null} */}
                {role === "STAFF" ? (
                  <div>
                    <select
                      className="header-item text-right  bg-transparent text-light  border-0 d-sm-block d-none"
                      style={{ outline: "none" }}
                      value={selectedCollege}
                      onChange={(e) => {
                        changeCollegeId(e.target.value);
                        localStorage.setItem("clg", e.target.value);
                      }}
                    >
                      {empColleges?.map((i, key) => (
                        <option className="text-secondary" value={i.id}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
                <button
                  onClick={checkRoleAndNavigateToTicket}
                  className="btn  text-light d-flex align-items-center"
                >
                  <i className="ri ri-coupon-line mr-1"></i> Ticket
                </button>
                <div
                  className="d-flex mt-3"
                  style={{ height: "35px", width: "10px" }}
                >
                  <div class="vr bg-white" style={{ width: "2px" }}>
                    <p></p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <p className="text-light">
                    {empDetails?.first_name ? empDetails?.first_name : ""}{" "}
                    {empDetails?.last_name ? empDetails?.last_name : ""}
                  </p>
                </div>
                <div className="d-flex">
                  <button
                    className="btn action-btn"
                    onClick={() => setDropdownOpen((prev) => !prev)}
                  >
                    <img
                      className="rounded-circle header-profile-user"
                      src={
                        empDetails?.photo
                          ? empDetails?.photo
                          : "/assets/images/users/avatar-2.jpg"
                      }
                      alt="Header Avatar"
                    />
                  </button>
                  {dropdownOpen ? (
                    <div className="action-list">
                      <ul>
                        {role == "STAFF" && (
                          <>
                            <li>
                              <button
                                type="submit"
                                className="dropdown-item"
                                onClick={() => {
                                  navigate(ROUTES.Employee.Profile);
                                }}
                              >
                                <i className="ri-user-line align-middle mr-1" />{" "}
                                Profile
                              </button>
                            </li>
                          </>
                        )}
                        <li>
                          <div
                            className="dropdown-item w-100 cursor-pointer"
                            data-toggle="modal"
                            data-target="#ResetPassword"
                            onClick={() => {}}
                          >
                            <i className="ri-lock-2-line auti-custom-input-icon" />{" "}
                            Reset Password
                          </div>
                        </li>
                        <li>
                          <div
                            className="dropdown-item text-danger w-100 cursor-pointer"
                            onClick={() => {
                              Logout();
                            }}
                          >
                            <i className="ri-shut-down-line align-middle mr-1 text-danger" />{" "}
                            Logout
                          </div>
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </header>
        </div>
      )}
    </>
  );
}

export default Topbar;
