import axios from "axios";
import React from "react";
import { useState } from "react";
import Nodata from "../../../Components/NoData/Nodata";
import { REPORT_FEE_DETAILS } from "../../../utils/Reports.apiConst";
import * as XLSX from "xlsx/xlsx.mjs";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { toast } from "react-toastify";
import { college_title } from "../../../Data/main";
import Select from "react-select";

const DepartmentWiseCollectionReport = ({ setLoading, collegeId }) => {
  let role = sessionStorage.getItem("role");
  // const [user, setUser] = useState({
  //   date: "",
  //   to_transaction_date: "",
  // });

  const [user, setUser] = useState({
    date: new Date().toISOString().split('T')[0], // Set to current date
    to_transaction_date: new Date().toISOString().split('T')[0], // Set to current date
  });

  const tableRef = useRef();

  const [data, setData] = useState([]);

  const [dates, setDates] = useState([]);

  const [deparment, setDeparment] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const facultyData = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const departmentData = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [studentSet, setStudentSet] = useState([]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [name, setName] = useState("");

  // const getData = async () => {
  //   if (!deparment) return toast.error("Please Select Department");
  //   setLoading(1);
  //   const config = {
  //     method: "get",
  //     url: `${REPORT_FEE_DETAILS}?transaction_date=${user?.date}&to_transaction_date=${user?.to_transaction_date}`,
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   await axios(config)
  //     .then((res) => {
  //       setSelectedDepartment(deparment);
  //       setData(res.data.data.filter((s) => s?.department_id == deparment));
  //       let x = res.data.data.filter((s) => s?.department_id == deparment);
  //       const result = {};
  //       for (var i = 0; i < x.length; i++) {
  //         for (var j = 0; j < x[i].newAmount.length; j++) {
  //           if (!result[x[i].newAmount[j].date]) {
  //             result[x[i].newAmount[j].date] = {
  //               date: x[i].newAmount[j].date,
  //               totalAmount: x[i].newAmount[j].amount,
  //             };
  //           } else {
  //             result[x[i].newAmount[j].date].totalAmount +=
  //               x[i].newAmount[j].amount;
  //           }
  //         }
  //       }
  //       const groupedArray = Object.values(result);
  //       setDates(groupedArray);

  //       for (var i = 0; i < departmentData.length; i++) {
  //         if (departmentData[i].id == deparment) {
  //           setName(departmentData[i].name);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   setLoading(0);
  // };

  const getData = async () => {
    setLoading(1);
  
    let apiUrl = `${REPORT_FEE_DETAILS}?transaction_date=${user?.date}&to_transaction_date=${user?.to_transaction_date}`;
  
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('UMS_auth')}`,
          'Content-Type': 'application/json',
        },
      });
  
      setSelectedDepartment(deparment);
  
      let filteredData = response.data.data;
  
      // Check if the selected department is not "All"
      if (deparment !== 'all') {
        filteredData = filteredData.filter((s) => s?.department_id == deparment);
      }
  
      setData(filteredData);
  
      const result = {};
      for (var i = 0; i < filteredData.length; i++) {
        for (var j = 0; j < filteredData[i].newAmount.length; j++) {
          if (!result[filteredData[i].newAmount[j].date]) {
            result[filteredData[i].newAmount[j].date] = {
              date: filteredData[i].newAmount[j].date,
              totalAmount: filteredData[i].newAmount[j].amount,
            };
          } else {
            result[filteredData[i].newAmount[j].date].totalAmount += filteredData[i].newAmount[j].amount;
          }
        }
      }
  
      const groupedArray = Object.values(result);
      setDates(groupedArray);
  
      if (deparment == 'all') {
        const department = departmentData.find((s) => s.id == deparment);
        setName(department ? department.name : '');
      } else {
        setName('All Departments');
      }
    } catch (error) {
      console.log(error);
    }
  
    setLoading(0);
  };

  const collegeList1 = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const downloadExcel = () => {
    let json_data = [];
    for (const iterator of data) {
      const obj = {
        "Student Registeration Number": iterator?.usn,
        "Student Name": iterator?.name,
        Amount: iterator?.amount,
        "Transaction Id": iterator?.transaction_id,
        "Transaction Type": iterator?.type,
      };
      json_data.push(obj);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `SUKALOL-Fee Details.xlsx`);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Department Fee Collection Report of " + name,
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const handleDepartmentChange = (selectedOption) => {
    setDeparment(selectedOption?.value);
  };

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">DEPARTMENT AND DATE WISE FEE REPORTS</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">Select Criteria</div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.date}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="to_transaction_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.to_transaction_date}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Department</label>
                      {/* <select
                        className="form-control"
                        value={deparment}
                        onChange={(e) => {
                          setDeparment(e.target.value);
                        }}
                        name=""
                        id=""
                      >
                        <option value="">Select Department</option>
                        {departmentData
                          ?.filter((s) => s.college_id == collegeId)
                          ?.map((i, index) => (
                            <option key={index} value={i.id}>
                              {i.name}
                            </option>
                          ))}
                      </select> */}

<Select
  options={[
    { value: 'all', label: 'All' },
    ...departmentData
      ?.filter((s) => s.college_id == collegeId)
      ?.map((i) => ({ value: i.id, label: i.name })),
  ]}
  value={
    deparment
      ? {
          value: deparment,
          label: deparment == 'all' ? 'All' : departmentData?.find((i) => i.id == deparment)?.name,
        }
      : null
  }
  onChange={handleDepartmentChange}
  placeholder="Select Department"
/>

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary rounded-pill"
                        onClick={getData}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="card-title">Fee Reports</div>
                    {
                      <button
                        className="btn btn-primary rounded-pill"
                        onClick={role != "AD-CON" ? onDownload : handlePrint}
                      >
                        Export
                      </button>
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered" ref={tableRef}>
                        <tr>
                          <th colSpan={8}>{college_title}</th>
                        </tr>
                        <tr>
                          <th colSpan={8}>Date : {user?.date}</th>
                        </tr>
                        <tr>
                          <th>Sl.No</th>
                          <th>Name</th>
                          <th>Enrollment No.</th>
                          <th>Transaction Id</th>
                          <th>Payment Type</th>
                          <th>Date</th>
                          <th>Note</th>
                          <th>Amount</th>
                        </tr>

                        <tr className="bg-dark text-light">
                          <td colSpan={7}>
                            {
                              departmentData.find(
                                (s) => s.id == selectedDepartment
                              )?.name
                            }
                          </td>
                          <td>
                            {data
                              ?.filter(
                                (s) => s.department_id == selectedDepartment
                              )
                              ?.reduce(
                                (acc, current) =>
                                  acc +
                                  current?.newAmount?.reduce(
                                    (acc2, current2) => acc2 + current2?.amount,
                                    0
                                  ),
                                0
                              )}
                          </td>
                        </tr>
                        {dates.map((item, key) => (
                          <>
                            <tr className="bg-dark text-light">
                              <td colSpan={7}>Date: {item?.date}</td>
                              <td>{item?.totalAmount}</td>
                            </tr>
                            {data?.map((k, key) => (
                              <>
                                {k?.newAmount?.map((j, key2) => {
                                  if (j?.date == item?.date) {
                                    return (
                                      <tr key={key2}>
                                        <td>{key + 1}</td>
                                        <td>{k?.studentName}</td>
                                        <td>{k?.user_id}</td>
                                        <td>{j?.payment_id}</td>
                                        <td>{j?.mode}</td>
                                        <td>{j?.date}</td>
                                        <td>{j?.note}</td>
                                        <td>{j?.amount}</td>
                                      </tr>
                                    );
                                  }
                                })}
                              </>
                            ))}
                          </>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentWiseCollectionReport;
